import {useState, useEffect, useMemo, useCallback} from 'react';

import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';
import Loading from '@/components/search/common/Loading';
import {NoSearchResults, ShowMore} from '@/components/search/common';
import {ErrorBoundary} from '@/components/common';
import {SectionHeader} from '@/components/main/common';
import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import {SoopUiSelectBox} from '@/components/ui';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useInfiniteScroll} from '@/components/main/common/hooks/useInfiniteScroll';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

import {
  SEARCH_LIVE_FILTER,
  SEARCH_TAG_SEARCH_LIVE_FILTER,
} from '@/constants/search/filter';
import {BROADCAST_ITEM_DISPLAY_TYPE} from '@/constants';
import {DIRECTORY_LIVE_CONTEXT_MENU as LIVE_CONTEXT_MENU} from '@/constants/main';

const LiveGroup = ({live, stopWord}) => {
  const {sendSckactLiveClickLog} = useSearchClickLog();
  const {getParams} = useUrlParameter();
  const {
    szKeyword: keyword = '',
    szSearchType: searchType = '',
    hash: hashParam = '',
    hashtype: hashType = '',
    tagname,
  } = getParams();
  const {
    sessionKey,
    liveLoading,
    noResult,
    hasMore,
    changeStatus,
    currentPageNumber,
    perPage,
    nextPage,
    activeTab,
    resetPageNumber,
    isTotalPage,
    isLivePage,
    isLiveTagSearchPage,
  } = useSearchGlobalStatus();

  const [selectBoxItem, setSelectBoxItem] = useState(
    hashParam ? 'view_cnt_up' : 'score',
  );
  const [liveList, setLiveList] = useState([]);
  const [liveListSlice, setLiveListSlice] = useState(6);

  const showFilter = useMemo(() => {
    return (
      (isTotalPage && liveList.length > 0) || isLivePage || isLiveTagSearchPage
    );
  }, [searchType, hashType, liveList]);

  const {ref: lastPositionRef, inView: isLastInView} = useInfiniteScroll({
    delay: 100,
    onChange: (inView) => {
      if (inView && (isLivePage || isLiveTagSearchPage) && hasMore.live) {
        nextPage(activeTab);
      }
    },
  });

  // 필터 옵션 변경
  const handleChangeFilterOption = (item, filters) => {
    if (isLivePage || isLiveTagSearchPage) {
      setLiveList([]);
    }
    setSelectBoxItem(filters.type);
    changeStatus('live', perPage, [filters]);
  };

  // 더보기 버튼
  const handleShowMore = () => {
    setLiveListSlice((prevCount) => prevCount * 2);
    handleLogCallback('', '', '', {type: 'more'});
  };

  const liveDisplayList = useMemo(() => {
    if (isTotalPage) {
      return liveList.slice(0, Math.max(liveListSlice, 6)); // 최소 6개 항목 보장
    }
    return liveList;
  }, [searchType, liveList, liveListSlice]);

  const inflowPathLogData = useMemo(() => {
    let logData = {};

    if (isTotalPage || isLivePage) {
      logData = {path1: 'sch', path2: 'sch', path3: 'total'};
    } else if (isLiveTagSearchPage) {
      logData = {path1: 'hash', path2: 'live', path3: 'live'};
    }

    return logData;
  }, [searchType, hashType, tagname]);

  // SCKACT 로그
  const handleLogCallback = useCallback(
    (index, broadcastId, bjId, param) => {
      const {type = 'sn'} = param || {};

      let contentsType = 'live';
      let contentsValue = broadcastId;
      let location = 'total_search';

      let tab =
        (tagname !== undefined && tagname !== '') || searchType === 'broad'
          ? 'live'
          : searchType;

      let convertActtype = type;
      if (tagname !== undefined && tagname !== '') {
        convertActtype = 'hash';
        location = 'hash';
      }

      if (type === 'sti') {
        contentsType = 'bj';
        contentsValue = bjId;
      }

      const isRemoveField = ['nick', 'more', 'hash'].includes(type);

      // sckact 로그 전송
      sendSckactLiveClickLog(
        {
          tab: tab,
          contentsType: isRemoveField ? '' : contentsType,
          contentsValue: isRemoveField ? '' : contentsValue,
          page: currentPageNumber['broad'],
          actCode: type,
          bjid: bjId,
          bno: broadcastId,
          listIdx: index,
          location: location,
          sessionKey: sessionKey,
        },
        '',
      );
    },
    [sessionKey, searchType, currentPageNumber['broad']],
  );

  useEffect(() => {
    setLiveList([]);
    // live 필터옵션 '정확도'로 초기화
    if (isTotalPage || isLivePage) {
      setSelectBoxItem('score');
    }
    setLiveListSlice(isTotalPage ? 6 : liveList.length);
  }, [searchType]);

  useEffect(() => {
    if (currentPageNumber[activeTab] > 1) {
      setLiveList((prevLive) => [...prevLive, ...live]);
    } else {
      setLiveList(live);
    }
  }, [live]);

  // TODO 테스트 후 문제 없으면 아래 코드 삭제 예정
  // useEffect(() => {
  //   setLiveListSlice(searchType === 'total' ? 6 : liveList.length);
  // }, [searchType, liveList.length]);

  useEffect(() => {
    return () => {
      resetPageNumber('broad');
    };
  }, []);

  const renderLiveSectionHeader = () => {
    if (showFilter) {
      return (
        <SectionHeader
          title='LIVE'
          rightElement={
            <SoopUiSelectBox
              isSelectedOptionHighlight={true}
              options={
                hashParam ? SEARCH_TAG_SEARCH_LIVE_FILTER : SEARCH_LIVE_FILTER
              }
              type={selectBoxItem || ''}
              onChange={handleChangeFilterOption}
            />
          }
        />
      );
    }
    return null;
  };

  const renderLiveSectionNoResult = () => {
    const shouldRenderNoResult =
      (isLivePage || isLiveTagSearchPage) &&
      !liveLoading &&
      (stopWord || noResult.live || (!keyword && !tagname));

    if (shouldRenderNoResult) {
      return (
        <NoSearchResults
          searchTab='broad'
          isKeywordEntered={keyword || tagname}
          stopWord={stopWord}
        />
      );
    }

    return null;
  };

  const renderLiveListItems = () => {
    return liveDisplayList.map((live, index) => {
      return (
        <li key={`search_live-${live.broadNo}-${index}`} data-type='cBox'>
          <ErrorBoundary>
            <BroadcastLayout
              broadcastType='live'
              viewType={BROADCAST_ITEM_DISPLAY_TYPE.LIST_VIEW}
              thumbnailUrl={live.broadImg}
              userId={live.userId}
              userNickname={live.userNick}
              viewCount={live.totalViewCnt}
              startDateTime={live.broadStart}
              title={live.bBroadTitle}
              hasBadge={live.categoryTags || live.hashTags || live.autoHashtags}
              categoryTags={live.categoryTags}
              hashTags={live.hashTags}
              autoHashTags={live.autoHashtags}
              broadcastId={live.broadNo}
              liveType={live.broadType}
              categoryName={
                live.categoryTags && live.categoryTags.length > 0
                  ? live.categoryTags[0]
                  : ''
              }
              broadBps={live.broadBps}
              broadResolution={live.broadResolution}
              allowsAdult={live.broadGrade === '19'}
              searchActionType='live'
              hasPassword={live.isPassword === 'Y'}
              isFan={live.fanFlag === '1'}
              isSubscribe={live.subsFlag === '1'}
              canResend={live.visitType === 'true' || live.visitType === '1'}
              contextMenu={{
                menuList: LIVE_CONTEXT_MENU,
              }}
              log={{
                data: {
                  ...inflowPathLogData,
                  cli_list_data_idx: index + 1,
                },
              }}
              handleAfterLogSend={(params) => {
                handleLogCallback(index, live.broadNo, live.userId, params);
              }}>
              <BroadcastType.Live />
            </BroadcastLayout>
          </ErrorBoundary>
        </li>
      );
    });
  };

  return (
    <>
      {renderLiveSectionHeader()}

      {renderLiveSectionNoResult()}

      {!stopWord && liveList.length > 0 && (
        <>
          <div className='cBox-list'>
            <ul>
              {renderLiveListItems()}
              {(isLivePage || isLiveTagSearchPage) && (
                <div ref={lastPositionRef} />
              )}
            </ul>
          </div>
          {isTotalPage && (
            <ShowMore
              active={live.length > 6}
              name={'더보기'}
              onHanlder={handleShowMore}
            />
          )}
        </>
      )}
      {(isLivePage || isLiveTagSearchPage) && liveLoading && <Loading />}
    </>
  );
};

export default LiveGroup;
