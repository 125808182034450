// Medal 컴포넌트
const Medal = ({medalList, onMedalClick}) => {
  return (
    <dd className='medal_area'>
      <ul className='medal_list simple'>
        {medalList?.slice(0, 5).map((medal, index) => (
          <li key={index}>
            <img src={medal?.simpleUrl} alt={medal?.content} />
          </li>
        ))}
      </ul>
      {/* 메달 노출 최대 5개 */}
      <button type='button' className='more' onClick={onMedalClick}>
        더보기
      </button>
    </dd>
  );
};

export default Medal;
