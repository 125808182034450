import {useEffect, useState, useCallback} from 'react';

import {SoopUiSelectBox} from '@/components/ui';
import {sortStreamers} from '@/utils/main/my/sort';
import {useNavigate} from 'react-router-dom';
import {ROUTE} from '@/constants/main/route';
import {usePrevious} from '@/components/main/common/hooks/usePrevious';
import Streamer from '@/components/my/dialog/Streamer';

export const formatStreamer = (streamer) => ({
  ...streamer,
  nickname: streamer.userNick,
  lastBroadcastDate: streamer.lastBroadStart,
  isAlarm: streamer.isMobilePush === 'Y',
});

const useDebouncedValue = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const StreamerSetting = ({
  options,
  handleAllAlarmChange,
  isAllAlarm,
  handleClose,
  isSubscribeStreamers,
  isFavoriteStreamers,
  isFanclubStreamers,
  allStreamers,
  getStreamers,
}) => {
  const [streamers, setStreamers] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [sortType, setSortType] = useState(options[0].type);
  const [isHideFixed, setIsHideFixed] = useState(false);
  const debouncedKeyword = useDebouncedValue(keyword, 300);

  useEffect(() => {
    getStreamers();
  }, [getStreamers]);

  const navigate = useNavigate();
  const {prev: prevStreamers} = usePrevious(allStreamers);

  useEffect(() => {
    if (prevStreamers?.length > 0 && allStreamers.length === 0) {
      // 모든 스트리머가 즐찾 해제되었다면 전체 탭으로 이동해 줘야 함
      handleClose();
      navigate(ROUTE.MY.FAVORITE);
    }

    if (allStreamers.length > 0) {
      let formattedStreamers = allStreamers.map(formatStreamer);

      if (debouncedKeyword.length) {
        formattedStreamers = formattedStreamers.filter((streamer) =>
          streamer.userNick.includes(debouncedKeyword),
        );
      }

      const sortedStreamers = sortStreamers(formattedStreamers, sortType, {
        fixedOrder: ['isPin'],
      });

      setStreamers(sortedStreamers);
    }
  }, [allStreamers, sortType, debouncedKeyword]);

  const handleKeywordChange = useCallback((event) => {
    setKeyword(event.target.value);
  }, []);

  const handleSortChange = (type) => {
    setSortType(type);
  };

  const handleDeleteKeyword = () => {
    setKeyword('');
  };

  const handleCheckboxChange = () => {
    setIsHideFixed((state) => !state);
  };

  const shownStreamers = isHideFixed
    ? streamers.filter((streamer) => !streamer.isPin)
    : streamers;

  return (
    <div className='layer_container'>
      <h3>
        스트리머 관리
        <span className='total_txt'>({allStreamers.length}명)</span>
      </h3>
      <div className='my_adm_layer'>
        <div className='search_area'>
          <div className='form'>
            <button type='submit' className='submit'>
              검색
            </button>
            <input
              type='text'
              id='search-inp'
              placeholder='스트리머를 검색해 주세요.'
              value={keyword}
              onChange={handleKeywordChange}
              maxLength={50}
            />
            <button
              type='button'
              className='del_text'
              style={{display: keyword.length > 0 ? '' : 'none'}}
              onClick={handleDeleteKeyword}>
              입력내용 삭제
            </button>
          </div>
        </div>
        <div className='strm_area'>
          <div className='total_wrap'>
            <div className='pin_hide'>
              <input
                type='checkbox'
                id='hide'
                checked={isHideFixed}
                onChange={handleCheckboxChange}
              />
              <label htmlFor='hide'>고정 숨기기</label>
            </div>
            <div className='right_area'>
              {handleAllAlarmChange && (
                <div className='alarm_toggle'>
                  <span>전체 알림</span>
                  <label htmlFor='all' className='switch'>
                    <input
                      type='checkbox'
                      id='all'
                      checked={isAllAlarm}
                      onChange={handleAllAlarmChange}
                    />
                    <em />
                  </label>
                </div>
              )}
              {options?.length > 0 && (
                <SoopUiSelectBox
                  options={options}
                  type={sortType}
                  onChange={handleSortChange}
                />
              )}
            </div>
          </div>
          {isHideFixed && shownStreamers.length === 0 ? (
            <div className='noList'>
              <p>고정하지 않은 스트리머가 없습니다.</p>
            </div>
          ) : (
            <ul className='strm_list'>
              {shownStreamers.map((streamer, index) => (
                <Streamer
                  key={streamer.userId}
                  streamerIdx={index}
                  handleClose={handleClose}
                  getStreamers={getStreamers}
                  isSubscribeStreamers={isSubscribeStreamers}
                  isFavoriteStreamers={isFavoriteStreamers}
                  isFanclubStreamers={isFanclubStreamers}
                  sortType={sortType}
                  {...streamer}
                />
              ))}
            </ul>
          )}
        </div>
      </div>
      <button type='button' className='btn_close' onClick={handleClose}>
        레이어 닫기
      </button>
    </div>
  );
};

export default StreamerSetting;
