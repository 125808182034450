import {SoopUiContextmenuRework} from '@/components/ui';
import {MY_HOTISSUE_MENU} from '@/constants/my';
import ProfileGroup from '@/components/my/feed/hotissue/item/ProfileGroup';
import FeedContent from '@/components/my/feed/hotissue/item/FeedContent';
import ActionGroup from '@/components/my/feed/hotissue/item/ActionGroup';
import globalDomains from '@/utils/global-domains';
import MyFeedApi from '@/api/my/feed';
import {throwConfirm} from '@/utils/ui/dialog';

const Feed = (props) => {
  const {rank, userId, userNick, fetchFeedData} = props;
  const handleOnClickMenu = async (e, type, data, buttonRef) => {
    if (type === 'station') {
      window.open(`${globalDomains.BJ_AFREECATV}/${userId}`);
    } else if (type === 'no') {
      const {data: isOk} = await throwConfirm({
        title: `${userNick}님의 게시글을 보지 않으시겠습니까?`,
        message: `실시간 인기글에서 ${userNick}의 게시글이 더는 노출되지 않습니다.`,
      });

      if (!isOk) {
        return;
      }

      try {
        await MyFeedApi.setFeedBlind(userId);
        fetchFeedData();
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <li>
      <em className='rank high'>{rank}위</em>
      <ProfileGroup {...props} />
      <FeedContent {...props} />
      <ActionGroup {...props} />
      <SoopUiContextmenuRework
        className='btn-detail'
        menuList={MY_HOTISSUE_MENU}
        handleMenuClicked={handleOnClickMenu}
      />
    </li>
  );
};

export default Feed;
