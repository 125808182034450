'use client';

import MyProfileImg from '@/components/my/common/item/MyProfileImg';
import globalDomains from '@/utils/global-domains';
import {formatDate} from '@/utils/main';
import {SoopUiSelectBox} from '@/components/ui';
import {useEffect, useState} from 'react';
import MyFavoriteApi from '@/api/my/favorite';
import {throwDialog} from '@/utils/ui/dialog';
import throwToast from '@/utils/ui/toast';
import useMyStore from '@/stores/my';
import MyFanApi from '@/api/my/fan';
import MySubscribeApi from '@/api/my/subscribe';
import {
  sendLiveThumbnailClickLog,
  sendMyStreamerSettingProfileClickLog,
  sendPinClickLog,
} from '@/utils/main/my/log';
import {isEmpty} from 'lodash';

/**
 * 스트리머 컴포넌트
 *
 * @param {Object} props - 컴포넌트 속성
 * @param {boolean} props.isLive - 라이브 여부
 * @param {string} props.userId - BJ 아이디
 * @param {boolean} props.isPin - 고정 여부
 * @param {boolean} props.isFanclub - 팬클럽 여부
 * @param {boolean} props.isSubscribe - 구독 여부
 * @param {boolean} props.isAlarm - 알림 여부
 * @param {string} props.lastBroadcastDate - 최근 방송 날짜
 * @param {string} props.nickname - 닉네임
 * @param {number} props.streamerIdx - 스트리머 인덱스
 * @param {Function} props.handleClose - 팝업 닫기 함수
 * @param {Function} props.getStreamers - 스트리머 목록 가져오기 함수
 * @param {string} props.subNick - 구독 닉네임
 * @param {string} props.defaultNick - 기본 닉네임
 * @param {boolean} props.isSubscribeStreamers - 구독 스트리머 여부
 * @param {boolean} props.isFavoriteStreamers - 즐겨찾기 스트리머 여부
 * @param {boolean} props.isFanclubStreamers - 팬클럽 스트리머 여부
 * @param {string} props.firstPaymentDate - 첫 결제 날짜
 * @param {boolean} props.isFavorite - 즐겨찾기 여부
 * @param {Object} props.broadInfo - 방송 정보
 * @returns JSX.Element
 */
const Streamer = ({
  isLive,
  userId,
  isPin,
  isFanclub,
  isSubscribe,
  isAlarm,
  lastBroadcastDate,
  nickname,
  streamerIdx,
  handleClose,
  getStreamers,
  subNick,
  defaultNick,
  isSubscribeStreamers,
  isFavoriteStreamers,
  isFanclubStreamers,
  firstPaymentDate,
  isFavorite,
  broadInfo,
  firstDate,
  favoriteNo,
  subscribeNo,
  fanclubNo,
  paymentCount,
}) => {
  const [isAlarmOn, setIsAlarmOn] = useState(isAlarm);
  const [selectedOption, setSelectedOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [isFavoriteOn, setIsFavoriteOn] = useState(isFavorite);
  const [isPinOn, setIsPined] = useState(isPin);

  const {
    getStreamers: getFavoriteStreamers,
    getFanclubStreamers,
    getSubscribeStreamers,
  } = useMyStore();

  const getStoreStreamers = async () => {
    if (isFavoriteStreamers) {
      return getFavoriteStreamers();
    }

    if (isSubscribeStreamers) {
      return getSubscribeStreamers();
    }

    if (isFanclubStreamers) {
      return getFanclubStreamers();
    }
  };

  useEffect(() => {
    if (isFavoriteStreamers) {
      initializeFavoriteOptions();
    }

    if (isSubscribeStreamers) {
      initializeSubscribeOptions();
    }

    if (isFanclubStreamers) {
      initializeFanclubOptions();
    }
  }, [
    isAlarmOn,
    isFavoriteOn,
    isPinOn,
    isFavoriteStreamers,
    isSubscribeStreamers,
    isFanclubStreamers,
  ]);

  useEffect(() => {
    if (isFavoriteStreamers) {
      setIsAlarmOn(isAlarm);
      initializeFavoriteOptions();
    }
  }, [isAlarm]);

  const initializeOptions = (options) => {
    setSelectedOption(options[0].type);
    setOptions(options);
  };

  const initializeFavoriteOptions = () => {
    initializeOptions([
      {
        label: '즐겨찾기 그룹에 담기',
        type: 'add_favorite_group',
        className: 'fav_group_add',
      },
      {
        label: isPinOn ? '고정 해제하기' : '고정하기',
        type: isPinOn ? 'pin_off' : 'pin_on',
        className: isPinOn ? 'pin_off' : 'pin_on',
      },
    ]);
  };

  const initializeSubscribeOptions = () => {
    initializeOptions([
      {
        label: '구독중',
        type: 'subscribe_on',
        isPlaceholder: true,
        className: 'subscribe_on',
      },
      {
        label: '구독 닉네임 변경',
        type: 'edit',
        className: 'edit',
      },
      {
        label: '구독 결제정보',
        type: 'subscribe_info',
        className: 'subscribe_info',
      },
      {
        label: isPinOn ? '고정 해제하기' : '고정하기',
        type: isPinOn ? 'pin_off' : 'pin_on',
        className: isPinOn ? 'pin_off' : 'pin_on',
      },
    ]);
  };

  const initializeFanclubOptions = () => {
    initializeOptions([
      {
        label: isPinOn ? '고정 해제하기' : '고정하기',
        type: isPinOn ? 'pin_off' : 'pin_on',
        className: isPinOn ? 'pin_off' : 'pin_on',
      },
    ]);
  };

  const handleApiRequest = async (apiCall, successMessage, toggleState) => {
    try {
      await apiCall();
      await getStreamers();

      sendPinClickLog({
        isAddPin: !isPin,
        streamerId: userId,
      });

      if (toggleState) {
        toggleState((prev) => !prev);
      }
      throwToast(successMessage);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAlarmChange = async () => {
    handleApiRequest(
      () => MyFavoriteApi.setPush(userId, !isAlarmOn),
      `${nickname}의 알림을 ${isAlarmOn ? '받지 않습니다' : '받습니다'}.`,
      setIsAlarmOn,
    );
  };

  const handleAddFavoriteGroup = () => {
    throwDialog(
      'my/FavoriteGroup',
      {
        userId,
        streamerIdx,
        streamerId: userId,
        handleClose,
        streamerInfo: {
          userId: userId,
          userNick: nickname,
        },
      },
      {
        title: '즐겨찾기 그룹',
        customClassName: 'list_add_layer',
        titleAlign: 'left',
      },
    );
  };

  const handleDeleteFavorite = async () => {
    handleApiRequest(
      () => MyFavoriteApi.deleteFavorite(userId),
      '즐겨찾기에서 삭제됐습니다.',
      setIsFavoriteOn,
    );
  };

  const handleSubscribeInfoOpen = () => {
    window.open(
      `${globalDomains.POINT_AFREECATV}/Subscription/SubscriptionDtl.asp?userid=${userId}&ruserid=${userId}`,
      'subscriptiondtl',
      'width=460,height=390,scrollbars=no,resizable=no,status=no,menubar=no,toolbar=no',
    );
  };

  const handleAddFavorite = async () => {
    handleApiRequest(
      () => MyFavoriteApi.addFavorite(userId),
      '즐겨찾기에 추가됐습니다.',
      setIsFavoriteOn,
    );
  };

  const handlePinChange = async (isAdding) => {
    const apiCalls = {
      fanclub: isAdding ? MyFanApi.setPin : MyFanApi.deletePin,
      subscribe: isAdding ? MySubscribeApi.setPin : MySubscribeApi.deletePin,
      favorite: isAdding ? MyFavoriteApi.setPin : MyFavoriteApi.deletePin,
    };

    const apiCall = isFanclubStreamers
      ? apiCalls.fanclub(fanclubNo)
      : isSubscribeStreamers
        ? apiCalls.subscribe(subscribeNo)
        : apiCalls.favorite(favoriteNo);

    handleApiRequest(
      () => apiCall,
      `목록 상단 고정 ${isAdding ? '완료' : '해제'} 되었습니다.`,
      setIsPined,
    );
  };

  const handleOptionChange = async (type) => {
    const optionHandlers = {
      alarm_off: handleAlarmChange,
      alarm_on: handleAlarmChange,
      add_favorite_group: handleAddFavoriteGroup,
      delete_favorite: handleDeleteFavorite,
      edit: () =>
        throwDialog(
          'my/NicknameEdit',
          {
            bjId: userId,
            defaultNick,
            getStreamers,
            initialNickname: subNick || defaultNick,
          },
          {
            title: '구독 닉네임 변경',
            customClassName: 'nickname_layer',
            titleAlign: 'left',
          },
        ),
      subscribe_info: handleSubscribeInfoOpen,
      add_favorite: handleAddFavorite,
      pin_off: () => handlePinChange(false),
      pin_on: () => handlePinChange(true),
    };

    if (optionHandlers[type]) {
      optionHandlers[type]();
    }
  };

  const link = isLive
    ? broadInfo[0].url
    : `${globalDomains.BJ_AFREECATV}/${userId}`;

  const handleProfileClick = () => {
    // 스트리머 관리 레이어 > 프로필 클릭 로그
    sendMyStreamerSettingProfileClickLog(isLive);

    if (isLive && !isEmpty(broadInfo?.[0])) {
      const broadcast = broadInfo[0];
      const {broadCateNo, broadNo, userId, broadBps, broadResolution} =
        broadcast;

      // 라이브 방송일 때 프로필 클릭 로그
      sendLiveThumbnailClickLog({
        streamerId: userId,
        broadcastId: broadNo,
        broadCateNo,
        broadBps,
        broadResolution,
      });
    }
  };

  return (
    <li className={isLive ? 'live' : ''}>
      <div className='thumb'>
        <a href={link} target='_blank' onClick={handleProfileClick}>
          <MyProfileImg userId={userId} />
        </a>
        {isPinOn && <span className='pin'>고정</span>}
      </div>

      <div className='nick_wrap'>
        <a
          href={`${globalDomains.BJ_AFREECATV}/${userId}`}
          className='nick'
          target='_blank'>
          <span>{nickname}</span>
          {!isSubscribeStreamers && isSubscribe && (
            <span className='grade-badge-subscribe' tip='구독'>
              구독
            </span>
          )}
          {!isFanclubStreamers && isFanclub && (
            <span className='grade-badge-fan' tip='팬클럽'>
              F
            </span>
          )}
        </a>

        {/* 구독 스트리머 정보 */}
        {isSubscribeStreamers && (
          <>
            <div className='subscribe_nick'>
              <span>구독 닉네임 :</span>
              <em>{subNick || defaultNick}</em>
            </div>
            <div className='subscribe_date'>
              구독일 :
              <em>{formatDate(firstPaymentDate, 'yyyy-MM-dd HH:mm')}</em>
              <em className='total_months'>({paymentCount}개월)</em>
            </div>
          </>
        )}

        {/* 팬클럽 스트리머 정보 */}
        {isFanclubStreamers && (
          <>
            <div className='fan_date'>
              팬클럽 가입일 :<em> {formatDate(firstDate, 'yyyy-MM-dd')}</em>
            </div>
            <span className='last_live'>
              최근 방송 :
              <em> {formatDate(lastBroadcastDate, 'yyyy-MM-dd HH:mm', '-')}</em>
            </span>
          </>
        )}

        {/* 즐겨찾기 스트리머 정보 */}
        {isFavoriteStreamers && (
          <span className='last_live'>
            최근 방송 :
            <em> {formatDate(lastBroadcastDate, 'yyyy-MM-dd HH:mm', '-')}</em>
          </span>
        )}
      </div>

      <div className='util_btn_wrap'>
        {isFavoriteStreamers && (
          <button
            type='button'
            className={isAlarmOn ? 'alarm_on' : 'alarm_off'}
            onClick={handleAlarmChange}>
            알림 끄기
          </button>
        )}
        <button
          type='button'
          className={isFavoriteOn ? 'fav_on' : 'fav_off'}
          onClick={isFavoriteOn ? handleDeleteFavorite : handleAddFavorite}>
          즐겨찾기 삭제
        </button>
      </div>
      <SoopUiSelectBox
        options={options}
        type={selectedOption}
        onChange={handleOptionChange}
        isSelectedOptionHighlight={false}
        toggleComponent={
          <div className='select_area'>
            <button type='button' className='more_dot'>
              더보기 메뉴
            </button>
          </div>
        }
      />
    </li>
  );
};

export default Streamer;
