import {useState, useEffect} from 'react';

export const useVisibilityChange = () => {
  const [isGlobalVisible, setIsGlobalVisible] = useState(!document.hidden);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsGlobalVisible(!document.hidden);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return {
    isGlobalVisible,
  };
};
