'use client';

import MyFanApi from '@/api/my/fan';
import {useEffect, useState} from 'react';
import MyBroadcastLayout from '../common/MyBroadcastLayout';
import {ErrorBoundary} from '@/components/common';
import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';
import {BROADCAST_TYPES} from '@/constants';
import {CONTEXT_MENU} from '@/constants/my/contextMenu';

export const getContextMenu = (fileType) => {
  return fileType === 'CATCH' ? CONTEXT_MENU.CATCH : CONTEXT_MENU.VOD;
};

/**
 * 팬클럽 BJ VOD 목록 컴포넌트
 * @desc API 로부터 VOD를 가져오고 필드명을 맞춰서 MyCatchGroup 컴포넌트에 전달한다.
 * @returns {JSX.Element} VOD
 */
const MyFanclubVod = () => {
  const [vods, setVods] = useState([]);

  const getVod = async () => {
    try {
      const vod = await MyFanApi.getVod();
      setVods(vod);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getVod();
  }, []);

  return (
    <MyBroadcastLayout type='swiper'>
      {vods.map((broadcast) => (
        <ErrorBoundary key={broadcast.titleNo}>
          <BroadcastLayout
            broadcastType={BROADCAST_TYPES.VOD}
            thumbnailUrl={broadcast.thumbnail}
            viewCount={broadcast.readCnt}
            createdDate={broadcast.regDate}
            startDateTime={broadcast.broadStart}
            authNumber={broadcast.authNo}
            userNickname={broadcast.userNick}
            title={broadcast.titleName}
            broadcastId={broadcast.titleNo}
            userId={broadcast.userId}
            hasBadge={
              broadcast.categoryTags ||
              broadcast.hashTags ||
              broadcast.autoHashtags
            }
            categoryTags={broadcast.categoryTags}
            hashTags={broadcast.hashTags}
            autoHashTags={broadcast.autoHashtags}
            vodType={broadcast.fileType}
            categoryNo={Number(broadcast.category)}
            accessibleAge={Number(broadcast.grade)}
            allowsAdult={broadcast.isAdult}
            isFan={broadcast.isFanclub}
            isSubscribe={broadcast.isSubscribe}
            duration={broadcast.duration}
            previewThumbnailUrl={broadcast.webpPath}
            contextMenu={{
              menuList: getContextMenu(broadcast.fileType),
            }}>
            <BroadcastType.Vod />
          </BroadcastLayout>
        </ErrorBoundary>
      ))}
    </MyBroadcastLayout>
  );
};

export default MyFanclubVod;
