'use client';
import {useEffect, useState} from 'react';
import SearchApi from '@/api/search/search';
import SearchHome from '@/components/search/layout';
import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';
import {getClassName, setTagRouteUrl} from '@/constants/search/tab';
import dayjs from 'dayjs';

const Search = () => {
  // 검색 페이지내 사용할 전역 변수
  const {
    initFilterOption,
    resetPageNumber,
    currentPageNumber,
    perPage,
    gameProfileOrder,
    gameProfileSize,
    liveOrder,
    vodOrder,
    vodDetailOrder,
    postOrder,
    postDetailOrder,
    streamerOrder,
    setLoading,
    setNoResult,
    onHasMore,
    setActiveTab,
    changeStatus,
    sessionKey,
    setSessionKey,
    isTotalPage,
    isLivePage,
    isVodPage,
    isPostPage,
    isStreamerPage,
    isLiveTagSearchPage,
    isVodTagSearchPage,
  } = useSearchGlobalStatus();

  const {getParams} = useUrlParameter();
  // 동적으로 바뀌는 parameter들
  const {
    szKeyword: keyword = '',
    szSearchType: searchType = '',
    szLocation = '',
    stype = '',
    szStype = '',
    hash = '',
    hashtype: hashType = '',
    tagname = '',
    location = '',
    acttype = '',
    szActype = '',
    has_hint = '',
    pk_cnt = '',
    szOriginalKeyword = '',
    szModifiedKeyword = '',
  } = getParams();
  const [enteredKeyword, setEnteredKeyword] = useState(true);
  const [vodList, setVodList] = useState([]);
  const [catchList, setCatchList] = useState([]);
  const [catchStoryList, setCatchStoryList] = useState([]);
  const [liveList, setLiveList] = useState([]);
  const [postList, setPostList] = useState([]);
  const [streamerList, setStreamerList] = useState([]);
  const [profileThemeList, setProfileThemeList] = useState({});
  const [stopWordList, setStopWordList] = useState({});
  const [isStop, setIsStop] = useState('1');
  const [relatedSearchWords, setRelatedSearchWords] = useState([]);

  // 연관 검색 API 호출
  const fetchRelatedSearchTermsData = async () => {
    const {relatedSearchTerms = []} = await SearchApi.getUnifiedSearch({
      keyword: encodeURIComponent(keyword),
      sessionKey: sessionKey,
    });
    setRelatedSearchWords(relatedSearchTerms);
  };

  // 금칙어 API 호출 - 제일 먼저 호출 되야 하는 api
  const fetchStopWord = async () => {
    try {
      setLoading('stopWord', true);
      const inputFieldTypes = ['di', 'ac', 'rt', 'rk', 'lc', 'ad', 'pk'];
      const response = await SearchApi.getStopWord({
        keyword:
          encodeURIComponent(keyword) ?? encodeURIComponent(tagname) ?? '',
        location: szLocation || location || 'etc',
        pathSearch: stype,
        szStype: szStype,
        acttype: acttype
          ? acttype
          : inputFieldTypes.includes(szStype) || stype === 'di'
            ? 'input_field'
            : '',
        ac_type: szStype === 'ac' ? szActype : '',
        originalKeyword: encodeURIComponent(szOriginalKeyword),
        modifiedKeyword: encodeURIComponent(szModifiedKeyword),
        pk_cnt,
        has_hint,
        tagname,
      });

      const {
        modified = '',
        origin = '',
        stopword = '',
        isstop = '',
        postpos = '로',
        sessionKey: _sessionkey = '',
      } = response || {};

      setStopWordList((prevStopWordList) => {
        return {
          ...prevStopWordList,
          modified: modified,
          origin: origin,
          stopword: stopword,
          isstop: isstop,
          postpos: postpos,
        };
      });

      setIsStop(isstop);
      setSessionKey(_sessionkey);
    } catch (error) {
      console.error('Failed to fetch stop words:', error);
    } finally {
      setLoading('stopWord', false);
    }
  };

  // 프로필 테마 API 호출
  const fetchProfileThemeData = async (
    gameProfileMaxSize,
    pttype,
    gameScheduleDate,
  ) => {
    try {
      const {
        advertisement = {},
        recommendContents = [],
        homonym = [],
        gameProfile = [],
        esport = [],
        profile = [],
        latestVod = [],
        recommendBj = [],
        relatedBj = [],
        theme = [],
        themeShortcuts = [],
        themeBj = [],
        themeVod = [],
      } = await SearchApi.getProfileTheme({
        sessionKey: sessionKey,
        keyword: encodeURIComponent(keyword),
        pttype: pttype || 'all',
        order: gameProfileOrder
          ? gameProfileOrder[0].value
          : 'current_sum_viewer',
        orderBy: gameProfileOrder ? gameProfileOrder[0].orderType : 'desc',
        date: gameScheduleDate || dayjs().format('YYYY-MM-DD'),
        size: gameProfileMaxSize || gameProfileSize,
      });

      // 게임프로필 > 관련스트리머 영역 필터정렬이 변경되는 경우에 다시 size 10개로 요청해달라고해서 아래코드 일단주석
      // 일단 어디서 쓰일지 모르니 && 나중에 또 말이 달라질 수 있으니
      // if (gameProfileMaxSize) {
      //   setGameProfileSize(gameProfileMaxSize);
      // }

      return {
        advertisement,
        recommendContents,
        homonym,
        gameProfile,
        esport,
        profile,
        latestVod,
        recommendBj,
        relatedBj,
        theme,
        themeShortcuts,
        themeBj,
        themeVod,
      };
    } catch (error) {
      console.error('Failed to fetch profile theme data:', error);
    }
  };

  const fetchAndBindProfileThemeData = async () => {
    const profileTheme = await fetchProfileThemeData();

    setProfileThemeList((prevProfileThemeList) => {
      return {
        ...prevProfileThemeList,
        ...profileTheme,
      };
    });
  };

  // 게임 프로필 영역 - 관련 스트리머 추가 로드를 위함
  const fetchGameProfileThemeStreamerList = async (gameProfileMaxSize) => {
    const {gameProfile} = await fetchProfileThemeData(gameProfileMaxSize);

    const oldGameProfileStreamerList =
      profileThemeList.gameProfile?.[0]?.bj || [];
    const newGameProfileStreamerList = gameProfile?.[0]?.bj || [];

    const gameProfileStreamerList = newGameProfileStreamerList.filter(
      (newStreamer) =>
        !oldGameProfileStreamerList.some(
          (oldStreamer) => oldStreamer.userId === newStreamer.userId,
        ),
    );
    return gameProfileStreamerList;
  };

  // 게임 프로필 영역 - 경기 일정 데이터 로드를 위함
  const fetchGameScheduleList = async (pttype, gameScheduleDate) => {
    const {esport} = await fetchProfileThemeData('', pttype, gameScheduleDate);
    return esport || [];
  };

  // live API 호출
  const fetchLiveData = async () => {
    try {
      setLoading('live', true);
      if (liveOrder) {
        let searchKeyword = tagname !== '' ? tagname : keyword;
        const {realBroad = [], hasMoreList} = await SearchApi.getLiveSearch({
          sessionKey: sessionKey,
          tagname: tagname,
          keyword: encodeURIComponent(searchKeyword),
          pageNo: isTotalPage ? 1 : currentPageNumber['broad'],
          perPage: isLivePage || isLiveTagSearchPage ? 40 : 12,

          order: liveOrder[0]?.value,
          orderType: liveOrder[0]?.orderType,
          tab: isLivePage || isLiveTagSearchPage ? 'live' : 'total',
          location: isLiveTagSearchPage ? 'hash' : 'total_search',
        });
        onHasMore({live: hasMoreList});
        setLiveList(realBroad);

        if (realBroad.length < 1) {
          setNoResult('live', true);
        } else {
          setNoResult('live', false);
        }
      }
    } catch (error) {
      console.error('Failed to fetch live data:', error);
    } finally {
      setLoading('live', false);
    }
  };

  // VOD API 호출
  const fetchVodData = async () => {
    try {
      setLoading('vod', true);
      // VOD 리스트 API 호출
      if (vodOrder || vodDetailOrder) {
        let searchKeyword = isVodTagSearchPage ? tagname : keyword;

        let order = 'score';
        let term = '';
        let fileType = '';

        if (isVodTagSearchPage) {
          order =
            vodDetailOrder.length > 0 ? vodDetailOrder[0]?.value : 'view_cnt';
          term = vodDetailOrder.length > 0 ? vodDetailOrder[2]?.value : '1week';
          fileType =
            vodDetailOrder.length > 0 ? vodDetailOrder[1]?.value : 'ALL';
        } else if (isTotalPage) {
          order = vodOrder[0]?.value || 'score';
          term = '1year';
        } else {
          order =
            vodDetailOrder.length > 0 ? vodDetailOrder[0]?.value : 'score';
          term = vodDetailOrder.length > 0 ? vodDetailOrder[2]?.value : '1year';
          fileType =
            vodDetailOrder.length > 0 ? vodDetailOrder[1]?.value : 'ALL';
        }

        const {
          data: vodData = [],
          catchData = [],
          catchStoryData = [],
          hasMoreList,
        } = await SearchApi.getVodSearch({
          sessionKey: sessionKey,
          tagname: tagname,
          keyword: encodeURIComponent(searchKeyword),
          pageNo: isTotalPage ? 1 : currentPageNumber['vod'],
          perPage: isVodPage || isVodTagSearchPage ? 40 : 12,
          order: order,
          fileType: fileType,
          term: term,
          searchType,
          tab: isVodPage || isVodTagSearchPage ? 'vod' : 'total',
          location: isVodTagSearchPage ? 'hash' : 'total_search',
        });
        onHasMore({vod: hasMoreList});

        let resultVod = vodData === null ? [] : vodData;
        if (resultVod.length < 1) {
          setNoResult('vod', true);
        } else {
          setNoResult('vod', false);
        }
        if (isTotalPage) {
          setVodList(resultVod);
          setCatchList(catchData || []);
          setCatchStoryList(catchStoryData || []);
        } else if (isVodPage || isVodTagSearchPage) {
          setVodList(resultVod);
        }
      }
    } catch (error) {
      console.error('Failed to fetch vod data:', error);
    } finally {
      setLoading('vod', false);
    }
  };

  // 게시글 API 호출
  const fetchPostData = async () => {
    setLoading('post', true);

    try {
      if (postOrder || postDetailOrder) {
        const {data: postData = [], totalCnt = 0} =
          await SearchApi.getPostSearch({
            sessionKey: sessionKey,
            keyword: encodeURIComponent(keyword),
            pageNo: currentPageNumber[searchType],
            perPage: isTotalPage ? 12 : 40,
            order: isTotalPage
              ? postOrder[0]?.value
              : postDetailOrder[0]?.value || 'view_cnt',
            termType: isTotalPage
              ? '1month'
              : postDetailOrder[1]?.value || '1month',
            tab: isTotalPage ? 'total' : 'post',
          });

        if (postData.length > 0) {
          setNoResult('post', false);
          onHasMore({
            post:
              currentPageNumber[searchType] * (isTotalPage ? 12 : 40) <
              totalCnt,
          });
        } else {
          setNoResult('post', true);
          onHasMore({post: false});
        }

        setPostList(postData);
      }
    } catch (error) {
      console.error('Error fetching post data:', error);
    } finally {
      setLoading('post', false);
    }
  };

  // 스트리머 API 호출
  const fetchStreamerData = async () => {
    setLoading('streamer', true);
    let totalPageOrder = '';
    if (!streamerOrder) {
      totalPageOrder = [{label: '정확도', type: 'score', value: 'score'}];
    }
    if (streamerOrder || totalPageOrder) {
      try {
        const {data: streamerData = [], totalCnt = 0} =
          await SearchApi.getStreamerSearch({
            sessionKey: sessionKey,
            keyword: encodeURIComponent(keyword),
            pageNo: isTotalPage ? 1 : currentPageNumber[searchType],
            perPage: isTotalPage ? 24 : 40,
            order: streamerOrder[0]?.value || totalPageOrder[0]?.value,
            tab: isTotalPage ? 'total' : 'bj',
          });

        if (streamerData.length > 0) {
          setNoResult('streamer', false);
          onHasMore({
            streamer:
              currentPageNumber[searchType] * (isTotalPage ? 12 : 40) <
              totalCnt,
          });
        } else {
          setNoResult('streamer', true);
          onHasMore({streamer: false});
        }
        setStreamerList(streamerData);
      } catch (error) {
        console.error('Error fetching streamer data:', error);
      } finally {
        setLoading('streamer', false);
      }
    }
  };

  useEffect(() => {
    const newTab = isLiveTagSearchPage ? 'broad' : hashType || searchType;
    if (newTab) {
      setActiveTab(newTab);
    }
    window.scrollTo(0, 0);

    // 금칙어 검사
    if (enteredKeyword && (keyword || tagname)) {
      fetchStopWord();
    }
    setEnteredKeyword(false);

    // 통합검색 각 섹션 필터옵션 초기화
    if (isTotalPage) {
      resetPageNumber('all');
      changeStatus('live', perPage, initFilterOption.live);
      changeStatus('vod', 12, initFilterOption.vod);
      changeStatus('post', 12, initFilterOption.post);
      changeStatus('streamer', perPage, initFilterOption.streamer);
    }

    // LIVE탭 필터옵션 초기화
    if (isLivePage) {
      changeStatus('live', perPage, initFilterOption.live);
    }

    // 해시태그 검색시 필터 옵션 초기화
    if (isLiveTagSearchPage) {
      changeStatus('live', perPage, initFilterOption.liveHash);
    }

    // VOD탭 필터옵션 초기화
    if (isVodPage || isVodTagSearchPage) {
      changeStatus(
        'vod',
        40,
        isVodPage ? initFilterOption.vodTab : initFilterOption.vodHash,
        true,
      );
    }

    // 게시글탭 필터옵션 초기화
    if (isPostPage) {
      changeStatus('post', perPage, initFilterOption.postTab, true);
    }

    // 스트리머탭 필터옵션 초기화
    if (isStreamerPage) {
      changeStatus('streamer', 40, initFilterOption.streamer);
    } else {
      setStreamerList([]);
    }
  }, [searchType, hashType]);

  useEffect(() => {
    if (isStop === '0' && searchType) {
      fetchRelatedSearchTermsData();
    }
  }, [sessionKey, searchType]);

  // 프로필 테마 API 호출
  useEffect(() => {
    if (isTotalPage && isStop === '0' && sessionKey !== null) {
      fetchAndBindProfileThemeData().then();
    }
  }, [sessionKey, searchType, gameProfileOrder]);

  // live API 호출
  useEffect(() => {
    if (
      (isTotalPage || isLivePage || isLiveTagSearchPage) &&
      isStop === '0' &&
      sessionKey !== null
    ) {
      fetchLiveData();
    }
  }, [sessionKey, currentPageNumber['broad'], liveOrder, isStop]);

  // VOD API 호출
  useEffect(() => {
    if (
      (isTotalPage || isVodPage || isVodTagSearchPage) &&
      isStop === '0' &&
      sessionKey !== null
    ) {
      fetchVodData();
    }
  }, [sessionKey, currentPageNumber['vod'], vodOrder, vodDetailOrder, isStop]);

  // 게시글 API 호출
  useEffect(() => {
    if ((isTotalPage || isPostPage) && isStop === '0' && sessionKey !== null) {
      fetchPostData();
    }
  }, [
    sessionKey,
    currentPageNumber['post'],
    postOrder,
    postDetailOrder,
    isStop,
  ]);

  // 스트리머 API 호출
  useEffect(() => {
    if (
      (isTotalPage || isStreamerPage) &&
      isStop === '0' &&
      sessionKey !== null
    ) {
      fetchStreamerData();
    }
  }, [sessionKey, currentPageNumber['streamer'], isStop, streamerOrder]);

  return (
    <SearchHome
      className={getClassName(hash, hashType, searchType)}
      currentTab={setTagRouteUrl(
        searchType,
        tagname ? tagname : keyword,
        stype || szStype || 'di',
        acttype,
        'total_search',
        szActype,
        has_hint,
        pk_cnt,
      )}
      keyword={keyword}
      searchType={searchType}
      hashType={isLiveTagSearchPage ? 'live' : hashType}
      live={liveList}
      vod={vodList}
      catchList={catchList}
      catchStory={catchStoryList}
      postList={postList}
      streamerList={streamerList}
      profileTheme={profileThemeList}
      relatedWord={relatedSearchWords}
      stopWord={stopWordList}
      tagname={tagname}
      fetchGameProfileThemeStreamerList={fetchGameProfileThemeStreamerList}
      fetchGameScheduleList={fetchGameScheduleList}
    />
  );
};

export default Search;
