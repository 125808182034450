import MyApi from '@/api/my';
import throwToast from '@/utils/ui/toast';

/**
 * @typedef {Object} HotissueData
 * @property {number} rank 순위
 * @property {string} feedType 게시물 타입
 * @property {number} titleNo 제목 번호
 * @property {number} stationNo 채널 번호
 * @property {string} stationUserId 채널 사용자 ID
 * @property {string} stationUserNick 채널 사용자 닉네임
 * @property {string} stationProfileImage 채널 프로필 이미지
 * @property {number} bbsNo 채널 번호
 * @property {number} authNo 인증 번호
 * @property {number} boardType 게시판 타입
 * @property {string} titleName 제목 이름
 * @property {string} userNick 사용자 닉네임
 * @property {string} userId 사용자 ID
 * @property {string} profileImage 프로필 이미지
 * @property {number} photoCnt 사진 개수
 * @property {number} platformType 플랫폼 타입
 * @property {boolean} isNotice 공지 여부
 * @property {boolean} commentYn 댓글 여부
 * @property {boolean} livepostYn 라이브 포스트 여부
 * @property {boolean} shareYn 공유 여부
 * @property {string} ip IP 주소
 * @property {string} regDate 등록 날짜
 * @property {string} content 내용
 * @property {boolean} contentYn 내용 여부
 * @property {Object} count 카운트 정보
 * @property {number} count.likeCnt 좋아요 개수
 * @property {number} count.readCnt 조회수
 * @property {number} count.commentCnt 댓글 수
 * @property {number} count.uccFavoriteCnt 즐겨찾기 수
 * @property {Object[]} photos 사진 리스트
 * @property {string} url URL 주소
 * @property {string} scheme 스킴
 * @property {string|null} hashtags 해시태그
 * @property {boolean} isLike 좋아요 여부
 * @property {boolean} isBlind 게시물 안보기 여부
 */
const MyFeedApi = {
  /**
   * 핫이슈 Feed 데이터 가져오기
   */
  async getFeedHotissue() {
    try {
      const data = await MyApi.get('/api/hotissue/popular');
      return data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 피드 좋아요 추가
   * @param {string} userId - 좋아요할 사용자 ID
   * @param {string} titleNo - 제목 ID
   * @returns {Promise<Object>}
   */

  async setFeedLike(userId, titleNo) {
    const formData = new FormData();
    formData.append('_method', 'post');

    try {
      const {message: response} = await MyApi.post(
        `/api/feed/like/${userId}/${titleNo}`,
        formData,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 피드 좋아요 삭제
   * @param {string} userId - 좋아요 삭제할 사용자 ID
   * @param {string} titleNo - 제목 ID
   * @returns {Promise<Object>}
   */

  async setFeedUnLike(userId, titleNo) {
    const formData = new FormData();
    formData.append('_method', 'delete');

    try {
      const {message: response} = await MyApi.post(
        `/api/feed/like/${userId}/${titleNo}`,
        formData,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 피드 게시글 안보기 설정
   * @param {string} userId - 피드 게시글 안보기 설정할 사용자 ID
   * @returns {Promise<Object>}
   */

  async setFeedBlind(userId) {
    const formData = new FormData();
    formData.append('blind_id', userId);

    try {
      const {message} = await MyApi.post(
        `/api/hotissue/popular/blind`,
        formData,
      );

      return throwToast(message);
    } catch (error) {
      throw error;
    }
  },

  /**
   * 피드 게시글 안보기 해제
   * @param {string} userId - 피드 게시글 안보기 해제할 사용자 ID
   * @returns {Promise<Object>}
   */

  async setFeedUnBlind(userId) {
    const formData = new FormData();
    formData.append('_method', 'delete');

    try {
      const {message} = await MyApi.post(
        `/api/hotissue/popular/blind/${userId}`,
        formData,
      );
      return throwToast(message);
    } catch (error) {
      throw error;
    }
  },
  /**
   * 핫이슈 VOD 데이터 가져오기
   */
  async getHotissueVod() {
    try {
      const {data: data} = await MyApi.get(`/api/hotissue/popular/vod`);
      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default MyFeedApi;
