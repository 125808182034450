'use client';
import MyPlusApi from '@/api/main/my-plus';
import {FloatingGroup} from '@/components/main/MyPlus/common/FloatingGroup';
import {
  showMainBanner,
  getLocalStorage,
  getUserCookie,
  isLogin,
  setLocalStorage,
} from '@/utils/main';
import {useEffect, useState} from 'react';
import {Popular} from '@/components/main/MyPlus/Popular';
import {Prefer} from '@/components/main/MyPlus/Prefer';
import useMainStore from '@/stores/main';
import {useResizeWidth} from '../common/hooks/useResizeWidth';

//선호/인기 mode 인지 확인 후, 값이없다면 로그인 여부에따라서 default 값 셋팅
const getInitialSelectedMode = () => {
  try {
    let mode = getLocalStorage('selectedMode', undefined);
    if (mode === undefined) {
      mode = 'prefer';
    }
    setLocalStorage('selectedMode', mode);

    return mode;
  } catch (error) {
    return 'popular';
  }
};

export const MyPlusMain = () => {
  const [selectedMode, setSelectedMode] = useState(getInitialSelectedMode());
  const [mainListInfo, setMainListInfo] = useState({});
  const [broadList, setBroadList] = useState({});
  const {
    checkAd,
    topLiveList,
    isShowMainBanner,
    isCloseMainBanner,
    setTopLiveList,
    setIsShowMainBanner,
    setIsLoadingPlayerArea,
    setIsCloseMainBanner,
  } = useMainStore();
  const userAge = getUserCookie('age');
  const handleOnClickMode = (mode) => {
    setLocalStorage('selectedMode', mode);
    setSelectedMode(mode);
  };

  useEffect(() => {
    getStaticBroadListMainInfo();
  }, []);

  useEffect(() => {
    // 숲네이게이션으로부터 로드된 값 받아옴
    // 어드민에서 메인 배너 노출 여부 확인
    if (checkAd.banners?.bAdPcMainBanner) {
      // 새로운 광고가 있는지 확인
      if (checkAd.hasNewAd) {
        // 메인 배너가 노출 중이지 않고, 닫히지 않은 경우
        if (!isShowMainBanner && !isCloseMainBanner) {
          const isShow = showMainBanner(checkAd.banners?.bAdPcMainBannerCount);
          // 메인 플로팅 배너 노출
          if (isShow) {
            setIsShowMainBanner(isShow);
          } else {
            // 메인 배너는 호출하지 않았지만 비쥬얼영역내 배너는 호출해서 노출
            setIsLoadingPlayerArea(false);
            setIsCloseMainBanner(true);
          }
        }
      } else {
        if (!isShowMainBanner) {
          setIsCloseMainBanner(true);
        }
        setIsLoadingPlayerArea(false);
      }
    } else {
      // 메인 배너가 비활성화 된 경우
      setIsShowMainBanner(false);
      setIsLoadingPlayerArea(false);
    }
  }, [checkAd]);

  useEffect(() => {
    if (isLogin() && selectedMode === 'prefer') {
      getPreferLiveVodList();
    } else {
      getPopularLiveList();
    }
    window.scrollTo(0, 0);
    //selectedMode 가바뀔때 상단 스트리밍영역 초기화 -> state갱신이 느려지거나 하면 다른값의 이전값의 방송을 재생시킨다,,,
    setTopLiveList({
      ...topLiveList,
      count: 0,
      list: [],
    });
  }, [selectedMode]);

  //선호 데이터 가져오는 api
  const getPreferLiveVodList = async () => {
    try {
      const nextRelationType = getLocalStorage('nextRelationType', '');
      const queryParams = {
        szRelationType: nextRelationType,
      };
      const response = await MyPlusApi.getPreferLiveVodList(queryParams);
      if (response) {
        setLocalStorage('nextRelationType', response.nextRelationType);
        setBroadList(response);
      }
    } catch (error) {
      setBroadList({});
    }
  };

  //인기 데이터 가져오는 api
  const getPopularLiveList = async () => {
    try {
      // 성인인증 완료면 19세 방송이 포함된 리스트 호출
      if (userAge >= 19) {
        const response = await MyPlusApi.getStaticBroadListWithAdult();
        setBroadList(response);
      } else {
        const response = await MyPlusApi.getStaticBroadList();
        setBroadList(response);
      }
    } catch (error) {
      setBroadList({});
    }
  };

  //정적 데이터 가져오는 api
  const getStaticBroadListMainInfo = async () => {
    try {
      const response = await MyPlusApi.getStaticBroadListMainInfo();
      if (response) {
        setMainListInfo(response);
      }
    } catch (error) {
      setMainListInfo({});
    }
  };

  useResizeWidth();
  return (
    <div id='container'>
      {selectedMode === 'prefer' && isLogin() ? (
        <Prefer
          response={broadList}
          selectedMode={selectedMode}
          mainListInfo={mainListInfo}
        />
      ) : (
        <Popular
          response={broadList}
          selectedMode={selectedMode}
          mainListInfo={mainListInfo}
        />
      )}

      {/* 하단 플로팅 버튼 */}
      <FloatingGroup
        selectedMode={selectedMode}
        handleOnClickMode={handleOnClickMode}
      />
    </div>
  );
};
