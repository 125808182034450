import ApiCore from '@/api/index';
import globalDomains from '@/utils/global-domains';

//광고 서버쪽은 sooplive.co.kr 로 아직 변환작업이안된거같아서 일단 하드코딩
const BANNER_URL = {
  DEV: globalDomains.DEAPIDEV_AD_AFREECATV,
  PRODUCTION: globalDomains.DEAPI_AD_AFREECATV,
};

const DeApi = ApiCore(
  process.env.BUILD_ENV === 'develop' ? BANNER_URL.DEV : BANNER_URL.PRODUCTION,
);
export default DeApi;
