import styled from 'styled-components';

const Loading = () => {
  return (
    <LoadingItem>
      <span className='loading'></span>
    </LoadingItem>
  );
};

export default Loading;

const LoadingItem = styled.div`
  .loading {
    display: block;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 28 29'%3e%3cpath stroke='%230182FF' stroke-linecap='round' stroke-width='2' d='M27 14.064c0 7.18-5.82 13-13 13s-13-5.82-13-13 5.82-13 13-13'/%3e%3c/svg%3e")
      50% 50% no-repeat;
    background-size: 100% 100%;
    width: 26px;
    height: 26px;
    margin: 0 auto;
    animation: loading 1s linear 0s infinite;
  }
  @keyframes loading {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
