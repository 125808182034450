import DirectoryCategory from '@/app/(main)/directory/category';
import DiscoverCategoryDetail from '@/app/(main)/directory/category/[...categoryParams]';
import {Route} from 'react-router-dom';
import {ROUTE} from '@/constants/main/route';
import CustomRoutes from './CustomRoutes';

export default function DirectoryRoute() {
  return (
    <>
      <CustomRoutes>
        {/* categoryMenu가 없을 때 */}
        <Route
          path={ROUTE.DIRECTORY.CATEGORY.INDEX}
          element={<DirectoryCategory />}
        />
        {/* categoryMenu가 있을 때 */}
        <Route
          path={ROUTE.DIRECTORY.CATEGORY._CATEGORY_MENU}
          element={<DirectoryCategory />}
        />
        <Route
          path={ROUTE.DIRECTORY.CATEGORY._CATEGORY_NAME._CATEGORY_TYPE}
          element={<DiscoverCategoryDetail />}
        />
      </CustomRoutes>
    </>
  );
}
