'use client';
import {DirectoryCategory} from '@/components/main/directory/DirectoryCategory';
import {DirectoryContent} from '@/components/main/directory/DirectoryContent';
import {DirectoryMenu} from '@/components/main/directory/DirectoryMenu';
import {FloatingPortal} from '../common';
import {useScrollTop} from '../common/hooks/useScrollTop';
import useDirectoryStore from '@/stores/directory';
import {useParams, useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';

export const Directory = () => {
  const location = useLocation();
  const {categoryMenu} = useParams();
  const {isDisplay, scrollToTop} = useScrollTop();
  const {
    selectedCategoryType,
    selectedCategoryMenu,
    setSelectedCategoryType,
    setSelectedCategoryMenu,
  } = useDirectoryStore();
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('view_cnt_desc');

  useEffect(() => {
    const {pathname} = location;
    if (pathname === '/directory/category') {
      setSelectedCategoryType({
        type: 'category',
        adminId: '',
      });
      setSelectedCategoryMenu(0);
    } else {
      setSelectedCategoryMenu(Number(categoryMenu));
    }
  }, [location]);

  const handleOnClickAdminMenu = () => {
    setPage(1);
    setOrder('view_cnt_desc');
  };

  const directoryContentProps = {
    page,
    order,
    setPage,
    setOrder,
  };

  return (
    <>
      <DirectoryMenu handleOnClickAdminMenu={handleOnClickAdminMenu} />
      <div id='container' className='_category'>
        {selectedCategoryType.type === 'category' &&
        selectedCategoryMenu === 0 ? (
          <DirectoryCategory />
        ) : (
          <DirectoryContent {...directoryContentProps} />
        )}
      </div>
      <FloatingPortal className={'floating_btns'} target={'soop_wrap'}>
        <button
          type='button'
          className={`page_top ${isDisplay ? 'active' : ''}`}
          onClick={scrollToTop}>
          상단 바로가기
        </button>
      </FloatingPortal>
    </>
  );
};
