import _ from 'lodash';
import os from 'os';
import globalDomains from './global-domains';

// api 등에서 리턴되는 스네이크 케이스를 카멜 케이스로 변환
export const camelizeObject = (object) => {
  return _.transform(object, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key);

    acc[camelKey] = _.isObject(value) ? camelizeObject(value) : value;
  });
};

/**
 * 객체의 모든 키를 스네이크 케이스로 변환
 * @param {Object} object - 변환할 객체
 * @returns {Object} - 키가 스네이크 케이스로 변환된 새로운 객체
 */
export const convertKeysToSnakeCase = (object) => {
  return _.transform(
    object,
    (acc, value, key) => {
      // 키를 변환: 숫자는 그대로 두고, 문자 부분만 snake_case로 변환
      const snakeKey = key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();

      acc[snakeKey] =
        _.isObject(value) && !_.isArray(value)
          ? convertKeysToSnakeCase(value)
          : value;
    },
    {},
  );
};

export const parseCookie = (cookieValue) => {
  const pairs = cookieValue.split('&');
  const cookieObj = {};
  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    cookieObj[key] = value;
  });
  return cookieObj;
};

/**
 * 현재 운영 체제가 macOS인지 Windows인지 확인하는 함수
 * @returns {string} - 운영 체제 이름
 */
export const getOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.includes('win')) {
    return 'win';
  } else if (userAgent.includes('mac')) {
    return 'mac';
  } else if (userAgent.includes('linux')) {
    return 'linux';
  } else {
    return 'unknown';
  }
};
/**
 * 현재 장치가 모바일인지 PC인지 확인
 * @returns {'mobile' | 'pc'} - 'mobile' 또는 'pc'
 */
export const getDeviceType = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  if (/mobile|android|iphone|ipad|tablet/i.test(userAgent)) {
    return 'mobile';
  } else {
    return 'pc';
  }
};

/**
 * 객체를 query string 형태로 변환
 * @param {Object} obj - 변환할 객체
 * @returns {string} - query string
 */
export const objectToQueryString = (obj) => {
  const params = new URLSearchParams(obj);
  return params.toString();
};

/**
 * 데이터를 배열 형태로 변환
 * @param {any} data - 변환할 데이터
 * @returns {Array} - 변환된 배열 데이터
 */
export const convertToArray = (data) => {
  // 데이터가 배열인지 확인
  if (Array.isArray(data)) {
    return data;
  }

  // 데이터가 객체인 경우, 값을 배열로 변환
  if (typeof data === 'object' && data !== null) {
    return Object.values(data);
  }

  // 데이터가 문자열인 경우, 문자열을 배열로 변환
  if (typeof data === 'string') {
    return [data];
  }

  // 데이터가 숫자인 경우, 숫자를 배열로 변환
  if (typeof data === 'number') {
    return [data];
  }

  // 데이터가 null 또는 undefined인 경우, 빈 배열 반환
  if (data === null || data === undefined) {
    return [];
  }

  // 그 외 다른 데이터 타입인 경우, 데이터 자체를 배열로 변환
  return [data];
};

export const decodeHtmlEntities = (str) => {
  if (!str) {
    return '';
  }

  return str
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&amp;/g, '&');
};

export const preProcessValue = (str) => {
  // '%' 문자 처리: 올바르지 않은 % 인코딩을 안전하게 처리
  str = str.replace(/%(?![0-9A-Fa-f]{2})/g, '%25');

  // HTML 및 URL에서 특별한 의미를 갖는 문자들을 URL 인코딩
  const specialChars = {
    '<': '%3C',
    '>': '%3E',
    '"': '%22',
    "'": '%27',
    '`': '%60',
    '=': '%3D',
    '{': '%7B',
    '}': '%7D',
    '[': '%5B',
    ']': '%5D',
    '|': '%7C',
    '\\': '%5C',
    '^': '%5E',
    '~': '%7E',
  };

  str = str.replace(/[<>"'`={}[\]|\\^~]/g, (match) => specialChars[match]);

  // '&' 문자 처리: HTML 엔티티의 시작일 수 있으므로 조건부로 처리
  str = str.replace(/&(?![a-zA-Z0-9#]+;)/g, '%26');

  // '#' 문자 처리: URL 프래그먼트의 시작을 나타낼 수 있으므로 조건부로 처리
  str = str.replace(/#/g, '%23');

  // '+' 문자 처리: URL에서 공백을 나타낼 수 있으므로 인코딩
  str = str.replace(/\+/g, '%2B');

  return str;
};

/**
 * 모든 HTML 엔티티를 변환하는 함수 (DOM을 활용)
 */
export const decodeFullHtmlEntities = (str) => {
  if (!str) {
    return '';
  }

  const textarea = document.createElement('textarea');
  textarea.innerHTML = str;
  return textarea.value;
};

export const blockDuringMaintenance = () => {
  if (
    typeof navigator.userAgent == 'undefined' ||
    navigator.userAgent == null
  ) {
    //UA 가 없으면 기본 차단 실행
    location.href = 'https://www.afreecatv.com/not_found.htm';
  } else {
    if (
      navigator.userAgent
        .toLocaleLowerCase()
        .match(
          new RegExp('(Sooplive Webview|Sooplive API)[2]?/(8.0.0)', 'i'),
        ) === null
    ) {
      //앱 이외의 접근시, 차단 실행
      location.href = 'https://www.afreecatv.com/not_found.htm';
    }
  }
};

export const setPosterImageObserver = () => {
  try {
    const $container = document.querySelector('html');
    const handleDarkModeChange = () => {
      const newMode = document.documentElement.getAttribute('dark');
      // setIsDarkMode(newMode);

      // 오류로 인해 변경된 이미지를 다시 설정
      document.querySelectorAll('img[data-replaced="true"]').forEach((img) => {
        if (newMode) {
          img.src = `${globalDomains.RES_AFREECATV}/images/svg/category_thumb_default_dark.svg`;
        } else {
          img.src = `${globalDomains.RES_AFREECATV}/images/svg/category_thumb_default.svg`;
        }
      });
    };

    const observer = new MutationObserver(handleDarkModeChange);
    observer.observe($container, {
      attributes: true,
      attributeFilter: ['dark'],
    });

    return () => {
      observer.disconnect();
    };
  } catch (error) {}
};

/**
 * 객체 프로퍼티의 모든 값을 대문자로 반환
 * @param {Object} obj
 * @returns {Object}
 */
export const uppercaseObjectValue = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    acc[key] = obj[key].toUpperCase();
    return acc;
  }, {});
};
