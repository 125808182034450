import {SoopUiSwiper} from '@/components/ui';
import {useEffect, useState} from 'react';
import '/public/static/css/module/hot_swiper.scss';

export const LiveSportsHotTextList = ({mainListInfo}) => {
  const [sportTextBanner, setSportTextBanner] = useState([]);
  const swiperConfig = {
    direction: 'vertical',
    slidesPerView: 1,
    loop: sportTextBanner.length > 1 ? true : false, // 자동 반복 설정
    autoplay: {
      delay: 2500,
      disableOnInteraction: false, // 상호작용 후에도 자동 재생 유지
    },
  };

  useEffect(() => {
    if (mainListInfo && mainListInfo?.sportTextBannerResult === 1) {
      setSportTextBanner(mainListInfo?.sportTextBannerData);
    }
  }, [mainListInfo]);

  return (
    <SoopUiSwiper
      options={swiperConfig}
      swiperTag='ul'
      hasControls={false}
      swiperClass='hot_swiper'>
      {sportTextBanner.map((item, idx) => (
        <li key={item.no}>
          <a href={`${item.linkUrl}`} target='_blank'>
            <em>HOT</em>
            <p>{item.title}</p>
          </a>
        </li>
      ))}
    </SoopUiSwiper>
  );
};
