import Search from '@/app/(main)/search/layout';
import Helmet from 'react-helmet';
import {Route, Routes} from 'react-router-dom';

export default function SearchRoute() {
  return (
    <>
      <Routes>
        <Route path='' element={<Search />} />
      </Routes>
    </>
  );
}
