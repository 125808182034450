import '/public/static/css/module/cBox.scss';
import Broadcast from '@/components/my/common/item/MyBroadcast';
import {SectionHeader} from '@/components/main/common';
import {SoopUiSwiper} from '@/components/ui';
import globalDomains from '@/utils/global-domains';
import {sendClickLog} from '@/utils/main/my-plus/log';
import {useEffect, useState} from 'react';

export const HOT_ISSUE_CONTEXT_MENU = [
  {label: '나중에 보기', type: 'view-later'},
  {label: '공유하기', type: 'share'},
];

export const HotIssue = ({mainListInfo}) => {
  const [hotIssue, setHotIssue] = useState([]);

  const swiperConfig = {
    loop: true,
  };

  useEffect(() => {
    // mainListInfo가 존재하고, realtimeHotissueResult 값이 1인 경우
    if (mainListInfo && mainListInfo?.realtimeHotissueResult === 1) {
      // mainListInfo의 realtimeHotissueData 배열을 변환하여 setHotIssue에 전달
      setHotIssue(
        mainListInfo?.realtimeHotissueData &&
          mainListInfo?.realtimeHotissueData?.length > 0 &&
          mainListInfo?.realtimeHotissueData?.map((broadcast) => {
            const {
              totalViewCnt, // 총 조회수
              broadStart, // 방송 시작 시간
              userNick, // 유저 닉네임
              broadTitle, // 방송 제목
              broadNo, // 방송 번호
              categoryTags,
              ...rest // 나머지 속성
            } = broadcast;

            // 새로운 객체를 반환
            return {
              ...rest,
              thumbnailSrc: `${globalDomains.LIVEIMG_AFREECATV}/m/${broadNo}?${Math.floor(Math.random() * 10 + 1)}`, // 썸네일 이미지
              isPassword: 0, // 비밀번호 보호 여부 (기본값 0)
              isAdult: 0, // 성인 여부 (기본값 0)
              viewCount: totalViewCnt, // 조회수
              broadcastStartTime: broadStart, // 방송 시작 시간
              userNickname: userNick, // 유저 닉네임
              title: broadTitle, // 방송 제목
              broadcastId: broadNo, // 방송 ID
              categoryName:
                categoryTags && categoryTags.length > 0 ? categoryTags[0] : '',
              categoryTags: categoryTags,
            };
          }),
      );
    }
  }, [mainListInfo]);

  return (
    <>
      {hotIssue && hotIssue.length > 0 ? (
        <>
          <SectionHeader title={'실시간 핫이슈'} />
          <div className='cBox_slide'>
            <SoopUiSwiper
              swiperClass={`cBox_list`}
              options={swiperConfig}
              maxSlidesPerView={6}
              swiperTag={'ul'}>
              {hotIssue.map((item, indx) => (
                <li data-type='cBox' key={item.broadcastId}>
                  <Broadcast
                    {...{
                      ...item,
                      menuList: HOT_ISSUE_CONTEXT_MENU,
                    }}
                    isLogSendData={{
                      path1: 'popular',
                      path2: 'list',
                      path3: 'hotissue',
                    }}
                    logCallback={() =>
                      sendClickLog('main_00000002', {
                        bjId: item.userId,
                        broadNo: item.broadcastId,
                      })
                    }
                  />
                </li>
              ))}
            </SoopUiSwiper>
          </div>
        </>
      ) : null}
    </>
  );
};
