import {SoopUiSwiper} from '@/components/ui';
import Broadcast from '@/components/my/common/item/MyBroadcast';
import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';
import {BROADCAST_TYPES} from '@/constants';

const HotIssueVod = ({vod}) => {
  return (
    <>
      {vod?.length > 0 && (
        <SoopUiSwiper
          swiperTag='ul'
          swiperClass='cBox_list'
          maxSlidesPerView={6}
          options={{
            loop: true,
            autoplay: {
              delay: 2500,
            },
          }}>
          {vod.map((video, index) => {
            return (
              <li
                key={`hotissue_video_${video.userId}`}
                data-type={'cBox'}
                data-slide-index={video.userId}>
                <BroadcastLayout
                  broadcastType={BROADCAST_TYPES.VOD}
                  thumbnailUrl={video.ucc?.thumb || ''}
                  viewCount={video.count.readCnt}
                  userNickname={video.userNick}
                  title={video.titleName}
                  broadcastId={video.titleNo}
                  userId={video.userId}
                  vodType={video.ucc?.fileType}
                  categoryNo={Number(video.ucc?.category)}
                  accessibleAge={Number(video.ucc?.grade)}
                  allowsAdult={video.ucc?.isAdult}
                  duration={video.ucc?.totalFileDuration}
                  contextMenu={{
                    menuList: [],
                  }}
                  isShowChips={false}>
                  <BroadcastType.Vod />
                </BroadcastLayout>
              </li>
            );
          })}
        </SoopUiSwiper>
      )}
    </>
  );
};

export default HotIssueVod;
