import {useEffect, useRef, useState} from 'react';

const useMouseDrag = (
  progressBarRef,
  setProgress,
  onProgress,
  updateOnMouseMove = false, //mouseMove에서도 progress update 진행 여부
) => {
  const isDraggingRef = useRef(false);
  const dragProgress = useRef(0);
  const [progressWhileDragging, setProgressWhileDragging] = useState(0);

  const handleMouseDown = (event) => {
    isDraggingRef.current = true;
    updateProgress(event);
  };

  const handleMouseMove = (event) => {
    if (isDraggingRef.current) {
      updateProgress(event);
      if (updateOnMouseMove) {
        setProgress(dragProgress.current);
        onProgress(dragProgress.current);
      }
    }
  };

  const handleMouseUp = () => {
    if (isDraggingRef.current) {
      isDraggingRef.current = false;
      setProgress(dragProgress.current);
      onProgress(dragProgress.current);
    }
  };

  const updateProgress = (event) => {
    if (progressBarRef.current) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const newProgress = Math.min(
        100,
        Math.max(0, ((event.clientX - rect.left) / rect.width) * 100),
      );
      setProgressWhileDragging(newProgress);
      dragProgress.current = newProgress;
    }
  };

  useEffect(() => {
    if (progressBarRef.current) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [progressBarRef.current]);

  return {
    handleMouseDown,
    progressWhileDragging,
    isDraggingRef,
  };
};

export default useMouseDrag;
