import React, {useRef} from 'react';
import useMouseDrag from '@/components/player/common/hooks/useMouseDrag';

export const VolumeProgressBar = ({
  progress,
  setProgress,
  onProgress = (progress) => {},
  children,
}) => {
  const progressBarRef = useRef(null);

  const {handleMouseDown, progressWhileDragging, isDraggingRef} = useMouseDrag(
    progressBarRef,
    setProgress,
    onProgress,
    true,
  );

  return (
    <div
      className='volume_slider_wrap'
      ref={progressBarRef}
      onMouseDown={handleMouseDown}>
      <div className='volume_slider'>
        <div
          className='volume_range'
          style={{
            width: `${progress}%`,
          }}></div>
        <button
          className='volume_handler'
          style={{
            left: `${progress}%`,
          }}>
          <span></span>
        </button>
        {children}
      </div>
    </div>
  );
};
