// 이벤트태그라고 고정된 스타일값이 내려오는게 있는데 거기서 테마값을 dark/light 로내려줘서 아래 상수로 변경,,,
export const MAIN_THEME = {
  light: 'light',
  dark: 'dark',
};

export const DISPLAY_TYPE = {
  list: 'list',
  grid: 'grid',
};

export const DIALOG_BUTTON_TYPES = {
  positive: 'positive',
  negative: 'negative',
};

// FIXME: BROADCAST_TYPES 랑 값을 맞춰 줘야 하는데 바꿔 줄 엄두가 안 난다
export const VOD_TYPES = {
  CATCH: 'CATCH',
  CATCH_STORY: 'CATCH_STORY',
  HIGHLIGHT: 'HIGHLIGHT',
  REVIEW: 'REVIEW',
  PLAYLIST: 'PLAYLIST',
};
export const BROADCAST_ITEM_DISPLAY_TYPE = {
  GRID_VIEW: 'grid', // default
  LIST_VIEW: 'list',
};
// FIXME: VOD_TYPES 랑 값을 맞춰 줘야 하는데 바꿔 줄 엄두가 안 난다
export const BROADCAST_TYPES = {
  VOD: 'vod',
  CATCH: 'catch',
  CATCH_STORY: 'catchstory',
  PLAYLIST: 'playlist',
  LIVE: 'live',
  CLIP: 'CLIP',
};
