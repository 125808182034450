import {useState, useEffect, useMemo} from 'react';
import useMyStore from '@/stores/my';
import {isEmpty} from 'lodash';

/**
 * 즐겨찾기 페이지의 VOD 를 가져오는 훅
 * @param {string} groupId
 * @param {Function} fetchFunction
 * @param {number} maxSize
 * @returns {Object} VOD 와 유입로그에서 사용할 groupName, groupType 을 가져온다
 */
export const useFetchFavoriteVod = (groupId, fetchFunction, maxSize) => {
  const [items, setItems] = useState([]);
  const {favoriteGroups} = useMyStore();
  const groupType = groupId === '' ? 'all' : 'custom';

  const getContent = async () => {
    try {
      const data = await fetchFunction(groupId);
      const limitedData = maxSize ? data.slice(0, maxSize) : data;
      setItems(limitedData);
    } catch (error) {
      console.error(error);
    }
  };

  const groupName = useMemo(() => {
    if (groupId === '') {
      return null;
    }

    if (isEmpty(favoriteGroups)) {
      return null;
    }

    const group = favoriteGroups.find(
      (group) => Number(group.idx) === Number(groupId),
    );

    return isEmpty(group) ? null : group.title;
  }, [favoriteGroups, groupId]);

  useEffect(() => {
    getContent();
  }, [groupId]);

  return {items, groupName, groupType};
};
