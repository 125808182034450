import useIsHover from '@/components/player/common/hooks/useIsHover';
import {forwardRef} from 'react';

const BasePlayer = forwardRef(
  (
    {
      videoRef,
      playerType,
      autoPlay = false,
      muted = true,
      thumbnail,
      showPoster,
      children,
      ...props
    },
    ref,
  ) => {
    const [
      isControlBarHover,
      handleControlBarMouseEnter,
      handleControlBarMouseLeave,
    ] = useIsHover(3000);

    return (
      <div
        ref={ref}
        id='da_player'
        className={`video ${children && isControlBarHover ? 'mouseover' : ''}`}
        onMouseEnter={handleControlBarMouseEnter}
        onMouseLeave={handleControlBarMouseLeave}
        {...props}>
        <div className='player_size'></div>
        <div id='videoLayer'>
          <video
            ref={videoRef}
            id={playerType}
            autoPlay={autoPlay}
            muted={muted}></video>
          {children}
          <div id='videoLayerCover' tabIndex='1'>
            <div
              className='poster'
              style={
                thumbnail && showPoster
                  ? {
                      backgroundImage: `url("${thumbnail}")`,
                    }
                  : {}
              }></div>
          </div>
          <div className='loading' style={{display: 'none'}}>
            <span></span>
          </div>
          <div className='buffering' style={{display: 'none'}}>
            <span></span>
          </div>
        </div>
      </div>
    );
  },
);

BasePlayer.displayName = 'BasePlayer';

export default BasePlayer;
