import MyFavoriteApi from '@/api/my/favorite';
import {SectionHeader} from '@/components/main/common';
import {useFetchFavoriteVod} from '@/components/my/hooks/useFetchFavoriteVod';
import MyBroadcastLayout from '../common/MyBroadcastLayout';
import {ErrorBoundary} from '@/components/common';
import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';
import {BROADCAST_TYPES} from '@/constants';
import {CONTEXT_MENU} from '@/constants/my/contextMenu';
import useMyStore from '@/stores/my';
import {throwDialog} from '@/utils/ui/dialog';

// 유저클립은 15개만 보여줌
const MAX_SIZE = 15;

export const getFavoriteContextMenu = (fileType) => {
  return fileType === 'CATCH'
    ? CONTEXT_MENU.FAVORITE.CATCH
    : CONTEXT_MENU.FAVORITE.VOD;
};

/**
 * 유저클립 목록 컴포넌트
 * @desc API 로부터 유저클립을 가져오고 필드명을 맞춰서 MyCatchGroup 컴포넌트에 전달한다.
 * @returns {JSX.Element} UserClip
 */
const UserClip = ({groupId}) => {
  const {
    items: userClips,
    groupName,
    groupType,
  } = useFetchFavoriteVod(groupId, MyFavoriteApi.getUserClip, MAX_SIZE);

  const {myInfo} = useMyStore();

  const handleContextMenuClick =
    (originalUserId, originalUserNick) => async (event, type) => {
      if (type !== 'group') {
        return;
      }

      throwDialog(
        'my/FavoriteGroup',
        {
          userId: myInfo.userId,
          streamerIdx: originalUserId,
          streamerId: originalUserId,
          broadcastInfo: {
            userId: originalUserId,
            userNickname: originalUserNick,
          },
        },
        {
          title: '즐겨찾기 그룹',
          customClassName: 'list_add_layer',
          titleAlign: 'left',
        },
      );
    };

  return (
    userClips?.length > 0 && (
      <>
        <SectionHeader title='유저클립' />
        <MyBroadcastLayout type='swiper'>
          {userClips.map((broadcast) => (
            <ErrorBoundary key={broadcast.titleNo}>
              <BroadcastLayout
                broadcastType={BROADCAST_TYPES.VOD}
                thumbnailUrl={broadcast.thumbnail}
                viewCount={broadcast.readCnt}
                createdDate={broadcast.regDate}
                startDateTime={broadcast.broadStart}
                authNumber={broadcast.authNo}
                userNickname={broadcast.userNick}
                title={broadcast.titleName}
                broadcastId={broadcast.titleNo}
                userId={broadcast.userId}
                hasBadge={
                  broadcast.categoryTags ||
                  broadcast.hashTags ||
                  broadcast.autoHashtags
                }
                originalUserId={broadcast.copyright?.userId}
                originalUserNickname={broadcast.copyright?.userNick}
                categoryTags={broadcast.categoryTags}
                hashTags={broadcast.hashTags}
                autoHashTags={broadcast.autoHashtags}
                vodType={broadcast.fileType}
                categoryNo={Number(broadcast.category)}
                accessibleAge={Number(broadcast.grade)}
                allowsAdult={broadcast.isAdult}
                isFan={broadcast.isFanclub}
                isSubscribe={broadcast.isSubscribe}
                duration={broadcast.duration}
                contextMenu={{
                  menuList: getFavoriteContextMenu(broadcast.fileType),
                }}
                log={{
                  groupType,
                  groupName,
                }}
                previewThumbnailUrl={broadcast.webpPath}
                handleMenuClicked={handleContextMenuClick(
                  broadcast.copyright?.userId,
                  broadcast.copyright?.userNick,
                )}>
                <BroadcastType.Vod />
              </BroadcastLayout>
            </ErrorBoundary>
          ))}
        </MyBroadcastLayout>
      </>
    )
  );
};

export default UserClip;
