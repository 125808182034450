'use client';
import globalDomains from '@/utils/global-domains';
import {scheme} from '@afreecatv/domains';
import {useEffect, useRef, useState} from 'react';
import {useOutsideClick} from '../main/common/hooks/useOutsideClick';
import throwToast from '@/utils/ui/toast';
import useSharePopupStore from '@/stores/ui/share-popup';

const SHARE_MENU_LIST = [
  {label: 'x', url: 'ico_share_x.svg'},
  {label: 'facebook', url: 'ico_share_facebook.svg'},
  {label: 'telegram', url: 'ico_share_tele.svg'},
  {label: 'kakaotalk', url: 'ico_share_kakao.svg'},
  {label: 'url', url: 'ico_share_link.svg'},
];

const loadKakaoScript = () => {
  return new Promise((resolve, reject) => {
    const existingScript = document.querySelector(
      `script[src="${globalDomains.RES_AFREECATV}/script/kakao.min.js"]`,
    );

    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `${globalDomains.RES_AFREECATV}/script/kakao.min.js`;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    } else {
      resolve();
    }
  });
};

const SoopUiSharePopup = () => {
  const shareRef = useRef(null);
  const {sharePopup, closeSharePopup} = useSharePopupStore();
  const [position, setPosition] = useState({top: 0, left: 0});

  const checkButtonPosition = () => {
    const MENU_WIDTH = 395;
    const initPosition = sharePopup.parentPosition;
    let newTop = initPosition.top + window.scrollY;
    let newLeft = initPosition.left + window.scrollX;
    if (newLeft + MENU_WIDTH > window.innerWidth) {
      newLeft = window.innerWidth - MENU_WIDTH - 100;
    }

    setPosition({
      top: newTop,
      left: newLeft,
    });
  };
  const handleClose = () => {
    closeSharePopup();
  };

  const handleShare = (snsType) => {
    const shareUrl = sharePopup.pageUrl;
    const textToShare = sharePopup.title;

    switch (snsType) {
      case 'x':
        const xShareUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(textToShare)}&url=${encodeURIComponent(shareUrl)}`;
        window.open(xShareUrl, '_blank');
        break;
      case 'facebook':
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        window.open(facebookShareUrl, '_blank');
        break;
      case 'telegram':
        const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(textToShare)}`;
        window.open(telegramShareUrl, '_blank');
        break;
      case 'kakaotalk':
        loadKakaoScript().then(() => {
          if (!Kakao.isInitialized()) {
            // SDK 초기화는 처음 실행했을 때 한번만
            Kakao.init('b4b3dd0cdd9894c56957b935d868c7ea');
          }
          Kakao.Link.sendCustom(sharePopup.kakaoParams);
        });
        break;
      case 'url':
        navigator.clipboard
          .writeText(shareUrl)
          .then(() => {
            throwToast('주소가 복사됐습니다. 원하는 곳에 붙여넣기 해주세요.');
          })
          .catch((err) => {
            throwToast('URL 복사 실패');
          });
        break;
      default:
        break;
    }
    handleClose();
  };

  useOutsideClick(
    shareRef,
    () => {
      sharePopup && closeSharePopup();
    },
    !!sharePopup,
  ); // sharePopup객체가 있을때만 활성화

  useEffect(() => {
    const handleResize = () => {
      closeSharePopup();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (sharePopup) {
      closeSharePopup();
    }
  }, [window.location.href]);

  useEffect(() => {
    if (sharePopup) {
      checkButtonPosition();
    } else {
      closeSharePopup();
    }
  }, [sharePopup]);

  return (
    sharePopup && (
      <div
        ref={shareRef}
        className='_share_layer'
        style={{
          display: 'block',
          top: position.top,
          left: position.left,
        }}>
        <div className='title'>
          <h3>공유하기</h3>
        </div>
        <div className='_share_type'>
          <div className='_list'>
            {SHARE_MENU_LIST.filter((shareMenu) => {
              if (!sharePopup.kakaoParams) {
                console.error(
                  'kakaoParmas가 등록되지 않아 카카오톡 공유 메뉴는 제거됩니다.',
                );
                return shareMenu.label !== 'kakaotalk';
              } else {
                return true;
              }
            }).map((item, idx) => (
              <button
                key={idx}
                type='button'
                id={item.label}
                onClick={() => handleShare(item.label)}>
                <img
                  src={`${globalDomains.RES_AFREECATV}/images/svg/${item.url}`}
                  alt='X'
                />
              </button>
            ))}
          </div>
        </div>
        <button type='button' className='close_layer' onClick={handleClose}>
          레이어 닫기
        </button>
      </div>
    )
  );
};

export default SoopUiSharePopup;
