'use client';
import {
  MAIN_MENU_LIST,
  LIVE_CONTEXT_MENU,
  LIVE_SORT_OPTION,
} from '@/constants/main';
import {SectionHeader, FloatingPortal} from '@/components/main/common';
import {useScrollTop} from '../common/hooks/useScrollTop';
import {SoopUiSelectBox} from '@/components/ui';
import LiveBroadcastItem from '../MyPlus/LiveBroadcastItem';

const getMenuName = (selectedMenu) => {
  const menuItem = MAIN_MENU_LIST.find((item) => item.id === selectedMenu);
  return menuItem ? menuItem.name : '';
};

export const LiveGroupList = ({
  order,
  broadList,
  selectedMenu,
  slicedBroadList,
  handleOnClickMore,
  handleOnClickSort,
  handleOnClickHiddenStreamer,
  location,
}) => {
  const {isDisplay, scrollToTop} = useScrollTop();

  const handleContextMenuClick = (event, type, data) => {
    if (type === 'hidden') {
      handleOnClickHiddenStreamer(data);
    }
  };
  return (
    <>
      <SectionHeader
        title={getMenuName(selectedMenu)}
        rightElement={
          <SoopUiSelectBox
            options={LIVE_SORT_OPTION}
            type={order}
            onChange={handleOnClickSort}
          />
        }
      />
      {broadList?.list?.length > 0 ? (
        <div className='cBox-list'>
          <ul>
            {broadList?.list?.map((broadcast, idx) => (
              <li data-type='cBox' key={idx}>
                <LiveBroadcastItem
                  broadcastType='live'
                  location={location}
                  thumbnailUrl={broadcast.broadThumb}
                  userId={broadcast.userId}
                  userNickname={broadcast.userNick}
                  viewCount={broadcast.totalViewCnt}
                  liveType={broadcast.broadType}
                  title={broadcast.broadTitle}
                  categoryTags={broadcast.categoryTags}
                  autoHashTags={broadcast.autoHashtags}
                  hashTags={broadcast.hashTags}
                  canResend={Boolean(Number(broadcast.visitBroadType))}
                  allowsAdult={Number(broadcast.broadGrade)}
                  hasPassword={broadcast.isPassword === 'Y' ? 1 : 0}
                  hasDrops={broadcast.isDrops}
                  startDateTime={broadcast.broadStart}
                  broadcastId={broadcast.broadNo}
                  handleMenuClicked={handleContextMenuClick}
                  contextMenu={{
                    menuList: LIVE_CONTEXT_MENU,
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className='nt_area'>
          <p>진행중인 라이브가 없습니다.</p>
        </div>
      )}

      {broadList.isMore || (slicedBroadList && slicedBroadList.length > 0) ? (
        <div className='show_more'>
          <button type='button' onClick={() => handleOnClickMore()}>
            더보기
          </button>
        </div>
      ) : null}
      <FloatingPortal className={'floating_btns'} target={'soop_wrap'}>
        <button
          type='button'
          className={`page_top ${isDisplay ? 'active' : ''}`}
          onClick={scrollToTop}>
          상단 바로가기
        </button>
      </FloatingPortal>
    </>
  );
};
