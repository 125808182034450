'use client';

import {SectionHeader} from '@/components/main/common';
import useMyStore from '@/stores/my';
import {sendMyStreamerSettingClickLog} from '@/utils/main/my/log';
import {throwDialog} from '@/utils/ui/dialog';

const StreamerTitle = () => {
  const {favoriteStreamersCount} = useMyStore();

  const handleButtonClick = () => {
    sendMyStreamerSettingClickLog();
    throwDialog('my/FavoriteSetting', {}, {isCustomDialog: true});
  };

  return (
    <SectionHeader
      title={
        <>
          스트리머
          <span className='total'>({favoriteStreamersCount}명)</span>
        </>
      }
      rightElement={
        <button
          type='button'
          className='fav_manage'
          onClick={handleButtonClick}>
          스트리머 관리
        </button>
      }
    />
  );
};

export default StreamerTitle;
