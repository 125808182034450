'use client';
import {Suspense, useMemo, useCallback} from 'react';
import Search from '@/app/(main)/search';
import SearchProvider from '@/components/search/SearchProvider';
import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import '/public/static/css/module/depth2Menu.scss';
import '/public/static/css/module/medal.scss'; // medal css

const SearchLayout = () => {
  const {getParams} = useUrlParameter();
  const {szKeyword: keyword = '', tagname = ''} = getParams();

  // useMemo를 사용하여 키워드가 변경될 때만 새로운 key 생성
  const searchKey = useMemo(() => keyword, [keyword, tagname]);

  // useCallback을 사용하여 Search 컴포넌트를 메모이제이션
  const MemoizedSearch = useCallback(() => <Search />, [searchKey, tagname]);

  return (
    <SearchProvider key={searchKey}>
      <Suspense>
        <MemoizedSearch key={searchKey} />
      </Suspense>
    </SearchProvider>
  );
};

export default SearchLayout;
