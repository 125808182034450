'use client';

import {forwardRef} from 'react';
import MyStreamerSetting from '@/components/my/common/group/StreamerSetting';
import useMyStore from '@/stores/my';

const OPTIONS = Object.freeze([
  {label: '최근 가입', type: 'fanclubNo'},
  {label: '닉네임', type: 'userNick'},
  {label: '최근 시작', type: 'lastBroadStart'},
]);

/**
 * 팬클럽 스트리머 관리 팝업
 * @param {Object} data - 팝업 데이터 (throwDialog 함수에서 전달)
 * @param {Function} handleClose - 팝업 닫기 함수 (팝업에서 전달)
 * @param {Object} ref
 * @returns {JSX.Element}
 */
const MyFanClubSetting = forwardRef(function MyLayer({handleClose}, ref) {
  const {fanclubStreamers, getFanclubStreamers} = useMyStore();

  return (
    <MyStreamerSetting
      allStreamers={fanclubStreamers}
      getStreamers={getFanclubStreamers}
      options={OPTIONS}
      handleClose={handleClose}
      isFanclubStreamers
    />
  );
});

export default MyFanClubSetting;
