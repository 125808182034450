import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';
import {StreamerListItem} from '@/components/search/common';

import {
  DIRECTORY_LIVE_CONTEXT_MENU as LIVE_CONTEXT_MENU,
  VOD_CONTEXT_MENU,
  PLAYLIST_CONTEXT_MENU,
} from '@/constants/main';
import {VOD_TYPES} from '@/constants';
import {sendLegacyInflowPathLog} from '@/utils/main/my/log';
import {decodeFullHtmlEntities} from '@/utils';

// Props 생성 함수
const createBroadcastItemProps = (
  content,
  index,
  catchStoryList,
  handleLogCallback,
) => {
  const isVod = !!content.fileType;
  const broadcastId = !isVod ? content.broadNo : content.titleNo;
  const isPlaylist = content.fileType === VOD_TYPES.PLAYLIST;
  const isCatchStory = content.fileType === VOD_TYPES.CATCH_STORY;
  const isAdult = !isVod
    ? Number(content.broadGrade) === 19
    : Number(content.grade) === 19 && Number(content.category) === 30000;
  const isPassword = !isVod ? content.isPassword === 'Y' : false;

  return {
    broadcastType: isVod ? 'vod' : 'live',
    thumbnailUrl: isVod ? content.thumbnailPath : content.broadImg,
    previewThumbnailUrl: isVod && content.webpPath,
    userId: content.userId,
    userNickname: content.userNick,
    originalUserId: isVod && content.originalBj,
    originalUserNickname: isVod && content.originalUserNick,
    viewCount: content.viewCnt || content.totalViewCnt,
    startDateTime: content.broadStart,
    title: decodeFullHtmlEntities(content.title || content.bBroadTitle),
    hasBadge: content.categoryTags || content.hashTags || content.autoHashtags,
    categoryTags: content.categoryTags,
    hashTags: content.hashTags,
    autoHashTags: content.autoHashtags,
    broadcastId: isCatchStory ? content.listNo : broadcastId,
    duration: isVod && content.duration,
    vodType: content.fileType,
    createdDate: isPlaylist ? content.vodAddDate : content.regDate,
    liveType: content.broadType,
    canResend: Number(content.visitType) > 0,
    categoryNo: content.broadCateNo || content.category,
    categoryName:
      content?.categoryTags && content?.categoryTags.length > 0
        ? content?.categoryTags[0]
        : '',
    catchStoryIdList: catchStoryList,
    accessibleAge: Number(content.grade || content.broadGrade),
    searchActionType: 'recommend',
    allowsAdult: isAdult,
    hasPassword: isPassword,
    isPpv: content.ppv,
    isFan: !!Number(content.fanFlag),
    isSubscribe: !!Number(content.subsFlag),
    isDisplay: isPlaylist && content.config?.display,
    playlistId: isPlaylist && content.listNo,
    playlistCount: content.playlistCnt,
    vodOriginalId: content.vodOriginalId,
    vodUploaderId: content.vodUploader,
    handleAfterLogSend: (param) => {
      handleLogCallback({param, index, ...content});
    },
  };
};

// 컨텍스트 메뉴 선택 함수
const getContextMenu = (content) => {
  if (
    content.fileType === VOD_TYPES.CATCH ||
    content.fileType === VOD_TYPES.CATCH_STORY
  ) {
    return {menuList: [{label: '공유하기', type: 'share'}]};
  } else if (content.fileType === VOD_TYPES.PLAYLIST) {
    return {menuList: PLAYLIST_CONTEXT_MENU};
  } else if (content.recommendType === 'LIVE') {
    return {menuList: LIVE_CONTEXT_MENU};
  } else {
    return {menuList: VOD_CONTEXT_MENU};
  }
};

// 로그 데이터 생성 함수
const createLogData = (content, index) => {
  if (content.recommendType === 'LIVE') {
    return {
      path1: 'sch',
      path2: 'sch',
      path3: 'bjp',
      path4: 'smart_recommend',
      cli_list_data_idx: index + 1,
    };
  } else {
    return {
      path1: 'sch',
      path2: 'sch',
      path3: 'recommend',
      cli_list_data_idx: index + 1,
    };
  }
};

const RecommendContentListItem = ({
  content,
  index,
  catchStoryList,
  handleLogCallback,
}) => {
  const props = createBroadcastItemProps(
    content,
    index,
    catchStoryList,
    handleLogCallback,
  );
  const contextMenu = getContextMenu(content);
  const logData = createLogData(content, index);

  switch (content.recommendType) {
    case 'LIVE':
    case 'VOD':
      return (
        <BroadcastLayout
          {...props}
          contextMenu={contextMenu}
          log={{data: logData}}>
          {content.recommendType === 'VOD' ? (
            <BroadcastType.Vod />
          ) : (
            <BroadcastType.Live />
          )}
        </BroadcastLayout>
      );
    case 'BJ':
      return (
        <StreamerListItem
          {...content}
          showButton={false}
          searchArea='recommend'
          logCallback={(param) => {
            handleLogCallback({param, index, ...content});
            sendLegacyInflowPathLog({
              broadcastId: content.broadNo,
              streamerId: content.userId,
              isLogSendData: {
                path1: 'sch',
                path2: 'total',
                path3: 'recommend_contents',
                path4: 'sti',
                cli_list_data_idx: index + 1,
              },
            });
          }}
        />
      );
    default:
      return null;
  }
};

export default RecommendContentListItem;
