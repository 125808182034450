import {useState, useRef} from 'react';
import {useOutsideClick} from '../common/hooks/useOutsideClick';
import {SoopUiSelectBox} from '@/components/ui';
import {DIRECTORY_CATEGORYT_SORT_OPTION} from '@/constants/main';
import {getFilteredContextMenu} from '@/utils/main';
import SectionHeader from '../common/SectionHeader';

export const DirectoryCategoryTitle = ({
  order,
  searchText,
  handleOnClickSort,
  handleOnChangeSearch,
  handleOnClickClearSearchText,
}) => {
  const [isExpandSearch, setIsExpandSearch] = useState(false);
  const searchRef = useRef(null);

  const handleOnClickExpandSearch = () => {
    setIsExpandSearch(true);
  };

  useOutsideClick(searchRef, () => {
    if (searchText.length === 0) {
      setIsExpandSearch(false);
    }
  });

  return (
    <SectionHeader
      customElement={
        <>
          <h2>카테고리 검색</h2>
          <div
            className={`searh_box ${isExpandSearch ? `expanded` : ''}`}
            ref={searchRef}>
            <button
              type='button'
              className='btn_expand'
              onClick={handleOnClickExpandSearch}>
              <span>검색</span>
            </button>
            <div className='search_from'>
              <input
                className='search_inp'
                placeholder='검색어를 입력하세요.'
                type='text'
                value={searchText}
                onChange={handleOnChangeSearch}
              />
              {searchText.length > 0 && (
                <button
                  type='button'
                  className='del_text'
                  onClick={handleOnClickClearSearchText}>
                  입력내용 삭제
                </button>
              )}
            </div>
          </div>
        </>
      }
      rightElement={
        <SoopUiSelectBox
          options={getFilteredContextMenu(DIRECTORY_CATEGORYT_SORT_OPTION, [
            'view_cnt',
            'reg_date',
          ])}
          type={order}
          onChange={handleOnClickSort}
        />
      }
    />
  );
};
