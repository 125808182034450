import {useRef} from 'react';

import {SoopUiProfileIconMenu} from '@/components/ui';

import globalDomains from '@/utils/global-domains';
import {convertViewCnt} from '@/utils/main';

/**
 * StreamerNickBox 컴포넌트
 * @param {Object} props - StreamerNickBox props
 * @param {string} props.isFanclub - 팬클럽 여부
 * @param {string} props.isSubscribe - 구독 여부
 * @param {string} props.userNick - 닉네임
 * @param {string} props.userId - 아이디
 * @param {string} props.fanCount - 애청자
 * @param {string} props.currentLoginUserId - 로그인한 유저 아이디
 * @param {string} props.onEvent - 프로필 ict 메뉴의 이벤트가 발생했을 때 트리거
 * @param {string} props.onLogClick - 로그관련 메소드
 * @returns {JSX.Element} StreamerNickBox 컴포넌트
 */
const StreamerNickBox = ({
  isFanclub,
  isSubscribe,
  userNick,
  userId,
  fanCount,
  currentLoginUserId,
  onEvent,
  logCallback,
}) => {
  const soopUiProfileIconMenu = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    if (soopUiProfileIconMenu.current) {
      soopUiProfileIconMenu.current.open();
      logCallback();
    } else {
      console.error('Profile menu is not available');
    }
  };

  return (
    <div className='nick_box'>
      <div className='nick'>
        <button type='button' onClick={handleClick}>
          {userNick}
        </button>
        {!!isSubscribe && <span className='grade-badge-subscribe'>구독</span>}
        {!!isFanclub && <span className='grade-badge-fan'>F</span>}

        {userId === currentLoginUserId && (
          <button
            onClick={() =>
              window.open(
                `${globalDomains.BJ_AFREECATV}/${userId}/setting/profile`,
                '_blank',
                'noopener,noreferrer',
              )
            }
            className='modi'>
            내 프로필 수정
          </button>
        )}
      </div>
      <SoopUiProfileIconMenu
        ref={soopUiProfileIconMenu}
        streamerId={userId}
        streamerNick={userNick}
        handleMenuButtonClicked={onEvent}
        searchArea='bjp'
      />
      <ul className='detail'>
        <li>{userId}</li>
        <li>애청자 {convertViewCnt(fanCount)}명</li>
      </ul>
    </div>
  );
};

export default StreamerNickBox;
