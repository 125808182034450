'use client';

import MyFavoriteApi from '@/api/my/favorite';
import {forwardRef, useEffect, useState} from 'react';
import MyStreamerSetting, {
  formatStreamer,
} from '@/components/my/common/group/StreamerSetting';
import throwToast from '@/utils/ui/toast';
import useMyStore from '@/stores/my';

const OPTIONS = Object.freeze([
  {label: '최근 추가', type: 'favoriteNo'},
  {label: '닉네임', type: 'userNick'},
  {label: '최근 시작', type: 'lastBroadStart'},
]);

/**
 * 즐겨찾기 스트리머 관리 팝업
 * @param {Object} data - 팝업 데이터 (throwDialog 함수에서 전달)
 * @param {Function} handleClose - 팝업 닫기 함수 (팝업에서 전달)
 * @param {Object} ref
 * @returns {JSX.Element}
 */
const MyFavoriteSetting = forwardRef(function MyLayer({handleClose}, ref) {
  const [isAllAlarm, setIsAllAlarm] = useState(false);
  const [allStreamers, setAllStreamers] = useState([]);
  const {streamers, getStreamers} = useMyStore();

  useEffect(() => {
    setAllStreamers(streamers.map(formatStreamer));
  }, [streamers]);

  const handleAllAlarmChange = async (event) => {
    try {
      const isAllAlarm = event.target.checked;
      const changedStreamers = allStreamers
        .map((streamer) => ({
          ...streamer,
          isMobilePush: isAllAlarm ? 'Y' : 'N',
          isAlarm: isAllAlarm,
        }))
        .map(formatStreamer);
      setIsAllAlarm(isAllAlarm);
      setAllStreamers(changedStreamers);
      await MyFavoriteApi.setPush('all', isAllAlarm);
      const toastText = isAllAlarm
        ? '즐겨찾기한 모든 스트리머에게 알림을 받습니다.'
        : '즐겨찾기한 모든 스트리머에게 알림을 받지 않습니다.';

      throwToast(toastText);
    } catch (e) {
      // TODO: 에러 얼럿 띄우기
    }
  };

  useEffect(() => {
    if (allStreamers.length > 0) {
      const isEveryAlarm = allStreamers.every((streamer) => streamer.isAlarm);
      setIsAllAlarm(isEveryAlarm);
    }
  }, [allStreamers]);

  return (
    <MyStreamerSetting
      getStreamers={getStreamers}
      isAllAlarm={isAllAlarm}
      handleAllAlarmChange={handleAllAlarmChange}
      handleClose={handleClose}
      options={OPTIONS}
      isFavoriteStreamers
      allStreamers={allStreamers}
    />
  );
});

export default MyFavoriteSetting;
