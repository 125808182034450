'use client';

import MyHistoryApi from '@/api/my/history';
import {SectionHeader} from '@/components/main/common';
import {SoopUiSelectBox} from '@/components/ui';
import {useEffect, useState} from 'react';
import MyNoList from '@/components/my/common/item/NoList';
import MyBroadcastLayout from '@/components/my/common/MyBroadcastLayout';
import {ErrorBoundary} from '@/components/common';
import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';
import {BROADCAST_TYPES} from '@/constants';
import {CONTEXT_MENU} from '@/constants/my/contextMenu';
import throwToast from '@/utils/ui/toast';

export const UP_VOD_OPTIONS = Object.freeze([
  {label: '최근 추가', type: 'reg_date'},
  {label: '조회수', type: 'read_cnt'},
  {label: 'UP수', type: 'like_cnt'},
]);

const MyHistoryLater = () => {
  const [order, setOrder] = useState(UP_VOD_OPTIONS[0].type);
  const [broadcasts, setBroadcasts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreVod, setHasMoreVod] = useState(false);

  const handleOrderChange = (type) => {
    setOrder(type);
    setPage(1);
  };

  const fetchBroadcasts = async () => {
    const {vod, hasMore} = await MyHistoryApi.getUpVod({
      page,
      orderByColumn: order,
    });

    setHasMoreVod(hasMore);

    if (page === 1) {
      // 1페이지라면 갈아끼우기
      setBroadcasts(vod);
    } else {
      // 1페이지보다 크다면 추가하기
      setBroadcasts((prevVod) => {
        return [...prevVod, ...vod];
      });
    }
  };

  // 방송 목록 로드
  useEffect(() => {
    fetchBroadcasts();
  }, [order, page]);

  const handlePageChange = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const getContextMenu = (fileType) => {
    return fileType === 'CATCH' ? CONTEXT_MENU.UP.CATCH : CONTEXT_MENU.UP.VOD;
  };

  const handleContextMenuClick =
    (broadcastUserId, broadcastId) => async (event, type) => {
      if (type !== 'delete-up') {
        return;
      }

      try {
        const {message} = await MyHistoryApi.deleteUpVod({
          streamerId: broadcastUserId,
          titleNo: broadcastId,
        });
        throwToast(message);

        if (page === 1) {
          fetchBroadcasts();
        } else {
          setPage(1);
        }
      } catch (e) {
        throwToast('에러가 발생하였습니다.');
      }
    };

  return (
    <>
      <SectionHeader
        title='UP 한 VOD'
        rightElement={
          <>
            <SoopUiSelectBox
              options={UP_VOD_OPTIONS}
              type={order}
              onChange={handleOrderChange}
            />
          </>
        }
      />
      {broadcasts?.length > 0 ? (
        <>
          <MyBroadcastLayout type='grid'>
            {broadcasts.map((broadcast) => (
              <ErrorBoundary key={broadcast.titleNo}>
                <BroadcastLayout
                  broadcastType={BROADCAST_TYPES.VOD}
                  thumbnailUrl={broadcast.thumb}
                  viewCount={broadcast.readCnt}
                  createdDate={broadcast.regDate}
                  startDateTime={broadcast.broadStart}
                  userNickname={broadcast.userNick}
                  title={broadcast.titleName}
                  broadcastId={broadcast.titleNo}
                  userId={broadcast.userId}
                  hasBadge={
                    broadcast.categoryTags ||
                    broadcast.hashTags ||
                    broadcast.autoHashtags
                  }
                  categoryTags={broadcast.categoryTags}
                  hashTags={broadcast.hashTags}
                  autoHashTags={broadcast.autoHashtags}
                  vodType={broadcast.fileType}
                  categoryNo={Number(broadcast.category)}
                  accessibleAge={Number(broadcast.grade)}
                  allowsAdult={broadcast.isAdult}
                  isFan={broadcast.isFanclub}
                  isSubscribe={broadcast.isSubscribe}
                  duration={broadcast.duration}
                  contextMenu={{
                    menuList: getContextMenu(broadcast.fileType),
                  }}
                  authNumber={broadcast.authNo}
                  previewThumbnailUrl={broadcast.webpPath}
                  handleMenuClicked={handleContextMenuClick(
                    broadcast.userId,
                    broadcast.titleNo,
                  )}>
                  <BroadcastType.Vod />
                </BroadcastLayout>
              </ErrorBoundary>
            ))}
          </MyBroadcastLayout>
          {hasMoreVod && (
            <div className='show_more'>
              <button type='button' onClick={handlePageChange}>
                더보기
              </button>
            </div>
          )}
        </>
      ) : (
        <MyNoList title='UP 한 VOD가 없습니다.' />
      )}
    </>
  );
};

export default MyHistoryLater;
