import {useNavigate} from 'react-router-dom';
import {SoopUiSwiper} from '@/components/ui';

/**
 * SeriesGame 컴포넌트 ( 관련 스트리머 탭 )
 * @param {Object} props - 컴포넌트의 props
 * @param {Object} props.series - 시리즈 데이터
 * @param {Function} logCallback - 로그 관련 콜백 함수
 * @returns SeriesGame 컴포넌트
 */
const SeriesGame = ({series, logCallback = () => {}}) => {
  const navigate = useNavigate();

  // SCKACT 로그
  const handleSeriesGameActionLog = (type, index) => {
    let param = type ? {type: type} : '';
    logCallback && logCallback(param, index);
  };

  const handleSeriesGameClick = (title, index) => {
    handleSeriesGameActionLog('img', index);

    const query = {
      szSearchType: 'total',
      acttype: 'ctsp',
      szStype: 'ctsp',
      szKeyword: title,
      rs: '1',
      location: 'total_search',
    };
    const queryString = new URLSearchParams(query).toString();
    navigate(`/search?${queryString}`);
  };

  if (!Array.isArray(series) || series.length === 0) {
    return null;
  }

  return (
    <div className='serise_game'>
      <SoopUiSwiper
        swiperTag='ul'
        swiperClass='serise_list'
        hasControls={false}
        options={{
          slidesPerView: 'auto',
          loop: false,
        }}>
        {series?.map((item, index) => {
          return (
            <li key={`${item.title}_${index}`}>
              <a
                style={{cursor: 'pointer'}}
                onClick={() => {
                  handleSeriesGameClick(item.title, index);
                }}>
                <div className='thumb'>
                  <img src={item.pcImage} alt={item.title} />
                  <i className='ico_search'>자세히 보기</i>
                </div>
                <span className='tit'>{item.title}</span>
              </a>
            </li>
          );
        })}
      </SoopUiSwiper>
    </div>
  );
};

export default SeriesGame;
