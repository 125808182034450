'use client';

import {SectionHeader} from '@/components/main/common';
import useMyStore from '@/stores/my';
import {sendMyStreamerSettingClickLog} from '@/utils/main/my/log';
import {throwDialog} from '@/utils/ui/dialog';

const MyFanclubStreamerTitle = ({params}) => {
  const {fanclubStreamerCount} = useMyStore();

  const handleButtonClick = () => {
    sendMyStreamerSettingClickLog();
    throwDialog('my/FanclubSetting', {}, {isCustomDialog: true});
  };

  return (
    <>
      {/* 스트리머 영역 */}
      <SectionHeader
        title={
          <>
            스트리머
            <span className='total'>({fanclubStreamerCount}명)</span>
          </>
        }
        rightElement={
          <button
            type='button'
            className='fav_manage'
            onClick={handleButtonClick}>
            스트리머 관리
          </button>
        }
      />
    </>
  );
};

export default MyFanclubStreamerTitle;
