'use client';

import MyFanclubStreamerTitle from '@/components/my/fan/MyFanclubStreamerTitle';
import MyFanclubStreamers from '@/components/my/fan/MyFanclubStreamers';
import {LiveTitle, Live} from '@/components/my/favorite';

import {SectionHeader} from '@/components/main/common';
import MyFanclubVod from '@/components/my/fan/MyFanclubVod';
import useMyStore from '@/stores/my';
import MyNoList from '@/components/my/common/item/NoList';
import {useEffect} from 'react';
import {Banner} from '@/components/common';

/**
 * 팬클럽 페이지
 */
const MyFan = () => {
  const {fanclubStreamers, getFanclubStreamers} = useMyStore();

  useEffect(() => {
    getFanclubStreamers();
  }, []);

  return fanclubStreamers?.length > 0 ? (
    <>
      {/* 스트리머 영역 */}
      <MyFanclubStreamerTitle />
      <MyFanclubStreamers />

      {/* 서브 배너 영역 */}
      <Banner type='sub' category={'fanclub'} />

      {/* LIVE 영역 */}
      <LiveTitle />
      <Live />

      {/* VOD 영역 */}
      <SectionHeader title='VOD' />
      <MyFanclubVod />
    </>
  ) : (
    <>
      {/* 서브 배너 영역 */}
      <Banner type='sub' category={'fanclub'} />
      <MyNoList
        title='가입한 팬클럽이 없습니다.'
        description={
          <>
            <span>
              LIVE, VOD, 채널 등 다양한 곳에서 스트리머에게 선물하여
              <br /> 팬 전용 채팅 색깔과 팬 게시판을 이용해보세요.
            </span>
          </>
        }
      />
    </>
  );
};

export default MyFan;
