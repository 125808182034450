'use client';

import MyHistoryStoryApi from '@/api/my/history/story';
import {SoopUiSelectBox} from '@/components/ui';
import {MY_HISTORY_STORY_FILTER_LIST} from '@/constants/my/filter';
import {useEffect, useRef, useState} from 'react';
import MyNoList from '@/components/my/common/item/NoList';
import {Banner} from '@/components/common';
import {SectionHeader} from '@/components/main/common';
import {
  MyChildComment,
  MyParentComment,
  MyPost,
} from '@/components/my/history/story/item';
/**
 * @description 내 글/댓글 리스트 조회
 * @param {Object} param - 파라미터
 * @param {Number} param.page - 페이지 번호
 * @param {String} param.feedType - 게시글 타입
 * @returns
 */

const getMyHistoryStoryList = async ({page = '', feedType = ''}) => {
  try {
    const {data: myHistoryStoryList, meta} =
      await MyHistoryStoryApi.getMyHistoryStoryList({
        page: page,
        feed_type: feedType,
      });
    return {myHistoryStoryList, meta};
  } catch (error) {
    console.log(error);
    return {myHistoryStoryList: [], meta: null};
  }
};

const MyHistoryStoryList = () => {
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [myHistoryStoryList, setMyHistoryStoryList] = useState([]);
  const [hasMore, setHasMore] = useState(true); // infinite scroll
  const [page, setPage] = useState(1); // infinite scroll page
  const [feedType, setFeedType] = useState(''); // feed type ['POST', 'PARENT_COMMENT', 'CHILD_COMMENT']
  const listEndRef = useRef(null);

  // data fetch
  useEffect(() => {
    const fetchList = async () => {
      setIsLoading(true);
      try {
        const {myHistoryStoryList: list, meta} = await getMyHistoryStoryList({
          page: page,
          feedType: feedType,
        });
        setMyHistoryStoryList(list);
        // 1페이지의 데이터가 20개 미만이면 더 이상 불러올 데이터가 없다고 판단
        // infinite scroll을 통한 api 시도 자체를 미리 차단
        if (meta.currentPage === meta.lastPage) {
          setHasMore(false);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchList();
  }, [feedType]);

  // event
  const handleChangeItem = (newFeedType) => {
    // 게시글 타입이 기존 타입과 다른 경우
    if (feedType !== newFeedType) {
      setPage(1);
      setHasMore(true);
    }
    setFeedType(newFeedType);
  };
  // 삭제할 항목의 feedType과 id를 인자로 받는 refetch 함수
  const handleRefetch = (feedType = '', id = 0) => {
    let fieldName;
    switch (feedType) {
      case 'POST':
      case 'PHOTO':
        fieldName = 'titleNo';
        break;
      case 'PARENT_COMMENT':
        fieldName = 'pCommentNo';
        break;
      case 'CHILD_COMMENT':
        fieldName = 'cCommentNo';
        break;
      default:
        console.error('Unknown feedType: ', feedType);
        return;
    }
    // 기존 리스트에서 해당 ID를 가진 항목을 제외한 새로운 리스트를 만듭니다.
    setMyHistoryStoryList((prevList) =>
      prevList.filter((item) => item[fieldName] !== id),
    );
  };

  // infinite scroll fetch
  const fetchMoreList = async () => {
    setIsLoading(true);
    try {
      const {myHistoryStoryList: newList, meta} = await getMyHistoryStoryList({
        page: page + 1,
        feedType: feedType,
      });

      // 불러온 데이터 갯수가 20개 미만이면 더 이상 불러올 데이터가 없다고 판단
      if (meta.currentPage === meta.lastPage) {
        setHasMore(false);
      }
      setMyHistoryStoryList((prevList) => [...prevList, ...newList]);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // 새로고침 시 페이지 맨 위로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // intersection observer(Infinite Scroll)
  useEffect(() => {
    let observer;
    // data loading 중이 아니고, listEndRef가 존재하면
    if (!isLoading && listEndRef.current) {
      // IntersectionObserver 생성
      observer = new IntersectionObserver(
        // entries: 교차감지된 대상들의 배열
        (entries) => {
          // 관찰중인 대상이 화면에 보이게 되면(관찰중인 대상 교차감지 시) 호출될 callback 함수
          if (entries[0].isIntersecting && hasMore) {
            fetchMoreList();
          }
        },
        {threshold: 1.0}, // 대상이 얼마나 보여야 콜백 함수가 호출될지를 결정하는 임계값. 기본값은 1.0으로, 이 경우 대상이 부모 요소 내에서 100% 보여질 때 콜백 함수가 호출됨.(0.0 ~ 1.0)
      );
      // listEndRef를 관찰 대상으로 등록
      observer.observe(listEndRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect(); // Prevent memory leaks by disconnecting the observer
      }
    };
  }, [isLoading, hasMore]);

  // TODO: error case
  if (error) {
    return <div>데이터를 불러오는 중 오류가 발생했습니다.</div>;
  }

  // render
  return (
    <>
      {/* 서브 배너 영역 */}
      <Banner type='sub' category={'story'} />
      {myHistoryStoryList && (
        <>
          {/* Header: 내 글/댓글  */}
          <SectionHeader
            rightElement={
              <SoopUiSelectBox
                type={feedType}
                options={MY_HISTORY_STORY_FILTER_LIST}
                onChange={handleChangeItem}
              />
            }
          />
          {/* 내 글/댓글 리스트가 있는 경우 */}
          {/* MY_CLASS_NAME.HISTORY[item.feedType] */}
          {myHistoryStoryList.length > 0 && (
            <ul className='story_list'>
              {myHistoryStoryList.map((item, idx) => {
                switch (item.feedType) {
                  case 'POST': // 게시글
                  case 'PHOTO': // 사진 게시판에 작성한 게시글(형태는 POST와 동일)
                    return (
                      <MyPost
                        key={`post-${idx}`}
                        {...item}
                        refetching={handleRefetch}
                      />
                    );
                  case 'PARENT_COMMENT': // 댓글
                    return (
                      <MyParentComment
                        key={`parent-comment-${idx}`}
                        {...item}
                        refetching={handleRefetch}
                      />
                    );
                  case 'CHILD_COMMENT': // 답글
                    return (
                      <MyChildComment
                        key={`child-comment-${idx}`}
                        {...item}
                        refetching={handleRefetch}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </ul>
          )}
          <div ref={listEndRef} />

          {/* 내 글/댓글 리스트가 없는 경우 */}
          {!isLoading && myHistoryStoryList.length === 0 && (
            <MyNoList title={'작성한 게시글 또는 댓글이 없습니다.'} />
          )}
        </>
      )}
    </>
  );
};

export default MyHistoryStoryList;
