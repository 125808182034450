'use client';

import {
  StreamerTitle,
  TabGroup,
  Feed,
  Streamers,
  LiveTitle,
  Live,
  CatchStoryWrapper,
  UserClip,
  Vod,
  CatchWrapper,
  RecommendStreamer,
} from '@/components/my/favorite';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import useMyStore from '@/stores/my';
import NoList from '@/components/my/common/item/NoList';
import {Banner} from '@/components/common';

/**s
 * MyFavorite 페이지
 * @returns {JSX.Element} MyFavorite 페이지
 */
const MyFavorite = () => {
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId');
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const {streamers, getStreamers} = useMyStore();
  const [isGroupAll, setIsGroupAll] = useState(false);

  useEffect(() => {
    // TODO: 전체일 경우 queryString 을 달지 않도록 수정
    // 즐겨찾기 홈에 도착하자마자 groupId 를 달아줘야 한다
    if (typeof groupId !== 'string') {
      navigate('/my/favorite?groupId=', {replace: true});
    } else {
      getStreamers();
      setRender(true);
    }

    setIsGroupAll(groupId === '');
  }, [groupId]);

  return (
    <>
      {render &&
        (!isGroupAll || streamers?.length > 0 ? (
          <>
            {/* 즐겨찾기 그룹 영역 */}
            <TabGroup />

            {/* 스트리머 영역 */}
            <StreamerTitle />
            <Streamers groupId={groupId} />

            {/* 광고 서브 배너 영역 */}
            <Banner type='sub' category={'favorite'} />

            {/* 채널 새 글 영역 */}
            <Feed groupId={groupId} />

            {/* LIVE 영역 */}
            <LiveTitle />
            <Live groupId={groupId} />

            {/* Catch 스토리 영역 */}
            <CatchStoryWrapper groupId={groupId} />

            {/* 유저클립 영역 */}
            <UserClip groupId={groupId} />

            {/* VOD 영역 */}
            <Vod groupId={groupId} />

            {/* 관심 Catch 모아보기 */}
            <CatchWrapper groupId={groupId} />

            {/* 추천 스트리머 */}
            <RecommendStreamer />
          </>
        ) : (
          <>
            {/* 서브 배너 영역 */}
            <Banner type='sub' category={'favorite'} />
            <NoList
              title='즐겨찾기 한 스트리머가 없습니다'
              description={
                <>
                  <span>
                    좋아하는 스트리머를 즐겨찾기 하면 언제든 편하게 방송을 찾아
                    볼 수 있으며,
                    <br />
                    방송 시작 시 알림을 받을 수 있습니다.
                  </span>
                </>
              }
            />
          </>
        ))}
    </>
  );
};

export default MyFavorite;
