import jsonp from 'jsonp';
import {SchApi, ScketcApi} from '@/api/sch';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import {
  STOPWORD_PARAMETER,
  VOD_PARAMETER,
  LIVE_PARAMETER,
  getSelectParameter,
  PROFILE_THEME_PARAMETER,
} from '@/constants/search/api-parameter';

dayjs.locale('ko');

const SearchApi = {
  getStopWord: async ({
    keyword = '',
    originalKeyword = '',
    modifiedKeyword = '',
    acttype = '',
    userId = null,
    szStype = '',
    pathSearch = '',
    ac_type = '',
    location = '',
    pk_cnt,
    has_hint,
    tagname,
  }) => {
    let params = {
      ...STOPWORD_PARAMETER.FIXED,
      d: keyword,
      w: 'webk',
      acttype: acttype || 'total',
      uid: userId,
      ut: 'sv',
      stype: pathSearch || szStype,
      original: keyword,
      location: location,
      original: szStype === 'mk' ? originalKeyword : '',
      modified: szStype === 'mk' ? modifiedKeyword : '',
      ac_type,
      pk_cnt: szStype === 'pk' ? pk_cnt : '',
      has_hint: szStype === 'pk' ? has_hint : '',
      isHashSearch: tagname ? 1 : 0,
    };
    try {
      const response = await ScketcApi.get(
        '/api.php',
        getSelectParameter(params),
      );
      return response;
    } catch (error) {
      return {
        modified: '',
        origin: '',
        stopword: '',
        isstop: '',
        postpos: '',
        sessionKey: '',
      };
    }
  },
  /**
   * 프로필 테마
   * @param params
   * @return {Promise<void>}
   */
  getProfileTheme: async ({
    //   ScketcApi
    sessionKey,
    keyword,
    pttype,
    order = '', // 게임프로필 관련 bj 정렬 방식
    orderBy = '',
    date = '',
    size = '',
  }) => {
    const currentDate = new Date();
    const parsedDate = dayjs(currentDate, 'YYYY-MM-DD HH:mm:ss');
    const formatDate = parsedDate.format('YYYY-MM-DD');

    let params = {
      ...PROFILE_THEME_PARAMETER.FIXED,
      sessionKey: sessionKey,
      d: keyword,
      pttype: pttype,
      date: date === '' ? formatDate : date,
      order: order,
      orderBy: orderBy,
      size: size,
    };

    try {
      const response = await ScketcApi.get(
        '/api.php',
        getSelectParameter(params),
      );
      return response;
    } catch (error) {
      return {
        advertisement: {},
        recommendContents: [],
        homonym: [],
        gameProfile: [],
        esport: [],
        profile: [],
        latestVod: [],
        recommendBj: [],
        relatedBj: [],
        theme: [],
        themeShortcuts: [],
        themeBj: [],
        themeVod: [],
      };
    }
  },
  /**
   * 연관 검색어, 실시간 검색어
   * @param params
   * @return {Promise<void>}
   */
  getUnifiedSearch: async ({sessionKey, keyword}) => {
    let params = {
      m: 'unifiedSearch',
      keyword: keyword,
      character: 'UTF-8',
      limit: 20,
      sessionKey: sessionKey,
    };
    try {
      const response = await SchApi.get('/api.php', params);
      return response;
    } catch (error) {
      return {relatedSearchTerms: []};
    }
  },

  /**
   * Live
   * @param {string} m 서비스명
   * @param {string} v 버전
   * @param {string} c 언어코드
   * @param {Number} h ???
   * @param {Number} onlyParent ???
   * @param {Number} isHashSearch ???
   * @param {string} szKeyword 검색키워드
   * @param {Number} nPageNo 페이지 번호
   * @param {Number} nListCnt [페이지당 결과수]
   * @param {string} w 호출 경로
   * @param {string} location 접근하는 페이지 코드
   * @param {string} tab 검색한 현재 탭 코드
   * @return {Promise<void>}
   */
  getLiveSearch: async ({
    sessionKey,
    tagname,
    order,
    keyword,
    pageNo,
    perPage,
    tab,
    location,
    orderType,
  }) => {
    //   SchApi
    let params = {
      ...LIVE_PARAMETER.FIXED,
      sessionKey: sessionKey,
      szOrder: order,
      szOrderType: orderType,
      szKeyword: keyword,
      nPageNo: pageNo,
      nListCnt: perPage,
      tab: tab,
      location: location,
      isHashSearch: tagname ? 1 : 0,
    };

    try {
      const response = await SchApi.get('/api.php', getSelectParameter(params));
      return response;
    } catch (error) {
      return {realBroad: [], hasMoreList: false};
    }
  },
  /**
   *
   * @param keyword
   * @param pageNo
   * @param perPage
   * @param order
   * @param orderType
   * @param fileType
   * @param term
   * @param tab
   * @param location
   * @return {Promise<any|null>}
   */
  getVodSearch: async ({
    sessionKey,
    tagname,
    keyword = '',
    pageNo = 1,
    perPage = 12,
    order = '',
    fileType = '',
    term = '',
    tab = '',
    location = '',
  }) => {
    let params = {
      ...VOD_PARAMETER.FIXED,
      v: tab === 'HASH' ? '4.0' : '5.0',
      sessionKey: sessionKey,
      szKeyword: keyword,
      nPageNo: pageNo,
      nListCnt: perPage,
      szOrder: order,
      szFileType: fileType,
      szTerm: term,
      tab: tab,
      location: location,
      isHashSearch: tagname ? 1 : 0,
    };

    try {
      const response = await SchApi.get('/api.php', getSelectParameter(params));
      return response;
    } catch (error) {
      return {data: [], catchData: [], catchStoryData: [], hasMoreList: false};
    }
  },
  /**
   * 게시글
   * @param {string} m 채널 게시글 검색
   * @param {string} v 버전
   * @param {string} szKeyword 키워드
   * @param {string} nPageNo 현재 페이지번호
   * @param {string} nListCnt 한번에 호출할 데이터 수
   * @param {string} szOrder 정렬값
   * @param {string} szOrderType 정렬기준
   * @param {string} szTerm 기간
   * @param {string} nUseFiltering 필터링 기능 사용 여부
   * @param {string} isMobile 플랫폼정보
   * @param {string} isHashSearch 해시태그 검색 여부
   * @return {Promise<void>}
   */
  getPostSearch: async ({
    sessionKey,
    keyword = '',
    pageNo = 1,
    perPage = 6,
    order = '',
    termType = '',
    tab = '',
  }) => {
    //   SchApi
    let params = {
      l: 'DF',
      m: 'postsSearch',
      v: '1.0',
      sessionKey: sessionKey,
      szKeyword: keyword,
      nPageNo: pageNo,
      nListCnt: perPage,
      szOrder: order,
      szTerm: termType,
      nUseFiltering: 1,
      isMobile: 0,
      isHashSearch: 0,
      tab: tab,
    };
    try {
      const response = await SchApi.get('/api.php', params);
      return response;
    } catch (error) {
      return {data: [], totalCnt: 0};
    }
  },
  /**
   * 스트리머
   * @param {string} m 서비스명
   * @param {string} v 버전
   * @param {string} szKeyword 검색키워드
   * @param {string} szOrder 정렬방식
   * @param {Number} nPageNo 페이지 번호
   * @param {Number} nListCnt 페이지당 결과수
   * @param {string} l 검색 로직 식별 값
   * @param {string} w 호출하는 경로
   * @param {string} ut useType   // TODO BJ검색 위키에 기입 예정이라 쓰여있는데 아직 쓰여있는게 없음 (필수값) >> 확인하기
   * @return {Promise<void>}
   */
  getStreamerSearch: async ({
    sessionKey,
    keyword,
    pageNo,
    perPage,
    order,
    tab = '',
  }) => {
    //   SchApi
    let params = {
      l: 'DF',
      m: 'bjSearch',
      v: '2.0',
      sessionKey: sessionKey,
      szOrder: order,
      szKeyword: keyword,
      nPageNo: pageNo,
      nListCnt: perPage,
      w: 'webk',
      tab: tab,
    };
    try {
      const response = await SchApi.get('/api.php', params);
      return response;
    } catch (error) {
      return {data: [], totalCnt: 0};
    }
  },

  /**
   * 태그트랜드
   * @param {string} m 서비스명
   * @param {string} service 'rank'
   * @return {Promise<void>}
   */
  getTagTrend: async (params) => {
    try {
      const response = await SchApi.get('/api.php', params);
      return response;
    } catch (error) {
      return {rank: [], result: 1};
    }
  },

  // 즐겨찾기 기능을 위한 메소드
  // 백엔드측 api json 추가 작업이 되어있지 않아 jsonp로 호출
  manageFavorite: async (szWork, userId, streamerId) => {
    let params = {
      szWork: szWork,
      szBjId: userId,
      favorite: streamerId,
    };

    try {
      // JSONP 호출
      const response = await new Promise((resolve, reject) => {
        jsonp(
          `https://live.sooplive.co.kr/afreeca/favorite_api.php?${new URLSearchParams(params)}`,
          {name: 'callback'},
          (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          },
        );
      });

      return response;
    } catch (error) {
      // 에러 처리
      console.error('Error managing favorite:', error);
      return null;
    }
  },
};
export default SearchApi;
