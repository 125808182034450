import {
  BroadcastInfo,
  BroadcastThumbnail,
} from '@/components/main/common/Broadcast/index';
import {useBroadcastContext} from '@/components/main/common/Broadcast/BroadcastProvider';
import {VOD_TYPES} from '@/constants';

const BroadcastTypeMain = () => {
  const {broadcastType} = useBroadcastContext();
  return (
    <div className='cBox-info'>
      {broadcastType === 'vod' && <Vod />}
      {broadcastType === 'live' && <Live />}
    </div>
  );
};

const Vod = () => {
  const {vodType, isShowChips} = useBroadcastContext();

  return (
    <>
      {vodType === VOD_TYPES.PLAYLIST ? (
        <>
          <BroadcastThumbnail.PlayListThumbnail />
          <BroadcastInfo.PlaylistInfo />
        </>
      ) : (
        <>
          <BroadcastThumbnail.VodThumbnail />
          <BroadcastInfo>
            <BroadcastInfo.Title />
            {isShowChips && <BroadcastInfo.Chips />}
            <BroadcastInfo.TitleHistory />
          </BroadcastInfo>
        </>
      )}
    </>
  );
};

const Live = () => {
  return (
    <>
      <BroadcastThumbnail.LiveThumbnail />
      <BroadcastInfo>
        <BroadcastInfo.Title />
        <BroadcastInfo.Chips />
      </BroadcastInfo>
    </>
  );
};

const BroadcastType = Object.assign(BroadcastTypeMain, {
  Vod,
  Live,
});

export default BroadcastType;
