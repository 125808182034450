'use client';
import {SoopUiProfileIconMenu} from '@/components/ui';
import {decodeEmoticon} from '@/libs/emoticon';
import globalDomains from '@/utils/global-domains';
import {convertViewCnt} from '@/utils/main';
import {useRef} from 'react';

/**
 * @typedef {Object} count
 * @property {number} readCnt - 조회 수
 */

/**
 * @description 채널 새 글 내 실시간 인기글 목록
 * @property {string} userId - 작성자 ID
 * @property {string} userNick - 작성자 닉네임
 * @property {string} profileImage - 작성자 프로필 이미지
 * @property {string} titleName - 제목
 * @property {string} content - 내용
 * @property {string} url - URL 주소
 * @property {string} regDate - 등록 날짜
 * @property {Object} count - 카운트 정보
 * @property {Object[]} photos - 이미지 리스트
 * @returns
 */

const RealTimePopularPost = ({
  userId = '',
  userNick = '',
  profileImage = '',
  titleName = '',
  content = '',
  url = '',
  regDate = '',
  count: {readCnt = 0} = {readCnt: 0}, // count object와 readCnt의 기본값을 둘 다 설정
  photos = [],
}) => {
  const soopUiProfileIconMenu = useRef(null);

  return (
    <>
      <div className='list-item'>
        {/* 작성자 프로필 이미지, 작성자, 작성일, 조회수 */}
        <div className='autor_wrap'>
          <span className='thum'>
            <a href={`${globalDomains.BJ_AFREECATV}/${userId}`} target='_blank'>
              <img src={profileImage} alt='프로필 이미지' />
            </a>
          </span>
          <div className='info_box'>
            <button
              type='button'
              className='nick'
              onClick={() => {
                soopUiProfileIconMenu.current?.open();
              }}>
              <p>{userNick}</p>
            </button>
            <SoopUiProfileIconMenu
              ref={soopUiProfileIconMenu}
              streamerId={userId}
              streamerNick={userNick}
            />
            <span>{regDate}</span>
            <span>{`조회 ${convertViewCnt(readCnt)}`}</span>
          </div>
        </div>
        {/* 제목, 본문 */}
        <div className='item_contents'>
          <a href={url} target='_blank'>
            <h3>{titleName}</h3>
            <p
              className='text'
              dangerouslySetInnerHTML={{__html: decodeEmoticon(content)}}></p>
          </a>
        </div>

        {/* 이미지 썸네일(photos?.[0]?.url) */}
        {photos.length > 0 && (
          <div className='file'>
            <a href={url} target='_blank'>
              <img className='image' src={photos?.[0]?.url} alt='' />
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default RealTimePopularPost;
