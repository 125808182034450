import LiveApi from '../live';
import {SchApi} from '../sch';

const CategoryApi = {
  /**
   * 카테고리 상세정보 가져오는 api
   * @param {String} m - 'categoryInfo'
   * @param {String} szCateNo - 카테고리번호
   * @param {String} szPlatform - PC
   * @returns {Object}
   */
  async getCategoryInfo(queryParams) {
    try {
      const response = await SchApi.get('/api.php', queryParams);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 카테고리 상세정보와 관련된 live,vod,catch 리스트 가져오는 api
   * @param {String} m - 'categoryContentsList'
   * @param {String} szType - live/vod/catch
   * @param {Number} szCateNo - 카테고리번호
   * @param {String} szOrder - view_cnt_desc/view_cnt_asc/reg_date
   * @param {String} szFileType - 빈값(전체) REVIEW(다시보기),NORMAL(업로드VOD), CLIP(클립), -> szType 이 vod 일때만
   * @param {String} szTerm - 빈값(전체) 1day,1week,1month,1year -> szType 이 vod 일때만
   * @param {Number} nPageNo - 페이지 번호
   * @param {Number} nListCnt - 리스트 갯수
   * @param {String} szPlatform - PC
   * @returns {Object}
   */
  async getCategoryContents(queryParams) {
    try {
      const response = await SchApi.get('/api.php', queryParams);
      return response?.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 스트리머 차단 리스트 가져오는 api
   * @returns {Object}
   */
  async getHiddenStreamerList() {
    try {
      const response = await LiveApi.get(
        '/api/hiddenbj/hiddenbjController.php',
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export default CategoryApi;
