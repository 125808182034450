import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

/*
 * sharePopup
 *  title, pageUrl, kakaoParams
 * */
const store = (set) => ({
  sharePopup: null,

  showSharePopup: (data) => {
    set((state) => {
      return {
        sharePopup: {
          title: data.title,
          pageUrl: data.pageUrl,
          kakaoParams: data.kakaoParams,
          parentPosition: data.parentPosition,
        },
      };
    });
  },
  closeSharePopup: () => {
    set((state) => {
      return {
        sharePopup: null,
      };
    });
  },
});

const useSharePopupStore = create(devtools(store));

export default useSharePopupStore;
