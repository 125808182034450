'use client';
import useGlobalUIStore from '@/stores';
import globalDomains from '@/utils/global-domains';

import {useEffect, useState, memo} from 'react';

const DEFAULT_BANNER_THUMB_TMG = `${globalDomains.RES_AFREECATV}/images/afmain/img_thumb_v_dark.gif`;
const DEFAULT_THUMB_IMG = `${globalDomains.RES_AFREECATV}/images/svg/thumb_default.svg`;
const DEFAULT_PROFILE_IMG = `${globalDomains.RES_AFREECATV}/images/svg/thumb_profile.svg`;

export const DEFAULT_POSTER_THUMB_TMG = (isDarkMode) =>
  `${globalDomains.RES_AFREECATV}/images/svg/category_thumb_default${isDarkMode ? '_dark' : ''}.svg`;

/**
 * SoopUiImg 컴포넌트
 * @desc 이미지를 렌더링하고, 이미지 로딩 실패 시 대체 이미지를 보여줌
 * @param {Object} props - 컴포넌트의 props
 * @param {string} props.src - 렌더링할 이미지의 URL
 * @param {string} [props.className] - 이미지의 클래스명
 * @param {string} [props.alt='image'] - 이미지의 alt 값
 * @param {Function} [props.onError] - 이미지 로딩이 실패했을 때 호출되는 콜백 함수
 * @param {string} [props.type] - 이미지 타입 (thumbs, banner, poster)
 * @returns {JSX.Element} 이미지를 렌더링하는 JSX 요소
 * @returns {Function} current.setSource - 내부 img 태그src 직접 제어
 */

export const SOOP_UI_IMG_TYPES = {
  thumbnail: 'thumbs',
  banner: 'banner',
  poster: 'poster',
};

const SoopUiImg = ({src, className, alt = 'image', onError, type = ''}) => {
  const {isDarkMode} = useGlobalUIStore();
  const [currentSrc, setCurrentSrc] = useState(src);
  const {protocol} = window.location;
  /**
   * 이미지 로딩이 실패했을 때 호출되는 함수
   * @param {Event} event - onError 이벤트 객체
   */
  const handleImageRenderFailed = (event) => {
    if (onError) {
      onError(event);
    }

    // 타입에 따라 대체 이미지 설정
    if (type === 'thumbs') {
      setCurrentSrc(DEFAULT_THUMB_IMG);
    } else if (type === 'banner') {
      setCurrentSrc(DEFAULT_BANNER_THUMB_TMG);
    } else if (type === 'poster') {
      setCurrentSrc(DEFAULT_POSTER_THUMB_TMG(isDarkMode));
    } else {
      setCurrentSrc(DEFAULT_PROFILE_IMG);
    }
  };

  useEffect(() => {
    setCurrentSrc(src); // 초기 이미지 설정
  }, [src]);

  return (
    <img
      src={
        currentSrc?.includes('http') ? currentSrc : `${protocol}${currentSrc}`
      }
      className={className}
      onError={handleImageRenderFailed}
      alt={alt}
      loading='lazy'
    />
  );
};

export default memo(SoopUiImg);
