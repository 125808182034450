export const ROUTE = {
  INDEX: '/',
  MY: {
    FAVORITE: '/my/favorite',
    SUBSCRIBE: '/my/subscribe',
    FAN: '/my/fan',
    HISTORY: {
      RECENT: {
        INDEX: '/my/history/recent',
        VOD: '/my/history/recent/vod',
      },
      LATER: '/my/history/later',
      UP: '/my/history/up',
      PLAYLIST: '/my/history/playlist',
      STORY: '/my/history/story',
    },
    FEED: {
      INDEX: '/my/feed',
      HOT_ISSUE: '/my/feed/hotissue',
      _USER_ID: '/my/feed/:userId',
    },
  },
  SEARCH: '/search',
  DIRECTORY: {
    CATEGORY: {
      INDEX: '/directory/category',
      _CATEGORY_MENU: '/directory/category/:categoryMenu',
      _CATEGORY_NAME: {
        _CATEGORY_TYPE: '/directory/category/:categoryName/:categoryType',
      },
    },
  },
  LIVE: {
    ALL: '/live/all',
    GROUP: {
      _TYPE: '/live/group/:type',
    },
  },
};
