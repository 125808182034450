import LiveAll from '@/app/(main)/live/all';
import LiveGroup from '@/app/(main)/live/group/[...type]';
import {Route, Routes} from 'react-router-dom';
import NotFound from './not-found';
import CustomRoutes from './CustomRoutes';
import {ROUTE} from '@/constants/main/route';

export default function LiveRoute() {
  return (
    <>
      <CustomRoutes>
        <Route path={ROUTE.LIVE.ALL} element={<LiveAll />} />
        <Route path={ROUTE.LIVE.GROUP._TYPE} element={<LiveGroup />} />
      </CustomRoutes>
    </>
  );
}
