import {useEffect} from 'react';

/**
 * 이벤트 이외 영역 클릭시 이벤트 닫아주는 공통 hook
 * @param {Object} ref - Element ref
 * @param {Function} handler - 이벤트
 * @param {boolean} isActive - 활성화 유무
 * @param {int} targetIndex - 닫아야 할 창이 여러 개일 때 닫을 창의 index
 */

export function useOutsideClick(
  ref,
  handler,
  isActive = true,
  targetIndex = 0,
) {
  useEffect(() => {
    if (!isActive) {
      return;
    }
    const listener = (event) => {
      if (!ref.current) {
        return;
      }

      // 닫는 창이 하나일 때
      if (ref.current.contains && ref.current.contains(event.target)) {
        return;
      }

      // 닫는 창이 여러 개일 때
      if (Array.isArray(ref.current)) {
        if (
          ref.current[targetIndex] &&
          ref.current[targetIndex].contains(event.target)
        ) {
          return;
        }
      }
      handler && handler(event);
    };
    document.addEventListener('click', listener);
    return () => {
      document.removeEventListener('click', listener);
    };
  }, [ref, handler, isActive, targetIndex]);
}
