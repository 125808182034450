'use client';
import _ from 'lodash';
import useToastStore from '@/stores/ui/toast';

const throwToast = (
  message = '',
  className = '',
  children = null,
  timeout = 3000,
) => {
  const id = _.uniqueId('soop-dialog-');

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useToastStore.getState().addToast(id, message, className, children, timeout);

  setTimeout(() => {
    useToastStore.getState().popToast(id);
  }, timeout);
};
export default throwToast;
