import {create} from 'zustand';
import {camelCase, sortBy} from 'lodash';
import {devtools} from 'zustand/middleware';
import MyFavoriteApi from '@/api/my/favorite';
import {sortStreamers} from '@/utils/main/my/sort';
import MySubscribeApi from '@/api/my/subscribe';
import MyFanApi from '@/api/my/fan';
import MyFeedApi from '@/api/my/feed';
import {ROUTE} from '@/constants/main/route';

export const MY_SORT = Object.freeze({
  VIEW_COUNT: 'total_view_cnt',
  RECENT_START: 'broad_start',
  RECENT_WATCH: 'order_no',
});

export const MY_SORT_ORDER = Object.freeze({
  ASC: 'asc',
  DESC: 'desc',
});

/**
 * Broadcasts를 주어진 sortOption에 따라 정렬
 * @param {Object[]} broadcasts - 정렬할 방송 목록
 * @param {Object} sortOption - 정렬 옵션
 * @param {'total_view_cnt' | 'broad_start' | 'order_no'} sortOption.sortKey - 정렬 키
 * @param {'desc' | 'asc'} sortOption.sortOrder - 정렬 순서
 * @returns {Object[]} 정렬된 방송 목록
 */
export const sortBroadcasts = (broadcasts, sortOption) => {
  const {sortKey, sortOrder} = sortOption;

  let sortedItems = [];

  if (sortKey === MY_SORT.RECENT_START) {
    sortedItems = sortBy(
      broadcasts,
      (item) => new Date(item.broadStart || item.broadcastStartTime),
    );
  } else if (sortKey === MY_SORT.VIEW_COUNT) {
    sortedItems = sortBy(
      broadcasts,
      (item) => item.viewCount || item.totalViewCnt,
    );
  } else if (sortKey === MY_SORT.RECENT_WATCH) {
    sortedItems = sortBy(broadcasts, (item) => item.recentWatch);
  } else {
    const camelizedSortKey = camelCase(sortKey);
    sortedItems = sortBy(broadcasts, (item) => item[camelizedSortKey]);
  }

  if (sortOrder === MY_SORT_ORDER.DESC) {
    sortedItems = sortedItems.reverse();
  }

  return sortedItems;
};

/**
 * Zustand를 사용하여 상태 관리를 수행하는 스토어를 생성
 * @param {Function} set - Zustand의 상태를 설정하는 함수
 * @param {Function} get - Zustand의 상태를 가져오는 함수
 * @returns {Object} 생성된 스토어
 */
const store = (set, get) => {
  return {
    liveBroadcasts: [],
    liveSortKey: MY_SORT.VIEW_COUNT,
    liveSortOrder: MY_SORT_ORDER.DESC,
    streamers: [],
    subscribeStreamers: [],
    fanclubStreamers: [],
    hotissue: {},
    vod: [],
    myInfo: {},
    favoriteGroups: [],
    favoriteStreamersCount: 0,
    subscribeStreamerCount: 0,
    fanclubStreamerCount: 0,
    recommendBroadcast: [],
    /**
     * 라이브 방송 목록을 설정하고, 주어진 sortOption에 따라 정렬
     * @param {Object[]} broadcasts - 방송 목록
     * @param {Object} sortOption - 정렬 옵션
     * @param {'total_view_cnt' | 'broad_start' | 'recent_watch'} sortOption.sortKey - 정렬 키
     * @param {'desc' | 'asc'} sortOption.sortOrder - 정렬 순서
     */
    setLiveBroadcasts: (broadcasts, sortOption) => {
      const sortedBroadcasts = sortBroadcasts(broadcasts, sortOption);
      set({liveBroadcasts: sortedBroadcasts});
    },
    /**
     * 라이브 방송을 가져오는 함수
     * @desc 최신 상태의 스트리머 목록을 가져오고, 방송을 정렬 옵션에 따라 정렬한다
     */
    getLiveBroadcasts: () => {
      const pathname = location.pathname;

      const streamers = () => {
        const streamerDataByRoute = {
          [ROUTE.MY.FAVORITE]: get().streamers,
          [ROUTE.MY.FAN]: get().fanclubStreamers,
          [ROUTE.MY.SUBSCRIBE]: get().subscribeStreamers,
        };

        return streamerDataByRoute[pathname] || [];
      };

      const liveBroadcasts = streamers().flatMap(
        (favorite) => favorite.broadInfo,
      );
      get().setLiveBroadcasts(liveBroadcasts, {
        sortKey: get().liveSortKey,
        sortOrder: get().liveSortOrder,
      });
    },
    /**
     * 현재 라이브 방송 목록을 주어진 sortOption에 따라 정렬
     * @param {Object} sortOption - 정렬 옵션
     * @param {'total_view_cnt' | 'broad_start' | 'recent_watch'} sortOption.sortKey - 정렬 키
     * @param {'desc' | 'asc'} sortOption.sortOrder - 정렬 순서
     */
    sortLiveBroadcasts: (sortOption) => {
      set((state) => {
        const sortedBroadcasts = sortBroadcasts(
          state.liveBroadcasts,
          sortOption,
        );
        return {liveBroadcasts: sortedBroadcasts};
      });
    },
    /**
     * 라이브 방송 정렬 키를 설정
     * @param {'total_view_cnt' | 'broad_start' | 'recent_watch'} sortKey - 정렬 키
     */
    setLiveSortKey: (sortKey) => {
      set({liveSortKey: sortKey});
    },
    /**
     * 라이브 방송 정렬 순서를 설정
     * @param {'desc' | 'asc'} sortOrder - 정렬 순서
     */
    setLiveSortOrder: (sortOrder) => {
      set({liveSortOrder: sortOrder});
    },
    setStreamers: (streamers) => {
      set({streamers});
    },
    /**
     * 스트리머 변경
     * @param {number} idx - 스트리머 인덱스
     * @param {Object} streamer - 변경할 스트리머
     */
    setStreamer(idx, streamer) {
      set((state) => {
        const newStreamers = [...state.streamers];
        newStreamers[idx] = {
          ...newStreamers[idx],
          ...streamer,
        };
        return {streamers: newStreamers};
      });
    },
    /**
     * 스트리머를 가져오고 핀 상태, 방송 상태, 주어진 키로 정렬하고 상태 변경
     */
    async getStreamers() {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        const groupId = searchParams.get('groupId');
        const {favorites} = await MyFavoriteApi.getFavorites(groupId);
        const sortedStreamers = sortStreamers(favorites, 'favoriteNo', {
          fixedOrder: ['isPin', 'isLive'],
        });
        set({
          streamers: sortedStreamers,
          favoriteStreamersCount: (favorites || []).length,
        });
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * 구독한 스트리머를 가져오고 핀 상태, 방송 상태, 주어진 키로 정렬하고 상태 변경
     */
    async getSubscribeStreamers() {
      try {
        const {subscribes} = await MySubscribeApi.getSubscribes();
        const sortedStreamers = sortStreamers(subscribes, 'subscribeNo', {
          fixedOrder: ['isPin', 'isLive'],
        });
        set({
          subscribeStreamers: sortedStreamers,
          subscribeStreamerCount: (subscribes || []).length,
        });
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * 팬클럽한 스트리머를 가져오고 핀 상태, 방송 상태, 주어진 키로 정렬하고 상태 변경
     */
    async getFanclubStreamers() {
      try {
        const {fan} = await MyFanApi.getFanClub();
        const sortedStreamers = sortStreamers(fan, 'fanclubNo', {
          fixedOrder: ['isPin', 'isLive'],
        });
        set({
          fanclubStreamers: sortedStreamers,
          fanclubStreamerCount: (fan || []).length,
        });
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * 핫이슈 페이지 feed 가져오기
     */
    async getHotissue() {
      try {
        const data = await MyFeedApi.getFeedHotissue();
        set({hotissue: data});
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * 핫이슈 페이지 vod 가져오기
     */
    async getHotissueVod() {
      try {
        const data = await MyFeedApi.getHotissueVod();
        set({vod: data});
      } catch (e) {
        console.error(e);
      }
    },
    /**
     * 내 정보 셋팅하기
     * @param {Object} user
     * @param {boolean} user.isLogin
     * @param {number} user.adFrame
     * @param {number} user.adPoint
     * @param {string} user.stationNumber
     * @param {number} user.unreadNoteCount
     * @param {string} user.userId
     * @param {string} user.userNick
     * @param {string} user.verifiedStateCode
     */
    async setMyInfo(user) {
      set({myInfo: user});
    },

    /**
     * 즐겨찾기 그룹 가져오기
     */
    async getFavoriteGroups() {
      try {
        const data = await MyFavoriteApi.getFavoriteGroups();
        set({favoriteGroups: data});
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 추천 방송 셋팅하기
     * @param {Object[]} broadcasts
     */
    setRecommendBroadcast(broadcasts) {
      set({
        recommendBroadcast: broadcasts,
      });
    },
  };
};
const useMyStore = create(devtools(store));

export default useMyStore;
