import React, {useState, useRef, useEffect} from 'react';

const observerMap = new Map();
const RootIds = new WeakMap();
let rootId = 0;

let unsupportedValue;

export const defaultFallbackInView = (inView) => {
  unsupportedValue = inView;
};

const getRootId = (root) => {
  if (!root) return '0';
  if (RootIds.has(root)) return RootIds.get(root);
  rootId += 1;
  RootIds.set(root, rootId.toString());
  return RootIds.get(root);
};

const optionsToId = (options) => {
  return Object.keys(options)
    .sort()
    .filter((key) => options[key] !== undefined)
    .map(
      (key) =>
        `${key}_${key === 'root' ? getRootId(options.root) : options[key]}`,
    )
    .toString();
};

const createObserver = (options) => {
  const id = optionsToId(options);
  let instance = observerMap.get(id);

  if (!instance) {
    const elements = new Map();
    const thresholds = Array.isArray(options.threshold)
      ? options.threshold
      : [options.threshold || 0];

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const inView =
          entry.isIntersecting &&
          thresholds.some((threshold) => entry.intersectionRatio >= threshold);

        if (options.trackVisibility && typeof entry.isVisible === 'undefined') {
          entry.isVisible = inView;
        }

        elements.get(entry.target)?.forEach((callback) => {
          callback(inView, entry);
        });
      });
    }, options);

    instance = {
      id,
      observer,
      elements,
    };

    observerMap.set(id, instance);
  }

  return instance;
};

export const observe = (
  element,
  callback,
  options = {},
  fallbackInView = unsupportedValue,
) => {
  if (
    typeof window.IntersectionObserver === 'undefined' &&
    fallbackInView !== undefined
  ) {
    const bounds = element.getBoundingClientRect();
    callback(fallbackInView, {
      isIntersecting: fallbackInView,
      target: element,
      intersectionRatio:
        typeof options.threshold === 'number' ? options.threshold : 0,
      time: 0,
      boundingClientRect: bounds,
      intersectionRect: bounds,
      rootBounds: bounds,
    });
    return () => {};
  }

  const {id, observer, elements} = createObserver(options);

  let callbacks = elements.get(element) || [];
  if (!elements.has(element)) {
    elements.set(element, callbacks);
  }

  callbacks.push(callback);
  observer.observe(element);

  return () => {
    callbacks.splice(callbacks.indexOf(callback), 1);

    if (callbacks.length === 0) {
      elements.delete(element);
      observer.unobserve(element);
    }

    if (elements.size === 0) {
      observer.disconnect();
      observerMap.delete(id);
    }
  };
};

export const useInfiniteScroll = ({
  threshold,
  delay,
  trackVisibility,
  rootMargin,
  root,
  triggerOnce,
  skip,
  initialInView,
  fallbackInView,
  onChange,
  onClick,
} = {}) => {
  const [ref, setRef] = useState(null);
  const callback = useRef();
  const [state, setState] = useState({
    inView: !!initialInView,
    entry: undefined,
  });

  callback.current = onChange;

  useEffect(() => {
    if (skip || !ref) return;

    let unobserve;
    unobserve = observe(
      ref,
      (inView, entry) => {
        setState({
          inView,
          entry,
        });
        if (callback.current) callback.current(inView, entry);

        if (entry.isIntersecting && triggerOnce && unobserve) {
          unobserve();
          unobserve = undefined;
        }
      },
      {
        root,
        rootMargin,
        threshold,
        trackVisibility,
        delay,
      },
      fallbackInView,
    );

    return () => {
      if (unobserve) {
        unobserve();
      }
    };
  }, [
    Array.isArray(threshold) ? threshold.toString() : threshold,
    ref,
    root,
    rootMargin,
    triggerOnce,
    skip,
    trackVisibility,
    fallbackInView,
    delay,
  ]);

  const entryTarget = state.entry?.target;
  const previousEntryTarget = useRef();
  if (
    !ref &&
    entryTarget &&
    !triggerOnce &&
    !skip &&
    previousEntryTarget.current !== entryTarget
  ) {
    previousEntryTarget.current = entryTarget;
    setState({
      inView: !!initialInView,
      entry: undefined,
    });
  }

  const handleClick = () => {
    setState((prevState) => ({
      ...prevState,
      inView: !prevState.inView,
    }));
    if (onClick) {
      onClick(!state.inView);
    }
  };

  const result = [setRef, state.inView, state.entry, handleClick];

  result.ref = result[0];
  result.inView = result[1];
  result.entry = result[2];
  result.handleClick = result[3];
  return result;
};

// 가이드 코드 - 쓰지 마세여....
const guideCode = () => {
  const {
    ref: lastPositionRef,
    inView: isLastInView,
    handleClick,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useInfiniteScroll({
    delay: 100,

    onChange: (inView) => {
      if (inView) {
        // 마지막 위치에 도달 시 true로 변경
        console.log('스크롤 - 마지막 위치');
      }
    },
    onClick: () => {
      // callback
      console.log('클릭');
    },
  });
  return (
    <div>
      {/* 1. ref 를 넣는건 스크롤 이벤트 나 click 이벤트 둘다 동일,
          2. ref가 들어있는 태그는 다음 데이터가 보일 자리에 삽입해 주세요.
          3. 스크롤만 사용할거면 handleClick은 제거, 클릭만 사용할거면 onChange 는 주석 해주세요.*/}
      <div ref={lastPositionRef} onClick={handleClick}>
        LastItem
      </div>
    </div>
  );
};
