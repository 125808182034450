import MyApi from '@/api/my';
const MyCommonApi = {
  /**
   * 핫 키워드(hashtag) 조회
   */
  async getHotKeywordList() {
    try {
      const response = await MyApi.get(`/api/hashtag`);
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(error); // 에러 로깅은 개발 환경에서만 수행
      }
      throw error;
    }
  },

  /**
   * 실시간 인기글(popular) 조회
   */
  async getRealTimePopularList() {
    try {
      const response = await MyApi.get(`/api/popular`);
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(error); // 에러 로깅은 개발 환경에서만 수행
      }
      throw error;
    }
  },
};
export default MyCommonApi;
