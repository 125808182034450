'use client';

import {useOutsideClick} from '@/components/main/common/hooks/useOutsideClick';
import DialogItem from './DialogItem';
import useGlobalUIStore from '@/stores';
import useDialogStore from '@/stores/ui/dialogs';
import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import '/public/static/css/module/my_layer.scss';

const MemoedDialogItem = React.memo(DialogItem);

export default function SoopUiDialog() {
  const {dialogList, popDialog} = useDialogStore((state) => ({
    dialogList: state.dialogList,
    popDialog: state.popDialog,
  }));
  const setDialogOpened = useGlobalUIStore((state) => {
    return state.setDialogOpened;
  });
  const getLayerIndex = (dialogId) => {
    return Number(dialogId.replace('soop-dialog-', ''));
  };
  const [isOpen, setIsOpen] = useState(false);
  const dialogRef = useRef([]);
  /**
   * 현재 dom 외 외부 클릭 close
   */
  useOutsideClick(
    dialogRef,
    () => {
      if (dialogList.length === 0) {
        return;
      }

      popDialog(null, false);
      setIsOpen(false); // 외부 클릭으로 다이얼로그를 닫을 때 isOpen 상태를 false로 변경
    },
    isOpen,
    dialogList.length - 1,
  );

  useEffect(() => {
    // body에 dialog-opened class 추가하기 위함
    const htmlElement = document.querySelector('html');

    if (dialogList.length > 0) {
      setIsOpen(true);
      //document.body.classList.add('dialog-opened');
      htmlElement.classList.add('dialog-opened');
    } else {
      htmlElement.classList.remove('dialog-opened');
      //document.body.classList.remove('dialog-opened');
    }
  }, [dialogList]);

  return dialogList.map((dialog, index) => (
    <div
      className={classNames('_Modal_UI_Wrap', {
        dimed: index === dialogList.length - 1,
      })}
      key={index}>
      <div
        className='flex_box'
        ref={(element) => {
          dialogRef.current[index] = element;
        }}>
        <React.Fragment key={index}>
          {index === dialogList.length - 1 ? (
            <div className='backdrop'></div>
          ) : (
            ''
          )}
          <MemoedDialogItem
            name={dialog.name}
            id={dialog.id}
            data={dialog.data}
            option={dialog.option}
          />
        </React.Fragment>
      </div>
    </div>
  ));
}
