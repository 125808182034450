'use client';
import {SoopUiSwiper} from '@/components/ui';
import {MAIN_MENU_LIST} from '@/constants/main';
import '/public/static/css/module/depth2Menu.scss';

export const LiveMenu = ({selectedMenu, handleOnClickLiveMenu}) => {
  const swiperConfig = {
    slidesPerView: 'auto',
    loop: false,
    spaceBetween: 6,
  };
  return (
    <>
      <div className='dept2_menu_wrap'>
        <SoopUiSwiper
          options={swiperConfig}
          swiperClass='tab_swiper_item'
          includesControlsIntoSwiper={true}
          maxSlidesPerView={4}
          swiperTag='ul'>
          {MAIN_MENU_LIST.map((item, idx) => (
            <li
              key={idx}
              className={`swiper-slide ${selectedMenu === item.id ? 'on' : ''}`}>
              <button
                type='button'
                onClick={() => handleOnClickLiveMenu(item.id)}>
                {item.name}
              </button>
            </li>
          ))}
        </SoopUiSwiper>
      </div>
    </>
  );
};
