import {DirectoryCategoryListItem} from '@/components/main/directory/DirectoryCategoryListItem';

export const DirectoryCategoryList = ({
  searchText,
  categoryList,
  handleOnClickMore,
}) => {
  return (
    <>
      <div className='category_conts'>
        {categoryList?.list && categoryList?.list.length > 0 ? (
          <ul>
            {categoryList?.list.map((item, idx) => (
              <DirectoryCategoryListItem key={idx} index={idx} item={item} />
            ))}
          </ul>
        ) : (
          <div className='noList'>
            {searchText.length > 0 && categoryList?.list?.length === 0 && (
              <>
                <p>
                  <strong>
                    {`'`}
                    {searchText}
                    {`'`}
                  </strong>
                  와 일치하는 검색어가 없습니다.
                </p>
                <span>다른 검색어를 입력해보세요.</span>
              </>
            )}
          </div>
        )}
      </div>
      {categoryList?.isMore ? (
        <div className='show_more'>
          <button type='button' onClick={handleOnClickMore}>
            더보기
          </button>
        </div>
      ) : null}
    </>
  );
};
