import {ROUTE} from '@/constants/main/route';

// search 페이지 진입 전 페이지
export const getUrlLocation = (pathname) => {
  if (!pathname) {
    return '';
  }

  const path = pathname;

  if (path.includes('search') && location.search.includes('tagname')) {
    return 'hash';
  } else if (path.includes('search')) {
    return 'total_search';
  } else if (path === '/' || path === '/index.html') {
    return 'main';
  } else if (path.includes('my')) {
    return 'my_main';
  } else if (path.includes('live') && !path.includes('directory')) {
    return 'live_main';
  } else if (path.includes('vod')) {
    return 'vod';
  } else if (path.includes('catch')) {
    return 'catch';
  } else if (path.includes('station')) {
    return 'station';
  } else if (path.includes('directory')) {
    return 'directory';
  } else if (path.includes('ch')) {
    return 'station';
  } else if (path.includes('etc')) {
    return 'etc';
  } else {
    return 'total_search'; // 기본값
  }
};

/**
 * search페이지에서 칩 이동 후 검색페이지 재진입시 inflow_tab 값 리턴
 * @param {*} pathname
 * @returns
 */
export const getSearchInflowTab = (pathname) => {
  if (!pathname) {
    return '';
  }

  let inflowTab = '';

  if (ROUTE.SEARCH === pathname) {
    const searchParams = new URLSearchParams(location.search);
    const szSearchType = searchParams.get('szSearchType');
    const tagname = searchParams.get('tagname');
    const hashtype = searchParams.get('hashtype');
    if (
      szSearchType === 'broad' ||
      (tagname && !hashtype) ||
      (tagname && hashtype === 'live')
    ) {
      inflowTab = 'live';
    } else if (szSearchType === 'vod' || (tagname && hashtype === 'vod')) {
      inflowTab = 'vod';
    } else if (szSearchType === 'post') {
      inflowTab = 'post';
    } else if (szSearchType === 'streamer') {
      inflowTab = 'bj';
    } else {
      inflowTab = 'total';
    }
  }
  return inflowTab;
};
