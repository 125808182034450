'use client';

import Broadcast from '@/components/my/common/item/MyBroadcast';
import {MAIN_LIST_CNT, MY_PLUS_VOD_CONTEXT_MENU} from '@/constants/main';

import {useState, useEffect} from 'react';

import useMainStore from '@/stores/main';
import {SectionHeader} from '@/components/main/common';
import VodBroadcastItem from '../VodBroadcastItem';

export const VodGroup = ({response, isVod = true}) => {
  const [visibleListCount, setVisibleListCount] = useState(0);
  const [isClickMore, setIsClickMore] = useState(false);
  const {resizeCount} = useMainStore();

  const handleShowMore = () => {
    if (visibleListCount < MAIN_LIST_CNT) {
      const visibleCount = MAIN_LIST_CNT - visibleListCount;
      setVisibleListCount((prev) => prev + visibleCount);
    } else {
      const visibleCount = response.vodCnt - visibleListCount;
      setVisibleListCount((prev) => prev + visibleCount);
    }
    setIsClickMore(true);
  };

  useEffect(() => {
    if (resizeCount > 0 && !isClickMore) {
      setVisibleListCount(resizeCount * 2);
    }
  }, [resizeCount]);

  return (
    <>
      <SectionHeader title={'VOD'} />
      {response && response.vodList?.length > 0 ? (
        <>
          <div className='cBox-list'>
            <ul>
              {response.vodList?.map(
                (broadcast, idx) =>
                  idx < visibleListCount && (
                    <li data-type='cBox' key={broadcast.titleNo}>
                      <VodBroadcastItem
                        vodType={broadcast.fileType}
                        broadcastType='vod'
                        duration={broadcast.vodDuration}
                        broadcastId={broadcast.titleNo}
                        categoryName={
                          broadcast.categoryTags &&
                          broadcast.categoryTags.length > 0
                            ? broadcast.categoryTags[0]
                            : ''
                        }
                        autoHashTags={broadcast.autoHashtags}
                        categoryTags={broadcast.categoryTags}
                        hashTags={broadcast.hashTags}
                        allowsAdult={broadcast.grade}
                        hasBadge={
                          broadcast.autoHashtags ||
                          broadcast.categoryTags ||
                          broadcast.hashTags
                        }
                        thumbnailUrl={broadcast.thumbnail}
                        title={broadcast.title}
                        userId={broadcast.userId}
                        userNickname={broadcast.userNick}
                        originalUserId={broadcast.originalUserId}
                        originalUserNickname={broadcast.originalUserNick}
                        viewCount={broadcast.viewCnt}
                        createdDate={broadcast.regDate}
                        previewThumbnailUrl={broadcast.webPath}
                        listDataType={broadcast.listDataType}
                        score={broadcast.score}
                        location='myplus'
                        contextMenu={{
                          menuList: MY_PLUS_VOD_CONTEXT_MENU,
                        }}
                      />
                    </li>
                  ),
              )}
            </ul>
          </div>
          <div className='show_more'>
            {response.vodCnt > visibleListCount && (
              <button type='button' onClick={handleShowMore}>
                더보기
              </button>
            )}
          </div>
        </>
      ) : (
        <div className='nt_area'>
          <p>VOD가 없습니다.</p>
        </div>
      )}
    </>
  );
};
