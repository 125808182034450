'use client';
import {useState, useEffect, useMemo, useCallback} from 'react';

import {NoSearchResults, ShowMore, Loading} from '@/components/search/common';

import {SectionHeader} from '@/components/main/common';
import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import {SoopUiSelectBox} from '@/components/ui';
import {useInfiniteScroll} from '@/components/main/common/hooks/useInfiniteScroll';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {
  StreamerItemLeftBox,
  StreamerItemRightBox,
} from '@/components/search/group/streamer-group';
import {SEARCH_STREAMER_FILTER} from '@/constants/search/filter';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

import useFavoriteStore from '@/stores/search';
import {sendLegacyInflowPathLog} from '@/utils/main/my/log';
import {ErrorBoundary} from '@/components/common';

/**
 * StreamerGroup 컴포넌트 ( 스트리머 )
 * @param {Object} props 컴포넌트의 props
 * @param {Object[]} streamer 스트리머관련 데이터
 * @param {String} stopWord 금칙어
 * @returns {JSX.Element} StreamerGroup 컴포넌트
 */
const StreamerGroup = ({streamer, stopWord}) => {
  const {sendStreamerSectionClickLog} = useSearchClickLog();
  const {getParams} = useUrlParameter();
  const {szKeyword: keyword = '', szSearchType: searchType = ''} = getParams();
  const {
    hasMore,
    changeStatus,
    nextPage,
    streamerLoading,
    currentPageNumber,
    noResult,
    perPage,
    activeTab,
    resetPageNumber,
    sessionKey,
    isTotalPage,
    isStreamerPage
  } = useSearchGlobalStatus();

  const [streamerList, setStreamerList] = useState([]);
  const [selectBoxItem, setSelectBoxItem] = useState('score');
  const [streamerListSlice, setStreamerListSlice] = useState(6);

  const {toggleFavorite, setFavoriteStreamerName} = useFavoriteStore();

  const streamerDisplayedList = useMemo(() => {
    return isTotalPage
      ? streamerList.slice(0, Math.max(streamerListSlice, 6))
      : streamerList;
  }, [searchType, streamerList, streamerListSlice]);

  const {ref: lastPositionRef, inView} = useInfiniteScroll({
    delay: 100,
    onChange: (inView) => {
      // 주석된코드 없어도 잘 동작되는데, 혹시 모르니 코드 남겨둠
      // if (inView) {
      //   onHasMore((prevState) => ({...prevState, streamer: true}));
      // }
      if (inView && isStreamerPage && hasMore.streamer) {
        nextPage(activeTab);
      }
    },
  });

  // SCKACT 로그
  const handleLogCallback = useCallback(
    (params, index, userId, live) => {
      const {type = 'sn'} = params || {};

      let contentsType = '';
      let contentsValue = '';

      if (['sti'].includes(type)) {
        contentsType = live ? 'live' : 'bj';
        contentsValue = live || userId;
      }

      sendStreamerSectionClickLog({
        actCode: type,
        listIdx: index,
        bjid: userId,
        page: currentPageNumber['streamer'],
        sessionKey: sessionKey,
        contentsType: contentsType,
        contentsValue: contentsValue,
        bno: live || '',
        ...params,
      });
    },
    [searchType, sessionKey, currentPageNumber['streamer']],
  );

  // 프로필 ict 메뉴의 이벤트가 발생했을 때 트리거
  const handleProfileIconMenuEvent = async (event) => {
    const {eventName, data} = event;
    if (eventName === 'deleteFavorite') {
      toggleFavorite();
      setFavoriteStreamerName(data.id);
    }
  };

  // 필터 옵션 변경
  const handleChangeFilterOption = (item, options) => {
    if (isStreamerPage) {
      setStreamerList([]);
    }
    setSelectBoxItem(item);
    changeStatus('streamer', perPage, [options]);
  };

  // 더보기 버튼
  const handleShowMore = () => {
    setStreamerListSlice((prevCount) => prevCount * 2);
    handleLogCallback({type: 'more'});
  };

  useEffect(() => {
    setStreamerList([]);
    setSelectBoxItem('score');
    setStreamerListSlice(isTotalPage ? 12 : 40);
  }, [searchType]);

  useEffect(() => {
    if (currentPageNumber[activeTab] > 1) {
      setStreamerList((prevStreamer) => [...prevStreamer, ...streamer]);
    } else {
      setStreamerList(streamer);
    }
  }, [streamer]);

  // useEffect(() => {
  //   // 칩 변경시 스트리머 필터옵션 '정확도'로 초기화
  //   if (searchType === 'total' || searchType === 'streamer') {
  //     setSelectBoxItem('score');
  //   }
  // }, [searchType]);

  useEffect(() => {
    return () => {
      resetPageNumber('streamer');
    };
  }, []);

  const renderStreamerSectionHeader = () => {
    if (isStreamerPage || (isTotalPage && streamerList.length > 0)) {
      return (
        <SectionHeader
          title='스트리머'
          rightElement={
            <SoopUiSelectBox
              options={SEARCH_STREAMER_FILTER}
              type={selectBoxItem || ''}
              onChange={handleChangeFilterOption}
            />
          }
        />
      );
    }
    return null;
  };

  const renderStreamerSectionNoResult = () => {
    const shouldRenderNoResult =
      isStreamerPage &&
      !streamerLoading &&
      (stopWord || noResult.streamer || !keyword);

    if (shouldRenderNoResult) {
      return (
        <NoSearchResults
          searchTab='streamer'
          isKeywordEntered={Boolean(keyword)}
          stopWord={stopWord}
        />
      );
    }

    return null;
  };

  const renderStreamerListItems = () => {
    return streamerDisplayedList.map((data, index) => (
      <li
        key={`search_streamer-${data.broadNo}-${index}`}
        className={`strm_list ${data.broadNo ? 'live' : ''}`}>
        <ErrorBoundary>
          <StreamerItemLeftBox
            {...data}
            onEvent={handleProfileIconMenuEvent}
            logCallback={(params) => {
              handleLogCallback(params, index, data.userId, data.broadNo);
              if (data.broadNo) {
                sendLegacyInflowPathLog({
                  broadcastId: data.broadNo,
                  streamerId: data.userId,
                  isLogSendData: {
                    path1: 'sch',
                    path2: 'bj',
                    path3: 'sti',
                    cli_list_data_idx: index + 1,
                  },
                });
              }
            }}
          />
        </ErrorBoundary>
        <ErrorBoundary>
          <StreamerItemRightBox
            userId={data.userId}
            favorite={!!data.favoriteFlag}
            medalURL={data.medalUrl}
            logCallback={(params) => {
              handleLogCallback(params, index, data.userId);
            }}
          />
        </ErrorBoundary>
      </li>
    ));
  };

  const shouldRenderContent = !stopWord && streamerList.length > 0 && keyword;

  return (
    <>
      {renderStreamerSectionHeader()}

      {renderStreamerSectionNoResult()}

      {shouldRenderContent && (
        <>
          <div className='search_strm_area'>
            <ul>
              {renderStreamerListItems()}
              {isStreamerPage && <div ref={lastPositionRef} />}
            </ul>
          </div>

          {isTotalPage && (
            <ShowMore
              active={streamerList.length > 12}
              name='더보기'
              onHanlder={handleShowMore}
            />
          )}
        </>
      )}

      {/* 스트리머칩 영역 > 무한스크롤 로딩 */}
      {isStreamerPage && streamerLoading && keyword && <Loading />}
    </>
  );
};

export default StreamerGroup;
