export default function Footer() {
  return (
    <>
      <div id='footer'>
        <div className='footer_inner'>
          <ul className='f_list'>
            <li>
              <a href='#n'>회사소개</a>
            </li>
            <li>
              <a href='#n'>서비스 소개</a>
            </li>
            <li>
              <a href='#n'>인재채용</a>
            </li>
            <li>
              <a href='#n'>이용약관</a>
            </li>
            <li>
              <a href='#n'>
                <strong>개인정보처리방침</strong>
              </a>
            </li>
            <li>
              <a href='#n'>청소년보호정책</a>
            </li>
            <li>
              <a href='#n'>운영정책</a>
            </li>
            <li>
              <a href='#n'>권리침해신고센터</a>
            </li>
            <li>
              <a href='#n'>오픈스튜디오</a>
            </li>
            <li>
              <a href='#n'>Developers</a>
            </li>
            <li>
              <a href='#n'>주요서비스</a>
            </li>
            <li className='familysite off' style={{display: 'none'}}>
              <a href='#n' id='familylBtn'>
                패밀리 사이트
              </a>
              <div className='flayer' id='familyLayer'>
                <ul>
                  <li>
                    <a href='#n'>프리캡</a>
                  </li>
                  <li>
                    <a href='#n'>프리캡 소셜트레이딩</a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          <div className='adr'>
            주식회사 숲<span>대표이사 : 정찬용</span>
            <span>사업자번호 : 220-81-10886</span>
            <span>통신판매번호 제2010-경기성남-0834</span>
            <span>
              <a
                href='http://ftc.go.kr/bizCommPop.do?wrkr_no=2208110886&apv_perm_no='
                target='_blank'>
                사업자 정보 확인
              </a>
            </span>
            <span>호스팅 제공자 : 주식회사 숲</span>
            <div>
              <address>
                주소 : 경기도 성남시 분당구 판교로228번길 15 판교세븐밴처밸리
                1단지 주식회사 숲(삼평동)
              </address>
              <span>FAX : 031-622-8008</span>
              <span>
                <a
                  href='mailto:afreecaTV@afreecatv.com'
                  className='mail'
                  title='메일'>
                  soop@sooplive.com
                </a>{' '}
                (1688-7022)
              </span>
            </div>
          </div>
          <p className='copyright'>ⓒ SOOP Corp.</p>
        </div>
      </div>
    </>
  );
}
