import MyHistoryStoryApi from '@/api/my/history/story';
import useConvertTime from '@/components/main/common/hooks/useConvertTime';
import MyProfileImg from '@/components/my/common/item/MyProfileImg';

import {SOOP_UI_IMG_TYPES} from '@/components/ui/SoopUiImg';
import {
  SoopUiProfileIconMenu,
  SoopUiImg,
  SoopUiContextMenu,
} from '@/components/ui';
import {MY_STORY_MENU} from '@/constants/my';
import {decodeEmoticon} from '@/libs/emoticon';
import globalDomains from '@/utils/global-domains';
import {convertViewCnt, newLineToBreak} from '@/utils/main';
import {sendLegacyInflowPathLog} from '@/utils/main/my/log';
import {throwConfirm} from '@/utils/ui/dialog';
import {useMemo, useRef, useState} from 'react';

/**
 * @typedef {Array} Photos
 * @description 사진 목록
 * @property {String} url - 사진 URL
 */

/**
 * @typedef {Object} Count
 * @description 조회수, 댓글수
 * @property {Number} readCnt - 조회수
 * @property {Number} commentCnt - 댓글수
 */

/**
 * @typedef {Object} Parent
 * @description 부모 글 정보
 * @property {String} url - 게시글 URL
 * @property {String} userId - 사용자 ID
 * @property {String} userNick - 사용자 닉네임
 * @property {String} regDate - 등록일
 * @property {String} titleName - 제목
 * @property {Count} count - 조회수, 댓글수
 * @property {String} content - 내용
 * @property {Photos} photos - 사진 목록
 * @property {Boolean} isNotice - 공지 여부
 */

/**
 * @description 댓글 컴포넌트
 * @param {Parent} parents - 부모 글 정보
 * @param {String} feedType - 게시글 타입
 * @param {String} stationUserId - 채널 아이디
 * @param {Number} titleNo - 게시글 번호
 * @param {Number} pCommentNo - 부모 댓글 번호
 * @param {String} url - 댓글 URL
 * @param {Object} photo - 댓글 이미지
 * @param {String} comment - 댓글 내용
 * @param {String} regDate - 등록일
 */
const MyParentComment = ({
  parent,
  feedType,
  stationUserId,
  titleNo,
  pCommentNo,
  url,
  photo,
  comment,
  regDate,
  refetching,
}) => {
  const {convertSecondToTime} = useConvertTime();
  const [isLoadImageError, setIsLoadImageError] = useState(false);
  const [imageClassName, setImageClassName] = useState('');
  const soopUiProfileIconMenu = useRef(null);

  const deleteMyParentComment = async ({
    feedType,
    stationUserId,
    titleNo,
    pCommentNo,
  }) => {
    try {
      await MyHistoryStoryApi.deleteMyHistoryStory({
        feedType,
        stationUserId,
        titleNo,
        pCommentNo,
      });
      refetching(feedType, pCommentNo);
      return;
    } catch (error) {
      console.log(error);
      return;
    }
  };
  const handleMenuClicked = async (e, type) => {
    const {data: isOk} = await throwConfirm({
      subTitle: '선택하신 댓글(답글 포함)을 삭제하시겠습니까?',
      message: '삭제 시 스토리와 채널에서 완전 삭제되며, 복구가 불가능합니다.',
    });

    if (!isOk) {
      return;
    }
    if (type === 'delete') {
      deleteMyParentComment({feedType, stationUserId, titleNo, pCommentNo});
    }
  };

  const handleThumbnailPath = useMemo(() => {
    const {boardType, photos, ucc} = parent || {};
    if (boardType === 105) {
      if (!!ucc) {
        const {grade, category, thumb} = ucc || {};
        if (Number(grade) === 19 && category === '30000') {
          setImageClassName('thumb-adult');
          setIsLoadImageError(true);
          return '';
        }
        return 'https:' + thumb;
      }
      setIsLoadImageError(true);
      setImageClassName('thumb-default');
      return '';
    }
    if (photos && photos.length > 0) {
      return photos?.[0]?.url || '';
    }

    return '';
  }, [isLoadImageError]);

  const handleImageError = () => {
    setImageClassName('thumb-default');
    setIsLoadImageError(true);
  };

  /**
   * VOD 유입로그 전송
   */
  const handleInflowPathLog = () => {
    if (parent.feedType !== 'VOD') {
      return;
    }

    sendLegacyInflowPathLog({
      streamerId: stationUserId,
      broadcastId: titleNo,
      isLogSendData: {
        path1: 'etc',
        path2: '', // 내글댓글은 path2, path3 이 없기 때문에 없는 값으로 덮어씀
        path3: '',
      },
    });
  };

  return (
    <li className='comment'>
      <span className='list_icon' />
      <div className='list_wrap'>
        <div className='bs_wrap'>
          {/* 게시글 */}
          <div className='cont_area'>
            <a
              href={`${globalDomains.BJ_AFREECATV}/${parent.userId}`}
              target='_blank'>
              <MyProfileImg userId={parent.userId} className='thumb' />
            </a>
            <div className='bs_detail'>
              <div className='info_wrap'>
                <div className='nick_wrap'>
                  <button
                    type='button'
                    className='nick'
                    onClick={() => {
                      soopUiProfileIconMenu.current?.open();
                    }}>
                    <strong>{parent.userNick}</strong>
                    <SoopUiProfileIconMenu
                      ref={soopUiProfileIconMenu}
                      streamerId={parent.userId}
                      streamerNick={parent.userNick}
                    />
                  </button>
                  {parent.feedType !== 'VOD' && (
                    <div className='info'>
                      <span className='date'>{parent.regDate}</span>
                      <span className='views'>
                        {convertViewCnt(parent.count.readCnt)}
                      </span>
                      <span className='cmt'>{parent.count.commentCnt}</span>
                    </div>
                  )}
                </div>
                <a href={parent.url} target='_blank' className='desc'>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        (parent.isNotice
                          ? '<span class="notice">공지</span>'
                          : '') + parent.titleName,
                    }}></p>
                </a>
              </div>
            </div>
          </div>
          {/* thumbnail(vod, photo) */}
          {parent.feedType === 'VOD' ? (
            <div className='post_img'>
              <a
                href={parent.url}
                target='_blank'
                className={imageClassName}
                onClick={handleInflowPathLog}>
                {!isLoadImageError && (
                  <SoopUiImg
                    src={handleThumbnailPath}
                    type={SOOP_UI_IMG_TYPES.thumbnail}
                    onError={handleImageError}
                  />
                )}
                {parent.ucc.fileType === 'CATCH' ? (
                  <span className='ic_catch'>캐치</span>
                ) : (
                  <span className='time'>
                    {convertSecondToTime(parent.ucc.totalFileDuration / 1000)}
                  </span>
                )}
              </a>
            </div>
          ) : (
            <>
              {parent.photos.length > 0 && (
                <div className='post_img'>
                  <a
                    href={parent.url}
                    target='_blank'
                    onClick={handleInflowPathLog}>
                    <SoopUiImg
                      src={parent.photos?.[0]?.url}
                      type={SOOP_UI_IMG_TYPES.thumbnail}
                    />
                  </a>
                </div>
              )}
            </>
          )}
        </div>

        {/* vod(view count, registration date) */}
        {parent.feedType === 'VOD' && (
          <div className='vod_info'>
            <span className='views'>
              {convertViewCnt(parent.count.readCnt)}
            </span>
            <span className='date'>{parent.regDate}</span>
          </div>
        )}

        {/* comment */}
        <div className='post_box'>
          {/* image */}
          {photo && (
            <div className='post_img'>
              <a href={url} target='_blank' onClick={handleInflowPathLog}>
                <SoopUiImg src={photo.url} type={SOOP_UI_IMG_TYPES.thumbnail} />
              </a>
            </div>
          )}
          {/* content */}
          <div className='cont_area'>
            <a href={url} target='_blank'>
              <span className='comment_badge'>댓글</span>
              <h3
                className='title'
                dangerouslySetInnerHTML={{
                  __html: decodeEmoticon(newLineToBreak(comment)),
                }}></h3>
            </a>
            <div className='info'>
              <span className='date'>{regDate}</span>
            </div>
          </div>
          <SoopUiContextMenu
            menuList={MY_STORY_MENU}
            handleMenuClicked={handleMenuClicked}
          />
          {/* <div class="more-layer">
        <button type="button"><span>삭제하기</span></button>
      </div> */}
        </div>
      </div>
    </li>
  );
};

export default MyParentComment;
