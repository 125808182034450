import {useRef} from 'react';

import {SoopUiProfileIconMenu} from '@/components/ui';

import {convertViewCnt} from '@/utils/main';

/**
 * UserInfo 스트리머 유저 정보 컴포넌트
 * @param {Object} props - 컴포넌트의 props
 * @param {String} userId - 유저아이디
 * @param {String} userNick - 유저닉네임
 * @param {String} isFanclub - 팬구독 여부
 * @param {String} isSubscribe - 구독 여부
 * @param {String} favoriteCount - 애청자 수
 * @param {String} onEvent - 프로필 ict 메뉴의 이벤트가 발생했을 때 트리거
 * @returns UserInfo 스트리머 유저 정보 컴포넌트
 */

const StreamerInfo = ({
  userId,
  userNick,
  isFanclub,
  isSubscribe,
  favoriteCount,
  onEvent,
  logCallback = () => {},
}) => {
  const soopUiProfileIconMenu = useRef(null);

  return (
    <div className='info_wrap'>
      <div className='nick'>
        <button
          type='button'
          onClick={(e) => {
            e.preventDefault();
            soopUiProfileIconMenu.current?.open();
            logCallback();
          }}>
          {userNick}
        </button>

        {!!isSubscribe && (
          <span className='grade-badge-subscribe' tip='구독'>
            구독
          </span>
        )}

        {!!isFanclub && (
          <span className='grade-badge-fan' tip='팬클럽'>
            F
          </span>
        )}
      </div>
      <SoopUiProfileIconMenu
        ref={soopUiProfileIconMenu}
        streamerId={userId}
        streamerNick={userNick}
        handleMenuButtonClicked={onEvent}
        searchArea='bj'
      />
      <span className='id'>({userId})</span>
      <div className='fav'>
        <span className='txt'>애청자</span>
        <span className='num'>{convertViewCnt(favoriteCount)}</span>
      </div>
    </div>
  );
};

export default StreamerInfo;
