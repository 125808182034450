import MyFavoriteApi from '@/api/my/favorite';
import MyProfileImg from '@/components/my/common/item/MyProfileImg';
import {SectionHeader} from '@/components/main/common';
import {SoopUiSelectBox} from '@/components/ui';
import useMyStore from '@/stores/my';
import {sortStreamers} from '@/utils/main/my/sort';
import {forwardRef, useEffect, useState} from 'react';
import {isEmpty} from 'lodash';
import throwToast from '@/utils/ui/toast';
import classNames from 'classnames';
import {sendCreateFavoriteGroupClickLog} from '@/utils/main/my/log';

const OPTIONS = Object.freeze([
  {label: '최근 추가', type: 'favoriteNo'},
  {label: '닉네임', type: 'userNick'},
  {label: '아이디', type: 'userId'},
  {label: '최근 시작', type: 'lastBroadStart'},
]);

const MAX_GROUP_NAME_LENGTH = 10;

/**
 * 즐겨찾기 그룹 추가 팝업
 * @desc 그룹 수정 시에도 사용
 * @param {Object} data - 팝업 데이터 (throwDialog 함수에서 전달)
 * @param {boolean} data.isEdit - 다이얼로그 수정 모드 여부
 * @param {Object[]} data.addedStreamers - 초기부터 추가 되어있는 그룹을 전달해야 할 경우 사용 (즐겨찾기 그룹 다이얼로그를 위한 데이터)
 * @param {number[]} data.checkedGroups - 선택되어있는 즐겨찾기 그룹 (즐겨찾기 그룹 다이얼로그를 위한 데이터)
 * @param {number} data.favoriteUserId - 즐겨찾기 그룹을 넣어줄 유저 아이디 (즐겨찾기 그룹 다이얼로그를 위한 데이터)
 * @param {Function} handleClose - 팝업 닫기 함수 (팝업에서 전달)
 * @param {Function} handlePositiveClose - 팝업 닫기 함수 (팝업에서 전달)
 */
const MyFavoriteGroup = forwardRef(function MyLayer(
  {data, handleClose, handlePositiveClose},
  ref,
) {
  // state 선언
  const [isSideBarOn, setIsSideBarOn] = useState(false);
  const [streamers, setStreamers] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [addedStreamers, setAddedStreamers] = useState(
    !isEmpty(data.addedStreamers) ? data.addedStreamers : [],
  );
  const [selectedStreamers, setSelectedStreamers] = useState(
    !isEmpty(data.addedStreamers) ? data.addedStreamers : [],
  );
  const [order, setOrder] = useState(OPTIONS[0].type);
  const [clickedStreamers, setClickedStreamers] = useState([]);

  // store에서 streamers 가져오기
  const {getFavoriteGroups, getStreamers: getStoreStreamers} = useMyStore();

  const getStreamers = async () => {
    const {favorites} = await MyFavoriteApi.getFavorites();
    setStreamers(sortStreamers([...favorites], order));
  };

  // store의 streamers가 변경될 때마다 streamers state 업데이트
  useEffect(() => {
    getStreamers();
  }, [order]);

  /**
   * 그룹명 변경 이벤트 핸들러
   * @param {Event} e
   */
  const handleGroupNameChange = (e) => {
    const inputText = e.target.value;
    let realLength = 0;
    let newText = '';

    for (let char of inputText) {
      // 정규식을 사용하여 한글 문자를 1자로 계산
      realLength += char.match(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/) ? 1 : 1;

      if (realLength > MAX_GROUP_NAME_LENGTH) {
        break;
      }

      newText += char;
    }

    setGroupName(newText);
  };

  /**
   * 체크박스 변경 이벤트 핸들러
   * @param {Object} streamer
   */
  const handleCheckboxChange = (streamer) => (event) => {
    if (event.target.checked) {
      setSelectedStreamers((prevStreamers) => [...prevStreamers, streamer]);
    } else {
      setSelectedStreamers((prevStreamers) =>
        prevStreamers.filter(
          (prevStreamer) => prevStreamer.userId !== streamer.userId,
        ),
      );
    }
  };

  /**
   * 사이드 바 추가 버튼 클릭 이벤트 핸들러
   * @desc 추가된 스트리머 목록에 선택된 스트리머 추가
   */
  const handleAddButtonClick = () => {
    setAddedStreamers([...selectedStreamers]);
    setIsSideBarOn(false);
  };

  /**
   * 정렬 변경 이벤트 핸들러
   * @param {'favoriteNo' | 'userNick' | 'useId'} type  - 정렬 타입
   */
  const handleOrderChange = (type) => {
    setOrder(type);
    setStreamers((prevStreamers) => sortStreamers(prevStreamers, type));
  };

  /**
   * 스트리머 클릭 이벤트 핸들러
   * @desc 스트리머 클릭 시 클릭된 스트리머의 userId를 state에 저장
   * @desc 선택된 스트리머를 삭제할 때 쓰인다
   * @param {Object} streamer
   */
  const handleStreamerClick = (streamer) => () => {
    if (clickedStreamers.includes(streamer.userId)) {
      setClickedStreamers(
        clickedStreamers.filter((id) => id !== streamer.userId),
      );
    } else {
      setClickedStreamers((prevStreamers) => [
        ...prevStreamers,
        streamer.userId,
      ]);
    }
  };

  /**
   * 추가된 스트리머 삭제 이벤트 핸들러
   * @desc 추가된 스트리머 목록에서 선택된 스트리머 삭제
   */
  const handleAddedStreamerDelete = () => {
    setAddedStreamers((prevStreamers) =>
      prevStreamers.filter(
        (streamer) => !clickedStreamers.includes(streamer.userId),
      ),
    );
    setSelectedStreamers((prevStreamers) =>
      prevStreamers.filter(
        (streamer) => !clickedStreamers.includes(streamer.userId),
      ),
    );
    setClickedStreamers([]);
  };

  /**
   * 저장 버튼 클릭 이벤트 핸들러
   * @desc 그룹을 추가하면서 해당 그룹에 추가된 스트리머를 추가함
   */
  const handleSaveButtonClick = async () => {
    if (data.isEdit) {
      await MyFavoriteApi.updateFavoriteGroup({
        groupId: data.groupId,
        favoriteIds: addedStreamers.map((streamer) => streamer.userId),
        title: groupName,
      });
    } else {
      sendCreateFavoriteGroupClickLog({
        buttonType: 'create_save',
      });

      const {idx} = await MyFavoriteApi.addFavoriteGroup({
        favoriteIds: addedStreamers.map((streamer) => streamer.userId),
        title: groupName,
      });

      if (!isEmpty(data.checkedGroups)) {
        try {
          await MyFavoriteApi.putFavoriteGroup({
            favoriteId: data.favoriteUserId,
            groupIds: [...data.checkedGroups, idx],
          });
        } catch (e) {
          throwToast('에러가 발생하였습니다.');
        }
      }
    }

    handlePositiveClose();
    getFavoriteGroups();
    getStoreStreamers();
    throwToast('즐겨찾기 그룹에 추가됐습니다.');
  };

  const getFavoriteGroup = async () => {
    try {
      const groupInfo = await MyFavoriteApi.getFavoriteGroup(data.groupId);
      setGroupName(groupInfo.group.title);
      setAddedStreamers(groupInfo.list);
      setSelectedStreamers(groupInfo.list);
    } catch {
      console.error('즐겨찾기 그룹 정보를 가져오지 못했습니다.');
    }
  };

  useEffect(() => {
    if (data.isEdit) {
      getFavoriteGroup();
    }
  }, [data.isEdit]);

  return (
    <>
      <div className='conts_box'>
        <h2>{data.isEdit ? '그룹 수정' : '새 그룹 만들기'}</h2>
        <div className='strm_area'>
          <div className='input_area'>
            <div className='input_box'>
              <input
                type='text'
                placeholder='그룹명을 입력해주세요.'
                value={groupName}
                onChange={handleGroupNameChange}
                maxLength={MAX_GROUP_NAME_LENGTH}
              />
              <button
                type='button'
                className='del'
                style={{display: groupName.length > 0 ? '' : 'none'}}
                onClick={() => setGroupName('')}>
                삭제
              </button>
            </div>
            <span className='txt_num'>
              <em>{groupName.length}</em>/10
            </span>
          </div>
          <SectionHeader
            customElement={
              <>
                <h3>
                  스트리머
                  <span className='total'>{addedStreamers.length}명</span>
                </h3>
                <button
                  type='button'
                  className='add'
                  onClick={() => setIsSideBarOn(true)}>
                  + 스트리머 추가
                </button>
              </>
            }
          />
          {addedStreamers.length > 0 ? (
            <StreamerList
              streamers={addedStreamers}
              handleStreamerClick={handleStreamerClick}
              clickedStreamers={clickedStreamers}
            />
          ) : (
            <div className='noList'>
              <p>스트리머를 추가해주세요.</p>
            </div>
          )}
        </div>
        <div className='btn_area'>
          <button
            type='button'
            className='del'
            disabled={clickedStreamers.length !== 0 ? false : true}
            onClick={handleAddedStreamerDelete}>
            삭제
          </button>
          <button
            type='submit'
            className='submit'
            disabled={!groupName || addedStreamers.length === 0}
            onClick={handleSaveButtonClick}>
            저장
          </button>
        </div>
        <button type='button' className='btn_close' onClick={handleClose}>
          레이어 닫기
        </button>
      </div>
      <SideBar
        streamers={streamers}
        order={order}
        handleOrderChange={handleOrderChange}
        selectedStreamers={selectedStreamers}
        handleCheckboxChange={handleCheckboxChange}
        handleAddButtonClick={handleAddButtonClick}
        setIsSideBarOn={setIsSideBarOn}
        style={{display: isSideBarOn ? '' : 'none'}}
      />
    </>
  );
});

/**
 * 스트리머 목록 컴포넌트
 * @param {Object} props
 * @param {Array<Object>} props.streamers - 스트리머 목록
 * @param {Array<Object>} clickedStreamers - 선택된 스트리머 목록
 * @param {Function} props.handleStreamerClick - 스트리머 클릭 이벤트 핸들러
 */
function StreamerList({streamers, clickedStreamers, handleStreamerClick}) {
  return (
    <ul className='strm_list'>
      {streamers.map((streamer) => (
        <li key={streamer.userId}>
          <button
            type='button'
            onClick={handleStreamerClick(streamer)}
            className={clickedStreamers.includes(streamer.userId) ? 'on' : ''}>
            <MyProfileImg userId={streamer.userId} />
            <span>{streamer.userNick}</span>
          </button>
        </li>
      ))}
    </ul>
  );
}

/**
 * 사이드바 컴포넌트
 * @param {Object} props
 * @param {Array<Object>} props.streamers - 스트리머 목록
 * @param {String} props.order - 정렬 타입
 * @param {Function} props.handleOrderChange - 정렬 변경 이벤트 핸들러
 * @param {Array<Object>} props.selectedStreamers - 선택된 스트리머 목록
 * @param {Function} props.handleCheckboxChange - 체크박스 변경 이벤트 핸들러
 * @param {Function} props.handleAddButtonClick - 추가 버튼 클릭 이벤트 핸들러
 * @param {Function} props.setIsSideBarOn - 사이드바 온/오프 상태 변경 핸들러
 * @returns
 */
function SideBar({
  streamers,
  order,
  handleOrderChange,
  selectedStreamers,
  handleCheckboxChange,
  handleAddButtonClick,
  setIsSideBarOn,
  style,
}) {
  const getIsCheckedStreamer = (userId) =>
    selectedStreamers.some(
      (selectedStreamer) => selectedStreamer.userId === userId,
    );

  return (
    <div className='side_box' style={style}>
      <h2>스트리머 추가</h2>
      <div className='strm_area'>
        <div className='total_wrap'>
          <span className='total_txt'>총 {streamers.length}명</span>
          <div className='right_area'>
            <SoopUiSelectBox
              options={OPTIONS}
              type={order}
              onChange={handleOrderChange}
            />
          </div>
        </div>
        <ul className='strm_list' key={order}>
          {streamers.map((streamer) => (
            <li
              key={streamer.userId}
              className={classNames({
                on: getIsCheckedStreamer(streamer.userId),
              })}>
              {
                <>
                  <label htmlFor={`checked-streamer-${streamer.userId}`}>
                    <MyProfileImg userId={streamer.userId} />
                    <span className='nick'>{streamer.userNick}</span>
                    {streamer.isSubscribe && (
                      <span className='grade-badge-subscribe' tip='구독'>
                        구독
                      </span>
                    )}
                    {streamer.isFanclub && (
                      <span className='grade-badge-fan' tip='팬클럽'>
                        F
                      </span>
                    )}
                  </label>
                  <input
                    type='checkbox'
                    id={`checked-streamer-${streamer.userId}`}
                    checked={getIsCheckedStreamer(streamer.userId)}
                    onChange={handleCheckboxChange(streamer)}
                  />
                </>
              }
            </li>
          ))}
        </ul>
      </div>
      <button
        type='button'
        className='add'
        disabled={selectedStreamers.length === 0 ? true : false}
        onClick={handleAddButtonClick}>
        {selectedStreamers.length}명 추가
      </button>
      <button
        type='button'
        className='btn_close'
        onClick={() => setIsSideBarOn(false)}>
        레이어 닫기
      </button>
    </div>
  );
}

export default MyFavoriteGroup;
