'use client';

import {SectionHeader} from '@/components/main/common';
import {SoopUiSelectBox} from '@/components/ui';
import {useEffect, useState} from 'react';
import useMyStore, {MY_SORT, MY_SORT_ORDER} from '@/stores/my';

const OPTIONS = Object.freeze([
  {label: '참여자수(높은순)', type: 'total_viewers_desc'},
  {label: '참여자수(낮은순)', type: 'total_viewers_asc'},
  {label: '최근 시작', type: 'broad_start'},
]);

const ORDER_MAP = Object.freeze({
  total_viewers_desc: {
    sortKey: MY_SORT.VIEW_COUNT,
    sortOrder: MY_SORT_ORDER.DESC,
  },
  total_viewers_asc: {
    sortKey: MY_SORT.VIEW_COUNT,
    sortOrder: MY_SORT_ORDER.ASC,
  },
  broad_start: {sortKey: MY_SORT.RECENT_START, sortOrder: MY_SORT_ORDER.DESC},
});

const LiveTitle = () => {
  const [order, setOrder] = useState(OPTIONS[0].type);
  const handleOrderChange = (type) => {
    setOrder(type);
  };
  const {setLiveSortKey, setLiveSortOrder} = useMyStore();

  useEffect(() => {
    const {sortKey, sortOrder} = ORDER_MAP[order];
    setLiveSortKey(sortKey);
    setLiveSortOrder(sortOrder);
  }, [order]);

  return (
    <SectionHeader
      title='LIVE'
      rightElement={
        <SoopUiSelectBox
          options={OPTIONS}
          type={order}
          onChange={handleOrderChange}
        />
      }
    />
  );
};

export default LiveTitle;
