/**
 * @description 마이페이지 필터 리스트
 */
export const MY_HISTORY_STORY_FILTER_LIST = [
  {
    type: '',
    label: '전체보기',
  },
  {
    type: 'POST_PHOTO',
    label: '게시글만 보기',
  },
  {
    type: 'PARENT_COMMENT',
    label: '댓글만 보기',
  },
  {
    type: 'CHILD_COMMENT',
    label: '답글만 보기',
  },
];

export const MY_FEED_FILTER_LIST = [
  {
    type: '',
    label: '전체보기',
  },
  {
    type: 'NOTICE',
    label: '공지글 보기',
  },
  {
    type: 'POST_PHOTO',
    label: '게시글 보기',
  },
];
