'use client';

import {SoopUiSwiper} from '@/components/ui';
import {isEmpty} from 'lodash';
import {usePathname, useSearchParams} from 'next/navigation';
import {Link, useNavigate} from 'react-router-dom';
import '/public/static/css/module/tab_list_item.scss';

import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';

const TAB_CLASS_MAP = Object.freeze({
  default: 'tab_swiper_item',
  flat: 'tab_list',
  search_route: 'tab_swiper_item fixed',
});

/**
 *
 * @param {Object} props - MyTabGroup props
 * @param {string} props.type - 버튼 타입 (default 는 기본 버튼 모양 / flat 은 평평한 모양)
 * @param {Object[]} props.tabs - 탭 목록
 * @param {string} props.tabs[].name - 탭 이름
 * @param {string} [props.tabs[].route] - 탭 누르면 이동할 경로
 * @param {Object} [props.tabs[].query] - 탭 누르면 이동할 쿼리
 * @param {Object} [props.tabs[].layout] - 레이아웃 경로 (시작하는 경로에 따라 같은 경로로 인식해야 하는 경우)
 * @param {JSX.Element} [props.moreTab] - 추가 탭
 * @returns {JSX.Element} MyTabGroup 컴포넌트
 */
const TabGroup = ({
  tabs,
  type = 'default',
  className = '',
  moreTab,
  onHandle,
}) => {
  const {getParams} = useUrlParameter();
  // 동적으로 바뀌는 parameter들
  const {
    szKeyword: keyword = '',
    szSearchType: searchType = '',
    hashtype: hashType = '',
    tagname,
  } = getParams();
  const pathname = usePathname();
  const params = useSearchParams();

  const isCurrentTab = (route, query, layout) => {
    if (Array.isArray(layout) && !isEmpty(layout)) {
      return layout.includes(pathname);
    }

    if (route) {
      return route === pathname;
    }

    //param 으로 route 할 경우 query : { matchKey: value }, parameter ?matchKey=value 형태로 맞출것 - matchKey는 예시 입니다.

    if (query && !isEmpty(query)) {
      const key = Object.keys(query)[0];
      if (key === 'hashtype' && params.get(key) === null) {
        return query[key] === 'live';
      } else if (params.get(key) === 'catch') {
        return query[key] === 'vod';
      }

      return params.get(key) === query[key];
    }
  };

  const navigate = useNavigate();

  const handleButtonClick = (route, query, item) => () => {
    const queryString = new URLSearchParams(query).toString();

    onHandle && onHandle(item || query || route);
    navigate(`${route ?? pathname}${queryString ? `?${queryString}` : ''}`);
    // 새로고침 하는 부분이 필요할수도 있을거 같아 href 기능은 주석처리
    // window.location.href = `${route ?? pathname}${queryString ? `?${queryString}` : ''}`;
  };
  return (
    <div className={`${className} ${type === 'flat' ? 'tab_list_item' : ''}`}>
      {pathname.startsWith('/my/feed') ? null : (
        <SoopUiSwiper
          swiperTag='ul'
          isDisableButton
          includesControlsIntoSwiper={true}
          swiperClass={TAB_CLASS_MAP[type]}
          options={{
            slidesPerView: 'auto',
            spaceBetween: 6,
            loop: false,
          }}>
          {tabs.map(({name, route, query, layout}, index) => (
            <li
              className={isCurrentTab(route, query, layout) ? 'on' : ''}
              key={`${name}-${index}`}>
              {type === 'default' && (
                <Link to={`${route ?? pathname}${query ? `/${query}` : ''}`}>
                  {name}
                </Link>
              )}
              {type === 'flat' && (
                <button type='button' onClick={handleButtonClick(route, query)}>
                  <span>{name}</span>
                </button>
              )}
              {type === 'search_route' && (
                <button
                  type='button'
                  onClick={handleButtonClick(route, query, name)}>
                  {name}
                </button>
              )}
            </li>
          ))}
          {moreTab?.props?.children &&
            moreTab.props.children.map((child, index) => (
              <li key={index}>{child}</li>
            ))}
        </SoopUiSwiper>
      )}
    </div>
  );
};

export default TabGroup;
