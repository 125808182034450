import {DEFAULT_POSTER_THUMB_TMG} from '@/components/ui/SoopUiImg';
import useGlobalUIStore from '@/stores';
import {convertViewCnt} from '@/utils/main';
import {sendClickLog} from '@/utils/main/my-plus/log';
import {Link, useNavigate} from 'react-router-dom';

export const DirectoryCategoryListItem = ({index, item}) => {
  const navigate = useNavigate();
  const {isDarkMode} = useGlobalUIStore();

  const handleOnClickCategory = (e, categoryName) => {
    e.preventDefault();
    const encodeCategoryName = encodeURIComponent(categoryName);
    //클릭 로그
    sendClickLog('cate_list', {
      buttonType: 'directory_cate',
      location: 'directory',
      filterId: categoryName,
      filterIdx: index,
    });
    navigate(`/directory/category/${encodeCategoryName}/live`);
  };

  const handleImageError = (e) => {
    const imgElement = e.target;
    imgElement.src = DEFAULT_POSTER_THUMB_TMG(isDarkMode);
    imgElement.setAttribute('data-replaced', 'true');
  };

  return (
    <li key={item.categoryNo}>
      <a href='#' onClick={(e) => handleOnClickCategory(e, item.categoryName)}>
        {item.viewCnt > 0 && (
          <span className='views'>
            <em>{convertViewCnt(item.viewCnt)}</em>
          </span>
        )}
        <div className='thumb'>
          <img
            src={item.cateImg}
            alt={item.categoryName}
            onError={(e) => handleImageError(e)}
          />
        </div>
        <div className='info_wrap'>
          <p className='title'>{item.categoryName}</p>
        </div>
      </a>
      <div className='tag_wrap'>
        {item.fixedTags &&
          item.fixedTags.length > 0 &&
          item.fixedTags.map(
            (data, idx) =>
              data.length > 0 && (
                <Link
                  key={idx}
                  to={`/search?hash=hashtag&tagname=${encodeURIComponent(data)}&hashtype=live&stype=hash&acttype=total&location=directory`}>
                  {data}
                </Link>
              ),
          )}
      </div>
    </li>
  );
};
