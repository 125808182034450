'use client';
import {SoopUiImg} from '@/components/ui';
import useDirectoryStore from '@/stores/directory';
import {convertViewCnt} from '@/utils/main';
import {useState} from 'react';
import {Link} from 'react-router-dom';

const CategoryDetail = ({categoryInfo}) => {
  const [imageError, setImageError] = useState(false);
  const {selectedCategoryTab} = useDirectoryStore();
  const handleImageError = () => {
    setImageError(true);
  };
  return (
    <>
      {categoryInfo && (
        <div className='list_detail'>
          <span className='thumb'>
            <SoopUiImg
              src={categoryInfo.cateImg}
              alt=''
              type={'poster'}
              onError={handleImageError}
            />
          </span>
          <div className='detail_box'>
            <p className='tit'>{categoryInfo.categoryName}</p>
            <span className='info'>
              <em>{convertViewCnt(categoryInfo.viewCnt)}</em>명 참여중
            </span>
            <div className='tag_wrap'>
              {categoryInfo.fixedTags &&
                categoryInfo.fixedTags.length > 0 &&
                categoryInfo.fixedTags.map((item, idx) => (
                  <Link
                    key={idx}
                    to={`/search?hash=hashtag&tagname=${encodeURIComponent(item)}&hashtype=${selectedCategoryTab}&stype=hash&acttype=total&location=directory`}>
                    {item}
                  </Link>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default CategoryDetail;
