import '/public/static/css/module/depth2Menu.scss';
import DirectoryApi from '@/api/directory/directory';
import {SoopUiSwiper} from '@/components/ui';
import {DIRECTORY_INIT_LIST} from '@/constants/main';
import useDirectoryStore from '@/stores/directory';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

export const DirectoryMenu = ({handleOnClickAdminMenu = () => {}}) => {
  const swiperConfig = {
    slidesPerView: 'auto',
    loop: false,
    spaceBetween: 6,
  };
  const {
    selectedCategoryMenu,
    setSelectedCategoryType,
    setSelectedCategoryTab,
    setSelectedCategoryMenu,
    setSelectedCategoryName,
  } = useDirectoryStore();
  const [categoryMenu, setCategoryMenu] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCategoryMenu();
  }, []);

  //카테고리 메뉴 리스트 api
  const getCategoryMenu = async () => {
    try {
      const response = await DirectoryApi.getCategoryMenu();
      if (response && response.data) {
        setCategoryMenu(response.data.list);
      }
    } catch (error) {
      return setCategoryMenu(DIRECTORY_INIT_LIST);
    }
  };

  const handleOnClickCategoryMenu = (e, item) => {
    e.preventDefault();
    const {type, menuId, menuName, adminId} = item;
    setSelectedCategoryName(menuName);
    setSelectedCategoryType({
      type: type,
      adminId: adminId,
    });
    handleOnClickAdminMenu();
    if (type !== 'category') {
      setSelectedCategoryTab('live');
      navigate(`/directory/category/${menuId}`);
      setSelectedCategoryMenu(menuId);
    } else {
      setSelectedCategoryMenu(0);
      navigate(`/directory/category`);
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      {categoryMenu && (
        <div className='dept2_menu_wrap'>
          <SoopUiSwiper
            options={swiperConfig}
            includesControlsIntoSwiper={true}
            swiperClass='tab_swiper_item'
            swiperTag='ul'>
            {categoryMenu.map((item, idx) => (
              <li
                className={`swiper-slide ${
                  selectedCategoryMenu === item?.menuId ? 'on' : ''
                }`}
                key={idx}>
                <a
                  role='button'
                  onClick={(e) => handleOnClickCategoryMenu(e, item)}>
                  {item.menuName}
                </a>
              </li>
            ))}
          </SoopUiSwiper>
        </div>
      )}
    </>
  );
};
