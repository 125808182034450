import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

/**
 * AdvertisementGroup 컴포넌트 ( 광고영역 )
 * @param {Object} profileTheme - profileTheme API의 리턴값
 * @returns AdvertisementGroup 컴포넌트
 */
const AdvertisementGroup = (profileTheme) => {
  const {advertisement = {}} = profileTheme ?? {};
  const {backgroundColor = '', image = '', url = ''} = advertisement;

  const {sendAdClickLog} = useSearchClickLog();
  const {sessionKey} = useSearchGlobalStatus();

  // SCKACT 로그
  const handleSectionClick = () => {
    const params = {
      tab: 'total',
      acttype: 'ad',
      actcode: 'bn',
      location: 'total_search',
      sessionKey: sessionKey,
    };

    sendAdClickLog(params);
  };

  if (!image || !url || !backgroundColor) {
    return null;
  }

  return (
    <a
      className='a_d_area'
      style={{backgroundColor}}
      href={url}
      target='_blank'
      onClick={handleSectionClick}>
      <img src={image} title='' alt='Advertisement' />
    </a>
  );
};

export default AdvertisementGroup;
