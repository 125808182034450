'use client';
import {useRouter, useSearchParams, usePathname} from 'next/navigation';

export default function ListShowMore({pageNo}) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const {replace} = useRouter();

  const handleMoreButtonClicked = () => {
    //UseQueryParam({pageNo: '123123'});
    const params = new URLSearchParams(searchParams);
    console.log(pathname, params);
    params.set('pageNo', '2');
    replace(`${pathname}?${params.toString()}`);
  };

  return (
    <div className='show_more'>
      <button type='button' onClick={handleMoreButtonClicked}>
        더보기
      </button>
    </div>
  );
}
