import {useCallback, useEffect, useState} from 'react';

import MyCatch from '@/components/my/common/item/MyCatch';
import {SoopUiSwiper} from '@/components/ui';
import {SectionHeader} from '@/components/main/common';
import {ErrorBoundary} from '@/components/common';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

/**
 * CatchStoryGroup 컴포넌트 ( 캐치스토리 콘텐츠 )
 * @param {Object} props 컴포넌트의 props
 * @param {object[]} catchStory 캐치스토리 데이터
 * @returns {JSX.Element} CatchStoryGroup 컴포넌트
 */
const CatchStoryGroup = ({catchStory}) => {
  const {sendCatchClickLog} = useSearchClickLog();
  const {sessionKey} = useSearchGlobalStatus();

  const [catchStoryIdList, setCatchStoryIdList] = useState([]);

  // SCKACT 로그
  const handleCatchStoryLog = useCallback(
    (params, index, catchNumber, userId) => {
      const {type} = params || {};

      let param = {
        actCode: type,
        vno: catchNumber,
        listIdx: index,
        sectionName: 'catch_story_vod',
        fileType: 'catch_story',
        contentsType: type === 'nick' ? '' : 'vod',
        contentsValue: type === 'nick' ? '' : catchNumber,
        sessionKey: sessionKey,
        bjid: userId,
      };

      sendCatchClickLog(param, '');
    },
    [sessionKey],
  );

  useEffect(() => {
    if (catchStory.length > 0) {
      const list = catchStory
        .filter((item) => item.fileType === 'CATCH_STORY')
        .map((item) => item.listNo);
      setCatchStoryIdList(list);
    }
  }, [catchStory]);

  if (catchStory && catchStory.length < 1) {
    return null;
  }

  return (
    <>
      <SectionHeader title={'Catch 스토리'} />

      <div className='catch_story_area'>
        <SoopUiSwiper
          swiperTag='ul'
          swiperClass='catch_list catch_story_list'
          maxSlidesPerView={10}
          options={{
            loop: false,
            spaceBetween: 20,
          }}>
          {catchStory?.map((catchStory, index) => {
            return (
              <li key={`catch_story_${catchStory?.titleNo}`}>
                <ErrorBoundary>
                  <MyCatch
                    isStory={true}
                    {...{
                      ...catchStory,
                      catchId: catchStory?.listNo,
                      thumbnailSrc: catchStory?.thumbnailPath,
                      userNickname: catchStory?.originalUserNick,
                      viewCount: catchStory?.viewCnt,
                      broadcastDate: catchStory?.regDate,
                      catchStoryIdList,
                      catchContextMenuList: [
                        {label: '공유하기', type: 'share'},
                      ],
                      isLogSendData: {
                        story_no: catchStory?.listNo,
                        cli_list_data_idx: index + 1,
                      },
                    }}
                    logCallback={(params) => {
                      handleCatchStoryLog(
                        params,
                        index,
                        catchStory?.titleNo,
                        catchStory?.userId,
                      );
                    }}
                  />
                </ErrorBoundary>
              </li>
            );
          })}
        </SoopUiSwiper>
      </div>
    </>
  );
};

export default CatchStoryGroup;
