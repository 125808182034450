'use client';

import {Banner} from '@/components/common';
import MyTabGroup from '@/components/my/common/group/TabGroup';
import {ROUTE} from '@/constants/main/route';
import {Outlet, useLocation} from 'react-router-dom';
import '/public/static/css/module/depth2Menu.scss';

const TABS = [
  {
    name: '최근 본 스트리머의 LIVE',
    route: ROUTE.MY.HISTORY.RECENT.INDEX,
  },
  {
    name: '최근 본 VOD',
    route: ROUTE.MY.HISTORY.RECENT.VOD,
  },
  {
    name: '나중에 보기',
    route: ROUTE.MY.HISTORY.LATER,
  },
  {
    name: 'UP한 VOD',
    route: ROUTE.MY.HISTORY.UP,
  },
  {
    name: '재생목록',
    route: ROUTE.MY.HISTORY.PLAYLIST,
  },
];

/**
 * /my 경로 한정 레이아웃
 * @param {React.ReactNode} 페이지
 * @returns {JSX.Element} 페이지 레이아웃
 */
const MyLayout = () => {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <>
      {pathname !== '/my/history/story' && (
        <MyTabGroup tabs={TABS} type='flat' />
      )}
      <Banner type={'sub'} category={'history'} />
      <Outlet />
    </>
  );
};

export default MyLayout;
