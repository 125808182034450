import {matchPath, Outlet, useLocation} from 'react-router-dom';
import NavigationLoader from '../NavigationLoader';
import {Suspense} from 'react';
import {ROUTE} from '@/constants/main/route';
import {blockDuringMaintenance} from '@/utils';

const getIsPathValid = (pathname, routes) => {
  for (const key in routes) {
    const value = routes[key];
    if (typeof value === 'string') {
      if (matchPath(value, pathname)) {
        return true;
      }
    } else if (typeof value === 'object' && value !== null) {
      if (getIsPathValid(pathname, value)) {
        return true;
      }
    }
  }
  return false;
};

export default function Layout() {
  const location = useLocation();
  const isPathValid = getIsPathValid(location.pathname, ROUTE);

  return (
    <>
      {isPathValid && (
        <>
          <div id='soop_wrap'>
            <div id='soop-gnb'></div>
            <div id='soop-snb'></div>
            <main id='main'>
              <Outlet />
              <div id='soop-fnb'></div>
            </main>
          </div>
          <Suspense>
            <NavigationLoader />
          </Suspense>
        </>
      )}
      {!isPathValid && <Outlet />}
    </>
  );
}
