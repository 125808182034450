import '/public/static/css/module/category.scss';
import MainLiveApi from '@/api/main/main-live';
import {SoopUiSwiper} from '@/components/ui';
import globalDomains from '@/utils/global-domains';
import {convertViewCnt, isLogin} from '@/utils/main';
import {useEffect, useState} from 'react';
import useGlobalUIStore from '@/stores';
import {DEFAULT_POSTER_THUMB_TMG} from '@/components/ui/SoopUiImg';
import {useLocation} from 'react-router-dom';

export const LiveGroupCategoryList = ({
  categoryList,
  selectedCategory,
  handleOnClickPosterCategory,
}) => {
  const [preferCategoryList, setPreferCategoryList] = useState([]);
  const [isExpand, setIsExpand] = useState(false);
  const [swiperConfig, setSwiperConfig] = useState({
    spaceBetween: 0,
    slidesPerView: 'auto',
    loop: false,
    direction: 'horizontal',
    scrollbar: false,
    freeMode: false,
  });
  const {isDarkMode} = useGlobalUIStore();
  const [resetSlide, setResetSlide] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  useEffect(() => {
    if (categoryList && categoryList.length > 0) {
      if (isLogin()) {
        getPreferCategoryList();
      } else {
        setPreferCategoryList(categoryList);
      }
    } else {
      setPreferCategoryList([]);
    }
    //언마운트시 초기화
    return () => {
      setIsExpand(false);
      setSwiperConfig({
        spaceBetween: 0,
        slidesPerView: 'auto',
        loop: false,
        direction: 'horizontal',
        scrollbar: false,
        freeMode: false,
      });
    };
  }, [categoryList]);

  useEffect(() => {
    if (isExpand) {
      setTimeout(() => {
        const item = document.querySelector('.swiper-wrapper li a.active');
        if (item) {
          item.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
      }, 100); // 렌더링이 완료된 후에 실행되도록 지연
    }
  }, [isExpand]);

  useEffect(() => {
    setResetSlide(true);
  }, [location.pathname]);

  const getPreferCategoryList = async () => {
    try {
      const response = await MainLiveApi.getPreferCategoryList();
      if (response && response.result === 1) {
        if (response.data.length > 0) {
          setPreferCategoryList(
            preferCategorySort(categoryList, response.data),
          );
        } else {
          setPreferCategoryList([...categoryList]);
        }
      }
    } catch (error) {
      setPreferCategoryList(categoryList);
    }
  };

  const handleonClickShowMore = () => {
    setIsExpand(!isExpand);
  };

  const handleImageError = (e) => {
    const imgElement = e.target;
    imgElement.src = DEFAULT_POSTER_THUMB_TMG(isDarkMode);
    imgElement.setAttribute('data-replaced', 'true');
  };

  useEffect(() => {
    if (isExpand) {
      setSwiperConfig((prevConfig) => ({
        ...prevConfig,
        direction: 'vertical',
        scrollbar: true,
        freeMode: true,
      }));
    } else {
      setSwiperConfig((prevConfig) => ({
        ...prevConfig,
        direction: 'horizontal',
        scrollbar: false,
        freeMode: false,
      }));
    }
  }, [isExpand]);

  // 포스터를 클릭할 때 Swiper 인스턴스를 초기화하거나 업데이트
  const handlePosterClick = (e, broadCateNo, index) => {
    setResetSlide(false);
    setIsExpand(false);
    setActiveIndex(index);
    handleOnClickPosterCategory(e, broadCateNo);
  };

  return (
    <>
      {preferCategoryList && preferCategoryList.length > 0 && (
        <>
          <div className={`category_conts_wrap ${isExpand ? 'expand' : ''}`}>
            <SoopUiSwiper
              key={location.pathname} // Use location.pathname as the key to remount component
              swiperClass='category_conts'
              swiperTag='ul'
              hasControls={!isExpand}
              maxSlidesPerView={10}
              options={swiperConfig}
              resetSlideOnUpdate={resetSlide}
              resetDirection={true}
              activeIndex={activeIndex}>
              {preferCategoryList.map((item, index) => (
                <li key={item.broadCateNo}>
                  <a
                    className={
                      item.broadCateNo === selectedCategory.categoryNo
                        ? 'active'
                        : ''
                    }
                    style={{cursor: 'pointer'}}
                    onClick={(e) =>
                      handlePosterClick(e, item.broadCateNo, index)
                    }>
                    <span className='views'>
                      <em>{convertViewCnt(item.viewer)}</em>
                    </span>
                    <div className='thumb'>
                      <img
                        src={`${globalDomains.ADMIN_IMG_AFREECATV}/${item.catePcImg}`}
                        alt={item.cateName}
                        onError={handleImageError}
                      />
                    </div>
                    <div className='info_wrap'>
                      <p className='title'>{item.cateName}</p>
                    </div>
                  </a>
                </li>
              ))}
            </SoopUiSwiper>
          </div>
          <div className={`show_more ${isExpand ? 'close' : ''}`}>
            <button type='button' onClick={handleonClickShowMore}>
              {isExpand ? '간략히 보기' : '더보기'}
            </button>
          </div>
        </>
      )}
    </>
  );
};

const preferCategorySort = (categoryList, preferCategoryList) => {
  const preferredCategories = [];
  const otherCategories = [];
  try {
    categoryList.forEach((category) => {
      const preferIdx = preferCategoryList.indexOf(category.broadCateNo);

      if (preferIdx !== -1) {
        preferredCategories[preferIdx] = category;
      } else {
        otherCategories.push(category);
      }
    });

    // null 제거
    const filteredPreferredCategories = preferredCategories.filter(
      (item) => item !== null,
    );
    return filteredPreferredCategories.concat(otherCategories);
  } catch (error) {
    return categoryList;
  }
};
