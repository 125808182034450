import {hostGenerator} from '@afreecatv/domains';

const envConverter = {
  local: 'development',
  dev: 'development',
  qa: 'staging',
  prod: 'production',
};

const globalDomains = hostGenerator({
  environment: envConverter[process.env.NODE_ENV] || 'development',
  currentProtocol: 'https:', // document ? document.location.protocol : 'https:',
});
export default globalDomains;
