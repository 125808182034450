import {useCallback} from 'react';

import MyCatch from '@/components/my/common/item/MyCatch';
import {SoopUiSwiper} from '@/components/ui';
import {SectionHeader} from '@/components/main/common';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';
import {ErrorBoundary} from '@/components/common';
import { decodeFullHtmlEntities } from '@/utils';

/**
 * CatchGroup 컴포넌트 ( 캐치 콘텐츠 )
 * @param {Object} props 컴포넌트의 props
 * @param {object[]} catchList 캐치 데이터
 * @returns {JSX.Element} CatchGroup 컴포넌트
 */
const CatchGroup = ({catchList}) => {
  const {sendCatchClickLog} = useSearchClickLog();
  const {sessionKey} = useSearchGlobalStatus();

  // SCKACT 로그
  const handleCatchLog = useCallback(
    (params, index, catchNumber, userId) => {
      const {type = 'sn'} = params || {};

      let param = {
        actCode: type,
        vno: catchNumber,
        bjid: userId,
        listIdx: index,
        contentsValue: catchNumber,
        sessionKey: sessionKey,
      };
      sendCatchClickLog(param, '');
    },
    [sessionKey],
  );

  if (catchList && catchList.length < 1) {
    return null;
  }

  return (
    <>
      <SectionHeader title={'Catch'} />
      <div className='catch_area'>
        <SoopUiSwiper
          swiperTag='ul'
          swiperClass='catch_list catch_story_list'
          maxSlidesPerView={10}
          options={{
            loop: false,
            spaceBetween: 20,
          }}>
          {catchList?.map((catchInfo, index) => {
            return (
              <li key={`catch_${catchInfo.titleNo}`}>
                <ErrorBoundary>
                  <MyCatch
                    {...{
                      ...catchInfo,
                      title: decodeFullHtmlEntities(catchInfo.title),
                      catchId: catchInfo.titleNo,
                      isAdult: catchInfo.grade === '19',
                      thumbnailSrc: catchInfo.thumbnailPath,
                      userNickname: catchInfo.userNick,
                      viewCount: catchInfo.viewCnt,
                      broadcastDate: catchInfo.regDate,
                      catchContextMenuList: [
                        {label: '공유하기', type: 'share'},
                      ],
                      isLogSendData: {cli_list_data_idx: index + 1},
                    }}
                    logCallback={(params) => {
                      handleCatchLog(
                        params,
                        index,
                        catchInfo.titleNo,
                        catchInfo.userId,
                      );
                    }}
                  />
                </ErrorBoundary>
              </li>
            );
          })}
        </SoopUiSwiper>
      </div>
    </>
  );
};

export default CatchGroup;
