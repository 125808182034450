import MyAvatar from '@/components/my/common/item/MyAvatar';
import NoList from '@/components/my/common/item/NoList';
import {SoopUiSwiper} from '@/components/ui';
import '/public/static/css/module/cBox.scss';
import '/public/static/css/module/strm_list.scss';

function getAvatarClass(isLive, isPin) {
  return `strm_box ${isLive ? 'live' : ''} ${isPin ? 'pinOn' : ''}`;
}

/**
 * MyAvatarGroup 컴포넌트
 * @param {Object} props - MyAvatarGroup props
 * @param {Array} props.favorites[] - 즐겨찾기 한 스트리머 목록
 * @param {Function} props.handleUserPin - 스트리머 핀 설정/해제 핸들러
 * @param {boolean} props.isFanclub - 팬클럽 컴폼넌트 여부
 * @param {boolean} props.isSubscribe - 구독 컴포넌트 여부
 * @param {boolean} props.isFavorite - 즐겨찾기 컴포넌트 여부
 * @returns {JSX.Element} MyAvatarGroup 컴포넌트
 */
const AvatarGroup = ({
  favorites,
  handleUserPin,
  isFanclub,
  isSubscribe,
  isFavorite,
  isShowSubscribe = true,
  isShowFanclub = true,
}) => {
  return (
    <>
      {favorites.length > 0 && (
        <div className='strm_list'>
          <SoopUiSwiper
            swiperTag='ul'
            swiperClass='strm_list'
            maxSlidesPerView={15}
            isSameSlidesViewGroup
            key={favorites.length}
            options={{
              spaceBetween: 6,
              speed: 450,
              loop: false,
            }}>
            {favorites.map((user) => {
              const {isLive, isPin, userId} = user;
              const avatarClass = getAvatarClass(isLive, isPin);

              return (
                <li key={`avatar-${userId}`} className={avatarClass}>
                  <MyAvatar
                    {...user}
                    handleUserPin={handleUserPin}
                    isShowFanclub={isShowFanclub}
                    isShowSubscribe={isShowSubscribe}
                  />
                </li>
              );
            })}
          </SoopUiSwiper>
        </div>
      )}
      {favorites.length === 0 && (
        <>
          {isFavorite && <FavoriteNoList />}
          {isSubscribe && <SubscribeNoList />}
          {isFanclub && <FanclubNoList />}
        </>
      )}
    </>
  );
};

const SubscribeNoList = () => {
  return (
    <NoList
      title='구독한 스트리머가 없습니다.'
      description={
        <>
          스트리머를 즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,
          <br />
          방송 시작 시 알림을 받을 수 있습니다.
        </>
      }
    />
  );
};

const FanclubNoList = () => {
  return (
    <NoList
      title='가입한 팬클럽이 없습니다.'
      description={
        <>
          LIVE, VOD, 채널 등 다양한 곳에서 스트리머에게 선물하여
          <br />팬 전용 채팅 색깔과 팬 게시판을 이용해보세요.
        </>
      }
    />
  );
};

const FavoriteNoList = () => {
  return (
    <NoList
      title='즐겨찾기 한 스트리머가 없습니다.'
      description={
        <>
          스트리머를 즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수 있으며,
          <br />
          방송 시작 시 알림을 받을 수 있습니다.
        </>
      }
    />
  );
};

export default AvatarGroup;
