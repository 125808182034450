import React, {useRef} from 'react';
import useMouseDrag from '@/components/player/common/hooks/useMouseDrag';

export const VideoProgressBar = ({
  progress,
  setProgress,
  onProgress = (progress) => {},
  isSeekable = true,
}) => {
  const progressBarRef = useRef(null);

  const {handleMouseDown, progressWhileDragging, isDraggingRef} = useMouseDrag(
    progressBarRef,
    setProgress,
    onProgress,
  );

  return (
    <div
      className='progress'
      style={{
        cursor: `${isSeekable ? '' : 'default'}`,
      }}
      ref={progressBarRef}
      onMouseDown={isSeekable ? handleMouseDown : null}>
      <div className='progress_track'>
        <div
          className='watched'
          style={{
            width: `${isDraggingRef.current ? progressWhileDragging : progress}%`,
          }}></div>
        <button
          className='handler'
          style={{
            left: `${isDraggingRef.current ? progressWhileDragging : progress}%`,
            cursor: `${isSeekable ? '' : 'default'}`,
          }}>
          <span></span>
        </button>
        <div className='progress_bar'></div>
      </div>
    </div>
  );
};
