import {SoopUiImgRework} from '@/components/ui';
import globalDomains from '@/utils/global-domains';
import {useState, useEffect} from 'react';

const getWebpProfileSrc = (userId, extention = 'webp', size = 'small') => {
  if (userId) {
    return `${globalDomains.STIMG_AFREECATV}/LOGO/${userId.substring(0, 2)}/${userId}${extention === 'webp' && size === 'small' ? '/m' : ''}/${userId}.${extention}`;
  }
};
const DEFAULT_PROFILE_IMG = `${globalDomains.RES_AFREECATV}/images/svg/thumb_profile.svg`;

/**
 * My 프로필 이미지 컴포넌트
 * @desc 사용자 아이디를 받아 프로필 이미지를 렌더링
 * @desc 프로필 이미지는 경로가 고정되어있어서 추가하였음
 * @param {Object} props - 컴포넌트의 props
 * @param {string} props.userId - 사용자 아이디
 * @param {string} [props.className=''] - 클래스명
 * @param {'small' | 'large'} [props.size='small'] - 프로필 이미지 사이즈 webp 구분, small 일때 url /m 이 추가로 붙음, 아닐 경우는 안붙음 [small, large]
 */
const MyProfileImg = ({
  userId,
  adminProfileImg,
  className = '',
  size = 'small',
}) => {
  const [imgSrc, setImgSrc] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //씨네티같은경우는 어드민에서 등록한 프로필 이미지를 내려준다고한다.
    const webpSrc = adminProfileImg
      ? adminProfileImg
      : getWebpProfileSrc(userId, 'webp', size);
    const webpImg = new Image();

    webpImg.onload = () => {
      setImgSrc(webpSrc);
      setLoading(false);
    };

    webpImg.onerror = () => {
      const jpgSrc = getWebpProfileSrc(userId, 'jpg', size);
      const jpgImg = new Image();

      jpgImg.onload = () => {
        setImgSrc(jpgSrc);
        setLoading(false);
      };

      jpgImg.onerror = () => {
        setImgSrc(DEFAULT_PROFILE_IMG);
        setLoading(false);
      };

      jpgImg.src = jpgSrc;
    };

    webpImg.src = webpSrc;
  }, [userId, size]);

  if (loading) {
    return null; // 리스트쪽에 변동이있을때마다 기본 프로필 이미지에서 프로필 이미지로 바뀌는 현상이 오동작같아서 수정
  }

  return <SoopUiImgRework src={imgSrc} alt={userId} className={className} />;
};

export default MyProfileImg;
