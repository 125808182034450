import {MY_SORT_ORDER} from '@/stores/my';

/**
 * 스트리머를 고정 상태, 방송 상태, 주어진 키로 정렬
 * @desc 배열에 주어진 순서에 따라 스트리머를 정렬
 * @param {Array} streamers - 정렬할 스트리머들
 * @param {string} [sortKey] - 정렬할 키
 * @param {Object} [options] - 옵션
 * @param {Array} [options.fixedOrder] - 우선순위 정렬할 키 배열 (예: ['isPin', 'isLive'])
 * @returns {Array} - 정렬된 스트리머들
 */
export function sortStreamers(streamers, sortKey, options) {
  const {fixedOrder = [], sortOrder = 'ASC'} = options || {};

  // 스트리머들을 각각의 그룹으로 분류
  const groups = {};
  streamers.forEach((streamer) => {
    const groupKey = fixedOrder.map((key) => streamer[key]).join('-');
    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(streamer);
  });

  // 그룹을 우선순위에 따라 정렬
  const sortedGroups = Object.keys(groups).sort((prev, next) => {
    for (let i = 0; i < fixedOrder.length; i++) {
      const key = fixedOrder[i];
      if (groups[prev][0][key] !== groups[next][0][key]) {
        return groups[next][0][key] - groups[prev][0][key];
      }
    }
    return 0;
  });

  // 그룹 내에서 sortKey로 스트리머를 정렬
  let sortedStreamers = [];
  sortedGroups.forEach((groupKey) => {
    let group = groups[groupKey].sort((prevStreamer, nextStreamer) =>
      sortByKey(prevStreamer, nextStreamer, sortKey),
    );

    if (sortOrder === MY_SORT_ORDER.DESC || sortKey === 'lastBroadStart') {
      group = group.reverse();
    }
    sortedStreamers = sortedStreamers.concat(group);
  });

  return sortedStreamers;
}

/**
 * 주어진 키로 스트리머를 정렬하고 정렬 순서를 반환
 * 키 값이 숫자인 경우, 큰 값이 앞에 오게 됨
 * 키 값이 문자열인 경우, 알파벳 순서대로 정렬됨
 * @param {Object} prevStreamer - 이전 스트리머
 * @param {Object} nextStreamer - 다음 스트리머
 * @param {string} key - 정렬할 키
 * @returns {number} - 정렬 순서
 */
function sortByKey(prevStreamer, nextStreamer, key) {
  if (prevStreamer[key] && nextStreamer[key]) {
    if (
      typeof prevStreamer[key] === 'number' &&
      typeof nextStreamer[key] === 'number'
    ) {
      return nextStreamer[key] - prevStreamer[key];
    } else if (
      typeof prevStreamer[key] === 'string' &&
      typeof nextStreamer[key] === 'string'
    ) {
      return prevStreamer[key].localeCompare(nextStreamer[key]);
    }
  }
  return 0;
}
