'use client';
import useDialogStore from '@/stores/ui/dialogs';
import _ from 'lodash';
import {useEffect} from 'react';

const Deferrer = () => {
  let resolve = null,
    reject = null;
  const promise = new Promise((success, fail) => {
    resolve = success;
    reject = fail;
  });
  return {
    promise,
    resolve,
    reject,
  };
};

/**
 * 다이얼로그를 띄우는 함수
 * @param {String} name - dialog 이름
 * @param {Object} data - dialog 에 전달할 데이터
 * @param {Object} option  - dialog 옵션 (구체화가 되면 더 좋습니다)
 * @returns {Promise} - dialog 종료 시 resolve 되는 Promise
 */
export const throwDialog = (name, data, option) => {
  const id = _.uniqueId('soop-dialog-');
  console.log('ThrowDialog ::', id, name, option, data);
  /*
   * onClick 내에서 throwDialog 호출 시 에러 발생. Invalid hook call. ~ You might have mismatching versions of React and the renderer
   * 결국 onClick handler 내에서 useDialogStore를 호출하는 꼴이라 발생하는 에러로 react 애기(baby)는 추측한다.
   * zustand의 이거 뭐라고 부르냐 getState, setState으로 처리를 하는 것으로 해결
   *
   * const addDialog = useDialogStore((state) => {
        return state.addDialog;
     });

     addDialog(... );
   * */
  /*useDialogStore.setState({
    dialogList: [
      ...useDialogStore.getState().dialogList,
      {id, name, option, data, _deferrer: Deferrer()},
    ],
  });
  const lastAddedDialog = _.find(useDialogStore.getState().dialogList, {
    id: id,
  });
  return lastAddedDialog._deferrer.promise;*/

  //addDialog(id, name, option, data, Deferrer());
  /* useDialogStore.setState({
    dialogList: [
      ...useDialogStore.getState().dialogList,
      {id, name, option, data, _deferrer: Deferrer()},
    ],
  });*/
  useDialogStore.getState().addDialog(id, name, data, option, Deferrer());
  const lastAddedDialog = _.find(useDialogStore.getState().dialogList, {
    id: id,
  });
  return lastAddedDialog._deferrer.promise;
};

export const throwAlert = (data) => {
  return throwDialog('Message', data, {
    size: 'small',
    isCustomDialog: true,
    hasCloseButtonOnly: true,
    isConfirm: !data.title,
    isAlert: true,
  });
};

export const throwConfirm = (data) => {
  return throwDialog('Message', data, {
    isCustomDialog: true,
    hasCloseButtonOnly: false,
    isConfirm: true,
  });
};
