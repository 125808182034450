/**
 * getTabs 함수는 API 응답으로 받은 gameProfile 데이터에서 특정 파라미터 값(bj, esportList, stations, events, series)이
 * 존재하는 경우에만 해당 항목을 탭으로 생성하여 반환합니다.
 *
 * @param {object[]} bj - 관련 스트리머 목록. 값이 존재하면 '관련 스트리머' 탭이 생성됩니다.
 * @param {object[]} esportList - 경기 일정 목록. 첫 번째 아이템의 `keywordShow` 값이 1인 경우 '경기 일정' 탭이 생성됩니다.
 * @param {object[]} stations - 공식 채널 목록. 값이 존재하면 '공식 채널' 탭이 생성됩니다.
 * @param {object[]} events - 이벤트 목록. 값이 존재하면 '이벤트' 탭이 생성됩니다.
 * @param {object[]} series - 시리즈 게임 목록. 값이 존재하면 '시리즈게임' 탭이 생성됩니다.
 *
 * @returns {object[]} tabs - 값이 있는 항목들에 대해 생성된 탭 객체의 배열. 각 탭은 `id`와 `label`을 포함합니다.
 */
export const getTabs = (bj, esportList, stations, events, series) => {
  const tabs = [];

  if (bj && bj.length > 0) {
    tabs.push({id: 'streamers', label: '관련 스트리머'});
  }

  if (esportList && esportList.length > 0 && esportList[0].keywordShow === 1) {
    tabs.push({id: 'schedule', label: '경기 일정'});
  }

  if (stations && stations.length > 0) {
    tabs.push({id: 'official', label: '공식 채널'});
  }

  if (events && events.length > 0) {
    tabs.push({id: 'events', label: '이벤트'});
  }

  if (series && series.length > 0) {
    tabs.push({id: 'seriesGames', label: '시리즈게임'});
  }

  return tabs;
};

// SCKACT 로그에 sectionName 값을 보내기 위한 메소드
export const getSectionName = (tab) => {
  switch (tab) {
    case 'streamers':
      return 'bj';
    case 'schedule':
      return 'esport';
    case 'official':
      return 'station';
    case 'events':
      return 'event';
    case 'seriesGames':
      return 'series';
    default:
      return '';
  }
};
