'use client';
import '/public/static/css/module/strm_list.scss';

import {useState} from 'react';

import {
  GameEvent,
  SeriesGame,
  GameSchedule,
  RelatedStreamers,
  OfficialChannels,
} from '@/components/search/group/game-profile-group';
import {SoopUiSwiper} from '@/components/ui';
import {ErrorBoundary} from '@/components/common';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';

import {sendLegacyInflowPathLog} from '@/utils/main/my/log';
import {getTabs, getSectionName} from '@/utils/main/search/gameProfileSection';

/**
 * GameProfileContents 컴포넌트 ( 게임 프로필 정보 )
 * @param {Object} props - 컴포넌트의 props
 * @param {Object[]} bj 관련 스트리머 데이터
 * @param {Object[]} stations 공식 채널 데이터
 * @param {Object[]} events 이벤트 데이터
 * @param {Object[]} series 시리즈 데이터
 * @param {Object[]} esportList 경기 일정 데이터
 * @param {String} sessionKey 로그에 필요한 세션키
 * @param {Function} fetchGameProfileThemeStreamerList 추가로 관련 스트리머 데이터 가져오기 위한 메소드
 * @param {Function} fetchGameScheduleList 경기 일정 데이터 가져오기 위한 메소드
 * @returns GameProfileContents 컴포넌트
 */
const GameProfileContents = ({
  bj,
  stations,
  events,
  series,
  esportList,
  sessionKey,
  fetchGameProfileThemeStreamerList,
  fetchGameScheduleList,
}) => {
  const {sendSckactGameProfileClickLog} = useSearchClickLog();

  // 게임프로필 영역에 생성될 Tab들
  const tabs = getTabs(bj, esportList, stations, events, series);

  // 현재 활성화 되어있는 Tab
  const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  // SCKACT 로그 관련
  const handleLogCallback = (params, index, userId) => {
    const {type, tabId, contentsType, contentsValue, bno, vno, bjId, fileType} =
      params || {};
    const currentTab = getSectionName(activeTab);

    const isRemoveField = ['nick', 'more', 'hash'].includes(type);

    const param = {
      actCode: type,
      bjid: bjId ? bjId : userId,
      listIdx: type === 'tab' ? '' : index,
      sectionName: type === 'tab' ? getSectionName(tabId) : currentTab,
      contentsType: isRemoveField ? '' : contentsType,
      contentsValue: isRemoveField ? '' : contentsValue,
      sessionKey: sessionKey,
      bno: isRemoveField ? '' : bno ? bno : '',
      vno: isRemoveField ? '' : vno ? vno : '',
      fileType: fileType,
    };

    sendSckactGameProfileClickLog(param);
  };

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    handleLogCallback({type: 'tab', tabId: tabId}, '');
  };

  const renderContent = () => {
    switch (activeTab) {
      // 관련 스트리머
      case 'streamers':
        return (
          <ErrorBoundary>
            <RelatedStreamers
              streamer={bj}
              fetchGameProfileThemeStreamerList={
                fetchGameProfileThemeStreamerList
              }
              logCallback={(params, index, userId) => {
                handleLogCallback(params, index, userId);

                if (params.contentsType === 'live') {
                  sendLegacyInflowPathLog({
                    broadcastId: params.bno,
                    streamerId: userId,
                    isLogSendData: {
                      path1: 'sch',
                      path2: 'total',
                      path3: 'ctsp_bj',
                      path4: 'sti',
                      cli_list_data_idx: index + 1,
                    },
                  });
                }
              }}
            />
          </ErrorBoundary>
        );
      // 경기 일정
      case 'schedule':
        return (
          <ErrorBoundary>
            <GameSchedule
              fetchGameScheduleList={fetchGameScheduleList}
              logCallback={handleLogCallback}
            />
          </ErrorBoundary>
        );
      // 공식 채널
      case 'official':
        return (
          <ErrorBoundary>
            <OfficialChannels
              channelList={stations}
              logCallback={(params, index, userId) => {
                handleLogCallback(params, index, userId);

                if (params.contentsType === 'live') {
                  sendLegacyInflowPathLog({
                    broadcastId: params.bno,
                    streamerId: userId,
                    isLogSendData: {
                      path1: 'sch',
                      path2: 'total',
                      path3: 'ctsp_ch',
                      path4: 'sti',
                      cli_list_data_idx: index + 1,
                    },
                  });
                }
              }}
            />
          </ErrorBoundary>
        );
      // 이벤트
      case 'events':
        return (
          <ErrorBoundary>
            <GameEvent events={events} logCallback={handleLogCallback} />
          </ErrorBoundary>
        );
      // 시리즈게임
      case 'seriesGames':
        return (
          <ErrorBoundary>
            <SeriesGame series={series} logCallback={handleLogCallback} />
          </ErrorBoundary>
        );
      default:
        return null;
    }
  };

  return (
    <>
      {/* 관련 스트리머, 경기 일정, 공식 채널, 이벤트, 시리즈게임 Tab 영역*/}
      {tabs.length > 0 && (
        <>
          <div className='profile_tab_area'>
            <SoopUiSwiper
              swiperTag='ul'
              swiperClass='profile_tab_list'
              hasControls={false}
              options={{
                slidesPerView: 'auto',
                spaceBetween: 0,
                loop: false,
              }}>
              {tabs.map((tab) => (
                <li key={tab.id} className={activeTab === tab.id ? 'on' : ''}>
                  <button type='button' onClick={() => handleTabClick(tab.id)}>
                    <span>{tab.label}</span>
                  </button>
                </li>
              ))}
            </SoopUiSwiper>
          </div>
          {/* 관련 스트리머, 경기 일정, 공식 채널, 이벤트, 시리즈게임 Tab 중 사용자가 클릭한 Tab 관련 콘텐츠가 노출되는 영역 */}
          <div className='tab_content_wrap'>
            <div className='tab_content'>
              {/* Tab 관련 콘텐츠 */}
              {renderContent()}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GameProfileContents;
