import MyPlusApi from '@/api/main/my-plus';
import throwToast from '@/utils/ui/toast';
import {forwardRef, useState} from 'react';

const NewPlayList = forwardRef(function NewPlayList({data, handleClose}, ref) {
  const [createPlayList, setCreatePlayList] = useState({
    title: '',
    display: 'Y',
    share_yn: 'Y',
  });
  const [titleLength, setTitleLength] = useState(0);

  const handleOnchangeTitle = (e) => {
    const text = e.target.value;
    if (text.length > 30) {
      return;
    }
    setCreatePlayList({
      ...createPlayList,
      title: text,
    });
    setTitleLength(text.length);
  };

  const handleOnChangeToggle = (e, type) => {
    const toggle = e.target.checked;
    console.log(toggle);
    if (type === 'display') {
      setCreatePlayList({
        ...createPlayList,
        display: toggle ? 'Y' : 'N',
      });
    } else if (type === 'share') {
      setCreatePlayList({
        ...createPlayList,
        share_yn: toggle ? 'Y' : 'N',
      });
    }
  };

  const handleOnClickAdd = async () => {
    await setInsertPlayList(createPlayList, handleClose, data);
  };

  return (
    <>
      <div className='list_form'>
        <div className='title'>
          <input
            type='text'
            name='title'
            placeholder='제목을 입력해주세요.'
            autoComplete='off'
            onChange={handleOnchangeTitle}
            value={createPlayList.title}
          />
          <span className='bytes'>
            <em>{titleLength}/</em>30
          </span>
          <button
            type='button'
            className='delete'
            style={{display: createPlayList.title.length ? '' : 'none'}}
            onClick={() => {
              setCreatePlayList({
                ...createPlayList,
                title: '',
              });
              setTitleLength(0);
            }}>
            삭제
          </button>
        </div>
        <div className='select_area'>
          <ul>
            <li>
              <span>목록 공개</span>
              <label htmlFor='toggle1' className='switch'>
                <input
                  type='checkbox'
                  id='toggle1'
                  onChange={(e) => handleOnChangeToggle(e, 'display')}
                  defaultChecked={true}
                />
                <em />
              </label>
            </li>
            <li>
              <span>목록 퍼가기 허용</span>
              <label htmlFor='toggle2' className='switch'>
                <input
                  type='checkbox'
                  id='toggle2'
                  onChange={(e) => handleOnChangeToggle(e, 'share')}
                  defaultChecked={true}
                />
                <em />
              </label>
            </li>
          </ul>
          <button type='button' className='submit' onClick={handleOnClickAdd}>
            추가
          </button>
        </div>
      </div>
    </>
  );
});
export default NewPlayList;

const setInsertPlayList = async (createPlayList, handleClose, data) => {
  try {
    const {title, display, share_yn} = createPlayList;
    const params = {
      szWork: 'ins_playlist_list',
      title: title,
      display: display,
      share_yn: share_yn,
    };
    const response = await MyPlusApi.setCreatePlayList(params);
    if (response) {
      if (response.result !== 1) {
        throwToast(response.msg);
        return;
      }
      throwToast(response.msg);
      //재생목록 리스트 호출 콜백 함수
      data.fetchData();
      handleClose();
    }
  } catch (error) {
    console.error(error);
  }
};
