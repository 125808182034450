'use client';
import {useNavigate} from 'react-router-dom';

import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';

import {convertViewCnt} from '@/utils/main';

/**
 * GameProfileHeader 컴포넌트 ( 게임 프로필 정보 영역 )
 * @param {string} pcImage 게임 프로필 이미지
 * @param {string} title 게임 프로필 타이틀
 * @param {string} viewer 게임에 참여중인 인원
 * @param {string[]} fixedTags 장르태그
 * @returns GameProfileHeader 컴포넌트
 */
const GameProfileHeader = ({
  pcImage = '',
  title = '',
  viewer = null,
  fixedTags = [],
  categoryTags = [],
  sessionKey = '',
}) => {
  const navigate = useNavigate();
  const {sendSckactGameProfileClickLog} = useSearchClickLog();

  const getCategoryTag =
    categoryTags && categoryTags.length > 0 ? categoryTags[0] : title;

  // SCKACT 로그
  const handleLogCallback = (type) => {
    sendSckactGameProfileClickLog({
      actCode: type,
      sessionKey: sessionKey,
    });
  };

  const handleClick = (tagName, type) => {
    handleLogCallback(type);
    if (type === 'hash') {
      // sck2 로그 관련 태그 검색 페이지 뒤로가기 처리
      // 목적: 태그 검색 페이지에서 뒤로가기 시 sck2로그에 올바른 히스토리 상태 유지
      // 1. 현재 페이지가 '/search'로 시작하는 경우에만 실행
      if (location.pathname.startsWith('/search')) {
        // 2. 현재 URL의 파라미터 추출
        const currentParams = new URLSearchParams(location.search);

        // 3. URL 파라미터 수정
        // - 'location' 파라미터가 있으면 값을 'hash'로 설정
        // - 없으면 'szLocation' 파라미터를 'hash'로 설정
        if (currentParams.has('location')) {
          currentParams.set('location', 'hash');
        } else {
          currentParams.set('szLocation', 'hash');
        }

        // 4. 수정된 URL을 history에 push
        // replace: true 옵션으로 현재 history 항목을 대체
        const desiredPath = `${location.pathname}?${currentParams.toString()}`;
        navigate(desiredPath, {replace: true});
      }
      const query = {
        hash: 'hashtag',
        tagname: tagName,
        hashtype: 'live',
        stype: 'hash',
        acttype: 'ctsp',
        location: 'total_search',
      };
      const queryString = new URLSearchParams(query).toString();
      navigate(`/search?${queryString}`);
    } else {
      navigate(`/directory/category/${tagName}/live`);
    }
  };

  return (
    <div className='list_detail'>
      {/* 게임 이미지 */}
      <a
        className='thumb'
        style={{cursor: 'pointer'}}
        onClick={() => handleClick(getCategoryTag, 'sn')}>
        <img src={pcImage} alt='' />
      </a>
      {/* 게임 정보 */}
      <div className='detail_box'>
        <a
          style={{cursor: 'pointer'}}
          className='tit'
          onClick={() => handleClick(getCategoryTag, 'name')}>
          {title}
        </a>
        <span className='info'>
          <em>{convertViewCnt(viewer)}</em>명 참여중
        </span>
        {/* 장르 태그 */}
        <div className='tag_wrap'>
          {fixedTags &&
            fixedTags.length > 0 &&
            fixedTags.map((tagName, idx) => {
              return (
                <a
                  key={`${tagName}_${idx}`}
                  style={{cursor: 'pointer'}}
                  onClick={() => handleClick(tagName, 'hash')}>
                  {tagName}
                </a>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default GameProfileHeader
