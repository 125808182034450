import {createContext, useContext, useState} from 'react';
import {useLocation} from 'react-router-dom';

import {ROUTE} from '@/constants/main/route';
import {VOD_TYPES} from '@/constants';
import globalDomains from '@/utils/global-domains';

const BroadcastContext = createContext({
  userId: '',
  userNickname: '',
  broadcastType: 'vod',
  broadcastId: '',
  updatedDate: '',
  allowsAdult: '',
  hasBadge: '',
  vodType: '',
  duration: '',
  originalUserId: '',
  originalUserNickname: '',
  thumbnailUrl: '',
  previewThumbnailUrl: '',
  profileImageUrl: '',
  contextMenu: {
    appScheme: '',
    shareUrl: '',
    stationNo: '',
    menuList: [],
  },
  chipCategoryOptions: {},
  createdDate: '',
  viewCount: '',
  isTitleHover: false,
  authNumber: 0,
  categoryNo: '',
  accessibleAge: 0,
  hasPassword: false,
  isPpv: false,
  hasDrops: false,
  isCinety: true,
  isSubscribe: false,
  isFan: false,
  isDisplay: '',
  title: '',
  autoHashTags: [],
  categoryName: '',
  titleHistory: [],
  vodUploaderId: '',
  searchActionType: '',
  vodOriginalId: '',
  vodUploaderId: '',
  isUserClip: false,
  canResend: false,
  catchStoryIdList: [],
  broadBps: '',
  broadResolution: '',
  log: {listDataType: null, data: {}, groupType: null, groupName: null},
  blockStreamer: {hiddenStreamerList: [], nonRecommendableStreamerList: []},
  handleMenuClicked: () => {},
  handleUndoBlockedUser: () => {},
  handleAfterLogSend: () => {},
  setIsTitleHover: () => {},
  broadcastLink: '',
});

export const useBroadcastContext = () => {
  const context = useContext(BroadcastContext);
  if (!context) {
    throw new Error(
      'useBroadcastContext must be within a BroadcastInfo Component.',
    );
  }
  return context;
};

const BroadcastProvider = ({broadInfo, children}) => {
  const {
    userId,
    broadcastId,
    updatedDate = '',
    vodType,
    broadcastType,
    handleAfterLogSend,
    broadcastLink,
  } = broadInfo;

  const {VOD_AFREECATV, PLAY_AFREECATV} = globalDomains;
  const {CATCH, CATCH_STORY} = VOD_TYPES;
  const [isTitleHover, setIsTitleHover] = useState(false);
  const location = useLocation();

  if (!broadcastType) {
    throw new Error('broadcastType is required.');
  }

  const getBroadcastLink = (broadcastType) => {
    if (broadcastType === 'vod') {
      if (!broadInfo.vodType) {
        throw new Error('vodType is required.');
      }
      if (vodType === 'PLAYLIST') {
        if (!broadInfo.playlistId) {
          throw new Error('playlistId is required.');
        }
        return (
          broadInfo.playlistLink ||
          `${VOD_AFREECATV}/player/${broadcastId}/playlist/${broadInfo.playlistId}`
        );
      }
      return `${VOD_AFREECATV}/player/${broadcastId}${updatedDate && '/later#REG_DATE/1'}${broadInfo.vodType === CATCH ? '/catch' : ''}${broadInfo.vodType === CATCH_STORY ? `/catchstory?szLocation=search&aStoryListIdx=${broadInfo.catchStoryIdList?.join('-')}` : ''}`;
    }

    if (broadcastType === 'live') {
      if (!broadInfo.liveType) {
        throw new Error('liveType is required.');
      }
      return `${PLAY_AFREECATV}/${userId}/${broadcastId}${updatedDate && '/later#REG_DATE/1'}`;
    }
  };

  const shouldShowLaterButton = (type, vodType, location) => {
    if (type === 'vod') {
      return (
        vodType !== VOD_TYPES.CATCH &&
        vodType !== VOD_TYPES.CATCH_STORY &&
        location.pathname !== ROUTE.MY.HISTORY.LATER
      );
    } else {
      return location.pathname !== ROUTE.MY.HISTORY.LATER;
    }
  };

  const handleSectionClick = (type) => {
    let param = type ? {type: type} : '';

    handleAfterLogSend?.(param);
  };

  return (
    <BroadcastContext.Provider
      value={{
        isShowChips: true,
        ...broadInfo,
        isTitleHover,
        setIsTitleHover,
        broadcastLink:
          broadcastLink || getBroadcastLink(broadInfo.broadcastType),
        hasShowLaterButton: shouldShowLaterButton(
          broadcastType,
          vodType,
          location,
        ),
        handleSectionClick,
      }}>
      {children}
    </BroadcastContext.Provider>
  );
};

export default BroadcastProvider;
