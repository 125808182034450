import MyCatch from '@/components/my/common/item/MyCatch';
import {SoopUiSwiper} from '@/components/ui';
import classnames from 'classnames';
import {useEffect, useState} from 'react';
import '/public/static/css/module/catch_list.scss';
import {ErrorBoundary} from '@/components/common';

/**
 * CatchGroup 컴포넌트
 * @param {Object} props - CatchGroup props
 * @param {boolean} props.isStory - 캐치 스토리 여부
 * @param {Array} props.catches[] - 캐치 목록 (스토리도 동일하게 보냄)
 * @param {Array} props.catchContextMenuList - 캐치 컨텍스트 메뉴 리스트
 * @param {function} props.handleMenuClicked - 캐치 컨텍스트 메뉴 클릭 핸들러
 */
const CatchGroup = ({
  isStory,
  catches,
  catchContextMenuList,
  handleMenuClicked,
}) => {
  const getListItemClass = (flag) =>
    classnames({
      play_on: isStory && flag,
      play_end: isStory && !flag,
    });

  const formattedCatches = catches.map((catchData) => {
    const {copyright, resolutionType, thumb, verticalThumb, ...rest} =
      catchData;

    return {
      ...rest,
      thumbnailSrc: resolutionType === 'horizontal' ? thumb : verticalThumb,
      ...(isStory ? {} : {userId: copyright?.userId}),
    };
  });

  const catchStoryIdList = formattedCatches
    .filter((item) => item.fileType === 'CATCH_STORY')
    .map((item) => item.catchId);

  return (
    <SoopUiSwiper
      swiperTag='ul'
      swiperClass={`catch_list ${isStory ? 'catch_story_list' : ''}`}
      maxSlidesPerView={10}
      isSameSlidesViewGroup
      options={{
        loop: false,
        spaceBetween: 20,
      }}>
      {formattedCatches.map((catchData, idx) => (
        <li
          key={`${catchData.catchId || idx}-${isStory ? 'story' : 'catch'}`}
          className={getListItemClass(catchData.flag)}>
          <ErrorBoundary>
            <MyCatch
              {...catchData}
              isStory={isStory}
              catchStoryIdList={catchStoryIdList}
              catchContextMenuList={catchContextMenuList}
              handleMenuClicked={handleMenuClicked}
            />
          </ErrorBoundary>
        </li>
      ))}
    </SoopUiSwiper>
  );
};

export default CatchGroup;
