import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';

export const TABS_SEARCH = [
  {
    key: 'total',
    name: '통합검색',
    query: {szSearchType: 'total'},
  },
  {
    key: 'broad',
    name: 'LIVE',
    query: {szSearchType: 'broad'},
  },
  {
    key: 'vod',
    name: 'VOD',
    query: {szSearchType: 'vod'},
  },
  {
    key: 'post',
    name: '게시글',
    query: {szSearchType: 'post'},
  },
  {
    key: 'streamer',
    name: '스트리머',
    query: {szSearchType: 'streamer'},
  },
];

export const TABS_TAG = [
  {
    key: 'tag_live',
    name: 'LIVE',
    query: {hashtype: 'live', hash: 'hashtag'},
  },
  {
    key: 'tag_vod',
    name: 'VOD',
    query: {hashtype: 'vod', hash: 'hashtag'},
  },
];

export const getClassName = (hash, hashtype = '', searchType) => {
  let className = '';
  className = searchType === 'total' ? '' : `_search_${searchType}`;

  if (hash) {
    className = `_search_${hashtype}`;
  }
  return className;
};

export const setTagRouteUrl = (
  searchType,
  keyword,
  sType,
  acttype = '',
  location = '',
  szActype = '',
  has_hint = '',
  pk_cnt = '',
) => {
  if (['total', 'broad', 'vod', 'post', 'streamer'].includes(searchType)) {
    return TABS_SEARCH.map((item) => {
      if (item.key === 'tag_live') {
        return {
          ...item,
          query: {
            hashtype: 'live',
            hash: 'hashtag',
            tagname: keyword,
            stype: 'hash',
            location: location,
          },
        };
      }
      return {
        ...item,
        query: {
          szSearchType: item.query.szSearchType,
          szKeyword: keyword,
          szStype: sType,
          szActype: szActype,
          rs: 1,
          szVideoFileType: 'ALL',
          has_hint: has_hint,
          pk_cnt: pk_cnt,
          location: location,
        },
      };
    });
  } else {
    return TABS_TAG.map((item) => {
      return {
        ...item,
        query: {
          hashtype: item.query.hashtype,
          hash: 'hashtag',
          tagname: keyword,
          stype: 'hash',
          acttype: acttype,
          location: location,
        },
      };
    });
  }
};
