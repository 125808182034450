import MyFavoriteApi from '@/api/my/favorite';
import {SectionHeader} from '@/components/main/common';
import {useEffect} from 'react';
import '/public/static/css/module/cBox.scss';
import useMyStore from '@/stores/my';
import MyBroadcastLayout from '../common/MyBroadcastLayout';
import {ErrorBoundary} from '@/components/common';
import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';
import {BROADCAST_TYPES} from '@/constants';
import {RECOMMEND_CONTEXT_MENU} from '@/constants/my/contextMenu';
import throwToast from '@/utils/ui/toast';

/**
 * 즐겨찾기 페이지 라이브 방송 목록 컴포넌트
 */
const RecommendStreamer = () => {
  const {setRecommendBroadcast, recommendBroadcast} = useMyStore();

  useEffect(() => {
    const getRecommendStreamers = async () => {
      try {
        const recommendStreamers = await MyFavoriteApi.getRecommendStreamers();

        setRecommendBroadcast(recommendStreamers);
      } catch (error) {
        return [];
      }
    };

    getRecommendStreamers();
  }, []);

  const handleContextMenuClick =
    (broadcastUserId, broadcastId, broadcastLogic) => async (event, type) => {
      const recommendedStreamerIds = recommendBroadcast.map(
        (broadcast) => broadcast.userId,
      );

      try {
        const {filterId} = await MyFavoriteApi.rejectRecommend({
          streamerId: broadcastUserId,
          broadcastId: broadcastId,
          logic: broadcastLogic,
          recommendedStreamerIds,
        });
        throwToast('해당 방송을 더 이상 추천하지 않습니다.');
        const filteredBroadcasts = recommendBroadcast.filter(
          (broadcast) => broadcast.userId !== filterId,
        );
        setRecommendBroadcast(filteredBroadcasts);
      } catch (error) {
        throwToast('에러가 발생하였습니다.');
      }
    };

  return (
    recommendBroadcast?.length > 0 && (
      <>
        <SectionHeader title='추천 스트리머' />
        <MyBroadcastLayout type='swiper'>
          {recommendBroadcast?.map((broadcast) => (
            <ErrorBoundary key={broadcast.titleNo}>
              <BroadcastLayout
                broadcastType={BROADCAST_TYPES.LIVE}
                thumbnailUrl={broadcast.broadImg}
                viewCount={broadcast.totalViewCnt}
                startDateTime={broadcast.broadStart}
                authNumber={broadcast.authNo}
                userNickname={broadcast.userNick}
                title={broadcast.broadTitle}
                broadcastId={broadcast.broadNo}
                userId={broadcast.userId}
                hasBadge={
                  broadcast.categoryTags ||
                  broadcast.hashTags ||
                  broadcast.autoHashtags
                }
                categoryTags={broadcast.categoryTags}
                hashTags={broadcast.hashTags}
                autoHashTags={broadcast.autoHashtags}
                accessibleAge={Number(broadcast.grade)}
                allowsAdult={broadcast.isAdult}
                isFan={broadcast.isFanclub}
                isSubscribe={broadcast.isSubscribe}
                contextMenu={{
                  menuList: RECOMMEND_CONTEXT_MENU,
                }}
                liveType={broadcast.broadType}
                handleMenuClicked={handleContextMenuClick(
                  broadcast.userId,
                  broadcast.broadNo,
                  broadcast.logic,
                )}>
                <BroadcastType.Vod />
              </BroadcastLayout>
            </ErrorBoundary>
          ))}
        </MyBroadcastLayout>
      </>
    )
  );
};

export default RecommendStreamer;
