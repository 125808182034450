import {useEffect, useRef} from 'react';

export const usePrevious = (value) => {
  const prevValueRef = useRef(value);
  useEffect(() => {
    prevValueRef.current = value;
  }, [value]);

  return {prev: prevValueRef.current, current: value};
};
