import React, {useEffect, useState} from 'react';
import useGlobalUIStore from '@/stores';
import {useLocation, useNavigate} from 'react-router-dom';
import {convertToArray} from '@/utils';
import {getSearchInflowTab, getUrlLocation} from '@/utils/main/search';

import {ROUTE} from '@/constants/main/route';

/**
 * 전역에서 사용하고 있는 tag chip
 *
 * @desc 태그 노출 순서는 1. autoHashtags 2. categoryTags  3. hashTags
 *
 * @param {string} location lnb 메뉴 접근 경로 (sck2(stopword)에 쏘는 location 로그 변수 MY+, HOT, ALL : main, VOD : vod_main, MY : my_main)
 * @param {string} hashType 현재 태그 타입 LIVE, VOD - 방송 태그 or vod 태그
 * @param {string[]} autoHashtags - 자동 해시태그 목록 (이벤트성 태그가 있을 경우 이쪽으로 내려 주는 걸로 협의)
 * @param {string[]} categoryTags - 카테고리 태그 목록 (방송 설정할 때)
 * @param {string[]} hashTags - 해시태그 목록 (유저가 설정한 태그)
 * @param {string} searchArea 페이지 내 어떤 영역에서 검색했는지 ( SCK2 로그 관련 )
 * @returns {string[]} tag-wrap 하위의 아이템
 * */
export const generateStyle = (tag, data, theme, type) => {
  try {
    if (tag && data) {
      const element = data?.filter((val) => tag === val.tagName);

      if (element.length > 0) {
        const {event, style} = element[0][theme].pc || {};
        if (type === 'style') {
          return style;
        } else {
          return event[type];
        }
      }
    }
    return undefined;
  } catch (error) {
    return undefined;
  }
};

const ChipCategory = React.memo(function ChipCategory({
  categoryName,
  hashType,
  autoHashTags: eventTags = [],
  categoryTags = [],
  hashTags = [],
  searchArea = 'total',
  onClickLog,
}) {
  const location = useLocation(); // props로 전달 받고 있어서 새로 선언
  const navigate = useNavigate();
  const {autoTagStyle} = useGlobalUIStore();
  const [theme, setTheme] = useState(
    document.documentElement.getAttribute('dark') ? 'dark' : 'light',
  );
  const handleMoveTag = (event, tagName, hashtype, type = '') => {
    event.preventDefault();
    const urlLocation = getUrlLocation(location.pathname);
    const _hashType = hashtype.toLowerCase();
    const inflowTab = getSearchInflowTab(location.pathname);

    const query = {
      hash: 'hashtag',
      tagname: tagName,
      hashtype: _hashType,
      stype: 'hash',
      acttype: searchArea,
      location: urlLocation,
      inflow_tab: inflowTab,
    };

    let path = '';
    if (type === 'category') {
      const encodeCategoryName = encodeURIComponent(categoryName || tagName);
      //탐색 상세페이지로 이동(방송 태그에 한에서만)
      path = `/directory/category/${encodeCategoryName}/${hashType.toLowerCase()}`;
    } else {
      const queryString = new URLSearchParams(query).toString();
      path = `/search?${queryString}`;
    }

    // sck2 로그 관련 태그 검색 페이지 뒤로가기 처리
    // 목적: 태그 검색 페이지에서 뒤로가기 시 sck2로그에 올바른 히스토리 상태 유지
    // 1. 현재 페이지가 '/search'로 시작하는 경우에만 실행
    if (location.pathname.startsWith('/search') && type !== 'category') {
      // 2. 현재 URL의 파라미터 추출
      const currentParams = new URLSearchParams(location.search);

      // 3. URL 파라미터 수정
      // - 'location' 파라미터가 있으면 값을 'hash'로 설정
      // - 없으면 'szLocation' 파라미터를 'hash'로 설정
      if (currentParams.has('location')) {
        currentParams.set('location', 'hash');
      } else {
        currentParams.set('szLocation', 'hash');
      }

      // 4. 수정된 URL을 history에 push
      // replace: true 옵션으로 현재 history 항목을 대체
      const desiredPath = `${location.pathname}?${currentParams.toString()}`;
      navigate(desiredPath, {replace: true});
    }

    onClickLog && onClickLog();

    // 실제 페이지 이동을 수행합니다.
    navigate(path);
  };

  const setAutoTagStyleObserver = () => {
    try {
      const $container = document.querySelector('html');
      const handleDarkModeChange = () => {
        const newMode = document.documentElement.getAttribute('dark');
        setTheme(newMode ? 'dark' : 'light');
      };

      const observer = new MutationObserver(handleDarkModeChange);
      observer.observe($container, {
        attributes: true,
        attributeFilter: ['dark'],
      });

      return () => {
        observer.disconnect();
      };
    } catch (error) {}
  };

  useEffect(() => {
    setAutoTagStyleObserver();
  }, []);

  return (
    <div data-testid='chips' className='tag_wrap'>
      {/* 이벤트 태그 */}
      {convertToArray(eventTags).map(
        (name, idx) =>
          name &&
          name.length > 0 && (
            <a
              key={`event_tag_${idx}`}
              href='#'
              onClick={(event) => {
                handleMoveTag(event, name, hashType);
              }}
              style={generateStyle(name, autoTagStyle, theme, 'style')}
              onMouseEnter={(e) => {
                const styleData = generateStyle(
                  name,
                  autoTagStyle,
                  theme,
                  'hover',
                );
                if (styleData) {
                  const {background, color} = styleData;
                  e.currentTarget.style.background = background;
                  e.currentTarget.style.color = color;
                }
              }}
              onMouseLeave={(e) => {
                const styleData = generateStyle(
                  name,
                  autoTagStyle,
                  theme,
                  'style',
                );
                if (styleData) {
                  const {background, color} = styleData;
                  e.currentTarget.style.background = background;
                  e.currentTarget.style.color = color;
                }
              }}>
              {name}
            </a>
          ),
      )}
      {/* 카테고리 태그 */}
      {convertToArray(categoryTags).map(
        (name, idx) =>
          name &&
          name.length > 0 && (
            <a
              key={`category_tag_${idx}`}
              className='category'
              href='#'
              onClick={(event) => {
                handleMoveTag(event, name, hashType, 'category');
              }}>
              {name}
            </a>
          ),
      )}
      {/* 유저 태그 */}
      {convertToArray(hashTags).map(
        (name, idx) =>
          name &&
          name.length > 0 && (
            <a
              key={`user_hash_tag_${idx}`}
              href='#'
              onClick={(event) => {
                handleMoveTag(event, name, hashType);
              }}>
              {name}
            </a>
          ),
      )}
    </div>
  );
});

export default ChipCategory;
