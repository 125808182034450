import {create} from 'zustand';
import _ from 'lodash';
import {devtools} from 'zustand/middleware';

const store = (set) => {
  return {
    toastList: [],
    addToast: (id, message, className, children, timeout) => {
      return set((prevState) => {
        return {
          toastList: [
            ...prevState.toastList,
            {id, className, message, children, timeout},
          ],
        };
      });
    },
    popToast: (id) => {
      return set((prevState) => {
        return {
          toastList: prevState.toastList.filter((toast) => toast.id !== id),
        };
      });
    },
  };
};
const useToastStore = create(devtools(store));

export default useToastStore;
