import globalDomains from '@/utils/global-domains';

const HiddenStreamer = ({type, userNickname, handleUndoBroadcast}) => {
  return (
    <div className='hidden_strm_wrap'>
      {type === 'hidden' ? (
        <>
          <p className='desc'>
            <em>{userNickname}</em>님의 콘텐츠를
            <br /> 현재 목록에서 제외합니다.
          </p>
          <a
            href={`${globalDomains.MEMBER_AFREECATV}/app/bj_hide_info.php`}
            target='_blank'
            className='adm'>
            숨긴 스트리머 관리
          </a>
        </>
      ) : (
        <>
          <p className='desc'>
            홈에서 <em>{userNickname}</em>님의 콘텐츠를
            <br />
            90일간 추천하지 않습니다.
          </p>
          <p className='tip'>(해당 스트리머 재시청시 노출 가능)</p>
        </>
      )}
      <button type='button' onClick={() => handleUndoBroadcast()}>
        실행 취소
      </button>
    </div>
  );
};
export default HiddenStreamer;
