import {
  MySubscribeStreamerTitle,
  MySubscribeStreamers,
  MySubscribeVod,
} from '@/components/my/subscribe';
import {LiveTitle, Live} from '@/components/my/favorite';
import {SectionHeader} from '@/components/main/common';
import useMyStore from '@/stores/my';
import {useEffect} from 'react';
import MyNoList from '@/components/my/common/item/NoList';
import globalDomains from '@/utils/global-domains';
import {Banner} from '@/components/common';

const MySubscribe = ({params}) => {
  const {subscribeStreamers, getSubscribeStreamers} = useMyStore();

  useEffect(() => {
    getSubscribeStreamers();
  }, []);

  return subscribeStreamers?.length > 0 ? (
    <>
      {/* 스트리머 영역 */}
      <MySubscribeStreamerTitle />
      <MySubscribeStreamers />

      {/* 서브 배너 영역 */}
      <Banner type='sub' category={'subscribe'} />

      {/* LIVE 영역 */}
      <LiveTitle />
      <Live />

      {/* VOD 영역 */}
      <SectionHeader title='VOD' />
      <MySubscribeVod />
    </>
  ) : (
    <>
      {/* 서브 배너 영역 */}
      <Banner type='sub' category={'subscribe'} />
      <MyNoList
        title='구독한 스트리머가 없습니다.'
        description={
          <>
            <span>
              내가 좋아하는 스트리머를 구독하고 구독팬만을 위한 특별한 혜택을
              만나보세요!
            </span>
            <button
              type='button'
              className='btn_default'
              onClick={() =>
                window.open(`${globalDomains.ITEM_AFREECATV}/subscription.php`)
              }>
              구독혜택 보기
            </button>
          </>
        }
      />
    </>
  );
};

export default MySubscribe;
