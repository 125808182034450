import BannerApi from '@/api/common/banner';
import {
  BANNER_REQUEST_TYPE,
  BANNER_RESPONSE_TYPE,
} from '@/constants/main/banner';
import useMainStore from '@/stores/main';
import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const formattedMainBanner = (banner) => {
  if (!banner || !banner.length === 0) {
    return [];
  }

  return banner.map((item) => {
    const {advertiser, adText, imageUrl, ...rest} = item;
    return {
      isBanner: true,
      thumbnailSrc: imageUrl, //썸네일
      title: adText, //광고제목
      userNickname: advertiser, //광고주
      ...rest,
    };
  });
};

export const useBanner = ({type, category}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const {
    banner,
    checkAd,
    setBanner,
    setIsRenderMainBanner,
    setIsShowMainBanner,
    setIsLoadingPlayerArea,
  } = useMainStore();
  const location = useLocation();

  const handleClickClose = (closeUrl) => {
    //배너 닫힘 트래킹
    if (isOpen) {
      if (checkAd.AdParameter.hasOwnProperty('sendTracking')) {
        checkAd.AdParameter.sendTracking(closeUrl);
      }
    }
    setIsOpen(!isOpen);
  };

  const handelErrorImage = (e, errorUrl, alterImageUrl = '') => {
    if (checkAd.AdParameter.hasOwnProperty('sendTracking')) {
      if (alterImageUrl) {
        e.target.src = alterImageUrl;
      }
      checkAd.AdParameter.sendTracking(errorUrl);
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  //배너 클릭 트래킹
  const handleClickBanner = (clickUrl) => {
    if (checkAd.AdParameter.hasOwnProperty('sendTracking')) {
      checkAd.AdParameter.sendTracking(clickUrl);
    }
  };

  //광고 배너 호출
  useEffect(() => {
    if (
      (type === 'main' &&
        !banner.main.exist &&
        checkAd.banners?.bAdPcMainBanner) ||
      (type === 'sub' &&
        location.key !== banner.sub.key &&
        checkAd.banners?.bAdPcCategoryBanner)
    ) {
      getBanner();
    }
  }, [checkAd]);

  useEffect(() => {
    if (category && category !== banner.sub.category) {
      setIsOpen(false);
      setHovered(false);
    }
  }, [category]);

  useEffect(() => {
    if (
      banner[type].exist &&
      checkAd.AdParameter.hasOwnProperty('sendTracking')
    ) {
      if (type === 'sub') {
        checkAd.AdParameter.sendTracking(
          banner[type].creatives[0]?.impressionUrls,
        );
      }
    }
  }, [banner[type].exist]);

  const getBanner = async () => {
    try {
      if (checkAd.AdParameter.hasOwnProperty('getAdParameters')) {
        const params = checkAd.AdParameter.getAdParameters(
          type === 'main'
            ? BANNER_REQUEST_TYPE.MAIN_BANNER
            : BANNER_REQUEST_TYPE.SUB_BANNER,
          {category: category},
        );
        const response = await BannerApi.getBanner(params);
        if (response) {
          if (type === 'main') {
            //정의된 type으로 내려오는 광고만 노출
            if (
              response.exist &&
              (response.type === BANNER_RESPONSE_TYPE.PC_MAIN_IMAGE ||
                response.type === BANNER_RESPONSE_TYPE.PC_MAIN_VIDEO)
            ) {
              setBanner({
                ...banner,
                main: {
                  type: response.type,
                  exist: response.exist,
                  creatives: [...formattedMainBanner(response.creatives)],
                },
              });
            } else {
              //메인 배너에서 정의되지 않은 type이 내려오거나 exist 가 없어서 광고노출에 실패했을때 비쥬얼영역은 노출
              setIsShowMainBanner(false);
              setIsRenderMainBanner(true);
            }
            //광고에 필요한 윈도우객체등을 soop-navigation 으로부터 받아와서 사용하는데 soop-live 랜더링 후, soop-navigation 순서다보니
            //메인 배너가 로딩될떄까지 순간적으로 스트리밍영역이 보여지는 현상이 있어서 api 응답 받고나서 노출되도록 수정..
            setIsLoadingPlayerArea(false);
          } else if (type === 'sub') {
            if (
              response.exist &&
              (response.type === BANNER_RESPONSE_TYPE.PC_728_90_BANNER ||
                response.type ===
                  BANNER_RESPONSE_TYPE.PC_SUB_BANNER_EXPANDED_IMAGE ||
                response.type ===
                  BANNER_RESPONSE_TYPE.PC_SUB_BANNER_EXPANDED_VIDEO)
            ) {
              setBanner({
                ...banner,
                sub: {
                  key: location.key,
                  type: response.type,
                  exist: response.exist,
                  category: category,
                  creatives: [...formattedMainBanner(response.creatives)],
                },
              });
            }
          }
        }
      }
    } catch (error) {
      if (type === 'main') {
        setIsShowMainBanner(false);
        setIsLoadingPlayerArea(false);
        setBanner({
          ...banner,
          main: {
            type: '',
            exist: false,
            creatives: [],
          },
        });
      }
      if (type === 'sub') {
        setHovered(false);
        setIsOpen(false);
        setBanner({
          ...banner,
          sub: {
            key: '',
            type: '',
            exist: false,
            category: '',
            creatives: [],
          },
        });
      }
    }
  };

  useEffect(() => {
    let timer;
    if (hovered && !isOpen) {
      timer = setTimeout(() => {
        clearTimeout(timer);
        setIsOpen(true);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [hovered, isOpen]);

  const state = {isOpen, banner};
  const handler = {
    handleClickClose,
    handleClickBanner,
    handelErrorImage,
    handleMouseEnter,
    handleMouseLeave,
  };
  return {...state, ...handler};
};
