import {useCallback, useEffect, useState} from 'react';

import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';
import {SoopUiSwiper} from '@/components/ui';
import {ErrorBoundary} from '@/components/common';
import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';
import {SectionHeader} from '@/components/main/common';

import {
  DIRECTORY_LIVE_CONTEXT_MENU as LIVE_CONTEXT_MENU,
  VOD_CONTEXT_MENU,
} from '@/constants/main';
import { decodeFullHtmlEntities } from '@/utils';

/**
 * LatestVideoGroup 컴포넌트 ( 최신영상 ) : '스트리머 프로필 최신영상'과 '테마 최신영상'에서 쓰임
 * @param {object} props 컴포넌트의 props
 * @param {object[]} latestVideoData 최신영상 관련 데이터
 * @param {object[]} title 최신영상 타이틀
 * @param {object[]} section 스트리머 프로필 영역인지 테마 영역인지 구분
 * @returns LatestVideoGroup 컴포넌트
 */
const LatestVideoGroup = ({latestVideoData = [], title = '', section = ''}) => {
  const {sessionKey} = useSearchGlobalStatus();
  const {sendSckactLiveClickLog, sendSckactVodClickLog} = useSearchClickLog();
  const {getParams} = useUrlParameter();
  const {szSearchType: searchType = ''} = getParams();

  const [aCatchListIdx, setACatchListIdx] = useState([]);

  useEffect(() => {
    if (latestVideoData && Array.isArray(latestVideoData)) {
      const newCatchStoryTitleNos = latestVideoData
        .filter((item) => item.fileType === 'CATCH_STORY')
        .map((item) => item.titleNo);

      setACatchListIdx((prevList) => [
        ...new Set([...prevList, ...newCatchStoryTitleNos]),
      ]);
    }
  }, [latestVideoData]);

  // SCKACT 로그
  const handleLogCallback = useCallback(
    (param, index, isVod, fileType, isHot, broadcastId, userId) => {
      const {type = 'sn'} = param || {};

      const isRemoveField = ['nick', 'hash'].includes(type);

      let checkContentType = isVod ? 'vod' : 'live';
      let checkContentValue = broadcastId;

      if (type === 'sti') {
        checkContentType = 'bj';
        checkContentValue = userId;
      }

      let params = {
        fileType: isVod ? fileType : '',
        isHot,
        bjid: userId,
        bno: !isVod ? broadcastId : '',
        vno: isVod ? broadcastId : '',
        contentsType: isRemoveField ? '' : checkContentType,
        contentsValue: isRemoveField ? '' : checkContentValue,
        actCode: type,
        acttype: section === 'streamer' ? 'bjp' : 'tsk',
        sectionName: 'latest_contents',
        listIdx: index,
        sessionKey: sessionKey,
        ...param,
      };

      if (isVod) {
        sendSckactVodClickLog(params, 'latestVod');
      } else {
        sendSckactLiveClickLog(params, 'latestVod');
      }
    },
    [sessionKey, searchType],
  );

  // inflow_path 로그
  const getLogSendData = (isVod) => {
    if (isVod) {
      return section === 'streamer'
        ? {path1: 'sch', path2: 'sch', path3: 'latest_contents'}
        : {path1: 'sch', path2: 'sch', path3: 'bj_new'};
    } else {
      return section === 'streamer'
        ? {path1: 'sch', path2: 'sch', path3: 'direct'}
        : {path1: 'sch', path2: 'total', path3: 'tsk'};
    }
  };

  const renderLatestVideoItem = (video, index) => {
    const isVod = !!video.fileType;
    const isAdult = !isVod
      ? Number(video.broadGrade) === 19 || Number(video.grade) === 19
      : Number(video.grade) === 19 && Number(video.category) === 30000;
    const isPassword = !isVod ? video.isPassword === '1' : false;
    const broadcastId = !isVod ? video.broadNo : video.titleNo;

    return (
      <li
        key={`search_latest_video_${video.broadNo || video.titleNo || video.userNick}_${index}`}
        data-type={'cBox'}
        data-slide-index={index}>
        <ErrorBoundary>
          <BroadcastLayout
            broadcastType={isVod ? 'vod' : 'live'}
            vodType={video.fileType}
            liveType={video.broadType}
            thumbnailUrl={isVod ? video.thumbnailPath : video.thumbnail}
            previewThumbnailUrl={isVod && video.webpPath}
            userId={video.userId}
            userNickname={video.userNick}
            originalUserId={isVod && video.originalBj}
            originalUserNickname={isVod && video.originalUserNick}
            viewCount={String(video.viewCnt)}
            startDateTime={video.broadStart}
            title={decodeFullHtmlEntities(video.title)}
            hasBadge={
              video.categoryTags || video.hashTags || video.autoHashtags
            }
            categoryTags={video.categoryTags}
            hashTags={video.hashTags}
            autoHashTags={video.autoHashtags}
            broadcastId={
              video.fileType === 'CATCH_STORY' ? video.listNo : broadcastId
            }
            duration={isVod && video.duration}
            createdDate={video.regDate}
            canResend={Number(video.visitBroadType) > 0}
            categoryNo={Number(video.broadCateNo) || Number(video.category)}
            categoryName={
              video?.categoryTags && video?.categoryTags.length > 0
                ? video?.categoryTags[0]
                : ''
            }
            catchStoryIdList={aCatchListIdx}
            accessibleAge={Number(video.grade)}
            searchActionType={section === 'streamer' ? 'bjp' : 'tsk'}
            allowsAdult={isAdult}
            hasPassword={isPassword}
            isPpv={video.ppv}
            isFan={!!video.fanFlag}
            isSubscribe={!!video.subsFlag}
            contextMenu={{
              menuList:
                video.fileType === 'CATCH' || video.fileType === 'CATCH_STORY'
                  ? [{label: '공유하기', type: 'share'}]
                  : video?.fileType
                    ? VOD_CONTEXT_MENU
                    : LIVE_CONTEXT_MENU,
            }}
            log={{
              data: {
                ...getLogSendData(isVod),
                cli_list_data_idx: index + 1,
              },
            }}
            handleAfterLogSend={(param) => {
              handleLogCallback(
                param,
                index,
                isVod,
                video.fileType,
                video.isHot,
                broadcastId,
                video.userId,
                video.vno,
              );
            }}>
            {isVod ? <BroadcastType.Vod /> : <BroadcastType.Live />}
          </BroadcastLayout>
        </ErrorBoundary>
      </li>
    );
  };

  if (!latestVideoData || latestVideoData.length < 1) {
    return null;
  }

  return (
    <>
      <SectionHeader title={title} />

      <div className='cBox_slide vodSlide'>
        <SoopUiSwiper
          swiperTag='ul'
          swiperClass='cBox_list'
          maxSlidesPerView={6}
          options={{
            loop: false,
          }}>
          {latestVideoData?.map((video, index) =>
            renderLatestVideoItem(video, index),
          )}
        </SoopUiSwiper>
      </div>
    </>
  );
};

export default LatestVideoGroup;


