import ApiMApi from '@/api/apim';
import LiveApi from '@/api/live';
import StaticFileApi from '@/api/staticfile';
import StbbsApi from '@/api/stbbs';
import MyApi from '@/api/my';
import {getTimeStamp} from '@/utils/main';

const MyPlusApi = {
  /**
   * 선호 방송 리스트 가져오기
   * @param {String} szRelationType - 선호타입 ABCD 이런식으로 api 에서 내려줬었던 값
   * @returns {Object}
   */
  async getPreferLiveVodList(queryParams) {
    try {
      const response = await LiveApi.get(
        '/api/myplus/preferbjLiveVodController.php',
        queryParams,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 정적 방송 리스트 가져오기
   * @returns {Object}
   */
  async getStaticBroadList() {
    try {
      const response = await StaticFileApi.get(
        `/pc/ko_KR/main_broad_list_json.js?${getTimeStamp()}`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 정적 방송 리스트 가져오기(성인방송까지)
   * @returns {Object}
   */
  async getStaticBroadListWithAdult() {
    try {
      const response = await StaticFileApi.get(
        `/pc/ko_KR/main_broad_list_with_adult_json.js?${getTimeStamp()}`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 정적 핫이슈,배너정보 등 가져오기
   * @returns {Object}
   */
  async getStaticBroadListMainInfo() {
    try {
      //원래는 StaticFileApi
      const response = await StaticFileApi.get(
        `/main/get_broadlist_main_info_ko_KR_json.js?${getTimeStamp()}`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 선호 카테고리 리스트 가져오는 api
   * @param {String} type - prefer/popular
   * @returns {Object}
   */
  async getPreferCategoryList(param) {
    try {
      const response = await LiveApi.get(
        '/api/myplus/preferCategoryPostController.php',
        param,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 선호 캐치 리스트 가져오는 api
   * @returns {Object}
   * */
  async getPreferCatchList() {
    try {
      const response = await LiveApi.get(
        '/api/myplus/myplusCatchController.php',
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 선호 캐치스토리 리스트 가져오는 api
   * @returns {Object}
   * */
  async getPreferCatchStoryList() {
    try {
      const response = await LiveApi.get(
        '/api/myplus/myplusCatchStoryController.php',
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 배너 알람 등록 api
   * @param {Number} alarm_idx - 배너 인덱스값
   * @param {string} platform - PC
   * @returns {Object}
   * */
  async setTopBannerAlarm(params) {
    try {
      const response = await ApiMApi.post('/push/alarm/a/register', params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 배너 알람 등록 여부 조회 api
   * @param {Number} alarm_idx - 배너 인덱스값
   * @returns {Object}
   * */
  async setCheckTopBannerAlarm(params) {
    try {
      const response = await ApiMApi.post('/push/alarm/a/status', params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 배너 알람 등록 여부 조회 api
   * @param {Number} alarm_idx - 배너 인덱스값
   * @returns {Object}
   * */
  async setDeleteTopBannerAlarm(params) {
    try {
      const response = await ApiMApi.post('/push/alarm/a/unregister', params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 영상 스트리밍 리스트 가져오는 api
   * @returns {Object}
   * */
  async getMainTopLiveList() {
    try {
      const response = await LiveApi.get('/api/get_main_top_live_list.php');
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 나중에 보기 추가하기
   * @param {String} type - LIVE/VOD
   * @param {Number} broadNo - 방송번호/타이틀번호
   * @returns {Object}
   */
  async setLaterView(type, broadNo) {
    try {
      const response = await MyApi.post(
        `/api/history/later/${type}/${broadNo}`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 추천받지 않기 추가
   * @param {String} bj_id - 스트리머 아이디
   * @param {String} bj_nick - 스트리머 닉네임
   * @param {String} type- LIVE/VOD/CATCH
   * @param {String} list_data_type - ex) pbj_live_score
   * @param {Number} broad_no - 방송번호
   * @param {Number} title_no - 영상번호
   * @param {String} os - PC
   * @param {String} namespace - ex) prflvod
   * @param {String} key - 캐시방지
   * @returns {Object}
   */
  async setNoRecommendStreamer(params) {
    try {
      const response = await ApiMApi.post('/broad/a/hidebj', params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 추천받지 않기 취소
   * @param {String} bj_id - 스트리머 아이디
   * @param {String} type- LIVE/VOD/CATCH
   * @param {String} list_data_type - ex) pbj_live_score
   * @param {Number} broad_no - 방송번호
   * @param {Number} title_no - 영상번호
   * @param {Number} score - 점수
   * @returns {Object}
   */
  async setNoRecommendUndoStreamer(params) {
    try {
      const response = await ApiMApi.post('/broad/a/hidebjdel', params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 스트리머 숨기기 추가
   * @param {String} bj_id - 스트리머 아이디
   * @param {String} bj_nick - 스트리머 닉네임
   * @param {String} type- LIVE/VOD/CATCH
   * @param {String} list_data_type - ex) pbj_live_score
   * @param {String} location - ex) myplus , all , bora, game, sports?
   * @param {String} broad_no - 방송번호
   * @param {String} title_no - 영상번호
   * @param {String} story_idx - 스토리번호
   * @param {String} os - PC
   * @param {String} namespace - ex) prflvod
   * @param {String} key - 캐시방지
   * @returns {Object}
   */
  async setHiddenStreamer(params) {
    try {
      const response = await ApiMApi.post('/broad/hiddenbj/a/add', params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 스트리머 숨기기 취소
   * @param {String} bj_id - 스트리머 아이디
   * @param {String} type- LIVE/VOD/CATCH
   * @param {String} list_data_type - ex) pbj_live_score
   * @param {String} location - ex) myplus , all , bora, game, sports?
   * @param {String} broad_no - 방송번호
   * @param {String} title_no - 영상번호
   * @param {String} story_idx - 스토리번호
   * @returns {Object}
   */
  async setHiddenUndoStreamer(params) {
    try {
      const response = await ApiMApi.post('/broad/hiddenbj/a/remove', params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 재생목록에 담기
   * @param {String} szWork - get_making_playlist
   * @param {String} type- all
   * @param {String} sorttype - ex) recent_date
   * @param {Number} title_no - 영상 번호
   * @param {Number} nPage - 페이지번호
   * @param {Number} nLimit - 노출갯수
   * @returns {Object}
   */
  async getPlayList(params) {
    try {
      const response = await StbbsApi.post('/vodplaylist/api/api.php', params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 재생목록 만들기
   * @param {String} szWork - ins_playlist_list
   * @param {String} display- 공개/비공개 여부
   * @param {String} title - 재생목록 이름
   * @param {String} share_yn - 공유 여부
   * @returns {Object}
   */
  async setCreatePlayList(params) {
    try {
      const response = await StbbsApi.post('/vodplaylist/api/api.php', params);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 재생목록 추가
   * @param {String} szWork - ins_playlist_info
   * @param {Number[]} nPlaylistIdx[]- 재생목록 인덱스
   * @param {String} title_no - 재생목록 이름
   * @returns {Object}
   */
  async setAddPlayList(params) {
    try {
      const response = await StbbsApi.post('/vodplaylist/api/api.php', params);
      return response;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 재생목록 퍼가기
   * @param {number} playlistIdx - 재생목록 번호
   */
  async setSharedPlaylist(playlistIdx) {
    if (!playlistIdx) {
      return;
    }
    try {
      const data = await StbbsApi.post('/vodplaylist/api/api.php', {
        szWork: 'share_playlist_bj_list',
        nPlaylistIdx: playlistIdx,
      });
      return data;
    } catch (e) {
      console.error(e, 'share_playlist error');
    }
  },

  /**
   * 인기 캐치 리스트
   * @returns {Object}
   */
  async getPopularCatchList() {
    try {
      const response = await LiveApi.get(
        '/api/myplus/popularCatchController.php',
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export default MyPlusApi;
