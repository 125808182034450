import MyFavoriteApi from '@/api/my/favorite';
import {SectionHeader} from '@/components/main/common';
import {useFetchFavoriteVod} from '../hooks/useFetchFavoriteVod';
import MyBroadcastLayout from '../common/MyBroadcastLayout';
import {ErrorBoundary} from '@/components/common';
import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';
import {BROADCAST_TYPES} from '@/constants';
import {getFavoriteContextMenu} from './UserClip';
import {throwDialog} from '@/utils/ui/dialog';
import useMyStore from '@/stores/my';

/**
 * 즐겨찾기 VOD 목록 컴포넌트
 * @desc API 로부터 VOD를 가져오고 필드명을 맞춰서 MyCatchGroup 컴포넌트에 전달한다.
 * @returns {JSX.Element} VOD
 */
const Vod = ({groupId}) => {
  const {
    items: vods,
    groupName,
    groupType,
  } = useFetchFavoriteVod(groupId, MyFavoriteApi.getVod);

  const {myInfo} = useMyStore();

  const handleContextMenuClick =
    (broadcastUserId, broadcastUserNickname) => async (event, type) => {
      if (type !== 'group') {
        return;
      }

      throwDialog(
        'my/FavoriteGroup',
        {
          userId: myInfo.userId,
          streamerIdx: broadcastUserId,
          streamerId: broadcastUserId,
          broadcastInfo: {
            userId: broadcastUserId,
            userNickname: broadcastUserNickname,
          },
        },
        {
          title: '즐겨찾기 그룹',
          customClassName: 'list_add_layer',
          titleAlign: 'left',
        },
      );
    };

  return (
    vods?.length > 0 && (
      <>
        <SectionHeader title='VOD' />
        <MyBroadcastLayout type='swiper'>
          {vods.map((broadcast) => (
            <ErrorBoundary key={broadcast.titleNo}>
              <BroadcastLayout
                broadcastType={BROADCAST_TYPES.VOD}
                thumbnailUrl={broadcast.pcThumb || broadcast.thumb}
                viewCount={broadcast.readCnt}
                createdDate={broadcast.regDate}
                startDateTime={broadcast.broadStart}
                authNumber={broadcast.authNo}
                userNickname={broadcast.userNick}
                title={broadcast.titleName}
                broadcastId={broadcast.titleNo}
                userId={broadcast.userId}
                hasBadge={
                  broadcast.categoryTags ||
                  broadcast.hashTags ||
                  broadcast.autoHashtags
                }
                categoryTags={broadcast.categoryTags}
                hashTags={broadcast.hashTags}
                autoHashTags={broadcast.autoHashtags}
                vodType={broadcast.fileType}
                categoryNo={Number(broadcast.category)}
                accessibleAge={Number(broadcast.grade)}
                allowsAdult={broadcast.isAdult}
                isFan={broadcast.isFanclub}
                isSubscribe={broadcast.isSubscribe}
                duration={broadcast.duration}
                contextMenu={{
                  menuList: getFavoriteContextMenu(broadcast.fileType),
                }}
                log={{
                  groupType,
                  groupName,
                }}
                previewThumbnailUrl={broadcast.webpPath}
                handleMenuClicked={handleContextMenuClick(
                  broadcast.userId,
                  broadcast.userNick,
                )}>
                <BroadcastType.Vod />
              </BroadcastLayout>
            </ErrorBoundary>
          ))}
        </MyBroadcastLayout>
      </>
    )
  );
};

export default Vod;
