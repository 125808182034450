import {useEffect, useState} from 'react';
import {
  MAIN_LIST_CNT,
  DIRECTORY_CONTENT_INIT_LIST,
  DIRECTORY_LIVE_CONTEXT_MENU,
  DIRECTORY_VOD_CONTEXT_MENU,
  DIRECTORY_LIST_CNT,
} from '@/constants/main';
import CategoryApi from '@/api/directory/category';
import {ListTab} from '@/components/main/directory/common/ListTab';
import Catch from '@/components/my/common/item/MyCatch';
import {isLogin} from '@/utils/main';
import {FloatingPortal} from '@/components/main/common';
import {useScrollTop} from '@/components/main/common/hooks/useScrollTop';
import {useNavigate} from 'react-router-dom';
import useDirectoryStore from '@/stores/directory';
import LogApi from '@/api/common/log';
import {useHiddenStreamer} from '../../common/hooks/useHiddenStreamer';
import {BroadcastLayout, BroadcastType} from '../../common/Broadcast';
import ErrorBoundary from './ErrorBoundary';
const CategoryContent = ({categoryName, categoryInfo, categoryType}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('view_cnt_desc');
  const [categoryContentList, setCategoryContentList] = useState(
    DIRECTORY_CONTENT_INIT_LIST,
  );

  const {
    selectedCategoryTab,
    selectedContentFilter,
    setSelectedCategoryTab,
    setSelectedContentFilter,
  } = useDirectoryStore();
  const {
    remainCount,
    slicedBroadList,
    setRemainCount,
    setSlicedBroadList,
    filterBroadList,
  } = useHiddenStreamer();
  const [hiddenStreamer, setHiddenStreamer] = useState([]);
  const {isDisplay, scrollToTop} = useScrollTop();

  useEffect(() => {
    if (categoryInfo && Object.keys(categoryInfo).length > 0) {
      //정책에 의해서 해당탭이 비노출 상태일경우,
      if (categoryType === 'live') {
        if (categoryInfo.liveCategoryNo === '00000000') {
          setSelectedCategoryTab('vod');
          navigate(
            `/directory/category/${encodeURIComponent(categoryName)}/vod`,
          );
        } else {
          setSelectedCategoryTab(categoryType);
        }
      } else {
        if (categoryInfo.vodCategoryNo === '00000000') {
          setSelectedCategoryTab('live');
          navigate(
            `/directory/category/${encodeURIComponent(categoryName)}/live`,
          );
        } else {
          setSelectedCategoryTab(categoryType);
        }
      }
    }
  }, [categoryInfo]);

  useEffect(() => {
    if (categoryContentList.isMore) {
      if (isLogin()) {
        getHiddenStreamerList();
      } else {
        getCategoryContents();
      }
    } else {
      if (slicedBroadList.length > 0) {
        setCategoryContentList({
          ...categoryContentList,
          list: [...categoryContentList.list, ...slicedBroadList],
        });
        setSlicedBroadList([]);
      } else {
        if (isLogin()) {
          getHiddenStreamerList();
        } else {
          getCategoryContents();
        }
      }
    }
  }, [page, order, selectedCategoryTab, selectedContentFilter]);

  useEffect(() => {
    if (hiddenStreamer.length > 0) {
      getCategoryContents();
    }
  }, [hiddenStreamer]);

  useEffect(() => {
    if (remainCount > 0) {
      setPage((prev) => prev + 1);
    }
  }, [remainCount]);

  const handleLiveViewLog = (response) => {
    try {
      const params = {
        path1: 'directory',
        path2: 'cate',
        listSendType: 'client',
        listViewCnt: response.list.length,
        listViewData: response.list.map((item) => ({
          bno: item.broadNo,
        })),
      };
      LogApi.sendLiveListViewLog(params);
    } catch (error) {}
  };

  const handleVodViewLog = (response) => {
    try {
      const params = {
        path1: 'directory',
        path2: 'cate',
        listSendType: 'client',
        listViewCnt: response.list.length,
        listViewData: response.list.map((item) => ({
          vno: item.titleNo,
        })),
      };
      LogApi.sendVodListViewLog(params);
    } catch (error) {}
  };

  const handleOnClickTab = (type) => {
    if (type === selectedCategoryTab) {
      return;
    }
    navigate(`/directory/category/${encodeURIComponent(categoryName)}/${type}`);
    setSelectedCategoryTab(type);
    setPage(1);
    if (type === 'live') {
      setOrder('view_cnt_desc');
    } else {
      setSelectedContentFilter({
        tab: type,
        selected: [
          {key: 'sort', label: '조회수', type: 'view_cnt_desc'},
          {key: 'fileType', label: '전체', type: 'ALL'},
          {key: 'period', label: '1주', type: '1week'},
        ],
      });
    }
    setRemainCount(0);
    setSlicedBroadList([]);
    setCategoryContentList(DIRECTORY_CONTENT_INIT_LIST);
  };

  const handleOnClickMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleOnClickSort = (type) => {
    if (type === order || (!isLogin() && type === 'prefer')) {
      return;
    }
    setPage(1);
    setOrder(type);
    setRemainCount(0);
    setSlicedBroadList([]);
    setCategoryContentList(DIRECTORY_CONTENT_INIT_LIST);
  };
  const getCategoryContents = async () => {
    try {
      const queryParams = {
        m: 'categoryContentsList',
        szType: selectedCategoryTab,
        nPageNo: page,
        nListCnt: categoryType === 'live' ? MAIN_LIST_CNT : DIRECTORY_LIST_CNT,
        szPlatform: 'pc',
      };
      //categoryNo === '00000000' 으로 올때는 해당탭 비노출
      if (selectedCategoryTab === 'live') {
        queryParams.szOrder = order;
        if (categoryInfo.liveCategoryNo === '00000000') {
          return;
        } else {
          queryParams.szCateNo = categoryInfo.liveCategoryNo;
        }
      } else if (
        selectedCategoryTab === 'vod' ||
        selectedCategoryTab === 'catch'
      ) {
        if (categoryInfo.vodCategoryNo === '00000000') {
          return;
        } else {
          queryParams.szCateNo = categoryInfo.vodCategoryNo;
        }
        if (selectedCategoryTab === 'vod') {
          queryParams.szFileType = selectedContentFilter.selected.find(
            (item) => item.key === 'fileType',
          )?.type;
        }
        queryParams.szOrder = selectedContentFilter.selected.find(
          (item) => item.key === 'sort',
        )?.type;
        queryParams.szTerm = selectedContentFilter.selected.find(
          (item) => item.key === 'period',
        )?.type;
      }
      const response = await CategoryApi.getCategoryContents(queryParams);
      if (response) {
        const {isMore, list} = response;
        if (hiddenStreamer.length > 0) {
          filterBroadList(
            selectedCategoryTab,
            page,
            list,
            isMore,
            hiddenStreamer,
            setCategoryContentList,
          );
        } else {
          setCategoryContentList((prevState) => ({
            isMore: isMore,
            list: page > 1 ? [...prevState.list, ...list] : [...list],
          }));
        }

        //listView 로그 전송
        if (response?.list?.length > 0) {
          if (selectedCategoryTab === 'live') {
            handleLiveViewLog(response);
          }
          if (
            selectedCategoryTab === 'vod' ||
            selectedCategoryTab === 'catch'
          ) {
            handleVodViewLog(response);
          }
        }
      }
    } catch (error) {
      setCategoryContentList(DIRECTORY_CONTENT_INIT_LIST);
    }
  };

  const getHiddenStreamerList = async () => {
    try {
      const response = await CategoryApi.getHiddenStreamerList();
      if (response && response.result === 1) {
        if (response.data.length === 0) {
          getCategoryContents();
        } else {
          setHiddenStreamer([...response.data]);
        }
      }
    } catch (error) {
      getCategoryContents();
    }
  };

  const handleVodFilterClick = (key, label, type) => {
    setSelectedContentFilter({
      ...selectedContentFilter,
      selected: selectedContentFilter.selected.map((filter) =>
        filter.key === key ? {...filter, label, type} : filter,
      ),
    });
    setPage(1);
    setRemainCount(0);
    setSlicedBroadList([]);
    setCategoryContentList(DIRECTORY_CONTENT_INIT_LIST);
  };

  const listTabProps = {
    tab: selectedCategoryTab,
    order,
    categoryInfo,
    handleOnClickTab,
    handleOnClickSort,
    handleVodFilterClick,
  };

  return (
    <>
      <ListTab {...listTabProps} />
      {(selectedCategoryTab === 'live' || selectedCategoryTab === 'vod') &&
        categoryContentList?.list?.length > 0 && (
          <div className='cBox-list'>
            <ul>
              {categoryContentList?.list?.map((broadcast, broadcastIdx) => (
                <li
                  data-type='cBox'
                  key={`${broadcast.userId || broadcast.broadcastId}-${broadcastIdx}`}>
                  {selectedCategoryTab === 'live' && (
                    <ErrorBoundary fallback={<div>로딩중</div>}>
                      <BroadcastLayout
                        broadcastType='live'
                        liveType={broadcast.broadType}
                        broadcastId={broadcast.broadNo}
                        categoryName={
                          broadcast.categoryTags &&
                          broadcast.categoryTags.length > 0
                            ? broadcast.categoryTags[0]
                            : ''
                        }
                        autoHashTags={broadcast.autoHashtags}
                        categoryTags={broadcast.categoryTags}
                        hashTags={broadcast.hashTags}
                        allowsAdult={broadcast.grade}
                        hasBadge={
                          broadcast.autoHashtags ||
                          broadcast.categoryTags ||
                          broadcast.hashTags
                        }
                        hasPassword={broadcast.isPassword}
                        hasDrops={broadcast.isDrops}
                        createdDate={broadcast.broadStart}
                        thumbnailUrl={broadcast.thumbnail}
                        title={broadcast.broadTitle}
                        userId={broadcast.userId}
                        userNickname={broadcast.userNick}
                        viewCount={broadcast.viewCnt}
                        startDateTime={broadcast.broadStart}
                        contextMenu={{
                          menuList: DIRECTORY_LIVE_CONTEXT_MENU,
                        }}
                        log={{
                          data: {
                            directoryCateId: categoryInfo.categoryName,
                            categoryTags: categoryInfo.fixedTags,
                          },
                        }}>
                        <BroadcastType.Live />
                      </BroadcastLayout>
                    </ErrorBoundary>
                  )}
                  {selectedCategoryTab === 'vod' && (
                    <ErrorBoundary fallback={<div>로딩중</div>}>
                      <BroadcastLayout
                        broadcastType='vod'
                        vodType={broadcast.fileType}
                        duration={broadcast.duration}
                        broadcastId={broadcast.titleNo}
                        categoryName={
                          broadcast.categoryTags &&
                          broadcast.categoryTags.length > 0
                            ? broadcast.categoryTags[0]
                            : ''
                        }
                        autoHashTags={broadcast.autoHashtags}
                        categoryTags={broadcast.categoryTags}
                        hashTags={broadcast.hashTags}
                        allowsAdult={broadcast.grade}
                        hasBadge={
                          broadcast.autoHashtags ||
                          broadcast.categoryTags ||
                          broadcast.hashTags
                        }
                        thumbnailUrl={broadcast.thumbnail}
                        title={broadcast.title}
                        userId={broadcast.userId}
                        userNickname={broadcast.userNick}
                        originalUserId={broadcast.originalUserId}
                        originalUserNickname={broadcast.originalUserNick}
                        viewCount={broadcast.viewCnt}
                        createdDate={broadcast.regDate}
                        previewThumbnailUrl={broadcast.webPath}
                        contextMenu={{
                          menuList: DIRECTORY_VOD_CONTEXT_MENU,
                        }}
                        log={{
                          data: {
                            directoryCateId: categoryInfo.categoryName,
                            ...(selectedCategoryTab === 'vod' && {
                              exCategoryNo: categoryInfo.vodCategoryNo,
                            }),
                            categoryTags: categoryInfo.fixedTags,
                          },
                        }}>
                        <BroadcastType.Vod />
                      </BroadcastLayout>
                    </ErrorBoundary>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      {selectedCategoryTab === 'catch' &&
        categoryContentList?.list?.length > 0 && (
          <div className='catch_area'>
            <div className='catch_list'>
              <ul>
                {categoryContentList?.list?.map((catchItem, catchIdx) => (
                  <li key={`${catchItem.catchId}-${catchIdx}`}>
                    <Catch
                      catchId={catchItem.titleNo}
                      thumbnailSrc={catchItem.thumbnail}
                      isAdult={catchItem.grade}
                      isHorizontal={catchItem.resolutionType === 'horizontal'}
                      viewCount={catchItem.viewCnt}
                      {...catchItem}
                      isLogSendData={{
                        directoryCateId: categoryInfo.categoryName,
                        exCategoryNo: categoryInfo.vodCategoryNo,
                      }}
                      catchContextMenuList={[
                        {label: '공유하기', type: 'share'},
                      ]}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      {(slicedBroadList.length > 0 || categoryContentList?.isMore) && (
        <div className='show_more'>
          <button type='button' onClick={handleOnClickMore}>
            더보기
          </button>
        </div>
      )}
      {categoryContentList?.list?.length === 0 && (
        <div className='noList'>
          <p>
            해당 카테고리의{' '}
            <strong>
              {selectedCategoryTab === 'catch'
                ? 'Catch'
                : selectedCategoryTab.toUpperCase()}
            </strong>
            가 없습니다.
          </p>
        </div>
      )}
      <FloatingPortal className={'floating_btns'} target={'soop_wrap'}>
        <button
          type='button'
          className={`page_top ${isDisplay ? 'active' : ''}`}
          onClick={scrollToTop}>
          상단 바로가기
        </button>
      </FloatingPortal>
    </>
  );
};
export default CategoryContent;
