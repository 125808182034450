'use client';

import {useEffect, useState} from 'react';
import MyCommonApi from '@/api/my/common';
import RealTimePopularPost from '../item/real-time-popular/RealTimePopularPost';

const getRealTimePopularList = async () => {
  try {
    const {data: realTimePopularList} =
      await MyCommonApi.getRealTimePopularList();

    if (!realTimePopularList || !Array.isArray(realTimePopularList)) {
      return {realTimePopularList: []};
    }

    return {realTimePopularList};
  } catch (error) {
    console.log(error);
    return {realTimePopularList: []};
  }
};

const RealTimePopularList = () => {
  const [realTimePopularList, setRealTimePopularList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchList = async () => {
      setIsLoading(true);
      try {
        const {realTimePopularList: data} = await getRealTimePopularList();
        setRealTimePopularList(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchList();
  }, []);
  return (
    <>
      {realTimePopularList && realTimePopularList.length > 0 && (
        <div className='realTime_post'>
          <h3 className='title'>
            실시간 인기글
            <a href='/my/feed/hotissue' target='_blank'>
              전체글 보기
            </a>
          </h3>
          <div className='post_list'>
            {realTimePopularList.map((item, idx) => {
              return <RealTimePopularPost key={idx} {...item} />;
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default RealTimePopularList;
