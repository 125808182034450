'use client';

import MyNoList from '@/components/my/common/item/NoList';
import useMyStore from '@/stores/my';
import {useEffect, useState} from 'react';
import '/public/static/css/module/cBox.scss';
import {ErrorBoundary} from '@/components/common';
import MyBroadcastLayout from '../common/MyBroadcastLayout';
import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';
import {BROADCAST_TYPES} from '@/constants';
import {CONTEXT_MENU} from '@/constants/my/contextMenu';
import {useLocation} from 'react-router-dom';
import {throwDialog} from '@/utils/ui/dialog';

const PAGE_SIZE = 60;

/**
 * 즐겨찾기 페이지 라이브 방송 목록 컴포넌트
 */
const Live = () => {
  const {
    liveBroadcasts,
    getLiveBroadcasts,
    liveSortKey,
    liveSortOrder,
    streamers,
  } = useMyStore();
  const [broadcasts, setBroadcasts] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getLiveBroadcasts();
  }, [streamers, liveSortKey, liveSortOrder]);

  useEffect(() => {
    setPage(0);
    setBroadcasts(liveBroadcasts.slice(0, PAGE_SIZE));
  }, [liveBroadcasts]);

  useEffect(() => {
    if (page > 0) {
      const startIndex = page * PAGE_SIZE;
      const endIndex = startIndex + PAGE_SIZE;
      const newBroadcasts = liveBroadcasts.slice(startIndex, endIndex);

      setBroadcasts((prevBroadcasts) => [...prevBroadcasts, ...newBroadcasts]);
    }
  }, [page]);

  const handlePageChange = () => {
    setPage((page) => page + 1);
  };

  const {myInfo} = useMyStore();

  const handleContextMenuClick =
    (broadcastUserId, broadcastUserNickname) => async (event, type) => {
      if (type !== 'group') {
        return;
      }

      throwDialog(
        'my/FavoriteGroup',
        {
          userId: myInfo.userId,
          streamerIdx: broadcastUserId,
          streamerId: broadcastUserId,
          broadcastInfo: {
            userId: broadcastUserId,
            userNickname: broadcastUserNickname,
          },
        },
        {
          title: '즐겨찾기 그룹',
          customClassName: 'list_add_layer',
          titleAlign: 'left',
        },
      );
    };

  const location = useLocation();

  const contextMenu = () => {
    switch (location.pathname) {
      case '/my/favorite':
        return CONTEXT_MENU.FAVORITE.LIVE;
      case '/my/fan':
      case '/my/subscribe':
        return CONTEXT_MENU.LIVE;
      default:
        return CONTEXT_MENU.LIVE;
    }
  };

  return broadcasts.length > 0 ? (
    <>
      <MyBroadcastLayout type='grid'>
        {broadcasts.map((broadcast) => (
          <ErrorBoundary key={broadcast.broadNo}>
            <BroadcastLayout
              broadcastType={BROADCAST_TYPES.LIVE}
              thumbnailUrl={broadcast.broadImg}
              viewCount={broadcast.totalViewCnt}
              startDateTime={broadcast.broadStart}
              watchOrder={broadcast.orderNo}
              userNickname={broadcast.userNick}
              title={broadcast.broadTitle}
              broadcastId={broadcast.broadNo}
              userId={broadcast.userId}
              hasBadge={
                broadcast.categoryTags ||
                broadcast.hashTags ||
                broadcast.autoHashtags
              }
              categoryTags={broadcast.categoryTags}
              hashTags={broadcast.hashTags}
              autoHashTags={broadcast.autoHashtags}
              liveType={broadcast.broadType}
              canResend={broadcast.isVisitBroad}
              categoryNo={Number(broadcast.broadCateNo)}
              accessibleAge={Number(broadcast.grade)}
              allowsAdult={broadcast.isAdult}
              hasPassword={broadcast.isPassword}
              isFan={broadcast.isFanclub}
              isSubscribe={broadcast.isSubscribe}
              contextMenu={{
                menuList: contextMenu(),
              }}
              handleMenuClicked={handleContextMenuClick(
                broadcast.userId,
                broadcast.userNick,
              )}
              broadBps={broadcast.broadBps}
              broadResolution={broadcast.broadResolution}>
              <BroadcastType.Live />
            </BroadcastLayout>
          </ErrorBoundary>
        ))}
      </MyBroadcastLayout>
      {liveBroadcasts?.length > broadcasts.length && (
        <div className='show_more' onClick={handlePageChange}>
          <button type='button'>더보기</button>
        </div>
      )}
    </>
  ) : (
    <MyNoList title='방송중인 스트리머가 없습니다.' />
  );
};

export default Live;
