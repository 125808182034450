/**
 * @typedef {import('@/components/ui/SoopUiProfileIconMenu').EventData} EventData
 */

import {useRef, useState} from 'react';
import globalDomains from '@/utils/global-domains';
import MyProfileImg from '@/components/my/common/item/MyProfileImg';
import {ChipCategory} from '@/components/search/common';
import useConvertRegDate from '@/components/main/common/hooks/useConvertRegDate';
import {SoopUiContextMenu, SoopUiProfileIconMenu} from '@/components/ui';
import {convertViewCnt} from '@/utils/main';
import {useLocation, useSearchParams} from 'react-router-dom';
import {ROUTE} from '@/constants/main/route';
import useMyStore from '@/stores/my';
import useFavoriteStore from '@/stores/search';
import {decodeHtmlEntities} from '@/utils';
import {sendLegacyInflowPathLog} from '@/utils/main/my/log';
import {convertToArray} from '@/utils';

const ThumbnailInfo = ({
  userId,
  userNickname,
  broadcastId,
  isDrops,
  title,
  viewCount,
  registerDate,
  categoryName,
  fileType = '',
  score = 0,
  listDataType = '',
  isWidthList = false,
  isVod = false,
  isFan = false,
  isFavorite = false,
  isSubscribe = false,
  isBadge,
  isContextmenu = true,
  isOriginalContent,
  autoHashtags = [],
  categoryTags = [],
  hashTags = [],
  menuList = [],
  titleHistory = [],
  logCallback,
  logic,
  originalUserId,
  catchStoryIdList,
  thumbnailSrc,
  scheme,
  stationNo,
  handleOnClickMenu = () => {},
  onMouseTitleEnter = () => {},
  onMouseTitleLeave = () => {},
  groupType,
  groupName,
  adminProfileImg,
  searchArea,
  isUserClip,
  isLogSendData,
  originalUserNick,
}) => {
  const soopUiProfileIconMenuThumbnail = useRef(null);
  const soopUiProfileIconMenuNickname = useRef(null);

  const [expand, setExpand] = useState('');
  const [isShowProfileIconMenu, setIsShowProfileIconMenu] = useState(false);
  const {convertRegDate} = useConvertRegDate();
  const {toggleFavorite, setFavoriteStreamerName} = useFavoriteStore();
  const handleOpen = () => {
    setExpand((prevItem) => (prevItem === 'expand' ? '' : 'expand'));
    handleSectionClick('oth');
  };
  const [searchParams] = useSearchParams();
  const searchKey = searchParams.get('szKeyword');

  const titleLink = isVod
    ? `${globalDomains.VOD_AFREECATV}/player/${broadcastId}${fileType === 'CATCH_STORY' ? `/catchstory?szLocation=search&aStoryListIdx=${catchStoryIdList.join('-')}` : ''}`
    : `${globalDomains.PLAY_AFREECATV}/${userId}/${broadcastId}`;

  const formattedDate = () => {
    if (registerDate) {
      const broadDate = new Date(registerDate);
      return `${broadDate.getMonth() + 1}월 ${broadDate.getDate()}일`;
    }

    return '';
  };

  const location = useLocation();
  const {getStreamers, getLiveBroadcasts} = useMyStore();

  const handleSectionClick = (type) => {
    let param = type ? {type: type} : '';
    logCallback && logCallback(param);
  };

  /**
   * 제목 클릭 시에만 유입경로 찍힐수 있도록 함수 분리
   */
  const handleInflowPath = () => {
    let inflowPathRequestParameter = {
      broadcastId: broadcastId,
      streamerId: userId,
      type: isVod ? 'vod' : 'live',
      moreData: {
        contentsType: isVod ? 'vod' : 'live',
        hashTags: convertToArray(hashTags),
        categoryTags: convertToArray(categoryTags),
        ...(listDataType && {listDataType}),
        ...(searchKey && {skey: searchKey}),
        exCategoryNo: broadcastId,
        ...(groupName && {groupName}),
        ...(groupType && {groupType}),
        isUserClip,
      },
      isLogSendData,
    };

    if (isVod) {
      inflowPathRequestParameter = {
        ...inflowPathRequestParameter,
        isLogSendData: {...isLogSendData, uploader: userId},
        streamerId: originalUserId ? originalUserId : userId,
      };
    }
    // 기존 유입 로그 전송
    sendLegacyInflowPathLog(inflowPathRequestParameter);
  };

  /**
   * 프로필 ict 메뉴의 이벤트가 발생했을 때 트리거
   * @param {EventData} event
   */
  const handleProfileIconMenuEvent = (event) => {
    const {eventName, data} = event;

    if (eventName === 'deleteFavorite') {
      if (location.pathname === ROUTE.MY.FAVORITE) {
        getStreamers();
        getLiveBroadcasts();
      }
      // 검색페이지에서 즐겨찾기 했을 경우 프로필테마/스트리머영역 즐겨찾기 관련 동기화를 위한 로직
      toggleFavorite();
      setFavoriteStreamerName(data.id);
    }
  };

  const handleProfileClick = (e) => {
    e.preventDefault();

    if (userId) {
      handleSectionClick('sti');

      window.open(`${globalDomains.BJ_AFREECATV}/${userId}`);
    }
  };

  // MY 구독 페이지에서는 구독 뱃지가 보이면 안 됨
  const isSubscribeBadge =
    isSubscribe && !location.pathname.includes('/subscribe');
  // MY 팬클럽 페이지에서는 팬클럽 뱃지가 보이면 안 됨
  const isFanBadge = isFan && !location.pathname.includes('/fan');

  return (
    <div className='cBox-info'>
      {!isWidthList && (
        <a className='thumb' onClick={(e) => handleProfileClick(e)}>
          <MyProfileImg userId={userId} adminProfileImg={adminProfileImg} />
        </a>
      )}
      <div className='details'>
        {isWidthList ? (
          <>
            <div className='thumb_warp'>
              <a
                href={`${globalDomains.BJ_AFREECATV}/${userId}`}
                className='thumb'
                target='_blank'
                onClick={(e) => handleProfileClick(e)}>
                <MyProfileImg userId={userId} />
              </a>
              <div className='nick_wrap'>
                <a
                  className='nick'
                  style={{cursor: 'pointer'}}
                  onClick={(e) => {
                    e.preventDefault();
                    soopUiProfileIconMenuThumbnail.current?.open();
                    //ICT 메뉴
                    handleSectionClick('nick');
                  }}>
                  <span>{userNickname}</span>
                </a>
                {/*둘다(구독,팬) 노출이 있어 각 컴포넌트 별로 관리*/}
                {isSubscribeBadge && (
                  <span className='grade-badge-subscribe'>구독</span>
                )}
                {isFanBadge && <span className='grade-badge-fan'>F</span>}
              </div>
            </div>
            <SoopUiProfileIconMenu
              ref={soopUiProfileIconMenuThumbnail}
              streamerId={userId}
              streamerNick={userNickname}
              handleMenuButtonClicked={handleProfileIconMenuEvent}
              searchArea={searchArea}
            />
          </>
        ) : (
          <>
            <div className='nick_wrap'>
              <a
                className='nick'
                style={{cursor: 'pointer'}}
                target='_blank'
                onClick={(e) => {
                  //오리지날 콘텐츠는 프로필아이콘 기능을 지원하지 않는다.
                  if (!isOriginalContent) {
                    e.preventDefault();
                    soopUiProfileIconMenuNickname.current?.open();

                    handleSectionClick('nick');
                  } else {
                    window.open(`${globalDomains.CINETY_AFREECATV}`);
                  }
                }}>
                <span>{userNickname}</span>
              </a>
              {isSubscribeBadge && (
                <span className='grade-badge-subscribe'>구독</span>
              )}
              {isFanBadge && <span className='grade-badge-fan'>F</span>}
            </div>
            {!isOriginalContent && (
              <SoopUiProfileIconMenu
                ref={soopUiProfileIconMenuNickname}
                streamerId={userId}
                streamerNick={userNickname}
                onEvent={handleProfileIconMenuEvent}
                searchArea={searchArea}
              />
            )}
          </>
        )}
        {/* {isStory ? `${formattedDate()} 스토리` : convertViewCnt(viewCount)} */}
        <h3 className='title'>
          <a
            href={titleLink}
            target='_blank'
            title={decodeHtmlEntities(title)}
            onMouseEnter={onMouseTitleEnter}
            onMouseLeave={onMouseTitleLeave}
            onClick={() => {
              // sckact 로그 전송
              handleSectionClick('tt');
              // inflow path 로그 전송
              handleInflowPath();
            }}>
            {isDrops ? (
              <>
                <span className='ic_itemdrop'>드롭스 이벤트 진행 중</span>
                <i className='tip' tip='드롭스 이벤트 진행 중'></i>
              </>
            ) : null}
            <span />
            {fileType === 'CATCH_STORY'
              ? `${formattedDate()} 스토리`
              : decodeHtmlEntities(title)}
          </a>
        </h3>

        {isBadge && (
          <>
            {isVod && (
              <div className='vod_info'>
                {fileType !== 'CATCH_STORY' && (
                  <span className='views'>{convertViewCnt(viewCount)}</span>
                )}
                <span className='date'>{convertRegDate(registerDate)}</span>
              </div>
            )}
            {fileType !== 'CATCH_STORY' && (
              <ChipCategory
                categoryName={categoryName}
                hashType={
                  fileType === 'CATCH' ? 'CATCH' : isVod ? 'VOD' : 'LIVE'
                }
                autoHashTags={autoHashtags}
                categoryTags={categoryTags}
                hashTags={hashTags}
                searchArea={searchArea}
                onClickLog={() => {
                  handleSectionClick('hash');
                }}
                location={location}
              />
            )}
          </>
        )}
      </div>
      {isContextmenu && (
        <SoopUiContextMenu
          isVod={isVod}
          menuList={menuList}
          handleMenuClicked={handleOnClickMenu}
          isShowProfileIconMenu={isShowProfileIconMenu}
          data={{
            broadcastId,
            userNickname,
            userId,
            listDataType,
            score,
            title,
            logic,
            originalUserId,
            thumbnailSrc,
            scheme,
            stationNo,
            originalUserNick,
          }}
        />
      )}
      {fileType === 'REVIEW' && titleHistory.length > 0 && isWidthList && (
        <div className={`time_list_box ${expand}`}>
          <ul className='time_list'>
            {titleHistory.map((history, index) => {
              return (
                <li key={`${broadcastId}_review_history_${index}`}>
                  <a
                    href={`${globalDomains.PLAY_AFREECATV}/${userId}/${broadcastId}`}
                    target='_blank'
                    onClick={() => {
                      handleSectionClick('th');
                    }}>
                    <span className='time'>{history.changePosition}</span>
                    <p className='title'>{history.title}</p>
                  </a>
                </li>
              );
            })}
          </ul>
          <button type='button' className='expand' onClick={handleOpen}>
            제목 변경
          </button>
        </div>
      )}
    </div>
  );
};

export default ThumbnailInfo;
