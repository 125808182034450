import '/public/static/css/module/sports_schedule.scss';
import MainLiveApi from '@/api/main/main-live';
import {LiveSportsHotTextList} from '@/components/main/live/group/sports/LiveSportsHotTextList';
import {SoopUiSwiper} from '@/components/ui';
import {STATE_NAME_MAP, STATUS_NAME_MAP} from '@/constants/main/sports';
import globalDomains from '@/utils/global-domains';
import {useEffect, useState} from 'react';
import {sendLegacyInflowPathLog} from '@/utils/main/my/log';
import {SectionHeader} from '@/components/main/common';

export const LiveSportsSchedule = ({mainListInfo}) => {
  const [slideView, setSlideView] = useState(4);
  const [sportsSchedule, setSportsSchedule] = useState({data: []});

  const swiperConfig = {
    spaceBetween: 40,
    loop: false,
  };

  useEffect(() => {
    getSportsScheduleList();
  }, []);

  const getSportsScheduleList = async () => {
    const queryParams = {
      work: 'get_schedule_list',
      code_list: 'ALL',
      live_score: true,
    };
    const response = await MainLiveApi.getSportsScheduleList(queryParams);
    if (response) {
      setSportsSchedule(response);
    }
  };

  const handleOnClickLive = (e, item) => {
    e.preventDefault();
    sendLegacyInflowPathLog({
      broadcastId: 0,
      streamerId: item.vsBjId,
      isLogSendData: {
        path1: 'main',
        path2: 'sports',
      },
    });
    window.open(`${globalDomains.PLAY_AFREECATV}/${item.vsBjId}`);
  };

  const handleOnClickVod = (e, item) => {
    e.preventDefault();
    sendLegacyInflowPathLog({
      broadcastId: item.reviewTitleNo ? item.reviewTitleNo : item.hlTitleNo,
      streamerId: item.vsBjId,
      type: 'vod',
      isLogSendData: {
        path1: 'main',
        path2: 'sports',
        vtype: item.reviewTitleNo ? 'vod_review' : 'vod_highlight',
      },
    });
    window.open(
      `${globalDomains.VOD_AFREECATV}/player/${item.reviewTitleNo ? item.reviewTitleNo : item.hlTitleNo}`,
    );
  };

  const convertStatusName = (status) => {
    return STATUS_NAME_MAP[Number(status)] || '';
  };

  const convertStateName = (state) => {
    return STATE_NAME_MAP[state] || '';
  };

  const renderStatusLink = (item) => {
    if (item.liveState) {
      const stateName = convertStateName(item.liveState);
      switch (item.liveState) {
        case 'B': // 경기전
          return (
            <span className='state expected'>{item.vsMatchTime} 예정</span>
          );
        case 'I': // 경기중
        case 'F': // 경기종료
        case 'D': // 경기지연
        case 'S': // 경기중지
        case 'T': // 서스펜드
        case 'U': // 우천중지
          const stateClass = item.vsStatus == 'F' ? '' : 'end';
          if (item.liveState === 'F') {
            return <span className={`state ${stateClass}`}>{stateName}</span>;
          } else {
            return (
              <a
                className={`state ${stateClass}`}
                onClick={(e) => handleOnClickLive(e, item)}>
                {stateName}
              </a>
            );
          }
      }
    } else {
      const stateName = convertStatusName(item.vsStatus);
      switch (item.vsStatus) {
        case '0': //중계예정
          return (
            item.original && (
              <span className='state expected'>{item.vsMatchTime} 예정</span>
            )
          );

        case '4': //경기취소
          return <span className='state end'>{stateName}</span>;
        case '1': // 경기중
        case '2': // 하이라이트
        case '5': // 경기종료
        case '7': // 방송종료
          const stateClass = item.vsStatus == '1' ? '' : 'end';
          return ['3', '5', '7'].includes(item.vsStatus) ? (
            <span className={`state ${stateClass}`}>{stateName}</span>
          ) : (
            <a
              className={`state ${stateClass}`}
              onClick={(e) => handleOnClickLive(e, item)}>
              {stateName}
            </a>
          );
      }
    }
  };

  const renderSchedule = (item) => {
    let render;
    let stateText = '';

    if (['3', '6'].includes(item.vsStatus)) {
      return;
    }
    // 경기 취소인 경우 어드민 경기 데이터 로직으로 처리
    if (item?.liveState === 'C') {
      item.liveState = null;
      item.vsStatus = '4';
    }
    if (item.liveState) {
      let homeScore = item.liveHomeScore;
      let awayScore = item.liveAwayScore;
      stateText = convertStateName(item?.liveGState);
      // 어드민에서 경기종료로 선택하면 라이브 스코어 대신 아프리카TV 정보 가져옴
      if (item.vsStatus === '5') {
        homeScore = item.vsHomeScore;
        awayScore = item.vsAwayScore;
      }
      render = (
        <>
          <div className='team_l'>
            <span className='logo'>
              <img
                src={
                  item.vsCode === 'KBO'
                    ? item.vsAwayTeamPcImageUrl1
                    : item.vsHomeTeamPcImageUrl1
                }
                alt=''
              />
            </span>
            <p className='name'>
              {item.vsCode === 'KBO'
                ? item.vsAwayTeamCodeName
                : item.vsHomeTeamCodeName}
            </p>
          </div>
          <div className='match_status'>
            {['I', 'F', 'D', 'S', 'T', 'U'].includes(item.liveState) ? (
              <div className='score'>
                <span
                  className={
                    item.vsCode === 'KBO'
                      ? awayScore > homeScore
                        ? 'win'
                        : ''
                      : homeScore > awayScore
                        ? 'win'
                        : ''
                  }>
                  {item.vsCode === 'KBO' ? awayScore : homeScore}
                </span>
                <em>:</em>
                <span
                  className={
                    item.vsCode === 'KBO'
                      ? homeScore > awayScore
                        ? 'win'
                        : ''
                      : awayScore > homeScore
                        ? 'win'
                        : ''
                  }>
                  {item.vsCode === 'KBO' ? homeScore : awayScore}
                </span>
              </div>
            ) : (
              <div className='time_tbl'>{item.vsMatchTime}분 예정</div>
            )}
          </div>
          <div className='team_r'>
            <span className='logo'>
              <img
                src={
                  item.vsCode === 'KBO'
                    ? item.vsHomeTeamPcImageUrl1
                    : item.vsAwayTeamPcImageUrl1
                }
                alt=''
              />
            </span>
            <p className='name'>
              {item.vsCode === 'KBO'
                ? item.vsHomeTeamCodeName
                : item.vsAwayTeamCodeName}
            </p>
          </div>
        </>
      );
    } else {
      if (!item.original) {
        render = (
          <>
            <div className='team_l'>
              <span className='logo'>
                <img
                  src={
                    item.vsCode === 'KBO'
                      ? item.vsAwayTeamPcImageUrl1
                      : item.vsHomeTeamPcImageUrl1
                  }
                  alt=''
                />
              </span>
              <p className='name'>
                {item.vsCode === 'KBO'
                  ? item.vsAwayTeamCodeName
                  : item.vsHomeTeamCodeName}
              </p>
            </div>
            <div className='match_status'>
              {['1', '2', '5', '7'].includes(item.vsStatus) ? (
                <div className='score'>
                  <span
                    className={
                      item.vsCode === 'KBO'
                        ? item.vsAwayScore > item.vsHomeScore
                          ? 'win'
                          : ''
                        : item.vsHomeScore > item.vsAwayScore
                          ? 'win'
                          : ''
                    }>
                    {item.vsCode === 'KBO'
                      ? item.vsAwayScore
                      : item.vsHomeScore}
                  </span>
                  <em>:</em>
                  <span
                    className={
                      item.vsCode === 'KBO'
                        ? item.vsHomeScore > item.vsAwayScore
                          ? 'win'
                          : ''
                        : item.vsAwayScore > item.vsHomeScore
                          ? 'win'
                          : ''
                    }>
                    {item.vsCode === 'KBO'
                      ? item.vsHomeScore
                      : item.vsAwayScore}
                  </span>
                </div>
              ) : (
                <div className='time_tbl'>{item.vsMatchTime}분 예정</div>
              )}
            </div>
            <div className='team_r'>
              <span className='logo'>
                <img
                  src={
                    item.vsCode === 'KBO'
                      ? item.vsHomeTeamPcImageUrl1
                      : item.vsAwayTeamPcImageUrl1
                  }
                  alt=''
                />
              </span>
              <p className='name'>
                {item.vsCode === 'KBO'
                  ? item.vsHomeTeamCodeName
                  : item.vsAwayTeamCodeName}
              </p>
            </div>
          </>
        );
      } else {
        render = <p className='match_txt'>{item.vsContent}</p>;
      }
    }
    return render;
  };

  return (
    <>
      {sportsSchedule && (
        <>
          <SectionHeader
            customElement={
              <>
                <h2>오늘의 주요일정</h2>
                {/* 핫이슈 스포츠 */}
                <LiveSportsHotTextList mainListInfo={mainListInfo} />
              </>
            }
          />
          <div className='sports_schedule'>
            <SoopUiSwiper
              options={swiperConfig}
              swiperClass='schedule_list'
              swiperTag='ul'
              maxSlidesPerView={4}>
              {sportsSchedule?.data?.map((item, idx) => (
                <li key={idx}>
                  <div className='game_box'>
                    <div className='match_info_wrap'>
                      <div className='tit'>
                        <strong>{item.codeName}</strong>
                      </div>
                      <div className='match_info'>{renderSchedule(item)}</div>
                    </div>
                    <div className='match_btn_wrap'>
                      <div className='state_box'>{renderStatusLink(item)}</div>
                      <div className='vod_view'>
                        <a
                          className={item.reviewTitleNo ? '' : 'disabled'}
                          onClick={(e) => handleOnClickVod(e, item)}>
                          다시보기
                        </a>
                        <a
                          className={item.hlTitleNo ? '' : 'disabled'}
                          onClick={(e) => handleOnClickVod(e, item)}>
                          H/L
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
              {(() => {
                const emptySlidesCount = slideView - sportsSchedule.data.length;
                const emptySlides = [];
                for (let i = 0; i < emptySlidesCount; i++) {
                  emptySlides.push(
                    <li key={i} className='no-game'>
                      <p>경기가 없습니다</p>
                    </li>,
                  );
                }
                return emptySlides;
              })()}
            </SoopUiSwiper>
          </div>
        </>
      )}
    </>
  );
};
