import classNames from 'classnames';
import {Link} from 'react-router-dom';

/**
 * @description 즐겨찾기 스트리머 리스트
 * @param {Function} handleUserPin - 핀 등록/해제 핸들러
 * @param {String} userIdFromPath - 현재 선택된 스트리머 아이디
 * @param {Number} favoriteNo - 즐겨찾기 번호
 * @param {Boolean} isLive - 라이브 여부
 * @param {Boolean} isPin - 핀 여부
 * @param {String} profileImage - 프로필 이미지
 * @param {String} userId - 스트리머 아이디
 * @param {String} userNick - 스트리머 닉네임
 * @returns
 */

const FavoriteListItem = ({
  handleUserPin = () => {},
  userIdFromPath = '',
  favoriteNo = 0,
  isLive = false,
  isPin = false,
  profileImage = '',
  userId = '',
  userNick = '',
}) => {
  const handleUserPinClicked = (e) => {
    e.preventDefault();

    if (!handleUserPin) {
      return;
    }

    // WARN_API: 핀 등록을 그냥 user id 로 하면 될 일인데 굳이 favoriteNo, subscribeNo 를 넘겨서 발생한 지저분한 클라이언트 코드
    handleUserPin(favoriteNo, isPin);
  };
  return (
    <>
      <li
        className={classNames({
          checked: userId === userIdFromPath, // 현재 선택된 스트리머
          pin: isPin, // 핀 여부
          favor_live: isLive, // 라이브 여부
        })}>
        <Link to={`/my/feed/${userId}`}>
          <span className='thum'>
            <img src={profileImage} alt='프로필' />
            <i className='favor_live'>라이브</i>
          </span>
          <p>{userNick}</p>
        </Link>
        <button
          type='button'
          className='btn-pin'
          onClick={handleUserPinClicked}>
          고정해제
        </button>
      </li>
    </>
  );
};

export default FavoriteListItem;
