import globalDomains from '@/utils/global-domains';

//스포츠를 제외한 카테배너는 하드코딩되어있다....
export const CATE_BANNER = {
  bora: {
    no: '',
    element: [
      {
        bgColor: '#dbc8ff',
        exposePos: 'LEFT',
        imagePath: `${globalDomains.RES_AFREECATV}/images/afmain/banner_bora_new.png`,
        link: globalDomains.BORA_AFREECATV,
        target: 'Y',
      },
      {
        bgColor: '#e98550',
        exposePos: 'RIGHT',
        imagePath: `${globalDomains.RES_AFREECATV}/images/afmain/banner_bjguide.png`,
        link: globalDomains.BJGUIDE_AFREECATV,
        target: 'N',
      },
    ],
  },
  bora_music: {
    no: '00030001,00030002,00030000',
    element: [
      {
        bgColor: '#e95050',
        exposePos: 'LEFT',
        imagePath: `${globalDomains.RES_AFREECATV}/images/afmain/banner_bora_music.png`,
        link: `${globalDomains.BJ_AFREECATV}/afmusician/posts/88914251`,
        target: 'Y',
      },
    ],
  },
  bora_pet: {
    no: '00580000',
    element: [
      {
        bgColor: '#31bee5',
        exposePos: 'LEFT',
        imagePath: `${globalDomains.RES_AFREECATV}/images/afmain/banner_bora_pet.png`,
        link: `${globalDomains.BJ_AFREECATV}/afpetbj`,
        target: 'Y',
      },
    ],
  },
  bora_hobby: {
    no: '00020007',
    element: [
      {
        bgColor: '#8a50e9',
        exposePos: 'LEFT',
        imagePath: `${globalDomains.RES_AFREECATV}/images/afmain/banner_bora_hobby.png`,
        link: `${globalDomains.BJ_AFREECATV}/afinterest`,
        target: 'Y',
      },
    ],
  },
  bora_art: {
    no: '00020009',
    element: [
      {
        bgColor: '#5074e9',
        exposePos: 'LEFT',
        imagePath: `${globalDomains.RES_AFREECATV}/images/afmain/banner_bora_art.png`,
        link: `${globalDomains.BJ_AFREECATV}/afcharacter`,
        target: 'Y',
      },
    ],
  },
};

//광고 요청 타입
export const BANNER_REQUEST_TYPE = {
  MAIN_BANNER: 'MAIN_BANNER', //메인배너
  SUB_BANNER: 'SUB_BANNER', //서브배너
};

//광고 응답 타입
export const BANNER_RESPONSE_TYPE = {
  PC_MAIN_VIDEO: 'PC_MAIN_VIDEO', //메인 이미지 배너
  PC_MAIN_IMAGE: 'PC_MAIN_IMAGE', //메인 비디오 배너
  PC_728_90_BANNER: 'PC_728_90_BANNER', //서브 이미지 배너
  PC_SUB_BANNER_EXPANDED_IMAGE: 'PC_SUB_BANNER_EXPANDED_IMAGE', //서브 확장형 이미지 배너
  PC_SUB_BANNER_EXPANDED_VIDEO: 'PC_SUB_BANNER_EXPANDED_VIDEO', //서브 확장형 비디오 배너
  ERROR: 'ERROR',
};

export const BANNER_INIT_DATA = {
  type: '', //광고 형태 타입
  exist: false, //광고 존재 여부
  creatives: [
    {
      advertiser: '', //광고주
      profileImageUrl: '', //광고 프로필 이미지
      openInExternalBrowser: 1, //_blank 로보낼지 스킴으로 보낼지 판단하는 여부
      imageUrl: '', //기본 이미지 주소
      backgroundColor: '', //기본 이미지 배경 스타일
      expandedImageUrl: '', //확장 이미지 주소
      expandedBackgroundColor: '', //확장 이미지 배경 스타일
      alternativeImageUrl: '', //플레이어 영상 노출 불가할때 대체 이미지
      landingUrl: '', // 랜딩 주소
      impressionUrls: [''], //로드 되었을때 전송하는 트래킹 주소
      clickUrls: [''], //외부 광고주가 직접 서드파티를 통해서 트래킹 추적 로그를 심을수있기때문에 배열형태  ['우리 트래킹','외부 트래킹'] 이런식으로 될수있음
      closeUrl: '', //작게보기,닫기 클릭시 트래킹 주소
      errorUrl: '', //이미지 에러 났을때 트래킹 주소
      subClickUrls: [''], //리마인드 배너 클릭 트래킹 주소
      subImpressionUrls: [''], //리마인드 배너 로드 되었을때 전송하는 트래킹 주소
      subViewableUrls: [''], //라마인드 영역 50% && 1초 이상 재생 시
      vast: '', //메인 영상 주소
      vast2: '', //리마인드 영상 주소
      videoPlacement: '', //영상 어디에 띄울지 여부 ex ) 오른쪽,왼쪽
      viewableUrls: [''], //메인 영역 50% && 1초 이상 재생 시
    },
  ],
};
