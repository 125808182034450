import LogApi from '@/api/common/log';
import {isEmpty} from 'lodash';
import {ROUTE} from '@/constants/main/route';
import {getCookie} from '@/utils/main/index';
import {cookie} from '@afreecatv/domains';
import {getDeviceType, getOS} from '@/utils';

/**
 * MY 아바타 영역 클릭 로그 요청
 * @param {boolean} isLive - 방송중 여부
 */
export const sendMyAvatarClickLog = (isLive) => {
  const logDetails = {
    [ROUTE.MY.FAVORITE]: {logLocation: 'my_fav', buttonType: 'fav_list'},
    [ROUTE.MY.SUBSCRIBE]: {logLocation: 'my_subs', buttonType: 'subs_list'},
    [ROUTE.MY.FAN]: {logLocation: 'my_fan', buttonType: 'fan_list'},
  };

  const pathname = location.pathname;
  const {logLocation, buttonType} = logDetails[pathname] || {};

  if (logLocation && buttonType) {
    const params = {
      code_type: 'top_bj_list',
      sendData: {
        actionType: isLive ? 'to_live' : 'to_station',
        buttonType,
        location: logLocation,
      },
    };

    LogApi.sendClickLog(params);
  }
};

/**
 * MY 스트리머 관리 클릭 로그 요청
 */
export const sendMyStreamerSettingClickLog = () => {
  const searchParams = new URLSearchParams(location.search);
  const groupId = searchParams?.get('groupId');

  const codeTypeMapping = {
    [ROUTE.MY.FAVORITE]: isEmpty(groupId) ? 'my_fav_more' : 'my_group_more',
    [ROUTE.MY.SUBSCRIBE]: 'my_sub_more',
    [ROUTE.MY.FAN]: 'my_fan_more',
  };

  const pathname = location.pathname;
  const codeType = codeTypeMapping[pathname];

  if (codeType) {
    const params = {
      code_type: codeType,
      sendData: {
        buttonType: 'more_btn_bj',
      },
    };

    LogApi.sendClickLog(params);
  }
};

/**
 * MY 스트리머 관리 프로필 클릭 로그
 * @param {boolean} isLive
 */
export const sendMyStreamerSettingProfileClickLog = (isLive) => {
  const searchParams = new URLSearchParams(location.search);
  const groupId = searchParams?.get('groupId');

  const logDetails = {
    [ROUTE.MY.FAVORITE]: {
      logLocation: isEmpty(groupId) ? 'my_fav' : 'my_group',
      buttonType: isEmpty(groupId) ? 'fav_list' : 'group_list',
    },
    [ROUTE.MY.SUBSCRIBE]: {logLocation: 'my_subs', buttonType: 'subs_list'},
    [ROUTE.MY.FAN]: {logLocation: 'my_fan', buttonType: 'fan_list'},
  };

  const pathname = location.pathname;
  const {logLocation, buttonType} = logDetails[pathname] || {};

  if (logLocation && buttonType) {
    const params = {
      code_type: 'more_bj_list',
      sendData: {
        location: logLocation,
        buttonType,
        actionType: isLive ? 'to_live' : 'to_station',
      },
    };

    LogApi.sendClickLog(params);
  }
};

/**
 * LIVE 유입 경로 로그 보내기
 * @param {Object} params - 파라미터들
 * @param {string} params.path1 - 진입 경로 1
 * @param {string} params.path2 - 진입 경로 2
 * @param {string} params.path3 - 진입 경로 3
 * @param {string} [params.directoryAdminId] - 어드민 내 'ID' 필드에 있는 값을 보냄
 * @param {string} [params.contentsType] - 영상 타입 확인
 * @param {string} params.viewSessionPrefix - listViewSession 프리픽스
 * @param {number} params.broadCateNo - live 방송의 카테고리 번호
 * @param {number} [params.directoryCateId] - 카테고리에 등록된 메뉴명을 그대로 보냄
 * @param {string[]} params.categoryTags - 카테고리에 고정으로 등록된 태그
 * @param {string[]} params.hashTags - 유저가 설정한 태그
 * @param {string} [params.tjoin] - 입장 시간
 */
export const sendLiveInflowPathLog = (params) => {
  const {viewSessionPrefix, categoryTags, hashTags} = params;

  const userId = getCookie(cookie.BBS_COOKIE_NAME);
  const uuid = getCookie('_au');
  const sessionId = userId || uuid;
  const currentTimestamp = Date.now();
  const listViewSession = `${viewSessionPrefix}_${sessionId}_${currentTimestamp}`;

  // 태그를 전송할 땐 | 로 묶어서 string 으로 보낸다
  const categoryTag = isEmpty(categoryTags) ? '' : categoryTags.join('|');
  const tag = isEmpty(hashTags) ? '' : hashTags.join('|');

  const logParams = {
    ...params,
    listViewSession,
    ...(categoryTag && {categoryTag}),
    ...(tag && {tag}),
  };

  // string 으로 만들기 위해 들어있었던 속성을 삭제
  delete logParams.categoryTags;
  delete logParams.hashTags;
  delete logParams.viewSessionPrefix;

  LogApi.sendLog({
    sztype: 'INFLOW_PATH',
    codeType: 'live',
    sendData: {...logParams},
  });
};

/**
 * 신규 유입로그가 아닌 기존에 보내던 LIVE/VOD 유입로그
 *
 * @desc 기본적으로 추가하는 로그 스펙이 달라서 신규와 분리시켰다
 * @desc 만약 다른 인자를 더 추가해야 한다면 그냥.. 인자에 넣어주는 객체에 키와 값을 추가해 주면 됨 (예시:: tag: ['test','test2'])
 *
 * @param {Object} params
 * @param {number} params.broadcastId - 방송 번호 (LIVE: broadNo / VOD: titleNo)
 * @param {string} params.streamerId - 방송하는 스트리머의 유저 id
 * @param {'live' | 'vod'} [params.type='live'] - 방송 유형
 * @param {Object} [params.moreData={}] - 추가 정보
 * @param {Object} params.isLogSendData - 같은 pathName 에서 신규로그만 들어가는 로그를 쓰다보니깐 path1,2,3값들을 구분하기 불가능해서..추가함..
 */
export const sendLegacyInflowPathLog = ({
  broadcastId,
  streamerId,
  type = 'live',
  moreData = {},
  isLogSendData = {},
}) => {
  // 로그 전송 경로 리스트
  const logPaths = [
    ROUTE.INDEX,
    ROUTE.MY.FAVORITE,
    ROUTE.MY.SUBSCRIBE,
    ROUTE.MY.FAN,
    ROUTE.MY.HISTORY.RECENT.INDEX,
    ROUTE.MY.HISTORY.PLAYLIST,
    ROUTE.MY.FEED.INDEX,
    ROUTE.SEARCH,
    ROUTE.DIRECTORY.CATEGORY._CATEGORY_MENU,
    ROUTE.MY.HISTORY.LATER,
    ROUTE.MY.HISTORY.UP,
  ];

  // 해당 경로를 포함하고 있지 않으면 로그 전송하지 않음
  if (
    !logPaths.some((path) => {
      if (path.includes(':')) {
        const regex = new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`);
        return regex.test(location.pathname);
      }
      return location.pathname.startsWith(path);
    })
  ) {
    return;
  }

  const uuid = getCookie('_au');
  const userId = getCookie(cookie.BBS_COOKIE_NAME);
  const sessionId = userId || uuid;
  const middleValue = broadcastId || streamerId;
  const currentTime = Date.now();
  const pathKey = `${uuid}_${middleValue}_${currentTime}`;

  const isLive = type === 'live';

  const {
    groupType,
    groupName,
    isStory,
    hashTags,
    categoryTags,
    listDataType,
    contentsType,
    isCatch,
    skey,
    storyNo,
    sysType,
    directoryAdminId,
    directoryCateId,
    broadCateNo,
    exCategoryNo,
    isUserClip,
  } = moreData;

  const basePathConfig = {
    [ROUTE.MY.FAVORITE]: {
      path1: 'my',
      path2: 'fav',
      path3: (() => {
        if (isUserClip) return 'clip';
        if (isLive) return 'onair';
        if (isStory) return 'fav_story';
        if (isCatch) return 'catch';
        return 'vod';
      })(),
    },
    [ROUTE.MY.SUBSCRIBE]: {
      path1: 'my',
      path2: 'subbj',
      path3: isLive ? 'onair' : 'vod',
    },
    [ROUTE.MY.FAN]: {
      path1: 'my',
      path2: 'fanbj',
      path3: isLive ? 'onair' : 'vod',
    },
    [ROUTE.MY.HISTORY.RECENT.INDEX]: {
      path1: 'my',
      path2: 'history',
      path3: 'recent',
    },
    [ROUTE.MY.HISTORY.LATER]: {
      path1: 'my',
      path2: 'history',
      path3: 'afterward',
    },
    [ROUTE.MY.HISTORY.UP]: {
      path1: 'my',
      path2: 'history',
      path3: 'up',
    },
    [ROUTE.MY.HISTORY.PLAYLIST]: {
      path1: 'my',
      path2: 'history',
      path3: 'playlist',
    },
    [ROUTE.MY.FEED.INDEX]: {
      path1: 'my',
      path2: 'feed',
      path3: 'bj',
    },
    [ROUTE.SEARCH]: {
      path1: 'sch',
      path2: 'sch',
      path3: isStory ? 'story' : isCatch ? 'catch' : 'total',
      path4: '',
    },
    [ROUTE.DIRECTORY.CATEGORY._CATEGORY_MENU]: {
      path1: 'directory',
      path2: 'admin',
      path3: isLive ? 'live' : isCatch || isStory ? 'catch' : 'vod',
    },
    [ROUTE.DIRECTORY.CATEGORY._CATEGORY_NAME._CATEGORY_TYPE]: {
      path1: 'directory',
      path2: 'cate',
      path3: isLive ? 'live' : isCatch || isStory ? 'catch' : 'vod',
    },
    [ROUTE.LIVE.ALL]: {
      path1: 'main',
      path2: 'list',
      path3: 'total',
    },
    [ROUTE.LIVE.GROUP._TYPE]: {
      path1: 'page_hash',
      path2:
        ['game', 'bora', 'sports'].find((path) =>
          location.pathname.includes(path),
        ) || null,
    },
    [ROUTE.INDEX]: {
      path1: 'myplus',
      path2: isStory ? 'story' : isCatch ? 'catch' : 'list',
    },
  };

  const additionalPathConfig = {
    [ROUTE.MY.FAVORITE]: {
      ...(groupType && {groupType}),
      ...(groupName && {groupName}),
      ...(Object.keys(isLogSendData).length > 0 && {
        listViewSession: `my_top_${sessionId}_${currentTime}`,
      }),
    },
    [ROUTE.INDEX]: {
      ...(!isEmpty(hashTags) && {tag: hashTags}),
      ...(!isEmpty(categoryTags) && {categoryTag: categoryTags}),
      ...(listDataType && {listDataType}),
      ...(Object.keys(isLogSendData).length > 0 && {
        listViewSession: `${isCatch ? 'catch' : 'home'}_${sessionId}_${currentTime}`,
      }),
      ...(isCatch && {
        catchClientSessionId: `catch_${sessionId}_${currentTime}`,
      }),
    },
    [ROUTE.LIVE.ALL]: {
      ...(!isEmpty(hashTags) && {tag: hashTags}),
      ...(!isEmpty(categoryTags) && {categoryTag: categoryTags}),
      ...(isLive && broadcastId && {broadNo: broadcastId}),
    },
    [ROUTE.LIVE.GROUP._TYPE]: {
      ...(!isEmpty(hashTags) && {tag: hashTags}),
      ...(!isEmpty(categoryTags) && {categoryTag: categoryTags}),
      ...(isLive && broadcastId && {broadNo: broadcastId}),
    },
    [ROUTE.SEARCH]: {
      ...(skey && {skey}),
      ...(storyNo && {storyNo}),
      ...(!isEmpty(hashTags) && {tag: hashTags}),
      ...(!isEmpty(categoryTags) && {categoryTag: categoryTags}),
    },
    [ROUTE.MY.FEED.INDEX]: {
      ...(sysType && {sysType: getDeviceType() === 'mobile' ? 'webm' : 'web'}),
    },
    [ROUTE.DIRECTORY.CATEGORY._CATEGORY_MENU]: {
      ...(!isEmpty(hashTags) && {tag: hashTags}),
      ...(!isEmpty(categoryTags) && {categoryTag: categoryTags}),
      ...(directoryAdminId && {directoryAdminId}),
      ...(isStory || isCatch
        ? exCategoryNo && {exCategoryNo}
        : broadCateNo && {broadCateNo}),
      ...(contentsType && {contentsType}),
      listViewSession: `admin_${sessionId}_${currentTime}`,
      ...(isCatch && {
        catchClientSessionId: `catch_admin_${sessionId}_${currentTime}`,
      }),
    },
    [ROUTE.DIRECTORY.CATEGORY._CATEGORY_NAME._CATEGORY_TYPE]: {
      ...(!isEmpty(hashTags) && {tag: hashTags}),
      ...(!isEmpty(categoryTags) && {categoryTag: categoryTags}),
      ...(directoryCateId && {directoryCateId}),
      ...(isStory || isCatch
        ? exCategoryNo && {exCategoryNo}
        : broadCateNo && {broadCateNo}),
      ...(contentsType && {contentsType}),
      listViewSession: `cate_${sessionId}_${currentTime}`,
      ...(isCatch && {
        catchClientSessionId: `catch_cate_${sessionId}_${currentTime}`,
      }),
    },
  };

  const matchedBasePath = Object.keys(basePathConfig).find((path) => {
    if (path.includes(':')) {
      const regex = new RegExp(`^${path.replace(/:\w+/g, '[^/]+')}$`);
      return regex.test(location.pathname);
    }
    return location.pathname.startsWith(path);
  });

  if (!matchedBasePath) {
    return;
  }

  //신규 로그인데 같은 도메인을 사용할경우, isLogSendData 값으로 강제로 지정
  if (Object.keys(isLogSendData).length > 0) {
    basePathConfig[matchedBasePath] = {
      ...basePathConfig[matchedBasePath],
      ...isLogSendData,
    };
  }

  const pathConfig = {
    ...basePathConfig[matchedBasePath],
    ...additionalPathConfig[matchedBasePath],
  };

  LogApi.sendLog({
    sztype: 'INFLOW_PATH',
    codeType: isLive ? 'LIVE' : 'VOD',
    pathKey,
    sendData: {
      os: getOS(),
      bjId: streamerId,
      [isLive ? 'broadNo' : isStory ? 'storyNo' : 'titleNo']: broadcastId,
      referer: location.href,
      ...pathConfig,
    },
  });
};

/**
 * VOD 유입 경로 로그 보내기
 * @param {Object} params - 파라미터들
 * @param {string} params.path1 - 진입 경로 1
 * @param {string} params.path2 - 진입 경로 2
 * @param {string} params.path3 - 진입 경로 3
 * @param {string} [params.directoryAdminId] - 어드민 내 'ID' 필드에 있는 값을 보냄
 * @param {string} [params.contentsType] - 영상 타입 확인
 * @param {string} params.viewSessionPrefix - vodViewSession 프리픽스
 * @param {number} params.exCategoryNo -  vod의 카테고리 번호
 * @param {number} [params.directoryCateId] - 카테고리에 등록된 메뉴명을 그대로 보냄
 * @param {string[]} params.categoryTags - 카테고리에 고정으로 등록된 태그
 * @param {string[]} params.hashTags - 유저가 설정한 태그
 * @param {string} [params.tjoin] - 입장 시간
 */
export const sendVodInflowPathLog = (params) => {
  const {viewSessionPrefix, directoryCateId, categoryTags, hashTags} = params;

  const userId = getCookie(cookie.BBS_COOKIE_NAME);
  const uuid = getCookie('_au');
  const sessionId = userId || uuid;
  const currentTimestamp = Date.now();
  const vodViewSession = `${viewSessionPrefix}_${sessionId}_${currentTimestamp}`;

  // 태그를 전송할 땐 | 로 묶어서 string 으로 보낸다
  const categoryTag = isEmpty(categoryTags) ? '' : categoryTags.join('|');
  const tag = isEmpty(hashTags) ? '' : hashTags.join('|');

  const logParams = {
    ...params,
    vodViewSession,
    ...(categoryTag && {categoryTag}),
    ...(tag && {tag}),
    ...(directoryCateId && {directoryCateId}),
  };

  // string 으로 만들기 위해 들어있었던 속성을 삭제
  delete logParams.categoryTags;
  delete logParams.hashTags;
  delete logParams.viewSessionPrefix;

  LogApi.sendLog({
    sztype: 'INFLOW_PATH',
    ...logParams,
  });
};

/**
 * 유저 아바타 핀 클릭 로그
 * @param {Object} params
 * @param {boolean} params.isAddPin - 핀 추가 플래그
 * @param {string} params.streamerId
 */
export const sendPinClickLog = ({isAddPin, streamerId}) => {
  const locationMap = {
    [ROUTE.MY.FAVORITE]: 'favorite',
    [ROUTE.MY.SUBSCRIBE]: 'subsbj',
    [ROUTE.MY.FAN]: 'fanbj',
  };

  LogApi.sendClickLog({
    codeType: 'main_00000008',
    sendData: {
      location: locationMap[window.location.pathname] || '',
      actionType: isAddPin ? 'add_fix' : 'del_fix',
      bjId: streamerId,
    },
  });
};

/**
 * 라이브 방송 썸네일 클릭 로그
 * @param {Object} params
 * @param {string} params.streamerId - 방송 스트리머 ID
 * @param {number} params.broadcastId - 방송 번호 (broadNo)
 * @param {number} params.broadCateNo - 방송 카테고리 번호
 * @param {number} params.broadBps - 방송 bps
 * @param {number} params.broadResolution - 방송 사이즈
 */
export const sendLiveThumbnailClickLog = ({
  streamerId,
  broadcastId,
  broadCateNo, // TODO:/my/feed 에서 undefined로 보내주고 있음. 추후 수정 필요
  broadBps, // TODO:/my/feed 에서 undefined로 보내주고 있음. 추후 수정 필요
  broadResolution, // TODO:/my/feed 에서 undefined로 보내주고 있음. 추후 수정 필요
}) => {
  // 로그 전송 경로 리스트
  const logPaths = [
    ROUTE.MY.FAVORITE,
    ROUTE.MY.SUBSCRIBE,
    ROUTE.MY.FAN,
    ROUTE.MY.HISTORY.RECENT.INDEX,
    ROUTE.MY.FEED.INDEX,
  ];

  // 해당 경로를 포함하고 있지 않으면 로그 전송하지 않음
  if (!logPaths.some((path) => location.pathname.startsWith(path))) {
    return;
  }

  const sendData = {
    watchType: 'live',
    bjId: streamerId,
    broadNo: broadcastId,
    categoryNo: broadCateNo,
    bps: broadBps,
    resolution: broadResolution,
  };

  // 경로와 추가 로그 정보 매핑
  const pathConfigMap = {
    [ROUTE.MY.FAVORITE]: {codeType: 'main_00000006'},
    [ROUTE.MY.SUBSCRIBE]: {codeType: 'main_00000007', location: 'subsbj'},
    [ROUTE.MY.FAN]: {codeType: 'main_00000007', location: 'fanbj'},
    [ROUTE.MY.HISTORY.RECENT.INDEX]: {
      codeType: 'main_00000007',
      location: 'recent',
    },
    [ROUTE.MY.FEED.INDEX]: {codeType: 'main_00000006'},
  };

  const pathConfig = pathConfigMap[location.pathname] || {};

  const logParams = {
    codeType: pathConfig.codeType || '',
    sendData: {
      ...sendData,
      ...(pathConfig.location && {location: pathConfig.location}),
    },
  };

  LogApi.sendClickLog(logParams);
};

/**
 * VOD 방송 썸네일 클릭 로그
 * @param {Object} params
 * @param {string} params.streamerId - 방송 스트리머 ID
 * @param {number} params.vodNumber - 방송 번호 (titleNo)
 * @param {string} params.fileType - 방송 타입
 */
export const sendVodThumbnailClickLog = ({streamerId, vodNumber, fileType}) => {
  const logSendPaths = [
    ROUTE.MY.FAVORITE,
    ROUTE.MY.SUBSCRIBE,
    ROUTE.MY.FAN,
    ROUTE.MY.HISTORY.RECENT.VOD,
    ROUTE.MY.HISTORY.LATER,
    ROUTE.MY.HISTORY.UP,
  ];

  if (!logSendPaths.includes(location.pathname)) {
    return;
  }

  const sendData = {
    watchType: 'vod',
    bjId: streamerId,
    titleNo: vodNumber,
    vtype: fileType,
  };

  // 경로와 추가 로그 정보 매핑
  const pathConfigMap = {
    [ROUTE.MY.FAVORITE]: {codeType: 'main_00000006'},
    [ROUTE.MY.SUBSCRIBE]: {codeType: 'main_00000007', location: 'subsbj'},
    [ROUTE.MY.FAN]: {codeType: 'main_00000007', location: 'fanbj'},
    [ROUTE.MY.HISTORY.RECENT.VOD]: {
      codeType: 'my_history_recent',
    },
    [ROUTE.MY.HISTORY.LATER]: {
      codeType: 'my_history_afterward',
    },
    [ROUTE.MY.HISTORY.UP]: {codeType: 'my_history_up'},
  };

  const pathConfig = pathConfigMap[location.pathname] || {};

  const logParams = {
    codeType: pathConfig.codeType || '',
    sendData: {
      ...sendData,
      ...(pathConfig.location && {location: pathConfig.location}),
    },
  };

  LogApi.sendClickLog(logParams);
};

/**
 * 캐치 썸네일 클릭 로그
 * @param {Object} params
 * @param {string} params.streamerId - 방송 스트리머 ID
 * @param {number} params.vodNumber - 캐치 번호 (titleNo)
 * @param {string} params.fileType - 캐치 파일 타입
 */
export const sendCatchClickLog = ({streamerId, catchId, fileType}) => {
  LogApi.sendClickLog({
    codeType: 'main_00000006',
    sendData: {
      watchType: 'vod',
      bjId: streamerId,
      titleNo: catchId,
      vtype: fileType,
    },
  });
};

/**
 * 즐겨찾기 그룹 추가 버튼 클릭 로그
 * @param {Object} params
 * @param {'create_method1' | 'create_method2' | 'create_method3' | 'create_save' | 'delete_group'} params.buttonType - 추가 버튼은 'create_method1', 편집에서는 'create_method2', 즐겨찾기 담기에서는 'create_method3', 실제 저장은 'create_save'
 */
export const sendCreateFavoriteGroupClickLog = ({buttonType}) => {
  LogApi.sendClickLog({
    codeType: 'fav_grouping',
    sendData: {
      buttonType,
      location: 'favorite',
    },
  });
};

/**
 * 즐겨찾기 그룹 탭 클릭 로그
 * @param {Object} params
 * @param {string} params.groupId - 그룹 ID
 * @param {string} params.groupName - 그룹 이름
 */
export const sendGroupTabClickLog = ({groupId, groupName}) => {
  LogApi.sendClickLog({
    codeType: 'fav_grouping',
    sendData: {
      buttonType: groupId === '' ? 'group_all' : 'group_custom',
      location: 'favorite',
      groupName: groupName,
    },
  });
};

/**
 * @description VOD 재생 로그 전송
 * @param {Object} params - 파라미터
 * @param {number} params.stationNo - 방송국 번호
 * @param {number} params.titleNo - VOD 번호
 * @param {string} params.bjId - BJ 아이디
 * @param {string} params.category - 카테고리
 * @param {string} params.part - 파트
 * @param {boolean} params.isAutoplay - 자동재생 여부
 * @param {string} params.path1 - 경로1
 * @param {string} params.path2 - 경로2
 * @param {number} params.videoStart - 비디오 시작 시간
 * @param {boolean} params.isPreview - 미리보기 여부
 * @returns
 */
export const sendVodPlayLog = ({
  stationNo = 0,
  titleNo = 0,
  bjId = '',
  category = '',
  part = '',
  isAutoplay = false,
  path1 = '',
  path2 = '',
  videoStart = 0,
  isPreview = false,
}) => {
  LogApi.sendVodPlayLog({
    stationNo,
    titleNo,
    bjId,
    category,
    systemType: getDeviceType() === 'mobile' ? 'webm' : 'web',
    part,
    isAutoplay,
    path1,
    path2,
    videoStart,
    isPreview,
  });
};
