import MyApi from '@/api/my';
import STApi from '@/api/st';

const NICKNAME_ACTION_TYPE = {
  check: 'SubscriptionNickCheck',
  default: 'SubscriptionNickInit',
  change: 'SubscriptionNickUpdate',
};

/**
 * @typedef {Object} SubscribeStreamer
 * @property {Object[]} broadInfo 라이브 중인 방송정보
 * @property {string} defaultNick 기본 닉네임
 * @property {string} expirePaymentDate 만료일
 * @property {string} firstPaymentDate 최초 결제일
 * @property {boolean} isFanclub 팬클럽 여부
 * @property {boolean} isFavorite 즐겨찾기 여부
 * @property {boolean} isLive 라이브 여부
 * @property {boolean} isPin 핀 여부
 * @property {boolean} isSubscribe 구독 여부
 * @property {string} lastBroadStart 마지막 방송 시작일
 * @property {number} paymentCount 결제 횟수
 * @property {string} stationName 방송사
 * @property {string} subNick 서브 닉네임
 * @property {number} subscribeNo 구독 번호
 * @property {number} totalViewCnt 총 시청자수
 * @property {string} userId 사용자 ID
 * @property {string} userNick 사용자 닉네임
 */

const MySubscribeApi = {
  /**
   * 구독중인 스트리머 목록을 조회
   * @returns {Promise<SubscribeStreamer[]>}
   */
  getSubscribes: async () => {
    try {
      const {data: subscribes, totalCnt: totalCount} =
        await MyApi.get('api/subscribe');
      return {subscribes: subscribes || [], totalCount};
    } catch (error) {
      return {subscribes: [], totalCount: 0};
    }
  },
  /**
   * 구독 스트리머 고정
   * @desc 즐겨찾기 스트리머랑 뭐가 다르길래 각각 있는지는 모르겠지만 아무튼 각각 있다 진짜 너무 속상하다
   * @param {string} userId - BJ 사용자 아이디
   */
  async setPin(userId) {
    try {
      const formData = new FormData();
      formData.append('common_no', userId);
      const data = await MyApi.post('/api/subscribe/pin', formData);
      return data;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 구독 스트리머 고정 해제
   * @desc 즐겨찾기 스트리머랑 뭐가 다르길래 각각 있는지는 모르겠지만 아무튼 각각 있다 진짜 너무 속상하다
   * @param {string} userId - BJ 사용자 아이디
   */
  async deletePin(userId) {
    try {
      const formData = new FormData();
      formData.append('common_no', userId);
      formData.append('_method', 'delete');
      const data = await MyApi.post(`/api/subscribe/pin/${userId}`, formData);
      return data;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 구독한 BJ 의 VOD 조회
   * @returns {Promise<Array>} favoriteGroups
   */
  async getVod() {
    try {
      const {data: subscribeVod} = await MyApi.get('/api/subscribe/vod');
      return subscribeVod || [];
    } catch (error) {
      return [];
    }
  },
  /**
   * 닉네임 관련 액션 함수
   * @desc 중복 체크, 변경, 디폴트로 변경
   * @param {Object} params
   * @param {string} params.userId
   * @param {string} params.bjId
   * @param {string} params.nickname
   * @param {'check' | 'default' | 'change'} type
   * @return {Promise<{result: number; message: string; useUpdate: number;}>}
   */
  async postNicknameAction(params, type) {
    try {
      const {userId, bjId, nickname} = params;

      const data = await STApi.post(
        '/api/nickname_api.php',
        {
          szWork: NICKNAME_ACTION_TYPE[type],
          szUId: userId,
          szBjId: bjId,
          szNick: nickname,
          szType: 'json',
        },
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      return data;
    } catch (e) {
      throw error;
    }
  },
};

export default MySubscribeApi;
