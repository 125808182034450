export const SEO_TITLE = {
  '': '',
  all: '전체 LIVE - ',
  game: '게임 LIVE - ',
  bora: '보이는 라디오 LIVE - ',
  sports: '스포츠 LIVE - ',
  // player: 'Catch - ',
  category: '카테고리 전체 - ',
  my: 'MY - ',
  favorite: '즐겨찾기 - ',
  subscribe: '구독 - ',
  directory: '카테고리 전체 - ',
  fan: '팬클럽 - ',
  recent: '기록 - ',
  history: '기록 - ',
  later: '기록 - ',
  up: '기록 - ',
  playlist: '기록 - ',
  vod: '기록 - ',
  story: '내 글/댓글 - ',
  feed: '채널 새 글 - ',
  hotissue: '실시간 인기글 - ',
};

export const SEO_KEYWORD = {
  '': 'SOOP, 스트리머, 인터넷 방송, 개인 방송, 라이브, 라이브 방송',
  live: 'SOOP, 스트리머, 라이브, 생방송',
  // catch: 'SOOP, 스트리머, 숏폼, 쇼츠, 클립, VOD',
  my: 'SOOP, 스트리머, 팔로우, 팬클럽, 구독, 인기',
  directory:
    'SOOP, 스트리머, 인터넷 방송, 카테고리, LIVE 카테고리, 콘텐츠 탐색',
  totalSearch: 'SOOP, 스트리머 검색, 검색, 키워드 검색, LIVE 검색',
  tagSearch: 'SOOP, 스트리머 검색, 태그 검색, 해쉬 태그 검색, 장르 검색',
};

export const SEO_DESCRIPTION = {
  '': '콘텐츠로 소통하는 무한한 가능성의 공간, SOOP입니다.',
  all: '다양한 콘텐츠로 방송하는 SOOP의 스트리머들을 LIVE에서 만나보세요.',
  game: '다양한 콘텐츠로 방송하는 SOOP의 스트리머들을 LIVE에서 만나보세요.',
  bora: '다양한 콘텐츠로 방송하는 SOOP의 스트리머들을 LIVE에서 만나보세요.',
  sports: '다양한 콘텐츠로 방송하는 SOOP의 스트리머들을 LIVE에서 만나보세요.',
  catch: 'LIVE의 재미와 감동을 SOOP의 숏폼인 Catch에서 다시 한 번 느껴보세요.',
  directory: 'SOOP의 다양한 콘텐츠를 더욱 빠르고 쉽게 확인해보세요.',
  favorite:
    '자주 찾는 SOOP 스트리머들의 콘텐츠를 확인할 수 있는 MY 메뉴입니다.',
  subscribe:
    '자주 찾는 SOOP 스트리머들의 콘텐츠를 확인할 수 있는 MY 메뉴입니다.',
  fan: '자주 찾는 SOOP 스트리머들의 콘텐츠를 확인할 수 있는 MY 메뉴입니다.',
  recent: '내가 시청한 LIVE와 VOD, 내 활동 내역을 확인해보세요.',
  story: '내가 작성한 게시글과 댓글을 확인해보세요.',
  feed: '자주 보는 SOOP 스트리머들의 새로운 게시글을 확인해보세요.',
  hotissue: 'SOOP에서 다른 유저에게 가장 인기 있는 게시글을 확인해보세요.',
  totalSearch:
    'SOOP의 스트리머와 콘텐츠를 키워드로 검색하여 빠르게 찾아보세요.',
  tagSearch: 'SOOP의 다양한 콘텐츠를 콘텐츠 태그로 검색하여 빠르게 찾아보세요.',
};
