import React from 'react';

/**
 * 메인 / 탐색은 데이터 캐싱이 타는 문제가 있어서 ErrorBoundary 에서 콘솔만 찍게끔 추가
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // 에러가 발생하면 state 업데이트
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    console.error(
      '컴포넌트 그리던 중 발생한 클라이언트 에러 :',
      error,
      errorInfo,
    );
  }

  render() {
    if (this.state.hasError) {
      // 오류가 발생한 경우 대체 UI 렌더링 또는 null 반환
      return this.props.fallback || null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
