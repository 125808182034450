'use client';

import {useState, useRef, useEffect} from 'react';
import {useOutsideClick} from '@/components/main/common/hooks/useOutsideClick';
import classNames from 'classnames';
import useDialogStore from '@/stores/ui/dialogs';

const OPTION_HEIGHT = 36;

/**
 * @typedef {Object} Option
 * @property {string} label - SoopUiSelectBox 옵션의 라벨
 * @property {string} type - SoopUiSelectBox 옵션의 값 (TODO: 추후 value 로 이름을 바꾸는 것이 나을 듯 싶습니다)
 * @property {string} className - SoopUiSelectBox 옵션의 클래스 명
 */

/**
 *
 * @param {Object} props - SoopUiSelectBox props
 * @param {Option[]} props.options - SoopUiSelectBox 옵션
 * @param {string} props.type - SoopUiSelectBox 선택된 값 (TODO: 추후 value 로 이름을 바꾸는 것이 나을 듯 싶습니다)
 * @param {function} props.onChange - SoopUiSelectBox 값 변경 이벤트 핸들러
 * @param {boolean} props.disableOption - 옵션 비활성화 유무
 * @param {boolean} [props.isSelectedOptionHighlight] - 클릭된 옵션을 하이라이트 하는 여부
 * @param {JSX.Element} [props.toggleComponent] - 더보기 버튼 같이 셀렉트 박스의 기본 형태가 아닐 경우 사용
 * @returns {JSX.Element} SoopUiSelectBox
 */
export default function SoopUiSelectBox({
  options,
  disableOption,
  type,
  onChange,
  isSelectedOptionHighlight = true,
  toggleComponent,
}) {
  const [isShow, setIsShow] = useState(false);
  const selectBoxWrapperRef = useRef(null);
  /**
   * 현재 dom 외 외부 클릭 close
   */
  useOutsideClick(selectBoxWrapperRef, () => {
    setIsShow(false);
  });

  const handleClick = () => {
    setIsShow((prev) => !prev);
  };

  const handleItemClick = (type, option) => (event) => {
    event.stopPropagation();
    // 비활성화된 옵션의 경우 클릭 이벤트 무시
    if (type === 'recomm_cnt' && disableOption) {
      return;
    }
    setIsShow((prev) => !prev);
    onChange(type, option);
  };

  const {dialogList} = useDialogStore();

  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    if (!isShow || !selectBoxWrapperRef.current || !dialogList.length) {
      return;
    }

    const selectBoxWrapper = selectBoxWrapperRef.current;
    const layerContainer = selectBoxWrapper.closest('.layer_container');
    const isBottom = isOptionsListBoxAtBottom(
      selectBoxWrapper,
      layerContainer,
      options.length,
    );

    setIsBottom(isBottom);
  }, [isShow]);

  const selectedOption = options.find((option) => option.type === type);
  return (
    <div
      ref={selectBoxWrapperRef}
      className={`select_box_item ${isShow ? 'on' : ''}`}
      onClick={handleClick}>
      {toggleComponent ?? (
        <button
          type='button'
          className={classNames('selected', {
            [selectedOption?.className]: selectedOption?.className,
          })}>
          {selectedOption?.label}
        </button>
      )}
      <ul
        className='select_list'
        style={{
          display: isShow ? '' : 'none',
          ...(isBottom && {
            top: 'auto',
            bottom: `${OPTION_HEIGHT}px`,
          }),
        }}>
        {options.map(
          (option) =>
            !option.isPlaceholder && (
              <li
                key={option.type}
                className={classNames(option.className, {
                  on: isSelectedOptionHighlight && type === option.type,
                  disabled: option.type === 'recomm_cnt' && disableOption,
                })}
                onClick={handleItemClick(option.type, option)}>
                <button type='button'>{option.label}</button>
              </li>
            ),
        )}
      </ul>
    </div>
  );
}

/**
 * 옵션 리스트 박스가 바닥에 생성될 것인지 여부
 * @param {Element} selectBox - selectbox element
 * @param {Element} container - 상대 높이 계산할 껍데기 element
 * @param {number} optionCount - 옵션 갯수 (옵션 높이 계산용)
 * @returns {boolean} 바닥에 있는지 여부
 */
function isOptionsListBoxAtBottom(selectBox, container, optionCount) {
  if (!container) {
    return;
  }

  const threshold = 10; // 여백

  const containerRect = container.getBoundingClientRect();
  const selectBoxRect = selectBox.getBoundingClientRect();
  const optionBoxHeight = OPTION_HEIGHT * optionCount;

  const distanceFromBottom =
    containerRect.bottom - (selectBoxRect.bottom + optionBoxHeight);

  return distanceFromBottom <= threshold;
}
