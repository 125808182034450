import LiveApi from '@/api/live';
import {SchApi} from '../sch';

const DirectoryApi = {
  /**
   * 카테고리 메뉴 어드민 칩 가져오는 api
   * @returns {Object}
   */
  async getCategoryMenu() {
    try {
      const response = await LiveApi.get('/api/explore/get_menu_list.php');
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * 카테고리 리스트 가져오는 api
   * @param {String} m - 'categoryList'
   * @param {Number} nPageNo - 페이지 번호
   * @param {String} szPlatform - PC
   * @returns {Object}
   */
  async getCategoryList(queryParams) {
    try {
      const response = await SchApi.get('/api.php', queryParams);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 카테고리 검색 리스트 가져오는 api
   * @param {String} m - 'categoryList'
   * @param {String} szKeyword - 검색 키워드
   * @param {String} szOrder - view_cnt_desc/view_cnt_asc/reg_date
   * @param {Number} nPageNo - 페이지 번호
   * @param {Number} nlistCnt - 리스트 갯수
   * @param {Number} noffset -  ??
   * @param {String} szPlatform - PC
   * @returns {Object}
   */
  async getSearchCategoryList(queryParams) {
    try {
      const response = await SchApi.get('/api.php', queryParams);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 카테고리 콘텐츠 리스트 가져오는 api
   * @param {String} szMenuId - 메뉴번호
   * @param {String} szType - live,vod,catch (live default)
   * @param {String} szPlatform - PC
   * @returns {Object}
   */
  async getContentsList(queryParams) {
    try {
      const response = await LiveApi.get(
        '/api/explore/get_contents_list.php',
        queryParams,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 스트리머 차단 리스트 가져오는 api
   * @returns {Object}
   */
  async getHiddenStreamerList() {
    try {
      const response = await LiveApi.get(
        '/api/hiddenbj/hiddenbjController.php',
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};
export default DirectoryApi;
