'use client';

import {useEffect, useState} from 'react';

import useFavorite from '@/components/search/common/hooks/useFavorite';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

import {isLogin} from '@/utils/main';
import globalDomains from '@/utils/global-domains';
import MyFavoriteApi from '@/api/my/favorite';
import useFavoriteStore from '@/stores/search';

/**
 * FavoriteButton 즐겨찾기 컴포넌트
 * @param {Object} props - 컴포넌트의 props
 * @param {String} props.userId - 스트리머 ID
 * @param {String} props.favorite - 즐겨찾기 여부
 * @param {String} props.logCallback - 로그 관련 콜백 함수
 * @returns FavoriteButton 즐겨찾기 컴포넌트
 */
const FavoriteButton = ({userId, favorite, logCallback = () => {}}) => {
  const {currentLoginUserId} = useSearchGlobalStatus();
  const {handleAddFavorite, handleDeleteFavorite} = useFavorite();
  const {isFavoriteFlag, toggleFavorite, favoriteStreamerName} =
    useFavoriteStore();

  const [isFavorite, setIsFavorite] = useState(favorite);

  const handleFavorite = async () => {
    logCallback({actCode: 'fav', bjid: userId});

    const currentUrl = window.encodeURIComponent(window.location.href);

    if (!isLogin()) {
      const loginUrl = `${globalDomains.LOGIN_AFREECATV}/afreeca/login.php?szFrom=full&request_uri=${currentUrl}`;
      window.location.href = loginUrl;
      return;
    }

    let success = false;
    if (isFavorite) {
      success = await handleDeleteFavorite(currentLoginUserId, userId);
    } else {
      success = await handleAddFavorite(currentLoginUserId, userId);
    }

    if (success) {
      setIsFavorite(!isFavorite);
      toggleFavorite();
    }
    // 240719 GT : 기획서에 없던 내용인데 임의로 navi를 새로 고침 시켜서 즐찾 목록이 즉시 갱신 되도록 하였다. 이게 멋있으니까
    // 하지만 스테이지 환경에서부터 즐겨찾기 목록이 캐시에 의해 관리되고 있어 갱신이 안되는 관계로 괜히 맨티스 이슈만 생겼다.
    // 그래서 해당 동작을 중지한다. 추후 개선될 여지는 있음
    // globalNavigation.refresh();
  };

  // 스트리머의 즐겨찾기 상태를 컴포넌트 간에 동기화 시키는 메소드
  // ex) 통합검색 > 스트리머 프로필 영역에서 즐겨찾기 눌렀는데
  // 통햅검색 > 스트리머 영역에도 해당 스트리머 데이터가 있는경우 즐겨찾기 유무 동기화
  const synchronizeFavoriteStatus = async () => {
    if (userId !== favoriteStreamerName) {
      return;
    }
    try {
      const isFavorite = await MyFavoriteApi.getIsFavorite(userId);
      setIsFavorite(isFavorite);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  useEffect(() => {
    setIsFavorite(favorite);
  }, [favorite]);

  useEffect(() => {
    synchronizeFavoriteStatus();
  }, [isFavoriteFlag]);

  return (
    <button
      type='button'
      className={`fav ${isFavorite ? 'active' : ''}`}
      onClick={handleFavorite}>
      즐겨찾기
    </button>
  );
};

export default FavoriteButton;
