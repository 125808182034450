'use client';

import {forwardRef, useEffect, useState, useRef} from 'react';
import {throwAlert, throwConfirm, throwDialog} from '@/utils/ui/dialog';
import MyFavoriteApi from '@/api/my/favorite';
import {DragDropContext, Droppable, Draggable} from '@hello-pangea/dnd';
import {getCookie, setCookie} from '@/utils/main';
import useMyStore from '@/stores/my';
import throwToast from '@/utils/ui/toast';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {sendCreateFavoriteGroupClickLog} from '@/utils/main/my/log';

const MyFavoriteGroupEdit = forwardRef(function MyLayer(
  {data, handleClose},
  ref,
) {
  const {
    favoriteGroups,
    getFavoriteGroups,
    getStreamers: getStoreStreamers,
  } = useMyStore();
  const [groups, setGroups] = useState(favoriteGroups);

  const handleEditButtonClick = (groupId) => (event) => {
    event.stopPropagation();
    throwDialog(
      'my/ExtendedNewGroup',
      {isEdit: true, groupId, getFavoriteGroups: data.getFavoriteGroups},
      {isCustomDialog: true, customClassName: 'myFav_group_layer'},
    );
  };

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const paramGroupId = searchParams.get('groupId');

  const handleDeleteButtonClick = (groupId) => async (event) => {
    event.stopPropagation();
    const {data: isOk} = await throwConfirm({
      message: '그룹을 삭제하시겠습니까?',
    });

    if (!isOk) {
      return;
    }

    try {
      await MyFavoriteApi.deleteFavoriteGroup(groupId);

      sendCreateFavoriteGroupClickLog({
        buttonType: 'delete_group',
      });

      await getFavoriteGroups();

      if (Number(groupId) === Number(paramGroupId)) {
        handleClose();
        navigate('/my/favorite?groupId=');
        return;
      }

      getStoreStreamers();
    } catch (e) {
      throwToast('에러가 발생하였습니다.');
    }
  };

  const handleAddGroupButtonClick = async () => {
    sendCreateFavoriteGroupClickLog({
      buttonType: 'create_method2',
    });

    await throwDialog(
      'my/ExtendedNewGroup',
      {getFavoriteGroups: data.getFavoriteGroups},
      {isCustomDialog: true, customClassName: 'myFav_group_layer'},
    );
    getFavoriteGroups();
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newGroups = Array.from(groups);
    const [movedGroup] = newGroups.splice(result.source.index, 1);
    newGroups.splice(result.destination.index, 0, movedGroup);

    setGroups(newGroups);
    updateGroupSort(newGroups);
  };

  useEffect(() => {
    const groupEditWarn = getCookie('my_warn_group_edit');

    if (!groupEditWarn) {
      throwAlert({
        message: '즐겨찾기 그룹의 순서는 모바일과 다르게 노출될 수 있습니다.',
      });
      setCookie('my_warn_group_edit', 'true', '', 1);
    }
  }, []);

  const updateGroupSort = async (newGroups) => {
    try {
      await MyFavoriteApi.updateFavoriteGroupSort(
        newGroups.map((group) => group.idx),
      );
      getFavoriteGroups();
    } catch (e) {
      throwToast('에러가 발생하였습니다.');
    }
  };

  useEffect(() => {
    getFavoriteGroups();
  }, []);

  useEffect(() => {
    setGroups(favoriteGroups);
  }, [favoriteGroups]);

  return (
    <div className='conts_box'>
      <h2>즐겨찾기 그룹 편집</h2>
      <div className='group_area'>
        {groups.length < 10 && (
          <button
            type='button'
            className='add_group'
            onClick={handleAddGroupButtonClick}>
            새 그룹 만들기
          </button>
        )}

        {groups.length > 0 ? (
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided) => (
                <ul
                  className='group_list'
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {groups.map((group, index) => (
                    <Draggable
                      key={group.idx.toString()}
                      draggableId={group.idx.toString()}
                      index={index}>
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}>
                          <div
                            type='button'
                            className='move'
                            {...provided.dragHandleProps}>
                            이동
                          </div>
                          <div
                            className='group_tit'
                            {...provided.dragHandleProps}>
                            <span className='tit'>{group.title}</span>
                            <span className='total'>({group.totalCnt})</span>
                          </div>
                          <div className='set_box'>
                            <button
                              type='button'
                              className='edit'
                              onClick={handleEditButtonClick(group.idx)}>
                              편집
                            </button>
                            <button
                              type='button'
                              className='del'
                              onClick={handleDeleteButtonClick(group.idx)}>
                              삭제
                            </button>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div className='noList'>
            <p>편집할 그룹이 없습니다.</p>
          </div>
        )}
      </div>
    </div>
  );
});

export default MyFavoriteGroupEdit;
