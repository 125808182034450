import {useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

const RelatedSearchTermsGroup = ({relatedWord}) => {
  const {sendRelatedSearchTermsSectionClickLog} = useSearchClickLog();
  const {sessionKey} = useSearchGlobalStatus();
  const {getParams} = useUrlParameter();
  const {szSearchType: searchType = ''} = getParams();

  const [isToggled, setIsToggled] = useState(false);
  const [buttonText, setButtonText] = useState('더보기');

  const navigate = useNavigate();

  // SCKACT 로그
  const handleLogCallback = useCallback(
    (index) => {
      sendRelatedSearchTermsSectionClickLog({
        listIdx: index,
        sessionKey: sessionKey,
      });
    },
    [sessionKey, searchType],
  );

  const handleToggle = () => {
    setIsToggled(!isToggled);
    setButtonText(isToggled ? '더보기' : '접기');
  };

  const handleClickSearchTerm = (option, index) => {
    if (option) {
      const query = {
        szLocation: 'total_search',
        szSearchType: 'total',
        szStype: 'rt',
        szKeyword: option.d,
        szVideoFileType: 'ALL',
        rs: '1',
        acttype: 'rt',
      };

      const queryString = new URLSearchParams(query).toString();
      const newURL = `/search?${queryString}`;
      navigate(newURL);
    }
  };

  if (!relatedWord || !relatedWord.data || relatedWord.data.length === 0) {
    return null;
  }

  return (
    <div className='search_aside_floating'>
      <div className='floating_box'>
        <h2 className='title'>🔥 연관 검색어</h2>
        <ul>
          {relatedWord.data
            .slice(0, isToggled ? 20 : 10)
            .map((option, index) => (
              <li key={`aside_floating_${option.d}_${index}`}>
                <button
                  title={option.d}
                  type='button'
                  onClick={() => {
                    handleLogCallback(index);
                    handleClickSearchTerm(option, index);
                  }}>
                  <span>{option.d}</span>
                </button>
              </li>
            ))}
        </ul>
        {relatedWord.data?.length > 10 && (
          <button
            type='button'
            className={`more ${isToggled ? 'expand' : ''}`}
            onClick={handleToggle}>
            {buttonText}
          </button>
        )}
      </div>
    </div>
  );
};

export default RelatedSearchTermsGroup;
