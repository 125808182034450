'use client';
import {useEffect, useState, useRef} from 'react';
import {useOutsideClick} from '../main/common/hooks/useOutsideClick';
import {FloatingPortal} from '../main/common';
import MyPlusApi from '@/api/main/my-plus';
import throwToast from '@/utils/ui/toast';
import {throwDialog} from '@/utils/ui/dialog';
import {goLogin, isLogin} from '@/utils/main';
import useShareStore from '@/stores/common/share';

const SoopUiContextMenu = ({
  menuList,
  className,
  handleMenuClicked,
  data,
  isVod = false,
  isPlaylist = false,
  isShowProfileIconMenu,
}) => {
  const [isDisplay, setIsDisplay] = useState(false);
  const [position, setPosition] = useState({top: 0, left: 0});
  const [menuItems, setMenuItems] = useState(menuList);

  const buttonRef = useRef(null);
  const {setShareLayer} = useShareStore();

  const handleButtonClicked = (e) => {
    e.preventDefault();
    setIsDisplay((prevDisplay) => !prevDisplay);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsDisplay(false);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 쓰리닷 버튼 클릭 → 닉네임 클릭 시에만 재현이 되는 이슈 수정..이게 맞나,,
  useEffect(() => {
    if (isDisplay && isShowProfileIconMenu) {
      setIsDisplay(false);
    }
  }, [isShowProfileIconMenu]);

  // 로그인 여부에 따라 비로그인 시 공유하기만 노출
  const getFilteredContextMenu = () => {
    if (!isLogin()) {
      setMenuItems(menuList.filter((item) => item.type === 'share'));
    }
  };

  //나중에 보기 api
  const handleLaterView = async (isVod, broadcastId) => {
    if (isLogin()) {
      try {
        const response = await MyPlusApi.setLaterView(
          isVod ? 'VOD' : 'LIVE',
          broadcastId,
        );
        if (response) {
          throwToast(response.message);
        }
      } catch (error) {
        const {message} = error.response.data;
        throwToast(message);
      }
    } else {
      //비로그인시에는 로그인페이지로 이동
      goLogin();
    }
  };

  const convertShareType = () => {
    if (isVod) {
      return 'vod';
    } else if (isPlaylist) {
      return 'playlist';
    } else {
      if (data.fileType) {
        if (data.fileType === 'CATCH') {
          return 'catch';
        } else if (data.fileType === 'CATCH_STORY') {
          return 'catchstory';
        }
      } else {
        return 'live';
      }
    }
  };

  //공유하기
  const handleShare = (data, buttonRef) => {
    if (buttonRef.current) {
      const {top, left} = buttonRef.current.getBoundingClientRect();
      setShareLayer({
        type: convertShareType(),
        isShow: true,
        data: data,
        top: top,
        left: left,
      });
    }
  };

  //재생목록에 담기
  const handleOnClickAddPlayList = (broadcastId) => {
    if (isLogin()) {
      throwDialog(
        'common/PlayList',
        {
          broadcastId,
        },
        {
          title: '재생목록에 담기',
          customClassName: 'list_add_layer',
          titleAlign: 'left',
        },
      );
    } else {
      goLogin();
    }
  };

  // 재생목록 퍼가기
  const handlePlaylistShare = async (playlistIdx) => {
    // 비로그인일떄 미노출이긴 하지만 혹시나 하니 방어로직 추가
    if (!isLogin()) {
      goLogin();
      return;
    }

    try {
      const response = await MyPlusApi.setSharedPlaylist(playlistIdx);

      if (response) {
        throwToast(response.msg);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const checkButtonPosition = () => {
    if (buttonRef.current) {
      const MENU_WIDTH = 180;
      const MENU_HEIGHT = 14 + menuList.length * 36;
      const {top, left, bottom} = buttonRef.current.getBoundingClientRect();
      let newTop = top + window.scrollY;
      let newLeft = left + window.scrollX;

      // 메뉴가 우측에서 열릴 때 짤릴 케이스가 발생하면 좌측으로 조정
      if (newLeft + MENU_WIDTH > window.innerWidth) {
        newLeft = window.innerWidth - MENU_WIDTH - 35;
      }

      // 메뉴가 아래로 열릴 때 짤릴 케이스가 발생하면 위로 조정
      if (bottom + MENU_HEIGHT > window.innerHeight) {
        newTop = top + window.scrollY - MENU_HEIGHT;
      } else {
        newTop = bottom + window.scrollY;
      }

      setPosition({top: newTop, left: newLeft});
    }
  };

  useOutsideClick(buttonRef, () => {
    setIsDisplay(false);
  });

  useEffect(() => {
    if (isDisplay) {
      // props로 전달받은 menuList가 변경될 때마다 menuItems 상태를 업데이트(탭 이동 시 menuList의 내용이 변경되는 경우를 처리하기 위함)
      setMenuItems(menuList);
      checkButtonPosition();
      getFilteredContextMenu();
    }
  }, [isDisplay]);

  return (
    <>
      <button
        ref={buttonRef}
        type='button'
        className={className || 'more_dot'}
        onClick={handleButtonClicked}>
        <span>더보기 메뉴</span>
      </button>
      {isDisplay ? (
        <FloatingPortal className={'_moreDot_wrapper'} target={'soop_wrap'}>
          <div
            className='_moreDot_layer'
            style={{
              display: 'block',
              top: position.top,
              left: position.left,
            }}>
            {menuItems.map((menu, index) => {
              return (
                <button
                  type='button'
                  key={index}
                  onClick={(event) => {
                    if (menu.type === 'view-later') {
                      handleLaterView(isVod, data.broadcastId);
                    } else if (menu.type === 'share') {
                      handleShare(data, buttonRef);
                    } else if (menu.type === 'add-playlist') {
                      handleOnClickAddPlayList(data.broadcastId);
                    } else if (menu.type === 'playlist-share') {
                      handlePlaylistShare(data.playlistNumber);
                    } else {
                      handleMenuClicked(
                        event,
                        menu.type,
                        data,
                        buttonRef,
                        index,
                      );
                    }
                    setIsDisplay(false);
                  }}>
                  <span>{menu.label}</span>
                </button>
              );
            })}
          </div>
        </FloatingPortal>
      ) : null}
    </>
  );
};

export default SoopUiContextMenu;
