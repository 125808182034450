import {VideoProgressBar} from '@/components/player/common/VideoProgressBar';
import {useEffect, useRef, useState} from 'react';
import {convertToDuration} from '@/utils/main';
import {VolumeProgressBar} from '@/components/player/common/VolumeProgressBar';
import useIsHover from '@/components/player/common/hooks/useIsHover';

export const VideoControlBar = ({
  playerRef,
  loop = false,
  playing,
  setPlaying,
  muted = true,
  setMuted = () => {},
  volume = 0.5,
  setVolume,
  currentTime,
  setCurrentTime,
  duration,
  isMediaEnd,
  isSeekable = true,
}) => {
  const [volumeBarPosition, setVolumeBarPosition] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isVolumeHover, handleVolumeBarMouseEnter, handleVolumeBarMouseLeave] =
    useIsHover(2000);

  useEffect(() => {
    setVolumeUI(volume);
  }, [volume]);

  useEffect(() => {
    if (muted) {
      setVolumeUI(0);
    } else {
      setVolumeUI(volume);
    }
  }, [muted]);

  useEffect(() => {
    if (duration !== 0) {
      setProgress(parseFloat(((currentTime / duration) * 100).toFixed(2)));
    }
  }, [duration, currentTime]);

  const onVolumeChange = (volume) => {
    setVolume(parseFloat((volume / 100).toFixed(2)));
  };

  //from progress bar
  const onProgress = (progress) => {
    if (playerRef.current) {
      let duration = playerRef.current.media?.duration;
      if (duration) {
        //playerRef.current.seek((progress / 100) * duration);
        setCurrentTime((progress / 100) * duration);
      }
    }
  };

  const setVolumeUI = (uiVolume) => {
    setVolumeBarPosition(uiVolume * 100);

    if (uiVolume === 0) {
      setMuted(true);
    } else {
      if (muted) {
        setMuted(false);
      }
    }
  };

  return (
    <div className='player_ctrlBox'>
      <div className='ctrl'>
        <VideoProgressBar
          progress={progress}
          setProgress={setProgress}
          onProgress={onProgress}
          isSeekable={isSeekable}></VideoProgressBar>

        {/*광고에는 재생 버튼이 없음 - 버튼 하나하나씩 컴포넌트로 있어야하는 것 일까*/}
        {/*{!loop && isMediaEnd ? (*/}
        {/*  <button*/}
        {/*    type='button'*/}
        {/*    className='btn_refresh'*/}
        {/*    style={{display: 'block'}}*/}
        {/*    aria-label='다시 재생'*/}
        {/*    onClick={() => {*/}
        {/*      setPlaying(true);*/}
        {/*    }}></button>*/}
        {/*) : (*/}
        {/*  <button*/}
        {/*    type='button'*/}
        {/*    className={playing ? 'pause' : 'play'}*/}
        {/*    id='play'*/}
        {/*    aria-label='재생'*/}
        {/*    onClick={() => {*/}
        {/*      setPlaying(!playing);*/}
        {/*    }}></button>*/}
        {/*)}*/}

        <div
          className={`volume ${isVolumeHover ? `bar_show` : ''}`}
          onMouseEnter={handleVolumeBarMouseEnter}
          onMouseLeave={handleVolumeBarMouseLeave}>
          <button
            type='button'
            className={`sound ${muted ? 'mute' : ''}`}
            id='btn_sound'
            onClick={(event) => {
              event.stopPropagation();
              setMuted(!muted);
            }}>
            <span></span>
            <span></span>
          </button>

          <VolumeProgressBar
            progress={volumeBarPosition}
            setProgress={setVolumeBarPosition}
            onProgress={onVolumeChange}></VolumeProgressBar>
        </div>
        <div className='time_display'>
          <span className='time-current' id='time-current'>
            {convertToDuration(currentTime)}
          </span>
          /<span className='time-duration'>{convertToDuration(duration)}</span>
        </div>
      </div>
    </div>
  );
};
