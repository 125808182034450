import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

const store = (set) => ({
  isDarkMode: false,
  isDialogOpened: false,
  autoTagStyle: [], //이벤트 태그의 스타일

  setIsDarkMode: (item) => {
    return set((state) => {
      return {
        isDarkMode: item,
      };
    });
  },
  setDialogOpened: (opened) => {
    return set((state) => {
      return {
        isDialogOpened: opened,
      };
    });
  },

  setAutoTagStyle: (item) => {
    set((state) => {
      return {autoTagStyle: [...item]};
    });
  },
});

const useGlobalUIStore = create(devtools(store));

export default useGlobalUIStore;
