'use client';

import HotIssueVod from '@/components/my/common/group/HotIssueVod';
import {useEffect, useState} from 'react';
import '/public/static/css/module/popular.scss';
import MyFeedApi from '@/api/my/feed';
import FeedList from '@/components/my/feed/hotissue/FeedList';
/**
 * MyHotissue 페이지
 * @returns {JSX.Element} MyHotissue 페이지
 */
const MyHotissue = () => {
  const [feedData, setFeedData] = useState({
    top: [],
    bottom: [],
    refreshCycle: '',
    refreshTime: '',
  });
  const [vod, setVod] = useState({});

  const fetchFeedData = async () => {
    try {
      const {refreshCycle, refreshTime, data} =
        await MyFeedApi.getFeedHotissue();
      const vodData = await MyFeedApi.getHotissueVod();

      setFeedData({
        refreshCycle,
        refreshTime,
        top: data?.slice(0, 6),
        bottom: data?.slice(6),
      });

      setVod(vodData);
    } catch (error) {
      console.error('데이터 가져오기 실패:', error);
    }
  };

  useEffect(() => {
    fetchFeedData();
  }, []);

  return (
    <div id='container' className='content_area af-issue'>
      <h2>실시간 인기글</h2>
      <article className='top100'>
        <div className='top_title'>
          <h3>실시간 인기글 TOP 100</h3>
          <span className='refresh_time'>
            {feedData.refreshTime} <em>({feedData.refreshCycle})</em>
          </span>
        </div>
        {feedData.top && (
          <FeedList feedData={feedData.top} fetchFeedData={fetchFeedData} />
        )}
        <HotIssueVod vod={vod} />
        {feedData.bottom && (
          <FeedList feedData={feedData.bottom} fetchFeedData={fetchFeedData} />
        )}
      </article>
    </div>
  );
};

export default MyHotissue;
