'use client';

import React, {useEffect, useRef, useState} from 'react';
import BasePlayer from '@/components/player/live/BasePlayer';
import {PLAYER_TYPE, PREVIEW_PLAYER_ERROR} from '@/constants/player/live';
import {useLivePlayer} from '@/components/player/common/hooks/useLivePlayer';

export const PreviewPlayer = ({
  livePlayId,
  broadNo,
  thumbnail,
  autoPlay = true,
  playDurationLimit = 60,
  refreshIntervalTime = 10,
  playing: playingExternal,
  onAutoPlayBlock = (event) => {},
  onError = (event, message = '') => {},
}) => {
  const playerType = PLAYER_TYPE.PREVIEW_PLAYER;
  const previewPlayer = useRef(null);

  //메인 몰리는 경우 분산 처리용
  const loadPreviewDelayTIme = 3;
  const loadPreviewDelayTimer = useRef(0);

  const {videoRef, isPlayerLoaded, setPlaying, showPoster} = useLivePlayer({
    config: {
      playDurationLimit,
      refreshIntervalTime,
    },
    player: previewPlayer,
    playerType,
    autoPlay,
    muted: true,
    onAutoPlayBlock,
    onError,
  });

  useEffect(() => {
    return () => {
      initTimers();
    };
  }, []);

  useEffect(() => {
    if (previewPlayer.current && isPlayerLoaded) {
      //prePlayer.current = previewPlayer;
      previewPlayer.current.addEventListener(
        PREVIEW_PLAYER_ERROR.EXCEED_PLAY_LIMIT,
        (event) => {
          onError(PREVIEW_PLAYER_ERROR.EXCEED_PLAY_LIMIT);
        },
      );
      previewPlayer.current.addEventListener(
        PREVIEW_PLAYER_ERROR.STATUS_ERROR,
        (event) => {
          onError(PREVIEW_PLAYER_ERROR.STATUS_ERROR);
        },
      );
      if (livePlayId) {
        let delay = getRandomInclusive(1, loadPreviewDelayTIme);
        //console.log(delay);
        loadPreviewDelayTimer.current = setTimeout(() => {
          previewPlayer.current?.loadPreview(livePlayId, broadNo);
        }, delay * 1000);
      }
    }
  }, [isPlayerLoaded]);

  useEffect(() => {
    setPlaying(playingExternal);
  }, [playingExternal]);

  const initTimers = () => {
    clearTimeout(loadPreviewDelayTimer.current);
    loadPreviewDelayTimer.current = 0;
  };

  const getRandomInclusive = (min, max) => {
    const minCeil = Math.ceil(min);
    const maxFloor = Math.floor(max);
    return Math.random() * (maxFloor - minCeil) + minCeil;
  };

  return (
    <BasePlayer
      videoRef={videoRef}
      playerType={playerType}
      autoPlay={autoPlay}
      muted={false}
      thumbnail={thumbnail}
      showPoster={showPoster}></BasePlayer>
  );
};
