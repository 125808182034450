import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
/**
 * 3일전꺼 까지는 상대날짜로 나타냄, 그 이후는 절대날짜 yyyy-mm-dd 형식으로 보여줌
 * 번역 내용 들어가서 훅으로 만듬
 */
export default function useConvertRegDate() {
  const convertRegDate = (regDate) => {
    if (!regDate) {
      return;
    }
    let regDateTimeStamp = Number(regDate);

    if (isNaN(regDateTimeStamp)) {
      const parsedDate = new Date(regDate);
      regDateTimeStamp = parsedDate.getTime() / 1000; // 타임스탬프 초 단위로 변환
      if (isNaN(regDateTimeStamp)) {
        return '';
      }
    }

    const d = new Date(regDateTimeStamp * 1000);
    const now = new Date();

    const diffTimeStamp = Math.abs(now - d) / 1000;
    const unitSecond = {
      day: 86400, // 하루(24시간 * 60분 * 60초)
      hour: 3600, // 한시간(60분 * 60초)
      minute: 60, // 1분(60초)
      second: 1, // 1초
    };

    const relativeDay = Math.floor(diffTimeStamp / unitSecond.day);
    const relativeHour = Math.floor(diffTimeStamp / unitSecond.hour);
    const relativeMinute = Math.floor(diffTimeStamp / unitSecond.minute);
    const relativeSecond = Math.floor(diffTimeStamp / unitSecond.second);
    if (relativeDay < 4) {
      if (relativeDay > 0) {
        return relativeDay + '일 전';
      } else if (relativeHour > 0) {
        return relativeHour + '시간 전';
      } else if (relativeMinute > 0) {
        return relativeMinute + '분 전';
      } else if (relativeSecond > 0) {
        return relativeSecond + '초 전';
      }
    }

    // 상대적 날짜 표시에 해당 안되면 절대적 날짜로 표기
    const date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2),
    ].join('-');

    return date;
  };

  /**
   * UTC - 현재 브라우저 시간 변경
   * @return {*[]}
   */
  const convertUtcTimeStamp = (date) => {
    // GMT+9 시간대의 날짜 및 시간 - 서버 시간
    // const gmtDate = dayjs.tz(date, 'America/New_York'); // 미래
    // const gmtDate = dayjs.tz(date, 'Asia/Taipei'); // 과거
    const gmtDate = dayjs.tz(date, 'Asia/Seoul'); // 서울 시간
    // return date;
    // 서버 시간 -> UTC +0 시간으로 변환
    const utcDateTime = gmtDate.utc();
    // 브라우저의 현지 시간대로 변환
    const localDateTime = utcDateTime.local();

    return localDateTime?.format('YYYY-MM-DD HH:mm:ss');
  };

  return {convertRegDate, convertUtcTimeStamp};
}
