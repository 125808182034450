import {useRef} from 'react';
import {StreamerAvatar} from '@/components/search/common';
import {SoopUiProfileIconMenu} from '@/components/ui';

import globalDomains from '@/utils/global-domains';
import useFavoriteStore from '@/stores/search';

/**
 * PostThumnailInfo 컴포넌트 ( 게시글썸네일 )
 * @param {Object} props 컴포넌트의 props
 * @param {String} userId 게시글 작성자 ID
 * @param {String} userNick 게시글 작성자 닉네임
 * @param {String} stationUserId 게시글이 작성된 방송국 ID
 * @param {String} stationName 게시글이 작성된 방송국 이름
 * @param {Function} logCallback 로그 관련 콜백 함수
 * @returns {JSX.Element} PostThumnailInfo 컴포넌트
 */
const PostThumnailInfo = ({
  userId,
  userNick,
  stationUserId,
  stationName,
  logCallback,
}) => {
  const {toggleFavorite, setFavoriteStreamerName} = useFavoriteStore();
  const soopUiProfileIconMenu = useRef(null);

  /**
   * 프로필 ict 메뉴의 이벤트가 발생했을 때 트리거
   * @param {EventData} event
   */
  const handleProfileIconMenuEvent = (event) => {
    const {eventName, data} = event;

    if (eventName === 'deleteFavorite') {
      toggleFavorite();
      setFavoriteStreamerName(data.id);
    }
  };

  // SCKACT 로그
  const handlePostActionLog = (type) => {
    let param = type ? {type: type} : '';
    logCallback && logCallback(param);
  };
  return (
    <>
      <div className='info_box'>
        <StreamerAvatar
          userId={userId}
          className='thumb'
          logCallback={() => handlePostActionLog('sti')}
        />
        <a
          className='nick'
          href='#n'
          onClick={(e) => {
            e.preventDefault();
            soopUiProfileIconMenu.current?.open();
            handlePostActionLog('nick');
          }}>
          {userNick}
        </a>
        <a
          className='bs_title'
          href={`${globalDomains.BJ_AFREECATV}/${stationUserId}`}
          target='_blank'
          onClick={() => {
            handlePostActionLog('stn');
          }}>
          {stationName}
        </a>
      </div>
      <SoopUiProfileIconMenu
        ref={soopUiProfileIconMenu}
        streamerId={userId}
        streamerNick={userNick}
        handleMenuButtonClicked={handleProfileIconMenuEvent}
        searchArea='post'
      />
    </>
  );
};

export default PostThumnailInfo;
