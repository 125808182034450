import React, {useMemo} from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from '@/app/(main)/routes/not-found.jsx';

const removePrefixFromPath = (path) => {
  if (typeof path === 'string') {
    return path
      .replace(/^\/my/, '')
      .replace(/^\/directory/, '')
      .replace(/^\/search/, '')
      .replace(/^\/live/, '');
  }
  return path;
};

export default function CustomRoutes({children}) {
  const modifyRoutes = (children) => {
    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return child;
      }

      // 자식 요소를 재귀적으로 처리
      const newChildren = child.props.children
        ? modifyRoutes(child.props.children)
        : child.props.children;

      // 경로를 문자열로 확인하여 수정
      const newPath =
        typeof child.props.path === 'string'
          ? removePrefixFromPath(child.props.path)
          : child.props.path;

      return React.cloneElement(child, {
        path: newPath,
        children: newChildren,
      });
    });
  };

  const modifiedChildren = useMemo(() => modifyRoutes(children), [children]);

  return (
    <Routes>
      {modifiedChildren}
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}
