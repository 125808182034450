import {SoopUiSwiper} from '@/components/ui';
import React from 'react';

/**
 * 방송 그룹 랩퍼
 * @param {Object} props
 * @param {'grid' | 'swiper'} props.type
 * @param {React.JSX} props.children
 */
const MyBroadcastLayout = ({type = 'grid', children}) => {
  return (
    <>
      {/* NOTE: 현재 grid 형태는 즐겨찾기 - LIVE 영역 / 기록 - 재생목록 영역에서만 보임 */}
      {type === 'grid' && (
        <div className='cBox-list'>
          <ul>
            {React.Children.map(children, (child, index) => (
              <li data-type='cBox' key={`${child.props.broadcastId}-${index}`}>
                {child}
              </li>
            ))}
          </ul>
        </div>
      )}
      {type === 'swiper' && (
        <div className='cBox_slide vodSlide'>
          <SoopUiSwiper
            swiperTag='ul'
            swiperClass='cBox_list'
            maxSlidesPerView={6}
            isSameSlidesViewGroup
            options={{
              loop: false,
            }}>
            {React.Children.map(children, (child, index) => (
              <li data-type='cBox' key={`${child.props.broadcastId}-${index}`}>
                {child}
              </li>
            ))}
          </SoopUiSwiper>
        </div>
      )}
    </>
  );
};

export default MyBroadcastLayout;
