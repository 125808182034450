import MyApi from '@/api/my';

/**
 * @typedef {import('@/api/my/history').Broadcast} Broadcast
 */

/**
 * @typedef {Object} FanClub
 * @property {Broadcast[]} broadInfo - 라이브 방송 정보 배열
 * @property {number} fanclubNo - 팬클럽의 고유 식별자
 * @property {string} firstDate - 팬클럽이 처음 생성된 날짜 (ISO 8601 형식)
 * @property {boolean} isFanclub - 팬클럽 여부
 * @property {boolean} isFavorite - 즐겨찾기 여부
 * @property {boolean} isLive - 라이브 방송 여부
 * @property {boolean} isPin - 고정 여부
 * @property {boolean} isSubscribe - 구독 여부
 * @property {string} lastBroadStart - 마지막 방송 시작 날짜 (ISO 8601 형식)
 * @property {string} stationName - 채널 이름
 * @property {number} totalViewCnt - 총 조회수
 * @property {string} userId - 사용자 ID
 * @property {string} userNick - 사용자 닉네임
 */

const MyFanApi = {
  /**
   * 팬클럽 정보 가져오기
   * @returns {Promise<FanClub[]>}
   */
  async getFanClub() {
    try {
      const {data: fan, totalCnt: totalCount} = await MyApi.get('/api/fanclub');
      return {fan: fan || [], totalCount};
    } catch (error) {
      return {fan: [], totalCount: 0};
    }
  },
  /**
   * 팬클럽 스트리머 고정
   * @desc 즐겨찾기 스트리머랑 뭐가 다르길래 각각 있는지는 모르겠지만 아무튼 각각 있다 진짜 너무 속상하다
   * @param {string} userId - BJ 사용자 아이디
   */
  async setPin(userId) {
    try {
      const formData = new FormData();
      formData.append('common_no', userId);
      const data = await MyApi.post('/api/fanclub/pin', formData);
      return data;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 팬클럽 스트리머 고정 해제
   * @desc 즐겨찾기 스트리머랑 뭐가 다르길래 각각 있는지는 모르겠지만 아무튼 각각 있다 진짜 너무 속상하다
   * @param {string} userId - BJ 사용자 아이디
   */
  async deletePin(userId) {
    try {
      const formData = new FormData();
      formData.append('common_no', userId);
      formData.append('_method', 'delete');
      const data = await MyApi.post(`/api/fanclub/pin/${userId}`, formData);
      return data;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 팬클럽 BJ 의 VOD 조회
   * @returns {Promise<Array>} vods
   */
  async getVod() {
    try {
      const {data} = await MyApi.get('/api/fanclub/vod');
      return data || [];
    } catch (error) {
      return [];
    }
  },
};

export default MyFanApi;
