'use client';

import {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import MyFeedFavoriteListItem from '@/components/my/feed/item/FavoriteListItem';
import MyFeedApi from '@/api/my/feed/page';
import {useInfiniteScroll} from '@/components/main/common/hooks/useInfiniteScroll';
import {usePathname} from 'next/navigation';
import MyFavoriteApi from '@/api/my/favorite';
import throwToast from '@/utils/ui/toast';

/**
 * @typedef {Object} myFeedFavoriteList
 * @property {Number} favoriteNo - 즐겨찾기 번호
 * @property {String} isLive - 라이브 여부
 * @property {Boolean} isPin - 핀 여부
 * @property {String} profileImage - profile image
 * @property {String} url - station url
 * @property {String} userId - streamer id
 * @property {String} userNick - streamer nickname
 */

/**
 * 즐겨찾기 리스트 및 메타 데이터
 * @param {Array} myFeedFavoriteList - 즐겨찾기 리스트
 * @param {Object} links - 이전, 다음 페이지 링크
 * @param {Object} meta - 메타 데이터
 */

const DEFAULT_HEIGHT = 117; // 즐겨찾기 리스트 기본 높이
const EXPANDED_HEIGHT = 348; // 즐겨찾기 리스트 확장 높이

const FavoriteList = ({setIsFavoriteList}) => {
  const pathname = usePathname();
  const userIdFromPath = pathname.split('/')[3] || '';
  const [page, setPage] = useState(1); // infinite scroll page
  const [favoriteList, setFavoriteList] = useState([]);
  const [meta, setMeta] = useState({}); // meta data
  const [isLoading, setIsLoading] = useState(true);
  const listRef = useRef(null);
  const [isShow, setIsShow] = useState(false);
  const [height, setHeight] = useState(DEFAULT_HEIGHT);

  const getFavoriteList = async ({page = page}) => {
    try {
      const {
        data: myFeedFavoriteList,
        links,
        meta,
      } = await MyFeedApi.getFavoriteList({
        page: page,
      });
      return {myFeedFavoriteList, links, meta};
    } catch (error) {
      console.log(error);
      return {myFeedFavoriteList: [], links: null, meta: null};
    }
  };
  // fetch favorite list
  const fetchFavoriteList = async () => {
    setIsLoading(true);
    try {
      const {
        myFeedFavoriteList: data,
        links,
        meta,
      } = await getFavoriteList({page: page});
      if (page === 1) {
        setFavoriteList(data);
      } else {
        setFavoriteList((prevList) => [...prevList, ...data]);
      }
      if (meta.total === 0) {
        setIsFavoriteList(false);
      } else {
        setIsFavoriteList(true);
      }
      setMeta(meta);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFavoriteList();
  }, [page]);

  const handleUserPin = async (favoriteNo, isPin) => {
    let result;
    try {
      if (isPin) {
        result = await MyFavoriteApi.deletePin(favoriteNo);
      } else {
        result = await MyFavoriteApi.setPin(favoriteNo);
      }
      throwToast(result.message);
      setFavoriteList((prevList) => {
        // 핀 설정된 아이템을 찾아서 핀 설정 여부를 변경
        const updatedItem = prevList.find(
          (item) => item.favoriteNo === favoriteNo,
        );
        if (updatedItem) {
          updatedItem.isPin = !isPin;
        }
        // 핀 설정된 리스트
        const pinnedList = prevList.filter(
          (item) => item.isPin && item.favoriteNo !== favoriteNo,
        );
        // 핀 설정되지 않은 리스트
        const notPinnedList = prevList.filter(
          (item) => !item.isPin && item.favoriteNo !== favoriteNo,
        );
        // 핀을 설정/해제한 경우, [핀 설정된 리스트, 핀을 방금 설정/해제한 요소, 핀 설정이 해제되어있던 리스트] 순으로 노출
        return [...pinnedList, updatedItem, ...notPinnedList];
      });
    } catch (error) {
      console.error('#핀 설정/해제 실패:', error);
    }
  };

  const {ref: lastPositionRef, inView} = useInfiniteScroll({
    delay: 100,
    onChange: (inView) => {
      if (inView && !isLoading && meta.currentPage < meta.lastPage) {
        setPage((prevPage) => prevPage + 1);
      }
    },
  });

  // 즐겨찾기 리스트 더보기
  const handleClick = () => {
    let _height;

    if (isShow) {
      _height = DEFAULT_HEIGHT;
    } else {
      listRef.current.clientHeight > EXPANDED_HEIGHT
        ? (_height = EXPANDED_HEIGHT)
        : (_height = listRef.current.clientHeight);
    }
    setHeight(_height);
    setIsShow(!isShow);
  };
  return (
    <>
      {favoriteList && (
        <div className='favor_list'>
          <div className='scroll_wrap'>
            <div className={`list_all ${!userIdFromPath && 'checked'}`}>
              <Link to={`/my/feed`}>
                <span className='thum'></span>
                <p>전체</p>
              </Link>
            </div>
            <div className='favorite-scroll' style={{height: height}}>
              <div className='favorite-scroll-content'>
                <ul ref={listRef}>
                  {favoriteList.map((item, index) => (
                    <MyFeedFavoriteListItem
                      key={`favorite-${index}`}
                      userIdFromPath={userIdFromPath}
                      handleUserPin={handleUserPin}
                      {...item}
                    />
                  ))}
                </ul>
                <div ref={lastPositionRef} />

                {/* 반응형 미 지원 페이지이기 때문에 즐겨찾기 인원수 9명 이상인 경우에만 더보기 버튼 노출 처리 */}
                {favoriteList.length > 8 && (
                  <button
                    type='button'
                    className={`btn_more ${isShow ? 'active' : ''}`}
                    onClick={handleClick}>
                    더보기
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FavoriteList;
