import {SoopUiSwiper} from '@/components/ui';
import {formatDate} from '@/utils/main';

/**
 * GameEvent 컴포넌트 ( 이벤트 탭 )
 * @param {Object} props - 컴포넌트의 props
 * @param {Object[]} events - 이벤트 데이터
 * @param {Function} logCallback - 로그 관련 콜백 함수
 * @returns GameEvent 컴포넌트
 */
const GameEvent = ({events, logCallback = () => {}}) => {
  const formatString = 'yy.MM.dd';

  // SCKACT 로그
  const handleGameEventActionLog = (type, index) => {
    let param = type ? {type: type} : '';
    console.error('param', param);
    logCallback && logCallback(param, index);
  };

  if (!Array.isArray(events) || events.length === 0) {
    return null;
  }

  return (
    <div className='game_event'>
      <SoopUiSwiper
        swiperTag='ul'
        swiperClass='event_list'
        otherMaxSlidesResolution={[
          {max: 926, slidePerView: 4},
          {max: 453, slidePerView: 2},
          {max: 0, slidePerView: 1},
        ]}
        options={{
          loop: false,
        }}>
        {events?.map((event, index) => {
          const formattedStartDate = formatDate(event.startDate, formatString);
          const formattedEndDate = formatDate(event.endDate, formatString);
          const dateRangeString = `${formattedStartDate}~${formattedEndDate}`;
          return (
            <li key={`${event.title}_${index}`}>
              <a
                href={event.link}
                target='_blank'
                onClick={() => {
                  handleGameEventActionLog('img', index);
                }}>
                <em className='flag'>EVENT</em>
                <span className='tit'>{event.title}</span>
                <span className='date'>{dateRangeString}</span>
              </a>
            </li>
          );
        })}
      </SoopUiSwiper>
    </div>
  );
};

export default GameEvent;
