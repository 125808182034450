import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

const store = (set) => {
  return {
    isFavoriteFlag: false,
    favoriteStreamerName: '',
    toggleFavorite: () => {
      set((state) => {
        return {isFavoriteFlag: !state.isFavoriteFlag};
      });
    },
    setFavoriteStreamerName: (name) => {
      set(() => {
        return {favoriteStreamerName: name};
      });
    },
  };
};

const useFavoriteStore = create(devtools(store));
export default useFavoriteStore;
