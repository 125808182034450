import {useMemo, useState} from 'react';
import {SoopUiImg} from '@/components/ui';
import globalDomains from '@/utils/global-domains';
import {convertViewCnt} from '@/utils/main';
import {useLocation} from 'react-router-dom';
import {ROUTE} from '@/constants/main/route';
import {
  sendLegacyInflowPathLog,
  sendLiveThumbnailClickLog,
} from '@/utils/main/my/log';
import {convertToArray} from '@/utils';

const LiveThumbnailImage = ({
  thumbnailSrc,
  isPassword,
  isAdult,
  userId,
  broadcastId,
  broadType,
  visitBroadType,
  viewCount,
  formattedStartDate,
  isVisitBroad,
  laterRegDate,
  broadBps,
  broadCateNo,
  broadResolution,
  hashTags,
  categoryName,
  categoryTags,
  listDataType,
  isLogSendData,
  logCallback,
  handleLaterView = () => {},
}) => {
  const [isLoadImageError, setIsLoadImageError] = useState(false);

  const link = `${globalDomains.PLAY_AFREECATV}/${userId}/${broadcastId}${laterRegDate ? '/later#REG_DATE/1' : ''}`;

  const handleImageError = () => {
    setIsLoadImageError(true);
    return;
  };

  const location = useLocation();

  const handleThumbnailClick = () => {
    // 방송 썸네일 클릭 로그
    sendLiveThumbnailClickLog({
      streamerId: userId,
      broadcastId,
      broadCateNo,
      broadBps,
      broadResolution,
    });

    // 기존에 사용하던 라이브 방송 유입 로그
    sendLegacyInflowPathLog({
      broadcastId,
      streamerId: userId,
      moreData: {
        hashTags: convertToArray(hashTags),
        categoryTags: convertToArray(categoryTags),
        ...(listDataType && {listDataType}),
        contentsType: 'live',
        broadCateNo: broadCateNo,
        directoryCateId: categoryName,
      },
      isLogSendData,
    });

    logCallback && logCallback();
  };

  // 썸네일 이미지, 비공개, 19금 일반
  const thumbnailImage = useMemo(() => {
    if (isPassword && isAdult) {
      return (
        <a
          className='thumb-lock_adult'
          target='_blank'
          href={link}
          onClick={handleThumbnailClick}
        />
      );
    } else if (isAdult) {
      return (
        <a
          className='thumb-adult'
          target='_blank'
          href={link}
          onClick={handleThumbnailClick}
        />
      );
    } else if (isPassword) {
      return (
        <a
          className='thumb-lock'
          target='_blank'
          href={link}
          onClick={handleThumbnailClick}
        />
      );
    } else if (isLoadImageError) {
      // default 썸네일
      return (
        <a
          className='thumb-default'
          target='_blank'
          href={link}
          onClick={handleThumbnailClick}
        />
      );
    }

    // 썸네일 이미지, 이미지 에러시 default 이미지
    return (
      <a target='_blank' href={link} onClick={handleThumbnailClick}>
        <SoopUiImg
          src={thumbnailSrc}
          onError={handleImageError}
          type={'thumbs'}
        />
      </a>
    );
  }, [thumbnailSrc, isPassword, isAdult, link, isLoadImageError]);

  const isShowLaterButton = location.pathname !== ROUTE.MY.HISTORY.LATER;

  return (
    <div className='thumbs-box'>
      {thumbnailImage}
      {Number(broadType) === 40 && <span className='ppv'>유료</span>}
      {(Number(visitBroadType) > 0 || isVisitBroad) && (
        <span className='allow'>탐방허용</span>
      )}
      <span className='views'>
        <em>{convertViewCnt(viewCount)}</em>
      </span>
      {Number(broadType) === 22 && <span className='vr360'>VR</span>}
      <span className='time'>{formattedStartDate} 방송시작</span>
      {isShowLaterButton && (
        <button
          type='button'
          className='later'
          tip='나중에 보기'
          onClick={() => handleLaterView(broadcastId)}>
          <span>나중에 보기</span>
        </button>
      )}
    </div>
  );
};

export default LiveThumbnailImage;
