import MyProfileImg from '@/components/my/common/item/MyProfileImg';
import globalDomains from '@/utils/global-domains';

/**
 * Search 프로필 이미지 컴포넌트
 * @param {Object} props - 컴포넌트의 props
 * @param {String} userId - 사용자 아이디
 * @param {String} broadNo - 방송번호
 * @param {String} className - 클래스명
 * @param {String} logCallback - 로그관련 메소드
 * @returns Search 프로필 이미지 컴포넌트
 */
const StreamerAvatar = ({userId, broadNo, className, logCallback}) => {
  const href = broadNo
    ? `${globalDomains.PLAY_AFREECATV}/${userId}/${broadNo}`
    : `${globalDomains.BJ_AFREECATV}/${userId}`;

  const handleProfileImageClick = () => {
    logCallback && logCallback();
  };
  return (
    <a
      href={href}
      className={className}
      target='_blank'
      rel='noopener noreferrer'
      onClick={handleProfileImageClick}>
      <MyProfileImg userId={userId} />
    </a>
  );
};

export default StreamerAvatar;
