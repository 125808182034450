import axios from 'axios';
import {camelizeObject} from '@/utils';

/*
 * Api - API 호출 래핑 객체
 *
 * @params baseUrl<String> - https://live.afre..xx, vod 등 다양한 도메인의 api base host
 * @params withCredentials - 일부 api에 대해서는 Access-Control-Allow-Origin 이 '*' 형식으로 걸려있는게 있는데 이때 Access-Control-Allow-Credentials 를 true 보내는걸 동작하지 않도록 막고있다.
 * @params shouldConvertCaseAuto<Boolean> - snake case를 자동으로 camelCase로 변환해준다.
 * @params handleRequestDebugging<Function> - request 상세 분석용
 * @params handleResponseDebugging<Function> - camelCase로 변경 되기 전의 response 분석용
 *
 * @Methods get(url, queryParams): Promise
 * @Methods post(url, params, queryParams): Promise
 * */

/*
 * WARN : 고민해보실? 2
 * - https://tkdodo.eu/blog/you-might-not-need-react-query
 * - nextjs에서 react-query 꼭 필요한 것인가
 * */

const ApiCore = (
  baseUrl,
  withCredentials = true,
  shouldPreventConvertingCase,
  handleRequestDebugging,
  handleResponseDebugging,
) => {
  const instance = axios.create({
    baseURL: baseUrl,
    timeout: 300000, //타임아웃 3초 설정
    withCredentials: withCredentials,
    headers: {post: {'Content-Type': 'application/x-www-form-urlencoded'}},
  });

  instance.interceptors.request.use(
    async function (config) {
      const isServer = typeof window === 'undefined';

      if (isServer) {
        const {cookies} = await import('next/headers');
        config.headers.cookie = cookies().toString();
      }

      handleRequestDebugging && handleRequestDebugging(config);
      return config;
    },
    function (error) {
      //handleRequestDebugging && handleRequestDebugging(false, error);
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    function (response) {
      handleResponseDebugging && handleResponseDebugging(response);

      if (!shouldPreventConvertingCase) {
        response.data = camelizeObject(response.data);
      }

      return response;
    },
    function (error) {
      //handleResponseDebugging && handleResponseDebugging(false, error);
      // TODO: Sentry 연동
      console.error('API ERROR::: ', error.config.url, error);
      return Promise.reject(error);
    },
  );

  return {
    async get(url, queryParams) {
      try {
        const response = await instance.get(url, {params: queryParams});
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async post(url, params, queryParams, options = {}) {
      try {
        const response = await instance.post(url, params, {
          ...options,
          params: queryParams,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async put(url, params, queryParams) {
      try {
        const response = await instance.put(url, params, {
          params: queryParams,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async delete(url, params, queryParams) {
      try {
        const response = await instance.delete(url, {
          params: queryParams,
        });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  };
};

export default ApiCore;
