import React, {useEffect, useRef, useState} from 'react';
import {PLAYER_TYPE} from '@/constants/player/live';
import BasePlayer from '@/components/player/live/BasePlayer';
import {VideoControlBar} from '@/components/player/common/VideoControlBar';
import useIsHover from '@/components/player/common/hooks/useIsHover';
import useMainStore from '@/stores/main';
import {useLivePlayer} from '@/components/player/common/hooks/useLivePlayer';
import VastInfo from '@/utils/player/VastInfo';
import {useIntersection} from '@/components/common/hooks/useIntersection';

export const BannerAdPlayer = ({
  adInfo,
  loop = false,
  onError = () => {},
  //showControlBar = true,
}) => {
  const playerType = PLAYER_TYPE.BANNER_PLAYER;
  const bannerAdPlayer = useRef(null);

  const [isAutoPlayBlocked, setIsAutoPlayBlocked] = useState(false);

  const [isMediaEnd, setIsMediaEnd] = useState(false);

  const [thumbnail, setThumbnail] = useState('');
  const [isShowControlBar, setIsShowControlBar] = useState(false);

  const {checkAd} = useMainStore();

  const onAutoPlayBlock = (event) => {
    setIsAutoPlayBlocked(true);
  };

  const onPlay = (event) => {
    setIsMediaEnd(false);
    setIsAutoPlayBlocked(false);
    setIsShowControlBar(true);
  };

  const onDurationChange = (event) => {
    if (event?.duration) {
      setDuration(event.duration);
    }
  };

  const onTimeUpdate = (event) => {
    if (event?.currentTime) {
      setCurrentTIme(event.currentTime);
    }
  };

  const onEnd = () => {
    setIsMediaEnd(true);
  };

  const {
    videoRef,
    isPlayerLoaded,
    isCanPlay,
    muted,
    setMuted,
    volume,
    setVolume,
    playing,
    setPlaying,
    wasPlaying,
    duration,
    setDuration,
    currentTIme,
    setCurrentTIme,
    showPoster,
  } = useLivePlayer({
    player: bannerAdPlayer,
    playerType,
    autoPlay: true,
    loop,
    muted: true,
    volume: 0.5,
    onAutoPlayBlock,
    onPlay,
    onDurationChange,
    onTimeUpdate,
    onEnd,
    onError,
    shouldPlayWhenVisible: true,
  });

  const {intersectionRef, isIntersecting} = useIntersection({threshold: 0.25});

  useEffect(() => {
    if (adInfo?.creatives[0]?.thumbnailSrc) {
      setThumbnail(adInfo?.creatives[0]?.thumbnailSrc);
    }
  }, []);

  useEffect(() => {
    if (bannerAdPlayer.current && isPlayerLoaded) {
      console.log(bannerAdPlayer);
      if (adInfo) {
        bannerAdPlayer.current.loadVastAd({...adInfo});
        if (checkAd.AdParameter.hasOwnProperty('sendTracking')) {
          checkAd.AdParameter.sendTracking(adInfo.creatives[0]?.impressionUrls);
          checkAd.AdParameter.sendTracking(adInfo.creatives[0]?.viewableUrls);
        }
      }
    }
  }, [isPlayerLoaded]);

  //배너 노출 여부를 visible로 받았었는데 차피 배너 노출이 안되면 intersection 값 떄문에 동작 잘 되길래 intersection만 넣어봄
  useEffect(() => {
    if (isCanPlay.current) {
      setPlaying(isIntersecting);
    }
  }, [isIntersecting]);

  const sendClickTracking = () => {
    if (checkAd.AdParameter.hasOwnProperty('sendTracking')) {
      console.log('BannerAdPlayer clickUrls ', adInfo.creatives[0]?.clickUrls);
      checkAd.AdParameter.sendTracking(adInfo.creatives[0]?.clickUrls);

      const subVastInfo = VastInfo.getVastInfo(adInfo.creatives[0].vast2);
      checkAd.AdParameter.sendTracking(subVastInfo.videoClicks.clickTracking);
    }
  };

  return (
    <>
      <BasePlayer
        ref={intersectionRef}
        videoRef={videoRef}
        playerType={playerType}
        autoPlay={true}
        muted={muted}
        thumbnail={thumbnail}
        showPoster={showPoster}>
        {isAutoPlayBlocked ? (
          <div className='center_btn'>
            <button
              type='button'
              className='btn_cneter_play'
              onClick={() => {
                setPlaying(true);
              }}>
              <span>PLAY</span>
            </button>
          </div>
        ) : (
          isShowControlBar && (
            <VideoControlBar
              playerRef={bannerAdPlayer}
              loop={loop}
              playing={playing}
              setPlaying={setPlaying}
              muted={muted}
              setMuted={setMuted}
              volume={volume}
              setVolume={setVolume}
              currentTime={currentTIme}
              setCurrentTime={setCurrentTIme}
              duration={duration}
              isMediaEnd={isMediaEnd}
              isSeekable={false}></VideoControlBar>
          )
        )}
        <div className='da_area'>
          {adInfo?.creatives[0]?.landingUrl && (
            <a
              href={adInfo?.creatives[0]?.landingUrl}
              target='_blank'
              onClick={sendClickTracking}></a>
          )}
        </div>
      </BasePlayer>
    </>
  );
};
