import MyApi from '@/api/my';
import throwToast from '@/utils/ui/toast';

/**
 * @typedef GroupStreamer
 * @property {boolean} already - 이미 추가된 스트리머 여부
 * @property {number} isFan - 팬 여부
 * @property {boolean} isFavorite - 즐겨찾기 여부
 * @property {boolean} isPin - 고정 여부
 * @property {number} isSubscription - 구독 여부
 * @property {string} profileImg - 프로필 이미지 URL
 * @property {string} userId - BJ 사용자 아이디
 * @property {string} userNick - BJ 닉네임
 */

const MyFavoriteApi = {
  /**
   * 즐겨찾기 스트리머 목록 조회
   * @desc 즐겨찾기 페이지에서만 쓰인다
   * @param {string} groupId - 즐겨찾기 그룹 ID
   * @returns {Promise<Array>} favorites
   */
  async getFavorites(groupId) {
    try {
      const {data: favorites, totalCnt: totalCount} = await MyApi.get(
        `/api/favorite${groupId ? `/${groupId}` : ''}`,
      );
      return {favorites: favorites || [], totalCount: totalCount || 0};
    } catch (error) {
      return {favorites: [], totalCount: 0};
    }
  },
  /**
   * @deprecated
   * 기존에 getFavorites API 의 응답을 100개로 제한해 주기로 변경하였으나
   * 많은 사이드 이펙트로 인해 현재는 응답값이 똑같게 되었음
   * 따라서 `getFavorites` 를 사용해야 한다
   *
   * 즐겨찾기 스트리머 목록 조회
   * @desc 스트리머 관리 및 추가 다이얼로그에서만 쓰인다
   * @param {string} groupId - 즐겨찾기 그룹 ID
   * @returns {Promise<Array>} favorites
   */
  async getFavoriteStreamers(groupId) {
    try {
      const {data: favorites} = await MyApi.get(
        `/api/favorite${groupId ? `/${groupId}` : ''}`,
      );
      return (favorites || []).flat();
    } catch (error) {
      return [];
    }
  },
  /**
   * 스트리머  즐겨찾기 여부
   * @param {string} userId - BJ 사용자 아이디
   * @returns {Promise<boolean>} 즐겨찾기 여부
   */
  async getIsFavorite(userId) {
    try {
      const {result} = await MyApi.get(`/api/favorite/${userId}/relation`);
      return result || false;
    } catch (error) {
      return false;
    }
  },
  /**
   * 즐겨찾기 채널 새 글 조회
   * @param {Object} params - API 호출 파라미터
   * @param {string} params.group_idx - 즐겨찾기 그룹 ID
   * @returns {Promise<Array>} feed
   */
  async getFeed(params) {
    try {
      const {data: feed} = await MyApi.get('/api/feed', {
        index_reg_date: 0,
        user_id: '',
        is_bj_write: 1,
        feed_type: 'POST_PHOTO',
        ...params,
      });
      return feed || [];
    } catch (error) {
      return [];
    }
  },
  /**
   * 즐겨찾기 그룹 목록 조회
   * @param {string} [userId] - BJ 사용자 아이디
   * @returns {Promise<Array>} favoriteGroups
   */
  async getFavoriteGroups(userId) {
    try {
      const {data: favoriteGroups} = await MyApi.get(
        '/api/favorite/group/list',
        {
          ...(userId && {favorite_id: userId}),
        },
      );
      return favoriteGroups || [];
    } catch (error) {
      return [];
    }
  },
  /**
   * 즐겨찾기 캐치 스토리 조회
   * @param {string} groupId - groupId
   * @returns {Promise<Array>} favoriteGroups
   */
  async getCatchStory(groupId) {
    try {
      const {data: favoriteCatchStories} = await MyApi.get(
        `/api/favorite/catchstory${groupId ? `/${groupId}` : ''}`,
      );
      return favoriteCatchStories || [];
    } catch (error) {
      return [];
    }
  },
  /**
   * 즐겨찾기 VOD 조회
   * @param {string} groupId - groupId
   * @returns {Promise<Array>} favoriteGroups
   */
  async getVod(groupId) {
    try {
      const {data: favoriteVods} = await MyApi.get(
        `/api/favorite/vod${groupId ? `/${groupId}` : ''}`,
      );
      return favoriteVods || [];
    } catch (error) {
      return [];
    }
  },
  /**
   * 즐겨찾기 유저클립 조회
   * @param {string} groupId - groupId
   * @returns {Promise<Array>} userclips
   */
  async getUserClip(groupId) {
    try {
      const {list} = await MyApi.get(
        `/api/favorite/userclip${groupId ? `/${groupId}` : ''}`,
      );
      return list || [];
    } catch (error) {
      return [];
    }
  },
  /**
   * 즐겨찾기 캐치 조회
   * @param {string} groupId
   * @returns {Promise<Array>} favoriteCatches
   */
  async getCatch(groupId) {
    try {
      const {list: favoriteCatches} = await MyApi.get(
        `/api/favorite/catch${groupId ? `/${groupId}` : ''}`,
      );
      return favoriteCatches || [];
    } catch (error) {
      return [];
    }
  },
  /**
   * 즐겨찾기 추천 스트리머 조회
   * @returns {Promise<Array>} favoriteRecommendStreamers
   */
  async getRecommendStreamers() {
    try {
      const {data: favoriteRecommendStreamers} = await MyApi.get(
        // API 경로가 오타로 들어가있음
        '/api/favorite/recommand',
      );
      return favoriteRecommendStreamers || [];
    } catch (error) {
      return [];
    }
  },
  /**
   * 즐겨찾기 스트리머 고정
   * @param {string} userId - BJ 사용자 아이디
   */
  async setPin(userId) {
    try {
      const formData = new FormData();
      formData.append('common_no', userId);
      const data = await MyApi.post('/api/favorite/pin', formData);
      return data;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 즐겨찾기 스트리머 고정 해제
   * @param {string} userId - BJ 사용자 아이디
   */
  async deletePin(userId) {
    try {
      const formData = new FormData();
      formData.append('common_no', userId);
      formData.append('_method', 'delete');
      const data = await MyApi.post(`/api/favorite/pin/${userId}`, formData);
      return data;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 즐겨찾기 스트리머 푸시 설정
   * @param {string} userId - BJ 사용자 아이디
   * @param {boolean} isAlarmOn - 푸시 알림 설정 여부
   */
  async setPush(userId, isAlarmOn) {
    try {
      const data = await MyApi.post(
        `/api/favorite/${userId}/push?flag=${isAlarmOn ? '1' : '0'}`,
        {
          _method: 'put',
        },
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 즐겨찾기 스트리머 추가
   * @param {Object} params - API 호출 파라미터
   * @param {string} params.favoriteId - 즐겨찾기 그룹 ID
   * @param {string[]} params.groupIds - 추가할 즐겨찾기 그룹 ID 목록
   */
  async putFavoriteGroup(params) {
    try {
      const response = await MyApi.post(
        '/api/favorite/group/put',
        {
          _method: 'put',
          favorite_id: params.favoriteId,
          group_idx_list: params.groupIds.join(','),
        },
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  /**
   *
   * @param {Object} params
   * @param {string[]} params.favoriteIds - 즐겨찾기 그룹에 추가할 할 유저 ID
   * @param {string} params.title - 추가할 즐겨찾기 그룹 이름
   * @returns
   */
  addFavoriteGroup: async (params) => {
    try {
      const response = await MyApi.post(
        '/api/favorite/group/add',
        {
          favorite_id_list: params.favoriteIds.join(',') || '',
          title: params.title,
        },
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 즐겨찾기 스트리머 삭제
   * @param {string} userId
   */
  async deleteFavorite(userId) {
    try {
      const response = await MyApi.post(`/api/favorite/${userId}`, {
        _method: 'delete',
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  /**
   *
   * @param {string} groupId - 즐겨찾기 그룹 ID
   * @returns {Promise<{group: {title}, list: GroupStreamer[]}>} - 즐겨찾기 그룹 정보
   */
  async getFavoriteGroup(groupId) {
    try {
      const {data: groupInfo} = await MyApi.get(
        `/api/favorite/groupitem/list/${groupId}`,
      );
      return groupInfo;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 즐겨찾기 그룹 업데이트
   * @param {Object} params
   * @param {string[]} params.favoriteIds - 즐겨찾기 그룹에 추가할 할 유저 ID
   * @param {string} params.title - 추가할 즐겨찾기 그룹 이름
   * @param {string} params.groupId - 즐겨찾기 그룹 ID
   * @returns
   */
  async updateFavoriteGroup(params) {
    try {
      const response = await MyApi.post(
        `/api/favorite/group/update/${params.groupId}`,
        {
          _method: 'put',
          favorite_id_list: params.favoriteIds.join(',') || '',
          title: params.title,
        },
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 즐겨찾기 그룹 삭제
   * @param {string} groupId - 즐겨찾기 그룹 ID
   */
  async deleteFavoriteGroup(groupId) {
    try {
      const response = await MyApi.post(
        `/api/favorite/group/delete/${groupId}`,
        {
          _method: 'delete',
        },
      );
      return throwToast('해당 그룹이 삭제되었습니다.');
    } catch (error) {
      throw error;
    }
  },
  /**
   * 즐겨찾기 스트리머 추가
   * @param {string} userId - BJ 사용자 아이디
   */
  async addFavorite(userId) {
    try {
      const formData = new FormData();
      formData.append('user_id', userId);
      const data = await MyApi.post('/api/favorite', formData);
      return data;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 즐겨찾기 그룹 순서 조정
   * @param {string[]} groups - 수정할 group 인덱스 배열
   */
  async updateFavoriteGroupSort(groups) {
    try {
      const response = await MyApi.post('/api/favorite/group/updgroup', {
        _method: 'put',
        group_idx_list: groups.join(','),
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  /**
   * 추천 스트리머 추천 받지 않기
   * @param {Object} param
   * @param {string} param.streamerId - 스트리머 아이디
   * @param {string} param.broadcastId - 방송 아이디 (broadNo, titleNo)
   * @param {string} param.logic - 방송 로직 (?) 추천 방송 API 응답에 있음
   * @param {string[]} param.recommendedStreamerIds - 추천받은 스트리머 아이디 리스트
   * @returns
   */
  async rejectRecommend({
    streamerId,
    broadcastId,
    logic,
    recommendedStreamerIds,
  }) {
    try {
      const response = await MyApi.post('/api/favorite/recommand/reject', {
        bj_id: streamerId,
        broad_no: broadcastId,
        logic,
        recommand_id: JSON.stringify(recommendedStreamerIds),
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default MyFavoriteApi;
