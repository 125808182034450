import {useEffect} from 'react';
import {LiveGroup} from '@/components/main/live/group/LiveGroup';
import {useParams, useNavigate} from 'react-router-dom';

export default function Live() {
  const {type} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!type || !['game', 'bora', 'sports'].includes(type)) {
      navigate(`/live/all`);
    }
  }, [type, navigate]);

  if (type && ['game', 'bora', 'sports'].includes(type)) {
    try {
      return <LiveGroup liveType={type} />;
    } catch (error) {
      // 추후 에러페이지로 리턴할지, 메인으로 리다이렉트시킬지..기획적으로 정해봐야할듯
    }
  }
  return null;
}
