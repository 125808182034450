import {getTimeStamp} from '@/utils/main';
import {getCookie} from '@/utils/main';
/**
 * 금칙어 조회
 * @param {string} m Method명 (필수: stopWord)
 * @param {number} v 버전 (필수: 1.0(현재))
 * @param {string} d 검색키워드 (필수, UTF-8 인코딩한 문자열을 urlencode 한 값)
 * @param {string} t 리턴받을 type (선택, default: json, 예: json, xml)
 * @param {string} c 전달받을 캐릭터셋 (선택, default: UTF-8, 예: EUC-KR, UTF-8)
 * @param {string} callback jsonp 일시 callback 함수 (선택, 예: jQuery 자동 전달되는 함수명)
 * @param {string} w API 호출하는 client 타입 (필수, 예: webk, ios1, adr1, atv1, etc)
 * @param {number} z 압축 사용 여부 (선택, default: 0, 예: 0, 1)
 * @param {string} acttype 검색 타입 구분 (필수, 예: total, live, vod, bj, none)
 * @param {string} uid 사용자 아이디 (로그인 시 필수, 비로그인 시 null 값)
 * @param {string} ut Use Type (필수, 예: sv, tv, nv, cv)
 * @param {string} l 테스트 로직 코드 (선택, 예: df, ms, oc, kc)
 * @param {string} stype 검색 인입 경로 (필수, 예: di, rc, ac, rt, ad)
 * @param {string} original 원본 키워드 (필수)
 * @param {string} location 통합검색, 채널 구분 (필수, 예: total_search)
 * @param {string} modified 수정된 키워드 (필수)
 * @param {string} sckSessionId 요청 고유 세션 키 (필수, 예: _au 쿠키 값 + ‘_’ + 검색 keyword값 + timestamp)
 * @param {string} ac_type 자동완성 키워드 타입 (필수, stype이 ac일 때, 예: bj, contents)
 * @param {number} pk_cnt 개인화 추천 검색어 개수 (필수, stype이 pk일 때, Integer 값)
 * @param {boolean} has_hint 검색어 힌트 여부 (필수, stype이 pk일 때, 예: true, false)
 * @param {number} isHashSearch 해쉬 검색 유무 (필수, 예: 0, 1)
 * @return {Promise<void>}
 */

const uuid = getCookie('_au');
const ad_uuid = getCookie('ad_uuid');
const theme_color = getCookie('theme');
const userTicket = getCookie('UserTicket');

let age = '';
let sex = '';

if (userTicket) {
  age = userTicket?.match(/age%3D(\d+)/)[1];
  sex = userTicket?.match(/sex%3D(\w)/)[1];
}

export const STOPWORD_PARAMETER = {
  FIXED: {
    l: 'DF',
    m: 'stopWord',
    v: 1.0,
    w: 'webk',
    ut: 'sv',
    uuid,
    ad_uuid,
    theme_color,
    age,
    sex,
  },
  OPTIONAL: {
    d: '',
    acttype: '',
    uid: null,
    stype: '',
    original: '',
    location: '',
    modified: '',
    sckSessionId: '',
    ac_type: '',
    pk_cnt: '',
    has_hint: '',
    isHashSearch: '',
  },
};
/**
 *
 *@param {string} m	필수: profileTheme	profileTheme	메쏘드명
 *@param {string} v	필수: 6.0(현재)	1.0 (프로필 개편 전), 2.0(프로필 개편 후), 3.0(캐치, 게임 프로필 후) 4.0(광고, 테마 분리 후), 5.0(vod 캐치스토리 추가), 6.0(최신 SOOP 검색개편)	버전
 *@param {string} d	필수	봉준, 남순, 엣지	검색키워드 (utf-8 인코딩된 문자열을 urlencode 한 값을 전달)
 *@param {string} pttype	선택 (default : all)	profile, theme, all, (빈 값)	API 호출 타입
 *@param {string}w	필수	pc: webk, ios: ios1, android: adr1, 플레이어 1.0: atv1, 플레이어 2.0: atv2, 기타:etc	호출하는 경로
 *@param {number}isMobile	필수	1, 0	1 = Mobile
 *@param {string} date	필수	yyyy-mm-dd	게임 프로필 경기 일정
 *@param {string} order	선택 (default : current_sum_viewer)	current_sum_viewer, broad_start	게임프로필 관련 bj 정렬
 *@param {string} orderBy	선택 (default : desc)	desc, asc	정렬 순서
 */
export const PROFILE_THEME_PARAMETER = {
  FIXED: {
    l: 'DF',
    m: 'profileTheme',
    v: '6.0',
    // t: 'json',
    // c: 'UTF-8',
    w: 'webk',
    pttype: 'all',
    isMobile: '0', // 설마 모웹 할때.. 바뀌야 하지는 않곘지..
    location: 'total_search',
    tab: 'total',
  },
  OPTIONAL: {
    d: '',
    date: '',
    order: '',
    orderBy: '',
  },
};

/**
 *
 * @param {string} v 버전 (live 현재 버전 1.0)
 * @param {string} m 메서드명 LIVE (liveSearch)
 * @param {string} c 언어 인코딩 UTF-8
 * @param {string} w 호출 경로
 * @param {number} hl 검색어 하이라이닝 기능  1
 * @param {string} tab 검색한 현재 탭 코드 TOTAL, LIVE, VOD, POST, BJ
 * @param {number} onlyParent 본방 노출 1
 * @param {string} szKeyword 검색키워드
 * @param {Number} isHashSearch 해시태그 검색 여부
 * @param {Number} nPageNo 페이지 번호
 * @param {Number} nListCnt [페이지당 결과수]
 * @param {string} szType	선택 (default: json)	json, xml	리턴받을 type
 * @param {string} szOrder	선택 (default: 정확도)	rank, pc_total_view_cnt, broad_start, broad_bps, broad_resolution	정렬방식
 * @param {string} szOrderType	선택 (default:desc)	desc, asc	정렬기준
 * @param {string} location 접근하는 페이지 코드 total_search, main, live, vod, station
 */
export const LIVE_PARAMETER = {
  FIXED: {
    l: 'DF',
    m: 'liveSearch',
    c: 'UTF-8',
    w: 'webk',
    isMobile: 0, // 0(웹 통합 검색) or 1
    onlyParent: 1,
    szType: 'json',
  },
  OPTIONAL: {
    v: '1.0',
    isHashSearch: false,
    szKeyword: '',
    nPageNo: 1,
    nListCnt: 12,
    tab: '',
    location: '',
    szOrder: '',
    szOrderType: '', //asc, desc
  },
};
/**
 * Vod
 * @param {string} m 메서드명 - VOD (필수: vodSearch)
 * @param {string} v 버전 (최신 버전은 5.0)
 * @param {string} c UTF-8
 * @param {string} szType 리턴받을 타입
 * @param {string} szTabType 검색 Tab 타입
 * @param {string} szOrder 정렬방식
 * @param {string} szOrderType 정렬타입
 * @param {string} szFileType 카테고리 필터링
 * @param {string} szTerm 기간 필터링
 * @param {string} szKeyword 검색키워드
 * @param {Number} nPageNo 페이지 번호
 * @param {Number} nListCnt 페이지당 결과수- ** 쓸지 몰라서 안넣어둠..
 * @param {string} nCateNo 카테고리 넘버
 * @param {Number} isMobile 모바일 호출 여부
 * @param {Number} hl 검색어 하이라이팅 기능 옵션
 * @param {Number} nRecommYn 추천 영상 필터링 여부
 * @param {string} l 검색 로직 식별 값
 * @param {string} w 호출 경로
 * @param {Number} ut useType- 기입 예정이라 안넣어둠...
 * @param {Number} verbose 검색 상세 정보 출력 여부
 * @param {Number} bAdultFlag 19금 동영상 제외 여
 * @param {string} align_type 리스트 타입
 * @param {Number} isHashSearch 해시태그 검색 여부
 * @param {string} szVideoType 비디오 타입 - ** 쓸지 몰라서 안넣어둠..
 * @param {string} szBbsNo 게시판 번호 - ** 쓸지 몰라서 안넣어둠..
 * @param {string} szTitleNo 영상 번호 - ** 쓸지 몰라서 안넣어둠..
 * @return {Promise<void>}
 */
export const VOD_PARAMETER = {
  FIXED: {
    // 고정, 건들지 마세요
    l: 'DF',
    m: 'vodSearch',
    w: 'webk',
    isMobile: 0, // 0(웹 통합 검색) or 1
    szType: 'json', // json, xml
    _: getTimeStamp(),
    // default
    c: 'UTF-8',
  },
  OPTIONAL: {
    // 선택적 옵션
    v: '5.0', // 태그일 때는 4.0 기본은 5.0
    tab: 'TOTAL', // 기본 TOTAL, VOD, HASH
    isHashSearch: 0,
    szKeyword: '',
    szTabType: 'total', // total_search
    szFileType: 'ALL', //NORMAL, REVIEW, HIGHLIGHT, EDITOR, CLIP, CATCH
    szTerm: '1year', //1day, 1week, 1month, 1year
    nPageNo: 1,
    nListCnt: 12,
    location: 'total_search', // 이건 검색바에서 진행 할것, 검색 페이지를 어디에서 진입 했는가?

    szOrder: '', //reg_date, view_cnt , like_cnt , recomm_cnt
  },
};

export const getSelectParameter = (params) => {
  return Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== null && value !== '',
    ),
  );
};
