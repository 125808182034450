'use client';
import {useMemo, useState} from 'react';
import throwToast from '@/utils/ui/toast';
import MyPlusApi from '@/api/main/my-plus';
import globalDomains from '@/utils/global-domains';
import {getTimeStamp} from '@/utils/main';
import {DIRECTORY_LIST_CNT, MAIN_LIST_CNT} from '@/constants/main';

export const useHiddenStreamer = (type = '', location = '') => {
  const [remainCount, setRemainCount] = useState(0);
  const [slicedBroadList, setSlicedBroadList] = useState([]);
  const [hiddenStreamerList, setHiddenStreamerList] = useState([]);

  const handleOnClickHiddenStreamer = (data) => {
    setHiddenStreamer(data);
  };

  const handleOnClickUndoHiddenStreamer = (data) => {
    setHiddenUndoStreamer(data);
  };

  const filteredHiddenStreamerBroadList = useMemo(() => {
    return (list, type, hiddenStreamer) => {
      return list.filter((item) => {
        if (type === 'live') {
          return !hiddenStreamer.includes(item.userId);
        } else {
          // 원저작자 -> 업로더 순으로 우선순위
          if (item.originalUserId) {
            return !hiddenStreamer.includes(item.originalUserId);
          } else {
            return !hiddenStreamer.includes(item.userId);
          }
        }
      });
    };
  }, [type, hiddenStreamerList]); // type과 hiddenStreamerList가 변경될 때만 다시 계산

  const filterBroadList = (
    type = '', // live , vod, catch
    page = 1, //페이지
    list = [], //방송리스트
    isMore = false, //더보기 여부
    hiddenStreamer = [], //스트리머 숨기기 리스트
    setBroadList, //setState 콜백
  ) => {
    const filteredBroadList = filteredHiddenStreamerBroadList(
      list,
      type,
      hiddenStreamer,
    );

    const LIST_COUNT = type === 'live' ? MAIN_LIST_CNT : DIRECTORY_LIST_CNT;
    if (remainCount === 0) {
      if (isMore) {
        if (slicedBroadList.length === 0) {
          if (LIST_COUNT > filteredBroadList.length) {
            setRemainCount(Number(LIST_COUNT - filteredBroadList.length));
          }
        } else {
          if (LIST_COUNT > slicedBroadList.length) {
            setRemainCount(Number(LIST_COUNT - slicedBroadList.length));
          }
        }
      } else {
        if (list.length <= LIST_COUNT && page > 1) {
          setRemainCount(Number(LIST_COUNT - list.length));
        }
      }

      if (slicedBroadList.length > 0) {
        let uniqueBroadList = [
          ...slicedBroadList,
          ...filteredBroadList.filter(
            (item) =>
              !slicedBroadList.some(
                (slicedItem) => slicedItem.userId === item.userId,
              ),
          ),
        ];

        if (uniqueBroadList.length > filteredBroadList.length) {
          if (!isMore) {
            setBroadList((prevState) => ({
              isMore: isMore,
              list: [
                ...prevState.list,
                ...uniqueBroadList.slice(0, LIST_COUNT),
              ],
            }));
            setSlicedBroadList([...uniqueBroadList.slice(LIST_COUNT)]);
          }
        }
      } else {
        setBroadList((prevState) => ({
          isMore: isMore,
          list:
            page === 1
              ? [...filteredBroadList]
              : [...prevState.list, ...filteredBroadList],
        }));
      }
    } else {
      //여기서 LIST_COUNT 갯수만큼 넘었을때 잘라야하는걸까?
      if (remainCount < filteredBroadList.length) {
        setBroadList((prevState) => ({
          isMore: isMore,
          list: [
            ...prevState.list,
            ...slicedBroadList,
            ...filteredBroadList.slice(0, remainCount),
          ],
        }));
        setSlicedBroadList([...filteredBroadList].slice(remainCount));
        setRemainCount(0);
      } else {
        setBroadList((prevState) => ({
          isMore: isMore,
          list: [
            ...prevState.list,
            ...[...slicedBroadList, ...filteredBroadList].slice(0, remainCount),
          ],
        }));
        setSlicedBroadList(
          [...slicedBroadList, ...filteredBroadList].slice(remainCount),
        );
        setRemainCount(Number(remainCount - filteredBroadList.length));
      }
    }
  };

  //스트리머 숨기기 실행
  const setHiddenStreamer = async (data) => {
    const {broadcastId, userId, userNickname, listDataType, fileType} = data;
    try {
      const params = {
        bj_id: userId,
        bj_nick: userNickname,
        type: type,
        location: location,
        os: 'pc',
        ...(listDataType && {list_data_type: listDataType}),
        namespace: getTimeStamp(),
        key: getTimeStamp(),
      };
      if (type === 'LIVE') {
        params.broad_no = broadcastId;
      } else if (type === 'VOD') {
        params.title_no = broadcastId;
      } else if (type === 'CATCH') {
        params.title_no = broadcastId;
        params.type = fileType;
      } else if (type === 'CATCHSTORY') {
        //캐치스토리섹션에 캐치스토리가 부족하면 캐치가 내려온다.
        if (fileType === 'CATCH') {
          params.title_no = broadcastId;
          params.type = 'VOD';
        } else {
          params.story_idx = broadcastId;
          params.type = 'CATCHSTORY';
        }
      }
      const response = await MyPlusApi.setHiddenStreamer(params);
      if (response) {
        if (response.result === 1) {
          setHiddenStreamerList((prevState) => [...prevState, broadcastId]);
        } else {
          if (response.data.code === -24202) {
            //100명이상 초과했을시 내려오는 에러코드번호
            let replaceMsg = '';
            if (response.data.message) {
              replaceMsg = response.data.message.split('.');
            } else {
              replaceMsg = response.data.message;
            }
            throwToast(
              '',
              'side',
              <>
                <p>
                  {replaceMsg[0]}
                  <br />
                  {replaceMsg[1]}
                </p>
                <div className='btn'>
                  <a
                    href={`${globalDomains.MEMBER_AFREECATV}/app/bj_hide_info.php`}
                    target='_blank'>
                    숨긴 스트리머
                    <br /> 관리
                  </a>
                </div>
              </>,
              5000,
            );
          } else {
            throwToast(response.data.message, '', null, 5000);
          }
        }
      }
    } catch (error) {}
  };

  //스트리머 숨기기 취소
  const setHiddenUndoStreamer = async (data) => {
    const {broadcastId, userId, listDataType, fileType} = data;
    try {
      const params = {
        bj_id: userId,
        type: type,
        location: location,
        ...(listDataType && {list_data_type: listDataType}),
      };
      if (type === 'LIVE') {
        params.broad_no = broadcastId;
      } else if (type === 'VOD') {
        params.title_no = broadcastId;
      } else if (type === 'CATCH') {
        params.title_no = broadcastId;
        params.type = fileType;
      } else if (type === 'CATCHSTORY') {
        //캐치스토리섹션에 캐치스토리가 부족하면 캐치가 내려온다.
        if (fileType === 'CATCH') {
          params.title_no = broadcastId;
          params.type = 'VOD';
        } else {
          params.story_idx = broadcastId;
          params.type = 'CATCHSTORY';
        }
      }

      const response = await MyPlusApi.setHiddenUndoStreamer(params);
      if (response) {
        if (response.result === 1) {
          setHiddenStreamerList((prevState) =>
            prevState.filter((id) => id !== broadcastId),
          );
        } else {
          throwToast(response.data.message);
        }
      }
    } catch (error) {}
  };

  const state = {
    remainCount,
    slicedBroadList,
    hiddenStreamerList,
  };

  const handler = {
    setRemainCount,
    filterBroadList,
    setSlicedBroadList,
    handleOnClickHiddenStreamer,
    handleOnClickUndoHiddenStreamer,
  };

  return {...state, ...handler};
};
