import {useRef, useState} from 'react';
import {Link, useSearchParams} from 'react-router-dom';

import MyProfileImg from '@/components/my/common/item/MyProfileImg';
import {ChipCategory} from '@/components/search/common';
import useConvertRegDate from '@/components/main/common/hooks/useConvertRegDate';
import {useBroadcastContext} from '@/components/main/common/Broadcast/BroadcastProvider';
import {SoopUiContextmenuRework, SoopUiProfileIconMenu} from '@/components/ui';

import globalDomains from '@/utils/global-domains';
import {convertViewCnt} from '@/utils/main';
import {sendLegacyInflowPathLog} from '@/utils/main/my/log';
import {convertToArray, decodeHtmlEntities} from '@/utils';
import useMyStore from '@/stores/my';
import useFavoriteStore from '@/stores/search';
import {
  BROADCAST_ITEM_DISPLAY_TYPE,
  BROADCAST_TYPES,
  VOD_TYPES,
} from '@/constants';
import {ROUTE} from '@/constants/main/route';

const BroadcastInfoMain = ({children}) => {
  const {
    viewType = BROADCAST_ITEM_DISPLAY_TYPE.GRID_VIEW,
    contextMenuOptions,
    title,
    thumbnailUrl,
    userId,
    userNickname,
    broadcastId,
    broadcastType,
    contextMenu,
    vodType,
    liveType,
    handleMenuClicked,
    score,
    listDataType,
    broadcastLink,
    ...rest
  } = useBroadcastContext();

  return (
    <div className='cBox-info'>
      {viewType === BROADCAST_ITEM_DISPLAY_TYPE.GRID_VIEW && (
        <InfoGridView {...rest}>{children}</InfoGridView>
      )}
      {viewType === BROADCAST_ITEM_DISPLAY_TYPE.LIST_VIEW && (
        <InfoListView {...rest}>{children}</InfoListView>
      )}
      {contextMenu.menuList.length > 0 && (
        <SoopUiContextmenuRework
          broadcastType={broadcastType}
          handleMenuClicked={handleMenuClicked}
          menuList={contextMenu.menuList}
          option={{
            title,
            thumbnailUrl,
            userId,
            userNickname,
            broadcastId,
            appScheme: contextMenu.appScheme,
            stationNo: contextMenu.stationNo,
            fileType: broadcastType === 'vod' ? vodType : liveType,
            vodType,
            listDataType,
            score,
          }}
        />
      )}
    </div>
  );
};

const InfoListView = ({children}) => {
  const soopUiProfileIconMenuThumbnail = useRef(null);
  const {getStreamers, getLiveBroadcasts} = useMyStore();
  const {toggleFavorite, setFavoriteStreamerName} = useFavoriteStore();
  const {
    broadcastType,
    userId,
    userNickname,
    isSubscribe,
    isFan,
    searchActionType,
    vodType,
    handleSectionClick,
  } = useBroadcastContext();

  const handleProfileIconMenuEvent = (event) => {
    const {eventName, data} = event;

    if (eventName === 'deleteFavorite') {
      if (location.pathname === ROUTE.MY.FAVORITE) {
        getStreamers();
        getLiveBroadcasts();
      }
      // 검색페이지에서 즐겨찾기 했을 경우 프로필테마/스트리머영역 즐겨찾기 관련 동기화를 위한 로직
      toggleFavorite();
      setFavoriteStreamerName(data.id);
    }
  };

  const handleProfileClick = (e) => {
    e.preventDefault();

    if (userId) {
      handleSectionClick('sti');

      window.open(`${globalDomains.BJ_AFREECATV}/${userId}`);
    }
  };

  if (broadcastType !== 'vod' && broadcastType !== 'live') {
    throw new Error('broadcastType must be "live" or "vod".');
  }

  if (broadcastType === 'vod' && !vodType) {
    throw new Error('vodType is required.');
  }

  return (
    <>
      <div className='details'>
        <div className='thumb_warp'>
          <Link
            to={`${globalDomains.BJ_AFREECATV}/${userId}`}
            className='thumb'
            target='_blank'
            onClick={handleProfileClick}>
            <MyProfileImg userId={userId} />
          </Link>
          <div className='nick_wrap'>
            <button
              className='nick'
              style={{cursor: 'pointer'}}
              role='link'
              onClick={() => {
                soopUiProfileIconMenuThumbnail.current?.open();
                // ICT 메뉴
                handleSectionClick('nick');
              }}>
              <span>{userNickname}</span>
            </button>
            {/*둘다(구독,팬) 노출이 있어 각 컴포넌트 별로 관리*/}
            {isSubscribe && <span className='grade-badge-subscribe'>구독</span>}
            {isFan && <span className='grade-badge-fan'>F</span>}
          </div>
        </div>
        <SoopUiProfileIconMenu
          ref={soopUiProfileIconMenuThumbnail}
          streamerId={userId}
          streamerNick={userNickname}
          handleMenuButtonClicked={handleProfileIconMenuEvent}
          searchArea={searchActionType}
        />
        {children}
      </div>
    </>
  );
};

const InfoGridView = ({children}) => {
  const soopUiProfileIconMenuNickname = useRef(null);
  const {getStreamers, getLiveBroadcasts} = useMyStore();
  const {toggleFavorite, setFavoriteStreamerName} = useFavoriteStore();
  const {
    userId,
    userNickname,
    profileImageUrl,
    isCinety,
    isSubscribe,
    isFan,
    handleSectionClick,
    searchActionType,
  } = useBroadcastContext();

  const handleProfileClick = (e) => {
    e.preventDefault();

    if (userId) {
      handleSectionClick('sti');

      window.open(`${globalDomains.BJ_AFREECATV}/${userId}`);
    }
  };

  const handleProfileIconMenuEvent = (event) => {
    const {eventName, data} = event;

    if (eventName === 'deleteFavorite') {
      if (location.pathname === ROUTE.MY.FAVORITE) {
        getStreamers();
        getLiveBroadcasts();
      }
      // 검색페이지에서 즐겨찾기 했을 경우 프로필테마/스트리머영역 즐겨찾기 관련 동기화를 위한 로직
      toggleFavorite();
      setFavoriteStreamerName(data.id);
    }
  };

  const hasSubscribeBadge =
    isSubscribe && !location.pathname.includes('/my/subscribe');
  const hasFanBadge = isFan && !location.pathname.includes('/my/fan');

  return (
    <>
      <Link
        to={`${globalDomains.BJ_AFREECATV}/${userId}`}
        target='_blank'
        className='thumb'
        onClick={handleProfileClick}>
        <MyProfileImg userId={userId} adminProfileImg={profileImageUrl} />
      </Link>
      <div className='details'>
        <div className='nick_wrap'>
          <a
            className='nick'
            style={{cursor: 'pointer'}}
            target='_blank'
            onClick={(e) => {
              //오리지날 콘텐츠는 프로필아이콘 기능을 지원하지 않는다.
              if (!isCinety) {
                e.preventDefault();
                soopUiProfileIconMenuNickname.current?.open();

                handleSectionClick('nick');
              } else {
                window.open(`${globalDomains.CINETY_AFREECATV}`);
              }
            }}>
            <span>{userNickname}</span>
          </a>
          {hasSubscribeBadge && (
            <span className='grade-badge-subscribe'>구독</span>
          )}
          {hasFanBadge && <span className='grade-badge-fan'>F</span>}
        </div>
        {!isCinety && (
          <SoopUiProfileIconMenu
            ref={soopUiProfileIconMenuNickname}
            streamerId={userId}
            streamerNick={userNickname}
            handleMenuButtonClicked={handleProfileIconMenuEvent}
            searchArea={searchActionType}
          />
        )}
        {children}
      </div>
    </>
  );
};

const Title = () => {
  const {
    broadcastType,
    broadcastLink,
    vodType,
    title,
    hasDrops,
    log,
    broadcastId,
    userId,
    hashTags,
    categoryTags,
    isUserClip,
    originalUserId,
    handleSectionClick,
    createdDate,
    setIsTitleHover,
  } = useBroadcastContext();
  const isVod = broadcastType === 'vod';
  const [searchParams] = useSearchParams();
  const searchKeyword = searchParams.get('szKeyword');

  const handleInflowPath = () => {
    let inflowPathRequestParameter = {
      broadcastId: broadcastId,
      streamerId: userId,
      type: isVod ? 'vod' : 'live',
      moreData: {
        contentsType: isVod ? 'vod' : 'live',
        hashTags: convertToArray(hashTags),
        categoryTags: convertToArray(categoryTags),

        exCategoryNo: broadcastId,
        ...(searchKeyword && {skey: searchKeyword}),
        ...(log.groupName && {groupName: log.groupName}),
        ...(log.groupType && {groupType: log.groupType}),
        ...(log.listDataType && {listDataType: log.listDataType}),
        isUserClip,
      },
      ...(Object.keys(log?.data).length > 0 && {
        isLogSendData: log.data,
      }),
    };

    if (isVod) {
      inflowPathRequestParameter = {
        ...inflowPathRequestParameter,
        isLogSendData: {
          uploader: userId,
          ...log.data,
        },
        streamerId: originalUserId ? originalUserId : userId,
      };
    }
    // 기존 유입 로그 전송
    sendLegacyInflowPathLog(inflowPathRequestParameter);
  };

  const handleTitleMouseEntered = () => {
    setIsTitleHover(true);
  };
  const handleTitleMouseLeaved = () => {
    setIsTitleHover(false);
  };

  const getFormattedDate = () => {
    if (createdDate) {
      const broadDate = new Date(createdDate);
      return `${broadDate.getMonth() + 1}월 ${broadDate.getDate()}일`;
    }

    return '';
  };

  return (
    <h3 className='title'>
      <Link
        to={broadcastLink}
        target='_blank'
        title={decodeHtmlEntities(title)}
        onMouseEnter={handleTitleMouseEntered}
        onMouseLeave={handleTitleMouseLeaved}
        onClick={() => {
          // sckact 로그 전송
          handleSectionClick('tt');
          // 유입경로 로그
          handleInflowPath();
        }}>
        {!!hasDrops && (
          <>
            <span className='ic_itemdrop'>드롭스 이벤트 진행 중</span>
            <i className='tip' tip='드롭스 이벤트 진행 중'></i>
          </>
        )}
        <span />
        {broadcastType === 'vod' && vodType === VOD_TYPES.CATCH_STORY
          ? `${getFormattedDate()} 스토리`
          : decodeHtmlEntities(title)}
      </Link>
    </h3>
  );
};

const Chips = () => {
  const {
    broadcastType,
    chipCategoryOptions,
    viewCount,
    createdDate,
    categoryName,
    autoHashTags,
    categoryTags,
    hashTags,
    vodType,
    handleSectionClick,
  } = useBroadcastContext();
  const {convertRegDate} = useConvertRegDate();
  return (
    <>
      {broadcastType === 'vod' && (
        <div className='vod_info'>
          {vodType !== VOD_TYPES.CATCH_STORY && (
            <span data-testid='views' className='views'>
              {convertViewCnt(viewCount)}
            </span>
          )}
          <span data-testid='created-date' className='date'>
            {convertRegDate(createdDate)}
          </span>
        </div>
      )}
      {(broadcastType === 'LIVE' || vodType !== VOD_TYPES.CATCH_STORY) && (
        <ChipCategory
          categoryName={categoryName}
          autoHashTags={autoHashTags}
          categoryTags={categoryTags}
          hashTags={hashTags}
          searchArea={broadcastType}
          hashType={
            broadcastType === BROADCAST_TYPES.VOD && vodType === 'CATCH'
              ? 'CATCH'
              : broadcastType.toUpperCase()
          }
          onClickLog={() => {
            handleSectionClick('hash');
          }}
          {...chipCategoryOptions}
        />
      )}
    </>
  );
};

const TitleHistory = () => {
  const [titleHistoryExpandClass, setTitleHistoryExpandClass] = useState('');
  const {userId, broadcastId, titleHistory, handleSectionClick} =
    useBroadcastContext();

  const handleTitleHistoryOpened = () => {
    setTitleHistoryExpandClass((prevItem) =>
      prevItem === 'expand' ? '' : 'expand',
    );
    handleSectionClick('oth');
  };

  if (!titleHistory || titleHistory.length < 1) {
    return null;
  }

  return (
    <div className={`time_list_box ${titleHistoryExpandClass}`}>
      <ul className='time_list'>
        {titleHistory.map((history, index) => {
          return (
            <li key={`${broadcastId}_review_history_${index}`}>
              <Link
                to={`${globalDomains.VOD_AFREECATV}/player/${broadcastId}?change_second=${history.changeSecond}`}
                target='_blank'
                onClick={() => {
                  handleSectionClick('th');
                }}>
                <span className='time'>{history.changePosition}</span>
                <p className='title'>{history.title}</p>
              </Link>
            </li>
          );
        })}
      </ul>
      <button
        type='button'
        className='expand'
        onClick={handleTitleHistoryOpened}>
        제목 변경
      </button>
    </div>
  );
};

const PlaylistInfo = () => {
  const {
    viewType = BROADCAST_ITEM_DISPLAY_TYPE.GRID_VIEW,
    playlistCount,
    title,
    thumbnailUrl,
    userId,
    userNickname,
    broadcastId,
    broadcastType,
    contextMenu,
    vodType,
    liveType,
    originalUserId,
    vodUploaderId,
    isSubscribe,
    isFan,
    broadcastLink,
    isDisplay,
    createdDate,
    searchActionType,
    viewCount,
    playlistId,
    vodOriginalId,
    handleAfterLogSend,
    handleMenuClicked,
    log,
    ...rest
  } = useBroadcastContext();

  const soopUiProfileIconMenu = useRef(null);
  const {toggleFavorite, setFavoriteStreamerName} = useFavoriteStore();

  /**
   * 유입경로 로그 전송 처리
   */
  const handleInflowPathLogSend = () => {
    if (broadcastId && vodOriginalId && vodUploaderId) {
      sendLegacyInflowPathLog({
        broadcastId,
        streamerId: vodOriginalId,
        type: 'vod',
        isLogSendData: {
          ...log,
          uploader: vodUploaderId,
        },
      });
    }
  };

  /**
   * 프로필 아이콘 메뉴 이벤트 핸들러
   * @param {Object} event - 이벤트 데이터
   */
  const handleProfileIconMenuEvent = (event) => {
    const {eventName, data} = event;
    if (eventName === 'deleteFavorite') {
      toggleFavorite();
      setFavoriteStreamerName(data.id);
    }
  };

  /**
   * 프로필 클릭 핸들러
   * @param {Event} e - 클릭 이벤트
   */
  const handleProfileClick = (e) => {
    e.preventDefault();
    if (userId) {
      handleAfterLogSend({type: 'sti'});
      window.open(`${globalDomains.BJ_AFREECATV}/${userId}`);
    }
  };

  /**
   * 타이틀 클릭 핸들러
   * @param {Event} e - 클릭 이벤트
   */
  const handleTitleClick = (e) => {
    if (playlistCount === 0) {
      e.preventDefault();
      throwToast('재생 가능한 VOD가 없습니다.');
    } else {
      handleInflowPathLogSend();
      handleAfterLogSend({type: 'tt'});
    }
  };

  const isGridViewType = viewType === BROADCAST_ITEM_DISPLAY_TYPE.GRID_VIEW;
  const isListViewType = viewType === BROADCAST_ITEM_DISPLAY_TYPE.LIST_VIEW;

  return (
    <div className='cBox-info'>
      <div className='details play_list_detail'>
        {/* 그리드 뷰 타입 */}
        {isGridViewType && (
          <>
            <div className='nick_wrap'>
              <a
                className='nick'
                href={`${globalDomains.BJ_AFREECATV}/${userId}`}
                target='_blank'>
                <span>{userNickname}</span>
              </a>
              {!!isSubscribe && (
                <span className='grade-badge-subscribe'>구독</span>
              )}
              {!!isFan && <span className='grade-badge-fan'>F</span>}
            </div>
            <TitleSection
              title={title}
              clickLink={broadcastLink}
              handleTitleClick={handleTitleClick}
            />
            <SummaryInfoSection
              isDisplay={isDisplay}
              createdDate={createdDate}
            />
          </>
        )}
        {/* 리스트 뷰 타입 (검색에서 쓰이는) */}
        {isListViewType && (
          <>
            <ProfileSection
              userId={userId}
              userNickname={userNickname}
              isSubscribe={isSubscribe}
              isFan={isFan}
              handleProfileClick={handleProfileClick}
              handleAfterLogSend={handleAfterLogSend}
              soopUiProfileIconMenu={soopUiProfileIconMenu}
              searchArea={searchActionType}
              handleProfileIconMenuEvent={handleProfileIconMenuEvent}
            />
            <TitleSection
              title={title}
              clickLink={broadcastLink}
              handleTitleClick={handleTitleClick}
            />
            <DetailedInfoSection
              viewCount={viewCount}
              registerDate={createdDate}
            />
          </>
        )}
      </div>
      {contextMenu.menuList.length > 0 && (
        <SoopUiContextmenuRework
          broadcastType={broadcastType}
          menuList={contextMenu.menuList}
          handleMenuClicked={handleMenuClicked}
          option={{
            title,
            thumbnailUrl,
            userId,
            userNickname,
            broadcastId,
            appScheme: contextMenu.appScheme,
            stationNo: contextMenu.stationNo,
            fileType: broadcastType === 'vod' ? vodType : liveType,
            playlistId,
            vodType,
            playlistShareUrl: broadcastLink ?? null,
          }}
        />
      )}
    </div>
  );
};

/**
 * ProfileSection 컴포넌트
 *
 * @param {Object} props - 컴포넌트에 전달되는 props
 * @param {string} props.userId - 유저 ID
 * @param {string} props.userNickname - 유저 닉네임
 * @param {boolean} props.isSubscribe - 구독 여부
 * @param {boolean} props.isFan - 팬 여부
 * @param {Function} props.handleProfileClick - 프로필 클릭 핸들러
 * @param {Object} props.soopUiProfileIconMenu - 프로필 아이콘 메뉴 ref
 * @param {string} props.searchArea - 검색 영역
 * @param {Function} props.handleProfileIconMenuEvent - 프로필 아이콘 메뉴 이벤트 핸들러
 * @param {Function} props.handleAfterLogSend - 로그 전송 후 처리 핸들러
 */
const ProfileSection = ({
  userId,
  userNickname,
  isSubscribe,
  isFan,
  handleProfileClick,
  soopUiProfileIconMenu,
  searchArea,
  handleProfileIconMenuEvent,
  handleAfterLogSend,
}) => (
  <div className='thumb_warp'>
    <a
      href={`${globalDomains.BJ_AFREECATV}/${userId}`}
      className='thumb'
      target='_blank'
      onClick={handleProfileClick}>
      <MyProfileImg userId={userId} />
    </a>
    <div className='nick_wrap'>
      <a
        className='nick'
        href={`${globalDomains.BJ_AFREECATV}/${userId}`}
        target='_blank'
        onClick={(e) => {
          e.preventDefault();
          soopUiProfileIconMenu.current?.open();
          handleAfterLogSend({type: 'nick'});
        }}>
        <span>{userNickname}</span>
      </a>
      {!!isSubscribe && <span className='grade-badge-subscribe'>구독</span>}
      {!!isFan && <span className='grade-badge-fan'>F</span>}
    </div>
    <SoopUiProfileIconMenu
      ref={soopUiProfileIconMenu}
      streamerId={userId}
      streamerNick={userNickname}
      handleMenuButtonClicked={handleProfileIconMenuEvent}
      searchArea={searchArea}
    />
  </div>
);

/**
 * TitleSection 컴포넌트
 *
 * @param {Object} props - 컴포넌트에 전달되는 props
 * @param {string} props.title - 타이틀
 * @param {string} props.clickLink - 클릭 시 이동할 링크
 * @param {Function} props.handleTitleClick - 타이틀 클릭 핸들러
 */
const TitleSection = ({title, clickLink, handleTitleClick}) => (
  <h3 className='title'>
    <a href={clickLink} target='_blank' onClick={handleTitleClick}>
      {title}
    </a>
  </h3>
);

/**
 * DetailedInfoSection 컴포넌트
 *
 * @param {Object} props - 컴포넌트에 전달되는 props
 * @param {number} props.viewCount - 조회수
 * @param {string} props.registerDate - 등록 날짜
 */
const DetailedInfoSection = ({viewCount, registerDate}) => {
  const {convertRegDate} = useConvertRegDate();

  return (
    <div className='vod_info'>
      <span className='views'>{convertViewCnt(viewCount)}</span>
      <span className='date'>{convertRegDate(registerDate)}</span>
    </div>
  );
};

/**
 * SummaryInfoSection 컴포넌트
 *
 * @param {Object} props - 컴포넌트에 전달되는 props
 * @param {string} props.isDisplay - 공개 여부
 * @param {string} props.createdDate - 등록 날짜
 */
const SummaryInfoSection = ({isDisplay, createdDate}) => {
  const {convertRegDate} = useConvertRegDate();

  return (
    <div className='all_list'>
      {isDisplay === 'N' && <span className='hidden_list'>비공개</span>}
      <span className='update'>업데이트: {convertRegDate(createdDate)}</span>
    </div>
  );
};

const BroadcastInfo = Object.assign(BroadcastInfoMain, {
  Title,
  Chips,
  TitleHistory,
  PlaylistInfo,
});

export default BroadcastInfo;
