import {Fragment, useState, useRef, useEffect} from 'react';
import {decodeFullHtmlEntities} from '@/utils';

/**
 * StreamerQnA 10문 10답 컴포넌트
 * @param {Object} props - 컴포넌트의 props
 * @param {Array} props.quaData - qna관련 데이터
 * @param {Array} props.logCallback - 로그관련 함수
 * @returns StreamerQnA 10문 10답 컴포넌트
 */
const StreamerQnA = ({quaData = [], logCallback = () => {}}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const qnaBoxRef = useRef(null);
  const [isBtnVisible, setIsBtnVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (qnaBoxRef.current) {
        setIsBtnVisible(qnaBoxRef.current.offsetHeight >= 108);
      }
    };
    handleResize();
  }, []);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
    logCallback();
  };

  return (
    <div className={isExpanded ? 'qna_box expand' : 'qna_box'}>
      <strong className='title'>10문 10답</strong>
      <dl ref={qnaBoxRef}>
        {Array.isArray(quaData) && quaData.length > 0
          ? quaData.map((item, index) => (
              <Fragment key={index}>
                <dt>{item?.q}</dt>
                <dd>{decodeFullHtmlEntities(item?.a)}</dd>
              </Fragment>
            ))
          : null}
      </dl>
      <button
        type='button'
        className='expand'
        onClick={handleToggleExpand}
        style={{display: isBtnVisible ? 'block' : 'none'}}>
        {isExpanded ? '접기' : '펼치기'}
      </button>
    </div>
  );
};

export default StreamerQnA;
