'use client';

import MyFanApi from '@/api/my/fan';
import MyAvatarGroup from '@/components/my/common/group/AvatarGroup';
import useMyStore from '@/stores/my';
import {useEffect} from 'react';
import throwToast from '@/utils/ui/toast';
import {sendPinClickLog} from '@/utils/main/my/log';

/**
 * 팬클럽 스트리머 스와이퍼 컴포넌트
 */
const MyFanclubStreamers = () => {
  const {
    fanclubStreamers,
    getFanclubStreamers,
    setLiveBroadcasts,
    liveSortKey,
    liveSortOrder,
  } = useMyStore();

  const handleUserPin = async (userId, isPin) => {
    if (isPin) {
      await MyFanApi.deletePin(userId);
      throwToast('목록 상단 고정 해제 되었습니다.');
    } else {
      await MyFanApi.setPin(userId);
      throwToast('목록 상단 고정 완료 되었습니다.');
    }

    sendPinClickLog({
      isAddPin: !isPin,
      streamerId: userId,
    });
    getFanclubStreamers();
  };

  useEffect(() => {
    setLiveBroadcasts(
      fanclubStreamers.flatMap((favorite) => favorite.broadInfo),
      {
        sortKey: liveSortKey,
        sortOrder: liveSortOrder,
      },
    );
  }, [liveSortKey, liveSortOrder]);

  return (
    <div className='strm_area'>
      <MyAvatarGroup
        favorites={fanclubStreamers}
        handleUserPin={handleUserPin}
        isFanclub
        isShowFanclub={false}
      />
    </div>
  );
};

export default MyFanclubStreamers;
