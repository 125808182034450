import MySubscribeApi from '@/api/my/subscribe';
import useMyStore from '@/stores/my';
import {throwAlert, throwConfirm} from '@/utils/ui/dialog';
import classNames from 'classnames';
import {forwardRef, useState} from 'react';

const NicknameEdit = forwardRef(function MyLayer({data, handleClose}, ref) {
  const [nickname, setNickname] = useState(data.initialNickname);
  const {myInfo} = useMyStore();
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [checkedDuplicate, setCheckedDuplicate] = useState(false);

  const handleNicknameChange = (event) => {
    setNickname(event.target.value);
    setCheckedDuplicate(false);
  };

  const checkDuplicate = async () => {
    const params = {
      bjId: data.bjId,
      userId: myInfo.userId,
      nickname,
    };
    try {
      const response = await MySubscribeApi.postNicknameAction(params, 'check');
      const {message, result} = response;

      // 결과가 정상일 때만 result 가 1
      if (result === 1) {
        setMessage('사용 가능한 닉네임입니다.');
        setIsError(false);
        setCheckedDuplicate(true);
      }

      // 뭔가의 에러가 발생했을 때 음수의 result 가 온다
      // DB 에러는 애초에 515 가 내려와서 catch 로 걸림
      if (result < 0) {
        setMessage(message);
        setIsError(true);
        setCheckedDuplicate(false);
      }
    } catch (e) {
      setMessage('잠시 후 다시 시도해 주세요.');
      setIsError(true);
      setCheckedDuplicate(false);
    }
  };

  const alertNicknameChanged = async () => {
    await throwAlert({
      message: `구독팬 닉네임이 변경되었습니다.\n현재 방송 참여 중이시라면 변경된 닉네임은 방송 재진입 시 적용됩니다`,
    });
    handleClose();
    data.getStreamers();
  };

  /**
   * 닉네임을 변경하는 액션 함수
   * @param {'default' | 'change'} type - 닉네임 액션 타입 (일반 변경 / 기본 닉네임으로 변경)
   */
  const handleNicknameAction = async (type) => {
    const params = {
      bjId: data.bjId,
      userId: myInfo.userId,
      nickname,
    };

    try {
      const {result, message} = await MySubscribeApi.postNicknameAction(
        params,
        type,
      );

      if (result === 1) {
        alertNicknameChanged();
        return;
      }

      await throwAlert({message});
      handleClose();
    } catch (e) {
      throwToast('에러가 발생하였습니다.');
    }
  };

  const handleDefaultClick = async () => {
    const {data: isOk} = await throwConfirm({
      message: `회원님의 기본 닉네임 \n${data.defaultNick}를(을) 사용하시겠습니까?\n(변경 후, 24시간 뒤 재변경 가능)`,
    });

    if (!isOk) {
      return;
    }

    setNickname(data.defaultNick);
    handleNicknameAction('default');
  };

  const handleChangeButtonClick = async () => {
    handleNicknameAction('change');
  };

  return (
    <>
      <div className='change-nickname'>
        <dl>
          <dt>구독 닉네임</dt>
          <dd>
            <div
              className={classNames('input-box', {
                invalid: isError,
              })}>
              <input
                type='text'
                value={nickname}
                onChange={handleNicknameChange}
                autoFocus
              />
              {nickname && (
                <button type='button' onClick={checkDuplicate}>
                  중복확인
                </button>
              )}
            </div>
            <span className='error-msg'>{message}</span>
          </dd>
        </dl>
        <button
          type='button'
          className='default-nick'
          onClick={handleDefaultClick}>
          기본 닉네임 사용하기
        </button>
      </div>
      <div className='warning-list'>
        <ul>
          <li>최대 한글 6자 영문 12자</li>
          <li>닉네임 변경 시 24시간 후에 재변경 가능합니다.</li>
        </ul>
      </div>
      <div className='btn_area'>
        <button type='button' className='del' onClick={handleClose}>
          취소
        </button>
        <button
          type='submit'
          className='submit'
          disabled={isError || !nickname || !checkedDuplicate}
          onClick={handleChangeButtonClick}>
          변경
        </button>
      </div>
    </>
  );
});

export default NicknameEdit;
