'use client';

import MyPlusApi from '@/api/main/my-plus';
import {throwDialog} from '@/utils/ui/dialog';
import throwToast from '@/utils/ui/toast';
import {forwardRef, useEffect, useState} from 'react';

/**
 * 재생목록 팝업
 * @param {Object} data - 팝업 데이터 (throwDialog 함수에서 전달)
 * @param {Function} handleClose - 팝업 닫기 함수 (팝업에서 전달)
 * @param {Object} ref
 * @returns {JSX.Element}
 */
const PlayListLayer = forwardRef(function PlayListLayer(
  {handleClose, data},
  ref,
) {
  const [playList, setPlayList] = useState([]);
  const [addPlayList, setAddPlayList] = useState({
    title_no: data.broadcastId,
    playListIdx: [],
  });

  useEffect(() => {
    if (data.broadcastId) {
      fetchData();
    }
  }, [data]);

  const fetchData = async () => {
    try {
      const response = await getPlayList(data.broadcastId);
      setPlayList(response.playlists || []);
    } catch (error) {
      console.error('Error fetching playlist:', error);
    }
  };

  const handleOnClickNewPlayList = () => {
    throwDialog(
      'common/NewPlayList',
      {fetchData, data},
      {
        customClassName: 'new_list_add_layer',
        title: '새 재생목록 만들기',
        titleAlign: 'left',
      },
    );
  };

  const handleOnChangePlayList = (e, idx) => {
    const toggle = e.target.checked;
    setAddPlayList((prev) => {
      const newPlayListIdx = prev.playListIdx.includes(idx)
        ? prev.playListIdx
        : [...prev.playListIdx, idx];

      return {
        ...prev,
        playListIdx: newPlayListIdx,
      };
    });
    setPlayList((prevPlayList) =>
      prevPlayList.map((item) =>
        item.idx === idx ? {...item, already: toggle} : item,
      ),
    );
  };

  const handleOnClickSubmit = async () => {
    try {
      if (addPlayList.playListIdx.length === 0) {
        handleClose();
        return;
      }
      const response = await setInsertPlayList(addPlayList);
      throwToast(response.msg);
      handleClose(); // 완료 후 팝업 닫기
    } catch (error) {
      console.error('Error adding to playlist:', error);
    }
  };

  return (
    <>
      <ul className='list_box'>
        {playList.map((item, index) => (
          <li key={index}>
            <input
              id={`plst${index}`}
              type='checkbox'
              onChange={(e) => handleOnChangePlayList(e, item.idx)}
              checked={item.already}
            />
            <label htmlFor={`plst${index}`}>{item.title}</label>
            {item.display === 'N' ? (
              <span className='hidden'> 비공개</span>
            ) : null}
          </li>
        ))}
      </ul>
      <div className='btn_area'>
        <button
          type='button'
          className='new_list_add'
          onClick={handleOnClickNewPlayList}>
          새 재생목록 만들기
        </button>
        <button type='button' className='submit' onClick={handleOnClickSubmit}>
          담기
        </button>
      </div>

      <button type='button' className='btn_close' onClick={handleClose}>
        레이어 닫기
      </button>
    </>
  );
});

export default PlayListLayer;

// 재생목록 가져오기
const getPlayList = async (broadcastId) => {
  try {
    const params = {
      szWork: 'get_making_playlist',
      type: 'all',
      title_no: broadcastId,
      nPage: 1,
      nLimit: 60,
    };
    const response = await MyPlusApi.getPlayList(params);
    if (response) {
      return response;
    }
  } catch (error) {
    console.error('Error setting playlist:', error);
  }
  return {};
};

const setInsertPlayList = async (addPlayList) => {
  try {
    let params = new URLSearchParams();
    params.append('szWork', 'ins_playlist_info');
    params.append('title_no', addPlayList.title_no);

    if (addPlayList.playListIdx.length > 0) {
      addPlayList.playListIdx.forEach((item) => {
        params.append('nPlaylistIdx[]', item);
      });
    }

    const response = await MyPlusApi.setAddPlayList(params.toString());
    if (response) {
      return response;
    }
  } catch (error) {
    console.error('Error adding to playlist:', error);
  }
};
