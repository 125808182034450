import StreamerInfo from './StreamerInfo';
import {StreamerAvatar} from '@/components/search/common';

/**
 * StreamerItemLeftBox 컴포넌트 ( 스트리머 영역의 프로필이미지 && 스트리머 정보 부분 )
 * @param {Object} props - 컴포넌트의 props
 * @param {String} userId - 유저아이디
 * @param {String} userNick - 유저닉네임
 * @param {String} fanFlag - 팬구독 여부
 * @param {String} subsFlag - 구독 여부
 * @param {String} favoriteCnt - 애청자 수
 * @param {String} broadNo - 생방송 번호
 * @param {String} onEvent - 프로필 ict 메뉴의 이벤트가 발생했을 때 트리거
 * @param {Function} logCallback - 로그 관련 콜백 함수
 * @returns StreamerItemLeftBox 컴포넌트
 */
const StreamerItemLeftBox = ({
  userId,
  userNick,
  fanFlag,
  subsFlag,
  favoriteCnt,
  broadNo,
  onEvent,
  logCallback,
}) => {
  // SCKACT 로그
  const handleStreamerActionLog = (type) => {
    let param = type ? {type: type} : '';
    logCallback && logCallback(param);
  };
  return (
    <div className='lt_box'>
      {/* 스트리머 이미지 */}
      <StreamerAvatar
        userId={userId}
        broadNo={broadNo}
        className='thumb'
        logCallback={() => {
          handleStreamerActionLog('sti');
        }}
      />

      {/* 스트리머 정보 */}
      <StreamerInfo
        userId={userId}
        userNick={userNick}
        isFanclub={fanFlag}
        isSubscribe={subsFlag}
        favoriteCount={favoriteCnt}
        onEvent={onEvent}
        logCallback={() => {
          handleStreamerActionLog('nick');
        }}
      />
    </div>
  );
};

export default StreamerItemLeftBox;
