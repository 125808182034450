'use client';

import MyTabGroup from '@/components/my/common/group/TabGroup';
import useMyStore from '@/stores/my';
import {
  sendCreateFavoriteGroupClickLog,
  sendGroupTabClickLog,
} from '@/utils/main/my/log';
import {throwDialog} from '@/utils/ui/dialog';
import {useEffect, useState} from 'react';

const TOTAL_TAB = {
  name: '전체',
  query: {
    groupId: '',
  },
};

const TabGroup = () => {
  const [favoriteGroupTabs, setFavoriteGroupTabs] = useState([TOTAL_TAB]);
  const {getFavoriteGroups, favoriteGroups} = useMyStore();

  useEffect(() => {
    if (favoriteGroups?.length === 0) {
      setFavoriteGroupTabs([TOTAL_TAB]);
    }

    if (favoriteGroups?.length > 0) {
      setFavoriteGroupTabs(() => {
        return [TOTAL_TAB, ...createFavoriteGroupTabs(favoriteGroups)];
      });
    }
  }, [favoriteGroups]);

  useEffect(() => {
    getFavoriteGroups();
  }, []);

  const handleGroupAdd = () => {
    sendCreateFavoriteGroupClickLog({
      buttonType: 'create_method1',
    });

    throwDialog(
      'my/ExtendedNewGroup',
      {getFavoriteGroups},
      {isCustomDialog: true, customClassName: 'myFav_group_layer'},
    );
  };

  const handleGroupEdit = () => {
    throwDialog(
      'my/FavoriteGroupEdit',
      {getFavoriteGroups, groups: favoriteGroups},
      {isCustomDialog: false, customClassName: 'myFav_group_layer'},
    );
  };

  /**
   * 그룹 탭 변경 이벤트 핸들러
   * @desc 실질적으로 내부 로직은 클릭 로그를 보내기 위한 데이터 포맷팅밖에 없다
   * @param {Object} query - 그룹 정보를 포함한 쿼리 객체
   * @param {string} query.groupId - 선택된 그룹 ID
   */
  const handleGroupTabChanged = (query) => {
    if (!query || query.groupId === undefined) {
      return;
    }

    const {groupId} = query;

    if (groupId === '') {
      sendGroupTabClickLog({
        groupId,
        groupName: '',
      });
      return;
    }

    const selectedGroup = favoriteGroups.find(
      (group) => Number(group.idx) === Number(groupId),
    );

    if (!selectedGroup) {
      return;
    }

    const groupName = selectedGroup.title;

    sendGroupTabClickLog({
      groupId,
      groupName,
    });
  };

  return (
    <MyTabGroup
      tabs={favoriteGroupTabs}
      type='flat'
      onHandle={handleGroupTabChanged}
      moreTab={
        <>
          <button type='button' className='add' onClick={handleGroupAdd}>
            그룹 추가
          </button>
          <button type='button' className='edit' onClick={handleGroupEdit}>
            <span>그룹 편집</span>
          </button>
        </>
      }
    />
  );
};

/**
 * 즐겨찾기 그룹 탭 생성
 * @param {Array} favoriteGroups - 즐겨찾기 그룹 목록
 * @returns {Array} 즐겨찾기 그룹 탭 목록
 */
export function createFavoriteGroupTabs(favoriteGroups) {
  const favoriteGroupTabs = favoriteGroups.map((group) => ({
    name: group.title,
    query: {
      groupId: String(group.idx),
    },
  }));

  return favoriteGroupTabs;
}

export default TabGroup;
