'use client';

import React, {useEffect, useState} from 'react';
import MyFeedApi from '@/api/my/feed/page';
import {SoopUiSelectBox} from '@/components/ui';
import {MY_FEED_FILTER_LIST} from '@/constants/my/filter';
import NewPost from '@/components/my/feed/item/new-post/NewPost';
import NewVod from '@/components/my/feed/item/new-post/NewVod';
import {usePathname} from 'next/navigation';
import NoData from '@/components/my/feed/item/new-post/NoData';
import {useInfiniteScroll} from '@/components/main/common/hooks/useInfiniteScroll';
import FeedLive from '@/components/my/feed/item/new-post/FeedLive';
import {ErrorBoundary} from '@/components/common';

/**
 * 새로운 게시글 목록을 가져옴
 *
 * @param {Object} params - 게시글 목록을 가져오기 위한 파라미터들
 * @param {number} params.indexRegDate - 시작할 인덱스 등록 날짜
 * @param {string} params.userId - 피드를 가져올 사용자 ID
 * @param {'' | '1'} params.isStreamerPost - BJ 작성글만 조회 여부 ('': 전체글 조회, 1: BJ 작성글만 조회)
 * @param {'' | 'NOTICE' | 'POST_PHOTO'} params.feedType - 가져올 피드의 타입 (전체:'', 공지글:'NOTICE', 게시글:'POST_PHOTO')
 * @returns {Object} - 게시글 목록, 추가 게시글 여부, 링크, 메타 데이터를 포함한 객체를 반환
 */
const fetchPosts = async ({
  indexRegDate = 0,
  userId = '',
  isStreamerPost = '',
  feedType = '',
}) => {
  try {
    const {
      data: newPosts,
      hasMore,
      links,
      meta,
    } = await MyFeedApi.getNewPostList({
      indexRegDate,
      userId,
      isBjWrite: isStreamerPost,
      feedType,
    });
    return {newPosts, hasMore, links, meta};
  } catch (error) {
    console.error(error);
    return {newPosts: [], hasMore: false, links: null, meta: null};
  }
};

const PostList = () => {
  const pathname = usePathname();
  const userId = pathname.split('/')[3] || '';
  const [posts, setPosts] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [streamerPostFilter, setStreamerPostFilter] = useState('');
  const [selectedFeedType, setSelectedFeedType] = useState('');
  const [hasMorePosts, setHasMorePosts] = useState(false);
  const [latestVisibleVodIndex, setLatestVisibleVodIndex] = useState(-1);
  const [visibleVods, setVisibleVods] = useState([]);

  /**
   * VOD 노출 여부 이벤트 핸들러
   * @param {boolean} isVisible - 현재 스크롤 내에서 보이는지 여부
   * @param {Object} vod - vod 객체
   */
  const handleVisibilityChange = (isVisible, vod) => {
    if (isVisible) {
      setVisibleVods((prevVods) => {
        const updatedVods = [...prevVods, vod];
        const sortedNewVods = updatedVods.sort((prev, current) => {
          return current.indexRegDate - prev.indexRegDate;
        });

        return sortedNewVods;
      });
    } else {
      setVisibleVods((prevVods) => {
        return prevVods.filter(
          (preVod) => preVod.indexRegDate !== vod.indexRegDate,
        );
      });
    }
  };

  /**
   * VOD 플레이어 오류 핸들러
   * @param {number} index - VOD의 등록 날짜 인덱스
   * @returns {function} - 이벤트 핸들러 함수
   */
  const handleVodPlayerError = (index) => (e) => {
    if (index !== latestVisibleVodIndex) {
      return;
    }

    setVisibleVods((prevVods) => {
      return prevVods.filter(
        (prevVod) => prevVod.indexRegDate !== latestVisibleVodIndex,
      );
    });
  };

  useEffect(() => {
    if (visibleVods.length > 0) {
      const oldestVod = visibleVods[visibleVods.length - 1];
      setLatestVisibleVodIndex(oldestVod.indexRegDate);
    }
  }, [visibleVods]);

  /**
   * 게시글 목록 로드
   * 현재 목록을 초기화하거나 새 데이터를 추가
   *
   * @param {boolean} reset - 현재 목록을 초기화할지 여부 (userIdFromPath 가 바뀔 때 초기화가 되지 않아서 추가함)
   * @param {number} indexRegDate - 시작할 인덱스
   */
  const loadPosts = async (reset = false, indexRegDate = 0) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      const {newPosts, hasMore, meta} = await fetchPosts({
        indexRegDate,
        userId: userId,
        isStreamerPost: streamerPostFilter,
        feedType: selectedFeedType,
      });

      setHasMorePosts(hasMore);
      setPaginationMeta(meta);

      if (reset) {
        setPosts(newPosts); // 새로운 데이터로 리스트를 덮어씀
      } else {
        setPosts((prevList) => [...prevList, ...newPosts]); // 기존 리스트에 새 데이터를 추가
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // userId, isStreamerPost, feedType 변경 시 리스트 초기화 및 로드
  useEffect(() => {
    loadPosts(true); // 초기화 리스트 로드
  }, [userId, streamerPostFilter, selectedFeedType]);

  // 무한 스크롤을 위해 추가 게시글 로드
  const {ref: lastPostRef, inView} = useInfiniteScroll({
    delay: 100,
    onChange: (inView) => {
      if (
        inView &&
        !isLoading &&
        paginationMeta?.currentPage < paginationMeta?.lastPage &&
        hasMorePosts
      ) {
        const lastIndexRegDate =
          posts.length > 0 ? posts[posts.length - 1].indexRegDate : 0;
        loadPosts(false, lastIndexRegDate); // 무한 스크롤을 위한 추가 데이터 로드
      }
    },
  });

  /**
   * 피드 타입 선택 변경을 처리
   *
   * @param {string} newFeedType - 새로 선택된 피드
   */
  const handleFeedTypeChange = (newFeedType) => {
    setSelectedFeedType(newFeedType);
  };

  /**
   * 스트리머 글 필터를 토글
   */
  const handleStreamerFilterToggle = () => {
    setStreamerPostFilter((prev) => (prev === '' ? '1' : ''));
  };

  return (
    <>
      <section className='feed-contents'>
        <div className='feed-wrap'>
          <div className='feed_heading'>
            <div className='lt'>
              <h2 className='title'>채널 새 글</h2>
            </div>
            <div className='util'>
              <div className='chk-box'>
                <span>
                  <input
                    type='checkbox'
                    name=''
                    id='chk1'
                    onClick={handleStreamerFilterToggle}
                  />
                  <i></i>
                </span>
                <label htmlFor='chk1'>스트리머 글만 보기</label>
              </div>
              <SoopUiSelectBox
                type={selectedFeedType}
                options={MY_FEED_FILTER_LIST}
                onChange={handleFeedTypeChange}
              />
            </div>
          </div>
          {posts && (
            <>
              {/* 새글 리스트 */}
              {posts.map((item, idx) => {
                let content;
                switch (item.feedType) {
                  case 'POST':
                  case 'PHOTO':
                    content = <NewPost {...item} />;
                    break;
                  case 'LIVE':
                    content = <FeedLive {...item} />;
                    break;
                  case 'VOD':
                    content = (
                      <ErrorBoundary>
                        <NewVod
                          {...item}
                          isPlayable={
                            item.indexRegDate === latestVisibleVodIndex
                          }
                          onVisibilityChange={handleVisibilityChange}
                          onPlayerError={handleVodPlayerError(
                            item.indexRegDate,
                          )}
                        />
                      </ErrorBoundary>
                    );
                    break;
                  default:
                    content = null;
                }
                return (
                  <React.Fragment key={item.indexRegDate || idx}>
                    {content}
                  </React.Fragment>
                );
              })}
            </>
          )}
          <div className='last-post-ref' ref={lastPostRef} />
          {posts.length === 0 && !isLoading && (
            <NoData feedType={selectedFeedType} />
          )}
        </div>
      </section>
    </>
  );
};

export default PostList;
