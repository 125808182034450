import React, {useState} from 'react';

const ShowMore = ({active, name, onHanlder}) => {
  const [more, setMore] = useState(true);

  const handleShowMore = () => {
    setMore(false);
    onHanlder && onHanlder();
  };

  return (
    <div className='show_more'>
      {active && more && (
        <button onClick={handleShowMore} type='button'>
          {name}
        </button>
      )}
    </div>
  );
};

export default React.memo(ShowMore);
