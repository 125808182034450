'use client';

import category from '@/api/directory/category';
import {useState} from 'react';
import {SoopUiImg} from '@/components/ui';
import globalDomains from '@/utils/global-domains';
import {sendLegacyInflowPathLog} from '@/utils/main/my/log';
import throwToast from '@/utils/ui/toast';

const PlayListThumbnailImage = ({
  thumbnailSrc,
  grade,
  category,
  thumbType,
  url,
  onClick,
  vodNumber = 0,
  vodOriginalId = '',
  vodRegister = '',
  playlistNumber = 0,
  playlistCount = 0,
  userId,
  logCallback,
  isLogSendData,
  isPrivate = false,
}) => {
  const link = url
    ? url
    : `${globalDomains.VOD_AFREECATV}/player/${vodNumber}/playlist/${playlistNumber}`;
  // 성인 여부
  const isAdult =
    (parseInt(grade) === 19 && parseInt(category) === 30000) ||
    thumbType === 'ADULT';
  const [imageError, setImageError] = useState(false);

  const handleImageError = () => {
    setImageError(true);
  };

  const handleThumbnailClick = (event) => {
    if (playlistCount === 0) {
      event.preventDefault();
      throwToast('재생 가능한 VOD가 없습니다.');
      return;
    }

    onClick?.();
    playlistNumber &&
      vodOriginalId &&
      vodRegister &&
      sendLegacyInflowPathLog({
        broadcastId: vodNumber, // 재생목록 번호
        streamerId: vodOriginalId, // 재생목록 생성 아이디
        type: 'vod',
        isLogSendData: {...isLogSendData, uploader: vodRegister},
      });

    logCallback && logCallback();
  };

  const thumbnailImage = () => {
    if (thumbType === 'ADULT_LOCK') {
      // 연령제한 + 비공개
      return (
        <a
          href={link}
          onClick={handleThumbnailClick}
          target='_blank'
          className='thumb-lock_adult'>
          연령제한 + 비공개
          <div className='play_list'>
            <em>{playlistCount}</em>
          </div>
        </a>
      );
    }
    // 19금일 때
    if (isAdult) {
      return (
        <a
          href={link}
          onClick={handleThumbnailClick}
          target='_blank'
          className='thumb-adult'>
          19금
          <div className='play_list'>
            <em>{playlistCount}</em>
          </div>
        </a>
      );
    }

    // 재생목록 만든사람이 비공개 했을때, 첫번째 vod가 비공개 영상 일때
    if (isPrivate || thumbType === 'LOCK') {
      return (
        <a
          href={link}
          onClick={handleThumbnailClick}
          target='_blank'
          className='thumb-private'>
          비공개
          <div className='play_list'>
            <em>{playlistCount}</em>
          </div>
        </a>
      );
    }

    /**
     * 기본 썸네일 노출 기준, thumbnail path가 없거나 에러뜰때, 재생목록 개수가 0개일때
     * 재생목록 첫번째 vod가 삭제가 되었을때
     */

    if (
      imageError ||
      !thumbnailSrc ||
      playlistCount === 0 ||
      thumbType === 'DELETE'
    ) {
      return (
        <a
          className='thumb-default'
          href={link}
          onClick={handleThumbnailClick}
          target={`${playlistCount === 0 ? '_self' : '_blank'}`}>
          기본
          <div className='play_list'>
            <em>{playlistCount}</em>
          </div>
        </a>
      );
    }

    return (
      <a href={link} target={'_blank'} onClick={handleThumbnailClick}>
        <SoopUiImg src={thumbnailSrc} onError={handleImageError} />
        <div className='play_list'>
          <em>{playlistCount}</em>
        </div>
      </a>
    );
  };

  return <div className='thumbs-box'>{thumbnailImage()}</div>;
};

export default PlayListThumbnailImage;
