import {useState, useEffect, useRef} from 'react';

export const useIntersection = (options = {threshold: 0}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const intersectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    const currentTarget = intersectionRef.current;

    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [options]);

  return {intersectionRef, isIntersecting};
};
