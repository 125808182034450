import {useCallback} from 'react';

import {SoopUiSwiper} from '@/components/ui';
import {SectionHeader} from '@/components/main/common';
import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import {StreamerListItem} from '@/components/search/common';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

import classNames from 'classnames';
import {sendLegacyInflowPathLog} from '@/utils/main/my/log';
import {ErrorBoundary} from '@/components/common';

/**
 * RecommendedStreamerSection 컴포넌트 ( 관련 스트리머 ): 스트리머 프로필 미노출일 때 노출
 * @param {Object} props 컴포넌트의 props
 * @param {object[]} data 관련 스트리머 영역 데이터
 * @param {string} section 스트리며 영역인지 테마 영역인지 구분값
 * @returns {JSX.Element} RecommendedStreamerSection 컴포넌트
 */
const RecommendedStreamerGroup = ({data, section}) => {
  const {sendRecommendStreamerClickLog, sendThemeClickLog} =
    useSearchClickLog();
  const {sessionKey} = useSearchGlobalStatus();
  const {getParams} = useUrlParameter();
  // 동적으로 바뀌는 parameter들
  const {szSearchType: searchType = ''} = getParams();

  // SCKACT로그
  const handleLogCallback = useCallback(
    (params, index, userId, broadNo) => {
      const {type} = params || {};
      const isRemoveField = ['nick', 'more', 'hash'].includes(type);
      const contentsType = isRemoveField ? '' : broadNo ? 'live' : 'bj';
      const contentsValue = isRemoveField ? '' : broadNo || userId;

      const param = {
        actCode: type,
        sectionName: 'bj',
        listIdx: index,
        bjid: userId,
        contentsType,
        contentsValue,
        sessionKey,
        bno: isRemoveField ? '' : broadNo || '',
        ...params,
      };

      if (section === 'streamer') {
        sendRecommendStreamerClickLog(param);
      } else {
        sendThemeClickLog(param);
      }
    },
    [sessionKey, searchType],
  );

  if (!data || data.length < 1) {
    return null;
  }

  return (
    <>
      {data && data.length > 0 ? (
        <>
          <SectionHeader title='관련 스트리머' />

          <div className='strm_area'>
            <div className='strm_list'>
              <SoopUiSwiper
                swiperTag='ul'
                maxSlidesPerView={8}
                options={{loop: false}}>
                {data?.map((streamer, index) => {
                  return (
                    <li
                      className={classNames('strm_box', {
                        live: streamer.broadNo,
                      })}
                      key={`${streamer.userId}_${index}`}>
                      <ErrorBoundary>
                        <StreamerListItem
                          {...streamer}
                          logCallback={(params) => {
                            handleLogCallback(
                              params,
                              index,
                              streamer.userId,
                              streamer.broadNo,
                            );
                            sendLegacyInflowPathLog({
                              broadcastId: streamer.broadNo,
                              streamerId: streamer.userId,
                              isLogSendData: {
                                path1: 'sch',
                                path2: 'total',
                                path3:
                                  section === 'theme' ? 'fb' : 'recommend_bj',
                                path4: 'sti',
                                cli_list_data_idx: index + 1,
                              },
                            });
                          }}
                          searchArea='recommend'
                        />
                      </ErrorBoundary>
                    </li>
                  );
                })}
              </SoopUiSwiper>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default RecommendedStreamerGroup;
