'use client';

import {SoopUiSelectBox} from '@/components/ui';
import MyNoList from '@/components/my/common/item/NoList';
import {MY_SORT_ORDER, sortBroadcasts} from '@/stores/my';
import MyHistoryApi from '@/api/my/history';
import {useEffect, useState} from 'react';
import {SectionHeader} from '@/components/main/common';
import MyBroadcastLayout from '@/components/my/common/MyBroadcastLayout';
import {ErrorBoundary} from 'next/dist/client/components/error-boundary';
import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';
import {BROADCAST_TYPES, VOD_TYPES} from '@/constants';
import {CONTEXT_MENU} from '@/constants/my/contextMenu';
import globalDomains from '@/utils/global-domains';
import {throwConfirm} from '@/utils/ui/dialog';
import throwToast from '@/utils/ui/toast';

/**
 * 플레이리스트 정렬 옵션
 */
export const OPTIONS = Object.freeze([
  {label: '최근 업데이트', type: 'vod_add_date'},
  {label: '최근 등록', type: 'list_idx'},
]);

const MyHistoryPlaylist = () => {
  const [order, setOrder] = useState(OPTIONS[0].type);
  const [playlists, setPlaylists] = useState([]);

  const handleOrderChange = (type) => {
    setOrder(type);
  };

  const getPlaylist = async () => {
    try {
      const playlist = await MyHistoryApi.getPlaylist();
      setPlaylists(
        sortBroadcasts([...playlist], {
          sortKey: order,
          sortOrder: MY_SORT_ORDER.DESC,
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setPlaylists((playlists) => {
      return sortBroadcasts(playlists, {
        sortKey: order,
        sortOrder: MY_SORT_ORDER.DESC,
      });
    });
  }, [order]);

  useEffect(() => {
    getPlaylist();
  }, [order]);

  const handleContextMenuClick = (broadcast) => async (event, type) => {
    if (type === 'delete-playlist') {
      handleContextDelete(broadcast);

      return;
    }

    if (type === 'edit-playlist') {
      window.open(
        `${globalDomains.STBBS_AFREECATV}/vodplaylist/index.php?nPlaylistIdx=${broadcast.listIdx}`,
      );
    }
  };

  const handleContextDelete = async (broadcast) => {
    const {data: isOk} = await throwConfirm({
      message: '재생목록을 삭제하시겠습니까?',
    });

    if (!isOk) {
      return;
    }

    try {
      const response = await MyHistoryApi.deletePlaylist(
        broadcast.listIdx,
        broadcast.playlist?.regId === broadcast.addId,
      );
      throwToast(response.msg);
      getPlaylist();
    } catch (e) {
      throwToast('에러가 발생하였습니다.');
    }
  };

  /**
   * 플레이리스트 컨텍스트 메뉴 가져오기
   * @desc 플레이리스트는 컨텍스트 메뉴가 다를 뿐더러 특수 조건이 있기 때문에 함수를 따로 뻈다
   * @param {Object} playlist - 플레이리스트 데이터
   * @returns {Array} 플레이리스트 컨텍스트 메뉴 리스트
   */
  const getPlaylistContextMenu = (playlist) => {
    let playlistContextMenu = [...CONTEXT_MENU.PLAYLIST];

    // 추가한 사람이랑 등록한 사람이 같을 때만 수정하기 가능
    if (playlist.addId == playlist.playlist?.regId) {
      playlistContextMenu.unshift({
        label: '수정하기',
        type: 'edit-playlist',
      });
    }

    // 재생목록에 vod 가 1개 이상 있을 때만 공유하기 가능
    if (playlist.playlistCount === 0) {
      playlistContextMenu = playlistContextMenu.filter(
        (contextMenu) => contextMenu.label !== '공유하기',
      );
    }

    return playlistContextMenu;
  };

  return (
    <>
      <SectionHeader
        title='재생목록'
        rightElement={
          <>
            <SoopUiSelectBox
              options={OPTIONS}
              type={order}
              onChange={handleOrderChange}
            />
          </>
        }
      />
      {playlists?.length > 0 ? (
        <MyBroadcastLayout type='grid'>
          {playlists.map((broadcast) => (
            <ErrorBoundary key={broadcast.listIdx}>
              <BroadcastLayout
                broadcastType={BROADCAST_TYPES.VOD}
                thumbnailUrl={broadcast.playlist?.bbsTitle?.ucc?.thumb}
                createdDate={broadcast.playlist?.regDate}
                startDateTime={broadcast.broadStart}
                userNickname={broadcast.playlist?.regNick}
                title={broadcast.playlist?.title}
                playlistId={broadcast.listIdx}
                userId={broadcast.playlist?.regId}
                vodType={VOD_TYPES.PLAYLIST}
                isFan={broadcast.playlist?.isFanclub}
                isSubscribe={broadcast.playlist?.isSubscribe}
                broadcastLink={broadcast.playlist?.url}
                clickLink={broadcast.playlist?.url}
                isDisplay={broadcast.playlist?.config?.display}
                playlistCount={broadcast.playlist?.count?.playlistCnt}
                contextMenu={{
                  menuList: getPlaylistContextMenu(broadcast),
                }}
                thumbnailType={broadcast.playlist?.bbsTitle?.ucc?.thumbType}
                handleMenuClicked={handleContextMenuClick(broadcast)}>
                <BroadcastType.Vod />
              </BroadcastLayout>
            </ErrorBoundary>
          ))}
        </MyBroadcastLayout>
      ) : (
        <MyNoList
          title='재생목록이 없습니다.'
          description={
            <>
              <span>
                내 마음대로 만드는 플레이 리스트!
                <br /> 테마/스트리머 별로 재생목록에 담아 편하게 시청하세요.
              </span>
            </>
          }
        />
      )}
    </>
  );
};

export default MyHistoryPlaylist;
