import {Link} from 'react-router-dom';

const CategoryError = () => {
  return (
    <div className='category_empty'>
      <div>
        <p>카테고리가 삭제되었거나 존재하지 않습니다.</p>
        <Link to={`/directory/category`}>카테고리 리스트로 이동</Link>
      </div>
    </div>
  );
};
export default CategoryError;
