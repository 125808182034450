import {useMemo, useRef, useEffect, useState} from 'react';
import SoopUiImg, {SOOP_UI_IMG_TYPES} from '@/components/ui/SoopUiImg';
import globalDomains from '@/utils/global-domains';
import {isEmpty} from 'lodash';
import {useLocation, useSearchParams} from 'react-router-dom';
import {ROUTE} from '@/constants/main/route';
import {convertToDuration} from '@/utils/main';
import MyHistoryApi from '@/api/my/history';
import {
  sendLegacyInflowPathLog,
  sendVodThumbnailClickLog,
} from '@/utils/main/my/log';
import {convertToArray} from '@/utils';

const SUBSCRIBE_AUTH_NO = 107;

const VodThumbnailImage = ({
  thumbnailSrc,
  isPpv,
  isBadge,
  isPassword,
  isAdult,
  vodNumber, // titleNo
  authNumber,
  fileType,
  uccType,
  copyright,
  originalUserId: original_user_id,
  originalUserNick: original_user_nick,
  duration,
  handleLaterView = () => {},
  laterRegDate,
  webpPath, //미리보기 썸네일 주소 -> 기존 preViewWebpPath를 이걸로 변경 사용하고있지않았을 뿐더러 preViewWebpPath로 할시 vod를 그려주는 컴포넌트마다 한번더 포멧팅해줘야해서..이걸로..
  userId,
  categoryTags,
  hashTags,
  listDataType,
  isLogSendData,
  logCallback,
  catchStoryIdList = [],
  isTitleHover = false,
  grade,
  category,
  groupType,
  groupName,
  isUserClip,
}) => {
  const vodThumbBoxRef = useRef(null);
  const soopUImgRef = useRef(null);

  // 원저작자 data가 myapi에서는 copyright로, myapi가 아닌 경우는 original_user_id, original_user_nick로 옴
  const originalUserId = copyright?.userId || original_user_id;
  const originalUserNick = copyright?.userNick || original_user_nick;
  const link = `${globalDomains.VOD_AFREECATV}/player/${vodNumber}${laterRegDate ? '/later#REG_DATE/1' : ''}${fileType === 'CATCH' ? '/catch' : ''}${fileType === 'CATCH_STORY' ? `/catchstory?szLocation=search&aStoryListIdx=${catchStoryIdList.join('-')}` : ''}`;

  const location = useLocation();

  const [isMouseHovering, setMouseHovering] = useState(false);
  const [previewThumbnail, setPreviewThumbnail] = useState(thumbnailSrc); // thumbnailSrc로 초기화. validation 통과 되면 webpPath

  const [isLoadImageError, setIsLoadImageError] = useState(false);

  const isShowLaterButton =
    fileType !== 'CATCH' &&
    fileType !== 'CATCH_STORY' &&
    location.pathname !== ROUTE.MY.HISTORY.LATER;

  const shouldShowPreview = useMemo(() => {
    // 19금, 다시보기, 구독 전용, webp(미리 보기)파일이 없을 때
    return !(
      fileType === 'REVIEW' ||
      !webpPath ||
      isAdult ||
      isPpv ||
      parseInt(uccType) === 22 ||
      authNumber === 107
    );
  }, [webpPath, isAdult]);

  const [searchParams] = useSearchParams();
  const searchKey = searchParams.get('szKeyword');

  const validatePreviewThumbnail = () => {
    // 매번 mouseon이 되었을때 valdation하지 않고 여기서 최초 한번 실행
    // 비동기 image 로드로 인해 마우스 빠르게 움직였을때 실패할 확률을 방지
    const img = new Image();
    img.src = webpPath;
    img.onload = () => {
      setPreviewThumbnail(webpPath);
    };
  };

  const handleThumbnailClick = () => {
    const currentPathname = location.pathname;

    if (currentPathname === ROUTE.MY.HISTORY.LATER) {
      MyHistoryApi.viewLaterVod(vodNumber);
    }

    // VOD 썸네일 클릭 로그 전송
    sendVodThumbnailClickLog({
      streamerId: userId,
      vodNumber,
      fileType,
    });

    // 기존 유입 로그 전송
    sendLegacyInflowPathLog({
      broadcastId: vodNumber,
      streamerId: originalUserId ? originalUserId : userId, // 원저작자가 있는 영상일 경우에는 bj 가 원저작자 아이디로, 없는 경우에는 업로드한 유저 아이디로
      type: 'vod',
      moreData: {
        contentsType: 'vod',
        hashTags: convertToArray(hashTags),
        categoryTags: convertToArray(categoryTags),
        ...(listDataType && {listDataType}),
        ...(searchKey && {skey: searchKey}),
        exCategoryNo: vodNumber,
        ...(groupName && {groupName}),
        ...(groupType && {groupType}),
        isUserClip,
      },
      isLogSendData: {
        ...isLogSendData,
        uploader: userId, // 업로드한 유저 아이디
      },
    });

    logCallback && logCallback();
  };

  const updateThumbnail = (newUrl) => {
    soopUImgRef.current && soopUImgRef.current.setSource(newUrl);
  };

  const handleMouseEnter = (event) => {
    if (shouldShowPreview) {
      setMouseHovering(true);
    }
  };
  const handleMouseLeave = (event) => {
    setMouseHovering(false);
  };

  useEffect(() => {
    if (!isLoadImageError) {
      if (isMouseHovering) {
        updateThumbnail(previewThumbnail);
      } else {
        updateThumbnail(thumbnailSrc);
      }
    }
  }, [isLoadImageError, isMouseHovering]);

  useEffect(() => {
    validatePreviewThumbnail(); // 움직이는 이미지 webpPath 가 정상 로드되는지 체크
  }, []);

  useEffect(() => {
    if (!isLoadImageError) {
      if (isTitleHover) {
        handleMouseEnter();
      } else {
        handleMouseLeave();
      }
    }
  }, [isLoadImageError, isTitleHover]);

  // 썸네일 이미지, 비공개, 19금 일반
  const thumbnailImage = useMemo(() => {
    const isAdultThumbnail =
      isAdult || (Number(grade) === 19 && Number(category) === 30000);

    if (isPassword) {
      return (
        <a
          className='thumb-private'
          target='_blank'
          href={link}
          onClick={handleThumbnailClick}
        />
      );
    } else if (isAdultThumbnail) {
      return (
        <a
          className='thumb-adult'
          target='_blank'
          href={link}
          onClick={handleThumbnailClick}
        />
      );
    } else if (isLoadImageError) {
      return (
        <a
          className='thumb-default'
          target='_blank'
          href={link}
          onClick={handleThumbnailClick}
        />
      );
    }

    return (
      <a target='_blank' href={link} onClick={handleThumbnailClick}>
        <SoopUiImg
          type={SOOP_UI_IMG_TYPES.thumbnail}
          src={thumbnailSrc}
          onError={(event) => {
            event.preventDefault();
            setIsLoadImageError(true);
          }}
        />
      </a>
    );
  }, [thumbnailSrc, isPassword, isAdult, link, isLoadImageError]);

  return (
    <div
      ref={vodThumbBoxRef}
      className='thumbs-box'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {thumbnailImage}
      {isBadge && (
        <>
          {authNumber === SUBSCRIBE_AUTH_NO && (
            <span className='_subscribe'>구독전용</span>
          )}
          {!shouldShowPreview && (
            <span className='nonView'>미리보기를 제공하지 않는 VOD</span>
          )}
          {fileType === 'HIGHLIGHT' && (
            <span className='_highlight'>하이라이트</span>
          )}
          {fileType === 'REVIEW' && <span className='_replay'>다시보기</span>}
          {originalUserNick &&
            originalUserId &&
            fileType !== 'CATCH' &&
            fileType !== 'CATCH_STORY' && (
              <span className='clip_nick'>{originalUserNick}</span>
            )}
          {/* 캐치일 때 우측 하단 캐치 로고 노출  */}
          {fileType === 'CATCH' && <span className='catch'></span>}
          {fileType === 'CATCH_STORY' && <span className='story'>스토리</span>}
          {/* 이건 캐치일때 뜨면 안되여 */}
          {isShowLaterButton && (
            <button
              type='button'
              className='later'
              tip='나중에 보기'
              onClick={() => handleLaterView(vodNumber)}>
              <span>나중에 보기</span>
            </button>
          )}
        </>
      )}
      {/* api 응답값마다 duration 형식이 다다르다. */}
      {!isEmpty(duration) && fileType !== 'CATCH' && (
        <span className='time active'>{convertToDuration(duration)}</span>
      )}
    </div>
  );
};

export default VodThumbnailImage;
