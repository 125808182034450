'use client';

import MyPlusApi from '@/api/main/my-plus';
import MyCatch from '@/components/my/common/item/MyCatch';
import {SoopUiSwiper} from '@/components/ui';
import {SectionHeader} from '@/components/main/common';
import {CATCH_CONTEXT_MENU} from '@/constants/main';
import {useEffect, useState} from 'react';
import {useHiddenStreamer} from '../../common/hooks/useHiddenStreamer';
import '/public/static/css/module/catch_list.scss';
import LogApi from '@/api/common/log';
import {isLogin} from '@/utils/main';

export const CatchGroup = ({selectedMode}) => {
  const swiperConfig = {
    loop: false,
    spaceBetween: 20,
  };

  const [catchList, setCatchList] = useState([]);
  const {
    hiddenStreamerList,
    handleOnClickHiddenStreamer,
    handleOnClickUndoHiddenStreamer,
  } = useHiddenStreamer('VOD', 'myplus');

  useEffect(() => {
    if (selectedMode === 'prefer' && isLogin()) {
      getPreferCatchList();
    } else {
      getPopularCatchList();
    }
  }, []);

  useEffect(() => {
    handleVodViewLog();
  }, [catchList]);

  const handleVodViewLog = () => {
    try {
      if (catchList.length > 0) {
        if (selectedMode === 'popular') {
          const params = {
            path1: 'popular',
            path2: 'catch',
            list_send_type: 'client',
            list_view_cnt: catchList.length,
            list_view_data: catchList.map((item) => ({
              vno: item.catchId,
            })),
          };
          LogApi.sendVodListViewLog(params);
        }
      }
    } catch (error) {}
  };

  const getPreferCatchList = async () => {
    try {
      const response = await MyPlusApi.getPreferCatchList();
      if (response && response.data) {
        const transformedList = response.data?.list?.map((catchItem) => {
          const {
            thumb,
            titleNo,
            resolutionType,
            titleName,
            viewCnt,
            bjId,
            ...rest
          } = catchItem;

          return {
            ...rest,
            catchId: titleNo,
            thumbnailSrc: thumb,
            isHorizontal: resolutionType === 'horizontal',
            viewCount: viewCnt,
            title: titleName,
            userId: bjId,
          };
        });
        setCatchList(transformedList);
      }
    } catch (error) {
      setCatchList([]);
    }
  };

  const getPopularCatchList = async () => {
    try {
      const response = await MyPlusApi.getPopularCatchList();
      if (response && response.data) {
        const transformedList = response.data?.list?.map((catchItem) => {
          const {
            thumb,
            titleNo,
            resolutionType,
            titleName,
            viewCnt,
            bjId,
            verticalThumb,
            ...rest
          } = catchItem;

          return {
            ...rest,
            catchId: titleNo,
            thumbnailSrc:
              resolutionType === 'horizontal' ? thumb : verticalThumb,
            isHorizontal: resolutionType === 'horizontal',
            viewCount: viewCnt,
            title: titleName,
            userId: bjId,
          };
        });
        setCatchList(transformedList);
      }
    } catch (error) {
      setCatchList([]);
    }
  };

  const handleOnClickMenu = (e, type, data, buttonRef) => {
    if (type === 'hidden') {
      handleOnClickHiddenStreamer(data);
    }
  };

  const handleUndoBroadcast = (data, type) => {
    if (hiddenStreamerList.includes(data.broadcastId)) {
      handleOnClickUndoHiddenStreamer(data);
    }
  };

  return (
    <>
      {catchList && catchList.length > 0 && (
        <>
          <SectionHeader
            title={
              <>
                {selectedMode === 'prefer' && isLogin() ? '관심' : '인기'}
                <span className='catch'>Catch</span> 모아보기
              </>
            }
          />
          <div className='catch_area'>
            <SoopUiSwiper
              swiperClass='catch_list'
              swiperTag='ul'
              maxSlidesPerView={10}
              options={swiperConfig}>
              {catchList.map((item, idx) => {
                const isLogSendData =
                  selectedMode === 'popular'
                    ? {
                        path1: 'popular',
                        path2: 'list',
                        path3: 'catch',
                        exCategoryNo: item.vodCategory,
                      }
                    : {
                        listIdx: idx + 1,
                      }; // or null if you prefer

                return (
                  <li key={idx}>
                    <MyCatch
                      {...{
                        ...item,
                        catchContextMenuList: CATCH_CONTEXT_MENU,
                      }}
                      thumbnailSrc={item.thumbnailSrc ?? item.thumbH}
                      isLogSendData={isLogSendData}
                      hiddenStreamerList={hiddenStreamerList}
                      handleUndoBroadcast={handleUndoBroadcast}
                      handleMenuClicked={handleOnClickMenu}
                    />
                  </li>
                );
              })}
            </SoopUiSwiper>
          </div>
        </>
      )}
    </>
  );
};
