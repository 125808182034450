import {CategoryList} from '@/components/main/MyPlus/common/CategoryList';
import {HotIssue} from '@/components/main/MyPlus/common/HotIssue';
import {LiveGroup} from '@/components/main/MyPlus/common/LiveGroup';
import {PlayerArea} from '@/components/main/MyPlus/common/PlayerArea';
import {TopBanner} from '@/components/main/MyPlus/common/TopBanner';
import {MAIN_LIST_CNT, POPULAR_INIT_LIVE_LIST} from '@/constants/main';
import {useState, useEffect} from 'react';
import {OriginalContentGroup} from './common/OriginalContentGroup';
import MainLiveApi from '@/api/main/main-live';
import {isLogin} from '@/utils/main';
import {Banner} from '@/components/common';
import useMainStore from '@/stores/main';
import {CatchGroup} from '@/components/main/MyPlus/common/CatchGroup';

//몇개의 LIVE 방송 노출 해줄지 판단 (현재로써는 최대 3개 + 리마인드 배너 1개)
const LOOP_COUNT = 3;

const formattedTopLiveList = (broad, count) => {
  if (!broad || broad.length === 0) {
    return [];
  }
  const formattedList = broad.map((broadcast) => {
    const {
      totalViewCnt,
      userNick,
      broadTitle,
      broadNo,
      broadThumb,
      isPassword,
      broadGrade,
      ...rest
    } = broadcast;
    return {
      ...rest,
      viewCount: totalViewCnt,
      userNickname: userNick,
      title: broadTitle,
      broadcastId: broadNo,
      thumbnailSrc: broadThumb,
      isPassword: isPassword === 'Y' ? 1 : 0,
      isAdult: Number(broadGrade),
      isBanner: false,
    };
  });

  let filteredList = formattedList.filter((item) => {
    // 비번방 아니고 && 성인방송 아니고 && 유료방송 아니고 숨기기 하지않은 스트리머 방송만 추출
    return (
      Number(item.broadType) !== 40 &&
      Number(item.isPassword) === 0 &&
      Number(item.isAdult) === 0
    );
  });

  if (filteredList.length <= count) {
    return filteredList.map((item) => ({
      ...item,
      isBanner: false, // 원하는 값을 설정
    }));
  }

  let randomItems = [];
  let selectedIndices = new Set();

  // 추출한 인덱스가 중복일땐 리스트에 포함시키면 안되기 때문에 루프
  while (randomItems.length < count) {
    const randomIndex = Math.floor(Math.random() * filteredList.length);
    if (!selectedIndices.has(randomIndex)) {
      selectedIndices.add(randomIndex);
      randomItems.push({
        ...filteredList[randomIndex],
        isBanner: false, // 원하는 값을 설정
      });
    }
  }

  return randomItems;
};

export const Popular = ({response, selectedMode, mainListInfo}) => {
  //인기 Live 리스트
  const [popularLiveList, setPopularLiveList] = useState(
    POPULAR_INIT_LIVE_LIST,
  );

  //스트리밍 Live 리스트
  const {setTopLiveList} = useMainStore();
  const [hiddenStreamer, setHiddenStreamer] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {banner, isShowMainBanner, isCloseMainBanner, isLoadingPlayerArea} =
    useMainStore();

  useEffect(() => {
    if (isLogin()) {
      getHiddenStreamerList();
    } else {
      setIsLoading(true);
    }
  }, []);

  useEffect(() => {
    if (response && mainListInfo && isLoading) {
      //스트리머 숨기기 리스트 제거
      const filteredBroadList =
        response.broad &&
        response.broad.length > 0 &&
        response.broad
          .filter((item) => !hiddenStreamer.includes(item.userId))
          .slice(0, MAIN_LIST_CNT);

      //상단 스트리밍 필터링 및 포멧팅
      const formattedList = formattedTopLiveList(filteredBroadList, LOOP_COUNT);
      setTopLiveList({
        simplifyMode:
          mainListInfo && mainListInfo.simplifymodeResult === -1 ? false : true,
        count: formattedList.length + banner.main.creatives.length || 0,
        list: [...formattedList, ...banner.main.creatives] || [
          ...formattedList,
        ],
      });

      setPopularLiveList(
        {
          totalCnt: Number(response.totalCnt) || 0,
          cnt: Number(response.cnt) || 0,
          liveList:
            filteredBroadList && filteredBroadList.length > 0
              ? filteredBroadList
              : [],
        } || POPULAR_INIT_LIVE_LIST,
      );
    }
  }, [response, banner.main, mainListInfo, isLoading]);

  const getHiddenStreamerList = async () => {
    try {
      const response = await MainLiveApi.getHiddenStreamerList();
      if (response && response.result === 1) {
        setHiddenStreamer(response.data);
      }
    } catch (error) {
      setHiddenStreamer([]);
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <>
      {/* 해상도 축소에 따라 플로팅배너가 비노출되었을때, 기존 플로팅배너 DOM을 그대로 유지하고있어야해서(해상도 확대 시. 시청했던 시점부터 다시 광고 재생을 위해서) 실제로 플로팅 배너 닫기버튼을 클릭하지 않을 시에는 계속헤서 dom을 유지시킴 */}
      {!isCloseMainBanner && <Banner type={'main'} category={'myplus'} />}
      {/* 플로팅 배너 미노출 && 비쥬얼 영역이 로딩중(플로팅 배너가 노출되기 전에도 비쥬얼 영역이 노출되고 그 위를 플로팅배너가 덮이는 현상이 있어서)일때 노출  */}
      {!isShowMainBanner && !isLoadingPlayerArea && (
        <PlayerArea banner={banner.main} />
      )}
      <LiveGroup response={popularLiveList} selectedMode={selectedMode} />
      <CategoryList selectedMode={selectedMode} />
      <TopBanner mainListInfo={mainListInfo} />
      <CatchGroup selectedMode={selectedMode} />
      <OriginalContentGroup mainListInfo={mainListInfo} />
      <HotIssue mainListInfo={mainListInfo} />
    </>
  );
};
