import {getTimeStamp} from '@/utils/main';
import LiveApi from '../live';
import ResApi from '../res';
import SportsApi from '../sports';
import StaticFileApi from '../staticfile';

const MainLiveApi = {
  /**
   * 정적 방송 리스트 가져오기
   * @returns {Object}
   */
  async getStaticBroadList() {
    try {
      const response = await StaticFileApi.get(
        `/pc/ko_KR/main_broad_list_json.js?${getTimeStamp()}`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 정적 방송 리스트 가져오기(성인방송까지)
   * @returns {Object}
   */
  async getStaticBroadListWithAdult() {
    try {
      const response = await StaticFileApi.get(
        `/pc/ko_KR/main_broad_list_with_adult_json.js?${getTimeStamp()}`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 정적 카테고리 리스트 가져오기
   * @returns {Object}
   */
  async getStaticCategoryList() {
    try {
      const response = await ResApi.get('/main/main_category_ko_KR.js');
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 정적 핫이슈,배너정보 등 가져오기
   * @returns {Object}
   */
  async getStaticBroadListMainInfo() {
    try {
      const response = await StaticFileApi.get(
        `/main/get_broadlist_main_info_ko_KR_json.js?${getTimeStamp()}`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * JSON 데이터 가져오기
   * @returns {Object}
   */
  async getJsonData(url) {
    try {
      const response = await ResApi.get(url);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 방송 리스트 가져오기
   * @param {String} selectType - 'action
   * @param {String} selectValue - 'all'
   * @param {String} orderType - 정렬
   * @param {String} pageNo - 페이지 번호
   * @param {String} lang - ko_KR
   * @returns {Object}
   */
  async getBroadList(queryParams) {
    try {
      const response = await LiveApi.get(
        `/api/main_broad_list_api.php`,
        queryParams,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getHiddenStreamerList() {
    try {
      const response = await LiveApi.get(
        '/api/hiddenbj/hiddenbjController.php',
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getPreferCategoryList() {
    try {
      const response = await LiveApi.get(
        '/api/myplus/preferLiveCateNoController.php',
      );
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 스포츠 스케줄 리스트 가져오기
   * @param {String} work - get_schedule_list
   * @param {String} code_list - ALL
   * @param {Boolean} live_score- true?
   * @returns {Object}
   */
  async getSportsScheduleList(queryParams) {
    try {
      const response = await SportsApi.get('/api.php', queryParams);
      return response;
    } catch (error) {
      throw error;
    }
  },

  /**
   * 이벤트 태그 스타일 가져오기
   * @returns {Object}
   */
  async getAutoTagStyle(queryParams) {
    try {
      const response = await ResApi.get(
        '/event_auto_hashtags.php',
        queryParams,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default MainLiveApi;
