'use client';

import {HiddenStreamer} from '@/components/main/common';
import MyProfileImg from '@/components/my/common/item/MyProfileImg';
import {
  SoopUiContextMenu,
  SoopUiProfileIconMenu,
  SoopUiImg,
} from '@/components/ui';

import globalDomains from '@/utils/global-domains';
import {convertViewCnt, getValues} from '@/utils/main';
import classnames from 'classnames';
import {isEmpty} from 'lodash';
import React, {useRef, useState} from 'react';
import '/public/static/css/module/catch_list.scss';
import {sendCatchClickLog, sendLegacyInflowPathLog} from '@/utils/main/my/log';
import {ROUTE} from '@/constants/main/route';
import {useLocation, useSearchParams} from 'react-router-dom';
import {decodeHtmlEntities} from '@/utils';

/**
 * @typedef {Object} Menu
 * @description 캐치 컨텍스트 메뉴
 * @property {number} id - 메뉴 ID
 * @property {string} name - 메뉴 이름
 * @property {string} url - 메뉴 URL
 */

/**
 *
 * @param {Object} props - MyCatch 컴포넌트에 필요한 props
 * @param {boolean} props.isStory - 캐치 스토리 여부
 * @param {number} props.catchId - 캐치 ID (스토리도 동일하게 사용)
 * @param {string} props.thumbnailSrc - 썸네일 이미지 URL
 * @param {boolean} props.isHorizontal - 썸네일 이미지가 가로형인지 여부
 * @param {number} props.userId - BJ 사용자 ID
 * @param {string} props.userNickname - BJ 사용자 닉네임
 * @param {string} props.broadcastDate - 방송일시
 * @param {number} props.viewCount - 조회수
 * @param {string} props.title - 제목
 * @param {boolean} props.isAdult - 성인방송 여부
 * @param {Menu[]} props.catchContextMenuList - 캐치 컨텍스트 메뉴 리스트
 * @param {function} props.handleMenuClicked - 캐치 컨텍스트 메뉴 클릭 핸들러
 * @returns {JSX.Element} MyCatch 컴포넌트
 */
const MyCatch = React.memo(function MyCatch({
  isStory,
  catchId,
  thumbnailSrc,
  isHorizontal,
  userId,
  userNickname,
  broadcastDate,
  viewCount,
  title,
  isAdult,
  fileType = '',
  listDataType = '',
  originalUserId = '',
  originalUserNick = '',
  catchContextMenuList,
  hiddenStreamerList = [],
  handleUndoBroadcast = () => {},
  handleMenuClicked,
  userNick,
  isLogSendData = {},
  catchStoryIdList = [],
  logCallback,
  grade,
  category,
  scheme = '',
  copyright = {},
  copyrightUserId,
}) {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const soopUiProfileIconMenu = useRef(null);

  const searchKey = searchParams.get('szKeyword');
  const storyRoute = getLocation(isStory, location.pathname);
  const [isShowProfileIconMenu, setIsShowProfileIconMenu] = useState(false);
  //isStory 여도 캐치스토리가 부족하면 자동으로 fileType = CATCH 영상들로 채우기 때문에 교차 검증
  const catchLink =
    isStory && fileType === 'CATCH_STORY'
      ? `${globalDomains.VOD_AFREECATV}/player/${catchId}/catchstory?szLocation=${storyRoute}&aStoryListIdx=${catchStoryIdList.join('-')}`
      : `${globalDomains.VOD_AFREECATV}/player/${catchId}/catch`;

  const formattedDate = () => {
    if (broadcastDate) {
      const broadDate = new Date(broadcastDate);
      return `${broadDate.getMonth() + 1}월 ${broadDate.getDate()}일`;
    }

    return '';
  };

  const originalAuthor =
    originalUserId || copyrightUserId || copyright?.userId || userId;

  const handleMenuClickedWithData = (event, type, data, buttonRef) => {
    event.preventDefault();
    event.stopPropagation();
    handleMenuClicked(event, type, data, buttonRef);
  };

  //스트리머 숨기기 컴포넌트
  if (hiddenStreamerList.includes(catchId)) {
    return (
      <HiddenStreamer
        type={'hidden'}
        userNickname={originalUserNick ? originalUserNick : userNickname}
        handleUndoBroadcast={() =>
          handleUndoBroadcast(
            {
              userNickname: originalUserNick ? originalUserNick : userNickname,
              userId: originalAuthor,
              broadcastId: catchId,
              listDataType,
            },
            'hidden',
          )
        }
      />
    );
  }

  const handleThumbnailClick = (type) => {
    // 캐치 유입 로그
    sendLegacyInflowPathLog({
      broadcastId: catchId,
      streamerId: originalAuthor,
      type: 'vod',
      moreData: {
        contentsType: 'vod',
        isStory,
        isCatch: true,
        listDataType,
        storyNo: isStory && catchId,
        ...(searchKey && {skey: searchKey}),
      },
      isLogSendData: {
        ...isLogSendData,
        uploader: userId,
      },
    });

    logCallback && logCallback(type);

    if (isStory) {
      return;
    }

    const myRoutes = getValues(ROUTE.MY);

    if (myRoutes.includes(location.pathname)) {
      sendCatchClickLog({
        streamerId: userId,
        catchId,
        fileType,
      });
    }
  };

  const isAdultThumbnail =
    isAdult ||
    (grade === '19' && (category === '30000' || category === '00030000'));

  return (
    <>
      <div className={classnames({story_thumb: isStory})}>
        <a
          href={catchLink}
          target='_blank'
          onClick={() => handleThumbnailClick({type: 'sn'})}>
          {isStory && <span className='catch_story'>스토리</span>}
          <Thumbnail
            thumbnailSrc={thumbnailSrc}
            isAdult={isAdultThumbnail}
            isHorizontal={isHorizontal}
          />
        </a>
        {isStory && (
          <a href={catchLink} className='story_profile' target='_blank'>
            <MyProfileImg userId={userId} />
          </a>
        )}
      </div>
      <a href={catchLink} className='info_area' target='_blank' title={title}>
        <p
          className='tit'
          onClick={(e) => {
            if (isStory) {
              e.preventDefault();
              soopUiProfileIconMenu.current?.open();
              logCallback && logCallback({type: 'nick'});
            } else {
              logCallback && logCallback({type: 'tt'});
            }
          }}>
          {isStory ? userNickname : decodeHtmlEntities(title)}
        </p>
        <SoopUiProfileIconMenu
          ref={soopUiProfileIconMenu}
          streamerId={userId}
          streamerNick={userNickname}
        />
        <p
          className={classnames({date: isStory, view: !isStory})}
          onClick={(e) => {
            logCallback && logCallback({type: 'tt'});
          }}>
          {isStory ? `${formattedDate()} 스토리` : convertViewCnt(viewCount)}
        </p>
        {!isEmpty(catchContextMenuList) && (
          <SoopUiContextMenu
            menuList={catchContextMenuList}
            handleMenuClicked={handleMenuClickedWithData}
            data={{
              broadcastId: catchId,
              userId: originalUserId ? originalUserId : userId,
              userNickname: originalUserNick || userNickname || userNick,
              fileType,
              listDataType,
              title:
                isStory && fileType === 'CATCH_STORY'
                  ? `${formattedDate()} 스토리`
                  : title,
              thumbnailSrc,
              scheme,
            }}
          />
        )}
      </a>
    </>
  );
});

/**
 * Catch 컴포넌트의 하위 컴포넌트
 * @desc 컴포넌트 분리를 위해 이렇게 둠 (재사용은 되지 않아서 같은 파일 안에 뒀다)
 * @param {Object} props
 * @param {string} props.thumbnailSrc - 썸네일 src
 * @param {boolean} props.isAdult - 성인방송 여부
 * @param {boolean} props.isHorizontal - 가로 여부
 */
const Thumbnail = React.memo(function Thumbnail({
  thumbnailSrc,
  isAdult,
  isHorizontal,
}) {
  const [isError, setIsError] = useState(false);

  const thumbClass = classnames('thumb', {
    thumb_16_9: isHorizontal && thumbnailSrc && !isAdult,
    'thumb-adult': isAdult,
    'thumb-default': !thumbnailSrc || isError,
  });

  const handleImgError = () => {
    setIsError(true);
  };

  return (
    <span className={thumbClass}>
      {thumbnailSrc && !isAdult && !isError && (
        <>
          <SoopUiImg src={thumbnailSrc} onError={handleImgError} />
          {isHorizontal && (
            <div className='thumb_blur'>
              <SoopUiImg src={thumbnailSrc} />
            </div>
          )}
        </>
      )}
    </span>
  );
});

function getLocation(isStory, pathname) {
  if (!isStory) {
    return;
  }
  if (ROUTE.INDEX === pathname) {
    return 'home';
  }
  if (ROUTE.SEARCH === pathname) {
    return 'search';
  }
  if (
    [
      ROUTE.MY.FAVORITE,
      ROUTE.MY.SUBSCRIBE,
      ROUTE.MY.FAN,
      ROUTE.MY.HISTORY.RECENT,
      ROUTE.MY.HISTORY.STORY,
    ].includes(pathname)
  ) {
    return 'my';
  }
  return '';
}

export default MyCatch;
