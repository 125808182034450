import {FavoriteButton} from '@/components/search/common';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

/**
 * StreamerItemRightBox 컴포넌트 ( 스트리머 영역의 메달 및 즐겨찾기 버튼 부분 )
 * @param {Object} props - 컴포넌트의 props
 * @param {String} userId - 스트리머 ID
 * @param {Boolean} favorite - 즐겨찾기 유무
 * @param {Boolean} medalURL - 스트리머 메달 여부 및 메달 이미지
 * @param {Function} logCallback - 로그 관련 콜백 함수
 * @returns StreamerItemRightBox 컴포넌트
 */
const StreamerItemRightBox = ({
  userId,
  favorite,
  medalURL,
  logCallback = () => {},
}) => {
  const {currentLoginUserId} = useSearchGlobalStatus();

  // URL에서 파일명을 추출하는 함수
  const getFileNameFromURL = (url) => {
    const parts = url.split('/');
    const fileName = parts[parts.length - 1]; // 마지막 부분이 파일명
    return fileName.split('.')[0]; // 파일명에서 확장자를 제거
  };

  const altText = medalURL ? getFileNameFromURL(medalURL) : '';

  return (
    <div className='rt_box'>
      {/* 스트리머 메달 */}
      {!!medalURL && (
        <span className='medal'>
          <img src={medalURL} alt={altText} />
        </span>
      )}

      {currentLoginUserId !== userId && (
        <FavoriteButton
          userId={userId}
          favorite={favorite}
          logCallback={logCallback}
        />
      )}
    </div>
  );
};

export default StreamerItemRightBox;
