'use client';

import {useEffect, useState} from 'react';
import {SoopUiSwiper} from '@/components/ui';
import {CATE_BANNER} from '@/constants/main/banner';
import styled from 'styled-components';

export const LiveGroupBothBanner = ({
  selectedMenu,
  mainListInfo,
  selectedCategory,
}) => {
  const [renderedBanner, setRenderedBanner] = useState(null);

  const swiperConfig = {
    loop: false,
  };

  useEffect(() => {
    if (mainListInfo) {
      let bannerComponent;
      if (selectedMenu === 'sports' && mainListInfo.sportBannerResult) {
        bannerComponent = renderCategoryBanner(mainListInfo.sportBannerData);
      } else {
        if (selectedMenu === 'bora') {
          let banners = [...CATE_BANNER.bora.element];
          const targetCategory = selectedCategory.categoryNo.trim();
          // 음악라이브, 음악스트리밍
          if (CATE_BANNER.bora_music.no.includes(targetCategory)) {
            banners[0] = CATE_BANNER.bora_music.element[0];
            bannerComponent = renderCategoryBanner(banners);
            // 펫
          } else if (targetCategory === CATE_BANNER.bora_pet.no) {
            banners[0] = CATE_BANNER.bora_pet.element[0];
            bannerComponent = renderCategoryBanner(banners);
            // 취미
          } else if (targetCategory === CATE_BANNER.bora_hobby.no) {
            banners[0] = CATE_BANNER.bora_hobby.element[0];
            bannerComponent = renderCategoryBanner(banners);
            // 미술
          } else if (targetCategory === CATE_BANNER.bora_art.no) {
            banners[0] = CATE_BANNER.bora_art.element[0];
            bannerComponent = renderCategoryBanner(banners);
            // 기본 값
          } else {
            bannerComponent = renderCategoryBanner(CATE_BANNER.bora.element);
          }
        }
        if (selectedMenu === 'game') {
          return;
          //게임 띠배너가 생긴다면,,,
        }
      }
      setRenderedBanner(bannerComponent);
    }
  }, [mainListInfo, selectedCategory.categoryNo]);

  const renderCategoryBanner = (banner) => {
    return (
      banner &&
      banner.map((item, idx) => (
        <li key={idx}>
          <a
            href={item.link}
            style={{background: item.bgColor}}
            target={`${item?.target === 'Y' ? '_blank' : ''}`}>
            <img src={item.imagePath} alt='' />
          </a>
        </li>
      ))
    );
  };

  return (
    <>
      {(selectedMenu === 'bora' || selectedMenu === 'sports') &&
        renderedBanner && (
          <BothBanner className='bnr_both_wrap'>
            <SoopUiSwiper
              swiperClass={'bnr_list'}
              swiperTag={'ul'}
              options={swiperConfig}
              maxSlidesPerView={2}>
              {renderedBanner}
            </SoopUiSwiper>
          </BothBanner>
        )}
    </>
  );
};

const BothBanner = styled.div`
  position: relative;
  .bnr_list {
    overflow: hidden;
    ul {
      display: flex;
      li {
        margin-right: 20px;
        @container main-container (min-width:1000px) {
          width: calc(50% - 10px);
        }
        @container main-container (max-width:999px) {
          width: 100%;
        }
        a {
          position: relative;
          display: block;
          height: 50px;
          text-align: center;
          img {
            position: absolute;
            top: 0;
            left: 50%;
            height: 100%;
            transform: translateX(-50%);
            object-fit: cover;
          }
        }
      }
    }
  }
`;
