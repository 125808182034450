import Feed from '@/components/my/feed/hotissue/Feed';
import HiddenFeed from '@/components/my/feed/hotissue/HiddenFeed';

const FeedList = ({feedData = [], fetchFeedData}) => {
  return (
    <ul className='hot-post'>
      {feedData.map((feed, idx) =>
        feed.isBlind ? (
          <HiddenFeed
            key={`${feed.titleNo}-${feed.rank}-${idx}`}
            {...feed}
            fetchFeedData={fetchFeedData}
          />
        ) : (
          <Feed
            key={`${feed.titleNo}-${feed.rank}-${idx}`}
            {...feed}
            fetchFeedData={fetchFeedData}
          />
        ),
      )}
    </ul>
  );
};

export default FeedList;
