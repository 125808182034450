'use client';
import {SoopUiProfileIconMenu} from '@/components/ui';
import globalDomains from '@/utils/global-domains';
import {useEffect, useRef, useState} from 'react';
import Player from '@/components/my/feed/item/Player';
import ActionGroup from '@/components/my/feed/hotissue/item/ActionGroup';
import {sendLegacyInflowPathLog} from '@/utils/main/my/log';
import {convertViewCnt} from '@/utils/main';
import {decodeEmoticon} from '@/libs/emoticon';
/**
 * @typedef {Object} count
 * @property {number} likeCnt - 좋아요 수
 * @property {number} readCnt - 조회 수
 * @property {number} commentCnt - 댓글 수
 */

/**
 * @typedef {Object} ucc
 * @property {String} adultChk - 성인 여부
 * @property {Number} category - 카테고리
 * @property {String} fileType - 파일 타입(CATCH, VOD, ?)
 * @property {String} flag - 플래그
 * @property {Number} grade - 등급
 * @property {String} hls - HLS
 * @property {Boolean} isAbroadBlocking - 해외 차단 여부
 * @property {Boolean} isAdult - 성인 여부
 * @property {String} thumb - 썸네일
 * @property {Number} totalFileDuration - 총 파일 시간
 * @property {String} uccType - UCC 타입
 */

/**
 * @description - 채널 새 동영상
 * @param {String} stationUserId - 채널 아이디
 * @param {String} stationUserNick - 채널 닉네임
 * @param {String} stationProfileImage - 채널 프로필 이미지
 * @param {String} regDateHuman - 등록일
 * @param {Boolean} isNotice - 공지 여부
 * @param {String} titleName - 제목
 * @param {String} content - 내용
 * @param {Object} ucc - UCC 정보
 * @param {String} url - 동영상 URL
 * @param {Array} hashtags - 해시태그
 * @param {Object} count - 좋아요, 조회, 댓글 수
 * @returns
 */
const NewVod = (vodInfo = {}) => {
  const {
    userId = '',
    stationUserId = '',
    stationUserNick = '',
    stationProfileImage = '',
    regDateHuman = '',
    isNotice = false,
    titleNo = 0,
    titleName = '',
    content = '',
    url = '',
    hashtags = [],
    isLike = false,
    count: {likeCnt = 0, readCnt = 0, commentCnt = 0} = {
      likeCnt: 0,
      readCnt: 0,
      commentCnt: 0,
    },
    isPlayable,
    onVisibilityChange,
    bbsNo,
    onPlayerError = () => {},
  } = vodInfo || {};
  const [isRendered, setIsRendered] = useState(false);
  const [isError, setIsError] = useState(false);
  const vodRef = useRef(null);

  const soopUiProfileIconMenu = useRef(null);
  const handleVodClick = () => {
    // 기존에 사용하던 VOD 유입 로그
    sendLegacyInflowPathLog({
      streamerId: stationUserId,
      broadcastId: titleNo,
      type: 'vod',
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && entry.intersectionRatio === 1) {
          // VOD 컴포넌트가 화면에 완전히 들어왔을 때
          setIsRendered(true);
          onVisibilityChange(true, vodInfo);
        } else {
          // VOD 컴포넌트가 화면에서 한 칸이라도 벗어났을 때
          setIsRendered(false);
          onVisibilityChange(false, vodInfo);
        }
      },
      {
        root: null, // 뷰포트를 기준으로 함.
        rootMargin: '0px', // 중앙을 기준으로 50% 위아래 마진을 설정함.
        threshold: 1, // 타겟 요소가 뷰포트에 완전히 들어올 때 콜백 실행.
      },
    );

    if (vodRef.current) {
      observer.observe(vodRef.current);
    }

    return () => {
      if (vodRef.current) {
        observer.unobserve(vodRef.current);
      }
    };
  }, []);

  const handlePlayerError = (e) => {
    onPlayerError(e);
    setIsError(e);
  };

  return (
    <>
      <div className='list-item'>
        <div className='item-wrap'>
          <div className='autor_wrap'>
            <span className='thum'>
              <a
                href={`${globalDomains.BJ_AFREECATV}/${userId}`}
                target='_blank'>
                <img src={stationProfileImage} alt='프로필 이미지' />
              </a>
            </span>
            <div className='info_box'>
              <button
                type='button'
                className='nick'
                onClick={() => {
                  soopUiProfileIconMenu.current?.open();
                }}>
                <p>{stationUserNick}</p>
              </button>
              <span>{regDateHuman}</span>
              <span>{`조회 ${convertViewCnt(readCnt)}`}</span>
              <SoopUiProfileIconMenu
                ref={soopUiProfileIconMenu}
                streamerId={stationUserId}
                streamerNick={stationUserNick}
              />
            </div>
          </div>
          <div className='item_contents'>
            {/* 제목 및 본문 내용 */}
            <a href={url} target='_blank'>
              <h3>
                {isNotice && <i className='ic_noti'>공지</i>}
                {titleName}
              </h3>
              {content && (
                <p
                  className='text'
                  dangerouslySetInnerHTML={{
                    __html: decodeEmoticon(content),
                  }}></p>
              )}
            </a>
            {/* 해쉬태그(디자인이 안나와서 임시 미노출 처리(추후 MY개편때 적용될 예정)) */}
            {/* {hashtags && hashtags.length > 0 && (
              <ul className='hasTag'>
                {hashtags.map((hashtag, idx) => (
                  <li key={idx}>
                    <a
                      href={`/search?hashtype=vod&hash=hashtag&tagname=${hashtag}&stype=hash&acttype=live&location=search`}
                      target='_blank'>
                      {hashtag}
                    </a>
                  </li>
                ))}
              </ul>
            )} */}
          </div>
          {/* vod 영역 */}
          <a
            ref={vodRef}
            href={url}
            target='_blank'
            className={`file vod ${isError ? 'error' : ''}`}
            onClick={handleVodClick}>
            <Player
              videoInfo={vodInfo}
              onError={handlePlayerError}
              playable={isPlayable}
              isRendered={isRendered}
            />
          </a>
          <ActionGroup {...vodInfo} />
        </div>
      </div>
    </>
  );
};

export default NewVod;
