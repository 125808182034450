import {useCallback} from 'react';

import {SoopUiSwiper} from '@/components/ui';
import {SectionHeader} from '@/components/main/common';
import {ErrorBoundary} from '@/components/common';
import {StreamerListItem} from '@/components/search/common';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

/**
 * SameNameSection 컴포넌트 ( 동명이인 )
 * @returns {JSX.Element} SameNameList 컴포넌트
 */
const SameNameGroup = (profileTheme) => {
  const {sessionKey} = useSearchGlobalStatus();
  const {sendSameNameGroupClickLog} = useSearchClickLog();
  const {homonym = []} = profileTheme || {};

  // SCKACT로그
  const handleLogCallback = useCallback(
    (params, index, userId) => {
      const {type} = params || {};

      const param = {
        actCode: type,
        listIdx: index,
        bjid: userId,
        contentsType: type === 'sti' ? 'bj' : '',
        contentsValue: type === 'sti' ? userId : '',
        sessionKey: sessionKey,
      };
      sendSameNameGroupClickLog(param);
    },
    [sessionKey],
  );

  if (homonym.length < 1) {
    return null;
  }

  return (
    <>
      {homonym && homonym.length > 0 ? (
        <>
          <SectionHeader title='앗 혹시 찾으시는 분이...' />

          {/* Swiper 부분 */}
          <div className='sameName_area'>
            <SoopUiSwiper
              swiperTag='ul'
              options={{slidesPerView: 'auto', loop: false}}>
              {homonym?.map((item, index) => (
                <li className='strm_box' key={`${item.userId}-${index}`}>
                  <ErrorBoundary>
                    <StreamerListItem
                      {...item}
                      showButton={true}
                      searchArea='homonym'
                      logCallback={(params) =>
                        handleLogCallback(params, index, item.userId)
                      }
                    />
                  </ErrorBoundary>
                </li>
              ))}
            </SoopUiSwiper>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SameNameGroup;
