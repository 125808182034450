import {ListTab} from './common/ListTab';
import {useEffect, useState} from 'react';
import {
  MAIN_LIST_CNT,
  DIRECTORY_CONTENT_INIT_LIST,
  DIRECTORY_LIVE_CONTEXT_MENU,
  DIRECTORY_VOD_CONTEXT_MENU,
  DIRECTORY_LIST_CNT,
} from '@/constants/main';
import DirectoryApi from '@/api/directory/directory';
import Catch from '@/components/my/common/item/MyCatch';
import {isLogin} from '@/utils/main';
import CategoryApi from '@/api/directory/category';
import useDirectoryStore from '@/stores/directory';
import LogApi from '@/api/common/log';
import {useHiddenStreamer} from '../common/hooks/useHiddenStreamer';
import {BroadcastLayout, BroadcastType} from '../common/Broadcast';
import ErrorBoundary from './category/ErrorBoundary';

export const DirectoryContent = ({page, setPage, order, setOrder}) => {
  const {
    selectedCategoryTab,
    selectedCategoryMenu,
    selectedCategoryType,
    selectedContentFilter,
    setSelectedCategoryTab,
    setSelectedCategoryMenu,
    setSelectedContentFilter,
  } = useDirectoryStore();
  const {
    remainCount,
    slicedBroadList,
    setRemainCount,
    filterBroadList,
    setSlicedBroadList,
  } = useHiddenStreamer();
  const [contentList, setContentList] = useState(DIRECTORY_CONTENT_INIT_LIST);
  const [hiddenStreamer, setHiddenStreamer] = useState([]);

  //리덕스 state 를 공유하고있는데 그거에 따른 언마운트시 초기화
  useEffect(() => {
    return () => {
      setSelectedCategoryMenu(0);
      setSelectedCategoryTab('live');
    };
  }, []);

  useEffect(() => {
    if (remainCount > 0) {
      setPage((prev) => prev + 1);
    }
  }, [remainCount]);

  useEffect(() => {
    if (hiddenStreamer.length > 0) {
      getContentsList();
    }
  }, [hiddenStreamer]);

  useEffect(() => {
    setRemainCount(0);
    setSlicedBroadList([]);
    setContentList(DIRECTORY_CONTENT_INIT_LIST);
  }, [selectedCategoryMenu]);

  useEffect(() => {
    if (contentList.isMore) {
      if (isLogin()) {
        getHiddenStreamerList();
      } else {
        getContentsList();
      }
    } else {
      if (slicedBroadList.length > 0) {
        setContentList({
          ...contentList,
          list: [...contentList.list, ...slicedBroadList],
        });
        setSlicedBroadList([]);
      } else {
        if (isLogin()) {
          getHiddenStreamerList();
        } else {
          getContentsList();
        }
      }
    }
  }, [
    page,
    order,
    selectedCategoryTab,
    selectedCategoryMenu,
    selectedContentFilter,
  ]);

  const handleLiveViewLog = (response) => {
    try {
      const params = {
        path1: 'directory',
        path2: 'admin',
        listSendType: 'client',
        directoryAdminId: selectedCategoryType.adminId,
        listViewCnt: response.list.length,
        listViewData: response.list.map((item) => ({
          bno: item.broadNo,
        })),
      };
      LogApi.sendLiveListViewLog(params);
    } catch (error) {}
  };

  const handleVodViewLog = (response) => {
    try {
      const params = {
        path1: 'directory',
        path2: 'admin',
        listSendType: 'client',
        directoryAdminId: selectedCategoryType.adminId,
        listViewCnt: response.list.length,
        listViewData: response.list.map((item) => ({
          vno: item.titleNo,
        })),
      };
      LogApi.sendVodListViewLog(params);
    } catch (error) {}
  };

  const getContentsList = async () => {
    try {
      const queryParams = {
        szMenuId: selectedCategoryMenu,
        szType: selectedCategoryTab,
        nPageNo: page,
        nListCnt:
          selectedCategoryTab === 'live' ? MAIN_LIST_CNT : DIRECTORY_LIST_CNT,
        szPlatform: 'pc',
      };

      if (selectedCategoryTab === 'live') {
        queryParams.szOrder = order;
      }
      if (selectedCategoryTab === 'vod') {
        queryParams.szOrder = selectedContentFilter.selected.find(
          (item) => item.key === 'sort',
        )?.type;
        queryParams.szFileType = selectedContentFilter.selected.find(
          (item) => item.key === 'fileType',
        )?.type;
        queryParams.szTerm = selectedContentFilter.selected.find(
          (item) => item.key === 'period',
        )?.type;
      }
      if (selectedCategoryTab === 'catch') {
        queryParams.szOrder = selectedContentFilter.selected.find(
          (item) => item.key === 'sort',
        )?.type;
        queryParams.szTerm = selectedContentFilter.selected.find(
          (item) => item.key === 'period',
        )?.type;
      }

      const response = await DirectoryApi.getContentsList(queryParams);
      if (response) {
        if (hiddenStreamer.length > 0) {
          const {isMore, list} = response;
          filterBroadList(
            selectedCategoryTab,
            page,
            list,
            isMore,
            hiddenStreamer,
            setContentList,
          );
        } else {
          const {isMore, list} = response;
          setContentList((prevContentList) => ({
            isMore: isMore,
            list: page === 1 ? list : [...prevContentList.list, ...list],
          }));
        }

        //listView 로그 전송
        if (response?.list?.length > 0) {
          if (selectedCategoryTab === 'live') {
            handleLiveViewLog(response);
          }
          if (
            selectedCategoryTab === 'vod' ||
            selectedCategoryTab === 'catch'
          ) {
            handleVodViewLog(response);
          }
        }
      }
    } catch (error) {
      setContentList(DIRECTORY_CONTENT_INIT_LIST);
    }
  };

  const getHiddenStreamerList = async () => {
    try {
      const response = await CategoryApi.getHiddenStreamerList();
      if (response && response.result === 1) {
        if (response.data.length === 0) {
          getContentsList();
        } else {
          setHiddenStreamer([...response.data]);
        }
      }
    } catch (error) {
      getContentsList();
    }
  };

  const handleOnClickTab = (type) => {
    if (type === selectedCategoryTab) {
      return;
    }
    setPage(1);
    setRemainCount(0);
    setSlicedBroadList([]);
    setContentList(DIRECTORY_CONTENT_INIT_LIST);
    setSelectedCategoryTab(type);
    if (type === 'live') {
      setOrder('view_cnt_desc');
    } else {
      setSelectedContentFilter({
        tab: type,
        selected: [
          {key: 'sort', label: '조회수', type: 'view_cnt_desc'},
          {key: 'fileType', label: '전체', type: 'ALL'},
          {key: 'period', label: '1주', type: '1week'},
        ],
      });
    }
  };

  const handleOnClickMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleOnClickSort = (type) => {
    if (type === order) {
      return;
    }
    setPage(1);
    setOrder(type);
    setRemainCount(0);
    setSlicedBroadList([]);
    setContentList(DIRECTORY_CONTENT_INIT_LIST);
  };

  const handleVodFilterClick = (key, label, type) => {
    setSelectedContentFilter({
      ...selectedContentFilter,
      selected: selectedContentFilter.selected.map((filter) =>
        filter.key === key ? {...filter, label, type} : filter,
      ),
    });
    setPage(1);
    setRemainCount(0);
    setSlicedBroadList([]);
    setContentList(DIRECTORY_CONTENT_INIT_LIST);
  };

  const listTabProps = {
    tab: selectedCategoryTab,
    order,
    handleOnClickTab,
    handleOnClickSort,
    handleVodFilterClick,
  };

  return (
    <>
      <ListTab {...listTabProps}></ListTab>
      {(selectedCategoryTab === 'live' || selectedCategoryTab === 'vod') &&
        contentList?.list?.length > 0 && (
          <div className='cBox-list'>
            <ul>
              {contentList?.list?.map((broadcast, broadcastIdx) => (
                <li
                  data-type='cBox'
                  key={`${broadcast.userId || broadcast.broadcastId}-${broadcastIdx}`}>
                  {selectedCategoryTab === 'live' && (
                    <ErrorBoundary fallback={<div>로딩중</div>}>
                      <BroadcastLayout
                        broadcastType='live'
                        liveType={broadcast.broadType}
                        broadcastId={broadcast.broadNo}
                        categoryName={
                          broadcast.categoryTags &&
                          broadcast.categoryTags.length > 0
                            ? broadcast.categoryTags[0]
                            : ''
                        }
                        autoHashTags={broadcast.autoHashtags}
                        categoryTags={broadcast.categoryTags}
                        hashTags={broadcast.hashTags}
                        allowsAdult={broadcast.grade}
                        hasBadge={
                          broadcast.autoHashtags ||
                          broadcast.categoryTags ||
                          broadcast.hashTags
                        }
                        hasPassword={broadcast.isPassword}
                        hasDrops={broadcast.isDrops}
                        startDateTime={broadcast.broadStart}
                        thumbnailUrl={broadcast.thumbnail}
                        title={broadcast.broadTitle}
                        userId={broadcast.userId}
                        canResend={Boolean(broadcast.visitBroadType)}
                        userNickname={broadcast.userNick}
                        viewCount={broadcast.viewCnt}
                        contextMenu={{
                          menuList: DIRECTORY_LIVE_CONTEXT_MENU,
                        }}
                        log={{
                          data: {
                            directoryAdminId: selectedCategoryType.adminId,
                          },
                        }}>
                        <BroadcastType.Live />
                      </BroadcastLayout>
                    </ErrorBoundary>
                  )}
                  {selectedCategoryTab === 'vod' && (
                    <ErrorBoundary fallback={<div>로딩중</div>}>
                      <BroadcastLayout
                        broadcastType='vod'
                        vodType={broadcast.fileType}
                        duration={broadcast.vodDuration}
                        broadcastId={broadcast.titleNo}
                        categoryName={
                          broadcast.categoryTags &&
                          broadcast.categoryTags.length > 0
                            ? broadcast.categoryTags[0]
                            : ''
                        }
                        autoHashTags={broadcast.autoHashtags}
                        categoryTags={broadcast.categoryTags}
                        hashTags={broadcast.hashTags}
                        allowsAdult={broadcast.grade}
                        hasBadge={
                          broadcast.autoHashtags ||
                          broadcast.categoryTags ||
                          broadcast.hashTags
                        }
                        hasDrops={broadcast.isDrops}
                        thumbnailUrl={broadcast.thumbnail}
                        title={broadcast.title}
                        userId={broadcast.userId}
                        userNickname={broadcast.userNick}
                        originalUserId={broadcast.originalUserId}
                        originalUserNickname={broadcast.originalUserNick}
                        viewCount={broadcast.viewCnt}
                        createdDate={broadcast.regDate}
                        previewThumbnailUrl={broadcast.webPath}
                        profileImageUrl={broadcast.thumbnail}
                        contextMenu={{menuList: DIRECTORY_VOD_CONTEXT_MENU}}>
                        <BroadcastType.Vod />
                      </BroadcastLayout>
                    </ErrorBoundary>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      {selectedCategoryTab === 'catch' && contentList?.list?.length > 0 && (
        <div className='catch_area'>
          <div className='catch_list'>
            <ul>
              {contentList?.list?.map((catchItem, catchIdx) => (
                <li key={`${catchItem.catchId}-${catchIdx}`}>
                  <Catch
                    catchId={catchItem.titleNo}
                    thumbnailSrc={catchItem.thumbnail}
                    isAdult={catchItem.grade}
                    isHorizontal={catchItem.resolutionType === 'horizontal'}
                    viewCount={catchItem.viewCnt}
                    {...catchItem}
                    isLogSendData={{
                      directoryAdminId: selectedCategoryType.adminId,
                      ...(catchItem.category && {
                        exCategoryNo: catchItem.category,
                      }),
                    }}
                    catchContextMenuList={[{label: '공유하기', type: 'share'}]}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {(contentList?.isMore || slicedBroadList.length > 0) && (
        <div className='show_more'>
          <button type='button' onClick={handleOnClickMore}>
            더보기
          </button>
        </div>
      )}
      {contentList?.list?.length === 0 && (
        <div className='noList'>
          <p>
            해당 카테고리의{' '}
            <strong>
              {selectedCategoryTab === 'catch'
                ? 'Catch'
                : selectedCategoryTab.toUpperCase()}
            </strong>
            가 없습니다.
          </p>
        </div>
      )}
    </>
  );
};
