import {useEffect, useState, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

/**
 * SuggestKeywordGroup 컴포넌트 ( 검색어제안 )
 * @param {object} props 컴포넌트의 props
 * @param {object} stopWordInfo 검색어 제안 데이터
 * @returns StreamerProfileGroup 컴포넌트
 */
const SuggestKeywordGroup = ({stopWordInfo}) => {
  const navigate = useNavigate();
  const {sessionKey} = useSearchGlobalStatus();
  const {sendSuggestKeywordClickLog} = useSearchClickLog();

  const [stopWord, setStopWord] = useState({});

  // SCKACT 로그
  const handleSuggestKeywordClick = useCallback(
    (e) => {
      e.preventDefault();

      sendSuggestKeywordClickLog({
        sessionKey: sessionKey,
      });

      const searchParams = new URLSearchParams({
        szLocation: 'total_search',
        szSearchType: 'total',
        szStype: 'mk',
        szOriginalKeyword: stopWord.origin,
        szModifiedKeyword: stopWord.modified,
        szKeyword: stopWord.modified,
        rs: '1',
        acttype: 'mk',
      });

      navigate(`/search?${searchParams.toString()}`);
    },
    [sessionKey, stopWord, sendSuggestKeywordClickLog],
  );

  useEffect(() => {
    setStopWord(stopWordInfo);
  }, [stopWordInfo]);

  const searchUrl = `/search?szLocation=total_search&szSearchType=total&szStype=mk&szOriginalKeyword=${stopWord.origin}&szModifiedKeyword=${stopWord.modified}&szKeyword=${stopWord.modified}&rs=1&acttype=mk`;

  return (
    stopWord &&
    stopWord.modified && (
      <div className='suggest_area'>
        <span className='label'>제안</span>
        <div className='result_txt'>
          <strong>{stopWord.origin}</strong>
          <span>{stopWord.postpos} 검색한 결과입니다.</span>
          <a href={searchUrl} onClick={handleSuggestKeywordClick}>
            {stopWord.modified} 검색결과 보기
          </a>
        </div>
      </div>
    )
  );
};

export default SuggestKeywordGroup;
