import {useCallback} from 'react';
import SearchApi from '@/api/search/search';
import MyFavoriteApi from '@/api/my/favorite';
import throwToast from '@/utils/ui/toast';
import useFavoriteStore from '@/stores/search';

// 원래 실섭(=아프리카tv) 검색페이지에서 사용하던 즐겨찾기 api. 
// MY 즐겨찾기 API로 변경했는데 혹시 몰라서 코드 남겨둠
const manageFavorite = async (action, userId, streamerId) => {
  try {
    const response = await SearchApi.manageFavorite(action, userId, streamerId);
    return response;
  } catch (error) {
    return null;
  }
};

const useFavorite = () => {
  const {setFavoriteStreamerName} = useFavoriteStore();

  const handleAddFavorite = useCallback(async (userId, streamerId) => {
    try {
      await MyFavoriteApi.addFavorite(streamerId);
      setFavoriteStreamerName(streamerId);
      throwToast('즐겨찾기에 추가됐습니다.');
      return true;
    } catch (error) {
      console.error('즐겨찾기 추가 중 오류 발생:', error);
      throwToast('즐겨찾기 추가 중 오류가 발생했습니다.');
      return false;
    }
  }, []);

  const handleDeleteFavorite = useCallback(async (userId, streamerId) => {
    try {
      await MyFavoriteApi.deleteFavorite(streamerId);
      setFavoriteStreamerName(streamerId);
      throwToast('즐겨찾기에서 삭제됐습니다.');
      return true;
    } catch (error) {
      console.error('즐겨찾기 삭제 중 오류 발생:', error);
      throwToast('즐겨찾기 삭제 중 오류가 발생했습니다.');
      return false;
    }
  }, []);

  return {
    handleAddFavorite,
    handleDeleteFavorite,
  };
}

export default useFavorite;
