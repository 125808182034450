'use client';
import useToastStore from '@/stores/ui/toast';

export default function SoopUiToast() {
  const toastList = useToastStore((state) => {
    return state.toastList.reverse();
  });

  return (
    <div className='toast-box'>
      {toastList.map((toast, index) => {
        return (
          <div key={index} className={toast.className}>
            {!toast.children ? <p>{toast.message}</p> : <>{toast.children}</>}
          </div>
        );
      })}
    </div>
  );
}
