'use client';
import {useEffect, useState} from 'react';
import MainLiveApi from '@/api/main/main-live';
import {getUserCookie, getTimeStamp, isLogin} from '@/utils/main';
import {LIVE_INIT_LIST, MAIN_LIST_CNT} from '@/constants/main';
import {LiveGroupList} from '@/components/main/live/LiveGroupList';
import {useNavigate} from 'react-router-dom';
import {Banner} from '@/components/common';
import {useHiddenStreamer} from '../../common/hooks/useHiddenStreamer';
import {LiveMenu} from '@/components/main/live/LiveMenu';

export const LiveAll = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState({
    prev: 'view_cnt',
    current: 'view_cnt',
  });
  const [selectedMenu, setSelectedMenu] = useState('all');
  const [hiddenStreamer, setHiddenStreamer] = useState([]);
  const [broadList, setBroadList] = useState(LIVE_INIT_LIST);
  const {
    remainCount,
    slicedBroadList,
    hiddenStreamerList,
    setRemainCount,
    filterBroadList,
    setSlicedBroadList,
    handleOnClickHiddenStreamer,
    handleOnClickUndoHiddenStreamer,
  } = useHiddenStreamer('LIVE', selectedMenu);
  const userAge = getUserCookie('age');

  useEffect(() => {
    if (broadList.isMore) {
      if (isLogin()) {
        getHiddenStreamerList();
      } else {
        getBroadList();
      }
    } else {
      //다음페이지가 없지만 slicedBroadList가 있다는 것은 더보기 클릭시 남은 리스트를 다그려줘야한다.
      if (slicedBroadList.length > 0) {
        setBroadList({
          ...broadList,
          list: [...broadList.list, ...slicedBroadList],
        });
        setSlicedBroadList([]);
      } else {
        if (page === 1 && remainCount === 0 && broadList.list.length === 0) {
          if (isLogin()) {
            getHiddenStreamerList();
          } else {
            getBroadList();
          }
        }
      }
    }
  }, [page, order]);

  useEffect(() => {
    if (hiddenStreamer.length > 0) {
      getBroadList();
    }
  }, [hiddenStreamer]);

  useEffect(() => {
    if (remainCount > 0) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [remainCount]);

  //더보기 클릭 이벤트
  const handleOnClickMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  //상단 메뉴 클릭 이벤트 -> 그페이지로 이동 (전체,보이는 라디오,게임,스포츠)
  const handleOnClickLiveMenu = (menuId) => {
    if (menuId === 'all') {
      return;
    } else {
      navigate(`/live/group/${menuId}`);
    }
    setSelectedMenu(menuId);
    setPage(1);
    setOrder({
      prev: 'view_cnt',
      current: 'view_cnt',
    });
    setBroadList(LIVE_INIT_LIST);
    setRemainCount(0);
    setSlicedBroadList([]);
  };

  //정렬 클릭 이벤트
  const handleOnClickSort = (type) => {
    if (type === order.current) {
      return;
    }
    setPage(1);
    setOrder({
      prev: order.current,
      current: type,
    });
    setBroadList(LIVE_INIT_LIST);
    setRemainCount(0);
    setSlicedBroadList([]);
  };

  //방송 리스트 호출 api
  const getBroadList = async () => {
    try {
      let response = {};
      //라이브 전체일 경우 정적인 리스트 js를 호출한다.
      if (page === 1 && order.prev === order.current) {
        if (userAge >= 19) {
          response = await MainLiveApi.getStaticBroadListWithAdult();
        } else {
          response = await MainLiveApi.getStaticBroadList();
        }
      } else {
        const queryParams = {
          selectType: 'action',
          selectValue: selectedMenu,
          orderType: order.current,
          pageNo: page,
          lang: 'ko_KR',
          _: getTimeStamp(),
        };
        response = await MainLiveApi.getBroadList(queryParams);
      }
      if (response && response.broad) {
        const {totalCnt, broad} = response;
        if (Number(totalCnt) > 0 && broad.length > 0) {
          const totalPage = Math.ceil(Number(totalCnt) / MAIN_LIST_CNT);
          const isMore = Number(page) < Number(totalPage) ? true : false;
          if (hiddenStreamer.length > 0) {
            filterBroadList(
              'live',
              page,
              broad.slice(0, MAIN_LIST_CNT),
              isMore,
              hiddenStreamer,
              setBroadList,
            );
          } else {
            setBroadList({
              isMore: isMore,
              list: [...broadList.list, ...broad.slice(0, MAIN_LIST_CNT)],
            });
          }
        }
      }
    } catch (error) {
      setBroadList(LIVE_INIT_LIST);
    }
  };

  //스트리머 숨기기 리스트 호출 api
  const getHiddenStreamerList = async () => {
    try {
      const response = await MainLiveApi.getHiddenStreamerList();
      if (response && response.result === 1) {
        if (response.data.length === 0) {
          getBroadList();
        } else {
          setHiddenStreamer(response.data);
        }
      }
    } catch (error) {
      setHiddenStreamer([]);
    }
  };

  const liveMenuProps = {
    selectedMenu,
    handleOnClickLiveMenu,
  };

  const liveListProps = {
    order: order.current,
    broadList,
    selectedMenu,
    slicedBroadList,
    handleOnClickMore,
    handleOnClickSort,
    handleOnClickHiddenStreamer,
    location: selectedMenu,
  };

  return (
    <>
      <LiveMenu {...liveMenuProps}></LiveMenu>
      <div id='container' className='_live'>
        <Banner type={'sub'} category={selectedMenu} />
        <LiveGroupList {...liveListProps}></LiveGroupList>
      </div>
    </>
  );
};
