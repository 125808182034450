'use client';

import {useRef} from 'react';
import useDialogStore from '@/stores/ui/dialogs';

import classNames from 'classnames';
import PlayListLayer from '@/components/dialog-bodies/common/PlayList';
import ExtendedNewGroup from '@/components/dialog-bodies/my/ExtendedNewGroup';
import FanclubSetting from '@/components/dialog-bodies/my/FanclubSetting';
import FavoriteGroup from '@/components/dialog-bodies/my/FavoriteGroup';
import FavoriteGroupEdit from '@/components/dialog-bodies/my/FavoriteGroupEdit';
import FavoriteSetting from '@/components/dialog-bodies/my/FavoriteSetting';
import MiniNewGroup from '@/components/dialog-bodies/my/MiniNewGroup';
import NicknameEdit from '@/components/dialog-bodies/my/NicknameEdit';
import SubscribeSetting from '@/components/dialog-bodies/my/SubscribeSetting';
import NewPlayList from '@/components/dialog-bodies/common/NewPlayList';
import Message from '@/components/dialog-bodies/Message';

const BodyComponents = {
  'common/PlayList': PlayListLayer,
  'common/NewPlayList': NewPlayList,
  'my/ExtendedNewGroup': ExtendedNewGroup,
  'my/NicknameEdit': NicknameEdit,
  'my/FanclubSetting': FanclubSetting,
  'my/FavoriteGroup': FavoriteGroup,
  'my/FavoriteGroupEdit': FavoriteGroupEdit,
  'my/FavoriteSetting': FavoriteSetting,
  'my/MiniNewGroup': MiniNewGroup,
  'my/SubscribeSetting': SubscribeSetting,
  Message: Message,
};
const DialogItem = ({
  id,
  name,
  data,
  option = {
    size: 'middle',
    isCustomDialog: false,
    title: '',
    customClassName: '',
    titleAlign: 'center',
  },
}) => {
  const popDialog = useDialogStore((state) => {
    return state.popDialog;
  });

  const DialogBodyComponent = BodyComponents[name]; // TODO : 다이얼로그 없을때 예외처리

  const bodyRef = useRef(null);

  const _handleCloseBefore = (isPositiveClicked) => {
    return bodyRef.current?.getReturnValue(isPositiveClicked);
  };
  const _handlePositiveClick = () => {
    /*if (!isBodyLoaded) {
      popDialog(null, true);
      return false;
    }*/
    const returnValue = _handleCloseBefore(true);
    if (returnValue === false) {
      // 명시적 false는 validation 실패로 간주한다.
      return false;
    }
    popDialog(returnValue, true);
  };
  const _handleNegativeClick = () => {
    _handleCloseBefore(false);
    popDialog(null, false);
  };

  return option.isCustomDialog ? (
    <div
      id={id}
      className={classNames({
        [option.customClassName]: option.customClassName,
        basic_alert: option.isConfirm || option.isAlert,
      })}>
      <DialogBodyComponent
        bodyRef={bodyRef}
        data={data}
        dialogId={id}
        isConfirm={option.isConfirm}
        hasCloseButtonOnly={option.hasCloseButtonOnly}
        handleClose={_handleNegativeClick}
        handlePositiveClose={_handlePositiveClick}
      />
    </div>
  ) : (
    <div id={id}>
      <div className='layer_container'>
        <h3 className={`${option.titleAlign === 'left' ? 'align_left' : ''}`}>
          {option.title}
        </h3>
        <div className={`${option.customClassName || 'new_list_add_layer'}`}>
          <div className='list_form'>
            {
              <DialogBodyComponent
                bodyRef={bodyRef}
                data={data}
                dialogId={id}
                isConfirm={option.isConfirm}
                handleClose={_handleNegativeClick}
                handlePositiveClose={_handlePositiveClick}
              />
            }
          </div>
        </div>
        <button
          type='button'
          className='btn_close'
          onClick={_handleNegativeClick}>
          닫기
        </button>
      </div>
    </div>
  );
};

export default DialogItem;
