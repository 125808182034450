'use client';
import HotKeywordList from '@/components/my/feed/group/HotKeywordList';
import RealTimePopularList from '@/components/my/feed/group/RealTimePopularList';

// 채널 새 글 사이드 컴포넌트
const Side = () => {
  return (
    <section className='side-contents'>
      {/* 핫 키워드 리스트 */}
      <HotKeywordList />

      {/* 실시간 인기글 리스트 */}
      <RealTimePopularList />
    </section>
  );
};

export default Side;
