const NoData = ({feedType = ''}) => {
  const getMessage = (type) => {
    switch (type) {
      case '':
        return '즐겨찾기한 스트리머 채널에 업로드된 게시물이 없습니다.';
      case 'POST_PHOTO':
        return '글만 작성한 게시물이 없습니다.';
      case 'NOTICE':
        return '공지 게시물이 없습니다.';
      default:
        return null;
    }
  };

  return (
    <>
      <div className='list-item'>
        <div className='item-wrap'>
          <p className='no_list'>{getMessage(feedType)}</p>
        </div>
      </div>
    </>
  );
};

export default NoData;
