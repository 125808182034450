import {useState, useEffect, useRef} from 'react';
import '/public/static/css/module/ad_player.scss';
import playerCss from '/public/static/css/components/player.module.scss';
import {SoopUiSwiper, SoopUiImg} from '@/components/ui';
import {convertViewCnt} from '@/utils/main';
import globalDomains from '@/utils/global-domains';
import {PreviewPlayer} from '@/components/player/preview/PreviewPlayer';
import useMainStore from '@/stores/main';
import MyProfileImg from '@/components/my/common/item/MyProfileImg';
import {PreviewAdPlayer} from '@/components/player/preview/PreviewAdPlayer';
import {
  BANNER_REQUEST_TYPE,
  BANNER_RESPONSE_TYPE,
} from '@/constants/main/banner';
import BannerApi from '@/api/common/banner';
import {sendClickLog, sendTopListLiveInflowLog} from '@/utils/main/my-plus/log';
import VastInfo from '@/utils/player/VastInfo';
import {decodeHtmlEntities} from '@/utils';

const PLAYER_ERROR = {
  EXCEED_PLAY_LIMIT: 'EXCEED_PLAY_LIMIT', //1분 재생완료되었을떄 -> 라이브 이어보기 버튼 노출
  STATUS_ERROR: 'STATUS_ERROR', //플레이어 상태 변경 되었을때 -> 상태변경 안내 노출
};

const formattedMainBanner = (banner) => {
  if (!banner || !banner.length === 0) {
    return [];
  }

  return banner.map((item) => {
    const {advertiser, adText, imageUrl, ...rest} = item;
    return {
      isBanner: true,
      thumbnailSrc: imageUrl, //썸네일
      title: adText, //광고제목
      userNickname: advertiser, //광고주
      ...rest,
    };
  });
};

export const PlayerArea = ({banner}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [continueLiveButton, setContinueLiveButton] = useState({
    exceedPlay: false,
    autoPlayBlock: false,
    changeStatus: false,
  });
  const {topLiveList} = useMainStore();
  const [activeIndex, setActiveIndex] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [adPlaying, setAdPlaying] = useState(false);
  const [bannerMuted, setBannerMuted] = useState(true);
  const [isAdReady, setIsAdReady] = useState(false);

  const [isLoadingRemindBanner, setIsLoadingRemindBanner] = useState(false);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const {
    checkAd,
    isShowMainBanner,
    isLoadingPlayerArea,
    isRenderMainBanner,
    setBanner,
  } = useMainStore();

  const handleImageError = () => {
    setImageError(true);
  };

  const handleOnChangeSlide = (event) => {
    setActiveIndex(event.activeIndex);
    sendClickLog('main_top', {
      filterIdx: event.activeIndex + 1,
      button_type: 'live_banner',
    });
  };

  const handleOnClickLive = (item) => {
    if (item.isBanner) {
      if (checkAd.AdParameter.hasOwnProperty('sendTracking')) {
        checkAd.AdParameter.sendTracking(item.subClickUrls);
        //리마인드 배너의 응답갑이 비디오형일떄만
        if (banner.type === BANNER_RESPONSE_TYPE.PC_MAIN_VIDEO) {
          const subVastInfo = VastInfo.getVastInfo(item.vast2);
          checkAd.AdParameter.sendTracking(
            subVastInfo.videoClicks.clickTracking,
          );
        }
      }
    } else {
      //클릭 로그
      sendClickLog('main_top', {
        buttonType: 'live_mainarea',
        contentsType: 'live',
        actionType: 'to_live',
      });
      //진입 로그
      sendTopListLiveInflowLog({
        streamerId: item.userId,
        broadcastId: item.broadcastId,
      });
    }
    window.open(
      item.isBanner
        ? item.landingUrl
        : `${globalDomains.PLAY_AFREECATV}/${item.userId}/${item.broadcastId}`,
    );
  };

  const swiperConfigMain = {
    slidesPerView: 4,
    thumbs: {
      swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
    },
    effect: 'fade',
    fadeEffect: {crossFade: true},
    allowTouchMove: true,
    loop: false,
    pagination: {el: '.player-pagination'},
    navigation: {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    },
    onSlideChange: handleOnChangeSlide,
  };

  const swiperConfigSub = {
    direction: 'vertical',
    slidesPerView: 4,
    loop: false,
    freeMode: true,
    watchSlidesProgress: true,
    spaceBetween: 0,
  };

  useEffect(() => {
    if (continueLiveButton) {
      setContinueLiveButton({
        exceedPlay: false,
        autoPlayBlock: false,
      });
    }

    const currentSlide = topLiveList?.list[activeIndex];
    if (currentSlide) {
      //banner 그려진 적 있는지
      if (currentSlide.isBanner) {
        setIsLoadingRemindBanner(true);
      }
      setAdPlaying(currentSlide.isBanner);
    }
  }, [activeIndex]);

  useEffect(() => {
    if (!isRenderMainBanner && !isLoadingPlayerArea && !banner.exist) {
      getBanner();
    }
  }, [isLoadingPlayerArea]);

  const getBanner = async () => {
    try {
      if (
        checkAd.AdParameter.hasOwnProperty('getAdParameters') &&
        checkAd.banners.bAdPcMainBanner
      ) {
        const params = checkAd.AdParameter.getAdParameters(
          BANNER_REQUEST_TYPE.MAIN_BANNER,
          {category: 'myplus'},
        );
        const response = await BannerApi.getBanner(params);
        if (response) {
          if (
            response.exist &&
            (response.type === BANNER_RESPONSE_TYPE.PC_MAIN_IMAGE ||
              response.type === BANNER_RESPONSE_TYPE.PC_MAIN_VIDEO)
          ) {
            setBanner({
              ...banner,
              main: {
                type: response.type,
                exist: response.exist,
                creatives: [...formattedMainBanner(response.creatives)],
              },
            });
          }
        }
      }
    } catch (error) {
      setBanner({
        ...banner,
        main: {
          type: '',
          exist: false,
          creatives: [],
        },
      });
    }
  };

  const handlerAutoPlayBlock = (media) => {
    console.warn('autoPlayBlock ', media);
    setPlaying(false);
    setAdPlaying(false);
    setContinueLiveButton({
      ...continueLiveButton,
      autoPlayBlock: true,
    });
  };

  const handlePlayerError = (event) => {
    switch (event) {
      case PLAYER_ERROR.EXCEED_PLAY_LIMIT:
        setContinueLiveButton({
          ...continueLiveButton,
          exceedPlay: true,
        });
        break;
      case PLAYER_ERROR.STATUS_ERROR:
        setContinueLiveButton({
          ...continueLiveButton,
          changeStatus: true,
        });
        break;
      default:
        break;
    }
    console.error(event);
  };

  const handlerAdCanPlay = (event) => {
    setIsAdReady(true);
  };
  //topLiveList 가 없거나 simplifyMode가 true 일때는 스트리밍 영역자체 미노출
  if (
    (topLiveList && topLiveList.simplifyMode) ||
    (topLiveList && topLiveList.count === 0 && isLoadingPlayerArea)
  ) {
    return null;
  }

  return (
    topLiveList &&
    topLiveList.list?.length > 0 && (
      <div className={playerCss.player_wrap}>
        <div className={playerCss.player_box}>
          <SoopUiSwiper
            swiperClass={playerCss.player_view}
            swiperTag={'ul'}
            options={swiperConfigMain}
            maxSlidePerView={topLiveList.count}
            hasControls={false}
            handleSlideChanged={handleOnChangeSlide}>
            {topLiveList.list?.map(
              (item, idx) =>
                item && (
                  <li key={idx} className={item.isBanner ? playerCss.a_d : ''}>
                    <div className={playerCss.wrap}>
                      <a
                        onClick={() => handleOnClickLive(item)}
                        style={{cursor: 'pointer'}}>
                        {(activeIndex === idx ||
                          (item.isBanner && isLoadingRemindBanner)) &&
                          !isShowMainBanner &&
                          (item.isBanner ? (
                            <div className={playerCss.player}>
                              <div className={playerCss.inner}>
                                {banner.type ===
                                  BANNER_RESPONSE_TYPE.PC_MAIN_IMAGE && (
                                  <div className='img' id='da_player'>
                                    <div className='poster'>
                                      <img
                                        src={item.thumbnailSrc}
                                        onError={(e) => {
                                          if (
                                            checkAd.AdParameter.hasOwnProperty(
                                              'sendTracking',
                                            )
                                          ) {
                                            checkAd.AdParameter.sendTracking(
                                              item.errorUrl,
                                            );
                                          }
                                        }}
                                        onLoad={(e) => {
                                          if (
                                            checkAd.AdParameter.hasOwnProperty(
                                              'sendTracking',
                                            )
                                          ) {
                                            checkAd.AdParameter.sendTracking(
                                              item.subImpressionUrls,
                                            );
                                            checkAd.AdParameter.sendTracking(
                                              item.subViewableUrls,
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                                {banner.type ===
                                  BANNER_RESPONSE_TYPE.PC_MAIN_VIDEO && (
                                  // 리마인드 비디오형 배너는 기존 clickUrls, impressionUrls, viewableUrls, vast 가  subClickUrls, subImpressionUrls, subViewableUrls, vast2 값을 넣어줘야한다.
                                  <PreviewAdPlayer
                                    adInfo={{
                                      type: banner.type,
                                      exist: banner.exist,
                                      creatives: [
                                        {
                                          ...item,
                                          vast: item.vast2,
                                          clickUrls: item.subClickUrls,
                                          impressionUrls:
                                            item.subImpressionUrls,
                                          viewableUrls: item.subViewableUrls,
                                        },
                                      ],
                                    }}
                                    autoPlay={true}
                                    muted={bannerMuted}
                                    playing={adPlaying}
                                    visible={idx === activeIndex}
                                    onAutoPlayBlock={handlerAutoPlayBlock}
                                    onCanPlay={handlerAdCanPlay}
                                  />
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className={playerCss.player}>
                              <div className={playerCss.inner}>
                                <PreviewPlayer
                                  livePlayId={item.userId}
                                  broadNo={item.broadcastId}
                                  thumbnail={item.thumbnailSrc}
                                  playDurationLimit={60}
                                  refreshIntervalTime={10}
                                  autoPlay
                                  playing={playing}
                                  muted={true}
                                  onAutoPlayBlock={handlerAutoPlayBlock}
                                  onError={handlePlayerError}
                                />
                              </div>
                            </div>
                          ))}
                        <div className={playerCss.live_info_area}>
                          <div className={playerCss.info_inner}>
                            <span
                              className={playerCss.thumb}
                              onClick={(e) => {
                                if (!item.isBanner) {
                                  e.preventDefault();
                                  e.stopPropagation(); // 클릭 이벤트 전파 방지
                                  window.open(
                                    `${globalDomains.BJ_AFREECATV}/${item.userId}`,
                                  );
                                }
                              }}>
                              {item.isBanner ? (
                                <img src={item.profileImageUrl}></img>
                              ) : (
                                <MyProfileImg userId={item.userId} />
                              )}
                            </span>
                            <div className={playerCss.info_wrap}>
                              <div className={playerCss.info}>
                                <p className={playerCss.nick}>
                                  {item.userNickname}
                                </p>
                              </div>
                              <p className={playerCss.title}>
                                {decodeHtmlEntities(item.title)}
                              </p>

                              {continueLiveButton.changeStatus && (
                                <p className={playerCss.state}>
                                  스트리머가 방송 상태를 변경하여 미리볼 수
                                  없습니다.
                                </p>
                              )}
                              {continueLiveButton.exceedPlay && (
                                <button
                                  type='button'
                                  className={playerCss.continue}>
                                  라이브 이어보기
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>

                    <div className={playerCss.btn_utils}>
                      {item.isBanner &&
                        banner.type === BANNER_RESPONSE_TYPE.PC_MAIN_VIDEO &&
                        isAdReady && (
                          <button
                            type='button'
                            className={`${bannerMuted ? playerCss.soundOff : playerCss.soundOn}`}
                            onClick={() => {
                              setBannerMuted(!bannerMuted);
                            }}>
                            음소거/음소거 해제
                          </button>
                        )}
                      {continueLiveButton.autoPlayBlock && (
                        <button
                          className={playerCss.playOn}
                          onClick={() => {
                            const currentSlide = topLiveList?.list[activeIndex];
                            currentSlide && currentSlide.isBanner
                              ? setAdPlaying(true)
                              : setPlaying(true);

                            setContinueLiveButton({
                              ...continueLiveButton,
                              autoPlayBlock: false,
                            });
                          }}>
                          재생
                        </button>
                      )}
                    </div>
                  </li>
                ),
            )}
          </SoopUiSwiper>
        </div>
        {/* 스와이프 컨트롤바 */}
        <div className={`player-pagination`}></div>
        <button
          type='button'
          className={playerCss.btn_prev}
          ref={navigationPrevRef}>
          이전
        </button>
        <button
          type='button'
          className={playerCss.btn_next}
          ref={navigationNextRef}>
          다음
        </button>
        <SoopUiSwiper
          onSwiper={setThumbsSwiper}
          swiperClass={playerCss.thumb_list}
          hasControls={false}
          options={swiperConfigSub}
          maxSlidePerView={topLiveList.count}>
          {topLiveList.list.map((item, idx) => (
            <li key={idx}>
              {item.isBanner ? (
                <div className={playerCss.thumb_wrap}>
                  <div className={playerCss.thumb}>
                    <SoopUiImg
                      type='banner'
                      src={item.thumbnailSrc}
                      onError={handleImageError}
                    />
                  </div>
                  <div className={playerCss.info_wrap}>
                    <p className={playerCss.nickName}>{item.userNickname}</p>
                    <span className={playerCss.a_d_tag}>AD</span>
                  </div>
                </div>
              ) : (
                <div className={playerCss.thumb_wrap}>
                  <div className={playerCss.thumb}>
                    <SoopUiImg
                      type='banner'
                      src={item.thumbnailSrc}
                      onError={handleImageError}
                    />
                  </div>
                  <div className={playerCss.info_wrap}>
                    <p className={playerCss.nickName}>{item.userNickname}</p>
                    <span>{convertViewCnt(item.viewCount)}명 참여 중</span>
                  </div>
                </div>
              )}
            </li>
          ))}
        </SoopUiSwiper>
      </div>
    )
  );
};
