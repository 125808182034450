'use client';

import React from 'react';

export default function GnbDummy() {
  return (
    <>
      <header id='serviceHeader'>
        <div className='innerHeader'>
          {/* LNB 확장/축소 관련 */}
          <div className='btn_flexible'>
            <button type='button'>메뉴 확장/축소</button>
          </div>
          {/* // LNB 확장/축소 관련 */}
          <h1 id='logo'>
            <a href='/'>
              <img
                src='//res.afreecatv.com/images/svg/soop_logo.svg'
                alt='SOOP'
              />
            </a>
          </h1>

          <div className='collabo_brand'>
            <a href='#n'>
              <img src='https://www.dummyimage.com/80x40' alt='' />
            </a>
          </div>

          <div className='top_searcharea_wrap'>
            <div className='search_inner'>
              <div id='topSearchArea'>
                <div className='searchInner'>
                  <div className='inputArea'>
                    <button type='button' className='srh_back'>
                      뒤로
                    </button>
                    <div className='form'>
                      <input
                        type='text'
                        name=''
                        id='search-inp'
                        defaultValue='로나월드'
                      />
                      <button type='button' className='del_text'>
                        입력내용 삭제
                      </button>
                      <button type='submit' className='submit'>
                        검색
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*?php include ('../include/_header_search.html') ?*/}

              <div className='a_d_banner'>
                <a href='#n'>
                  <div className='lt'>
                    <p>과감하리만치 독창적인 구조</p>
                    <em>AirPods Max</em>
                  </div>
                  <span className='img'>
                    <img src='https://www.dummyimage.com/34x34' alt='' />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className='serviceUtil'>
            <button type='button' className='btn-search' tip='검색'>
              검색
            </button>
            <button type='button' className='btn-broadcast' tip='방송하기'>
              방송하기
            </button>
            <div className='noticeArea'>
              <button type='button' className='btn-notice new' tip='알림'>
                <span>알림</span>
              </button>
              {/*{
                // 알림 레이어
                state.isFeedLayer && <FeedLayer />
              }*/}
              <div className='tooltip' style={{display: 'none'}}>
                <p>
                  이제 PC에서도 푸시 알림을
                  <br />
                  받을 수 있어요!
                  <br />
                  지금 알림 설정을 확인하세요!
                </p>
                <button type='button' className='close'>
                  닫기
                </button>
              </div>
            </div>
            <button type='button' className='btn-login'>
              로그인
            </button>
            <div className='settingWrap'>
              <button type='button' className='btn-settings' tip='설정'>
                설정
              </button>

              {/*{
                // 로그인 전 설정 레이어
                state.isModeSet && <ModeSet />
              }*/}
            </div>

            <div className='profileWrap'>
              <div className='userInfo new'>
                <div className='thumb'>
                  <button type='button'>
                    <img
                      src='//res.afreecatv.com/images/svg/thumb_profile.svg'
                      alt=''
                    />
                  </button>
                </div>
              </div>

              {/* {
                // 로그인 후 개인 레이어
                state.isProfile && (
                  <UserMenu
                    userMenuDepth2Click={userMenuDepth2Click}
                    isUserMenuDepth2={state.isUserMenuDepth2}
                  />
                )
              }*/}
            </div>
            <div className='serviceMenu'>
              <button type='button' className='btn-allMenu' tip='전체메뉴'>
                전체메뉴
              </button>

              {/*{
                // 전체 메뉴 레이어
                state.isAllMenu && <AllMenu />
              }*/}
            </div>
            {/* // 전체 메뉴 레이어 */}
          </div>
        </div>
      </header>
    </>
  );
}
