import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';
import {useCallback} from 'react';

/**
 * SearchDirectLinkGroup 컴포넌트 ( 바로가기 테마 )
 * @param {Object} profileTheme - profileTheme API의 리턴값
 * @returns SearchDirectLinkGroup 컴포넌트
 */
const SearchDirectLinkGroup = (profileTheme) => {
  const {themeShortcuts = []} = profileTheme || {};
  const {sendThemeClickLog} = useSearchClickLog();
  const {sessionKey} = useSearchGlobalStatus();

  // SCKACT 로그
  const handleLogCallback = useCallback(() => {
    if (sessionKey) {
      sendThemeClickLog({
        actCode: 'inf',
        sessionKey: sessionKey,
      });
    }
  }, [sessionKey]);

  if (themeShortcuts.length < 1) {
    return null;
  }

  return (
    <>
      {themeShortcuts.map((item, index) => (
        <div key={index} className='directLink_area'>
          <a
            href={`${item?.link?.url}`}
            className='link_box'
            target='_blank'
            rel='noopener noreferrer'
            onClick={handleLogCallback}>
            <span className='thumb'>바로가기</span>
            <p className='title'>{item?.link?.title}</p>
          </a>
          {item?.link?.buttons && item?.link?.buttons.length > 0 && (
            <ul className='info_box'>
              {item?.link?.buttons?.map((button, btnIndex) => (
                <li key={`${button.title}-${btnIndex}`}>
                  <a
                    href={`${button.url}`}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={handleLogCallback}>
                    {button.title}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </>
  );
};

export default SearchDirectLinkGroup;
