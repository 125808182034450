import {useEffect, useRef, useState} from 'react';

const useIsHover = (delay = 0) => {
  const [isHover, setIsHover] = useState(false);
  const timerRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHover(true);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setIsHover(false);
    }, delay);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return [isHover, handleMouseEnter, handleMouseLeave];
};

export default useIsHover;
