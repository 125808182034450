'use client';

import {SectionHeader} from '@/components/main/common';
import {MAIN_LIST_CNT} from '@/constants/main';
import {isLogin} from '@/utils/main';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import useMainStore from '@/stores/main';
import LiveBroadcastItem from '../LiveBroadcastItem';
import {MY_PLUS_LIVE_CONTEXT_MENU, LIVE_CONTEXT_MENU} from '@/constants/main';
export const LiveGroup = ({response, selectedMode}) => {
  const navigate = useNavigate();
  const [visibleListCount, setVisibleListCount] = useState(0);
  const [isClickMore, setIsClickMore] = useState(false);
  const [headerText, setHeaderText] = useState('인기 LIVE');
  const {resizeCount} = useMainStore();

  useEffect(() => {
    // 클라이언트에서만 실행
    if (selectedMode === 'prefer' && isLogin()) {
      setHeaderText('LIVE');
    }
  }, [selectedMode]);

  useEffect(() => {
    if (resizeCount > 0 && !isClickMore) {
      setVisibleListCount(resizeCount * 2);
    }
  }, [resizeCount]);

  const handleShowMore = () => {
    if (visibleListCount < MAIN_LIST_CNT) {
      const visibleCount = MAIN_LIST_CNT - visibleListCount;
      setVisibleListCount((prev) => prev + visibleCount);
    } else {
      const visibleCount = response.liveCnt - visibleListCount;
      setVisibleListCount((prev) => prev + visibleCount);
    }
    setIsClickMore(true);
  };

  return (
    <>
      <SectionHeader title={headerText} />
      {response?.liveList && response.liveList?.length > 0 ? (
        <div className={`cBox-list`}>
          <ul>
            {response?.liveList?.map(
              (broadcast, idx) =>
                idx < visibleListCount && (
                  <li data-type='cBox' key={broadcast.broadNo}>
                    <LiveBroadcastItem
                      thumbnailUrl={broadcast.broadThumb}
                      userId={broadcast.userId}
                      broadcastType='live'
                      userNickname={broadcast.userNick}
                      viewCount={broadcast.totalViewCnt}
                      liveType={broadcast.broadType}
                      title={broadcast.broadTitle}
                      categoryTags={broadcast.categoryTags}
                      autoHashTags={broadcast.autoHashtags}
                      hashTags={broadcast.hashTags}
                      canResend={Boolean(Number(broadcast.visitBroadType))}
                      allowsAdult={Number(broadcast.broadGrade)}
                      listDataType={broadcast.listDataType}
                      score={broadcast.score}
                      hasPassword={broadcast.isPassword === 'Y' ? 1 : 0}
                      hasDrops={broadcast.isDrops}
                      startDateTime={broadcast.broadStart}
                      broadcastId={broadcast.broadNo}
                      location='myplus'
                      contextMenu={{
                        menuList:
                          selectedMode === 'prefer' && isLogin()
                            ? MY_PLUS_LIVE_CONTEXT_MENU
                            : LIVE_CONTEXT_MENU,
                      }}
                      {...(selectedMode === 'prefer' && isLogin()
                        ? {
                            log: {
                              listDataType: broadcast.listDataType,
                            },
                          }
                        : {
                            log: {
                              data: {
                                path1: 'popular',
                                path2: 'list',
                                path3: 'live',
                              },
                            },
                          })}
                    />
                  </li>
                ),
            )}
          </ul>
        </div>
      ) : (
        <div className='nt_area'>
          <p>진행중인 라이브가 없습니다.</p>
        </div>
      )}
      <div
        className={`show_more ${selectedMode === 'popular' && visibleListCount >= response?.liveList?.length ? 'all' : ''}`}>
        {selectedMode === 'prefer' && isLogin() ? (
          response?.liveCnt > visibleListCount ? (
            <button type='button' onClick={handleShowMore}>
              더보기
            </button>
          ) : null
        ) : visibleListCount >= response?.liveList?.length ? (
          <button
            type='button'
            onClick={() => {
              navigate('/live/all');
            }}>
            전체보기
          </button>
        ) : (
          <button type='button' onClick={handleShowMore}>
            더보기
          </button>
        )}
      </div>
    </>
  );
};
