'use client';
import {format} from 'date-fns';
import {cookie} from '@afreecatv/domains';
import globalDomains from '@/utils/global-domains';
import {ROUTE} from '@/constants/main/route';
import dayjs from 'dayjs';
import _ from 'lodash';

/**
 * api호출 시, 캐시방지를 위한 함수
 * @returns {Number}  현재시간 타임스탬프값 리턴
 */
export const getTimeStamp = () => {
  const timestamp = new Date().getTime(); // 현재 타임스탬프
  return timestamp;
};

/**
 * 쿠키 key 로 찾아서 값을 반환한다
 * @param {string} cname - 쿠키 이름
 * @returns 쿠키 값
 */
export const getCookie = (cname) => {
  if (typeof document !== 'undefined') {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return '';
};

/**
 * 로그인 여부 반환하는 함수
 * @returns {Boolean}  로그인 여부 true/false
 */
export const isLogin = () => {
  return getCookie(cookie.TICKET_COOKIE_NAME) ? true : false;
};

/**
 * 시청자수 1,000,000 형으로 반환
 * @param {Number} number - 성인방송 여부
 * @param {Number} desc - 소수점
 * @returns {String} 변환된 숫자
 */
export const convertViewCnt = (number, desc = 0) => {
  try {
    const num = Number(number);
    const dec = desc; // 소수점 자리수
    const reg = dec ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(\d{3})+$)/g;

    return num.toFixed(dec).replace(reg, '$1,'); // 포맷팅된 문자열 반환
  } catch (e) {
    console.error('Error in convertViewCnt:', e);
    return number; // 에러 발생 시 원본 숫자 반환
  }
};

/**
 * 라이브 방송 썸네일 클래스 반환 함수
 * @param {Number} grade - 성인방송 여부
 * @param {String} password - 비번방 여부
 * @returns {String} 클래스
 */
export const getThumbNail = (grade, password) => {
  if (grade === 19 && password === 'Y') {
    return 'thumb-lock_adult';
  } else if (grade === 19 && password === 'N') {
    return 'thumb-adult';
  } else if (grade !== 19 && password === 'Y') {
    return 'thumb-lock';
  } else {
    return '';
  }
};

/**
 * 방송시간 반환 함수
 * @param {String} totalSeconds - 방송시간(초)
 */
export const convertTotalHours = (totalSeconds) => {
  const secondsPerHour = 3600;
  const totalHours = Number(totalSeconds) / secondsPerHour;
  return totalHours;
};

/**
 * 쿠키 set 함수
 * @param {String} name - 쿠키명
 * @param {String} value - 쿠키값
 * @param {String} domain - 도메인
 * @param {Number} expiredays - 만료일
 */
export const setCookie = (
  name,
  value,
  domain = `.${globalDomains.SOOPLIVE_ROOT_DOMAIN}`,
  expiredays = 1,
) => {
  if (typeof window !== 'undefined') {
    const today = new Date();
    const expiryDate = new Date(
      today.setDate(today.getDate() + parseInt(expiredays)),
    );

    document.cookie = `${name}=${encodeURIComponent(value)}; path=/; expires=${expiryDate.toGMTString()}; domain=${domain};`;
  }
};

/**
 * 주어진 날짜 문자열이 유효한지 확인
 * @param {string} date - 유효성을 검사할 날짜 문자열
 * @returns {boolean} - 날짜가 유효하면 true, 그렇지 않으면 false를 반환
 */
export const isValidDate = (date) => {
  const parsedDate = new Date(date);
  return !isNaN(parsedDate.getTime());
};

/**
 * 날짜 포맷 함수
 * @param {string} date - 날짜
 * @param {string} formatString - 포맷 문자열
 * @param {string} [defaultValue] - date 가 이상할 경우 노출될 기본값
 */
export const formatDate = (date, formatString, defaultValue = '') => {
  if (!date || !formatString || !isValidDate(date)) {
    return defaultValue;
  }

  return format(new Date(date), formatString);
};

/**
 * PdboxUser 쿠키값 가져오는 함수
 * @param {String} key 키값이름
 */
export const getUserCookie = (key) => {
  try {
    let pdBoxUser = decodeURIComponent(getCookie(cookie.USER_COOKIE_NAME));

    for (let val of pdBoxUser.split('&')) {
      if (val.indexOf(key) === 0) {
        return val.substring(key.length + 1);
      }
    }
    return '';
  } catch (e) {
    return '';
  }
};

/**
 * ContextMenu 필터링 리스트 가져오는 함수
 * @param {String[]} contextMenu 리스트메뉴
 * @param {String[]} name 추출할 label 명
 */
export const getFilteredContextMenu = (contextMenu, name = []) => {
  // 로그인 여부 체크해서 비로그인일때 name으로 들어오는 메뉴들만 return
  if (!isLogin()) {
    return contextMenu.filter((item) => name.includes(item.type));
  }
  return contextMenu;
};

/**
 * localStorage get
 * @param {String} key 키값
 * @param {String} defaultValue 디폴트 값
 */
export const getLocalStorage = (key, defaultValue) => {
  try {
    return window.localStorage.getItem(key) === null
      ? defaultValue
      : window.localStorage.getItem(key);
  } catch (e) {
    return defaultValue;
  }
};

/**
 * localStorage set
 * @param {String} key 키값
 * @param {String} value 저장하는 값
 */
export const setLocalStorage = (key = '', value = '') => {
  try {
    window.localStorage.setItem(key, value);
  } catch (e) {}
};

/**
 *  비로그인 시 로그인 페이지 이동 함수
 */
export const goLogin = () => {
  const currentUrl = window.encodeURIComponent(window.location.href);
  location.href = `${globalDomains.LOGIN_AFREECATV}/afreeca/login.php?szFrom=full&request_uri=${currentUrl}`;
};

/**
 * 주어진 시간을 시, 분, 초 형식으로 변환
 * @param {number|string} duration - 변환할 초 혹은 "HH:MM:SS", "H:MM:SS", "HH:MM", "MM:SS", "H:MM", "M:SS" 형식의 문자열
 * @returns {string} - "HH:MM:SS" 또는 "MM:SS" 형식으로 변환된 시간 문자열
 */
export function convertToDuration(duration) {
  if (typeof duration === 'string') {
    const parts = duration.split(':').map(Number);

    if (parts.length === 3) {
      // "HH:MM:SS" 또는 "H:MM:SS" 형식
      const [hours, minutes, seconds] = parts;
      if (seconds === 0) {
        return `${hours > 0 ? String(hours).padStart(2, '0') + ':' : ''}${String(minutes).padStart(2, '0')}`;
      }
      return `${hours > 0 ? String(hours).padStart(2, '0') + ':' : ''}${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    } else if (parts.length === 2) {
      // "HH:MM", "MM:SS", "H:MM", "M:SS" 형식
      const [first, second] = parts;

      if (
        duration.includes(':') &&
        duration.indexOf(':') === duration.lastIndexOf(':')
      ) {
        // "MM:SS" 또는 "M:SS" 형식
        return `${String(first).padStart(2, '0')}:${String(second).padStart(2, '0')}`;
      } else {
        // "HH:MM" 또는 "H:MM" 형식
        return `${String(first).padStart(2, '0')}:${String(second).padStart(2, '0')}`;
      }
    }
  }

  // 숫자 형식의 초를 시, 분, 초로 변환
  let totalSeconds = Number(duration);

  if (isNaN(totalSeconds)) {
    console.error(
      'duration 은 초 형태의 Number 나 "HH:MM:SS", "H:MM:SS", "HH:MM", "MM:SS", "H:MM", "M:SS" 형식의 String 이어야 함',
    );

    return '';
  }

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);

  let time = '';
  if (hours > 0) {
    time += String(hours).padStart(2, '0') + ':';
  }
  time += String(minutes).padStart(2, '0');
  //if (seconds > 0) { //해당 조건이 있으면 광고 플레이어 영상 시간 표현 시 0으로 나와서리.. 00으로 나와야 디자인이 맞다
  time += ':' + String(seconds).padStart(2, '0');

  return time;
}

/**
 * 줄바꿈을 <br> 태그로 변경
 * @param string str
 */
export const newLineToBreak = (str) => {
  return str && str.replace(new RegExp('\r?\n', 'g'), '<br />');
};

/**
 * 메인 배너 노출 횟수 체크 함수 (오전 12시, 오후 12시 주기로 리셋)
 */
export const showMainBanner = (viewLimit) => {
  if (viewLimit) {
    const VIEW_LIMIT = viewLimit;
    const now = dayjs();
    const hours = now.hour();
    const currentPeriod = hours < 12 ? 'AM' : 'PM';
    const lastPeriod = decodeURIComponent(
      getCookie('lastMainBannerViewPeriod') || '',
    );

    let lastMainBannerViewCount =
      parseInt(getCookie('lastMainBannerViewCount')) || 0;

    // 현재 기간과 마지막 본 기간이 다르면 초기화
    if (lastPeriod !== currentPeriod) {
      lastMainBannerViewCount = 0; // 쿠키 초기화 후 mainBannerView를 0으로 설정
      setCookie('lastMainBannerViewCount', lastMainBannerViewCount, '', 1);
      setCookie('lastMainBannerViewPeriod', currentPeriod, '', 1);
    }

    if (lastMainBannerViewCount <= VIEW_LIMIT) {
      lastMainBannerViewCount += 1;
      setCookie('lastMainBannerViewCount', lastMainBannerViewCount, '', 1);
    }
    return lastMainBannerViewCount <= VIEW_LIMIT;
  } else {
    return false;
  }
};

/**
 * route값에 따른 location 값을 반환함
 * @param {string} pathName - 경로
 * @returns {string}
 */
export const filteredLocation = (pathName) => {
  console.log('pathName ', pathName);
  switch (pathName) {
    case ROUTE.INDEX:
      return 'myplus_list';
    default:
      return pathName;
  }
};

/**
 * 주어진 객체를 재귀로 돌아서 값을 뽑아내어 배열로 만듦
 * @param {Object} obj - 값 뽑아낼 객체
 * @returns {(string | number)[]} 객체에서 뽑아낸 값들의 배열
 */
export const getValues = (obj) => {
  const values = [];

  const recursiveHelper = (o) => {
    for (const key in o) {
      if (typeof o[key] === 'object' && o[key] !== null) {
        recursiveHelper(o[key]);
      } else {
        values.push(o[key]);
      }
    }
  };

  recursiveHelper(obj);
  return values;
};

export const jsonToQuerystring = (data) => {
  const queryString = Object.entries(data)
    .map(([key, value]) => {
      return Array.isArray(value)
        ? key + '=' + JSON.stringify(value)
        : key + '=' + value; // 배열은 문자열로
    })
    .filter((v) => v)
    .join('&');
  return queryString;
};

/**
 * Broadcast 아이템의 키를 변환하는 함수
 * @param {Object} content - 키를 변환할 객체
 * @returns {Object} 변환된 키를 가진 객체 (convertedContent)
 */
export const convertContentForBroadcastItem = (content) => {
  // Broadcast 객체의 키 매핑 정의 (TO-BE: AS-IS)
  const keyMapping = {
    accessibleAge: ['grade'],
    allowsAdult: ['isAdult'],
    canResend: ['isVisitBroad'],
    categoryNo: ['category', 'broadCateNo'],
    broadcastId: ['vodNumber', 'broadcastId'],
    originalUserId: ['copyright.originalUserId'],
    originalUserNickname: ['copyright.userNick'],
    thumbnailUrl: ['thumbnailSrc', 'playlist.bbsTitle.ucc.thumb'],
    previewThumbnailUrl: ['webpPath'],
    profileImageUrl: ['adminProfileImg'],
    startDateTime: ['broadcastStartTime'],
    vodType: ['fileType'],
    createdDate: ['registerDate'],
    liveType: ['broadType'],
    updatedDate: ['laterRegDate'],
    playlistId: ['listIdx', 'playlistNumber', 'no', 'listNo'],
    hasDrops: ['isDrops'],
    hasBadge: ['isBadge'],
    hasPassword: ['isPassword'],
    isCinety: ['isOriginalContentaccessibleAge'],
    searchActionType: ['urlActtype'],

    // TODO: 플레이리스트 용도의 함수를 나누든가 해야 할듯
    // 아래는 플레이리스트에서 쓰는 프로퍼티들
    userNickname: ['playlist.regNick'],
    title: ['playlist.title'],
    registerDate: ['playlist.regDate'],
    playlistCount: ['playlist.count.playlistCnt'],
    isDisplay: ['playlist.config.display'],
    playlistLink: ['url', 'playlist.url'],
    thumbnailType: ['playlist.bbsTitle.ucc.thumbType', 'thumbType'], // LOCK / ADULT / ADULT_LOCK / SUCCEED / ''
    registerUserId: ['playlist.regId', 'userId'],
    addUserId: ['playlist.addId'],
    isFan: ['isFan', 'isFanclub'],
  };

  const converted = convertKeysWithMapping(keyMapping, content);

  // 빈 값일 때 덮어 씌우는 것을 방지하기 위해서 필터링을 거친다
  const filteredContent = Object.keys(content).reduce((acc, key) => {
    if (content[key] !== undefined) {
      acc[key] = content[key];
    }
    return acc;
  }, {});

  return {
    ...converted,
    // 겹치는 키의 경우 content의 원래 값을 덮어씌움
    ...filteredContent,
  };
};

/**
 * 키 매핑을 통해 객체의 키를 변환하는 함수
 * @param {Object} keyMapping - TO-BE와 AS-IS 키 매핑 객체
 * @param {Object} content - 키를 변환할 객체
 * @returns {Object} 변환된 키를 가진 객체 (convertedContent)
 */
const convertKeysWithMapping = (keyMapping, content) => {
  const convertedContent = {};

  Object.entries(keyMapping).forEach(([tobeKey, asisKeys]) => {
    for (const asisKey of asisKeys) {
      // 중첩된 객체의 키 처리
      if (asisKey.includes('.')) {
        const keys = asisKey.split('.');
        let value = content;

        // 중첩된 키를 순차적으로 탐색하여 값 찾기
        for (const key of keys) {
          value = value?.[key];
          if (value === undefined) {
            break;
          }
        }

        // 중첩된 키의 값이 존재하면 convertedContent에 추가
        if (value !== undefined) {
          convertedContent[tobeKey] = value;
          break;
        }
      } else {
        // 중첩되지 않은 키의 값이 존재하면 convertedContent에 추가
        if (content[asisKey] !== undefined) {
          convertedContent[tobeKey] = content[asisKey];
          break;
        }
      }
    }
  });

  return convertedContent;
};
