import {useCallback, useEffect, useRef} from 'react';

import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

import {decodeHtmlEntities} from '@/utils';

/**
 * SearchThemeGroup 컴포넌트 ( 테마 )
 * @param {object} props 컴포넌트의 props
 * @param {object[]} props.profileTheme 테마 데이터
 * @returns SearchThemeGroup 컴포넌트
 */
const SearchThemeGroup = (profileTheme) => {
  const {sendThemeClickLog} = useSearchClickLog();
  const {sessionKey} = useSearchGlobalStatus();
  const {theme = []} = profileTheme || [];
  const themeRef = useRef([]);

  // SCKACT로그
  const handleLogCallback = useCallback(() => {
    sendThemeClickLog({
      actCode: 'tt',
      sessionKey: sessionKey,
    });
  }, [sessionKey, sendThemeClickLog]);

  // 테마 영역은 API에서 받는 HTML로 화면을 그리고 있음
  // HTML안의 버튼관련 태그에 SCKACT로그 메소드를 주입시키기 위한 로직
  useEffect(() => {
    themeRef.current.forEach((element) => {
      if (element) {
        const clickableElements = element.querySelectorAll(
          'a, button, [onclick]',
        );
        clickableElements.forEach((el) => {
          const originalOnClick = el.onclick;

          if (el.tagName.toLowerCase() === 'a') {
            el.addEventListener('click', (event) => {
              handleLogCallback();
            });
          } else {
            el.onclick = (event) => {
              if (originalOnClick) {
                originalOnClick.call(el, event);
              }
              handleLogCallback();
            };
          }
        });
      }
    });
  }, [theme, handleLogCallback]);

  if (theme.length < 1) {
    return null;
  }

  return (
    <>
      {theme.map((item, index) => {
        // esport 관련 테마인지 아닌지 확인
        const isEsportsTheme = item?.html?.includes('esports_theme');
        // backgroundColor가 있는지 확인
        const backgroundColor = item.backgroundColor || '';

        return (
          <div
            key={index}
            style={{backgroundColor}}
            className='searchTheme_area'
            ref={(el) => (themeRef.current[index] = el)}>
            {isEsportsTheme ? (
              // esport 테마일 때
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeHtmlEntities(item.html || ''),
                  }}
                />
                <div className='theme_box'></div>
              </>
            ) : (
              // 일반 테마일 때
              <div className='theme_box'>
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeHtmlEntities(item.html || ''),
                  }}
                />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default SearchThemeGroup;
