/**
 * StreamerMedalOverlay 컴포넌트
 * @param {Object} props - StreamerMedalOverlay props
 * @param {Object} medalData - 메달 정보
 * @param {boolean} isShow - 메달 레이어 오픈 여부
 * @param {boolean} onClose - 메달 레이어 클로즈 메소드
 * @returns {JSX.Element} StreamerMedalOverlay 컴포넌트
 */

const StreamerMedalOverlay = ({
  medalData = [],
  isShow = false,
  onClose = () => {},
}) => {
  return (
    medalData.length > 0 && (
      <div className='medal_area'>
        <div className={`medal_layer ${isShow ? 'active' : ''}`}>
          <h3>메달정보</h3>
          <ul>
            {medalData.map((medal, index) => (
              <li key={medal?.content || index}>
                <span className='img_medal'>
                  <img src={medal?.basicUrl} alt={medal?.content} />
                </span>
                <div className='desc'>
                  <h4>{medal?.content}</h4>
                  <p>{medal?.description}</p>
                </div>
              </li>
            ))}
          </ul>
          <button type='button' className='btn_close' onClick={onClose}>
            닫기
          </button>
        </div>
      </div>
    )
  );
};

export default StreamerMedalOverlay;
