'use client';
import {ErrorBoundary} from '@/components/common';
import BroadcastProvider from '@/components/main/common/Broadcast/BroadcastProvider';

const BroadcastLayout = ({children, ...props}) => {
  return (
    <ErrorBoundary>
      <BroadcastProvider broadInfo={props}>{children}</BroadcastProvider>
    </ErrorBoundary>
  );
};

export default BroadcastLayout;
