'use client';
import {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

/**
 * FloatingPortal 컴포넌트
 * @param {JSX.Element} children - 랜더링시키고자하는 element
 * @param {String} className - 컴포넌트 class명
 * @param {String} target - append 하고자하는 class명
 * @returns {JSX.Element} FloatingPortal 컴포넌트
 */
const FloatingPortal = ({children, className = '', target = ''}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    try {
      const targetElement = document.getElementById(target);
      if (targetElement) {
        setIsMounted(true);
      }
    } catch (error) {
      setIsMounted(false);
    }
  }, []);

  if (!isMounted) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={className}>{children}</div>,
    document.getElementById(target),
  );
};
export default FloatingPortal;
