'use client';
import {useEffect, useState, useRef} from 'react';
import {FloatingPortal} from '@/components/main/common';
import {useOutsideClick} from '@/components/main/common/hooks/useOutsideClick';
import MyPlusApi from '@/api/main/my-plus';
import throwToast from '@/utils/ui/toast';
import {throwDialog} from '@/utils/ui/dialog';
import {isLogin} from '@/utils/main';
import {BROADCAST_TYPES} from '@/constants';
import throwSharePopup, {generateUrlForShare} from '@/utils/ui/share-popup';

const SoopUiContextmenuRework = ({
  broadcastType = '', // live, vod, catch, catchstory, playlist
  menuList,
  className,
  handleMenuClicked = (event, menuType, data = {}) => {},
  option = {
    fileType: '',
    playlistId: '', // 공유, 플레이리스트 퍼가기
    title: '',
    thumbnailUrl: '',
    userId: '',
    userNickname: '',
    broadcastId: '',
    appScheme: '',
    stationNo: '',
    score: 0,
    listDataType: '',
    playlistShareUrl: null,
    vodType: '',
  },
}) => {
  const [isDisplay, setDisplay] = useState(false);
  const [position, setPosition] = useState({top: 0, left: 0});
  //const [menuItems, setMenuItems] = useState(menuList);

  const buttonRef = useRef(null);

  // 공유하기에 사용될 공유 URL 및 카카오 파라미터를 생성합니다.
  const shareUrlInfo = generateUrlForShare(
    {
      type: broadcastType,
      title: option.title,
      thumbnailUrl: option.thumbnailUrl,
      userId: option.userId,
      broadcastId: option.broadcastId,
      appScheme: option.appScheme,
      stationNo: option.stationNo,
      playlistId: option.playlistId,
      vodType: option.vodType,
    },
    option.playlistShareUrl,
  );

  if (
    menuList
      .map((menu) => {
        return menu.type;
      })
      .includes('playlist-share') &&
    !option.playlistId
  ) {
    console.error(
      'playlistId가 없습니다. 그러면 플레이리스트 퍼가기를 할 수 없다구요. 혹시 playlistNumber, playlistIdx 같은 이름으로 전달하고 있는건 아닌지 체크해보셈',
    );
  }

  const handle3DotClicked = (event) => {
    event.preventDefault();
    setDisplay(!isDisplay);
  };

  useEffect(() => {
    const handleResize = () => {
      setDisplay(false);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 쓰리닷 버튼 클릭 → 닉네임 클릭 시에만 재현이 되는 이슈 수정..이게 맞나,,
  /*useEffect(() => {
    if (isDisplay && isShowProfileIconMenu) {
      setIsDisplay(false);
    }
  }, [isShowProfileIconMenu]);*/

  const handleContextMenuClicked = async (event, menuActionType) => {
    let data = {};
    switch (menuActionType) {
      case 'view-later': {
        //나중에 보기
        try {
          const response = await MyPlusApi.setLaterView(
            broadcastType === BROADCAST_TYPES.LIVE ? 'LIVE' : 'VOD',
            option.broadcastId,
          );
          if (response) {
            throwToast(response.message);
          }
        } catch (error) {
          const {message} = error.response.data;
          throwToast(message);
        }
        break;
      }
      case 'share': {
        throwSharePopup({
          title: option.title,
          pageUrl: shareUrlInfo.pageUrl,
          kakaoParams: shareUrlInfo.kakaoParams,
          parentPosition: buttonRef.current.getBoundingClientRect(),
        });
        break;
      }
      case 'add-playlist': {
        // 재생목록에 담기
        throwDialog(
          'common/PlayList',
          {
            broadcastId: option.broadcastId,
          },
          {
            title: '재생목록에 담기',
            customClassName: 'list_add_layer',
            titleAlign: 'left',
          },
        );
        break;
      }
      case 'playlist-share': {
        // 재생목록 퍼가기
        try {
          const response = await MyPlusApi.setSharedPlaylist(option.playlistId);
          if (response) {
            throwToast(response.msg);
          }
        } catch (e) {
          console.error(e);
        }
        break;
      }

      case 'hidden': {
        data = {
          userId: option.userId,
          userNickname: option.userNickname,
          broadcastId: option.broadcastId,
        };
        break;
      }
      case 'not-recommend': {
        data = {
          userId: option.userId,
          userNickname: option.userNickname,
          broadcastId: option.broadcastId,
          listDataType: option.listDataType,
          score: option.score,
        };
        break;
      }
    }

    // 기본 메뉴 네개 중 하나가 호출된 후 콜백 실행된다. 커스텀 액션인 경우 해당 콜백에서 menuActionType로 구분하여 처리할 것
    handleMenuClicked(event, menuActionType, data);
  };

  const checkButtonPosition = () => {
    if (buttonRef.current) {
      const MENU_WIDTH = 180;
      const MENU_HEIGHT = 14 + menuList.length * 36;
      const {top, left, bottom} = buttonRef.current.getBoundingClientRect();
      let newTop = top + window.scrollY;
      let newLeft = left + window.scrollX;

      // 메뉴가 우측에서 열릴 때 짤릴 케이스가 발생하면 좌측으로 조정
      if (newLeft + MENU_WIDTH > window.innerWidth) {
        newLeft = window.innerWidth - MENU_WIDTH - 35;
      }

      // 메뉴가 아래로 열릴 때 짤릴 케이스가 발생하면 위로 조정
      if (bottom + MENU_HEIGHT > window.innerHeight) {
        newTop = top + window.scrollY - MENU_HEIGHT;
      } else {
        newTop = bottom + window.scrollY;
      }

      setPosition({top: newTop, left: newLeft});
    }
  };

  useOutsideClick(buttonRef, () => {
    setDisplay(false);
  });

  useEffect(() => {
    if (isDisplay) {
      // props로 전달받은 menuList가 변경될 때마다 menuItems 상태를 업데이트(탭 이동 시 menuList의 내용이 변경되는 경우를 처리하기 위함)
      //setMenuItems(menuList);  ??
      checkButtonPosition();
      // getFilteredContextMenu();
    }
  }, [isDisplay]);

  return (
    <>
      <button
        ref={buttonRef}
        type='button'
        className={className || 'more_dot'}
        onClick={handle3DotClicked}>
        <span>더보기 메뉴</span>
      </button>
      {isDisplay ? (
        <FloatingPortal className={'_moreDot_wrapper'} target={'soop_wrap'}>
          <div
            className='_moreDot_layer'
            style={{
              display: 'block',
              top: position.top,
              left: position.left,
            }}>
            {menuList
              .filter((menu) => {
                // 로그인 여부에 따라 비로그인 시 공유하기만 노출
                if (isLogin()) {
                  return true;
                } else {
                  return menu.type === 'share';
                }
              })
              .map((menu, index) => {
                return (
                  <button
                    type='button'
                    key={index}
                    onClick={(event) => {
                      return handleContextMenuClicked(event, menu.type);
                    }}>
                    <span>{menu.label}</span>
                  </button>
                );
              })}
          </div>
        </FloatingPortal>
      ) : null}
    </>
  );
};

export default SoopUiContextmenuRework;
