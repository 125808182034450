'use client';

import MyProfileImg from '@/components/my/common/item/MyProfileImg';
import globalDomains from '@/utils/global-domains';
import {
  sendLegacyInflowPathLog,
  sendLiveThumbnailClickLog,
  sendMyAvatarClickLog,
} from '@/utils/main/my/log';
import {isEmpty} from 'lodash';

/**
 * MyAvatar 컴포넌트
 * @param {Object} props - MyAvatar props
 * @param {string} props.userNick - user nickname
 * @param {boolean} props.isFanclub - 팬클럽 여부
 * @param {boolean} props.isSubscribe - 구독 여부
 * @param {node} props.hoverComponent - 컴포넌트에 hover 시 나타날 컴포넌트
 * @param {string} props.userId - user id
 * @param {function} props.handleUserPin - user pin 핸들러
 * @param {number} props.favoriteNo - 즐겨찾기 번호
 * @param {boolean} props.isPin - 핀 여부
 * @returns {JSX.Element} MyAvatar 컴포넌트
 */
const MyAvatar = ({
  userNick,
  isFanclub,
  isSubscribe,
  hoverComponent,
  userId,
  handleUserPin,
  favoriteNo,
  subscribeNo,
  isPin,
  fanclubNo,
  isShowSubscribe = true,
  isShowFanclub = true,
  broadInfo,
  isLive,
}) => {
  const handleUserPinClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!handleUserPin) {
      return;
    }

    // WARN_API: 핀 등록을 그냥 user id 로 하면 될 일인데 굳이 favoriteNo, subscribeNo 를 넘겨서 발생한 지저분한 클라이언트 코드
    handleUserPin(favoriteNo || subscribeNo || fanclubNo, isPin);
  };

  const link =
    broadInfo?.length > 0
      ? broadInfo[0].url
      : `${globalDomains.BJ_AFREECATV}/${userId}`;

  const handleProfileClick = () => {
    sendMyAvatarClickLog(isLive);

    if (isLive && !isEmpty(broadInfo?.[0])) {
      const broadcast = broadInfo[0];

      const {
        broadCateNo,
        categoryTags,
        hashTags,
        broadNo,
        userId,
        broadBps,
        broadResolution,
      } = broadcast;

      // 라이브 방송일 때 프로필 클릭 로그
      sendLiveThumbnailClickLog({
        streamerId: userId,
        broadcastId: broadNo,
        broadCateNo,
        broadBps,
        broadResolution,
      });

      // 기존에 사용하던 라이브 방송 유입 로그
      sendLegacyInflowPathLog({
        broadcastId: broadNo,
        streamerId: userId,
        isLogSendData: {
          path1: 'my',
          path2: 'top_list',
          path3: 'live',
          contentsType: 'live',
          broadCateNo: broadNo,
          categoryTag: categoryTags,
          tag: hashTags,
        },
      });
    }
  };

  return (
    <a href={link} target='_blank' onClick={handleProfileClick}>
      {/* 유저 이미지 */}
      <div className='thumb'>
        <MyProfileImg userId={userId} />
        <span className='community'>{hoverComponent}</span>
      </div>

      {/* 유저 닉네임 */}
      <div className='nick'>
        <span>{userNick}</span>

        {/* 팬클럽 여부 */}
        {isShowFanclub && isFanclub && (
          <span className='grade-badge-fan' tip='팬클럽'>
            F
          </span>
        )}

        {/* 팬클럽 구독 */}
        {isShowSubscribe && isSubscribe && (
          <span className='grade-badge-subscribe' tip='구독'>
            구독
          </span>
        )}
      </div>

      {/* 유저 핀 */}
      <button
        type='button'
        className='pin'
        onClick={handleUserPinClicked}
        tip={isPin ? '고정 해제하기' : '고정하기'}>
        고정
      </button>
    </a>
  );
};

export default MyAvatar;
