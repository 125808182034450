import DeApi from '../deapi';

const BannerApi = {
  async getBanner(params) {
    try {
      const response = await DeApi.get(`/api/v1/recommend?${params}`);
      return response;
    } catch (error) {}
  },
};
export default BannerApi;
