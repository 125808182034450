import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

const store = (set) => ({
  shareLayer: {
    isShow: false,
    type: '',
    data: {},
    top: 0,
    left: 0,
  },

  setShareLayer: (item) => {
    set((state) => {
      return {
        shareLayer: {
          isShow: item.isShow,
          type: item.type,
          data: item.data,
          top: item.top,
          left: item.left,
        },
      };
    });
  },
});

const useShareStore = create(devtools(store));

export default useShareStore;
