import BasePlayer from '@/components/player/live/BasePlayer';
import {useEffect, useRef} from 'react';
import {PLAYER_TYPE} from '@/constants/player/live';
import useMainStore from '@/stores/main';
import {useLivePlayer} from '@/components/player/common/hooks/useLivePlayer';
import {useIntersection} from '@/components/common/hooks/useIntersection';

export const PreviewAdPlayer = ({
  adInfo,
  autoPlay = true,
  loop = true,
  muted: mutedExternal = true,
  playing: playingExternal = false,
  thumbnail,
  visible,
  onAutoPlayBlock = (event) => {},
  onCanPlay = (event) => {},
}) => {
  const {checkAd} = useMainStore();
  const playerType = PLAYER_TYPE.PREVIEW_AD_PLAYER;
  const previewAdPlayer = useRef(null);

  const {intersectionRef, isIntersecting} = useIntersection({threshold: 0.1});

  const {
    videoRef,
    isPlayerLoaded,
    isCanPlay,
    setMuted,
    setPlaying,
    showPoster,
  } = useLivePlayer({
    player: previewAdPlayer,
    playerType,
    autoPlay,
    loop,
    muted: mutedExternal,
    volume: 0.8,
    onAutoPlayBlock,
    onCanPlay,
    shouldPlayWhenVisible: true,
  });

  useEffect(() => {
    // isPlayerLoaded 가 false 일때도 보내고있어서 true 일떄만 보내도록 수정
    if (previewAdPlayer.current && isPlayerLoaded) {
      console.log('adInfo', adInfo);
      if (adInfo) {
        previewAdPlayer.current.loadVastAd({...adInfo});
        //영상이 노출되었을떄 전송하는 트래킹
        if (checkAd.AdParameter.hasOwnProperty('sendTracking')) {
          console.log(
            'previewAdPlayer subImpressionUrls ',
            adInfo.creatives[0]?.impressionUrls,
          );
          console.log(
            'previewAdPlayer subViewableUrls ',
            adInfo.creatives[0]?.viewableUrls,
          );
          checkAd.AdParameter.sendTracking(adInfo.creatives[0]?.impressionUrls);
          checkAd.AdParameter.sendTracking(adInfo.creatives[0]?.viewableUrls);
        }
      }
    }
  }, [isPlayerLoaded]);

  useEffect(() => {
    setMuted(mutedExternal);
  }, [mutedExternal]);

  useEffect(() => {
    setPlaying(playingExternal);
  }, [playingExternal]);

  useEffect(() => {
    console.warn(isIntersecting);
    if (isCanPlay.current) {
      setPlaying(visible && isIntersecting);
    }
  }, [visible && isIntersecting]);

  return (
    <BasePlayer
      ref={intersectionRef}
      videoRef={videoRef}
      playerType={playerType}
      autoPlay={autoPlay}
      muted={true}
      thumbnail={thumbnail}
      showPoster={showPoster}></BasePlayer>
  );
};
