import {BroadcastLayout, BroadcastType} from '../common/Broadcast';
import {BROADCAST_ITEM_DISPLAY_TYPE} from '@/constants';
import withHiddenStreamer from './withHiddenStreamer';
import ErrorBoundary from '../directory/category/ErrorBoundary';

const VodBroadcastItem = ({
  thumbnailUrl,
  title,
  hasBadge,
  categoryTags,
  hashTags,
  autoHashTags,
  broadcastId,
  duration,
  vodType,
  createdDate,
  categoryName,
  accessibleAge,
  contextMenu,
  handleMenuClicked,
  previewThumbnailUrl,
  userId,
  userNickname,
  originalUserId,
  score,
  allowsAdult,
  originalUserNickname,
  viewCount,
  listDataType,
  location,
  broadcastType,
}) => {
  return (
    <ErrorBoundary fallback={<div>로딩중</div>}>
      <BroadcastLayout
        broadcastType={broadcastType}
        viewType={BROADCAST_ITEM_DISPLAY_TYPE.GRID_VIEW}
        thumbnailUrl={thumbnailUrl}
        previewThumbnailUrl={previewThumbnailUrl}
        userId={userId}
        userNickname={userNickname}
        originalUserId={originalUserId}
        originalUserNickname={originalUserNickname}
        viewCount={viewCount}
        title={title}
        hasBadge={hasBadge}
        categoryTags={categoryTags}
        hashTags={hashTags}
        autoHashTags={autoHashTags}
        broadcastId={broadcastId}
        duration={duration}
        vodType={vodType}
        createdDate={createdDate}
        categoryName={categoryName}
        allowsAdult={Number(allowsAdult)}
        accessibleAge={accessibleAge}
        searchActionType='vod'
        contextMenu={contextMenu}
        listDataType={listDataType}
        score={score}
        location={location}
        handleMenuClicked={handleMenuClicked}>
        <BroadcastType.Vod />
      </BroadcastLayout>
    </ErrorBoundary>
  );
};

export default withHiddenStreamer(VodBroadcastItem);
