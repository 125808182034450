'use client';

import {SectionHeader} from '@/components/main/common';
import {SoopUiSelectBox} from '@/components/ui';
import {useEffect, useState} from 'react';
import MyHistoryApi from '@/api/my/history';
import MyNoList from '@/components/my/common/item/NoList';
import {throwConfirm} from '@/utils/ui/dialog';
import throwToast from '@/utils/ui/toast';
import MyBroadcastLayout from '@/components/my/common/MyBroadcastLayout';
import {ErrorBoundary} from '@/components/common';
import {BROADCAST_TYPES} from '@/constants';
import {CONTEXT_MENU} from '@/constants/my/contextMenu';
import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';

/**
 * VOD 정렬 옵션
 */
const VOD_OPTIONS = Object.freeze([
  {label: '최근 추가', type: 'reg_date'},
  {label: '조회수', type: 'read_cnt'},
  {label: 'UP수', type: 'like_cnt'},
]);

const MyHistoryRecentVod = () => {
  const [order, setOrder] = useState(VOD_OPTIONS[0].type);
  const [broadcasts, setBroadcasts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreVod, setHasMoreVod] = useState(false);

  // 방송 목록 로드
  useEffect(() => {
    const fetchBroadcasts = async () => {
      const {vod, hasMore} = await MyHistoryApi.getRecentVod({
        page,
        orderByColumn: order,
      });

      setHasMoreVod(hasMore);

      if (page === 1) {
        // 1페이지라면 갈아끼우기
        setBroadcasts(vod);
      } else {
        // 1페이지보다 크다면 추가하기
        setBroadcasts((prevVod) => {
          return [...prevVod, ...vod];
        });
      }
    };
    fetchBroadcasts();
  }, [order, page]);

  /**
   * 정렬 순서 변경 핸들러
   * @param {string} type - 정렬 기준 타입
   */
  const handleOrderChange = (type) => {
    setOrder(type);
    setPage(1);
  };

  const handlePageChange = () => {
    setPage((prevPage) => prevPage + 1);
  };

  /**
   * 최근 본 VOD 를 모두 삭제
   * @returns {Promise<void>}
   */
  const handleAllDelete = async () => {
    const {data: isOk} = await throwConfirm({
      subTitle: '최근 본 VOD를 모두 삭제하시겠습니까?',
      message: '삭제된 VOD 리스트는 복구할 수 없습니다.',
    });

    if (!isOk) {
      return;
    }

    try {
      await MyHistoryApi.deleteAllRecentVod();
      setBroadcasts([]);
      throwToast('최근 본 VOD 목록이 모두 삭제됐습니다.');
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * 최근 본 VOD 방송 삭제
   */
  const handleContextMenuClick = (broadcastId) => async (event, type) => {
    if (type !== 'delete-vod') {
      return;
    }

    try {
      await MyHistoryApi.deleteRecentVod(broadcastId);

      throwToast('최근 본 VOD 목록에서 삭제됐습니다.');

      // 현재 페이지에서 VOD 삭제만 시켜달라고 하여 필터링 후 상태 업데이트
      const filteredBroadcast = broadcasts.filter((broadcast) => {
        return broadcast.titleNo !== broadcastId;
      });
      setBroadcasts(filteredBroadcast);
    } catch (e) {
      throwToast('에러가 발생하였습니다.');
    }
  };

  const getContextMenu = (fileType) => {
    return fileType === 'CATCH'
      ? CONTEXT_MENU.RECENT.CATCH
      : CONTEXT_MENU.RECENT.VOD;
  };

  return (
    <>
      <SectionHeader
        title='VOD'
        rightElement={
          <>
            <SoopUiSelectBox
              options={VOD_OPTIONS}
              type={order}
              onChange={handleOrderChange}
            />
            <button type='button' className='delete' onClick={handleAllDelete}>
              전체 삭제
            </button>
          </>
        }
      />
      {broadcasts?.length > 0 ? (
        <>
          <MyBroadcastLayout type='grid'>
            {broadcasts.map((broadcast) => (
              <ErrorBoundary key={broadcast.titleNo}>
                <BroadcastLayout
                  broadcastType={BROADCAST_TYPES.VOD}
                  thumbnailUrl={broadcast.thumb}
                  viewCount={broadcast.readCnt}
                  createdDate={broadcast.regDate}
                  startDateTime={broadcast.broadStart}
                  userNickname={broadcast.userNick}
                  title={broadcast.titleName}
                  broadcastId={broadcast.titleNo}
                  userId={broadcast.userId}
                  hasBadge={
                    broadcast.categoryTags ||
                    broadcast.hashTags ||
                    broadcast.autoHashtags
                  }
                  categoryTags={broadcast.categoryTags}
                  hashTags={broadcast.hashTags}
                  autoHashTags={broadcast.autoHashtags}
                  vodType={broadcast.fileType}
                  categoryNo={Number(broadcast.category)}
                  accessibleAge={Number(broadcast.grade)}
                  allowsAdult={broadcast.isAdult}
                  isFan={broadcast.isFanclub}
                  isSubscribe={broadcast.isSubscribe}
                  duration={broadcast.duration}
                  contextMenu={{
                    menuList: getContextMenu(broadcast.fileType),
                  }}
                  authNumber={broadcast.authNo}
                  previewThumbnailUrl={broadcast.webpPath}
                  handleMenuClicked={handleContextMenuClick(broadcast.titleNo)}>
                  <BroadcastType.Vod />
                </BroadcastLayout>
              </ErrorBoundary>
            ))}
          </MyBroadcastLayout>
          {hasMoreVod && (
            <div className='show_more'>
              <button type='button' onClick={handlePageChange}>
                더보기
              </button>
            </div>
          )}
        </>
      ) : (
        <MyNoList title='최근 본 VOD가 없습니다.' />
      )}
    </>
  );
};

export default MyHistoryRecentVod;
