'use client';
import {useEffect, useState, useRef} from 'react';
import MainLiveApi from '@/api/main/main-live';
import {getTimeStamp, getUserCookie, isLogin} from '@/utils/main';
import {LIVE_INIT_LIST, MAIN_LIST_CNT} from '@/constants/main';
import {LiveGroupList} from '@/components/main/live/LiveGroupList';
import {LiveMenu} from '@/components/main/live/LiveMenu';
import {LiveGroupCategoryList} from '@/components/main/live/group/LiveGroupCategoryList';
import {LiveSportsSchedule} from '@/components/main/live/group/sports/LiveSportsSchedule';
import {LiveGroupBothBanner} from '@/components/main/live/group/LiveGroupBothBanner';
import {useNavigate} from 'react-router-dom';
import {Banner} from '@/components/common';
import {useHiddenStreamer} from '../../common/hooks/useHiddenStreamer';

export const LiveGroup = ({liveType}) => {
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('view_cnt');
  const [selectedMenu, setSelectedMenu] = useState(liveType);
  const [selectedCategory, setSelectedCategory] = useState({
    active: '',
    categoryNo: '',
  });
  const [hiddenStreamer, setHiddenStreamer] = useState([]);
  const [mainListInfo, setMainListInfo] = useState({});
  const [broadList, setBroadList] = useState(LIVE_INIT_LIST);
  const {
    remainCount,
    slicedBroadList,
    setRemainCount,
    filterBroadList,
    setSlicedBroadList,
    handleOnClickHiddenStreamer,
  } = useHiddenStreamer('LIVE', selectedMenu);
  const navigate = useNavigate();

  const categoryList = useRef([]);

  useEffect(() => {
    getStaticBroadListMainInfo();
  }, []);

  useEffect(() => {
    getCategoryJsonData();
  }, [selectedMenu]);

  useEffect(() => {
    if (remainCount > 0) {
      setPage((prev) => prev + 1);
    }
  }, [remainCount]);

  useEffect(() => {
    if (broadList.isMore && selectedCategory.active !== '') {
      if (isLogin()) {
        getHiddenStreamerList();
      } else {
        getBroadList(selectedCategory.categoryNo);
      }
    } else {
      if (slicedBroadList.length > 0) {
        setBroadList({
          ...broadList,
          list: [...broadList.list, ...slicedBroadList],
        });
        setSlicedBroadList([]);
      } else {
        if (
          page === 1 &&
          remainCount === 0 &&
          selectedCategory.categoryNo.length > 0
        ) {
          if (isLogin()) {
            getHiddenStreamerList();
          } else {
            getBroadList(selectedCategory.categoryNo);
          }
        }
      }
    }
  }, [page, order, selectedCategory, selectedMenu]);

  useEffect(() => {
    if (hiddenStreamer.length > 0) {
      getBroadList(selectedCategory.categoryNo);
    }
  }, [hiddenStreamer]);

  const handleOnClickMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleOnClickLiveMenu = (menuId) => {
    if (selectedCategory.active === menuId) {
      return;
    }
    if (menuId === 'all') {
      navigate(`/live/${menuId}`);
    } else {
      navigate(`/live/group/${menuId}`);
    }
    setPage(1);
    setOrder('view_cnt');
    setRemainCount(0);
    setBroadList(LIVE_INIT_LIST);
    setSelectedMenu(menuId);
    if (selectedMenu === menuId) {
      setSelectedCategory({
        active: selectedMenu,
        categoryNo: categoryList.current
          .map((category) => category.broadCateNo)
          .join(','),
      });
    } else {
      setSelectedCategory({
        active: '',
        categoryNo: '',
      });
    }
    setSlicedBroadList([]);
  };

  //게임,보이는 라디오,스포츠 상에서 카테고리 클릭 이벤트
  const handleOnClickPosterCategory = (e, broadCateNo) => {
    e.preventDefault();
    if (broadCateNo === selectedCategory.categoryNo) return;
    setPage(1);
    setOrder('view_cnt');
    setRemainCount(0);
    setBroadList(LIVE_INIT_LIST);
    setSlicedBroadList([]);
    setSelectedCategory({
      active: 'poster',
      categoryNo: broadCateNo,
    });
  };

  const handleOnClickSort = (type) => {
    if (type === order) {
      return;
    }
    setPage(1);
    setOrder(type);
    setRemainCount(0);
    setBroadList(LIVE_INIT_LIST);
    setSlicedBroadList([]);
  };

  const getBroadList = async (selectValue) => {
    try {
      let queryParams = {
        selectType: 'cate',
        selectValue: selectValue,
        orderType: order,
        pageNo: page,
        lang: 'ko_KR',
        _: getTimeStamp(),
      };
      const selectedCategoryItem = categoryList.current.find(
        (category) => category.broadCateNo === selectValue,
      );
      if (selectedCategoryItem) {
        queryParams.szActionType = selectedCategoryItem?.isAdult ? 3 : 2;
      }
      const response = await MainLiveApi.getBroadList(queryParams);
      if (response && response.broad) {
        const {totalCnt, broad} = response;
        if (Number(totalCnt) > 0 && broad.length > 0) {
          const totalPage = Math.ceil(Number(totalCnt) / MAIN_LIST_CNT);
          const isMore = Number(page) < Number(totalPage) ? true : false;
          if (hiddenStreamer.length > 0) {
            filterBroadList(
              'live',
              page,
              broad,
              isMore,
              hiddenStreamer,
              setBroadList,
            );
          } else {
            setBroadList({
              isMore: isMore,
              list: [...broadList.list, ...broad],
            });
          }
        }
      }
    } catch (error) {
      setBroadList(LIVE_INIT_LIST);
    }
  };

  const getHiddenStreamerList = async () => {
    try {
      const response = await MainLiveApi.getHiddenStreamerList();
      if (response && response.result === 1) {
        if (response.data.length === 0) {
          getBroadList(selectedCategory.categoryNo);
        } else {
          setHiddenStreamer([...response.data]);
        }
      }
    } catch (error) {
      getBroadList(selectedCategory.categoryNo);
    }
  };

  const getStaticBroadListMainInfo = async () => {
    try {
      const response = await MainLiveApi.getStaticBroadListMainInfo();
      if (response) {
        setMainListInfo(response);
      }
    } catch (error) {
      setMainListInfo({});
    }
  };

  const getCategoryJsonData = async () => {
    try {
      const response = await MainLiveApi.getJsonData(
        `/main/main_${liveType}category_viewer.js`,
      );
      if (response) {
        const formattedCategoryList = response.data
          .filter((item) => {
            if (!isLogin()) {
              return !item.isAdult;
            } else {
              const userAge = getUserCookie('age');
              if (userAge >= 19) {
                return item;
              } else {
                return !item.isAdult;
              }
            }
          })
          .map((item) => {
            if (item.broadCateNo) {
              item.broadCateNo = item.broadCateNo
                .padStart(8, '0')
                .replace(/\s+/g, '');
            }
            return item;
          });
        categoryList.current = formattedCategoryList;
        setSelectedCategory({
          active: liveType,
          categoryNo: formattedCategoryList
            .map((category) => category.broadCateNo)
            .join(','),
        });
      }
    } catch (error) {
      setSelectedCategory({
        active: '',
        categoryNo: '',
      });
      categoryList.current = [];
    }
  };

  const liveMenuProps = {
    selectedMenu,
    handleOnClickLiveMenu,
  };

  const liveCategoryListProps = {
    categoryList: categoryList.current,
    selectedCategory,
    handleOnClickPosterCategory,
  };

  const liveGroupBothBannerProps = {
    selectedMenu,
    mainListInfo,
    selectedCategory,
  };

  const liveSportsScheduleProps = {
    mainListInfo,
  };

  const liveListProps = {
    order,
    broadList,
    selectedMenu,
    slicedBroadList,
    location: selectedMenu,
    handleOnClickMore,
    handleOnClickSort,
    handleOnClickHiddenStreamer,
  };

  return (
    <>
      <LiveMenu {...liveMenuProps} />
      <div id='container' className='_live'>
        <LiveGroupCategoryList {...liveCategoryListProps} />
        {selectedMenu === 'sports' && (
          <LiveSportsSchedule {...liveSportsScheduleProps} />
        )}
        <Banner type={'sub'} category={selectedMenu} />
        <LiveGroupBothBanner {...liveGroupBothBannerProps} />
        <LiveGroupList {...liveListProps} />
      </div>
    </>
  );
};
