import {getTimeStamp} from '@/utils/main';
import {useHiddenStreamer} from '@/components/main/common/hooks/useHiddenStreamer';
import {HiddenStreamer} from '@/components/main/common';
import {useState} from 'react';
import MyPlusApi from '@/api/main/my-plus';

const withHiddenStreamer = (Component) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const [nonRecommendStreamerList, setNonRecommendStreamerList] = useState(
      [],
    );

    const {
      hiddenStreamerList,
      handleOnClickHiddenStreamer,
      handleOnClickUndoHiddenStreamer,
    } = useHiddenStreamer(props.broadcastType.toUpperCase(), props.location);

    const isHiddenStreamer =
      hiddenStreamerList?.includes(props.broadcastId) ||
      nonRecommendStreamerList?.includes(props.broadcastId);

    const setNoRecommendStreamer = async (data) => {
      try {
        const {broadcastId, userNickname, userId, listDataType} = data;
        const params = {
          bj_id: userId,
          bj_nick: userNickname,
          type: 'LIVE',
          list_data_type: listDataType,
          broad_no: broadcastId,
          os: 'pc',
          namespace: getTimeStamp(),
          key: getTimeStamp(),
        };
        const response = await MyPlusApi.setNoRecommendStreamer(params);
        if (response && response.result === 1) {
          setNonRecommendStreamerList((prevState) => [
            ...prevState,
            broadcastId,
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const setNoRecommendUndoStreamer = async (data) => {
      try {
        const {broadcastId, score, userId, listDataType} = data;
        const params = {
          bj_id: userId,
          type: 'LIVE',
          list_data_type: listDataType,
          broad_no: broadcastId,
          score: score,
        };
        const response = await MyPlusApi.setNoRecommendUndoStreamer(params);
        if (response && response.result === 1) {
          setNonRecommendStreamerList((prevState) =>
            prevState.filter((id) => id !== broadcastId),
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleContextMenuClick = (e, type, data) => {
      if (type === 'not-recommend') {
        setNoRecommendStreamer(data);
      } else if (type === 'hidden') {
        handleOnClickHiddenStreamer(data);
      }
    };

    const handleUndoBlockedUser = (data, type) => {
      if (type === 'hidden' && hiddenStreamerList.includes(data.broadcastId)) {
        handleOnClickUndoHiddenStreamer(data);
      } else if (
        type === 'not-recommend' &&
        nonRecommendStreamerList.includes(data.broadcastId)
      ) {
        setNoRecommendUndoStreamer(data);
      }
    };

    const handleHiddenStreamerUndoBlock = () => {
      const type = hiddenStreamerList?.includes(props.broadcastId)
        ? 'hidden'
        : 'not-recommend';
      handleUndoBlockedUser(
        {
          userId: props.userId,
          score: props.score,
          userNickname: props.userNickname,
          broadcastId: props.broadcastId,
          listDataType: props.listDataType,
        },
        type,
      );
    };
    return isHiddenStreamer ? (
      <HiddenStreamer
        type={
          hiddenStreamerList?.includes(props.broadcastId)
            ? 'hidden'
            : 'not-recommend'
        }
        userNickname={props.userNickname}
        handleUndoBroadcast={handleHiddenStreamerUndoBlock}
      />
    ) : (
      <Component {...props} handleMenuClicked={handleContextMenuClick} />
    );
  };
};
export default withHiddenStreamer;
