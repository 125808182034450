import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import LogApi from '@/api/common/log';

export const useSearchClickLog = () => {
  const {getParams} = useUrlParameter();
  const {
    szKeyword: keyword = '',
    szSearchType: searchType = '',
    szStype = '',
    szOriginalKeyword = '',
    szModifiedKeyword = '',
    szActype = '',
    tagname = '',
    stype = '',
    has_hint,
  } = getParams();

  const DEFAULT_PARAMS = {
    keyword,
    tagname,
    szStype,
    szOriginalKeyword,
    acType: szStype === 'ac' ? szActype : '',
    original: szStype === 'mk' ? szOriginalKeyword : '',
    modified: szStype === 'mk' ? szModifiedKeyword : '',
    hasHint: has_hint,
    stype,
  };

  // 스트리머프로필 영역
  const sendStreamerProfileClickLog = (params) => {
    let sectionParams = {
      tab: 'total',
      acttype: 'bjp',
      location: 'total_search',
    };
    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };

    LogApi.searchClickLog(logParams);
  };

  // LIVE 썸네일 클릭
  const sendSckactLiveClickLog = (params, section) => {
    let sectionParams = {
      tab: searchType === 'total' ? 'total' : 'live',
      acttype: 'live',
      location: 'total_search',
    };

    if (section === 'latestVod') {
      sectionParams = {
        ...sectionParams,
        tab: 'total',
        acttype: 'bjp',
        sectionName: 'latest_contents',
        location: 'total_search',
      };
    }

    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };

    LogApi.searchClickLog(logParams);
  };

  // VOD 클릭했을 때
  const sendSckactVodClickLog = (params, section) => {
    let sectionParams = {
      tab: searchType === 'total' ? 'total' : 'vod',
      acttype: 'vod',
      location: 'total_search',
    };

    // const {actCode} = params || {};
    // sectionParams['tab'] = actCode === 'hash' ? 'total' : sectionParams['tab'];

    if (section === 'latestVod') {
      sectionParams = {
        ...sectionParams,
        tab: 'total',
        acttype: 'bjp',
        sectionName: 'latest_contents',
        location: 'total_search',
      };
    }

    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };
    LogApi.searchClickLog(logParams);
  };

  // 게임프로필
  const sendSckactGameProfileClickLog = (params) => {
    const sectionParams = {
      tab: 'total',
      acttype: 'ctsp',
      location: 'total_search',
    };
    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };
    LogApi.searchClickLog(logParams);
  };

  // 동명이인
  const sendSameNameGroupClickLog = (params) => {
    const sectionParams = {
      tab: 'total',
      acttype: 'homonym',
      location: 'total_search',
    };
    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };
    LogApi.searchClickLog(logParams);
  };

  // 관련콘텐츠 영역의 스트리머
  const sendRecommendStreamerClickLog = (params) => {
    const sectionParams = {
      tab: 'total',
      acttype: 'recommend',
      location: 'total_search',
    };
    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };
    LogApi.searchClickLog(logParams);
  };

  // 테마 바로가기
  const sendThemeClickLog = (params) => {
    const sectionParams = {
      tab: 'total',
      acttype: 'tsk',
      location: 'total_search',
    };
    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };
    LogApi.searchClickLog(logParams);
  };

  // 스트리머 섹션
  const sendStreamerSectionClickLog = (params) => {
    const sectionParams = {
      tab: searchType === 'total' ? 'total' : 'bj',
      acttype: 'bj',
      location: 'total_search',
    };
    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };
    LogApi.searchClickLog(logParams);
  };

  // 연관검색어
  const sendRelatedSearchTermsSectionClickLog = (params) => {
    const sectionParams = {
      tab: 'total',
      acttype: 'rt',
      actCode: 'rt',
      location: 'total_search',
    };
    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };
    LogApi.searchClickLog(logParams);
  };

  // 탭 클릭
  const sendTabClickLog = (params) => {
    const sectionParams = {
      tab: 'total',
      acttype: 'tab',
      actCode: 'tab',
      location: 'total_search',
    };
    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };
    LogApi.searchClickLog(logParams);
  };

  // 캐치 클릭
  const sendCatchClickLog = (params) => {
    const sectionParams = {
      tab: 'total',
      acttype: 'vod',
      sectionName: 'catch_vod',
      fileType: 'catch',
      contentsType: 'vod',
      location: 'total_search',
    };

    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };

    LogApi.searchClickLog(logParams);
  };

  // 게시글 클릭
  const sendPostClickLog = (params) => {
    const sectionParams = {
      tab: searchType === 'total' ? 'total' : 'post',
      acttype: 'post',
      contentsType: 'post',
      location: 'total_search',
    };

    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };
    LogApi.searchClickLog(logParams);
  };

  // 검색어 제안
  const sendSuggestKeywordClickLog = (params) => {
    const sectionParams = {
      tab: 'total',
      acttype: 'mk',
      actCode: 'mk',
    };

    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };

    LogApi.searchClickLog(logParams);
  };

  // 광고 클릭
  const sendAdClickLog = (params) => {
    const sectionParams = {
      tab: 'total',
      acttype: 'ad',
      actCode: 'bn',
    };

    const logParams = {
      ...DEFAULT_PARAMS,
      ...sectionParams,
      ...params,
    };

    LogApi.searchClickLog(logParams);
  };

  return {
    sendStreamerProfileClickLog,
    sendSckactLiveClickLog,
    sendSckactVodClickLog,
    sendSckactGameProfileClickLog,
    sendSameNameGroupClickLog,
    sendRecommendStreamerClickLog,
    sendThemeClickLog,
    sendStreamerSectionClickLog,
    sendRelatedSearchTermsSectionClickLog,
    sendTabClickLog,
    sendCatchClickLog,
    sendPostClickLog,
    sendSuggestKeywordClickLog,
    sendAdClickLog,
  };
};
