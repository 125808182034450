'use client';

import {useEffect, useState} from 'react';
import MyHistoryApi from '@/api/my/history';
import MyNoList from '@/components/my/common/item/NoList';
import {SectionHeader} from '@/components/main/common';
import {SoopUiSelectBox} from '@/components/ui';
import {sortBroadcasts} from '@/stores/my';
import globalDomains from '@/utils/global-domains';
import {throwConfirm} from '@/utils/ui/dialog';
import throwToast from '@/utils/ui/toast';
import MyBroadcastLayout from '@/components/my/common/MyBroadcastLayout';
import {ErrorBoundary} from '@/components/common';
import {CONTEXT_MENU} from '@/constants/my/contextMenu';
import {BROADCAST_TYPES} from '@/constants';
import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';

const OPTIONS = Object.freeze([
  {label: '최근 참여', type: 'recent_watch'},
  {label: '참여자수(높은순)', type: 'total_viewers_desc'},
  {label: '참여자수(낮은순)', type: 'total_viewers_asc'},
  {label: '최근 시작', type: 'broad_start'},
]);

/**
 * 최근 본 방송 목록 컴포넌트
 * @returns {JSX.Element} MyHistoryRecent
 */
const MyHistoryRecent = () => {
  const [broadcasts, setBroadcasts] = useState([]);
  const [order, setOrder] = useState(OPTIONS[0].type);

  /**
   * 정렬 키 및 순서를 계산하는 함수
   * @returns {{sortKey: string, sortOrder: 'asc' | 'desc'}}
   */
  const getSortOptions = () => {
    let sortKey = 'broadStart'; // 기본값 설정
    if (order.includes('total_viewers')) {
      sortKey = 'totalViewCnt';
    } else if (order.includes('recent_watch')) {
      sortKey = 'orderNo';
    }

    const sortOrder = order.endsWith('asc') ? 'asc' : 'desc';

    return {sortKey, sortOrder};
  };

  /**
   * 최근 방송 목록 조회
   * @returns {Promise<void>}
   */
  const getRecentLiveBroadcasts = async () => {
    try {
      const liveBroadcasts = await MyHistoryApi.getRecentLiveBroadcasts();
      const {sortKey, sortOrder} = getSortOptions();
      setBroadcasts(sortBroadcasts(liveBroadcasts, {sortKey, sortOrder}));
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * 정렬 변경 핸들러
   * @param {string} type - 선택된 정렬 타입
   */
  const handleOrderChange = (type) => {
    setOrder(type);
  };

  /**
   * LIVE 전체 삭제 처리
   */
  const handleAllDelete = async () => {
    const {data: isOk} = await throwConfirm({
      subTitle: '최근 본 스트리머의 LIVE를 모두 삭제하시겠습니까?',
      message: '삭제된 LIVE 리스트는 복구할 수 없습니다.',
    });

    if (!isOk) {
      return;
    }

    try {
      await MyHistoryApi.deleteAllRecentLive();
      setBroadcasts([]);
      throwToast('최근 본 스트리머의 LIVE 목록이 모두 삭제됐습니다.');
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (broadcasts.length) {
      const {sortKey, sortOrder} = getSortOptions();
      setBroadcasts((broadcasts) =>
        sortBroadcasts(broadcasts, {sortKey, sortOrder}),
      );
    }
  }, [order]);

  /**
   * LIVE 방송 리스트 화면으로 이동
   */
  const handleLiveShow = () => {
    window.open(`${globalDomains.WWW_AFREECATV}/live/all`, '_blank');
  };

  /**
   * 나중에보기 눌렀던 VOD 방송 삭제
   */
  const handleContextMenuClick = (broadcastUserId) => async (event, type) => {
    if (type !== 'delete-live') {
      return;
    }

    try {
      await MyHistoryApi.deleteRecentLive(broadcastUserId);
      await getRecentLiveBroadcasts();
      throwToast('최근 본 스트리머의 LIVE 목록에서 삭제됐습니다.');
    } catch (e) {
      throwToast('에러가 발생하였습니다.');
    }
  };

  useEffect(() => {
    getRecentLiveBroadcasts();
  }, []);

  return (
    <>
      <SectionHeader
        title='LIVE'
        rightElement={
          <>
            <SoopUiSelectBox
              options={OPTIONS}
              type={order}
              onChange={handleOrderChange}
            />
            <button type='button' className='delete' onClick={handleAllDelete}>
              전체 삭제
            </button>
          </>
        }
      />
      {broadcasts.length > 0 ? (
        <MyBroadcastLayout type='grid'>
          {broadcasts.map((broadcast) => (
            <ErrorBoundary key={broadcast.broadNo}>
              <BroadcastLayout
                broadcastType={BROADCAST_TYPES.LIVE}
                thumbnailUrl={broadcast.broadImg}
                viewCount={broadcast.totalViewCnt}
                startDateTime={broadcast.broadStart}
                watchOrder={broadcast.orderNo}
                userNickname={broadcast.userNick}
                title={broadcast.broadTitle}
                broadcastId={broadcast.broadNo}
                userId={broadcast.userId}
                hasBadge={
                  broadcast.categoryTags ||
                  broadcast.hashTags ||
                  broadcast.autoHashtags
                }
                categoryTags={broadcast.categoryTags}
                hashTags={broadcast.hashTags}
                autoHashTags={broadcast.autoHashtags}
                liveType={broadcast.broadType}
                canResend={broadcast.isVisitBroad}
                categoryNo={Number(broadcast.broadCateNo)}
                accessibleAge={Number(broadcast.grade)}
                allowsAdult={broadcast.isAdult}
                hasPassword={broadcast.isPassword}
                isFan={broadcast.isFanclub}
                isSubscribe={broadcast.isSubscribe}
                contextMenu={{
                  menuList: CONTEXT_MENU.RECENT.LIVE,
                }}
                handleMenuClicked={handleContextMenuClick(broadcast.userId)}
                broadBps={broadcast.broadBps}
                broadResolution={broadcast.broadResolution}>
                <BroadcastType.Live />
              </BroadcastLayout>
            </ErrorBoundary>
          ))}
        </MyBroadcastLayout>
      ) : (
        <MyNoList
          title='아직 참여한 방송이 없습니다.'
          description={
            <>
              <span>지금 바로, SOOP의 재미있고 다양한 방송을 만나보세요!</span>
              <button
                type='button'
                className='btn_default'
                onClick={handleLiveShow}>
                LIVE 보러가기
              </button>
            </>
          }
        />
      )}
    </>
  );
};

export default MyHistoryRecent;
