import {useEffect, useState} from 'react';
import classNames from 'classnames';
import globalDomains from '@/utils/global-domains';
import useGlobalUIStore from '@/stores';
import {sendLegacyInflowPathLog} from '@/utils/main/my/log';

// 경기 상태 관련 렌더링
const renderMatchStatus = (
  vsStatus,
  fileType,
  titleNo,
  bbsNo,
  stationNo,
  broadNo,
  matchIndex,
  vsBjId,
  vodUserId,
  logCallback,
) => {
  switch (vsStatus) {
    case '1': // 경기중 || LIVE
      return (
        <a
          href={`${globalDomains.PLAY_AFREECATV}/afchall/${broadNo}`}
          target='_blank'
          className='live'
          onClick={() => {
            logCallback(
              {
                type: 'live',
                contentsType: 'live',
                contentsValue: broadNo,
                bno: broadNo,
                bjId: vsBjId,
              },
              matchIndex,
            );
            sendLegacyInflowPathLog({
              broadcastId: broadNo,
              streamerId: vsBjId,
              type: 'live',
              isLogSendData: {
                path1: 'sch',
                path2: 'total',
                path3: 'ctsp_esport',
                cli_list_data_idx: matchIndex + 1,
              },
            });
          }}>
          LIVE
        </a>
      );
    case '5': // 다시보기
    case '8': // 경기종료
      return titleNo || bbsNo || stationNo ? (
        <a
          href={`${globalDomains.VOD_AFREECATV}/player/${titleNo || bbsNo || stationNo}`}
          target='_blank'
          className='replay'
          onClick={() => {
            logCallback(
              {
                type: 'vod',
                fileType: fileType,
                contentsType: 'vod',
                contentsValue: titleNo || bbsNo || stationNo,
                vno: titleNo || bbsNo || stationNo,
                bjId: vsBjId,
              },
              matchIndex,
            );

            // 다시 보기가 노출 됐을때, (vod number가 있을떄만 보내기)
            titleNo &&
              sendLegacyInflowPathLog({
                broadcastId: titleNo,
                streamerId: vsBjId,
                type: 'vod',
                isLogSendData: {
                  path1: 'sch',
                  path2: 'sch',
                  path3: 'ctsp',
                  uploader: vodUserId,
                  cli_list_data_idx: matchIndex + 1,
                },
              });
          }}>
          다시보기
        </a>
      ) : (
        <span className='end'>경기종료</span>
      );
    case '0': // 경기예정
      return <span className='expected'>경기예정</span>;
    case '4': // 경기취소
      return <span className='cancel'>경기취소</span>;
    case '3': // 중계없음
      return <span className='end'>중계없음</span>;
    case '2': // 경기종료
      return <span className='end'>경기종료</span>;
    default:
      return null;
  }
};

/**
 * RenderMatchInfo 컴포넌트 ( 개별 경기 정보 )
 * @param {Object} game 경기 데이터
 * @param {Number} matchIndex 경기 리스트 내 개별 경기의 index
 * @param {Function} logCallback 로그 관련 콜백 함수
 * @returns RenderMatchInfo 컴포넌트
 */
const RenderMatchInfo = ({game, matchIndex, logCallback = () => {}}) => {
  const {
    vsMatchTime,
    seasonDesc,
    seasonDescEng,
    fileType,
    titleNo,
    bbsNo,
    stationNo,
    vsAwayScore: awayScore,
    vsHomeScore: homeScore,
    vsAwayTeamCodeInfo: awayTeamCodeInfo,
    vsHomeTeamCodeInfo: homeTeamCodeInfo,
    vsStatus,
    broadNo,
    vsBjId,
    vodUserId,
  } = game ?? {};

  const {isDarkMode} = useGlobalUIStore();

  const [awayDefaultImg, setAwayDefaultImg] = useState('');
  const [homeDefaultImg, setHomeDefaultImg] = useState('');
  const [awayTeamImageError, setAwayTeamImageError] = useState(false);
  const [homeTeamImageError, setHomeTeamImageError] = useState(false);
  const [awayImageVisible, setAwayImageVisible] = useState(true);
  const [homeImageVisible, setHomeImageVisible] = useState(true);

  const awayScoreClasses = classNames('scroe', {
    win: awayScore > homeScore,
  });
  const homeScoreClasses = classNames('scroe', {
    win: homeScore > awayScore,
  });
  const matchInfoClassName =
    `match_info ${!awayTeamCodeInfo || !homeTeamCodeInfo ? 'single' : ''}`.trim();

  const handleAwayTeamImageRenderFailed = () => {
    setAwayImageVisible(false);
    setAwayDefaultImg('');
    setAwayTeamImageError(true);
  };

  const handleHomeTeamImageRenderFailed = () => {
    setHomeImageVisible(false);
    setHomeDefaultImg('');
    setHomeTeamImageError(true);
  };

  const handleAwayTeamImageLoad = () => {
    setAwayImageVisible(true);
    setAwayTeamImageError(false);
  };

  const handleHomeTeamImageLoad = () => {
    setHomeImageVisible(true);
    setHomeTeamImageError(false);
  };

  useEffect(() => {
    if (awayTeamCodeInfo?.imageDefaultUrl) {
      const awayTeamImg = isDarkMode
        ? awayTeamCodeInfo.imageDarkUrl
        : awayTeamCodeInfo.imageDefaultUrl;
      setAwayDefaultImg(awayTeamImg);
      if (awayTeamImg) {
        setAwayImageVisible(true);
      } else {
        setAwayImageVisible(false);
      }
    } else {
      setAwayDefaultImg('');
    }
    if (homeTeamCodeInfo?.imageDefaultUrl) {
      const homeTeamImg = isDarkMode
        ? homeTeamCodeInfo.imageDarkUrl
        : homeTeamCodeInfo.imageDefaultUrl;
      setHomeDefaultImg(homeTeamImg);
      if (homeTeamImg) {
        setHomeImageVisible(true);
      } else {
        setHomeImageVisible(false);
      }
    } else {
      setHomeDefaultImg('');
    }
    // 이미지 에러 상태 초기화
    setAwayTeamImageError(false);
    setHomeTeamImageError(false);
  }, [awayTeamCodeInfo, homeTeamCodeInfo, isDarkMode, game]);

  if (
    game == null ||
    typeof game !== 'object' ||
    Object.keys(game).length === 0
  ) {
    return null;
  }

  return (
    <div className='game_box'>
      <div className='match_info_wrap'>
        <div className='info_box'>
          <div className='tit'>
            <strong className='time'>{vsMatchTime}</strong>
            <p>
              {navigator.language.startsWith('ko') ? seasonDesc : seasonDescEng}
            </p>
          </div>
          <div className='state_box'>
            {renderMatchStatus(
              vsStatus,
              fileType,
              titleNo,
              bbsNo,
              stationNo,
              broadNo,
              matchIndex,
              vsBjId,
              vodUserId,
              logCallback,
            )}
          </div>
        </div>
        <div className={matchInfoClassName}>
          {awayTeamCodeInfo && (
            <div className='team'>
              <span
                className={`logo ${awayDefaultImg === '' || awayTeamImageError ? 'thumb-default' : ''}`}>
                <img
                  src={awayDefaultImg}
                  alt='awayDefaultImg'
                  onError={handleAwayTeamImageRenderFailed}
                  onLoad={handleAwayTeamImageLoad}
                  style={{display: awayImageVisible ? 'inline' : 'none'}}
                />
              </span>
              <p className='name'>{awayTeamCodeInfo?.name}</p>
              {/* 경기예정 || 경기 취소인 경우 노출 안함 */}
              {awayTeamCodeInfo &&
                homeTeamCodeInfo &&
                vsStatus !== '0' &&
                vsStatus !== '4' && (
                  <span className={awayScoreClasses}>{awayScore}</span>
                )}
            </div>
          )}
          {homeTeamCodeInfo && (
            <div className='team'>
              <span
                className={`logo ${homeDefaultImg === '' || homeTeamImageError ? 'thumb-default' : ''}`}>
                <img
                  src={homeDefaultImg}
                  alt='homeDefaultImg'
                  onError={handleHomeTeamImageRenderFailed}
                  onLoad={handleHomeTeamImageLoad}
                  style={{display: homeImageVisible ? 'inline' : 'none'}}
                />
              </span>
              <p className='name'>{homeTeamCodeInfo?.name}</p>
              {/* 경기예정 || 경기 취소인 경우 노출 안함 */}
              {awayTeamCodeInfo &&
                homeTeamCodeInfo &&
                vsStatus !== '0' &&
                vsStatus !== '4' && (
                  <span className={homeScoreClasses}>{homeScore}</span>
                )}
            </div>
          )}
          {/* 다시보기 || 경기종료인 경우에만 결과보기 버튼 노출 */}
          {awayTeamCodeInfo &&
          homeTeamCodeInfo &&
          (vsStatus === '2' || vsStatus === '8' || vsStatus === '5') ? (
            <button
              type='button'
              className='cover on'
              onClick={(e) => {
                e.target.classList.remove('on');
                logCallback({type: 'lg_result'}, matchIndex);
              }}>
              결과
              <br />
              보기
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RenderMatchInfo;
