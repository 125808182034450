export const STATE_NAME_MAP = {
  // 축구
  S_FHA: '전반',
  S_SHA: '후반',
  S_OTST: '연장대기',
  S_TFE: '연장종료',
  S_PSO: '승부차기',
  // 야구
  S_1T: '1회초',
  S_1B: '1회말',
  S_2T: '2회초',
  S_2B: '2회말',
  S_3T: '3회초',
  S_3B: '3회말',
  S_4T: '4회초',
  S_4B: '4회말',
  S_5T: '5회초',
  S_5B: '5회말',
  S_6T: '6회초',
  S_6B: '6회말',
  S_7T: '7회초',
  S_7B: '7회말',
  S_8T: '8회초',
  S_8B: '8회말',
  S_9T: '9회초',
  S_9B: '9회말',
  S_10T: '10회초',
  S_10B: '10회말',
  S_11T: '11회초',
  S_11B: '11회말',
  S_12T: '12회초',
  S_12B: '12회말',
  S_13T: '13회초',
  S_13B: '13회말',
  S_14T: '14회초',
  S_14B: '14회말',
  S_15T: '15회초',
  S_15B: '15회말',
  S_16T: '16회초',
  S_16B: '16회말',
  S_17T: '17회초',
  S_17B: '17회말',
  S_18T: '18회초',
  S_18B: '18회말',
  S_19T: '19회초',
  S_19B: '19회말',
  S_20T: '20회초',
  S_20B: '20회말',
  // 농구
  S_1Q: '1쿼터',
  S_2Q: '2쿼터',
  S_3Q: '3쿼터',
  S_4Q: '4쿼터',
  // 배구
  S_1S: '1세트',
  S_2S: '2세트',
  S_3S: '3세트',
  S_4S: '4세트',
  S_5S: '5세트',
  // 공통
  S_HAT: '하프타임',
  S_OT: '연장',
  S_2OT: '연장2',
  S_3OT: '연장3',
  S_GF: '경기종료',
};

export const STATUS_NAME_MAP = {
  1: 'LIVE',
  2: '하이라이트',
  4: '경기취소',
  5: '경기종료',
  7: '방송종료',
};
