'use client';

import {useEffect, useState} from 'react';

import {
  StreamerQnA,
  StreamerInfoBox,
  StreamerNickBox,
  StreamerMedalOverlay,
} from '@/components/search/group/streamer-profile-group';
import {ErrorBoundary} from '@/components/common';
import {
  FavoriteButton,
  ShowMore,
  StreamerAvatar,
} from '@/components/search/common';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

import useFavoriteStore from '@/stores/search';
import {PROFILE_FIELDS} from '@/constants/search/streamerProfile';

/**
 * StreamerProfileGroup 컴포넌트 (스트리머 프로필 )
 * @param {object} props 컴포넌트의 props
 * @param {object[]} profile 스트리머 프로필 데이터
 * @returns StreamerProfileGroup 컴포넌트
 */
const StreamerProfileGroup = ({profile}) => {
  const {toggleFavorite, setFavoriteStreamerName} = useFavoriteStore();
  const {currentLoginUserId, sessionKey} = useSearchGlobalStatus();
  const {sendStreamerProfileClickLog} = useSearchClickLog();

  const [profileList, setProfileList] = useState([]);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [showMedalLayer, setShowMedalLayer] = useState(false);
  const [buttonText, setButtonText] = useState('프로필 더보기');

  // sckact로그
  const handleLogCallback = (type, userId, titleNo) => {
    sendStreamerProfileClickLog({
      actCode: type,
      bjid: userId,
      contentsType: type === 'sti' ? 'bj' : type === 'nt' ? 'post' : '',
      contentsValue: type === 'sti' ? userId : type === 'nt' ? titleNo : '',
      tno: type === 'nt' ? userId : '',
      writer: type === 'nt' ? userId : '',
      sessionKey: sessionKey,
    });
  };

  // 프로필 더보기 클릭
  const handleToggleProfile = (userId) => {
    setIsProfileOpen(!isProfileOpen);
    setButtonText(isProfileOpen ? '프로필 더보기' : '프로필 닫기');
    handleLogCallback('more', userId);
  };

  // 메달 부분 '+' 버튼 클릭
  const handleShowMedalLayer = (userId) => {
    setShowMedalLayer(!showMedalLayer);
    handleLogCallback('prz', userId);
  };

  /**
   * 프로필 ICT 메뉴의 이벤트가 발생했을 때 트리거
   * @param {EventData} event
   */
  const handleProfileIconMenuEvent = async (event) => {
    const {eventName, data} = event;

    if (eventName === 'deleteFavorite') {
      toggleFavorite();
      setFavoriteStreamerName(data.id);
    }
  };

  // PROFILE_FIELDS에서 각 필드의 key 값에 해당하는 데이터가 API로 받아온 responseData 객체에 존재하고,
  // 해당 값이 비어 있지 않은 경우의 개수를 계산
  const countNonEmptyKeys = (profileFields, responseData) => {
    return profileFields.reduce((count, field) => {
      if (
        responseData.hasOwnProperty(field.key) &&
        responseData[field.key] &&
        responseData[field.key].length !== 0
      ) {
        count++;
      }
      return count;
    }, 0);
  };

  useEffect(() => {
    setProfileList(profile);
  }, [profile]);

  return (
    <>
      {profileList.map((list, index) => {
        //  화면에 보여질 스트리머 프로필 관련 정보가 몇 개인지 확인
        const nonEmptyKeyCount = countNonEmptyKeys(PROFILE_FIELDS, list);
        // 스트리머 메달 리스트 필터링
        const filteredMedalList = Array.isArray(list.medal)
          ? list.medal.filter((medal) =>
              ['best_streamer', 'award', 'partner'].some((keyword) =>
                medal?.simpleUrl?.includes(keyword),
              ),
            )
          : [];

        return (
          <div
            key={index}
            className={`strm_profile ${isProfileOpen ? 'expand' : ''}`}>
            <div className='profile_box'>
              {/* 스트리머 프로필 이미지 */}
              <ErrorBoundary>
                <StreamerAvatar
                  userId={list.userId}
                  className='thumb'
                  logCallback={() => {
                    handleLogCallback('sti', list.userId);
                  }}
                />
              </ErrorBoundary>

              <div className='info_wrap'>
                {/* 스트리머 프로필 닉네임 */}
                <ErrorBoundary>
                  <StreamerNickBox
                    isFanclub={list.fanFlag}
                    isSubscribe={list.subsFlag}
                    userNick={list.userNick}
                    userId={list.userId}
                    currentLoginUserId={currentLoginUserId}
                    fanCount={list.fanCount}
                    onEvent={handleProfileIconMenuEvent}
                    logCallback={() => {
                      handleLogCallback('nick', list.userId);
                    }}
                  />
                </ErrorBoundary>

                {/* 스트리머 프로필 정보 */}
                <ErrorBoundary>
                  <StreamerInfoBox
                    profileData={{...list, medal: filteredMedalList}}
                    onMedalClick={handleShowMedalLayer}
                    logCallback={() => {
                      handleLogCallback('nt', list.userId, list.titleNo);
                    }}
                  />
                </ErrorBoundary>

                {/* 스트리머 프로필 10문 10답 */}
                {list.qna.length > 0 && (
                  <ErrorBoundary>
                    <StreamerQnA
                      quaData={list.qna}
                      logCallback={() => {
                        handleLogCallback('qna', list.userId);
                      }}
                    />
                  </ErrorBoundary>
                )}
              </div>

              {/* 즐겨찾기 버튼 */}
              {currentLoginUserId !== list.userId && (
                <FavoriteButton
                  userId={list.userId}
                  favorite={!!list.favoriteFlag}
                  logCallback={() => {
                    handleLogCallback('fav', list.userId);
                  }}
                />
              )}
            </div>

            {/* 스트리머 프로필 영역 메달 정보 */}
            <StreamerMedalOverlay
              medalData={filteredMedalList}
              isShow={showMedalLayer}
              onClose={() => {
                handleShowMedalLayer(list.userId);
              }}
            />

            {nonEmptyKeyCount > 3 ? (
              <div className={`show_more ${isProfileOpen ? 'expand' : ''}`}>
                <button
                  type='button'
                  onClick={() => {
                    handleToggleProfile(list.userId);
                  }}>
                  {buttonText}
                </button>
              </div>
            ) : (
              <ShowMore active={false} />
            )}
          </div>
        );
      })}
    </>
  );
};
export default StreamerProfileGroup;
