'use client';

import 'swiper/css';
import 'swiper/css/autoplay';
import {Swiper, SwiperSlide} from 'swiper/react';
import {
  Autoplay,
  Navigation,
  Controller,
  FreeMode,
  Thumbs,
  Pagination,
  EffectFade,
} from 'swiper/modules';
import React, {
  forwardRef,
  useRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

/**
 * 리사이즈 시 슬라이드 갯수를 조정하기 위한 설정
 * maxSlidesPerView 에 따라 max width 와 slidePerView 설정
 */
const SLIDE_PER_VIEW_CONFIG = Object.freeze({
  1: [{max: 1000, slidePerView: 1}],
  2: [
    {max: 1000, slidePerView: 2},
    {max: 999, slidePerView: 1},
  ],
  3: [
    {max: 926, slidePerView: 3},
    {max: 453, slidePerView: 2},
    {max: 0, slidePerView: 1},
  ],
  4: [
    {max: 1623, slidePerView: 4},
    {max: 1000, slidePerView: 3},
    {max: 638, slidePerView: 2},
    {max: 0, slidePerView: 1},
  ],
  6: [
    {max: 1623, slidePerView: 6},
    {max: 1202, slidePerView: 5},
    {max: 1000, slidePerView: 4},
    {max: 751, slidePerView: 3},
    {max: 496, slidePerView: 2},
    {max: 0, slidePerView: 1},
  ],
  8: [
    {max: 1037, slidePerView: 8},
    {max: 926, slidePerView: 7},
    {max: 638, slidePerView: 5},
    {max: 546, slidePerView: 4},
    {max: 0, slidePerView: 3},
  ],
  10: [
    {max: 1623, slidePerView: 10},
    {max: 1202, slidePerView: 8},
    {max: 1000, slidePerView: 6},
    {max: 751, slidePerView: 5},
    {max: 496, slidePerView: 4},
    {max: 0, slidePerView: 3},
  ],
  15: [
    {max: 1623, slidePerView: 15},
    {max: 1550, slidePerView: 13},
    {max: 1120, slidePerView: 11},
    {max: 867, slidePerView: 8},
    {max: 640, slidePerView: 6},
    {max: 496, slidePerView: 5},
    {max: 0, slidePerView: 4},
  ],
});

/*
 * Swiper 객체를 래핑한 공통 컴포넌트입니다.
 * @desc - 각 페이지, 컴포넌트 레벨에서 Swiper를 직접 호출하지 않도록 wrap
 * @param props - props
 * @param props.swiperTag - swiper 최상위 엘리먼트의 tag 값. default div 이며 필요시 ul 같은 것으로 변경맨
 * @param props.swiperClass - swiper 최상위 엘리먼트의 class 명
 * @param props.hasControls - 슬라이드 버튼 표시 여부 (default true)
 * @param props.options - swiper option
 * @param props.handleSlideChanged - 슬라이드 변경 시 호출되는 핸들러
 * @param props.handleAllSlideEnded - 모든 슬라이드가 한 사이클 호출 되었을때 호출되는 핸들러 (로 추정 ^-^)
 * @param props.resetSlideOnUpdate - 스와이퍼가 리셋돼서 0번째 인덱스로 이동해야하는 경우 사용
 * * */

// 리사이즈 시 너비에 따른 미리 정의된 슬라이드 갯수를 리턴
const getSlidesPerViewCountWhenResized = (
  width,
  maxSlidePerView,
  otherMaxSlidesResolution,
) => {
  const config =
    otherMaxSlidesResolution.length > 0
      ? otherMaxSlidesResolution
      : SLIDE_PER_VIEW_CONFIG[maxSlidePerView];
  if (!config) {
    console.error(`설정된 maxSlidePerView ${maxSlidePerView} 가 없습니다.`);
    return 1;
  }

  const slidePerView = config.find((item) => width >= item.max)?.slidePerView;
  return slidePerView || 1;
};

const SoopUiSwiper = forwardRef(function SoopUiSwiper(
  {
    children,
    swiperTag = 'div',
    swiperClass,
    maxSlidesPerView = 1,
    hasControls = true,
    options,
    handleSlideChanged,
    handleAllSlideEnded,
    isSameSlidesViewGroup = false,
    otherMaxSlidesResolution = [],
    onSwiper = null,
    includesControlsIntoSwiper = false, // 콘트롤이 swiper 안에 포함되는가? boolean 명명법으로 변경 (inTagControls -> includesControlsIntoSwiper)
    resetSlideOnUpdate = false, // 새로운 prop 추가
    resetDirection = false, //vertical, horizontal 에따른  activeIndex유지설정
    activeIndex = 0,
    handleCustomMovingButtonClicked, // 버튼이 눌렸을때 슬라이드 이동 '직전' 실행할 동작 정의
  },
  ref,
) {
  const CONTAINER_ID = '#container';
  const [slidesPerView, setSlidesPerView] = useState(maxSlidesPerView);
  const [spaceBetween, setSpaceBetween] = useState(options.spaceBetween || 18);
  const [controller, setController] = useState(null);
  const [movingAction, setMovingAction] = useState(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const swiperOption = {
    slidesPerView: slidesPerView,
    spaceBetween: spaceBetween,
    loop: true,
    slidesPerGroup: isSameSlidesViewGroup ? slidesPerView : 1,
    ...options,
  };

  if (!handleCustomMovingButtonClicked && !options.navigation) {
    swiperOption.navigation = {
      prevEl: navigationPrevRef.current,
      nextEl: navigationNextRef.current,
    };
  }

  useImperativeHandle(ref, () => {
    return {
      next: () => {
        controller?.slideNext();
      },
      prev: () => {
        controller?.slidePrev();
      },
    };
  }, [controller]);

  useEffect(() => {
    // handleCustomMovingButtonClicked내에 정의된 렌더링이 완료 되면 동작
    // 렌더링 전에 slideNext()가 동작하는 문제로
    // useEffect, setTimeout0 조합을 사용하였다. vue의 nextTick()같은 기능을 원한다.
    if (movingAction) {
      setTimeout(() => {
        if (movingAction === 'prev') {
          controller?.slidePrev();
        } else if (movingAction === 'next') {
          controller?.slideNext();
        }
      }, 0);

      // 작업이 완료되었으면 상태 초기화
      setMovingAction(null);
    }
  }, [movingAction]);
  const handleSlideMovingButtonClick = async (event) => {
    const method = event.target.getAttribute('data-method');

    if (handleCustomMovingButtonClicked) {
      event.preventDefault();
      event.stopPropagation();
      await handleCustomMovingButtonClicked(method);

      setMovingAction(method);
    }
  };

  const handleWindowSizeChanged = (entries) => {
    for (let entry of entries) {
      setSlidesPerView(
        getSlidesPerViewCountWhenResized(
          entry.contentRect.width,
          maxSlidesPerView,
          otherMaxSlidesResolution,
        ),
      );
    }
  };

  useEffect(() => {
    //catch 특정 케이스에 대해서만 spaceBetween 별도 처리
    if (maxSlidesPerView === 10) {
      if (slidesPerView <= 4) {
        setSpaceBetween(14);
      } else if (slidesPerView === 5) {
        setSpaceBetween(20);
      }
    }
  }, [slidesPerView]);

  useEffect(() => {
    // 확인 후 spaceBetween을 Swiper에 적용
    if (controller) {
      controller.params.spaceBetween = spaceBetween;
      controller.update();
    }
  }, [spaceBetween]);

  useEffect(() => {
    const $container = document.querySelector(CONTAINER_ID);
    const observer = new ResizeObserver(handleWindowSizeChanged);
    observer.observe($container);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (navigationPrevRef.current && navigationNextRef.current && controller) {
      controller.navigation.prevEl = navigationPrevRef.current;
      controller.navigation.nextEl = navigationNextRef.current;

      controller.navigation.destroy();
      controller.navigation.init();
      controller.navigation.update();

      if (resetSlideOnUpdate) {
        controller.slideTo(0); // resetSlideOnUpdate가 true일 때만 실행
      } else if (resetDirection) {
        console.log('clickedIndex ', activeIndex);
        const index = activeIndex;
        const newIndex = Math.floor(index / slidesPerView) * slidesPerView;
        controller.slideTo(newIndex);
        controller.update(); // 슬라이드 상태 업데이트
      }
    }
  }, [controller, children]);

  return (
    <>
      <Swiper
        {...swiperOption}
        modules={[
          Navigation,
          Autoplay,
          Controller,
          FreeMode,
          Thumbs,
          EffectFade,
          Pagination,
        ]}
        onSwiper={onSwiper ? onSwiper : setController}
        className={swiperClass}
        wrapperTag={swiperTag}
        onSlideChange={handleSlideChanged}
        onReachEnd={handleAllSlideEnded}>
        {React.Children.map(children, (child, index) => {
          if (!child) {
            return null;
          }

          return child.type === React.Fragment ? (
            <p>React.Fragment 요소는 추가 될 수 없습니다.</p>
          ) : (
            <SwiperSlide
              key={index}
              tag={child.type}
              className={`${child.props.className || ''}`}
              data-type={child.props['data-type'] || ''}>
              {child.props.children}
            </SwiperSlide>
          );
        })}
        {hasControls && includesControlsIntoSwiper ? (
          <div className='controller'>
            <div
              ref={navigationPrevRef}
              onClick={handleSlideMovingButtonClick}
              className={`btn_prev`}
              data-method='prev'>
              이전 슬라이드
            </div>
            <div
              ref={navigationNextRef}
              onClick={handleSlideMovingButtonClick}
              className={`btn_next`}
              data-method='next'>
              다음 슬라이드
            </div>
          </div>
        ) : null}
      </Swiper>
      {hasControls && !includesControlsIntoSwiper ? (
        <div className='controller'>
          <div
            ref={navigationPrevRef}
            onClick={handleSlideMovingButtonClick}
            className={`btn_prev`}
            data-method='prev'>
            이전 슬라이드
          </div>
          <div
            ref={navigationNextRef}
            onClick={handleSlideMovingButtonClick}
            className={`btn_next`}
            data-method='next'>
            다음 슬라이드
          </div>
        </div>
      ) : null}
    </>
  );
});

export default SoopUiSwiper;
