'use client';
import globalDomains from '@/utils/global-domains';
import {scheme} from '@afreecatv/domains';
import {useEffect, useRef, useState} from 'react';
import {useOutsideClick} from '../main/common/hooks/useOutsideClick';
import throwToast from '@/utils/ui/toast';
import useShareStore from '@/stores/common/share';

const KAKAO_TEMPLATE = {
  LIVE: 78649,
  VOD: 78660,
};

const SHARE_MENU_LIST = [
  {label: 'x', url: 'ico_share_x.svg'},
  {label: 'facebook', url: 'ico_share_facebook.svg'},
  {label: 'telegram', url: 'ico_share_tele.svg'},
  {label: 'kakaotalk', url: 'ico_share_kakao.svg'},
  {label: 'url', url: 'ico_share_link.svg'},
];

const SoopUiShare = () => {
  const shareRef = useRef(null);
  const {shareLayer, setShareLayer} = useShareStore();
  const [position, setPosition] = useState({top: 0, left: -9999});

  useEffect(() => {
    const handleResize = () => {
      setShareLayer({
        type: '',
        isShow: false,
        data: {},
        top: 0,
        left: 0,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (shareLayer.isShow) {
      checkButtonPosition();
    } else {
      setPosition({top: 0, left: -9999});
    }
  }, [shareLayer.isShow]);

  useEffect(() => {
    if (shareLayer.isShow) {
      setShareLayer({
        type: '',
        isShow: false,
        data: {},
        top: 0,
        left: 0,
      });
    }
  }, [window.location.href]);

  useOutsideClick(shareRef, () => {
    if (shareLayer.isShow) {
      setShareLayer({
        type: '',
        isShow: false,
        data: {},
        top: 0,
        left: 0,
      });
    }
  });

  const checkButtonPosition = () => {
    if (shareLayer) {
      const MENU_WIDTH = 395;
      let newTop = shareLayer.top + window.scrollY;
      let newLeft = shareLayer.left + window.scrollX;
      if (newLeft + MENU_WIDTH > window.innerWidth) {
        newLeft = window.innerWidth - MENU_WIDTH - 100;
      }

      setPosition((prevPosition) => {
        if (prevPosition.top !== newTop || prevPosition.left !== newLeft) {
          return {
            top: newTop,
            left: newLeft,
          };
        }
        return prevPosition;
      });
    }
  };
  const handleClose = () => {
    setShareLayer({
      type: '',
      isShow: false,
      data: {},
      top: 0,
      left: 0,
    });
  };

  const createShareUrl = () => {
    const {type, data} = shareLayer;

    let url = data.url || window.location.href;
    if (type === 'live') {
      url = `${globalDomains.PLAY_AFREECATV}/${data.userId}/${data.broadcastId}`;
    } else if (type === 'vod') {
      url = `${globalDomains.VOD_AFREECATV}/player/${data.broadcastId}`;
    } else if (type === 'catch') {
      url = `${globalDomains.VOD_AFREECATV}/player/${data.broadcastId}/catch`;
    } else if (type === 'catchstory') {
      url = `${globalDomains.VOD_AFREECATV}/player/${data.broadcastId}/catchstory`;
    } else if (type === 'playlist') {
      url = data.url
        ? `${!!data.url.match(/http(s)?:/g) ? '' : window.location.protocol}${data.url}`
        : `${globalDomains.VOD_AFREECATV}/player/${data.vodNumber}/playlist/${data.playlistNumber}`;
    }
    return url;
  };
  const handleShare = (type) => {
    const urlToShare = createShareUrl();
    const textToShare = shareLayer.data.title;

    switch (type) {
      case 'x':
        const xShareUrl = `https://x.com/intent/tweet?text=${encodeURIComponent(textToShare)}&url=${encodeURIComponent(urlToShare)}`;
        window.open(xShareUrl, '_blank');
        break;
      case 'facebook':
        const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`;
        window.open(facebookShareUrl, '_blank');
        break;
      case 'telegram':
        const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(urlToShare)}&text=${encodeURIComponent(textToShare)}`;
        window.open(telegramShareUrl, '_blank');
        break;
      case 'kakaotalk':
        loadKakaoScript().then(() => shareKakao());
        break;
      case 'url':
        navigator.clipboard
          .writeText(urlToShare)
          .then(() => {
            throwToast('주소가 복사됐습니다. 원하는 곳에 붙여넣기 해주세요.');
          })
          .catch((err) => {
            throwToast('URL 복사 실패');
          });
        break;
      default:
        break;
    }
    handleClose();
  };

  const shareKakao = () => {
    const customObject = {
      templateId:
        shareLayer.type === 'live' ? KAKAO_TEMPLATE.LIVE : KAKAO_TEMPLATE.VOD, // 아프리카 카카오 데브 계정 커스텀 템플릿 아이디
    };
    let params = {};
    if (shareLayer.type === 'live') {
      params = {
        title: shareLayer.data.title,
        imageUrl: shareLayer.data.thumbnailSrc,
        bjId: shareLayer.data.userId,
        broadNumber: shareLayer.data.broadcastId,
        scheme:
          shareLayer.data.scheme ||
          `${scheme.SERVICE_APP_SCHEME}player/live?broad_no=${shareLayer.data.broadcastId}&user_id=${shareLayer.data.userId}`,
      };
    } else {
      const stationNoParam = shareLayer.data.stationNo
        ? `&station_no=${shareLayer.data.stationNo}`
        : '';

      params = {
        title: shareLayer.data.title,
        imageUrl: shareLayer.data.thumbnailSrc,
        bjId: shareLayer.data.userId,
        videoNumber: shareLayer.data.broadcastId,
        playerType:
          shareLayer.type === 'catch' || shareLayer.type === 'catchstory'
            ? `/${shareLayer.type}`
            : '',
        scheme:
          shareLayer.data.scheme ||
          `${scheme.SERVICE_APP_SCHEME}player/video?title_no=${shareLayer.data.broadcastId}&user_id=${shareLayer.data.userId}&type=${shareLayer.type === 'vod' ? 'REVIEW' : shareLayer.type.toUpperCase()}${stationNoParam}`,
      };
    }

    customObject.templateArgs = params;

    if (!Kakao.isInitialized()) {
      // SDK 초기화는 처음 실행했을 때 한번만
      Kakao.init('b4b3dd0cdd9894c56957b935d868c7ea');
    }
    Kakao.Link.sendCustom(customObject);
  };

  const loadKakaoScript = () => {
    return new Promise((resolve, reject) => {
      const existingScript = document.querySelector(
        `script[src="${globalDomains.RES_AFREECATV}/script/kakao.min.js"]`,
      );

      if (!existingScript) {
        const script = document.createElement('script');
        script.src = `${globalDomains.RES_AFREECATV}/script/kakao.min.js`;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      } else {
        resolve();
      }
    });
  };
  return (
    <div
      ref={shareRef}
      className='_share_layer'
      style={{
        display: 'block',
        top: position.top,
        left: position.left,
      }}>
      <div className='title'>
        <h3>공유하기</h3>
      </div>
      <div className='_share_type'>
        <div className='_list'>
          {SHARE_MENU_LIST.map((item, idx) => (
            <button
              key={idx}
              type='button'
              id={item.label}
              onClick={() => handleShare(item.label)}>
              <img
                src={`${globalDomains.RES_AFREECATV}/images/svg/${item.url}`}
                alt='X'
              />
            </button>
          ))}
        </div>
      </div>
      <button type='button' className='close_layer' onClick={handleClose}>
        레이어 닫기
      </button>
    </div>
  );
};

export default SoopUiShare;
