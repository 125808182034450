import {useEffect, useRef, useState} from 'react';
import globalDomains from '@/utils/global-domains';
import {
  PLAYER_EVENT,
  PLAYER_SCRIPT_NAME,
  STREAM_EVENT,
} from '@/constants/player/live';
import {useVisibilityChange} from '@/components/common/hooks/useVisibilityChange';

export const useLivePlayer = ({
  config,
  player,
  playerType,
  autoPlay = false,
  loop = false,
  muted: initMuted = true,
  volume: initVolume = 1,
  onAutoPlayBlock = (event) => {},
  onCanPlay = (event) => {},
  onPlay = (event) => {},
  onPause = (event) => {},
  onDurationChange = (event) => {},
  onTimeUpdate = (event) => {},
  onEnd = (event) => {},
  onError = (event) => {},
  shouldPlayWhenVisible = false,
}) => {
  const videoRef = useRef(null);
  const [isPlayerScriptLoaded, setIsPlayerScriptLoaded] = useState(false);
  const [isPlayerLoaded, setIsPlayerLoaded] = useState(false);
  const isCanPlay = useRef(false);
  const [playing, setPlayingState] = useState(false);
  //State for checking autoplay on foreground return
  const [wasPlaying, setWasPlaying] = useState(false);
  const [currentTIme, setCurrentTIme] = useState(0);
  const [duration, setDuration] = useState(0);
  const [muted, setMuted] = useState(initMuted);
  const [volume, setVolume] = useState(initVolume);
  const initConfig = useRef({});

  const [showPoster, setShowPoster] = useState(true);

  const {isGlobalVisible} = useVisibilityChange();

  const playerLib = `${PLAYER_SCRIPT_NAME[playerType]}`;

  const handleLoad = (event) => {
    if (window[playerLib]) {
      try {
        player.current = new window[playerLib](initConfig.current);
        setIsPlayerScriptLoaded(true);
      } catch (error) {
        console.error('Error creating player:', error);
      }
    }
  };

  const playerScriptUrl = `${globalDomains.STATIC_AFREECATV}/asset/app/liveplayer/player/dist/${playerLib}.js`;

  useEffect(() => {
    initConfig.current = {
      ...config,
      id: playerType,
      muted,
      volume,
    };

    let script = document.querySelector(`script[src="${playerScriptUrl}"]`);

    if (!script) {
      script = document.createElement('script');
      script.src = playerScriptUrl;
      document.body.appendChild(script);
    } else {
      handleLoad();
    }

    script.addEventListener('load', handleLoad);

    return () => {
      script.removeEventListener('load', handleLoad);

      if (player.current) {
        player.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (player.current && isPlayerScriptLoaded) {
      player.current.addEventListener(STREAM_EVENT.CAN_PLAY, (event) => {
        isCanPlay.current = true;
        onCanPlay(event);
      });
      player.current.addEventListener(STREAM_EVENT.PLAY, (event) => {
        setShowPoster(false);
        setPlayingState(true);
        onPlay(event);
      });
      player.current.addEventListener(STREAM_EVENT.PAUSE, (event) => {
        setPlayingState(false);
        //pause가 들어왔다는 것은 재생중이였었다는 것
        setWasPlaying(true);
        onPause(event);
      });
      player.current.addEventListener(STREAM_EVENT.DURATION_CHANGE, (event) => {
        onDurationChange(event);
        setDuration(event.duration);
      });
      player.current.addEventListener(STREAM_EVENT.TIME_UPDATE, (event) => {
        if (isCanPlay.current) {
          onTimeUpdate(event);
        }
      });
      player.current.addEventListener(STREAM_EVENT.VOLUME_CHANGE, (event) => {
        console.warn(event);
      });
      player.current.addEventListener(STREAM_EVENT.END, (event) => {
        if (loop) {
          setPlaying(true);
        } else {
          setPlayingState(false);
          setShowPoster(true);
        }
        onEnd();
      });
      player.current.addEventListener(STREAM_EVENT.ERROR, (event) => {
        onError(event);
      });
      player.current.addEventListener(PLAYER_EVENT.AUTOPLAY_BLOCK, (media) => {
        onAutoPlayBlock(media);
        setShowPoster(true);
      });
      setIsPlayerLoaded(true);
    }
  }, [isPlayerScriptLoaded]);

  useEffect(() => {}, [duration]);

  useEffect(() => {
    if (player.current) {
      player.current.volume = volume;

      if (volume === 0) {
        setMuted(true);
      }
    }
  }, [volume]);

  useEffect(() => {
    if (player.current) {
      player.current.muted = muted;
    }
  }, [muted]);

  useEffect(() => {
    if (shouldPlayWhenVisible) {
      // if (!muted) {
      //return foreground
      if (isGlobalVisible) {
        // setPlaying(wasPlaying);
        setPlaying(true);
      } else {
        //move to background
        setPlaying(false);
      }
      //}
    }
  }, [isGlobalVisible]);

  useEffect(() => {
    playing ? play() : pause();
  }, [playing]);

  const play = () => {
    player.current?.play();
  };

  const pause = () => {
    player.current?.pause();
  };

  const setPlaying = (newPlaying) => {
    //process something...
    setWasPlaying(playing);
    setPlayingState(newPlaying);
  };

  return {
    videoRef,
    isPlayerLoaded,
    isCanPlay,
    playing,
    setPlaying,
    wasPlaying,
    volume,
    setVolume,
    muted,
    setMuted,
    duration,
    setDuration,
    currentTIme,
    setCurrentTIme,
    onAutoPlayBlock,
    onCanPlay,
    onPlay,
    onPause,
    onTimeUpdate,
    showPoster,
  };
};
