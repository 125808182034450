import {decodeEmoticon} from '@/libs/emoticon';

const FeedContent = ({titleName, content, photos, url}) => {
  const firstPhotoUrl = photos?.[0]?.url;
  const photoCount = photos?.length || 0;

  return (
    <div className='post-contents'>
      <a href={url} target='_blank'>
        <strong className='post-title'>{titleName}</strong>
        <div className='post-box'>
          <div className='text_wrap'>
            <p
              className='text'
              dangerouslySetInnerHTML={{__html: decodeEmoticon(content)}}></p>
          </div>
          <div className='img_wrap'>
            <div className='file'>
              {firstPhotoUrl && (
                <>
                  <img src={firstPhotoUrl} alt='프로필 이미지' loading='lazy' />
                  {photoCount > 1 && (
                    <span className='count'>{photoCount}</span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default FeedContent;
