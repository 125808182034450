'use client';
import {useState, useEffect, memo} from 'react';

/**
 * SoopUiImgRework 컴포넌트
 * @desc 이미지를 렌더링하고, 이미지 로딩 실패 시 대체 이미지를 보여줌
 * @param {Object} props - 컴포넌트의 props
 * @param {string} props.src - 렌더링할 이미지의 URL
 * @param {string} [props.className] - 이미지의 클래스명
 * @param {string} [props.alt='image'] - 이미지의 alt 값
 * @param {boolean} [props.hasPassword] - 비밀번호방 여부
 * @param {boolean} [props.isAdult] - 성인방송 여부
 * @param {boolean} [props.isPrivate] - 비밀방송 여부
 * @returns {JSX.Element} 이미지를 렌더링하는 JSX 요소
 * @returns {Function} current.setSource - 내부 img 태그src 직접 제어
 */

const SoopUiImgRework = ({
  src,
  className,
  alt = 'image',
  hasPassword = false,
  isAdult = false,
  isPrivate = false,
  onError,
}) => {
  const [isError, setIsError] = useState(false);
  const [currentSrc, setCurrentSrc] = useState(src);

  useEffect(() => {
    setIsError(false);
    setCurrentSrc(src); // src가 변경될 때마다 currentSrc 업데이트
  }, [src]);

  const handleOnError = () => {
    if (onError) {
      onError();
    }
    setIsError(true);
  };

  if (hasPassword || isAdult || isPrivate || isError) return null;

  //TODO: 추후 Next Image로 변경 필요
  return (
    <img
      src={
        currentSrc?.includes('http')
          ? currentSrc
          : `${window.location.protocol}${currentSrc}`
      }
      className={className}
      onError={handleOnError}
      alt={alt}
      // fill={true}
    />
  );
};

export default memo(SoopUiImgRework);
