import MyHistoryStoryApi from '@/api/my/history/story';
import MyProfileImg from '@/components/my/common/item/MyProfileImg';
import {SoopUiContextMenu} from '@/components/ui';
import {SOOP_UI_IMG_TYPES} from '@/components/ui/SoopUiImg';
import {SoopUiProfileIconMenu, SoopUiImg} from '@/components/ui';
import {MY_STORY_MENU} from '@/constants/my';
import {decodeEmoticon} from '@/libs/emoticon';
import globalDomains from '@/utils/global-domains';
import {newLineToBreak} from '@/utils/main';
import {throwConfirm} from '@/utils/ui/dialog';
import {useRef, useState} from 'react';

/**
 * @typedef {Object} Photo
 * @description 이미지 정보
 * @property {String} url - 이미지 URL
 */

/**
 * @typedef {Object} Parent
 * @description 부모 글 정보
 * @property {String} url - 댓글 URL
 * @property {String} userId - 사용자 ID
 * @property {String} userNick - 사용자 닉네임
 * @property {String} regDate - 등록일
 * @property {String} comment - 댓글 내용
 * @property {Object} photo - 댓글 이미지
 */

/**
 * @description 답글 컴포넌트
 * @param {Parent} parent - 부모 글 정보
 * @param {String} feedType - 게시글 타입
 * @param {String} stationUserId - 채널 아이디
 * @param {Number} titleNo - 게시글 번호
 * @param {Number} pCommentNo - 부모 댓글 번호
 * @param {Number} cCommentNo - 답글 번호
 * @param {String} url - 답글 URL
 * @param {String} comment - 내용
 * @param {String} regDate - 등록일
 * @param {Photo} photo - 이미지
 */

const MyChildComment = ({
  parent,
  feedType,
  stationUserId,
  titleNo,
  pCommentNo,
  cCommentNo,
  url,
  comment,
  regDate,
  photo,
  refetching,
}) => {
  const soopUiProfileIconMenu = useRef(null);
  const deleteMyChildComment = async ({
    feedType,
    stationUserId,
    titleNo,
    pCommentNo,
    cCommentNo,
  }) => {
    try {
      await MyHistoryStoryApi.deleteMyHistoryStory({
        feedType,
        stationUserId,
        titleNo,
        pCommentNo,
        cCommentNo,
      });
      refetching(feedType, cCommentNo);
      return;
    } catch (error) {
      console.log(error);
      return;
    }
  };
  const handleMenuClicked = async (e, type) => {
    const {data: isOk} = await throwConfirm({
      subTitle: '선택하신 답글을 삭제하시겠습니까?',
      message: '삭제 시 스토리와 채널에서 완전 삭제되며, 복구가 불가능합니다.',
    });

    if (!isOk) {
      return;
    }
    if (type === 'delete') {
      deleteMyChildComment({
        feedType,
        stationUserId,
        titleNo,
        pCommentNo,
        cCommentNo,
      });
    }
  };
  return (
    <li className='reply'>
      <span className='list_icon' />
      <div className='list_wrap'>
        <div className='bs_wrap'>
          {/* 부모 댓글 */}
          <div className='cont_area'>
            <a
              href={`${globalDomains.BJ_AFREECATV}/${parent.userId}`}
              target='_blank'>
              <MyProfileImg userId={stationUserId} className='thumb' />
            </a>
            <div className='bs_detail'>
              <div className='info_wrap'>
                <div className='nick_wrap'>
                  <button
                    type='button'
                    className='nick'
                    onClick={() => {
                      soopUiProfileIconMenu.current?.open();
                    }}>
                    <strong>{parent.userNick}</strong>
                    <SoopUiProfileIconMenu
                      ref={soopUiProfileIconMenu}
                      streamerId={parent.userId}
                      streamerNick={parent.userNick}
                    />
                  </button>
                  <span className='date'>{parent.regDate}</span>
                </div>
                <a href={parent.url} target='_blank' className='desc'>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: decodeEmoticon(newLineToBreak(parent.comment)),
                    }}></p>
                </a>
              </div>
            </div>
          </div>
          {/* 댓글 이미지 */}
          {parent.photo && (
            <div className='post_img'>
              <a href={parent.url} target='_blank'>
                <SoopUiImg
                  src={parent.photo.url}
                  type={SOOP_UI_IMG_TYPES.thumbnail}
                />
              </a>
            </div>
          )}
        </div>
        <div className='post_box'>
          {/* 답글 이미지 */}
          {photo && (
            <div className='post_img'>
              <a href={url} target='_blank'>
                <SoopUiImg src={photo.url} type={SOOP_UI_IMG_TYPES.thumbnail} />
              </a>
            </div>
          )}
          <div className='cont_area'>
            <a href={url} target='_blank'>
              <span className='reply_badge'>답글</span>
              <p
                className='desc'
                dangerouslySetInnerHTML={{
                  __html: decodeEmoticon(newLineToBreak(comment)),
                }}></p>
            </a>
            <div className='info'>
              <span className='date'>{regDate}</span>
            </div>
          </div>
          <SoopUiContextMenu
            menuList={MY_STORY_MENU}
            handleMenuClicked={handleMenuClicked}
          />
        </div>
      </div>
    </li>
  );
};

export default MyChildComment;
