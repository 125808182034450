'use client';

import useMyStore from '@/stores/my';
import {forwardRef} from 'react';
import MyStreamerSetting from '@/components/my/common/group/StreamerSetting';

const OPTIONS = Object.freeze([
  {label: '최근 구독', type: 'subscribeNo'},
  {label: '닉네임', type: 'userNick'},
  {label: '최근 시작', type: 'lastBroadStart'},
]);

/**
 * 구독 스트리머 관리 팝업
 * @param {Object} data - 팝업 데이터 (throwDialog 함수에서 전달)
 * @param {Function} handleClose - 팝업 닫기 함수 (팝업에서 전달)
 * @param {Object} ref
 * @returns {JSX.Element}
 */
const MySubscribeSetting = forwardRef(function MyLayer({handleClose}, ref) {
  const {subscribeStreamers, getSubscribeStreamers} = useMyStore();

  return (
    <MyStreamerSetting
      allStreamers={subscribeStreamers}
      getStreamers={getSubscribeStreamers}
      options={OPTIONS}
      handleClose={handleClose}
      isSubscribeStreamers
    />
  );
});

export default MySubscribeSetting;
