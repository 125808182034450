import {useEffect, useMemo, useState, useRef} from 'react';
import Helmet from 'react-helmet';
import {DYNAMIC_TAG_LIST} from '@/constants/common/dynamic-tag';
import {
  SEO_TITLE,
  SEO_KEYWORD,
  SEO_DESCRIPTION,
} from '@/constants/common/seo.js';
import useDirectoryStore from '@/stores/directory';

const HelmetLoader = ({routeName, search}) => {
  const [isRender, setIsRender] = useState(false);
  const loadedScriptsRef = useRef(new Set());
  const {selectedCategoryName} = useDirectoryStore();
  const url = search.substring(1).split(/[?&=]/);

  const obj = {};

  url.forEach((item) => {
    if (item !== '') {
      const [key, value] = item.split('=');
      obj[key] = value;
    }
  });

  const route = routeName.toString().split('/');
  const routeList = useMemo(() => {
    return [route[1], route[route.length - 1]];
  }, [route]);

  const helmetKeyword = useMemo(() => {
    try {
      if (routeList[1] === 'search') {
        return url[1] === 'hashtag'
          ? SEO_KEYWORD['tagSearch']
          : SEO_KEYWORD['totalSearch'];
      }
      return SEO_KEYWORD[routeList[0]];
    } catch (error) {
      return 'SOOP';
    }
  }, [routeList]);

  const helmetDescription = useMemo(() => {
    try {
      if (routeList[0] === 'directory') {
        return SEO_DESCRIPTION[routeList[0]];
      }
      if (routeList[1] === 'search') {
        return url[1] === 'hashtag'
          ? SEO_DESCRIPTION['tagSearch']
          : SEO_DESCRIPTION['totalSearch'];
      }
      return SEO_DESCRIPTION[routeList[1]];
    } catch (error) {
      return 'SOOP';
    }
  }, [routeList]);

  const helmetTitle = useMemo(() => {
    try {
      if (routeList[0] === 'directory') {
        if (route.length === 5) {
          return `${decodeURI(decodeURIComponent(route[3]))} 카테고리 - SOOP`;
        } else if (route.length === 4) {
          return `${selectedCategoryName} 콘텐츠 - SOOP`;
        }
      }

      if (routeList[1] === 'search') {
        return url[1] === 'hashtag' ? '태그 검색 - SOOP' : '통합 검색 - SOOP';
      }
      return `${SEO_TITLE[routeList[1]] || ''}SOOP`;
    } catch (error) {
      return 'SOOP';
    }
  }, [routeList, url, selectedCategoryName, route]);

  const handleLoadScript = (tagInfo) => {
    const existingScript = document.getElementById(tagInfo.id);
    // 존재한 스크립트가 있을 경우 다음
    if (existingScript) {
      return;
    }
    let isLoad = tagInfo.isLoad || false;
    let element = document.createElement(tagInfo.tag);
    // 중복 생성 방지를 위해 id 추가
    element.id = tagInfo.id;
    // 어트리 뷰트 있을경우 추가
    tagInfo.attribute &&
      tagInfo.attribute.filter((attribute) => {
        element[attribute.key] = attribute.value;
        return attribute;
      });
    // head에 태그 추가
    document.head.appendChild(element);
    // 로드함수 진행
    if (isLoad) {
      element.onload = () => {
        tagInfo.callback && tagInfo.callback();
      };
      element.onerror = () => {};
    }
  };

  useEffect(() => {
    // 선언된 만큼 tag 추가, dom 상태가 완료될때 수행 - in
    const handleReadyStateChange = () => {
      if (!isRender && document.readyState === 'complete') {
        DYNAMIC_TAG_LIST.map((tagInfo) => {
          if (
            loadedScriptsRef &&
            loadedScriptsRef.current &&
            loadedScriptsRef.current.has(tagInfo.id)
          ) {
            return;
          }
          loadedScriptsRef.current.add(tagInfo.id);
          handleLoadScript(tagInfo);
        });
        setIsRender(true);
      }
    };
    handleReadyStateChange();
    document.addEventListener('readystatechange', handleReadyStateChange);

    return () =>
      document.removeEventListener('readystatechange', handleReadyStateChange);
  }, [isRender]);

  return (
    <Helmet>
      <title>{helmetTitle}</title>
      <meta name='keyword' content={helmetKeyword} />
      <meta name='description' content={helmetDescription} />
      <meta property='og:type' content='website' />
    </Helmet>
  );
};

export default HelmetLoader;
