import useConvertRegDate from '@/components/main/common/hooks/useConvertRegDate';
import {SoopUiContextMenu, SoopUiProfileIconMenu} from '@/components/ui';
import MyProfileImg from '@/components/my/common/item/MyProfileImg';
import globalDomains from '@/utils/global-domains';
import {convertViewCnt} from '@/utils/main';
import useFavoriteStore from '@/stores/search';
import {useRef} from 'react';
import {sendLegacyInflowPathLog} from '@/utils/main/my/log';
import throwToast from '@/utils/ui/toast';

const PlaylistThumbnailInfo = ({
  userId,
  userNickname,
  vodNumber,
  vodOriginalId = '',
  vodRegister = '',
  title,
  registerDate,
  url,
  playlistNumber = 0,
  isDisplay = '',
  isFan = false,
  isSubscribe = false,
  menuList,
  handleOnClickMenu,
  listIdx,
  regId,
  addId,
  logCallback,
  isWidthList = false,
  viewCnt = 0,
  isLogSendData,
  searchArea = 'total',
  playlistCount,
}) => {
  const soopUiProfileIconMenu = useRef(null);
  const {toggleFavorite, setFavoriteStreamerName} = useFavoriteStore();
  const link = url
    ? url
    : `${globalDomains.VOD_AFREECATV}/player/${vodNumber}/playlist/${playlistNumber}`;
  const {convertRegDate} = useConvertRegDate();

  const handleSectionClick = (type) => {
    let param = type ? {type: type} : '';

    logCallback && logCallback(param);
  };

  /**
   * 제목 클릭 시에 유입경로 찍힐수 있도록 함수 분리
   */
  const handleInflowPath = () => {
    vodNumber &&
      vodOriginalId &&
      vodRegister &&
      sendLegacyInflowPathLog({
        broadcastId: vodNumber,
        streamerId: vodOriginalId,
        type: 'vod',
        isLogSendData: {
          ...isLogSendData,
          uploader: vodRegister,
        },
      });
  };

  /**
   * 프로필 ict 메뉴의 이벤트가 발생했을 때 트리거
   * @param {EventData} event
   */
  const handleProfileIconMenuEvent = (event) => {
    const {eventName, data} = event;

    // 검색페이지에서 즐겨찾기 했을 경우 프로필테마/스트리머영역 즐겨찾기 관련 동기화를 위한 로직
    if (eventName === 'deleteFavorite') {
      toggleFavorite();
      setFavoriteStreamerName(data.id);
    }
  };

  const handleProfileClick = (e) => {
    e.preventDefault();

    if (userId) {
      handleSectionClick('sti');

      window.open(`${globalDomains.BJ_AFREECATV}/${userId}`);
    }
  };

  const handleTitleClick = (e) => {
    if (playlistCount === 0) {
      e.preventDefault();
      throwToast('재생 가능한 VOD가 없습니다.');
      return;
    }
  };

  return (
    <div className='cBox-info'>
      <div className='details play_list_detail'>
        {isWidthList ? (
          // 검색페이지 재생목록 UI
          <>
            <div className='thumb_warp'>
              <a
                href={`${globalDomains.BJ_AFREECATV}/${userId}`}
                className='thumb'
                target='_blank'
                onClick={(e) => handleProfileClick(e)}>
                <MyProfileImg userId={userId} />
              </a>
              <div className='nick_wrap'>
                <a
                  className='nick'
                  href={`${globalDomains.BJ_AFREECATV}/${userId}`}
                  target='_blank'
                  onClick={(e) => {
                    e.preventDefault();
                    soopUiProfileIconMenu.current?.open();
                    handleSectionClick('nick');
                  }}>
                  <span>{userNickname}</span>
                </a>
                {/*둘다(구독,팬) 노출이 있어 각 컴포넌트 별로 관리*/}
                {!!isSubscribe && (
                  <span className='grade-badge-subscribe'>구독</span>
                )}
                {!!isFan && <span className='grade-badge-fan'>F</span>}
              </div>
            </div>
            <SoopUiProfileIconMenu
              ref={soopUiProfileIconMenu}
              streamerId={userId}
              streamerNick={userNickname}
              handleMenuButtonClicked={handleProfileIconMenuEvent}
              searchArea={searchArea}
            />
            <h3 className='title'>
              <a
                href={link}
                target='_blank'
                onClick={() => {
                  handleSectionClick('tt');
                  handleInflowPath();
                  handleTitleClick();
                }}>
                {title}
              </a>
            </h3>
            <div className='vod_info'>
              <span className='views'>{convertViewCnt(viewCnt)}</span>
              <span className='date'>{convertRegDate(registerDate)}</span>
            </div>
          </>
        ) : (
          <>
            <div className='nick_wrap'>
              <a
                className='nick'
                href={`${globalDomains.BJ_AFREECATV}/${userId}`}
                target='_blank'>
                <span>{userNickname}</span>
              </a>
              {/*둘다(구독,팬) 노출이 있어 각 컴포넌트 별로 관리*/}
              {!!isSubscribe && (
                <span className='grade-badge-subscribe'>구독</span>
              )}
              {!!isFan && <span className='grade-badge-fan'>F</span>}
            </div>
            <h3 className='title'>
              <a href={link} target='_blank' onClick={handleTitleClick}>
                {title}
              </a>
            </h3>
            <div className='all_list'>
              {/* TODO:전체 보기도 있었던거 같은데 우선 UI 는 비공개만 노출, 있을경우 추가해 주세요 */}
              {isDisplay === 'N' && <span className='hidden_list'>비공개</span>}
              <span className='update'>
                업데이트: {convertRegDate(registerDate)}
              </span>
            </div>
          </>
        )}
      </div>
      <SoopUiContextMenu
        menuList={menuList}
        handleMenuClicked={handleOnClickMenu}
        isPlaylist
        data={{playlistNumber, listIdx, regId, addId, url, title}}
      />
    </div>
  );
};

export default PlaylistThumbnailInfo;
