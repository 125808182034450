import {useEffect, useState, useMemo, useCallback} from 'react';

import {
  FilterBox,
  ShowMore,
  NoSearchResults,
  Loading,
} from '@/components/search/common';
import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';
import SectionHeader from '@/components/main/common/SectionHeader';
import {ErrorBoundary} from '@/components/common';
import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import {SoopUiSelectBox} from '@/components/ui';
import {useInfiniteScroll} from '@/components/main/common/hooks/useInfiniteScroll';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

import {
  SEARCH_VOD_FILTER,
  SEARCH_VOD_TAB_FILTER,
  SEARCH_TAG_VOD_FILTER,
} from '@/constants/search/filter';
import {VOD_TYPES} from '@/constants';
import {VOD_CONTEXT_MENU} from '@/constants/main';
import {PLAYLIST_CONTEXT_MENU} from '@/constants/main';
import {BROADCAST_ITEM_DISPLAY_TYPE} from '@/constants';
import { decodeFullHtmlEntities } from '@/utils';

/**
 * VodGroup 컴포넌트 ( VOD )
 * @param {Object} props 컴포넌트의 props
 * @param {Object[]} vod vod 데이터
 * @param {String} stopWord 금칙어
 * @returns VodGroup 컴포넌트
 */
const VodGroup = ({vod, stopWord}) => {
  const {sendSckactVodClickLog} = useSearchClickLog();
  const {getParams} = useUrlParameter();
  const {
    szKeyword: keyword = '',
    szSearchType: searchType = '',
    hashtype: hashType = '',
    tagname,
  } = getParams();

  const {
    sessionKey,
    hasMore,
    noResult,
    vodLoading,
    changeStatus,
    currentPageNumber,
    nextPage,
    activeTab,
    resetPageNumber,
    isTotalPage,
    isVodPage,
    isVodTagSearchPage,
  } = useSearchGlobalStatus();

  const [vodList, setVodList] = useState([]);
  const [vodListSlice, setVodListSlice] = useState(6);
  const [selectBoxItem, setSelectBoxItem] = useState('score');
  const [filteredTabFilter, setFilteredTabFilter] = useState(
    hashType ? SEARCH_TAG_VOD_FILTER : SEARCH_VOD_TAB_FILTER,
  );
  const [allPlaylist, setAllPlaylist] = useState(false);

  const {ref: lastPositionRef, inView: isLastInView} = useInfiniteScroll({
    delay: 100,
    onChange: (inView) => {
      if (inView && (isVodPage || isVodTagSearchPage) && hasMore.vod) {
        nextPage(activeTab);
      }
    },
  });

  const handleCheckAllPlaylist = (vodList) => {
    const isAllPlaylist =
      vodList.length > 0 &&
      vodList.every((item) => item.fileType === 'PLAYLIST');
    setAllPlaylist(isAllPlaylist);
  };

  // 통합검색칩 > 필터 옵션 변경
  const handleChangeFilterOption = (item, options) => {
    setSelectBoxItem(options.type);
    changeStatus('vod', 12, [options]);
  };

  // vod칩 > 필터 옵션 변경
  const handleChangeDetailFilterOption = (filters) => {
    setVodList([]);
    changeStatus('vod', 40, filters, true);
  };

  const handleShowMoreBtn = () => {
    setVodListSlice((prevCount) => prevCount * 2);
    handleLogCallback({type: 'more'});
  };

  const vodDisplayedList = useMemo(() => {
    if (isTotalPage) {
      return vodList.slice(0, Math.max(vodListSlice, 6)); // 최소 6개 항목 보장
    }
    return vodList;
  }, [searchType, vodList, vodListSlice]);

  const inflowPathLogData = useMemo(() => {
    let logData = {
      path1: ['vod', 'total'].includes(searchType)
        ? 'sch'
        : hashType === 'vod'
          ? 'hash'
          : 'sch',
      path2: searchType === 'vod' || hashType === 'vod' ? 'vod' : 'sch',
      path3: searchType === 'total' ? 'vod' : '',
    };

    return logData;
  }, [searchType, hashType]);

  // SCKACT 로그
  const handleLogCallback = useCallback(
    (param, index, fileType, vodNumber, userId, listNo) => {
      const {type = 'sn'} = param || {};

      let contentsType = 'vod';
      let contentsValue = vodNumber;
      let location = 'total_search';

      let convertActtype = type;
      if (tagname !== undefined && tagname !== '') {
        convertActtype = 'hash';
        location = 'hash';
      }

      if (type === 'sti') {
        contentsType = 'bj';
        contentsValue = userId;
      }

      const isRemoveField = ['nick', 'more', 'hash'].includes(type);

      sendSckactVodClickLog(
        {
          fileType,
          contentsType: isRemoveField ? '' : contentsType,
          contentsValue: isRemoveField ? '' : contentsValue,
          page: currentPageNumber['vod'],
          actCode: type,
          bjid: userId,
          vno: vodNumber,
          playlistIdx: fileType === 'PLAYLIST' ? listNo : '',
          listIdx: index,
          location: location,
          sessionKey,
        },
        '',
      );
    },
    [sessionKey, searchType, currentPageNumber['vod']],
  );

  useEffect(() => {
    // vod 필터옵션 '정확도'로 초기화(통합검색에서만)
    if (isTotalPage) {
      setSelectBoxItem('score');
    }
    setVodList([]);
    setVodListSlice(isTotalPage ? 6 : vodList.length);
  }, [searchType]);

  useEffect(() => {
    handleCheckAllPlaylist(vod);
    if (currentPageNumber['vod'] > 1) {
      setVodList((prevVod) => [...prevVod, ...vod]);
    } else {
      setVodList(vod);
    }
  }, [vod]);

  useEffect(() => {
    return () => {
      resetPageNumber('vod');
    };
  }, []);

  const renderVodSectionHearder = () => {
    if (isTotalPage && vodList.length > 0) {
      return (
        <SectionHeader
          title='VOD'
          rightElement={
            <SoopUiSelectBox
              isSelectedOptionHighlight={true}
              options={SEARCH_VOD_FILTER}
              disableOption={allPlaylist}
              type={selectBoxItem || ''}
              onChange={handleChangeFilterOption}
            />
          }
        />
      );
    } else if (isVodPage || isVodTagSearchPage) {
      return (
        <FilterBox
          title='VOD'
          sortItems={filteredTabFilter}
          onCallback={handleChangeDetailFilterOption}
        />
      );
    }
    return null;
  };

  const renderVodSectionNoResult = () => {
    const shouldRenderNoResult =
      (isVodPage || isVodTagSearchPage) &&
      !vodLoading &&
      (stopWord || noResult.vod || (!keyword && !tagname));

    if (shouldRenderNoResult) {
      return (
        <NoSearchResults
          searchTab='vod'
          isKeywordEntered={Boolean(keyword || tagname)}
          stopWord={stopWord}
        />
      );
    }

    return null;
  };

  const renderVodListItems = () => {
    return vodDisplayedList.map((vod, index) => {
      const isPlaylist = vod.fileType === VOD_TYPES.PLAYLIST;
      const isCatchStory = vod.fileType === VOD_TYPES.CATCH_STORY;
      return (
        <li key={`search_vod-${vod.titleNo}-${index}`} data-type='cBox'>
          <ErrorBoundary>
            <BroadcastLayout
              broadcastType='vod'
              viewType={BROADCAST_ITEM_DISPLAY_TYPE.LIST_VIEW}
              thumbnailUrl={vod.thumbnailPath}
              previewThumbnailUrl={vod.webpPath}
              userId={vod.userId}
              userNickname={vod.userNick}
              originalUserId={vod.originalBj}
              originalUserNickname={vod.originalUserNick}
              viewCount={vod.viewCnt}
              title={decodeFullHtmlEntities(vod.title)}
              titleHistory={vod.titleHistory}
              hasBadge={vod.categoryTags || vod.hashTags || vod.autoHashtags}
              categoryTags={vod.categoryTags}
              hashTags={vod.hashTags}
              autoHashTags={vod.autoHashtags}
              broadcastId={isCatchStory ? vod.listNo : vod.titleNo}
              duration={vod.duration}
              vodType={vod.fileType}
              createdDate={vod.regDate}
              categoryNo={vod.category}
              categoryName={
                vod.categoryTags && vod.categoryTags.length > 0
                  ? vod.categoryTags[0]
                  : ''
              }
              accessibleAge={vod.grade}
              searchActionType='vod'
              isPpv={vod.ppv}
              isFan={!!vod.fanFlag}
              isSubscribe={!!vod.subsFlag}
              playlistId={isPlaylist && vod.listNo}
              playlistCount={vod.playlistCnt}
              vodOriginalId={vod.originalBj}
              vodUploaderId={vod.originalRegUserId}
              contextMenu={{
                menuList: isPlaylist
                  ? PLAYLIST_CONTEXT_MENU
                  : vod.fileType === VOD_TYPES.CATCH ||
                      vod.fileType === VOD_TYPES.CATCH_STORY
                    ? [{label: '공유하기', type: 'share'}]
                    : VOD_CONTEXT_MENU,
              }}
              log={{
                data: {
                  ...inflowPathLogData,
                  cli_list_data_idx: index + 1,
                },
              }}
              handleAfterLogSend={(params) => {
                handleLogCallback(
                  params,
                  index,
                  vod.fileType,
                  vod.titleNo,
                  vod.userId,
                  isPlaylist && vod.listNo,
                );
              }}>
              <BroadcastType.Vod />
            </BroadcastLayout>
          </ErrorBoundary>
        </li>
      );
    });
  };

  return (
    <>
      {renderVodSectionHearder()}

      {renderVodSectionNoResult()}

      {!stopWord && vodList.length > 0 && (
        <>
          <div className='cBox-list'>
            <ul>
              {renderVodListItems()}
              {(isVodPage || isVodTagSearchPage) && (
                <div ref={lastPositionRef} />
              )}
            </ul>
          </div>

          {isTotalPage && (
            <ShowMore
              active={vod.length > 6}
              name={'더보기'}
              onHanlder={handleShowMoreBtn}
            />
          )}
        </>
      )}

      {(isVodPage || isVodTagSearchPage) && vodLoading && <Loading />}
    </>
  );
};

export default VodGroup;