import {useRef, useState} from 'react';
import {SoopUiProfileIconMenu} from '@/components/ui';
import globalDomains from '@/utils/global-domains';
import {convertViewCnt} from '@/utils/main';

const ProfileGroup = (props) => {
  const PROFILE_MENU_TYPES = {
    PROFILE: 'profile',
    SUB_PROFILE: 'subProfile',
  };

  const {
    profileImage,
    userId,
    userNick,
    stationUserNick,
    stationUserId,
    regDate,
    count: {readCnt = 0},
  } = props;

  const [activeMenu, setActiveMenu] = useState(null);
  const targetIsMe = userId === stationUserId;

  const profileMenuRef = useRef(null);
  const profileSubMenuRef = useRef(null);

  const handleCloseProfileMenu = () => {
    setActiveMenu(null);
  };

  const handleOpenProfileMenu = () => {
    profileMenuRef.current?.open();
  };

  return (
    <div className='autor_wrap'>
      <span className='thum'>
        <a href={`${globalDomains.BJ_AFREECATV}/${userId}`} target='_blank'>
          <img src={profileImage} alt='프로필 이미지' />
        </a>
      </span>
      <div className='info_box'>
        <div className='nick_wrap'>
          <div>
            <button
              type='button'
              className='nick'
              onClick={handleOpenProfileMenu}>
              <p>{userNick}</p>
            </button>
            <SoopUiProfileIconMenu
              ref={profileMenuRef}
              streamerId={userId}
              streamerNick={userNick}
            />
          </div>
          {!targetIsMe && (
            <div>
              <button
                type='button'
                className='nick2'
                onClick={handleOpenProfileMenu}>
                {stationUserNick}
              </button>
              <SoopUiProfileIconMenu
                ref={profileSubMenuRef}
                streamerId={stationUserId}
                streamerNick={stationUserNick}
              />
            </div>
          )}
        </div>
        <span>{regDate}</span>
        <span>조회 {convertViewCnt(readCnt)}</span>
      </div>
    </div>
  );
};

export default ProfileGroup;
