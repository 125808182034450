'use client';

import {useEffect, useState} from 'react';
import MyCommonApi from '@/api/my/common';

const getHotKeywordList = async () => {
  try {
    const {data: hotKeywordList} = await MyCommonApi.getHotKeywordList();

    if (!hotKeywordList || !Array.isArray(hotKeywordList)) {
      return {hotKeywordList: []};
    }

    return {hotKeywordList};
  } catch (error) {
    console.log(error);
    return {hotKeywordList: []};
  }
};

const HotKeywordList = () => {
  const [hotKeywordList, setHotKeywordList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // fetch hot keyword list
  useEffect(() => {
    const fetchList = async () => {
      setIsLoading(true);
      try {
        const {hotKeywordList: data} = await getHotKeywordList();
        setHotKeywordList(data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchList();
  }, []);
  return (
    <>
      {hotKeywordList && (
        <div className='keyword_box'>
          <h3 className='title'>핫 키워드</h3>
          <div className='keyword_list'>
            {hotKeywordList.map((hashtag, index) => (
              <a
                href={`/search?hashtype=live&hash=hashtag&tagname=${hashtag}&stype=hash&acttype=live&location=search`}
                key={index}
                target='_blank'>
                <span>{hashtag}</span>
              </a>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default HotKeywordList;
