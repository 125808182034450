'use client';
import '/public/static/css/module/cBox.scss';
import MyPlusApi from '@/api/main/my-plus';
import {ErrorBoundary} from '@/components/common';
import {HiddenStreamer} from '@/components/main/common';
import {
  LiveThumbnailImage,
  ThumbnailInfo,
  VodThumbnailImage,
} from '@/components/main/common/Thumbnail';
import {formatDate, goLogin, isLogin} from '@/utils/main';
import throwToast from '@/utils/ui/toast';
import React, {useState} from 'react';

const MyBroadcast = React.memo(function MyBroadcast({
  thumbnailSrc,
  viewCount,
  broadcastStartTime,
  userId,
  userNickname,
  title,
  categoryTags,
  isDrops,
  isBadge = true,
  isContextmenu,
  isOriginalContent = false, // 씨네티여부 -> 씨네티는 오리지날컨텐츠이기때문에 방송국이 따로없다
  isAdult,
  isPassword,
  isHot,
  hashTags,
  autoHashtags,
  broadcastId,
  duration = 0,
  authNumber,
  fileType,
  isPpv = false,
  isVod = false,
  isWidthList = false,
  isFan = false,
  isFanclub = false,
  isFavorite = false,
  isSubscribe = false,
  registerDate = '',
  categoryName = '',
  visitBroadType = 0,
  broadType = 0,
  copyright = {},
  originalUserNick = '',
  originalUserId = '',
  listDataType = '',
  score = 0,
  menuList = [],
  hiddenStreamerList = [],
  noRecommendStreamerList = [],
  titleHistory = [],
  handleUndoBroadcast = () => {},
  handleOnClickMenu = () => {},
  isVisitBroad,
  laterRegDate,
  webpPath = '',
  broadBps,
  broadCateNo,
  broadResolution,
  isLogSendData = {},
  logCallback,
  logic,
  catchStoryIdList = [],
  grade,
  category,
  scheme = '',
  stationNo = 0,
  groupType,
  groupName,
  searchArea,
  isUserClip,
  adminProfileImg = '',
}) {
  const formattedStartDate = formatDate(broadcastStartTime, 'MM-dd HH:mm');
  const [isTitleHover, setIsTitleHover] = useState(false);

  const handleMouseTitleEnter = () => {
    setIsTitleHover(true);
  };
  const handleMouseTitleLeave = () => {
    setIsTitleHover(false);
  };

  //스트리머 숨기기 컴포넌트
  if (
    hiddenStreamerList.includes(broadcastId) ||
    noRecommendStreamerList.includes(broadcastId)
  ) {
    return (
      <HiddenStreamer
        type={
          hiddenStreamerList.includes(broadcastId) ? 'hidden' : 'not-recommend'
        }
        userNickname={userNickname}
        handleUndoBroadcast={() =>
          handleUndoBroadcast(
            {score, userId, userNickname, broadcastId, listDataType},
            hiddenStreamerList.includes(broadcastId)
              ? 'hidden'
              : 'not-recommend',
          )
        }
      />
    );
  }

  //나중에 보기 api
  const handleLaterView = async (broadcastId) => {
    if (isLogin()) {
      try {
        const response = await MyPlusApi.setLaterView(
          isVod ? 'VOD' : 'LIVE',
          broadcastId,
        );
        if (response) {
          throwToast(response.message);
        }
      } catch (error) {
        const {message} = error.response.data;
        throwToast(message);
      }
    } else {
      //비로그인시에는 로그인페이지로 이동
      goLogin();
    }
  };
  return (
    <ErrorBoundary>
      {isVod && (
        <VodThumbnailImage
          {...{
            thumbnailSrc,
            isPassword,
            isAdult,
            isHot,
            vodNumber: broadcastId, // titleNo
            viewCount,
            authNumber,
            fileType,
            copyright,
            originalUserId,
            originalUserNick,
            duration,
            formattedStartDate,
            isPpv,
            isBadge,
            handleLaterView,
            laterRegDate,
            webpPath,
            broadBps,
            userId,
            categoryTags,
            categoryName,
            hashTags,
            listDataType,
            isLogSendData,
            logCallback,
            catchStoryIdList,
            isTitleHover,
            grade,
            category,
            groupType,
            groupName,
            isUserClip,
          }}
        />
      )}
      {!isVod && (
        <LiveThumbnailImage
          {...{
            thumbnailSrc,
            isPassword,
            isAdult,
            isHot,
            userId,
            broadcastId,
            broadType,
            visitBroadType,
            viewCount,
            formattedStartDate,
            handleLaterView,
            isVisitBroad,
            laterRegDate,
            broadBps,
            broadCateNo,
            categoryName,
            broadResolution,
            hashTags,
            categoryTags,
            listDataType,
            isLogSendData,
            logCallback,
          }}
        />
      )}

      <ThumbnailInfo
        {...{
          userId,
          userNickname,
          broadcastId,
          isDrops,
          isHot,
          title,
          viewCount,
          registerDate,
          categoryName,
          fileType,
          isWidthList,
          isVod,
          isFan: isFan || isFanclub,
          isFavorite,
          isSubscribe,
          isBadge,
          isContextmenu,
          isOriginalContent,
          autoHashtags,
          categoryTags,
          hashTags,
          menuList,
          titleHistory,
          listDataType,
          score,
          handleOnClickMenu,
          logCallback,
          logic,
          originalUserId,
          catchStoryIdList,
          thumbnailSrc,
          scheme,
          stationNo,
          onMouseTitleEnter: handleMouseTitleEnter,
          onMouseTitleLeave: handleMouseTitleLeave,
          groupType,
          groupName,
          searchArea,
          adminProfileImg,
          isUserClip,
          isLogSendData,
          originalUserNick,
        }}
      />
    </ErrorBoundary>
  );
});
export default MyBroadcast;
