import {ROUTE} from '../main/route';

const LIVE_CONTEXT_MENU = Object.freeze([
  {label: '나중에 보기', type: 'view-later'},
  {label: '공유하기', type: 'share'},
]);

const CATCH_CONTEXT_MENU = Object.freeze([{label: '공유하기', type: 'share'}]);

const VOD_CONTEXT_MENU = Object.freeze([
  {label: '나중에 보기', type: 'view-later'},
  {label: '재생목록에 담기', type: 'add-playlist'},
  {label: '공유하기', type: 'share'},
]);

// 즐겨찾기 페이지 용 컨텍스트 메뉴
const FAVORITE_CONTEXT_MENU = [
  {
    label: '즐겨찾기 그룹에 담기',
    type: 'group',
  },
];

// 최근 본 LIVE 페이지 용 컨텍스트 메뉴
const RECENT_LIVE_CONTEXT_MENU = [
  {label: '최근 본 스트리머의 LIVE에서 삭제', type: 'delete-live'},
];

// 최근 본 VOD 페이지 용 컨텍스트 메뉴
const RECENT_VOD_CONTEXT_MENU = [
  {label: '최근 본 VOD에서 삭제', type: 'delete-vod'},
];

// 나중에 보기 페이지 용 컨텍스트 메뉴
const LATER_CONTEXT_MENU = [{label: '나중에 보기 삭제', type: 'delete-later'}];

// 나중에 보기 페이지는 나중에 보기 메뉴가 빠져야 한다
const LATER_VOD_CONTEXT_MENU = Object.freeze([
  {label: '재생목록에 담기', type: 'add-playlist'},
  {label: '공유하기', type: 'share'},
]);
const LATER_LIVE_CONTEXT_MENU = Object.freeze([
  {label: '공유하기', type: 'share'},
]);

// UP 한 VOD 페이지 용 컨텍스트 메뉴
const UP_CONTEXT_MENU = Object.freeze([
  {label: 'UP 취소하기', type: 'delete-up'},
]);

// 플레이리스트용 컨텍스트 메뉴
const PLAYLIST_CONTEXT_MENU = [
  {label: '삭제하기', type: 'delete-playlist'},
  {label: '공유하기', type: 'share'},
];

// 추천 스트리머 방송용 컨텍스트 메뉴
export const RECOMMEND_CONTEXT_MENU = Object.freeze([
  {label: '나중에 보기', type: 'view-later'},
  {label: '공유하기', type: 'share'},
  {label: '추천받지 않기', type: 'not-recommend'},
]);

export const CONTEXT_MENU_MAP = Object.freeze({
  [ROUTE.MY.FAVORITE]: {
    LIVE: [...LIVE_CONTEXT_MENU, ...FAVORITE_CONTEXT_MENU],
    VOD: [...VOD_CONTEXT_MENU, ...FAVORITE_CONTEXT_MENU],
    CATCH: [...CATCH_CONTEXT_MENU, ...FAVORITE_CONTEXT_MENU],
  },
  [ROUTE.MY.HISTORY.UP]: {
    LIVE: [...LIVE_CONTEXT_MENU, ...UP_CONTEXT_MENU],
    VOD: [...VOD_CONTEXT_MENU, ...UP_CONTEXT_MENU],
    CATCH: [...CATCH_CONTEXT_MENU, ...UP_CONTEXT_MENU],
  },
  [ROUTE.MY.HISTORY.LATER]: {
    LIVE: [...LATER_LIVE_CONTEXT_MENU, ...LATER_CONTEXT_MENU],
    VOD: [...LATER_VOD_CONTEXT_MENU, ...LATER_CONTEXT_MENU],
    CATCH: [...CATCH_CONTEXT_MENU, ...LATER_CONTEXT_MENU],
  },
  [ROUTE.MY.HISTORY.RECENT.INDEX]: {
    LIVE: [...LIVE_CONTEXT_MENU, ...RECENT_LIVE_CONTEXT_MENU],
  },
  [ROUTE.MY.HISTORY.RECENT.VOD]: {
    VOD: [...VOD_CONTEXT_MENU, ...RECENT_VOD_CONTEXT_MENU],
    CATCH: [...CATCH_CONTEXT_MENU, ...RECENT_VOD_CONTEXT_MENU],
  },
  VOD: VOD_CONTEXT_MENU,
  LIVE: LIVE_CONTEXT_MENU,
  CATCH: CATCH_CONTEXT_MENU,
  PLAYLIST: PLAYLIST_CONTEXT_MENU,
});

export const CONTEXT_MENU = Object.freeze({
  FAVORITE: {
    LIVE: [...LIVE_CONTEXT_MENU, ...FAVORITE_CONTEXT_MENU],
    VOD: [...VOD_CONTEXT_MENU, ...FAVORITE_CONTEXT_MENU],
    CATCH: [...CATCH_CONTEXT_MENU, ...FAVORITE_CONTEXT_MENU],
  },
  UP: {
    LIVE: [...LIVE_CONTEXT_MENU, ...UP_CONTEXT_MENU],
    VOD: [...VOD_CONTEXT_MENU, ...UP_CONTEXT_MENU],
    CATCH: [...CATCH_CONTEXT_MENU, ...UP_CONTEXT_MENU],
  },
  LATER: {
    LIVE: [...LATER_LIVE_CONTEXT_MENU, ...LATER_CONTEXT_MENU],
    VOD: [...LATER_VOD_CONTEXT_MENU, ...LATER_CONTEXT_MENU],
    CATCH: [...CATCH_CONTEXT_MENU, ...LATER_CONTEXT_MENU],
  },
  RECENT: {
    LIVE: [...LIVE_CONTEXT_MENU, ...RECENT_LIVE_CONTEXT_MENU],
    VOD: [...VOD_CONTEXT_MENU, ...RECENT_VOD_CONTEXT_MENU],
    CATCH: [...CATCH_CONTEXT_MENU, ...RECENT_VOD_CONTEXT_MENU],
  },
  VOD: VOD_CONTEXT_MENU,
  LIVE: LIVE_CONTEXT_MENU,
  CATCH: CATCH_CONTEXT_MENU,
  PLAYLIST: PLAYLIST_CONTEXT_MENU,
});
