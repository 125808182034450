import '/public/static/css/module/tab_list_item.scss';
import {SectionHeader} from '@/components/main/common';
import {SoopUiSelectBox} from '@/components/ui';
import {DIRECTORY_CONTENT_SORT_OPTION} from '@/constants/main';
import {useEffect, useRef, useState} from 'react';
import {useOutsideClick} from '../../common/hooks/useOutsideClick';
import useDirectoryStore from '@/stores/directory';

const getItemsByTab = (tab) => {
  if (tab) {
    const option = DIRECTORY_CONTENT_SORT_OPTION.find(
      (option) => option.type === tab,
    );

    if (tab === 'live') {
      return option ? option.items : [];
    } else {
      return option;
    }
  }
};

const LIST_TAB = [
  {key: 'live', label: 'LIVE'},
  {key: 'vod', label: 'VOD'},
  {key: 'catch', label: 'Catch'},
];

export const ListTab = ({
  tab,
  order,
  categoryInfo,
  handleOnClickTab,
  handleOnClickSort,
  handleVodFilterClick,
}) => {
  //LIVE VOD CATCH 어드민 설정에 의해 노출/비노출 여부 확인
  const showRenderTab = (item) => {
    if (item.key === 'live' && categoryInfo?.liveCategoryNo === '00000000') {
      return false;
    }
    if (
      (item.key === 'vod' || item.key === 'catch') &&
      categoryInfo?.vodCategoryNo === '00000000'
    ) {
      return false;
    }
    return true;
  };

  const [isShow, setIsShow] = useState(false); //vod, catch 필터 노출여부
  const {selectedContentFilter} = useDirectoryStore();
  const filterRef = useRef(null);

  useOutsideClick(filterRef, () => {
    if (tab === 'live') {
      setIsShow(false);
    }
  });

  const isActiveFilter = (key, type) => {
    return selectedContentFilter.selected.some(
      (filter) => filter.key === key && filter.type === type,
    );
  };

  useEffect(() => {
    setIsShow(false);
  }, [tab]);

  return (
    <>
      <div className='tab_list_item'>
        <ul>
          {LIST_TAB.filter(showRenderTab).map((item, idx) => (
            <li key={idx} className={`${tab === `${item.key}` ? 'on' : ''}`}>
              <button
                type='button'
                onClick={() => handleOnClickTab(`${item.key}`)}>
                <span>{item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <SectionHeader
        title={tab === 'catch' ? 'Catch' : tab.toUpperCase()}
        rightElement={
          tab === 'live' ? (
            <SoopUiSelectBox
              options={getItemsByTab(tab)}
              type={order}
              onChange={handleOnClickSort}
            />
          ) : (
            <div className={`select_box_item ${isShow ? 'on' : ''}`}>
              <button
                type='button'
                className='selected'
                onClick={() => {
                  setIsShow(!isShow);
                }}>
                {selectedContentFilter.selected.map(
                  (filter, idx) =>
                    (tab !== 'catch' || filter.key !== 'fileType') && (
                      <span key={idx} className='item'>
                        {filter.label}
                      </span>
                    ),
                )}
              </button>
            </div>
          )
        }
      />
      {tab !== 'live' && (
        <div
          className={`select_filter_list ${isShow ? 'active' : ''}`}
          ref={filterRef}>
          {getItemsByTab(tab)?.items.map((filter, index) => (
            <dl key={index}>
              <dt>{filter.title}</dt>
              <dd>
                <ul>
                  {filter.item.map((option, idx) => (
                    <li
                      key={idx}
                      className={
                        isActiveFilter(filter.key, option.type) ? 'active' : ''
                      }
                      onClick={() => {
                        if (isActiveFilter(filter.key, option.type)) {
                          return;
                        }
                        handleVodFilterClick(
                          filter.key,
                          option.label,
                          option.type,
                        );
                      }}>
                      <button type='button'>{option.label}</button>
                    </li>
                  ))}
                </ul>
              </dd>
            </dl>
          ))}
          <button
            type='button'
            className='btn_close'
            onClick={() => setIsShow(false)}>
            레이어 닫기
          </button>
        </div>
      )}
    </>
  );
};

export default ListTab;
