import {Fragment} from 'react';
import {Medal} from '@/components/search/group/streamer-profile-group';
import globalDomains from '@/utils/global-domains';
import {convertViewCnt, convertTotalHours} from '@/utils/main';
import {PROFILE_FIELDS} from '@/constants/search/streamerProfile';

const getNoticeBoardUrl = (userId, titleNo) => {
  return `${globalDomains.BJ_AFREECATV}/${userId}/post/${titleNo}`;
};

/**
 * StreamerInfoBox 컴포넌트
 * @param {Object} props - StreamerInfoBox props
 * @param {Object} profileData - 스트리머 정보
 * @param {Object} onMedalClick - 메달 리스트 확장 클릭 이벤트 핸들러
 * @returns {JSX.Element} StreamerInfoBox 컴포넌트
 */
const StreamerInfoBox = ({
  profileData,
  onMedalClick,
  logCallback = () => {},
}) => {
  const renderProfileField = (title, content, contentKey, userId, userAge) => {
    switch (title) {
      case '공지':
        return (
          <dd>
            <a
              href={getNoticeBoardUrl(userId, content.titleNo)}
              target='_blank'
              onClick={logCallback}>
              {content[contentKey]}
            </a>
          </dd>
        );
      case '메달':
        return (
          <Medal
            medalList={content[contentKey]}
            onMedalClick={() => {
              onMedalClick(userId);
            }}
          />
        );
      case '나이':
        return (
          <dd>
            {content[contentKey]}년생 ({userAge}세)
          </dd>
        );
      case '누적 참여자':
        return <dd>{convertViewCnt(content[contentKey])}명</dd>;
      case '방송시간':
        return (
          <dd>{convertViewCnt(convertTotalHours(content[contentKey]))}시간</dd>
        );
      case '경력':
        return (
          <dd>
            {content[contentKey]?.map((text, index) => (
              <Fragment key={index}>
                {text}
                {index < content[contentKey].length - 1 && <br />}
              </Fragment>
            ))}
          </dd>
        );
      default:
        return <dd>{content[contentKey]}</dd>;
    }
  };

  return (
    <>
      {PROFILE_FIELDS.map(
        ({key, title}) =>
          !!profileData[key] && // profileData[key]가 null, undefined, false가 아닌지 확인
          (!Array.isArray(profileData[key]) || profileData[key].length > 0) && ( // 빈 배열이 아닌지 확인
            <dl className='info_box' key={key}>
              <dt>{title}</dt>
              {renderProfileField(
                title,
                profileData,
                key,
                profileData.userId,
                profileData.userAge,
              )}
            </dl>
          ),
      )}
    </>
  );
};

export default StreamerInfoBox;
