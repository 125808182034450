'use client';

import useGlobalUIStore from '@/stores';
import {MAIN_THEME} from '@/constants';
import {useEffect} from 'react';
import MainLiveApi from '@/api/main/main-live';

//  이렇게 해도 되나?? 모르겠다 ㅋㅋ
export default function BodyStyler() {
  const {setAutoTagStyle} = useGlobalUIStore((state) => {
    return state;
  });

  useEffect(() => {
    getAutoTagStyle();
  }, []);

  const getAutoTagStyle = async () => {
    try {
      const response = await MainLiveApi.getAutoTagStyle();
      if (response) {
        setAutoTagStyle(response);
      }
    } catch (error) {
      setAutoTagStyle([]);
    }
  };

  return <></>;
}
