'use client';

import MyHistoryApi from '@/api/my/history';
import {SectionHeader} from '@/components/main/common';
import {SoopUiSelectBox} from '@/components/ui';
import {useEffect, useState} from 'react';
import MyNoList from '@/components/my/common/item/NoList';
import {throwConfirm} from '@/utils/ui/dialog';
import throwToast from '@/utils/ui/toast';
import MyBroadcastLayout from '@/components/my/common/MyBroadcastLayout';
import {ErrorBoundary} from '@/components/common';
import {BROADCAST_TYPES} from '@/constants';
import {CONTEXT_MENU} from '@/constants/my/contextMenu';
import {
  BroadcastLayout,
  BroadcastType,
} from '@/components/main/common/Broadcast';

const LATER_SORT_OPTIONS = Object.freeze([
  {label: '최근 추가', type: 'reg_date'},
  {label: '조회수', type: 'read_cnt'},
  {label: 'UP수', type: 'like_cnt'},
]);

const MyHistoryLater = () => {
  const [order, setOrder] = useState(LATER_SORT_OPTIONS[0].type);
  const [broadcasts, setBroadcasts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMoreVod, setHasMoreVod] = useState(false);

  // 방송 목록 로드
  useEffect(() => {
    const fetchBroadcasts = async () => {
      const {vod, hasMore} = await MyHistoryApi.getLaterVod({
        page,
        orderByColumn: order,
      });

      setHasMoreVod(hasMore);

      if (page === 1) {
        // 1페이지라면 갈아끼우기
        setBroadcasts(vod);
      } else {
        // 1페이지보다 크다면 추가하기
        setBroadcasts((prevVod) => {
          return [...prevVod, ...vod];
        });
      }
    };

    fetchBroadcasts();
  }, [order, page]);

  /**
   * 정렬 순서 변경 핸들러
   * @param {string} type - 정렬 기준 타입
   */
  const handleOrderChange = (type) => {
    setOrder(type);
    setPage(1);
  };

  const handlePageChange = () => {
    setPage((prevPage) => prevPage + 1);
  };

  /**
   * VOD 삭제 핸들러
   * @param {'all' | 'view'} type - 삭제 타입
   */
  const handleDelete = (type) => async (e) => {
    const confirmMessage =
      type === 'view'
        ? '재생한 VOD를 모두 삭제하겠습니까?'
        : '나중에 보기 목록을 모두 삭제하겠습니까?';

    const {data: isOk} = await throwConfirm({message: confirmMessage});

    if (!isOk) {
      return;
    }

    const toastMessage =
      type === 'view'
        ? '재생한 VOD가 삭제됐습니다.'
        : '나중에 보기가 모두 삭제됐습니다.';

    try {
      const {message, no} = await MyHistoryApi.deleteLaterVod(type);
      throwToast(message || toastMessage);

      const filteredBroadcast = broadcasts.filter((broadcast) => {
        if (Array.isArray(no)) {
          return !no.includes(broadcast.titleNo);
        } else {
          return broadcast.broadcastId !== no;
        }
      });
      setBroadcasts(filteredBroadcast);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteVod = (index) => {
    const filteredBroadcast = broadcasts.filter((broadcast) => {
      return broadcast.broadcastId !== index;
    });
    setBroadcasts(filteredBroadcast);
  };

  const getContextMenu = (fileType) => {
    return fileType === 'CATCH'
      ? CONTEXT_MENU.LATER.CATCH
      : CONTEXT_MENU.LATER.VOD;
  };

  /**
   * 나중에보기 방송 삭제
   */
  const handleContextMenuClick =
    (broadcastId, broadcastType) => async (event, type) => {
      if (type !== 'delete-later') {
        return;
      }

      try {
        await MyHistoryApi.deleteLaterBroadcast(broadcastId, broadcastType);
        throwToast('나중에 보기에서 삭제됐습니다.');

        const filteredBroadcast = broadcasts.filter((broadcast) => {
          const currentBroadcastId =
            broadcastType === 'VOD' ? broadcast.titleNo : broadcast.broadNo;
          return currentBroadcastId !== broadcastId;
        });
        setBroadcasts(filteredBroadcast);
      } catch (e) {
        throwToast('에러가 발생하였습니다.');
      }
    };

  return (
    <>
      <SectionHeader
        title='나중에 보기'
        rightElement={
          <>
            <SoopUiSelectBox
              options={LATER_SORT_OPTIONS}
              type={order}
              onChange={handleOrderChange}
            />
            <button
              type='button'
              className='delete'
              onClick={handleDelete('view')}>
              재생한 VOD 삭제
            </button>
            <button
              type='button'
              className='delete'
              onClick={handleDelete('all')}>
              전체 삭제
            </button>
          </>
        }
      />
      {broadcasts?.length > 0 ? (
        <>
          <MyBroadcastLayout type='grid'>
            {broadcasts.map((broadcast) =>
              // VOD 방송 컴포넌트
              broadcast.type === 'VOD' ? (
                <ErrorBoundary key={broadcast.titleNo}>
                  <BroadcastLayout
                    broadcastType={BROADCAST_TYPES.VOD}
                    thumbnailUrl={broadcast.thumb}
                    viewCount={broadcast.readCnt}
                    createdDate={broadcast.regDate}
                    startDateTime={broadcast.broadStart}
                    userNickname={broadcast.userNick}
                    title={broadcast.titleName}
                    broadcastId={broadcast.titleNo}
                    userId={broadcast.userId}
                    hasBadge={
                      broadcast.categoryTags ||
                      broadcast.hashTags ||
                      broadcast.autoHashtags
                    }
                    categoryTags={broadcast.categoryTags}
                    hashTags={broadcast.hashTags}
                    autoHashTags={broadcast.autoHashtags}
                    vodType={broadcast.fileType}
                    categoryNo={Number(broadcast.category)}
                    accessibleAge={Number(broadcast.grade)}
                    allowsAdult={broadcast.isAdult}
                    isFan={broadcast.isFanclub}
                    isSubscribe={broadcast.isSubscribe}
                    duration={broadcast.duration}
                    contextMenu={{
                      menuList: getContextMenu(broadcast.fileType),
                    }}
                    handleMenuClicked={handleContextMenuClick(
                      broadcast.titleNo,
                      broadcast.type,
                    )}
                    authNumber={broadcast.authNo}
                    previewThumbnailUrl={broadcast.webpPath}
                    updatedDate={broadcast.laterRegDate}>
                    <BroadcastType.Vod />
                  </BroadcastLayout>
                </ErrorBoundary>
              ) : (
                // LIVE 방송 컴포넌트
                <ErrorBoundary key={broadcast.broadNo}>
                  <BroadcastLayout
                    broadcastType={BROADCAST_TYPES.LIVE}
                    thumbnailUrl={broadcast.thumb}
                    viewCount={broadcast.totalViewCnt}
                    startDateTime={broadcast.broadStart}
                    watchOrder={broadcast.orderNo}
                    userNickname={broadcast.userNick}
                    title={broadcast.broadTitle}
                    broadcastId={broadcast.broadNo}
                    userId={broadcast.userId}
                    hasBadge={
                      broadcast.categoryTags ||
                      broadcast.hashTags ||
                      broadcast.autoHashtags
                    }
                    categoryTags={broadcast.categoryTags}
                    hashTags={broadcast.hashTags}
                    autoHashTags={broadcast.autoHashtags}
                    liveType={broadcast.broadType}
                    canResend={broadcast.isVisitBroad}
                    categoryNo={Number(broadcast.broadCateNo)}
                    accessibleAge={Number(broadcast.grade)}
                    allowsAdult={broadcast.isAdult}
                    hasPassword={broadcast.isPassword}
                    isFan={broadcast.isFanclub}
                    isSubscribe={broadcast.isSubscribe}
                    contextMenu={{
                      menuList: CONTEXT_MENU.LATER.LIVE,
                    }}
                    handleMenuClicked={handleContextMenuClick(
                      broadcast.broadNo,
                      broadcast.type,
                    )}>
                    <BroadcastType.Live />
                  </BroadcastLayout>
                </ErrorBoundary>
              ),
            )}
          </MyBroadcastLayout>
          {hasMoreVod && (
            <div className='show_more'>
              <button type='button' onClick={handlePageChange}>
                더보기
              </button>
            </div>
          )}
        </>
      ) : (
        <MyNoList title='나중에 보기 한 VOD가 없습니다.' />
      )}
    </>
  );
};

export default MyHistoryLater;
