import {useState, useMemo, useEffect} from 'react';

import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import {SectionHeader} from '@/components/main/common';

/**
 * FilterBox 컴포넌트 (VOD, 게시글 칩 필터옵션영역)
 * @param title 필터 타이틀
 * @param sortItems 필터 옵션
 * @param onCallback
 * @return {JSX.Element} FilterBox 컴포넌트
 */
const FilterBox = ({title, sortItems, onCallback}) => {
  const {getParams} = useUrlParameter();
  const {tagname = ''} = getParams();

  const [isOpen, setIsOpen] = useState(false);

  // 필터옵션 유효성 검사
  const isValidSortItems = useMemo(() => {
    if (!Array.isArray(sortItems) || sortItems.length === 0) {
      console.error('FilterBox: sortItems must be a non-empty array');
      return false;
    }
    return true;
  }, [sortItems]);

  // sortItems 복사본 생성
  const initialSortItems = useMemo(() => {
    if (!isValidSortItems) {
      return [];
    }
    return sortItems.map((filterGroup) => ({
      ...filterGroup,
      options: filterGroup.options.map((item) => ({...item})),
    }));
  }, [sortItems, isValidSortItems]);

  const [localSortItems, setLocalSortItems] = useState(initialSortItems);

  // 선택된 필터 옵션
  const initialSelectedOptions = useMemo(
    () =>
      localSortItems.map((filterGroup) => ({
        key: filterGroup.key,
        ...filterGroup.options.find((option) => option.default),
      })),
    [localSortItems],
  );

  const [selectedOptions, setSelectedOptions] = useState(
    initialSelectedOptions,
  );

  const handleOptionSelection = (
    filterGroupIndex,
    optionIndex,
    filterGroupKey,
    selectedOption,
  ) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.map((prevOption) =>
        prevOption.key === filterGroupKey
          ? {key: filterGroupKey, ...selectedOption}
          : prevOption,
      ),
    );

    setLocalSortItems((prevFilterGroups) =>
      prevFilterGroups.map((filterGroup, index) =>
        index === filterGroupIndex
          ? {
              ...filterGroup,
              options: filterGroup.options.map((option, idx) => ({
                ...option,
                default: idx === optionIndex,
              })),
            }
          : filterGroup,
      ),
    );
  };

  const isPlaylistDisabled = selectedOptions.some(
    (option) => option.label === 'UP수',
  );
  const isUpDisabled = selectedOptions.some(
    (option) => option.label === '재생목록',
  );

  const getOptionClass = (option) => {
    if (option.default) return 'active';
    if (option.label === '재생목록' && isPlaylistDisabled) return 'disabled';
    if (option.label === 'UP수' && isUpDisabled) return 'disabled';
    return '';
  };

  const renderFilterGroups = () =>
    localSortItems.map((filterGroup, filterGroupIndex) => (
      <dl key={`${filterGroup.key}_${filterGroupIndex}`}>
        <dt>{filterGroup.title}</dt>
        <dd>
          <ul>
            {filterGroup.options
              .filter((option) => !(tagname && option.label === '재생목록')) // 태그검색페이지에서는 재생목록 제거
              .map((option, optionIndex) => (
                <li key={optionIndex} className={getOptionClass(option)}>
                  <button
                    type='button'
                    onClick={() =>
                      handleOptionSelection(
                        filterGroupIndex,
                        optionIndex,
                        filterGroup.key,
                        option,
                      )
                    }>
                    {option.label}
                  </button>
                </li>
              ))}
          </ul>
        </dd>
      </dl>
    ));

  useEffect(() => {
    onCallback && onCallback(selectedOptions);
  }, [selectedOptions]);

  if (localSortItems.length < 1) {
    return null;
  }

  return (
    <>
      <SectionHeader
        title={title}
        rightElement={
          <div className={`select_box_item ${isOpen ? 'on' : ''}`}>
            <button
              type='button'
              className='selected'
              onClick={() => setIsOpen((prev) => !prev)}>
              {selectedOptions.map((option, index) => (
                <span key={`select_option_${index}`} className='item'>
                  {option.label}
                </span>
              ))}
            </button>
          </div>
        }
      />
      <div className={`select_filter_list ${isOpen ? 'active' : ''}`}>
        {renderFilterGroups()}
        <button
          type='button'
          className='btn_close'
          onClick={() => setIsOpen(false)}>
          레이어 닫기
        </button>
      </div>
    </>
  );
};

export default FilterBox;
