'use client';

import MyProfileImg from '@/components/my/common/item/MyProfileImg';
import {SoopUiImg} from '@/components/ui';
import {decodeEmoticon} from '@/libs/emoticon';
import useGlobalUIStore from '@/stores';
import {useEffect, useState} from 'react';

const DEFAULT_IMAGE =
  'https://res.sooplive.co.kr/images/svg/new_post_thumb_default.svg';
const DARK_DEFAULT_IMAGE =
  'https://res.sooplive.co.kr/images/svg/new_post_thumb_default_dark.svg';

/**
 * MyPost 컴포넌트
 * @param {Object} props - 포스트 컴포넌트의 props
 * @param {string} props.url - 포스트 url
 * @param {string} props.userId - user id
 * @param {string} props.userNick - user nickname
 * @param {string} props.regDateHuman - 등록일
 * @param {string} props.titleName - 제목
 * @param {string} props.content - 내용
 * @param {string} props.photoCnt - 이미지 갯수
 * @param {Object[]} props.photos - 이미지 목록
 * @returns {JSX.Element}
 */
const MyPost = ({
  url,
  userId,
  userNick,
  regDateHuman,
  titleName,
  content,
  photoCnt,
  photos,
}) => {
  const [clientContent, setClientContent] = useState('');
  const [src, setSrc] = useState();
  const [isError, setIsError] = useState(false);
  const {isDarkMode} = useGlobalUIStore();
  const defaultImage = isDarkMode ? DARK_DEFAULT_IMAGE : DEFAULT_IMAGE;

  useEffect(() => {
    setSrc(photos?.[0]?.url);
  }, [photos]);

  useEffect(() => {
    setClientContent(content);
  }, [content]);

  const handleImageError = () => {
    setIsError(true);
    setSrc(defaultImage);
  };

  useEffect(() => {
    if (isError) {
      setSrc(defaultImage);
    }
  }, [isDarkMode]);

  return (
    <a href={url} target='_blank'>
      <div className='detail'>
        <div className='profile'>
          <MyProfileImg userId={userId} className='thumb' />
          <span className='nick'>{userNick}</span>
        </div>
        <span className='date'>{regDateHuman}</span>
      </div>
      <div className='conts'>
        <div className='info'>
          <strong className='title'>{titleName}</strong>
          <p
            className='txt'
            dangerouslySetInnerHTML={{
              __html: decodeEmoticon(clientContent),
            }}></p>
        </div>
        {src && (
          <div className='thumb'>
            <SoopUiImg src={src} onError={handleImageError} />
            <span className='num'>{photoCnt}</span>
          </div>
        )}
      </div>
    </a>
  );
};

export default MyPost;
