// type : vod, playlist, catch, catchstory, live, post, photo
import globalDomains from '@/utils/global-domains';
import {scheme} from '@afreecatv/domains';
import {BROADCAST_TYPES} from '@/constants';

import useSharePopupStore from '@/stores/ui/share-popup';
import {uppercaseObjectValue} from '..';

const KAKAO_TEMPLATE = {
  LIVE: 78649,
  VOD: 78660,
  POST: 78575,
};

const processedBroadcastTypes = uppercaseObjectValue(BROADCAST_TYPES);

const getShareUrl = ({
  userId,
  broadcastId,
  playlistId,
  shareUrl,
  broadcastType,
  vodType = '',
}) => {
  const processedVodType = vodType.toUpperCase();

  const baseVodUrl = `${globalDomains.VOD_AFREECATV}/player/${broadcastId}`;
  const baseLiveUrl = `${globalDomains.PLAY_AFREECATV}/${userId}/${broadcastId}`;

  // broadcastType별로 처리
  switch (broadcastType) {
    case processedBroadcastTypes.LIVE:
      return baseLiveUrl;

    case processedBroadcastTypes.VOD:
      if (processedVodType === processedBroadcastTypes.CATCH) {
        return `${baseVodUrl}/catch`;
      }
      if (processedVodType === processedBroadcastTypes.CATCH_STORY) {
        return `${baseVodUrl}/catchstory`;
      }
      if (processedVodType === processedBroadcastTypes.PLAYLIST) {
        return shareUrl
          ? `${shareUrl.match(/http(s)?:/g) ? '' : window.location.protocol}${shareUrl}`
          : `${baseVodUrl}/playlist/${playlistId}`;
      }
      return baseVodUrl;

    default:
      return shareUrl;
  }
};

export const generateUrlForShare = (
  {
    type = '', // live, vod, catch, catchstory, playlist, post, photo
    title,
    content,
    count,
    thumbnailUrl,
    userId,
    broadcastId,
    appScheme,
    stationNo,
    titleNo,
    playlistId, // playlistIdx, playlistNumber, ... Id 로 통일
    vodType,
  },
  shareUrl,
) => {
  const processedType = type ? type.toUpperCase() : '';

  // 공유하기 레이어에서는 최소한의 작업 (on, off)만 하기 위함 (막 props로 주렁 주렁 전달 후 조합하기 싫다는 소리 )
  const getTemplateId = (type) => {
    switch (type) {
      case processedBroadcastTypes.LIVE:
        return KAKAO_TEMPLATE.LIVE;
      case processedBroadcastTypes.VOD:
        return KAKAO_TEMPLATE.VOD;
      default:
        return KAKAO_TEMPLATE.POST;
    }
  };

  const kakaoParams = {
    templateId: getTemplateId(processedType),
  };

  let subParams = {};

  const url = getShareUrl({
    userId,
    broadcastId,
    playlistId,
    shareUrl,
    broadcastType: processedType,
    vodType,
  });

  if (processedType === processedBroadcastTypes.LIVE) {
    subParams = {
      title: title,
      imageUrl: thumbnailUrl,
      bjId: userId,
      broadNumber: broadcastId,
      scheme:
        appScheme ||
        `${scheme.SERVICE_APP_SCHEME}player/live?broad_no=${broadcastId}&user_id=${userId}`,
    };
  } else if (processedType === 'post' || processedType === 'photo') {
    subParams = {
      title: title,
      description: content ? content.replace(/(<([^>]+)>)/gi, '') : '',
      count: count,
      imageUrl: thumbnailUrl,
      bjId: userId,
      videoNumber: 'post/' + titleNo,
      scheme: appScheme,
    };
  } else {
    const stationNoParam = stationNo ? `&station_no=${stationNo}` : '';
    subParams = {
      title: title,
      imageUrl: thumbnailUrl,
      bjId: userId,
      videoNumber: broadcastId,
      playerType:
        processedType === processedBroadcastTypes.CATCH ||
        processedType === processedBroadcastTypes.CATCH_STORY
          ? `/${processedType}`
          : '',
      scheme:
        appScheme ||
        `${scheme.SERVICE_APP_SCHEME}player/video?title_no=$broadcastId}&user_id=${userId}&type=${processedType === processedBroadcastTypes.VOD ? 'REVIEW' : processedType}${stationNoParam}`,
    };
  }
  kakaoParams.templateArgs = subParams;


  return {
    pageUrl: url,
    kakaoParams: kakaoParams,
  };
};

const throwSharePopup = ({
  title,
  pageUrl,
  kakaoParams,
  parentPosition = {top: 0, left: 0},
}) => {
  console.log(
    'throwSharePopup :: ',
    parentPosition,
    title,
    pageUrl,
    kakaoParams,
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useSharePopupStore
    .getState()
    .showSharePopup({title, pageUrl, kakaoParams, parentPosition});
};
export default throwSharePopup;
