'use client';
import {useCallback} from 'react';

import {
  VodGroup,
  PostGroup,
  LiveGroup,
  CatchGroup,
  SearchDirectLinkGroup,
  RecommededStreamerGroup,
  SameNameGroup,
  RelatedSearchTermsGroup,
  CatchStoryGroup,
  SearchThemeGroup,
  LatestVideoGroup,
  TagSearchTagTrend,
  AdvertisementGroup,
  SuggestKeywordGroup,
} from '@/components/search/group';
import MyTabGroup from '@/components/my/common/group/TabGroup';
import {useScrollTop} from '@/components/main/common/hooks/useScrollTop';
import {ErrorBoundary} from '@/components/common';
import {StreamerGroup} from '@/components/search/group/streamer-group';
import {FloatingPortal} from '@/components/main/common';
import {NoSearchResults} from '@/components/search/common';
import {GameProfileGroup} from '@/components/search/group/game-profile-group';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {StreamerProfileGroup} from '@/components/search/group/streamer-profile-group';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';
import {RecommendContentsGroup} from '@/components/search/group/recommend-contents-group/index';

const SearchHome = ({
  className,
  currentTab,
  live,
  streamerList,
  vod,
  postList,
  catchList,
  catchStory,
  profileTheme,
  relatedWord,
  stopWord,
  keyword,
  searchType,
  hashType,
  tagname,
  fetchGameProfileThemeStreamerList,
  fetchGameScheduleList,
}) => {
  const {sendTabClickLog} = useSearchClickLog();
  const {
    sessionKey,
    groupVisibility,
    stopWordLoading,
    gameProfileLoading,
    liveLoading,
    vodLoading,
    postLoading,
    streamerLoading,
    isTotalPage,
    isLiveTagSearchPage,
    isVodTagSearchPage,
  } = useSearchGlobalStatus();
  const {
    visibilitySuggestKeywordGroup,
    visibilityStreamerProfileGroup,
    visibilityDirectGroup,
    visibilityThemeGroup,
    visibilityAdvertisementGroup,
    visibilityGameProfileGroup,
    visibilityLatestVideoGroup,
    visibilitySameNameGroup,
    visibilityRecommendStreamerGroup,
    visibilitRecommendContentsGroup,
    visibilityLiveGroup,
    visibilityCatchStroyGroup,
    visibilityVodGroup,
    visibilityCatchGroup,
    visibilityPostGroup,
    visibilityStreamerGroup,
    visibilityTagSearchGroup,
    visibilityRelatedSearchKeywordGroup,
  } = groupVisibility(searchType, hashType);

  const {isDisplay, scrollToTop} = useScrollTop();

  const isEmpty =
    (live?.length ?? 0) === 0 &&
    (streamerList?.length ?? 0) === 0 &&
    (vod?.length ?? 0) === 0 &&
    (postList?.length ?? 0) === 0 &&
    (catchList?.length ?? 0) === 0 &&
    (catchStory?.length ?? 0) === 0 &&
    (profileTheme?.recommendContents?.length ?? 0) === 0 &&
    (profileTheme?.homonym?.length ?? 0) === 0 &&
    (profileTheme?.gameProfile?.length ?? 0) === 0 &&
    (profileTheme?.esport?.length ?? 0) === 0 &&
    (profileTheme?.profile?.length ?? 0) === 0 &&
    (profileTheme?.recommendBj?.length ?? 0) === 0 &&
    (profileTheme?.relatedBj?.length ?? 0) === 0 &&
    (profileTheme?.theme?.length ?? 0) === 0 &&
    (profileTheme?.themeShortcuts?.length ?? 0) === 0 &&
    (profileTheme?.themeVod?.length ?? 0) === 0 &&
    stopWordLoading !== true &&
    gameProfileLoading !== true &&
    liveLoading !== true &&
    vodLoading !== true &&
    postLoading !== true &&
    streamerLoading !== true &&
    Object.keys(profileTheme?.latestVod ?? {}).length === 0;

  // SCKACT 로그
  const handleLogCallback = useCallback(
    (nextTab) => {
      let newTab = 'total';
      switch (nextTab) {
        case '통합검색':
          newTab = 'total';
          break;
        case 'LIVE':
          newTab = 'live';
          break;
        case 'VOD':
          newTab = 'vod';
          break;
        case '게시글':
          newTab = 'post';
          break;
        case '스트리머':
          newTab = 'bj';
          break;
      }
      let params = {
        tab: isLiveTagSearchPage
          ? 'live'
          : isVodTagSearchPage
            ? 'vod'
            : searchType,
        sectionName: newTab,
        acttype: 'tab',
        location: isTotalPage ? 'total_search' : 'hash',
        actCode: 'tab',
        sessionKey: sessionKey,
      };

      sendTabClickLog(params);
    },
    [sessionKey, searchType, hashType],
  );

  const renderTotalSectionNoResult = () => {
    return (
      <NoSearchResults
        searchTab='total'
        isKeywordEntered={Boolean(keyword)}
        stopWord={stopWord.stopword}
      />
    );
  };

  const shouldRenderNoResult =
    isTotalPage && (isEmpty || !keyword || stopWord.stopword);

  return (
    <>
      <div className='container_flex_wrap'>
        <MyTabGroup
          className='dept2_menu_wrap'
          type='search_route'
          tabs={currentTab}
          onHandle={(param) => {
            handleLogCallback(param);
          }}
        />
        <div className={'container_wrap'}>
          <div id='container' className={`_search ${className}`}>
            {shouldRenderNoResult ? (
              renderTotalSectionNoResult()
            ) : (
              <>
                {/* 검색어 제안 */}
                {visibilitySuggestKeywordGroup && (
                  <ErrorBoundary>
                    <SuggestKeywordGroup stopWordInfo={stopWord} />
                  </ErrorBoundary>
                )}

                {/* 바로가기 */}
                {visibilityDirectGroup && (
                  <ErrorBoundary>
                    <SearchDirectLinkGroup {...profileTheme} />
                  </ErrorBoundary>
                )}

                {/* 스트리머 프로필 */}
                {visibilityStreamerProfileGroup &&
                  profileTheme.profile &&
                  profileTheme.profile.length > 0 && (
                    <ErrorBoundary>
                      <StreamerProfileGroup {...profileTheme} />
                    </ErrorBoundary>
                  )}

                {/* 광고 영역 */}
                {visibilityAdvertisementGroup && (
                  <ErrorBoundary>
                    <AdvertisementGroup {...profileTheme} />
                  </ErrorBoundary>
                )}

                {/* 검색 키워드 기준 최신 영상 */}
                {visibilityLatestVideoGroup &&
                  profileTheme?.latestVod?.length > 0 && (
                    <ErrorBoundary>
                      <LatestVideoGroup
                        latestVideoData={profileTheme.latestVod}
                        title={`${profileTheme.profile[0]?.userNick || ''}의 최신 영상`}
                        section='streamer'
                      />
                    </ErrorBoundary>
                  )}

                {/* 테마 영역 */}
                {visibilityThemeGroup && (
                  <ErrorBoundary>
                    <SearchThemeGroup {...profileTheme} />
                  </ErrorBoundary>
                )}

                {/* 테마 영역의 최신영상*/}
                {visibilityLatestVideoGroup &&
                  profileTheme?.themeVod?.length > 0 && (
                    <ErrorBoundary>
                      <LatestVideoGroup
                        latestVideoData={profileTheme.themeVod[0].contents}
                        title={`${profileTheme.themeVod[0]?.searchNick || ''}의 최신 영상`}
                        section='theme'
                      />
                    </ErrorBoundary>
                  )}

                {/* 게임 프로필 */}
                {visibilityGameProfileGroup && (
                  <ErrorBoundary>
                    <GameProfileGroup
                      gameProfileList={profileTheme.gameProfile}
                      esportList={profileTheme.esport}
                      fetchGameProfileThemeStreamerList={
                        fetchGameProfileThemeStreamerList
                      }
                      fetchGameScheduleList={fetchGameScheduleList}
                    />
                  </ErrorBoundary>
                )}

                {/* 앗 혹시 찾으시는 분이...(= 동명이인 영역) */}
                {visibilitySameNameGroup && (
                  <ErrorBoundary>
                    <SameNameGroup {...profileTheme} />
                  </ErrorBoundary>
                )}

                {/* 관련 스트리머 */}
                {visibilityRecommendStreamerGroup && (
                  <ErrorBoundary>
                    <RecommededStreamerGroup
                      data={profileTheme.recommendBj}
                      section='streamer'
                    />
                  </ErrorBoundary>
                )}

                {/* 테마 영역의 관련 스트리머*/}
                {visibilityLatestVideoGroup && (
                  <ErrorBoundary>
                    <RecommededStreamerGroup
                      data={profileTheme.themeBj}
                      section='theme'
                    />
                  </ErrorBoundary>
                )}

                {/* 검색 라이브 영역*/}
                {visibilityLiveGroup && (
                  <ErrorBoundary>
                    <LiveGroup live={live} stopWord={stopWord.stopword} />
                  </ErrorBoundary>
                )}

                {/* 관련 콘텐츠 */}
                {visibilitRecommendContentsGroup && (
                  <ErrorBoundary>
                    <RecommendContentsGroup {...profileTheme} />
                  </ErrorBoundary>
                )}

                {/* 캐치 스토리 */}
                {visibilityCatchStroyGroup && (
                  <ErrorBoundary>
                    <CatchStoryGroup catchStory={catchStory} />
                  </ErrorBoundary>
                )}

                {/* 캐치 - 캐치스토리가 없으면 해당 위치에 캐치 노출*/}
                {catchStory.length === 0 && visibilityCatchGroup && (
                  <ErrorBoundary>
                    <CatchGroup catchList={catchList} />
                  </ErrorBoundary>
                )}

                {/* VOD */}
                {visibilityVodGroup && (
                  <ErrorBoundary>
                    <VodGroup vod={vod} stopWord={stopWord.stopword} />
                  </ErrorBoundary>
                )}

                {/* 캐치 - 캐치스토리가 있으면 해당 위치에 캐치 노출*/}
                {catchStory.length > 0 && visibilityCatchGroup && (
                  <ErrorBoundary>
                    <CatchGroup catchList={catchList} />
                  </ErrorBoundary>
                )}

                {/* 게시글 */}
                {visibilityPostGroup && (
                  <ErrorBoundary>
                    <PostGroup post={postList} stopWord={stopWord.stopword} />
                  </ErrorBoundary>
                )}

                {/* 스트리머 */}
                {visibilityStreamerGroup && (
                  <ErrorBoundary>
                    <StreamerGroup
                      streamer={streamerList}
                      stopWord={stopWord.stopword}
                    />
                  </ErrorBoundary>
                )}

                {/* 태그 검색 */}
                {visibilityTagSearchGroup &&
                  (isLiveTagSearchPage ? (
                    <ErrorBoundary>
                      <LiveGroup live={live} stopWord={stopWord.stopword} />
                    </ErrorBoundary>
                  ) : (
                    isVodTagSearchPage && (
                      <ErrorBoundary>
                        <VodGroup vod={vod} stopWord={stopWord.stopword} />
                      </ErrorBoundary>
                    )
                  ))}
              </>
            )}
          </div>

          {/* 태그 트랜드 */}
          {visibilityTagSearchGroup && (
            <ErrorBoundary>
              <TagSearchTagTrend />
            </ErrorBoundary>
          )}
          {/* 연관검색어 */}
          {visibilityRelatedSearchKeywordGroup && (
            <ErrorBoundary>
              <RelatedSearchTermsGroup relatedWord={relatedWord} />
            </ErrorBoundary>
          )}
        </div>
      </div>

      {/* 스크롤 탑 버튼*/}
      <FloatingPortal className={'floating_btns'} target={'soop_wrap'}>
        <button
          type='button'
          className={`page_top ${isDisplay ? 'active' : ''}`}
          onClick={scrollToTop}>
          상단 바로가기
        </button>
      </FloatingPortal>
    </>
  );
};

export default SearchHome;
