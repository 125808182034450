'use client';
import {FloatingPortal} from '@/components/main/common';
import {FloatingButton} from '@/components/main/MyPlus/FloatingButton';
import {SetList} from '@/components/main/MyPlus/SetList';

export const FloatingGroup = ({selectedMode, handleOnClickMode}) => {
  return (
    <>
      <FloatingPortal
        className={'_Modal_UI_Wrap _set_list'}
        target={'soop_wrap'}>
        <SetList />
      </FloatingPortal>
      <FloatingPortal className={'floating_btns'} target={'soop_wrap'}>
        <FloatingButton
          selectedMode={selectedMode}
          handleOnClickMode={handleOnClickMode}
        />
      </FloatingPortal>
    </>
  );
};
