'use client';

const NoFeedList = () => {
  return (
    <>
      <div className='nt_area'>
        <p className='txt01'>즐겨찾기한 스트리머가 없습니다.</p>
        <p className='txt02'>
          좋아하는 스트리머를 즐겨찾기 하면 언제든 편하게 방송을 찾아 볼 수
          있으며,
          <br />
          방송 시작 시 알림을 받을 수 있습니다.{' '}
        </p>
      </div>
    </>
  );
};

export default NoFeedList;
