'use client';
import {useRef} from 'react';

import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import {StreamerAvatar} from '@/components/search/common';
import {SoopUiProfileIconMenu} from '@/components/ui';

import useFavoriteStore from '@/stores/search';
import {convertViewCnt} from '@/utils/main';

/**
 * StreamerListItem 컴포넌트 ( 동명이인 && 추천 스트리머 && 게임프로필 > 관련 스트리머 && 관련 콘텐츠 영역에 사용 )
 * @param {Object} props - SameNameList props
 * @param {string} userId - 스트리머 아이디
 * @param {string} userNick - 스트리머 닉네임
 * @param {string} favoriteCnt - 스트리머 애청자수
 * @param {string} broadNo - 스트리머 실시간 방송번호
 * @param {boolean} isLinkTag - 닉네임에 Link태그 필요 여부
 * @param {boolean} showButton - 검색 버튼 필요 여부
 * @param {Function} logCallback 로그 관련 콜백 함수
 * @param {string} searchArea 페이지 내 어떤 영역에서 검색했는지 ( SCK2 로그 관련 )
 * @returns {JSX.Element} StreamerListItem 컴포넌트
 */
const StreamerListItem = ({
  userId,
  userNick,
  favoriteCnt,
  broadNo,
  liveBroadNo,
  showButton = false,
  logCallback = () => {},
  searchArea,
}) => {
  // 동적으로 바뀌는 parameter들
  const {getParams} = useUrlParameter();
  const {szKeyword: keyword = ''} = getParams();
  const {toggleFavorite, setFavoriteStreamerName} = useFavoriteStore();
  const soopUiProfileIconMenu = useRef(null);

  /**
   * 프로필 ict 메뉴의 이벤트가 발생했을 때 트리거
   * @param {EventData} event
   */
  const handleProfileIconMenuEvent = async (event) => {
    const {eventName, data} = event;

    if (eventName === 'deleteFavorite') {
      toggleFavorite();
      setFavoriteStreamerName(data.id);
    }
  };

  // SCKACT 로그
  const handleStreamerActionLog = (type) => {
    let param = type ? {type: type} : '';
    logCallback && logCallback(param);
  };

  // 동명이인 영역에서 검색 버튼 클릭시 페이지 이동
  const handleSearchByStreamerId = () => {
    const searchName = keyword === userNick ? userId : userNick;
    const queryString = new URLSearchParams({
      szLocation: 'total_search',
      szSearchType: 'total',
      acttype: 'homonym',
      szStype: 'homonym',
      szKeyword: searchName,
      szVideoFileType: 'ALL',
      rs: '1',
    });
    window.location.href = `/search?${queryString}`;
    handleStreamerActionLog('search');
  };

  return (
    <>
      <StreamerAvatar
        userId={userId}
        broadNo={broadNo || liveBroadNo}
        liveBroadNo={liveBroadNo}
        className='thumb'
        logCallback={() => {
          handleStreamerActionLog('sti');
        }}
      />

      <div className='details'>
        <a
          href='#'
          className='nick'
          onClick={(e) => {
            e.preventDefault();
            soopUiProfileIconMenu.current?.open();
            handleStreamerActionLog('nick');
          }}>
          <span>{userNick}</span>
        </a>

        <SoopUiProfileIconMenu
          ref={soopUiProfileIconMenu}
          streamerId={userId}
          streamerNick={userNick}
          handleMenuButtonClicked={handleProfileIconMenuEvent}
          searchArea={searchArea}
        />

        {/* 애청자  */}
        <div className='fav'>
          <span className='txt'>애청자</span>
          <span className='num'>{convertViewCnt(favoriteCnt)}</span>
        </div>
      </div>

      {/* 동명이인 영역의 경우 검색버튼 노출 */}
      {showButton && (
        <button
          type='button'
          className='search'
          onClick={handleSearchByStreamerId}>
          검색
        </button>
      )}
    </>
  );
};

export default StreamerListItem;
