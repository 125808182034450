'use client';

import '/public/static/css/module/lnb.scss';
import '/public/static/css/soop.scss';
import '/public/static/globals.scss';

import React, {Suspense, useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

import HelmetLoader from '@/app/(main)/HelmetLoader';
import MyPlus from '@/app/(main)/my-plus/index';
import DirectoryRoute from '@/app/(main)/routes/DirectoryRoute';
import LiveRoute from '@/app/(main)/routes/LiveRoute';
import MyRoute from '@/app/(main)/routes/MyRoute';
import PrivateRoute from '@/app/(main)/routes/PrivateRoute';
import SearchRoute from '@/app/(main)/routes/SearchRoute';
import {BodyStyler} from '@/components/common';
import {
  SoopUiDialog,
  SoopUiToast,
  SoopUiSharePopup,
  SoopUiShare,
} from '@/components/ui';
import {getCookie} from '@/utils/main';
import NotFound from '@/app/(main)/routes/not-found';
import Layout from '@/app/(main)/routes/layout';
import {ROUTE} from '@/constants/main/route';
import useGlobalUIStore from '@/stores';
import {blockDuringMaintenance, setPosterImageObserver} from '@/utils';
import ResizeObserver from 'resize-observer-polyfill';

// TODO: 타이틀 디스크립션 추가 (현재 ssr 제거해서 meta 안됨)
// export const metadata = {
//   title: 'PoC For SOOP_KR',
//   description: 'Generated by create next app',
// };
const NavigationLoader = React.lazy(
  () => import('@/app/(main)/NavigationLoader'),
);

/**
 * 사파리 15 버전 이하에 특정 클래스를 추가
 *
 * @desc UI팀에서 사파리 이전 버전 대응을 위해 특정 클래스를 추가해 달라는 요청이 옴 (사파리 15 버전이하로 넣어달라고 하심)
 */
const addIos15LessClassForSafari = () => {
  const userAgent = navigator.userAgent;
  const safariMatch = userAgent.match(/Version\/(\d+)\.(\d+).*Safari/);

  if (safariMatch) {
    const majorVersion = parseInt(safariMatch[1], 10);

    // Safari 15.x
    if (majorVersion <= 15) {
      document.body.classList.add('ios15_less');
    }
  }
};

//pathname 변경될때마다 자동으로 페이지 scrollTop
const ScrollToTop = ({onPathName, onSearch}) => {
  const {pathname, search} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    onPathName && onPathName(pathname);
    onSearch && onSearch(search);
  }, [pathname, search]);

  return null;
};

export default function RootLayout({children}) {
  const [render, setRender] = useState(false);
  //브라우저 탭 - 이름 변경을 위한 state
  const [routeName, setRouteName] = useState('');
  const [search, setSearch] = useState('');
  const {setIsDarkMode} = useGlobalUIStore();

  const precheckDarkThemeBeforeSoopNavigation = () => {
    const isDark = getCookie('theme') === 'dark';
    setIsDarkMode(isDark);
    return isDark;
  };
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document
        .querySelector('html')
        .setAttribute(
          'dark',
          precheckDarkThemeBeforeSoopNavigation() ? 'true' : '',
        );

      //일정 버전 미만에서는 ResizeObserver를 지원하지않아서
      if (!window.ResizeObserver) {
        window.ResizeObserver = ResizeObserver;
      }

      // blockDuringMaintenance();
    }
    setRender(true);
    addIos15LessClassForSafari();
    setPosterImageObserver();
  }, []);

  return (
    <html lang='ko'>
      <HelmetLoader routeName={routeName} search={search} />
      <body>
        {render && (
          <Router>
            <ScrollToTop onPathName={setRouteName} onSearch={setSearch} />
            {/* MEMO: 현 시점에서는 Node 서버를 띄우지 않고 정적 배포해달라는 요청에 react router dom 으로 하였으나 추후 Next.js 에 맞게 변경이 필요하다. 변경 시 layout 에서 children 을 받아와서 그대로 뿌려주면 된다
             */}

            <Routes>
              <Route element={<Layout />}>
                <Route exact path='/' element={<MyPlus />} />
                <Route path={ROUTE.SEARCH} element={<SearchRoute />} />
                <Route path='/directory/*' element={<DirectoryRoute />} />
                <Route path='/live/*' element={<LiveRoute />} />
                <Route
                  path='/my/*'
                  element={
                    <PrivateRoute>
                      <MyRoute />
                    </PrivateRoute>
                  }
                />
              </Route>
              <Route path='*' element={<NotFound />} />
            </Routes>
            {/* 추후 진정한 next 로 수정이 된다면 이것을 살리면 된다 */}
            {/* {children} */}
            <SoopUiDialog />
            <SoopUiToast />
            <SoopUiShare />
            <SoopUiSharePopup />

            <BodyStyler />
          </Router>
        )}
      </body>
    </html>
  );
}
