import {createContext, useMemo, useCallback, useContext, useState} from 'react';
import {getUserCookie} from '@/utils/main';
import useUrlParameter from './common/hooks/useUrlParameter';

// 컨텍스트 생성
const SearchGlobalStatusContext = createContext(null);

export const useSearchGlobalStatus = () => {
  return useContext(SearchGlobalStatusContext);
};

const initialState = {
  pageNumber: {
    total: 1,
    broad: 1,
    vod: 1,
    post: 1,
    streamer: 1,
  },
  perPage: 12,
  gameProfileOrder: [{value: 'current_sum_viewer', orderType: 'desc'}],
  gameProfileSize: 10,
  liveOrder: [
    {
      label: '정확도',
      type: 'score',
      value: 'score',
      default: true,
    },
  ],
  vodOrder: [{label: '정확도', type: 'score', value: 'score', default: true}],
  vodDetailOrder: '',
  postOrder: [
    {
      label: '조회수',
      type: 'view_cnt_up',
      value: 'view_cnt',
      orderType: 'desc',
      default: false,
    },
  ],
  postDetailOrder: '',
  streamerOrder: '',
  stopWordLoading: false,
  gameProfileLoading: false,
  liveLoading: false,
  vodLoading: false,
  postLoading: false,
  streamerLoading: false,
  noResult: {
    total: false,
    live: false,
    vod: false,
    post: false,
    streamer: false,
  },
};

const SearchProvider = ({children}) => {
  const {getParams} = useUrlParameter();
  const {
    szSearchType: searchType = '',
    hashtype: hashType = '',
    hash: hashParam = '',
  } = getParams();

  const pageTypes = useMemo(() => {
    const isTotalPage = searchType === 'total'; // 통합검색칩
    const isLivePage = searchType === 'broad'; // LIVE칩
    const isVodPage = searchType === 'vod'; // VOD칩
    const isPostPage = searchType === 'post'; // 게시글칩
    const isStreamerPage = searchType === 'streamer'; // 스트리머칩
    const isLiveTagSearchPage = hashType === 'live' || (!searchType && !hashType); // 태그검색(LIVE)
    const isVodTagSearchPage = hashType === 'vod' || hashType === 'catch'; // 태그검색(VOD)

    return {
      isTotalPage,
      isLivePage,
      isVodPage,
      isPostPage,
      isStreamerPage,
      isLiveTagSearchPage,
      isVodTagSearchPage,
    };
  }, [searchType, hashType, hashParam]);

  // 현재 활성 탭 상태 추가 (예: 'live', 'vod', 'post', 'streamer')
  const [activeTab, setActiveTab] = useState('total');
  const [pageNumber, setPageNumber] = useState(initialState.pageNumber);
  const [perPage, setPerPage] = useState(initialState.perPage);
  const [gameProfileOrder, setGameProfileOrder] = useState(
    initialState.gameProfileOrder,
  );
  const [gameProfileSize, setGameProfileSize] = useState(
    initialState.gameProfileSize,
  );
  const [liveOrder, setLiveOrder] = useState(initialState.liveOrder);
  const [vodOrder, setVodOrder] = useState(initialState.vodOrder);
  const [vodDetailOrder, setVodDetailOrder] = useState(
    initialState.vodDetailOrder,
  );
  const [postOrder, setPostOrder] = useState(initialState.postOrder);
  const [postDetailOrder, setPostDetailOrder] = useState(
    initialState.postDetailOrder,
  );
  const [streamerOrder, setStreamerOrder] = useState(
    initialState.streamerOrder,
  );
  const [stopWordLoading, setStopWordLoading] = useState(
    initialState.stopWordLoading,
  );
  const [gameProfileLoading, setGameProfileLoading] = useState(
    initialState.gameProfileLoading,
  );
  const [liveLoading, setLiveLoading] = useState(initialState.liveLoading);
  const [vodLoading, setVodLoading] = useState(initialState.vodLoading);
  const [postLoading, setPostLoading] = useState(initialState.postLoading);
  const [streamerLoading, setStreamerLoading] = useState(
    initialState.streamerLoading,
  );
  const [noResult, setNoResult] = useState(initialState.noResult);
  const [hasMore, setHasMore] = useState({
    live: false,
    vod: false,
    post: false,
    streamer: false,
  });
  const [sessionKey, setSessionKey] = useState(null);

  const initFilterOption = {
    live: [
      {
        label: '정확도',
        type: 'score',
        value: 'score',
        default: true,
      },
    ],
    liveHash: [
      {
        label: '참여자수(높은순)',
        type: 'view_cnt_up',
        value: 'view_cnt',
        orderType: 'desc',
        default: true,
      },
    ],
    vod: [
      {
        key: 'sort',
        label: '정확도',
        type: 'score',
        value: 'score',
        default: true,
      },
    ],
    vodTab: [
      {
        tab: 'vod',
        label: '정확도',
        type: 'score',
        value: 'score',
        default: true,
      },
      {label: '전체', type: 'ALL', value: 'ALL', default: true},
      {label: '1년', type: '1year', value: '1year', default: true},
    ],
    vodHash: [
      {
        tab: 'vod',
        label: '조회수',
        type: 'view_cnt_up',
        value: 'view_cnt',
        default: true,
      },
      {label: '전체', type: 'ALL', value: 'ALL', default: true},
      {label: '1주', type: '1week', value: '1week', default: true},
    ],
    post: [
      {
        label: '조회수',
        type: 'view_cnt_up',
        value: 'view_cnt',
        orderType: 'desc',
        default: true,
      },
    ],
    postTab: [
      {
        tab: 'post',
        label: '조회수',
        type: 'view_cnt',
        value: 'view_cnt',
        default: true,
      },
      {label: '1개월', type: '1month', value: '1month', default: true},
    ],
    streamer: [
      {
        key: 'sort',
        label: '정확도',
        type: 'score',
        value: 'score',
        default: true,
      },
    ],
  };

  const groupVisibility = (searchType, hashType) => {
    return {
      visibilitySuggestKeywordGroup: searchType === 'total',
      visibilityStreamerProfileGroup: searchType === 'total',
      visibilityDirectGroup: searchType === 'total',
      visibilityAdvertisementGroup: searchType === 'total',
      visibilityThemeGroup: searchType === 'total',
      visibilityGameProfileGroup: searchType === 'total',
      visibilityLatestVideoGroup: searchType === 'total',
      visibilitySameNameGroup: searchType === 'total',
      visibilityRecommendStreamerGroup: searchType === 'total',
      visibilitRecommendContentsGroup: searchType === 'total',
      visibilityLiveGroup: searchType === 'total' || searchType === 'broad',
      visibilityCatchStroyGroup: searchType === 'total',
      visibilityVodGroup: searchType === 'total' || searchType === 'vod',
      visibilityCatchGroup: searchType === 'total',
      visibilityPostGroup: searchType === 'total' || searchType === 'post',
      visibilityStreamerGroup:
        searchType === 'total' || searchType === 'streamer',
      visibilityTagSearchGroup:
        hashType === 'live' || hashType === 'vod' || hashType === 'catch',
      visibilityRelatedSearchKeywordGroup:
        searchType === 'total' ||
        searchType === 'broad' ||
        searchType === 'vod' ||
        searchType === 'post' ||
        searchType === 'streamer',
    };
  };

  // 탭별 페이지 번호 초기화 함수
  const resetPageNumber = useCallback((tab) => {
    if (tab === 'all') {
      setPageNumber(initialState.pageNumber);
    } else {
      setPageNumber((prev) => ({...prev, [tab]: 1}));
    }
  }, []);

  // 탭별 결과없음 변경 함수
  const updateNoResult = useCallback((tab, newValue) => {
    setNoResult((prevState) => ({
      ...prevState,
      [tab]: newValue,
    }));
  }, []);

  const prevPageNumber = () => {
    setPageNumber((currentPageNumber) => currentPageNumber - 1);
  };

  // 무한 스크롤을 위한 페이지 번호 증가 함수
  const incrementPageNumber = useCallback((tab) => {
    setPageNumber((prev) => ({...prev, [tab]: prev[tab] + 1}));
  }, []);

  const handleChangeStatus = (
    type,
    _perPage = 12,
    _order = '',
    isDetail = false,
  ) => {
    resetPageNumber(type);
    setPerPage(_perPage);
    switch (type) {
      case 'gameProfile':
        setGameProfileOrder(_order);
        break;
      case 'live':
        setLiveOrder(_order);
        break;
      case 'vod':
        isDetail ? setVodDetailOrder(_order) : setVodOrder(_order);
        break;
      case 'post':
        isDetail ? setPostDetailOrder(_order) : setPostOrder(_order);
        break;
      case 'streamer':
        setStreamerOrder(_order);
        break;
      default:
        break;
    }
  };

  const handleSetLoading = (type, isLoading) => {
    switch (type) {
      case 'stopWord':
        setStopWordLoading(isLoading);
        break;
      case 'gameProfile':
        setGameProfileLoading(isLoading);
        break;
      case 'live':
        setLiveLoading(isLoading);
        break;
      case 'vod':
        setVodLoading(isLoading);
        break;
      case 'post':
        setPostLoading(isLoading);
        break;
      case 'streamer':
        setStreamerLoading(isLoading);
        break;
      default:
        break;
    }
  };

  // 로그인한 유저 정보
  const loginUserId = getUserCookie('uid');

  return (
    <SearchGlobalStatusContext.Provider
      value={{
        initFilterOption,
        currentPageNumber: pageNumber,
        perPage,
        groupVisibility,
        hasMore,
        onHasMore: setHasMore,
        gameProfileOrder,
        gameProfileSize,
        setGameProfileSize,
        setGameProfileOrder,
        liveOrder,
        vodOrder,
        vodDetailOrder,
        postOrder,
        postDetailOrder,
        streamerOrder,
        stopWordLoading,
        gameProfileLoading,
        liveLoading,
        vodLoading,
        postLoading,
        streamerLoading,
        currentLoginUserId: loginUserId,
        setNoResult: updateNoResult,
        noResult,
        setLoading: handleSetLoading,
        changeStatus: handleChangeStatus,
        resetPageNumber,
        nextPage: incrementPageNumber,
        prevPageNumber: prevPageNumber,
        activeTab,
        setActiveTab,
        sessionKey,
        setSessionKey,
        ...pageTypes,
      }}>
      {children}
    </SearchGlobalStatusContext.Provider>
  );
};

export default SearchProvider;
