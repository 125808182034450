'use client';

import CatchGroup from '@/components/my/common/group/CatchGroup';
import {throwDialog} from '@/utils/ui/dialog';

const CATCH_CONTEXT_MENU = [
  {
    label: '공유하기',
    type: 'share',
  },
  {
    label: '즐겨찾기 그룹에 담기',
    type: 'add-favorite',
  },
];

/**
 * 즐겨찾기 캐치 컴포넌트
 * @desc 스토리들을 받아서 렌더링 하고, 각 컨텍스트 메뉴를 띄워서 이벤트 핸들링을 한다
 * @param {Object[]} catches - 캐치 목록
 * @param {boolean} isStory - 캐치 스토리 여부
 * @returns {JSX.Element} 캐치스토리
 */
const MyFavoriteCatch = ({catches, isStory = false}) => {
  const handleMenuClicked = (event, type, data, buttonRef) => {
    if (type === 'add-favorite') {
      throwDialog(
        'my/FavoriteGroup',
        {
          userId: data.userId,
          streamerId: data.userId,
          broadcastInfo: {
            userId: data.userId,
            userNickname: data.userNickname,
          },
        },
        {
          title: '즐겨찾기 그룹',
          customClassName: 'list_add_layer',
          titleAlign: 'left',
        },
      );
    }
  };

  return (
    <CatchGroup
      isStory={isStory}
      catches={catches}
      catchContextMenuList={CATCH_CONTEXT_MENU}
      handleMenuClicked={handleMenuClicked}
    />
  );
};

export default MyFavoriteCatch;
