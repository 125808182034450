'use client';
import MyPlusApi from '@/api/main/my-plus';
import MyCatch from '@/components/my/common/item/MyCatch';
import {SoopUiSwiper} from '@/components/ui';
import {SectionHeader} from '@/components/main/common';
import {CATCH_CONTEXT_MENU} from '@/constants/main';
import {getUserCookie} from '@/utils/main';
import {useEffect, useState} from 'react';
import {useHiddenStreamer} from '../../common/hooks/useHiddenStreamer';
import '/public/static/css/module/catch_list.scss';

export const CatchStroyGroup = () => {
  const swiperConfig = {
    loop: false,
    spaceBetween: 20,
  };

  const [catchStoryList, setCatchStoryList] = useState([]);
  const [catchStoryIdList, setCatchStoryIdList] = useState([]);
  const [catchStoryTitle, setCatchStoryTitle] = useState('');
  const [userNick, setUserNick] = useState('');
  const {
    hiddenStreamerList,
    handleOnClickHiddenStreamer,
    handleOnClickUndoHiddenStreamer,
  } = useHiddenStreamer('CATCHSTORY', 'myplus');

  useEffect(() => {
    getPreferCatchStoryList();
    setUserNick(getUserCookie('unick'));
  }, []);

  const getPreferCatchStoryList = async () => {
    try {
      const response = await MyPlusApi.getPreferCatchStoryList();

      if (response && response.result === 1) {
        setCatchStoryTitle(response.data.title);
        const transformedList =
          response.data?.list?.length > 0 &&
          response.data?.list?.map((catchItem) => {
            const {
              thumb,
              storyIdx,
              titleNo,
              titleName,
              resolutionType,
              broadDate,
              grade,
              category,
              fileType,
              originalUserNick,
              originalUserId,
              listDataType,
              viewCnt,
              verticalThumb,
              ...rest
            } = catchItem;

            return {
              ...rest,
              catchId: fileType === 'CATCH_STORY' ? storyIdx : titleNo,
              thumbnailSrc:
                resolutionType === 'horizontal' ? thumb : verticalThumb,
              isHorizontal: resolutionType === 'horizontal',
              broadcastDate: broadDate,
              userNickname: originalUserNick,
              userId: originalUserId,
              isAdult: grade === '19' && category === '30000',
              listDataType:
                fileType === 'CATCH_STORY' ? 'catchstory' : listDataType,
              fileType: fileType,
              title: titleName,
              viewCount: viewCnt || 0,
            };
          });
        setCatchStoryList(transformedList);
      }
    } catch (error) {
      setCatchStoryList([]);
    }
  };

  useEffect(() => {
    if (catchStoryList.length > 0) {
      const list = catchStoryList
        .filter((item) => item.fileType === 'CATCH_STORY') // 조건에 맞는 요소들만 필터링
        .map((item) => item.catchId); // catchId 추출
      setCatchStoryIdList(list);
    }
  }, [catchStoryList]);

  const handleOnClickMenu = (e, type, data) => {
    if (type === 'hidden') {
      handleOnClickHiddenStreamer(data);
    }
  };

  const handleUndoBroadcast = (data) => {
    if (hiddenStreamerList.includes(data.broadcastId)) {
      handleOnClickUndoHiddenStreamer(data);
    }
  };

  return (
    <>
      {catchStoryList && catchStoryList.length > 0 && (
        <>
          <SectionHeader
            title={
              <>
                {userNick}님의 관심
                <span className='catch'>{catchStoryTitle}</span>
              </>
            }
          />
          <div className='catch_story_area'>
            <SoopUiSwiper
              swiperClass='catch_list catch_story_list'
              swiperTag='ul'
              maxSlidesPerView={10}
              options={swiperConfig}>
              {catchStoryList.map((item, idx) => (
                <li key={idx} className='play_on'>
                  <MyCatch
                    {...{
                      ...item,
                      isStory: item.fileType === 'CATCH_STORY' ? true : false,
                      catchContextMenuList: CATCH_CONTEXT_MENU,
                      catchStoryIdList,
                    }}
                    thumbnailSrc={item.thumbnailSrc ?? item.thumbH}
                    hiddenStreamerList={hiddenStreamerList}
                    handleUndoBroadcast={handleUndoBroadcast}
                    handleMenuClicked={handleOnClickMenu}
                  />
                </li>
              ))}
            </SoopUiSwiper>
          </div>
        </>
      )}
    </>
  );
};
