//라이브 플레이어에서 일부 복사해왔음 혹시나 더 확장 된다면 liveplayer 프로젝트 가서 VastInfo 클래스 참고해서 추가하면 됨
const VastInfo = () => {
  const vastBase = {
    VAST: 'VAST',
    adId: '', //광고서버 애즈ID. 디버깅시 어떤 광고가 선출이 되었는지 확인
    impression: [], //VAST 피드백 받은 즉시 본 트래킹 태그 호출
    error: [], //error 시 호출할 트래킹 태그
    //clickMessage 제거 되고 videoClicks 노출 정책과 통합
    videoClicks: {
      clickThroughTag: '', //광고 클릭 시 연결할 링크 영상은 계속 재생, 광고주 페이지만 새창으로 링크 연결
      clickTracking: [], //ClickThrough 시점에 호출
    },
  };

  const merge = (vastXml) => {
    if (typeof vastXml === 'string') {
      vastXml = new window.DOMParser().parseFromString(vastXml, 'text/xml');
    }

    //for deep copy
    const vast = JSON.parse(JSON.stringify({...vastBase}));

    let ad = vastXml.querySelector('Ad InLine');
    let item = ad.querySelector('Creatives Creative Linear');
    let extensions = ad.querySelector('Extensions Extension');

    //모바일 프리롤 공통의 띠배너: Companion id="banner"
    //모바일 vod프리롤의 진입 후 리스트배너: Companion id="thumbnail"
    //라이브는 공통의 띠배너만 사용
    let companion = ad.querySelector(
      'Creatives Creative CompanionAds Companion#banner',
    );

    //모바일 앱 작업할 때 Extention이라고 오타가 난걸 아직 못고친 상태라고 한다
    //근데 모바일 웹이랑 광고가 겹쳐서 extention으로 올 수 있어서 체크해야함
    if (!extensions) {
      extensions = ad.querySelector('Extensions Extention');
    }

    vast.adId = vastXml.querySelector('Ad').getAttribute('id');

    for (let error of ad.querySelectorAll('Error')) {
      vast.error.push(getXmlValue(error));
    }

    for (let impression of ad.querySelectorAll('Impression')) {
      vast.impression.push(getXmlValue(impression));
    }

    vast.videoClicks.clickThroughTag = getXmlValue(
      item.querySelector('VideoClicks ClickThrough'),
    );

    //클릭 트래킹도 멀티가 되어야 함
    for (let clickTracking of item.querySelectorAll(
      'VideoClicks ClickTracking',
    )) {
      let clickTrackingUrl = getXmlValue(clickTracking);
      //이거 값이 undefined인 경우가 있네 ㅡㅡ;;
      if (clickTrackingUrl) {
        vast.videoClicks.clickTracking.push(getXmlValue(clickTracking));
      }
    }

    return vast;
  };

  const getXmlValue = (element = '') => {
    if (element) {
      if (element.children && element.children.length > 0) {
        let value = [];
        for (let i = 0; i < element.children.length; i++) {
          value.push(getXmlValue(element.children[i]));
        }
        return value;
      } else {
        return convertType(getDOMText(element));
      }
    }

    return element;
  };

  const getDOMText = (element) => {
    let text = element.textContent || element.text;
    if (typeof text === 'string') {
      text = text.trim();
    }
    return text;
  };

  const convertType = (variable) => {
    if (variable === 'true' || variable === 'false') {
      variable = variable === 'true';
    } else if (!isNaN(variable)) {
      variable = Number(variable);
    }

    return variable;
  };

  return {
    getVastInfo: (vastXml) => {
      return merge(vastXml);
    },
  };
};

export default VastInfo();
