import MyApi from '@/api/my';

const MyFeedApi = {
  /**
   * 내 즐겨찾기 리스트
   * 1페이지당 32개의 데이터를 불러옵니다.
   * @param {Object} param
   * @param {number} param.page - 페이지 번호
   * @returns {Promise<FeedFavoriteResponse>}
   */
  async getFavoriteList({page = 1}) {
    try {
      const params = new URLSearchParams({page});
      const response = await MyApi.get(
        `/api/feed/favorite?${params.toString()}`,
      );
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(error); // 에러 로깅은 개발 환경에서만 수행
      }
      throw error;
    }
  },
  /**
   * 내 채널 새 글 리스트
   * @param {Object} param
   * @param {Number} indexRegDate - 다음페이지 구현에 필요한 인자
   * @param {String} userId - 조회할 사용자 아이디(전체보기: '', 특정 사용자 조회: '사용자아이디')
   * @param {String|Number} isBjWrite - BJ 작성글만 조회 여부('': 전체글 조회, 1: BJ 작성글만 조회)
   * @param {String} feedType - 게시글 타입(전체:'', 공지글:'NOTICE', 게시글:'POST_PHOTO')
   * @returns {Promise<FeedResponse>}
   */
  async getNewPostList({
    indexRegDate = 0,
    userId = '',
    isBjWrite = '',
    feedType = '',
  }) {
    try {
      const params = new URLSearchParams({
        index_reg_date: indexRegDate,
        user_id: userId,
        is_bj_write: isBjWrite,
        feed_type: feedType,
      });
      const endPoint = userId
        ? `/api/feed/${userId}?${params.toString()}`
        : `/api/feed?${params.toString()}`;
      const response = await MyApi.get(endPoint);
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(error); // 에러 로깅은 개발 환경에서만 수행
      }
      throw error;
    }
  },
};

export default MyFeedApi;
