/**
 * MyNoList 컴포넌트
 * @desc - 리스트가 없을 때 보여주는 컴포넌트
 * @param {Object} props - MyNoList props
 * @param {string} props.title - 제목
 * @param {React.ReactNode} props.description - 설명
 * @returns {JSX.Element}
 */
const NoList = ({title, description}) => {
  return (
    <div className='noList'>
      <p>{title}</p>
      {description}
    </div>
  );
};

export default NoList;
