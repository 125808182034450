/**
 * 이것은 클라이언트 에러 페이지를 막기 위해서 만든 파일이다
 *
 * 현재는 컴포넌트를 그리던 중 에러가 발생하면 client error 라고 페이지 자체가 에러로 먹히는데
 * 그 에러 전파를 막도록 ErrorBoundary 컴포넌트 내에서 error 를 catch 해서 fallback 을 보여 줘 버린다
 *
 * ErrorBoundary 는 클래스형 컴포넌트로 사용해야 한다 (getDerivedStateFromError 를 사용해야 하기 때문)
 * https://nextjs.org/docs/pages/building-your-application/configuring/error-handling#handling-client-errors
 *
 * 만약 하위 컴포넌트에서 에러가 발생할 경우 fallback 컴포넌트나 null 을 리턴시켜서 안 보여 주게끔 한다
 *
 * @example
 * 폴백이 리턴되게 하려면
 * <ErrorBoundary fallback={<div>폴백</div>}>
 *  {null.replace}
 * </ErrorBoundary>
 *
 * 아무것도 응답이 안 되게 하려면
 * <ErrorBoundary>
 *  {null.replace}
 * </ErrorBoundary>
 */

import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // 에러가 발생하면 state 업데이트
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'test'
    ) {
      throw error;
    }

    console.error(
      '컴포넌트 그리던 중 발생한 클라이언트 에러 :',
      error,
      errorInfo,
    );
  }

  render() {
    if (this.state.hasError) {
      // 오류가 발생한 경우 대체 UI 렌더링 또는 null 반환
      return this.props.fallback || null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
