import EventApi from '@/api/event';
import {ScketcApi} from '@/api/sch';
import StbbsApi from '@/api/stbbs';
import {
  convertKeysToSnakeCase,
  getDeviceType,
  objectToQueryString,
} from '@/utils';
import {
  getCookie,
  setCookie,
  getTimeStamp,
  jsonToQuerystring,
} from '@/utils/main';
import {cookie} from '@afreecatv/domains';
import {isEmpty} from 'lodash';

const BIN_KEY = [
  'path1',
  'path2',
  'path3',
  'path4',
  'path_key',
  'directory_cate_id',
  'directory_admin_id',
];

const VIN_KEY = [
  'path1',
  'path2',
  'path3',
  'path4',
  'path_key',
  'directory_cate_id',
  'directory_admin_id',
  'ex_category_no',
];

const LogApi = {
  /**
   * 클릭 로그 api
   * @param {Object} params
   * @param {Object} params.sendData - send_data 에 넣어서 보낼 것들을 객체로 넣으면 함수 내부에서 qs 로 바꾸게끔
   * @returns {Object}
   */
  sendClickLog(params) {
    try {
      const sendData =
        !isEmpty(params.sendData) && typeof params.sendData === 'object'
          ? objectToQueryString(convertKeysToSnakeCase(params.sendData))
          : '';

      const sendParams = {
        sysType: getDeviceType() === 'mobile' ? 'webm' : 'web',
        ...params,
        ...(sendData && {sendData}),
      };

      EventApi.post('/set_log_api.php', {
        sztype: 'CLICK',
        ...convertKeysToSnakeCase(sendParams),
      });
    } catch (error) {
      console.error('Error: 클릭 로그 전송에 실패했습니다');
    }
  },

  /**
   * 로그 보내기
   *
   * @desc sztype 마저 공통화 할 수 없을 때
   * @param {Object} params - 파라미터
   * @param {Object} params.sendData - send_data 에 넣어서 보낼 것들을 객체로 넣으면 함수 내부에서 qs 로 바꾸게끔
   */
  sendLog(params) {
    try {
      let sendData = '';

      if (!isEmpty(params.sendData) && typeof params.sendData === 'object') {
        sendData = prepareSendData(params.sendData);
      }
      const codeType = params.codeType && params.codeType.toUpperCase();
      const logParams = {
        sysType: getDeviceType() === 'mobile' ? 'webm' : 'web',
        ...params,
        ...(sendData && {sendData}),
      };

      EventApi.post('/set_log_api.php', convertKeysToSnakeCase(logParams));

      //여기서 부터 VIN.BIN 전송 로직
      const sendPlayerParams = new URLSearchParams(sendData);
      if (params.pathKey) {
        sendPlayerParams.append('path_key', params.pathKey);
      }
      let filteredLogParams = {};
      if (codeType === 'LIVE') {
        // 필요한 키-값 쌍만 추출
        BIN_KEY.forEach((key) => {
          if (sendPlayerParams.has(key)) {
            filteredLogParams[key] = sendPlayerParams.get(key);
          }
        });
      } else if (codeType === 'VOD') {
        VIN_KEY.forEach((key) => {
          if (sendPlayerParams.has(key)) {
            filteredLogParams[key] = sendPlayerParams.get(key);
          }
        });
      }
      console.log(objectToQueryString(filteredLogParams));
      // 플레이어팀에서 사용하기 위하여 쿠키를 넣어준다 (LIN, LOUT)
      setCookie(
        // 레거시: codeType, 신규: contentsType
        codeType === 'LIVE' ? 'LIN' : 'VIN',
        objectToQueryString(filteredLogParams), //LIN,VIN 은 sendData 키값이 제거된 searchParams 형태로 보내줘야한다고함
      );
    } catch (error) {
      console.error('Error: 로그 전송에 실패했습니다 ', error);
    }
  },

  /**
   * LIVE_LISTVIEW - 브라우저에 노출된 VOD 리스트 로그 전송
   * @param object params 쿼리스트링형식
   */
  sendLiveListViewLog(params) {
    try {
      if (!isEmpty(params) && typeof params === 'object') {
        const userId = getCookie(cookie.BBS_COOKIE_NAME);
        const uuid = getCookie('_au');
        const sessionId = userId || uuid;
        params.listViewSession = `${params.path2}_${sessionId}_${getTimeStamp()}`;
        const sendData = jsonToQuerystring(convertKeysToSnakeCase(params));
        EventApi.post('/set_log_api.php', {
          sztype: 'LIVE_LISTVIEW',
          send_data: sendData,
        });
      }
    } catch (error) {
      console.error('Error: 클릭 로그 전송에 실패했습니다');
    }
  },

  /**
   * VOD_LISTVIEW - 브라우저에 노출된 VOD 리스트 로그 전송
   * @param object etcData 쿼리스트링형식
   */
  sendVodListViewLog(params) {
    try {
      if (!isEmpty(params) && typeof params === 'object') {
        const userId = getCookie(cookie.BBS_COOKIE_NAME);
        const uuid = getCookie('_au');
        const sessionId = userId || uuid;
        params.listViewSession = `${params.path2}_${sessionId}_${getTimeStamp()}`;
        const sendData = jsonToQuerystring(convertKeysToSnakeCase(params));
        EventApi.post('/set_log_api.php', {
          sztype: 'VOD_LISTVIEW',
          send_data: sendData,
        });
      }
    } catch (error) {
      console.error('Error: 클릭 로그 전송에 실패했습니다');
    }
  },

  searchClickLog(params) {
    const uuid = getCookie('_au');
    const ad_uuid = getCookie('ad_uuid');
    const theme_color = getCookie('theme');
    const userTicket = getCookie('UserTicket');

    let age = '';
    let sex = '';

    if (userTicket) {
      age = userTicket?.match(/age%3D(\d+)/)[1];
      sex = userTicket?.match(/sex%3D(\w)/)[1];
    }

    const sckactParams = {
      uuid,
      ad_uuid,
      age,
      sex,
      theme_color: theme_color || 'light',
      isHashSearch: params.location === 'hash' ? true : false,
      d: encodeURIComponent(params.keyword || params.tagname),
      tab: params.tab,
      acttype: params.acttype || '',
      actcode: params.actCode || '',
      stype: params.szStype || params.stype || '',
      skey: encodeURIComponent(params.keyword) || '',
      skey_origin: encodeURIComponent(params.keyword) || '',
      l: 'DF',
      page: params.page || 1,
      location: params.location || '',
      modified: encodeURIComponent(params.modified) || '',
      original: encodeURIComponent(params.original) || '',
      has_hint: params.szStype === 'pk' ? params.hasHint : '',
      bjid: params.bjid || '',
      sck_session_key: params.sessionKey || '',
      list_idx: params.listIdx !== undefined ? params.listIdx : '',
      contents_type: params.contentsType || '',
      contents_value: params.contentsValue || '',
      playlist_idx: params.playlistIdx !== undefined ? params.playlistIdx : '',
      is_hot: params.isHot !== undefined ? String(params.isHot) : '',
      file_type: params.fileType || '',
      ac_type: params.acType || '',
      tno: params.tno || '',
      writer: params.writer || '',
      bno: params.bno || '',
      vno: params.vno || '',
      section_name: params.sectionName || '',
    };
    try {
      // 쿼리 스트링 생성
      const queryString = {
        m: 'actionLog',
        ...sckactParams,
      };

      // 빈 Key 제거
      const removeEmptyQueryStringKey = Object.fromEntries(
        Object.entries(queryString).filter(([key, value]) => value !== ''),
      );

      ScketcApi.get(
        `/api.php?${new URLSearchParams(removeEmptyQueryStringKey).toString()}`,
      );
    } catch (error) {
      console.error('Error in searchClickLog:', error);
    }
  },

  /**
   * @description VOD 재생 로그 전송
   * @param {Object} params - 파라미터
   * @param {number} params.stationNo - 방송국 번호
   * @param {number} params.titleNo - VOD 번호
   * @param {string} params.bjId - BJ 아이디
   * @param {string} params.category - 카테고리
   * @param {string} params.sysType - 시스템 타입
   * @param {string} params.part - 파트
   * @param {boolean} params.isAutoplay - 자동재생 여부
   * @param {string} params.path1 - 경로1
   * @param {string} params.path2 - 경로2
   * @param {number} params.videoStart - 비디오 시작 시간
   * @param {boolean} params.isPreview - 미리보기 여부
   */
  sendVodPlayLog(params) {
    StbbsApi.post('/api/video/set_video_log.php', {
      nStationNo: params.stationNo || 0,
      nTitleNo: params.titleNo || 0,
      szBjId: params.bjId || '',
      szCategory: params.category || '',
      szSysType: getDeviceType() === 'mobile' ? 'webm' : 'web',
      szPart: params.part || '',
      is_autoplay: params.isAutoplay || false,
      path1: params.path1 || '',
      path2: params.path2 || '',
      videoStart: params.videoStart || 0,
      is_preview: params.isPreview || false,
    });
  },
};
export default LogApi;

/**
 * sendData 를 쿼리스트링으로 바꾸는.. 그러한 함수
 *
 * @desc sendData 안에 배열이 들어갈 경우 (tag 등) | 로 묶어주길래 아예 함수로 뺐다..
 * @param {Object} data - 원본 데이터 객체
 * @returns {string} - 변환된 쿼리 문자열
 */
function prepareSendData(data) {
  const sendData = {};

  Object.keys(data).forEach((key) => {
    const value = data[key];
    if (Array.isArray(value)) {
      sendData[key] = value.join('|');
    } else {
      sendData[key] = value;
    }
  });

  return objectToQueryString(convertKeysToSnakeCase(sendData));
}
