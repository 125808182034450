import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

const store = (set) => {
  return {
    topLiveList: {
      simplifyMode: false,
      count: 0,
      list: [],
    },
    checkAd: {
      hasNewAd: false,
      banners: {},
      AdParameter: {},
    },
    banner: {
      main: {
        type: '', //광고 형태 타입
        exist: false, //광고 존재 여부
        creatives: [],
      },
      sub: {
        key: '', //location key 값
        type: '', //광고 형태 타입
        exist: false, //광고 존재 여부
        category: '',
        creatives: [],
      },
    },
    isLoadingPlayerArea: true, //스트리밍 영역 노출/비노출
    isCloseMainBanner: false, //메인배너 닫힘
    isShowMainBanner: false, //메인배너 노출/비노출
    isRenderMainBanner: false, //메인배너 컴포넌트 랜더링 여부
    resizeCount: 1,
    setTopLiveList: (item) => {
      set((state) => {
        return {topLiveList: {...state.topLiveList, ...item}};
      });
    },
    setIsShowMainBanner: (item) => {
      set((state) => {
        return {isShowMainBanner: item};
      });
    },
    setIsCloseMainBanner: (item) => {
      set((state) => {
        return {isCloseMainBanner: item};
      });
    },
    setIsLoadingPlayerArea: (item) => {
      set((state) => {
        return {isLoadingPlayerArea: item};
      });
    },
    setIsRenderMainBanner: (item) => {
      set((state) => {
        return {isRenderMainBanner: item};
      });
    },
    setCheckAd: (item) => {
      set((state) => {
        return {checkAd: {...state.checkAd, ...item}};
      });
    },
    setBanner: (item) => {
      set((state) => {
        return {banner: {...state.banner, ...item}};
      });
    },
    setResizeCount: (item) => {
      set((state) => {
        return {resizeCount: item};
      });
    },
  };
};

const useMainStore = create(devtools(store));
export default useMainStore;
