import Helmet from 'react-helmet';

export default function NotFoundRoute() {
  return (
    <>
      <Helmet>
        <title>SOOP 에러 페이지</title>
      </Helmet>
      <iframe
        src='/not-found.html'
        style={{width: '100%', height: '100vh', border: 'none'}}
        title='SOOP 에러 페이지'
      />
    </>
  );
}
