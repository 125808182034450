import {useMemo, useCallback, useState, useEffect} from 'react';

import {SoopUiSwiper} from '@/components/ui';
import {ErrorBoundary} from '@/components/common';
import {SectionHeader} from '@/components/main/common';
import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';
import RecommendContentListItem from '@/components/search/group/recommend-contents-group/RecommendContentListItem';

import classNames from 'classnames';

/**
 * RecommendContentsGroup 컴포넌트 ( 관련 콘텐츠 )
 * @param {Object} props 컴포넌트의 props
 * @param {object[]} profileTheme 관련 콘텐츠 영역 데이터
 * @returns {JSX.Element} RecommendContentsGroup 컴포넌트
 */
const RecommendContentsGroup = (profileTheme) => {
  const {sessionKey} = useSearchGlobalStatus();
  const {
    sendSckactLiveClickLog,
    sendSckactVodClickLog,
    sendRecommendStreamerClickLog,
  } = useSearchClickLog();
  const {getParams} = useUrlParameter();
  // 동적으로 바뀌는 parameter들
  const {szSearchType: searchType = ''} = getParams();
  const {recommendContents = []} = profileTheme || {};
  const [catchStoryList, setCatchStoryList] = useState([]);

  const hasBJ = useMemo(
    () =>
      Array.isArray(recommendContents) &&
      recommendContents.some((content) => content.recommendType === 'BJ'),
    [recommendContents],
  );

  const swiperOptions = useMemo(() => {
    return {
      loop: false,
      ...(hasBJ && {slidesPerView: 'auto', spaceBetween: 0}),
    };
  }, [hasBJ]);

  const maxSlidesPerView = useMemo(() => {
    return hasBJ ? null : 6;
  }, [hasBJ]);

  // SCKACT 로그
  const handleLogCallback = useCallback(
    ({
      param,
      index,
      recommendType,
      fileType,
      hotclipYn,
      broadNo,
      titleNo,
      userId,
    }) => {
      const {type = 'sn'} = param || {};
      const isRemoveField = ['nick', 'hash'].includes(type);

      let checkContentType = recommendType === 'VOD' ? 'vod' : 'live';
      let checkContentValue = recommendType === 'VOD' ? titleNo : broadNo;
      let checkSectionName =
        recommendType === 'VOD'
          ? 'contents_vod'
          : recommendType === 'LIVE'
            ? 'contents_live'
            : 'contents_bj';

      if (type === 'sti') {
        checkContentType = recommendType === 'BJ' && broadNo ? 'live' : 'bj';
        checkContentValue =
          recommendType === 'BJ' && broadNo ? broadNo : userId;
      }

      const params = {
        fileType: recommendType === 'VOD' ? fileType : '',
        isHot: recommendType === 'VOD' ? hotclipYn === '1' : '',
        bjid: userId,
        vno: recommendType === 'VOD' ? titleNo : '',
        bno: recommendType !== 'VOD' ? broadNo : '',
        contentsType: isRemoveField ? '' : checkContentType,
        contentsValue: isRemoveField ? '' : checkContentValue,
        actCode: type,
        acttype: 'recommend',
        sectionName: checkSectionName,
        listIdx: index,
        sessionKey: sessionKey,
        ...param,
      };

      if (recommendType === 'VOD') {
        sendSckactVodClickLog(params);
      } else if (recommendType === 'BJ') {
        sendRecommendStreamerClickLog(params);
      } else if (recommendType === 'LIVE') {
        sendSckactLiveClickLog(params);
      }
    },
    [sessionKey, searchType],
  );

  // 데이터에 캐치스토리가 있는 경우
  // 캐치스토리 순서대로 재생하기 위한 listNo 배열
  useEffect(() => {
    if (recommendContents && Array.isArray(recommendContents)) {
      const newCatchStoryTitleNos = recommendContents
        .filter((item) => item.fileType === 'CATCH_STORY')
        .map((item) => item.titleNo);

      setCatchStoryList(...newCatchStoryTitleNos);
    }
  }, [recommendContents]);

  return (
    <>
      {recommendContents && recommendContents.length > 0 ? (
        <>
          <SectionHeader title={'관련 콘텐츠'} />

          <div className='cBox_slide strm_area vodSlide'>
            <SoopUiSwiper
              swiperTag='ul'
              swiperClass='cBox_list'
              maxSlidesPerView={maxSlidesPerView}
              options={swiperOptions}>
              {recommendContents.map((content, index) => (
                <li
                  key={`${content.userId || ''}-${index}`}
                  data-type='cBox'
                  className={classNames({
                    strm_box: content.recommendType === 'BJ',
                    live: content.recommendType === 'BJ' && content.broadNo,
                  })}>
                  <ErrorBoundary>
                    <RecommendContentListItem
                      content={content}
                      index={index}
                      catchStoryList={catchStoryList}
                      handleLogCallback={handleLogCallback}
                    />
                  </ErrorBoundary>
                </li>
              ))}
            </SoopUiSwiper>
          </div>
        </>
      ) : null}
    </>
  );
};

export default RecommendContentsGroup;