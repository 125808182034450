import '/public/static/css/module/ad_player.scss';
import player from '/public/static/css/components/player.module.scss';
import subBannerCss from '/public/static/css/components/subBanner.module.scss';
import MainBannerCss from '/public/static/css/components/mainBanner.module.scss';
import useMainStore from '@/stores/main';
import {BANNER_RESPONSE_TYPE} from '@/constants/main/banner';
import {BannerAdPlayer} from '../player/banner/BannerAdPlayer';
import {useBanner} from '../main/common/hooks/useBanner';
import {useEffect} from 'react';
import VastInfo from '@/utils/player/VastInfo';

export default function Banner({type = '', category = ''}) {
  const {
    isOpen,
    banner,
    handleClickClose,
    handleClickBanner,
    handelErrorImage,
    handleMouseEnter,
    handleMouseLeave,
  } = useBanner({type, category});

  return type === 'main' ? (
    <MainBannerComponent
      bannerData={banner.main}
      handleClickBanner={handleClickBanner}
      handleClickClose={handleClickClose}
      handelErrorImage={handelErrorImage}
    />
  ) : (
    <SubBannerComponent
      isOpen={isOpen}
      bannerData={banner.sub}
      handleClickBanner={handleClickBanner}
      handleClickClose={handleClickClose}
      handelErrorImage={handelErrorImage}
      handleMouseEnter={handleMouseEnter}
      handleMouseLeave={handleMouseLeave}
    />
  );
}

const MainBannerComponent = ({
  bannerData,
  handleClickClose,
  handelErrorImage,
  handleClickBanner,
}) => {
  if (bannerData.exist) {
    switch (bannerData.type) {
      case BANNER_RESPONSE_TYPE.PC_MAIN_IMAGE:
        return (
          <ImageMainBannerComponent
            bannerData={bannerData}
            handleClickClose={handleClickClose}
            handleClickBanner={handleClickBanner}
            handelErrorImage={handelErrorImage}
          />
        );
      case BANNER_RESPONSE_TYPE.PC_MAIN_VIDEO:
        return (
          <VideoMainBannerComponent
            bannerData={bannerData}
            handleClickClose={handleClickClose}
            handleClickBanner={handleClickBanner}
          />
        );
      default:
        return null;
    }
  }
};

const ImageMainBannerComponent = ({
  bannerData,
  handleClickClose,
  handelErrorImage,
  handleClickBanner,
}) => {
  const {
    checkAd,
    isShowMainBanner,
    isCloseMainBanner,
    setIsCloseMainBanner,
    setIsShowMainBanner,
    setIsRenderMainBanner,
  } = useMainStore();

  useEffect(() => {
    setIsRenderMainBanner(true);
    if (checkAd.AdParameter.hasOwnProperty('sendTracking')) {
      checkAd.AdParameter.sendTracking(bannerData.creatives[0]?.impressionUrls);
      checkAd.AdParameter.sendTracking(bannerData.creatives[0]?.viewableUrls);
    }
  }, []);

  return (
    <>
      {bannerData &&
        !isCloseMainBanner &&
        bannerData?.creatives?.map((banner, idx) => (
          <div
            key={idx}
            className={`${MainBannerCss.brandBanner} ${!isShowMainBanner ? MainBannerCss.close : ''}`}
            style={{background: banner.backgroundColor}}>
            <a
              href={banner.landingUrl}
              className={MainBannerCss.link}
              style={{
                backgroundImage: `url(${banner.thumbnailSrc})`,
              }}
              target='_blank'
              onClick={() => handleClickBanner(banner.clickUrls)}
              onError={(e) => handelErrorImage(e, banner.errorUrl)}>
              {banner.title}
            </a>

            <button
              type='button'
              className={MainBannerCss.close}
              onClick={() => {
                setIsCloseMainBanner(true);
                setIsShowMainBanner(false);
                handleClickClose(banner.closeUrl);
              }}>
              브랜드 배너 닫기
            </button>
          </div>
        ))}
      {isShowMainBanner && <div className={player.player_wrap}></div>}
    </>
  );
};

const VideoMainBannerComponent = ({
  bannerData,
  handleClickClose,
  handleClickBanner,
}) => {
  const {
    isShowMainBanner,
    isCloseMainBanner,
    setIsShowMainBanner,
    setIsCloseMainBanner,
    setIsRenderMainBanner,
  } = useMainStore();

  useEffect(() => {
    setIsRenderMainBanner(true);
  }, []);

  const handleClickVideoBanner = (banner) => {
    handleClickBanner(banner.clickUrls);
    if (banner.vast) {
      const subVastInfo = VastInfo.getVastInfo(banner.vast);
      handleClickBanner(subVastInfo.videoClicks.clickTracking);
    }
  };

  return (
    <>
      {bannerData &&
        !isCloseMainBanner &&
        bannerData?.creatives?.map((banner, idx) => (
          <div
            key={idx}
            className={`${MainBannerCss.brandBanner} ${!isShowMainBanner ? MainBannerCss.close : ''}`}
            style={{background: banner.backgroundColor}}>
            <a
              href={banner.landingUrl}
              className={MainBannerCss.link}
              style={{
                backgroundImage: `url(${banner.thumbnailSrc})`,
              }}
              target='_blank'
              onClick={() => handleClickVideoBanner(banner)}>
              {banner.title}
            </a>
            <div className={MainBannerCss.inner_w}>
              <div className={MainBannerCss.inner}>
                <div
                  className={`${MainBannerCss.player} ${banner.videoPlacement === 'LEFT' ? MainBannerCss.left : ''}`}>
                  <BannerAdPlayer
                    adInfo={bannerData}
                    loop={true}
                    onError={(e) =>
                      handelErrorImage(
                        e,
                        banner.errorUrl,
                        banner.alternativeImageUrl,
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <button
              type='button'
              className={MainBannerCss.close}
              onClick={() => {
                setIsCloseMainBanner(true);
                setIsShowMainBanner(false);
                handleClickClose(banner.closeUrl);
              }}>
              브랜드 배너 닫기
            </button>
          </div>
        ))}
      {isShowMainBanner && <div className={player.player_wrap}></div>}
    </>
  );
};

const SubBannerComponent = ({
  isOpen,
  bannerData,
  handleClickClose,
  handelErrorImage,
  handleMouseLeave,
  handleMouseEnter,
  handleClickBanner,
}) => {
  if (bannerData.exist) {
    switch (bannerData.type) {
      case BANNER_RESPONSE_TYPE.PC_728_90_BANNER:
        return (
          <BasicSubBannerComponent
            bannerData={bannerData}
            handleClickBanner={handleClickBanner}
            handelErrorImage={handelErrorImage}
          />
        );
      case BANNER_RESPONSE_TYPE.PC_SUB_BANNER_EXPANDED_VIDEO:
        return (
          <ExpandVideoSubBannerComponent
            isOpen={isOpen}
            bannerData={bannerData}
            handleClickBanner={handleClickBanner}
            handleClickClose={handleClickClose}
            handelErrorImage={handelErrorImage}
            handleMouseLeave={handleMouseLeave}
            handleMouseEnter={handleMouseEnter}
          />
        );
      case BANNER_RESPONSE_TYPE.PC_SUB_BANNER_EXPANDED_IMAGE:
        return (
          <ExpandImageSubBannerComponent
            isOpen={isOpen}
            bannerData={bannerData}
            handleClickBanner={handleClickBanner}
            handleClickClose={handleClickClose}
            handelErrorImage={handelErrorImage}
            handleMouseLeave={handleMouseLeave}
            handleMouseEnter={handleMouseEnter}
          />
        );
      default:
        return null;
    }
  }
};

const BasicSubBannerComponent = ({
  bannerData,
  handelErrorImage,
  handleClickBanner,
}) => {
  return (
    <div className={`${subBannerCss.fullBanner}`}>
      {bannerData &&
        bannerData?.creatives?.map((banner, idx) => (
          <div key={idx}>
            {/* 축소 */}
            <div
              className={subBannerCss.contractionImg}
              style={{background: banner.backgroundColor}}>
              <a
                href={banner.landingUrl}
                className={subBannerCss.link}
                target='_blank'
                onClick={() => handleClickBanner(banner.clickUrls)}
                onError={(e) => handelErrorImage(e, banner.errorUrl)}>
                배너링크
              </a>
              <img src={banner.thumbnailSrc} alt='광고이미지' />
            </div>
          </div>
        ))}
    </div>
  );
};

const ExpandVideoSubBannerComponent = ({
  isOpen,
  bannerData,
  handleClickClose,
  handelErrorImage,
  handleMouseLeave,
  handleMouseEnter,
  handleClickBanner,
}) => {
  return (
    <>
      {bannerData && (
        <div
          className={`${bannerData?.creatives[0]?.videoPlacement === 'RIGHT' ? subBannerCss.a_d_right : ''} ${subBannerCss.fullBanner} ${isOpen ? subBannerCss.open : ''}`}>
          {bannerData?.creatives?.map((banner, idx) => (
            <div key={idx}>
              {/* 축소 */}
              <div
                className={subBannerCss.contractionImg}
                style={{background: banner.backgroundColor}}>
                <a
                  href={banner.landingUrl}
                  className={subBannerCss.link}
                  target='_blank'
                  onClick={() => handleClickBanner(banner.clickUrls)}>
                  배너링크
                </a>
                <img
                  src={banner.thumbnailSrc}
                  alt='광고이미지'
                  onError={(e) =>
                    handelErrorImage(
                      e,
                      banner.errorUrl,
                      banner.alternativeImageUrl,
                    )
                  }
                />
              </div>
              {/* 펼침 */}
              <div
                className={subBannerCss.expandImg}
                style={{
                  background: banner.expandedBackgroundColor,
                }}>
                <a
                  href={banner.landingUrl}
                  target='_blank'
                  className={subBannerCss.link}
                  style={{
                    backgroundImage: `url(${banner.expandedImageUrl})`,
                  }}
                  onClick={() => handleClickBanner(banner.clickUrls)}>
                  배너링크
                </a>
                {/* 플레이어 영역 */}
                <div className={subBannerCss.inner_w}>
                  <div className={subBannerCss.inner}>
                    {isOpen && (
                      <div className={subBannerCss.player}>
                        <BannerAdPlayer
                          adInfo={bannerData}
                          loop={true}
                          onError={(e) =>
                            handelErrorImage(
                              e,
                              banner.errorUrl,
                              banner.alternativeImageUrl,
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className={subBannerCss.AdvertisingBtn}>
            {isOpen ? (
              <button
                type='button'
                className={subBannerCss.close}
                onClick={() =>
                  handleClickClose(bannerData?.creatives[0]?.closeUrl)
                }>
                <em></em>
                <i className={subBannerCss.text}>작게 보기</i>
              </button>
            ) : (
              <button
                type='button'
                className={subBannerCss.open}
                onClick={() =>
                  handleClickClose(bannerData?.creatives[0]?.closeUrl)
                }
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <em></em>
                <i className={subBannerCss.text}>자세히 보기</i>
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const ExpandImageSubBannerComponent = ({
  isOpen,
  bannerData,
  handleClickBanner,
  handleClickClose,
  handelErrorImage,
  handleMouseLeave,
  handleMouseEnter,
}) => {
  return (
    <>
      <div
        className={`${subBannerCss.fullBanner} ${isOpen ? subBannerCss.open : ''}`}>
        {bannerData &&
          bannerData.creatives?.map((banner, idx) => (
            <div key={idx}>
              {/* 축소 */}
              <div
                className={subBannerCss.contractionImg}
                style={{background: banner.backgroundColor}}>
                <a
                  href={banner.landingUrl}
                  className={subBannerCss.link}
                  target='_blank'
                  onClick={() => handleClickBanner(banner.clickUrls)}>
                  배너링크
                </a>
                <img
                  src={banner.thumbnailSrc}
                  alt='광고이미지'
                  onError={(e) =>
                    handelErrorImage(
                      e,
                      banner.errorUrl,
                      banner.alternativeImageUrl,
                    )
                  }
                />
              </div>
              {/* 펼침 */}
              <div
                className={subBannerCss.expandImg}
                style={{
                  background: banner.expandedBackgroundColor,
                }}>
                <a
                  href={banner.landingUrl}
                  target='_blank'
                  className={subBannerCss.link}
                  style={{
                    backgroundImage: `url(${banner.expandedImageUrl})`,
                  }}
                  onClick={() => handleClickBanner(banner.clickUrls)}>
                  배너링크
                </a>
              </div>
            </div>
          ))}
        <div className={subBannerCss.AdvertisingBtn}>
          {isOpen ? (
            <button
              type='button'
              className={subBannerCss.close}
              onClick={() =>
                handleClickClose(bannerData?.creatives[0]?.closeUrl)
              }>
              <em></em>
              <i className={subBannerCss.text}>작게 보기</i>
            </button>
          ) : (
            <button
              type='button'
              className={subBannerCss.open}
              onClick={() =>
                handleClickClose(bannerData?.creatives[0]?.closeUrl)
              }
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
              <em></em>
              <i className={subBannerCss.text}>자세히 보기</i>
            </button>
          )}
        </div>
      </div>
    </>
  );
};
