
/**
 * NoResult 컴포넌트
 * @param {string} searchTab - search 페이지 tab
 * @param {string} period - 기간
 * @param {boolean} isKeywordEntered - 검색어 입력 여부
 * @param {string} stopWord - 금칙어
 * @returns {JSX.Element} NoResult 컴포넌트
 */
const NoResult = ({searchTab, period, isKeywordEntered, stopWord}) => {
  const defaultMessage = '검색 결과가 없습니다.';
  const defaultSuggestion = [
    '다른 검색어로 변경해 보세요.',
    '특수문자를 제외하고 검색해 보세요.',
  ];
  const noKeywordMessage = '검색어를 입력해 주세요.';
  const stopWordMessage = (
    <p>
      <strong>&#39;{stopWord}&#39;</strong>는(은) 검색이 제한된 금칙어가
      포함되어 있습니다.
      <br />
      다른 검색어로 입력해 보세요.
    </p>
  );

  const messages = {
    total: {
      message: defaultMessage,
      suggestion: defaultSuggestion,
    },
    broad: {
      message: defaultMessage,
      suggestion: defaultSuggestion,
    },
    vod: {
      message: defaultMessage,
      suggestion: [...defaultSuggestion, '검색 기간 옵션을 변경해 보세요.'],
    },
    post: {
      message:
        !period || period === '전체'
          ? defaultMessage
          : `최근 ${period}간 게시글 검색결과가 없습니다.`,
      suggestion: [...defaultSuggestion, '검색 기간 옵션을 변경해 보세요.'],
    },
    streamer: {
      message: defaultMessage,
      suggestion: defaultSuggestion,
    },
  };

  const {message, suggestion} = messages[searchTab] || messages.total;

  return (
    <div className='noList'>
      {stopWord ? (
        stopWordMessage
      ) : (
        <p>{isKeywordEntered ? message : noKeywordMessage}</p>
      )}
      {isKeywordEntered && !stopWord && suggestion && (
        <ul>
          {suggestion.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default NoResult;
