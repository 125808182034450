import {
  GameProfileContents,
  GameProfileHeader,
} from '@/components/search/group/game-profile-group';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

/**
 * GameProfileGroup 컴포넌트 (게임 프로필 )
 * @param {Object} props - 컴포넌트의 props
 * @param {Object[]} gameProfileList 게임 프로필 영역 데이터
 * @param {Function} fetchGameProfileThemeStreamerList 관련 스트리머 영역의 추가 데이터를 받아오기 위한 메소드
 * @param {Function} fetchGameScheduleList 경기 일정 데이터 받아오기 위한 메소드
 * @returns GameProfileGroup 컴포넌트
 */
const GameProfileGroup = ({
  esportList = [],
  gameProfileList = [],
  fetchGameProfileThemeStreamerList = () => {},
  fetchGameScheduleList = () => {},
}) => {
  const {sessionKey} = useSearchGlobalStatus();

  if (!gameProfileList || gameProfileList.length < 1) {
    return null;
  }

  return (
    <>
      {gameProfileList?.map((gameProfile) => (
        <div className='game_profile' key={gameProfile.title}>
          {/* 게임 프로필 정보 영역 */}
          <GameProfileHeader {...gameProfile} sessionKey={sessionKey} />
          {/* 게임 프로필 노출 콘텐츠 영역 ( 관련 스트리머, 경기 일정, 공식 채널, 이벤트, 시리즈게임 )*/}
          <GameProfileContents
            {...gameProfile}
            esportList={esportList}
            sessionKey={sessionKey}
            fetchGameProfileThemeStreamerList={
              fetchGameProfileThemeStreamerList
            }
            fetchGameScheduleList={fetchGameScheduleList}
          />
        </div>
      ))}
    </>
  );
};

export default GameProfileGroup;
