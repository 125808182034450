import {isIOS} from 'react-device-detect';
import Autolinker from 'autolinker';

export const EMOTICON_URL = '//res.afreecatv.com/images/chat/emoticon/big/';
export const EMOTICON_SMALL_URL =
  '//res.afreecatv.com/images/chat/emoticon/small/';
export const emoticons = {
  '/샤방/': '1.png',
  '/윽/': '2.png',
  '/휘파람/': '3.png',
  '/짜증/': '4.png',
  '/헉/': '5.png',
  '/하이/': '6.png',
  '/개좋아/': '7.png',
  '/개도발/': '8.png',
  '/개털림/': '9.png',
  '/개감상/': '10.png',
  '/개화나/': '11.png',
  '/개이득/': '12.png',
  '/개번쩍/': '13.png',
  '/짱좋아/': '94.png',
  '/피식/': '95.png',
  '/헐/': '96.png',
  '/감상중/': '97.png',
  '/화나/': '98.png',
  '/하하/': '99.png',
  '/ㅠㅠ/': '100.png',
  '/화이팅/': '102.png',
  '/주작/': '14.png',
  '/꿀잼/': '15.png',
  '/업/': '16.png',
  '/갑/': '17.png',
  '/묻/': '18.png',
  '/심쿵/': '19.png',
  '/스겜/': '20.png',
  '/추천/': '21.png',
  '/인정/': '22.png',
  '/사이다/': '23.png',
  '/더럽/': '24.png',
  '/극혐/': '25.png',
  '/매너챗/': '26.png',
  '/강퇴/': '27.png',
  '/드루와/': '28.png',
  '/야광봉/': '103.png',
  '/아잉/': '29.png',
  '/기겁/': '30.png',
  '/우울/': '31.png',
  '/쳇/': '32.png',
  '/ㅋㅋ/': '33.png',
  '/졸려/': '34.png',
  '/최고/': '35.png',
  '/엉엉/': '36.png',
  '/후훗/': '37.png',
  '/부끄/': '38.png',
  '/제발/': '39.png',
  '/덜덜/': '40.png',
  '/좋아/': '41.png',
  '/반함/': '42.png',
  '/멘붕/': '43.png',
  '/버럭/': '44.png',
  '/우엑/': '45.png',
  '/뽀뽀/': '46.png',
  '/심각/': '47.png',
  '/쥘쥘/': '48.png',
  '/헤헤/': '50.png',
  '/훌쩍/': '49.png',
  '/코피/': '51.png',
  '/철컹철컹/': '52.png',
  '/섬뜩/': '53.png',
  '/꺄/': '54.png',
  '/굿/': '55.png',
  '/글썽/': '56.png',
  '/황당/': '57.png',
  '/정색/': '58.png',
  '/피곤/': '59.png',
  '/사랑/': '60.png',
  '/좌절/': '61.png',
  '/사탕/': '62.png',
  '/RIP/': '63.png',
  '/건빵/': '64.png',
  '/사과/': '65.png',
  '/귤/': '93.png',
  '/겁나좋군/': '66.png',
  '/근육녀/': '101.png',
  '/근육남/': '67.png',
  '/박수/': '68.png',
  '/소주/': '71.png',
  '/짱/': '72.png',
  '/꽃/': '73.png',
  '/왕/': '74.png',
  '/썰렁/': '75.png',
  '/무지개/': '76.png',
  '/태극기/': '77.png',
  '/절교/': '78.png',
  '/하트/': '79.png',
  '/불/': '80.png',
  '/별/': '81.png',
  '/폭탄/': '82.png',
  '/폭죽/': '83.png',
  '/보석/': '84.png',
  '/금/': '85.png',
  '/돈/': '86.png',
  '/맥주/': '87.png',
  '/입술/': '88.png',
  '/콜!/': '89.png',
  '/번쩍/': '90.png',
  '/19/': '91.png',
  '/즐거워/': '92.png',
  '/케이크/': '69.png',
  '/약/': '70.png',

  '/SSG/': 'baseball/1.png',
  '/두산/': 'baseball/2.png',
  '/LG/': 'baseball/3.png',
  '/롯데/': 'baseball/4.png',
  '/삼성/': 'baseball/5.png',
  '/한화/': 'baseball/6.png',
  '/기아/': 'baseball/7.png',
  '/키움/': 'baseball/8.png',
  '/NC/': 'baseball/9.png',
  '/KT/': 'baseball/10.png',
  '/SSG마/': 'baseball/11.png',
  '/두산마/': 'baseball/12.png',
  '/LG마/': 'baseball/13.png',
  '/롯데마/': 'baseball/14.png',
  '/삼성마/': 'baseball/15.png',
  '/한화마/': 'baseball/16.png',
  '/기아마/': 'baseball/17.png',
  '/키움마/': 'baseball/18.png',
  '/NC마/': 'baseball/19.png',
  '/KT마/': 'baseball/20.png',
  '/메가폰/': 'baseball/21.png',
  '/신문/': 'baseball/22.png',
  '/봉투/': 'baseball/23.png',
  '/또리/': 'baseball/24.png',
  '/랜디/': 'baseball/25.png',

  '/광동/': 'lck/L1.png',
  '/T1/': 'lck/L2.png',
  '/DRX/': 'lck/L4.png',
  '/한화생명/': 'lck/L5.png',
  '/젠지/': 'lck/L6.png',
  '/케이티/': 'lck/L8.png',
  '/디플기아/': 'lck/L9.png',
  '/피어엑스/': 'lck/L13.png',
  '/브리온/': 'lck/L11.png',
  '/농심/': 'lck/L14.png',

  '/AXZ/': 'ljl/ljl01.png',
  '/BC/': 'ljl/ljl02.png',
  '/CGA/': 'ljl/ljl03.png',
  '/DFM/': 'ljl/ljl04.png',
  '/FL/': 'ljl/ljl05.png',
  '/SG/': 'ljl/ljl06.png',
  '/SHG/': 'ljl/ljl07.png',
  '/V3/': 'ljl/ljl08.png',

  '/RRQ/': 'vct/vct1.png',
  '/ZETA/': 'vct/vct2.png',
  '/TS/': 'vct/vct3.png',
  '/PRX/': 'vct/vct4.png',
  '/GES/': 'vct/vct5.png',
  '/TLN/': 'vct/vct6.png',
  '/SP/': 'vcl/vcl2.png',
  '/AAA/': 'vcl/vcl3.png',
  '/FS/': 'vcl/vcl4.png',
  '/XIA/': 'vcl/vcl5.png',
  '/NKT/': 'vcl/vcl7.png',
  '/MITH/': 'vcl/vcl8.png',
  '/BNC/': 'vcl/vcl9.png',
  '/VLTH/': 'vcl/vcl10.png',

  '/무친대/': 'starcollege/star1.png',
  '/바스포드/': 'starcollege/star2.png',
  '/염석대/': 'starcollege/star3.png',
  '/우끼끼즈/': 'starcollege/star4.png',
  '/철기중대/': 'starcollege/star5.png',
  '/캄성여대/': 'starcollege/star6.png',
  '/파이스트/': 'starcollege/star7.png',
  '/학버드/': 'starcollege/star8.png',
  '/CP/': 'starcollege/star9.png',
  '/JSA/': 'starcollege/star10.png',
  '/NSU/': 'starcollege/star11.png',
  '/GOU/': 'starcollege/star12.png',
  '/아마대/': 'starcollege/star13.png',
  '/츠나대/': 'starcollege/star14.png',
  '/MSG/': 'starcollege/star15.png',
  '/철와대/': 'starcollege/star16.png',

  '/울산/': 'klg/k1.png',
  '/전북/': 'klg/k2.png',
  '/김천/': 'klg/k3.png',
  '/포항/': 'klg/k4.png',
  '/대구/': 'klg/k5.png',
  '/강원/': 'klg/k6.png',
  '/부산/': 'klg/k7.png',
  '/수원/': 'klg/k8.png',
  '/광주/': 'klg/k9.png',
  '/서울/': 'klg/k10.png',
  '/성남/': 'klg/k11.png',
  '/인천/': 'klg/k12.png',
  '/수원FC/': 'klg/k13.png',
  '/대전/': 'klg/k14.png',
  '/제주/': 'klg/k15.png',
  '/부천/': 'klg/k16.png',
  '/서울E/': 'klg/k17.png',
  '/전남/': 'klg/k18.png',
  '/경남/': 'klg/k19.png',
  '/충남아산/': 'klg/k20.png',
  '/안양/': 'klg/k21.png',
  '/안산/': 'klg/k22.png',
  '/김포/': 'klg/k23.png',

  '/우리카드/': 'vlg/v1.png',
  '/대한항공/': 'vlg/v2.png',
  '/현대캐피탈/': 'vlg/v3.png',
  '/OK금융그룹/': 'vlg/v4.png',
  '/삼성화재/': 'vlg/v5.png',
  '/KB손해보험/': 'vlg/v6.png',
  '/한국전력/': 'vlg/v7.png',
  '/현대건설/': 'vlg/v8.png',
  '/흥국생명/': 'vlg/v9.png',
  '/GS칼텍스/': 'vlg/v10.png',
  '/배구정관장/': 'vlg/v11.png',
  '/IBK기업은행/': 'vlg/v12.png',
  '/도로공사/': 'vlg/v13.png',
  '/AI페퍼스/': 'vlg/v14.png',

  '/KB스타즈/': 'wkbl/wk1.png',
  '/삼성생명/': 'wkbl/wk2.png',
  '/우리은행/': 'wkbl/wk3.png',
  '/신한은행/': 'wkbl/wk4.png',
  '/하나원큐/': 'wkbl/wk5.png',
  '/BNK썸/': 'wkbl/wk6.png',

  '/푸하하/': 'gudok/movingEmoticon/webp/6.webp',
  '/분노/': 'gudok/movingEmoticon/webp/4.webp',
  '/궁금/': 'gudok/movingEmoticon/webp/3.webp',
  '/눈물/': 'gudok/movingEmoticon/webp/5.webp',
  '/쪽/': 'gudok/movingEmoticon/webp/2.webp',
  '/놀람/': 'gudok/movingEmoticon/webp/1.webp',
  '/확인요/': 'gudok/S101.png',
  '/미션/': 'gudok/S102.png',
  '/ㅇㅋ/': 'gudok/S103.png',
  '/티키타카/': 'gudok/S104.png',
  '/ㄱㄴㅇ/': 'gudok/S105.png',
  '/동의/': 'gudok/S106.png',
  '/굿밤/': 'gudok/S107.png',
  '/맴찢/': 'gudok/S108.png',
  '/나이따/': 'gudok/S109.png',
  '/ㄱㄱ/': 'gudok/S110.png',
  '/조오치/': 'gudok/S111.png',
  '/ㄴㅇㅂㅈ/': 'gudok/S112.png',
  '/데헷/': 'gudok/S113.png',
  '/런/': 'gudok/S114.png',
  '/각/': 'gudok/S115.png',
  '/실화/': 'gudok/S116.png',
  '/ㅇㅈ/': 'gudok/S117.png',
  '/ㅇㄱㄹㅇ/': 'gudok/S118.png',
  '/반사/': 'gudok/S119.png',
  '/TMI/': 'gudok/S120.png',
  '/JMT/': 'gudok/S121.png',
  '/할많하않/': 'gudok/S122.png',
  '/현타/': 'gudok/S123.png',
  '/엄근진/': 'gudok/S124.png',
  '/머쓱/': 'gudok/S125.png',
  '/탈룰라/': 'gudok/S126.png',
  '/누나/': 'gudok/S127.png',
  '/탈주/': 'gudok/S128.png',
  '/손절/': 'gudok/S129.png',
  '/하락/': 'gudok/S130.png',
  '/씨익/': 'gudok/S131.png',
  '/양머리/': 'gudok/S132.png',
  '/마스크/': 'gudok/S133.png',
  '/좌정권/': 'gudok/S134.png',
  '/우정권/': 'gudok/S135.png',
  '/천사/': 'gudok/S136.png',
  '/악마/': 'gudok/S137.png',
  '/청순/': 'gudok/S138.png',
  '/신랑/': 'gudok/S139.png',
  '/신부/': 'gudok/S140.png',
  '/로봇/': 'gudok/S141.png',
  '/아프로/': 'gudok/S142.png',

  '/서울SK/': 'kbl/kbl1.png',
  '/농구정관장/': 'kbl/kbl2.png',
  '/수원KT/': 'kbl/kbl3.png',
  '/소노/': 'kbl/kbl4.png',
  '/울산현대모비스/': 'kbl/kbl5.png',
  '/대구한국가스공사/': 'kbl/kbl6.png',
  '/창원LG/': 'kbl/kbl7.png',
  '/원주DB/': 'kbl/kbl8.png',
  '/농구KCC/': 'kbl/kbl9.png',
  '/서울삼성/': 'kbl/kbl10.png',
};

const gifEmoticions = {
  '/푸하하/': 'gudok/movingEmoticon/gif/6.gif',
  '/분노/': 'gudok/movingEmoticon/gif/4.gif',
  '/궁금/': 'gudok/movingEmoticon/gif/3.gif',
  '/눈물/': 'gudok/movingEmoticon/gif/5.gif',
  '/쪽/': 'gudok/movingEmoticon/gif/2.gif',
  '/놀람/': 'gudok/movingEmoticon/gif/1.gif',
};

const old_emoticons = {
  '(::A1)': '1.png',
  '(::A2)': '2.png',
  '(::A3)': '3.png',
  '(::A4)': '4.png',
  '(::A5)': '5.png',
  '(::A6)': '6.png',
  '(::A7)': '7.png',
  '(::A8)': '8.png',
  '(::A9)': '9.png',
  '(::A10)': '10.png',
  '(::A11)': '11.png',
  '(::A12)': '12.png',
  '(::A13)': '13.png',
  '(::A94)': '94.png',
  '(::A95)': '95.png',
  '(::A96)': '96.png',
  '(::A97)': '97.png',
  '(::A98)': '98.png',
  '(::A99)': '99.png',
  '(::A100)': '100.png',
  '(::A14)': '14.png',
  '(::A15)': '15.png',
  '(::A16)': '16.png',
  '(::A17)': '17.png',
  '(::A18)': '18.png',
  '(::A19)': '19.png',
  '(::A20)': '20.png',
  '(::A21)': '21.png',
  '(::A22)': '22.png',
  '(::A23)': '23.png',
  '(::A24)': '24.png',
  '(::A25)': '25.png',
  '(::A26)': '26.png',
  '(::A27)': '27.png',
  '(::A28)': '28.png',
  '(::A29)': '29.png',
  '(::A30)': '30.png',
  '(::A31)': '31.png',
  '(::A32)': '32.png',
  '(::A33)': '33.png',
  '(::A34)': '34.png',
  '(::A35)': '35.png',
  '(::A36)': '36.png',
  '(::A37)': '37.png',
  '(::A38)': '38.png',
  '(::A39)': '39.png',
  '(::A40)': '40.png',
  '(::A41)': '41.png',
  '(::A42)': '42.png',
  '(::A43)': '43.png',
  '(::A44)': '44.png',
  '(::A45)': '45.png',
  '(::A46)': '46.png',
  '(::A47)': '47.png',
  '(::A48)': '48.png',
  '(::A50)': '50.png',
  '(::A49)': '49.png',
  '(::A51)': '51.png',
  '(::A52)': '52.png',
  '(::A53)': '53.png',
  '(::A54)': '54.png',
  '(::A55)': '55.png',
  '(::A56)': '56.png',
  '(::A57)': '57.png',
  '(::A58)': '58.png',
  '(::A59)': '59.png',
  '(::A60)': '60.png',
  '(::A61)': '61.png',
  '(::A62)': '62.png',
  '(::A63)': '63.png',
  '(::A64)': '64.png',
  '(::A65)': '65.png',
  '(::A93)': '93.png',
  '(::A66)': '66.png',
  '(::A101)': '101.png',
  '(::A67)': '67.png',
  '(::A68)': '68.png',
  '(::A71)': '71.png',
  '(::A72)': '72.png',
  '(::A73)': '73.png',
  '(::A74)': '74.png',
  '(::A75)': '75.png',
  '(::A76)': '76.png',
  '(::A77)': '77.png',
  '(::A78)': '78.png',
  '(::A79)': '79.png',
  '(::A80)': '80.png',
  '(::A81)': '81.png',
  '(::A82)': '82.png',
  '(::A83)': '83.png',
  '(::A84)': '84.png',
  '(::A85)': '85.png',
  '(::A86)': '86.png',
  '(::A87)': '87.png',
  '(::A88)': '88.png',
  '(::A89)': '89.png',
  '(::A90)': '90.png',
  '(::A91)': '91.png',
  '(::A92)': '92.png',
  '(::A69)': '69.png',
  '(::A70)': '70.png',
  '(::B1)': 'baseball/1.png',
  '(::B2)': 'baseball/2.png',
  '(::B3)': 'baseball/3.png',
  '(::B4)': 'baseball/4.png',
  '(::B5)': 'baseball/5.png',
  '(::B6)': 'baseball/6.png',
  '(::B7)': 'baseball/7.png',
  '(::B8)': 'baseball/8.png',
  '(::B9)': 'baseball/9.png',
  '(::B10)': 'baseball/10.png',
  '(::B11)': 'baseball/11.png',
  '(::B12)': 'baseball/12.png',
  '(::B13)': 'baseball/13.png',
  '(::B14)': 'baseball/14.png',
  '(::B15)': 'baseball/15.png',
  '(::B16)': 'baseball/16.png',
  '(::B17)': 'baseball/17.png',
  '(::B18)': 'baseball/18.png',
  '(::B19)': 'baseball/19.png',
  '(::B20)': 'baseball/20.png',
  '(::B21)': 'baseball/21.png',
  '(::B22)': 'baseball/22.png',
  '(::B23)': 'baseball/23.png',
};

let emoticonsProperty = {};

export const getEmoticonKeys = () => {
  if (!Object.prototype.hasOwnProperty.call(emoticonsProperty, 'keys')) {
    emoticonsProperty['keys'] = Object.keys(emoticons);
  }
  return emoticonsProperty['keys'];
};

export const getEmoticonValues = () => {
  if (!Object.prototype.hasOwnProperty.call(emoticonsProperty, 'values')) {
    emoticonsProperty['values'] = Object.values(emoticons);
  }
  return emoticonsProperty['values'];
};

const findEmoticonByFilename = (filename) => {
  const index = getEmoticonValues().indexOf(filename);
  return index >= 0 ? getEmoticonKeys()[index] : null;
};
const findEmoticonByWord = (word) => {
  const index = getEmoticonKeys().indexOf(word);
  return index >= 0 ? getEmoticonValues()[index] : null;
};

// 움직이는 구독 이모티콘 webp OnError일 경우 함수
const gifEmoticionOnError = (match) => {
  let gifEmoticonsProperty = {};

  if (!Object.prototype.hasOwnProperty.call(gifEmoticonsProperty, 'keys')) {
    gifEmoticonsProperty['keys'] = Object.keys(gifEmoticions);
  }

  const index = gifEmoticonsProperty['keys'].indexOf(match);
  const filename = index >= 0 ? gifEmoticions[match] : null;

  // 움직이는 이모티콘 gif 배열에 있는 이모티콘일 경우에만 gif 이미지 경로 return
  if (filename === null) {
    return `this.onerror=null`;
  }

  return `this.src='${EMOTICON_URL}${filename}'`;
};

/**
 * 문자열내의 이모티콘 이미지 태그를  텍스트코드(/코드/) 로 변환 합니다.
 * @param {*} str
 */
export const encodeEmoticon = (str) => {
  if (!str || str === '') return '';
  var emojiRegex = new RegExp(
    '<img[^>]+src\\s*=\\s*[\'"]([^\'"]+)[\'"][^>]*>',
    'gi',
  );
  return str.replace(emojiRegex, function (match, text) {
    var filename = text.replace(EMOTICON_URL, '');
    return findEmoticonByFilename(filename);
  });
};

/**
 * 이모티콘 코드를  이미지파일로 교체
 * @param {*} str
 */
export const decodeEmoticon = (str) => {
  if (!str || str === '') return '';
  str = decodeOldEmoticon(str); //지난 이모티콘 바꾸기
  var emojiRegex = new RegExp(getEmoticonKeys().join('|'), 'gi');
  return str.replace(emojiRegex, function (match) {
    const filename = findEmoticonByWord(match);
    return `<img src="${EMOTICON_URL}${filename}" alt="${match}" onError="${gifEmoticionOnError(match)}" loading="lazy"  class="emtn"/>`;
  });
};

export const decodeEmoticonWithoutUrl = (str, autoLink = false) => {
  let matches = Autolinker.parse(str, {
    urls: {schemeMatches: true, wwwMatches: true, tldMatches: false},
    stripPrefix: false,
    stripTrailingSlash: false,
  });
  let newHtml = [],
    lastIndex = 0;
  for (let i = 0, len = matches.length; i < len; i++) {
    let match = matches[i];
    let matchStr = str.substring(lastIndex, match.getOffset());
    newHtml.push(decodeEmoticon(matchStr));
    if (autoLink) {
      newHtml.push(match.buildTag().toAnchorString());
    } else {
      newHtml.push(match.getMatchedText());
    }
    lastIndex = match.getOffset() + match.getMatchedText().length;
  }
  newHtml.push(decodeEmoticon(str.substring(lastIndex))); // handle the text after the last match

  return newHtml.join('');
};

/**
 * 지난 이모티콘 코드를  이미지파일로 교체
 * @param {*} str
 */
export const decodeOldEmoticon = (str) => {
  if (!str || str === '') return '';
  return str.replace(/\(::[A|B]\d+\)/g, function (match) {
    const filename = old_emoticons[match];
    return `<img src="${EMOTICON_URL}${filename}"  class="emtn" loading="lazy"/>`;
  });
};

/**
 * 문자열 -> 태그로 교체
 * @param {*} word
 */
export const getEmoticonTagByWord = (word) => {
  const filename = findEmoticonByWord(word);
  if (filename) {
    const url = `${EMOTICON_URL}${findEmoticonByWord(word)}`;
    return `<img src="${url}" alt="${word}"  class="emtn" width="27" height="27" loading="lazy"/>`;
  } else {
    return null;
  }
};

export const isOrContains = (node, container) => {
  while (node) {
    if (node === container) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};
export const setEndOfContenteditable = (contentEditableElement) => {
  var range, selection;
  if (document.createRange) {
    //Firefox, Chrome, Opera, Safari, IE 9+
    range = document.createRange(); //Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
    range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
    selection = window.getSelection(); //get the selection object (allows you to change selection)
    selection.removeAllRanges(); //remove any selections already made
    selection.addRange(range); //make the range you have just created the visible selection
  } else if (document.selection) {
    //IE 8 and lower
    range = document.body.createTextRange(); //Create a range (a range is a like the selection but invisible)
    range.moveToElementText(contentEditableElement); //Select the entire contents of the element with the range
    range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
    range.select(); //Select the range (make it the visible selection
  }
};

export const elementContainsSelection = (el) => {
  var sel;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.rangeCount > 0) {
      for (var i = 0; i < sel.rangeCount; ++i) {
        if (!isOrContains(sel.getRangeAt(i).commonAncestorContainer, el)) {
          return false;
        }
      }
      return true;
    }
  } else if ((sel = document.selection) && sel.type !== 'Control') {
    return isOrContains(sel.createRange().parentElement(), el);
  }
  return false;
};

export const pasteHtmlAtCaret = (html, dom) => {
  var sel, range;
  if (window.getSelection) {
    // IE9 and non-IE
    sel = window.getSelection();
    if (elementContainsSelection(dom)) {
      if (sel.getRangeAt && sel.rangeCount) {
        range = sel.getRangeAt(0);
        range.deleteContents();

        // Range.createContextualFragment() would be useful here but is
        // non-standard and not supported in all browsers (IE9, for one)
        var el = document.createElement('div');
        el.innerHTML = html;
        var frag = document.createDocumentFragment(),
          node,
          lastNode;
        while ((node = el.firstChild)) {
          lastNode = frag.appendChild(node);
        }
        range.insertNode(frag);

        // Preserve the selection
        if (lastNode) {
          range = range.cloneRange();
          range.setStartAfter(lastNode);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
        }
        //IOS에서 이모티콘선택시 이벤트가 발생하지 않아 임의 set_emoticon 이벤트를 날림
        if (isIOS) {
          dom.dispatchEvent(new Event('set_emoticon'));
        }
      } else if (document.selection && document.selection.type !== 'Control') {
        // IE < 9
        document.selection.createRange().pasteHTML(html);
      }
    } else {
      setEndOfContenteditable(dom);
      pasteHtmlAtCaret(html, dom);
    }
  }
};
