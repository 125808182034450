import globalDomains from '@/utils/global-domains';
import {isLogin} from '@/utils/main';

export default function PrivateRoute({children}) {
  const isLoggedIn = isLogin();

  if (isLoggedIn) {
    return children;
  }

  if (!isLoggedIn) {
    location.replace(
      `${globalDomains.LOGIN_AFREECATV}/afreeca/login.php?szFrom=full&request_uri=${window.location}`,
    );
  }
}
