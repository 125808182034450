'use client';
import {useScrollTop} from '@/components/main/common/hooks/useScrollTop';
import {isLogin} from '@/utils/main';
import {sendClickLog} from '@/utils/main/my-plus/log';

export const FloatingButton = ({selectedMode, handleOnClickMode}) => {
  const {isDisplay, scrollToTop} = useScrollTop();
  const isPrefer = selectedMode === 'prefer' && isLogin();

  const handleClickToggle = () => {
    handleOnClickMode(`${isPrefer ? 'popular' : 'prefer'}`);
    sendClickLog('main_list_button', {
      button_type: `${isPrefer ? 'list_hot' : 'list_recommend'}`,
    });
  };

  return (
    <>
      <button
        type='button'
        className={`page_top ${isDisplay ? 'active' : ''}`}
        onClick={scrollToTop}>
        상단 바로가기
      </button>
      {isLogin() && (
        <>
          <button
            tip={isPrefer ? '인기 모드' : '선호 모드'}
            type='button'
            className={`${isPrefer ? 'prefer_mode active' : 'popular_mode active'}`}
            onClick={handleClickToggle}>
            <span className='tooltip'>선호 모드</span>
          </button>
        </>
      )}
    </>
  );
};
