import useMainStore from '@/stores/main';
import {getCookie} from '@/utils/main';
import {useEffect} from 'react';

export const useResizeWidth = () => {
  const {
    checkAd,
    banner,
    isCloseMainBanner,
    setIsShowMainBanner,
    setResizeCount,
  } = useMainStore();

  const updateWidth = (container) => {
    if (!container) return;
    setResizeCount(getResizeCount(container));
  };

  useEffect(() => {
    const container = document.getElementById('container');
    if (!container) return;
    const handleResize = () => updateWidth(container);

    const observer = new ResizeObserver(handleResize);
    observer.observe(container);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!checkAd.banners.hasOwnProperty('bAdPcMainBanner')) return;
    const count = getCookie('lastMainBannerViewCount');
    const handleResize = () => {
      if (
        checkAd.banners.hasOwnProperty('bAdPcMainBanner') &&
        checkAd.banners.bAdPcMainBannerCount >= count
      ) {
        const width = window.innerWidth; //스크롤바 영역때문에 window.innerWidth 기준으로 취득
        if (width <= 1200) {
          setIsShowMainBanner(false);
        } else {
          if (!isCloseMainBanner && banner.main.exist) {
            setIsShowMainBanner(true);
          }
        }
      }
    };
    const wrap = document.getElementById('soop_wrap');
    const observer = new ResizeObserver(handleResize);
    if (wrap) {
      observer.observe(wrap);
    }
    return () => {
      observer.disconnect();
    };
  }, [banner.main, isCloseMainBanner]);
};

function getResizeCount(container) {
  if (container) {
    const width = container.clientWidth;
    let count = 1;
    if (width >= 1687) {
      count = 6;
    } else if (width >= 1261 && width < 1687) {
      count = 5;
    } else if (width >= 1060 && width < 1260) {
      count = 4;
    } else if (width >= 810 && width < 1060) {
      count = 3;
    } else if (width >= 556 && width < 810) {
      count = 2;
    } else {
      count = 1;
    }

    return count;
  }
}
