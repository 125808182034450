import {create} from 'zustand';
import _ from 'lodash';
import {devtools} from 'zustand/middleware';
import {DIALOG_BUTTON_TYPES} from '@/constants';

const store = (set) => {
  return {
    dialogList: [],
    /* addDialog: (id, name, option, data, deferred) =>
      set((prevState) => {
        dialogList: [
          ...prevState.dialogList,
          {id, name, option, data, _deferred: deferred},
        ];
      }),*/
    // 뎁스가 이게 맞냐
    addDialog: (id, name, data, option, deferrer) => {
      return set((prevState) => {
        return {
          dialogList: [
            ...prevState.dialogList,
            {id, name, option, data, _deferrer: deferrer},
          ],
        };
      });
    },
    popDialog: (returnValue, isPositive) => {
      return set((prevState) => {
        /* pop 직접 수정하는게 안먹히는구나?
        const removeItem = prevState.dialogList.pop();
        if (isPositive) {
          removeItem._deferrer.resolve(returnValue); // _deferrer.promise를 통해 다이얼로그 리턴값 전달
        } else {
          removeItem._deferrer.reject();
        }
        return {
          dialogList: prevState.dialogList,
        };*/
        const removeItem = _.last(prevState.dialogList);
        if (isPositive) {
          removeItem._deferrer.resolve({
            data: returnValue,
            action: DIALOG_BUTTON_TYPES.positive,
          }); // _deferrer.promise를 통해 다이얼로그 리턴값 전달
        } else {
          removeItem._deferrer.resolve({
            data: null,
            action: DIALOG_BUTTON_TYPES.negative,
          });
        }

        return {
          dialogList: prevState.dialogList.filter((dialog) => {
            return dialog.id !== removeItem.id;
          }),
        };
      });
    },
    removeDialog: (dialogId, returnValue) => {
      return set((prevState) => {
        return {
          dialogList: prevState.dialogList.filter((dialog) => {
            return dialog.id !== dialogId;
          }),
        };
      });
    },
  };
};
const useDialogStore = create(devtools(store));

export default useDialogStore;
