import {useEffect, useState} from 'react';

import {ContentListRenderer} from '@/components/search/group/game-profile-group';

/**
 * OfficialChannels 컴포넌트 ( 공식 채널 )
 * @param {Object} props - 컴포넌트의 props
 * @param {Object} channelList - 공식 채널 리스트
 * @param {Function} logCallback 로그 관련 콜백 함수
 * @returns OfficialChannels 컴포넌트
 */
const OfficialChannels = ({
  channelList = [],
  logCallback = () => {},
}) => {
  const [stationList, setStationList] = useState(channelList);

  useEffect(() => {
    setStationList(channelList);
  }, [channelList]);

  return (
    <div className='game_strm'>
      <ContentListRenderer list={stationList} logCallback={logCallback} />
    </div>
  );
}

export default OfficialChannels
