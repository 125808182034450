'use client';

import {SoopUiSwiper} from '@/components/ui';

export default function MainBanners({bannerList = []}) {
  return (
    <div className='main_banner'>
      <SoopUiSwiper
        swiperTag='ul'
        swiperClass='banner_list'
        maxSlidesPerView={4}>
        {bannerList.map((banner, index) => {
          return (
            <li key={index}>
              <a href='#'>
                <span className='thumb'>
                  <img
                    src='https://admin.img.afreecatv.com/main_list_top_banner/2023/06/28/8349649ba3a307214.png'
                    alt=''
                  />
                </span>
                <div className='info'>
                  <span className='date'>{banner.period}</span>
                  <strong>다음슬라이드</strong>
                  <p>
                    다음슬라이드다음슬라
                    <br />
                    이드다음슬드
                  </p>
                </div>
              </a>
              <button type='button' className='alarm'>
                방송 알람
              </button>
            </li>
          );
        })}
      </SoopUiSwiper>
    </div>
  );
}
