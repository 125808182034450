import '/public/static/css/module/category.scss';
import MyPlusApi from '@/api/main/my-plus';
import {DEFAULT_POSTER_THUMB_TMG} from '@/components/ui/SoopUiImg';
import {SectionHeader} from '@/components/main/common';
import {convertViewCnt, isLogin} from '@/utils/main';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {sendClickLog} from '@/utils/main/my-plus/log';
import useGlobalUIStore from '@/stores';

export const CategoryList = ({selectedMode}) => {
  const [categoryList, setCategoryList] = useState([]);
  const [headerText, setHeaderText] = useState('인기 카테고리');
  const {isDarkMode} = useGlobalUIStore();
  const isPrefer = selectedMode === 'prefer' && isLogin();

  const handleImageError = (e) => {
    const imgElement = e.target;
    imgElement.src = DEFAULT_POSTER_THUMB_TMG(isDarkMode);
    imgElement.setAttribute('data-replaced', 'true');
  };

  useEffect(() => {
    getPreferCategoryList();
  }, []);

  useEffect(() => {
    // 클라이언트에서만 실행
    if (isPrefer) {
      setHeaderText('카테고리');
    }
  }, [selectedMode]);

  const getPreferCategoryList = async () => {
    try {
      const param = {
        type: isPrefer ? selectedMode : 'popular',
      };
      const response = await MyPlusApi.getPreferCategoryList(param);
      if (response && response.data) {
        setCategoryList(response.data);
      }
    } catch (error) {
      setCategoryList([]);
    }
  };

  const handleClickCategory = (item, idx) => {
    const location = isPrefer ? 'myplus' : selectedMode;
    sendClickLog('cate_list', {
      buttonType: `${location}_cate`,
      location: location,
      filterId: item.categoryName,
      filterIdx: idx + 1,
    });
  };

  return (
    <>
      {categoryList && categoryList.length > 0 && (
        <>
          <SectionHeader
            customElement={
              <>
                <h2>{headerText}</h2>
                <Link to={`/directory/category`} className='more'>
                  전체보기
                </Link>
              </>
            }
          />
          <div className='category_conts'>
            <ul>
              {categoryList.map((item, idx) => (
                <li key={idx} onClick={() => handleClickCategory(item, idx)}>
                  <Link
                    to={`/directory/category/${encodeURIComponent(item.categoryName)}/live`}>
                    {item.viewCnt > 0 && (
                      <span className='views'>
                        <em>{convertViewCnt(item.viewCnt)}</em>
                      </span>
                    )}
                    <div className='thumb'>
                      <img
                        src={item.cateImg}
                        alt={item.categoryName}
                        onError={handleImageError}
                      />
                    </div>
                    <div className='info_wrap'>
                      <p className='title'>{item.categoryName}</p>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </>
  );
};
