'use client';

import MyLayout from '@/app/(main)/my/layout';
import MyFavorite from '@/app/(main)/my/favorite';
import MySubscribe from '@/app/(main)/my/subscribe';
import MyFan from '@/app/(main)/my/fan';
import MyHistoryRecent from '@/app/(main)/my/history/recent';
import MyHistoryLayout from '@/app/(main)/my/history/layout';
import MyHistoryRecentVod from '@/app/(main)/my/history/recent/vod';
import MyHistoryLater from '@/app/(main)/my/history/later';
import MyHistoryUp from '@/app/(main)/my/history/up';
import MyHistoryPlaylist from '@/app/(main)/my/history/playlist';
import MyHistoryStory from '@/app/(main)/my/history/story';
import MyHotissue from '@/app/(main)/my/feed/hotissue';
import MyFeed from '@/app/(main)/my/feed';
import MyFeedUserId from '@/app/(main)/my/feed/[user-id]';
import {Route, Routes} from 'react-router-dom';
import CustomRoutes from './CustomRoutes';
import {ROUTE} from '@/constants/main/route';

export default function MyRoute() {
  return (
    <CustomRoutes>
      <Route element={<MyLayout />}>
        <Route path={ROUTE.MY.FAVORITE} element={<MyFavorite />} />
        <Route path={ROUTE.MY.SUBSCRIBE} element={<MySubscribe />} />
        <Route path={ROUTE.MY.FAN} element={<MyFan />} />
        <Route element={<MyHistoryLayout />}>
          <Route
            path={ROUTE.MY.HISTORY.RECENT.INDEX}
            element={<MyHistoryRecent />}
          />
          <Route
            path={ROUTE.MY.HISTORY.RECENT.VOD}
            element={<MyHistoryRecentVod />}
          />
          <Route path={ROUTE.MY.HISTORY.LATER} element={<MyHistoryLater />} />
          <Route path={ROUTE.MY.HISTORY.UP} element={<MyHistoryUp />} />
          <Route
            path={ROUTE.MY.HISTORY.PLAYLIST}
            element={<MyHistoryPlaylist />}
          />
        </Route>
        <Route path={ROUTE.MY.HISTORY.STORY} element={<MyHistoryStory />} />
      </Route>
      <Route path={ROUTE.MY.FEED.HOT_ISSUE} element={<MyHotissue />}></Route>
      <Route path={ROUTE.MY.FEED.INDEX} element={<MyFeed />}></Route>
      <Route path={ROUTE.MY.FEED._USER_ID} element={<MyFeedUserId />}></Route>
    </CustomRoutes>
  );
}
