import {useEffect, useState} from 'react';

import {SectionHeader} from '@/components/main/common';
import {SoopUiSelectBox} from '@/components/ui';
import {ContentListRenderer} from '@/components/search/group/game-profile-group';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

import {SEARCH_GAME_PROFILE_STREAMER_FILTER} from '@/constants/search/filter';

/**
 * RelatedStreamers 컴포넌트 ( 관련 스트리머 )
 * @param {Object} props - 컴포넌트의 props
 * @param {Object} streamer - 관련 스트리머 데이터
 * @param {Object} activeTab - 게임 프로필 영역에서 현재 활성화 되어있는 탭
 * @param {Object} tabName - 게임 프로필 영역에서 현재 활성화 되어있는 탭 이름
 * @param {Function} logCallback 로그 관련 콜백 함수
 * @param {Function} fetchGameProfileThemeStreamerList 추가로 관련 스트리머 데이터 가져오기 위한 메소드
 * @returns RelatedStreamers 컴포넌트
 */
const RelatedStreamers = ({
  streamer = [],
  logCallback = () => {},
  fetchGameProfileThemeStreamerList = () => {},
}) => {
  const {gameProfileOrder, setGameProfileOrder} = useSearchGlobalStatus();

  const [type, setType] = useState('desc');
  const [streamerList, setStreamerList] = useState(streamer);
  const [resetSwiper, setResetSwiper] = useState(false);

  // 게임프로필 > 관련 스트리머 영역에서 다음 버튼 클릭시 관련스트리머 데이터 100개를 다시 받아옴
  const handleCustomMovingButtonClicked = async () => {
    if (streamerList.length <= 10) {
      const additionalStreamerList =
        await fetchGameProfileThemeStreamerList(100);
      setStreamerList((oldStreamerList) => {
        const newList = [...oldStreamerList, ...additionalStreamerList];
        return newList;
      });
    }
  };

  // 관련 스트리머 필터 관련 로직
  const handleChangeType = (filters) => {
    setResetSwiper(true);
    setType(filters);
    if (filters === 'asc') {
      setGameProfileOrder([{value: 'current_sum_viewer', orderType: 'asc'}]);
    } else if (filters === 'desc') {
      setGameProfileOrder([{value: 'current_sum_viewer', orderType: 'desc'}]);
    } else {
      setGameProfileOrder([{value: 'broad_start', orderType: ''}]);
    }
  };

  // 관련 스트리머 탭으로 돌아왔을 때 정렬 기준이 '참여자수(높은순)'으로 설정되어 있지 않으면
  // '참여자수(높은순)'으로 정렬 기준 초기화
  useEffect(() => {
    if (gameProfileOrder[0].orderType !== 'desc') {
      setType('desc');
      setGameProfileOrder([{value: 'current_sum_viewer', orderType: 'desc'}]);
    }
  }, []);

  useEffect(() => {
    setStreamerList(streamer);
    setResetSwiper(false);
  }, [streamer]);

  return (
    <div className='game_strm'>
      <SectionHeader
        rightElement={
          <SoopUiSelectBox
            options={SEARCH_GAME_PROFILE_STREAMER_FILTER}
            type={type}
            onChange={handleChangeType}
          />
        }
      />

      <ContentListRenderer
        list={streamerList}
        logCallback={logCallback}
        resetSlideOnUpdate={resetSwiper}
        handleCustomMovingButtonClicked={handleCustomMovingButtonClicked}
      />
    </div>
  );
}

export default RelatedStreamers