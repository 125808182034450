'use client';
import DirectoryApi from '@/api/directory/directory';
import {DirectoryCategoryList} from '@/components/main/directory/DirectoryCategoryList';
import {DirectoryCategoryTitle} from '@/components/main/directory/DirectoryCategoryTitle';
import {DIRECTORY_INIT_LIST, MAIN_LIST_CNT} from '@/constants/main';
import useDirectoryStore from '@/stores/directory';
import {useEffect, useState} from 'react';
import '/public/static/css/module/category.scss';

export const DirectoryCategory = () => {
  const [page, setPage] = useState(1);
  const [categoryList, setCategoryList] = useState({});
  const [searchText, setSearchText] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const {
    selectedCategoryMenu,
    selectedCategoryListOrder,
    setSelectedCategoryListOrder,
  } = useDirectoryStore();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 300); //검색할때마다 호출보다는 디바운싱처리를 줘서 입력이 끝나고 나서 api 호출

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  useEffect(() => {
    getCategoryList();
  }, [page, selectedCategoryListOrder, debouncedSearchText]);

  const getCategoryList = async () => {
    try {
      const queryParams = {
        m: 'categoryList',
        szKeyword: debouncedSearchText,
        szOrder: selectedCategoryListOrder,
        nPageNo: page,
        nListCnt: MAIN_LIST_CNT * 2,
        nOffset: categoryList?.offset ? categoryList.offset : 0,
        szPlatform: 'pc',
      };
      const response = await DirectoryApi.getCategoryList(queryParams);
      if (response) {
        if (page > 1) {
          setCategoryList({
            isMore: response.isMore,
            offset: response.offset,
            list: [...categoryList.list, ...response.list],
          });
        } else {
          setCategoryList({
            isMore: response.isMore,
            offset: response.offset,
            list: [...response.list],
          });
        }
      }
    } catch (error) {
      setCategoryList(DIRECTORY_INIT_LIST);
    }
  };

  const handleOnChangeSearch = (e) => {
    if (e.target.value.length > 50) {
      return;
    }
    setPage(1);
    setSearchText(e.target.value);
  };

  const handleOnClickSort = (type) => {
    if (type === selectedCategoryListOrder) {
      return;
    }
    setPage(1);
    setSelectedCategoryListOrder(type);
    setCategoryList(DIRECTORY_INIT_LIST);
  };

  const handleOnClickMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleOnClickClearSearchText = () => {
    setPage(1);
    setSearchText('');
  };

  const directoryCategoryTitleProps = {
    order: selectedCategoryListOrder,
    searchText,
    selectedCategoryMenu,
    handleOnClickSort,
    handleOnChangeSearch,
    handleOnClickClearSearchText,
  };

  const directoryCategoryListProps = {
    searchText,
    categoryList,
    handleOnClickMore,
  };

  return (
    <>
      <DirectoryCategoryTitle {...directoryCategoryTitleProps} />
      <DirectoryCategoryList {...directoryCategoryListProps} />
    </>
  );
};
