'use client';

import MyFavoriteApi from '@/api/my/favorite';
import {SectionHeader} from '@/components/main/common';
import {useEffect, useState} from 'react';
import MyFavoriteCatch from './Catch';

const MAX_SIZE = 30;

/**
 * 즐겨찾기 캐치스토리 컴포넌트
 * @desc API 로부터 캐치스토리를 가져오고 필드명을 맞춰서 MyCatchGroup 컴포넌트에 전달한다.
 * @returns {JSX.Element} 캐치스토리
 */
const CatchStoryWrapper = ({groupId}) => {
  const [stories, setStories] = useState([]);

  const getCatchStories = async () => {
    try {
      const catchStories = await MyFavoriteApi.getCatchStory(groupId);
      setStories(catchStories.slice(0, MAX_SIZE));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCatchStories();
  }, [groupId]);

  const formattedStories = stories?.map((story) => {
    const {
      copyrightUserId,
      storyIdx,
      thumb,
      resolutionType,
      copyrightUserNick,
      broadDate,
      grade,
      category,
      ...rest
    } = story;

    return {
      ...rest,
      catchId: storyIdx,
      thumbnailSrc: thumb,
      isHorizontal: resolutionType === 'horizontal',
      userNickname: copyrightUserNick,
      broadcastDate: broadDate,
      userId: copyrightUserId,
      isAdult: grade === '19' && Number(category) === 30000,
      fileType: 'CATCH_STORY',
    };
  });

  const hasStories = formattedStories?.length > 0;

  return (
    hasStories && (
      <>
        <SectionHeader title='Catch 스토리' />
        <div className='catch_story_area'>
          <MyFavoriteCatch catches={formattedStories} isStory />
        </div>
      </>
    )
  );
};

export default CatchStoryWrapper;
