export const LIVE_SORT_OPTION = [
  {label: '참여자수(높은순)', type: 'view_cnt'},
  {label: '참여자수(낮은순)', type: 'view_cnt_asc'},
  {label: '최근 시작', type: 'broad_start'},
];

export const LIVE_CONTEXT_MENU = [
  {label: '나중에 보기', type: 'view-later'},
  {label: '공유하기', type: 'share'},
  {label: '스트리머 숨기기', type: 'hidden'},
];

export const VOD_CONTEXT_MENU = [
  {label: '나중에 보기', type: 'view-later'},
  {label: '재생목록에 담기', type: 'add-playlist'},
  {label: '공유하기', type: 'share'},
];

export const MY_PLUS_LIVE_CONTEXT_MENU = [
  {label: '나중에 보기', type: 'view-later'},
  {label: '공유하기', type: 'share'},
  {label: '추천받지 않기', type: 'not-recommend'},
  {label: '스트리머 숨기기', type: 'hidden'},
];

export const MY_PLUS_VOD_CONTEXT_MENU = [
  {label: '나중에 보기', type: 'view-later'},
  {label: '재생목록에 담기', type: 'add-playlist'},
  {label: '공유하기', type: 'share'},
  {label: '추천받지 않기', type: 'not-recommend'},
  {label: '스트리머 숨기기', type: 'hidden'},
];

export const CATCH_CONTEXT_MENU = [
  {label: '공유하기', type: 'share'},
  {label: '스트리머 숨기기', type: 'hidden'},
];

export const PLAYLIST_CONTEXT_MENU = [
  {label: '공유하기', type: 'share'},
  {label: '재생목록 퍼가기', type: 'playlist-share'},
];

export const DIRECTORY_CATEGORYT_SORT_OPTION = [
  {label: '인기', type: 'view_cnt'},
  {label: '선호', type: 'prefer'},
  {label: '최근 추가', type: 'reg_date'},
];

export const DIRECTORY_CONTENT_SORT_OPTION = [
  {
    type: 'live',
    items: [
      {label: '참여자수(높은순)', type: 'view_cnt_desc'},
      {label: '참여자수(낮은순)', type: 'view_cnt_asc'},
      {label: '최근 시작', type: 'reg_date'},
    ],
  },

  {
    type: 'vod',
    items: [
      {
        key: 'sort',
        title: '정렬',
        item: [
          {label: '조회수', type: 'view_cnt_desc'},
          {label: 'UP수', type: 'like_cnt'},
          {label: '최근 등록', type: 'reg_date'},
        ],
      },
      {
        key: 'fileType',
        title: '구분',
        item: [
          {label: '전체', type: 'ALL'},
          {label: '다시보기', type: 'REVIEW'},
          {label: '업로드 VOD', type: 'NORMAL'},
          {label: '유저클립', type: 'CLIP'},
        ],
      },
      {
        key: 'period',
        title: '기간',
        item: [
          {label: '전체', type: 'all'},
          {label: '1일', type: '1day'},
          {label: '1주', type: '1week'},
          {label: '1개월', type: '1month'},
          {label: '1년', type: '1year'},
        ],
      },
    ],
  },

  {
    type: 'catch',
    items: [
      {
        key: 'sort',
        title: '정렬',
        item: [
          {label: '조회수', type: 'view_cnt_desc'},
          {label: 'UP수', type: 'like_cnt'},
          {label: '최근 등록', type: 'reg_date'},
        ],
      },
      {
        key: 'period',
        title: '기간',
        item: [
          {label: '전체', type: 'all'},
          {label: '1일', type: '1day'},
          {label: '1주', type: '1week'},
          {label: '1개월', type: '1month'},
          {label: '1년', type: '1year'},
        ],
      },
    ],
  },
];

export const DIRECTORY_LIVE_CONTEXT_MENU = [
  {label: '나중에 보기', type: 'view-later'},
  {label: '공유하기', type: 'share'},
];

export const DIRECTORY_VOD_CONTEXT_MENU = [
  {label: '나중에 보기', type: 'view-later'},
  {label: '재생목록에 담기', type: 'add-playlist'},
  {label: '공유하기', type: 'share'},
];

export const MAIN_MENU_LIST = [
  {
    id: 'all',
    name: '전체',
  },
  {
    id: 'game',
    name: '게임',
  },
  {
    id: 'bora',
    name: '보이는 라디오',
  },
  {
    id: 'sports',
    name: '스포츠',
  },
];

export const MAIN_LIST_CNT = 60;
export const DIRECTORY_LIST_CNT = 120;
export const LIVE_INIT_LIST = {isMore: false, list: []};
export const DIRECTORY_INIT_LIST = {isMore: false, offset: 0, list: []};
export const DIRECTORY_CONTENT_INIT_LIST = {isMore: false, list: []};
export const PREFER_INIT_LIVE_LIST = {liveCnt: 0, liveList: []};
export const PREFER_INIT_VOD_LIST = {vodCnt: 0, vodList: []};
export const POPULAR_INIT_LIVE_LIST = {totalCnt: 0, cnt: 0, broad: []};
