import Post from '@/components/my/common/item/MyPost';
import {SoopUiSwiper} from '@/components/ui';
import '/public/static/css/module/my_new_post.scss';
import {useSearchParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {ErrorBoundary} from '@/components/common';

/**
 * MyPostGroup 컴포넌트
 * @desc 피드 목록을 스와이퍼로 보여주는 컴포넌트
 * @param {Object[]} feeds - 즐겨찾기 한 bj 들의 피드 목록
 * @returns {JSX.Element} PostGroup 컴포넌트
 */
const PostGroup = ({feeds}) => {
  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId');
  const [swiper, setSwiper] = useState();

  useEffect(() => {
    if (!swiper) {
      return;
    }

    swiper.slideTo(0);
  }, [groupId]);

  return (
    <div className='new_post_list'>
      {feeds.length > 0 && (
        <SoopUiSwiper
          swiperTag='ul'
          onSwiper={setSwiper}
          maxSlidesPerView={4}
          otherMaxSlidesResolution={[
            {max: 1617, slidePerView: 4},
            {max: 1000, slidePerView: 3},
            {max: 638, slidePerView: 2},
            {max: 0, slidePerView: 1},
          ]}
          isSameSlidesViewGroup
          options={{
            loop: false,
          }}>
          {feeds.map((feed, index) => (
            <li className='post_box' key={`feed-${index}`}>
              <ErrorBoundary>
                <Post {...feed} />
              </ErrorBoundary>
            </li>
          ))}
        </SoopUiSwiper>
      )}
    </div>
  );
};

export default PostGroup;
