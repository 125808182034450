/**
 * HomeSectionHeader - 공통의 홈화면 섹션 타이틀 컴포넌트
 *
 * @props customElement: 섹션 타이틀 부분을 완전히 커스터마이징할 수 있는 JSX 요소
 * @props title: string 제목
 * @props rightElement: JSX 오른쪽 영역 UI (게임랭크, 새로고침 버튼, ...)
 */
export default function SectionHeader({customElement, title, rightElement}) {
  return (
    <div className='title_wrap'>
      {customElement}
      {title && <h2>{title}</h2>}
      {rightElement && <div className='right_area'>{rightElement}</div>}
    </div>
  );
}
