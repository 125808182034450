'use client';

import MySubscribeApi from '@/api/my/subscribe';
import MyAvatarGroup from '@/components/my/common/group/AvatarGroup';
import useMyStore from '@/stores/my';
import {useEffect} from 'react';
import throwToast from '@/utils/ui/toast';
import {sendPinClickLog} from '@/utils/main/my/log';

const MySubscribeStreamers = ({groupId}) => {
  const {
    subscribeStreamers,
    getSubscribeStreamers,
    setLiveBroadcasts,
    liveSortKey,
    liveSortOrder,
  } = useMyStore();

  const handleUserPin = async (userId, isPin) => {
    if (isPin) {
      await MySubscribeApi.deletePin(userId);
      throwToast('목록 상단 고정 해제 되었습니다.');
    } else {
      await MySubscribeApi.setPin(userId);
      throwToast('목록 상단 고정 완료 되었습니다.');
    }

    sendPinClickLog({
      isAddPin: !isPin,
      streamerId: userId,
    });
    getSubscribeStreamers();
  };

  useEffect(() => {
    setLiveBroadcasts(
      subscribeStreamers.flatMap((favorite) => favorite.broadInfo),
      {
        sortKey: liveSortKey,
        sortOrder: liveSortOrder,
      },
    );
  }, [liveSortKey, liveSortOrder]);

  return (
    <div className='strm_area'>
      <MyAvatarGroup
        favorites={subscribeStreamers}
        handleUserPin={handleUserPin}
        isSubscribe
        isShowSubscribe={false}
      />
    </div>
  );
};

export default MySubscribeStreamers;
