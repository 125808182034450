import '/public/static/css/module/home_banner.scss';
import MyPlusApi from '@/api/main/my-plus';
import {FloatingPortal} from '@/components/main/common';
import {useOutsideClick} from '@/components/main/common/hooks/useOutsideClick';
import {SoopUiSwiper, SoopUiImgRework} from '@/components/ui';
import globalDomains from '@/utils/global-domains';
import {goLogin, isLogin} from '@/utils/main';
import {useEffect, useRef, useState} from 'react';
import {sendLegacyInflowPathLog} from '@/utils/main/my/log';

export const TopBanner = ({mainListInfo}) => {
  const swiperConfig = {
    loop: false,
  };

  const [topBanner, setTopBanner] = useState([]);
  const [isShowPortalLayer, setIsShowPortalLayer] = useState(false);
  const [renderComponent, setRenderComponent] = useState(null);
  const [alarmPosition, setAlarmPosition] = useState({top: 0, left: 0});
  const [activeAlarmIdx, setActiveAlarmIdx] = useState(null);
  const buttonRef = useRef(null);
  const alarmRef = useRef(null);

  useEffect(() => {
    if (mainListInfo?.mainListTopBnrResult === 1) {
      setTopBanner(mainListInfo?.mainListTopBnrData);
    }
  }, [mainListInfo]);

  const handleOnClickAlarm = async (e, idx) => {
    const targetElement = document.querySelector(`a[data-idx="${idx}"]`);
    if (targetElement) {
      const position = targetElement.getBoundingClientRect();
      if (!isLogin()) {
        setIsShowPortalLayer(true);
      } else {
        setActiveAlarmIdx(idx);

        const isExist = await setCheckTopBannerAlarm(idx); // 알림 체크여부 확인
        setRenderComponent(
          isExist
            ? basicAlertCompleteComponent(handleOnClickClose, () =>
                handleOnClickAlarmCancel(idx),
              )
            : basicAlertComponent(handleOnClickClose, () =>
                handleOnClickAlarmOn(idx),
              ),
        );

        // 화면 경계 확인 및 위치 조정
        let left = position.x - 370;
        if (left < 0) {
          left = 10; // 최소 간격 설정
        }

        setAlarmPosition({top: 30, left: left});
      }
    }
  };

  const handleOnClickAlarmOn = async (idx) => {
    const response = await setTopBannerAlarm(idx);
    if (response && Number(response.result) === 1) {
      setRenderComponent(
        basicAlertCompleteComponent(handleOnClickClose, () =>
          handleOnClickAlarmCancel(idx),
        ),
      );
    }
  };

  const handleOnClickAlarmCancel = async (idx) => {
    const response = await setDeleteTopBannerAlarm(idx);
    if (response && Number(response.result) === 1) {
      setRenderComponent(basicAlertCancelComponent(handleOnClickClose));
    }
  };

  const handleOnClickClose = () => {
    setActiveAlarmIdx(null);
    setAlarmPosition({top: 0, left: -370});
  };

  const handleOnClickLive = (e, item) => {
    e.preventDefault();
    if (item.isType === 'LIVE') {
      const streamerData = item.link
        .replace(/^((https?:)?\/\/)/gi, '')
        .split('/');
      sendLegacyInflowPathLog({
        broadcastId:
          typeof streamerData[2] == 'undefined' ? 0 : streamerData[2],
        streamerId: streamerData[1],
        isLogSendData: {
          path1: 'main',
          path2: 'banner',
          path3: item.idx,
        },
      });
    }
    window.open(item.link);
  };

  const setTopBannerAlarm = async (idx) => {
    try {
      const params = {
        alarm_idx: idx,
        platform: 'pc',
      };
      const response = await MyPlusApi.setTopBannerAlarm(params);
      if (response) {
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setCheckTopBannerAlarm = async (idx) => {
    try {
      const params = {
        alarm_idx: idx,
      };
      const response = await MyPlusApi.setCheckTopBannerAlarm(params);
      if (response) {
        return response.isExist;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setDeleteTopBannerAlarm = async (idx) => {
    try {
      const params = {
        alarm_idx: idx,
      };
      const response = await MyPlusApi.setDeleteTopBannerAlarm(params);
      if (response) {
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useOutsideClick(alarmRef, () => {
    setActiveAlarmIdx(null);
    setAlarmPosition({top: 0, left: -370});
  });
  return (
    <>
      {topBanner && topBanner.length > 0 && (
        <div className={`main_banner`}>
          <SoopUiSwiper
            swiperClass='banner_list'
            options={swiperConfig}
            maxSlidesPerView={4}
            otherMaxSlidesResolution={[
              {max: 1623, slidePerView: 4},
              {max: 1130, slidePerView: 3},
              {max: 721, slidePerView: 2},
              {max: 0, slidePerView: 1},
            ]}
            swiperTag='ul'>
            {topBanner.map(
              (item, idx) =>
                item && (
                  <li key={idx}>
                    <a
                      data-idx={item.idx}
                      onClick={(e) => handleOnClickLive(e, item)}>
                      <span className='thumb'>
                        <SoopUiImgRework
                          src={`${globalDomains.ADMIN_IMG_AFREECATV}/${item.imgPath}`}
                          alt={item.memo}
                        />
                      </span>
                      <div className='info'>
                        <span className='date'>{item.bannerPeriod}</span>
                        <strong>{item.bannerTitle}</strong>
                        {item.bannerContent && (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.bannerContent.replace(
                                /\r?\n/g,
                                '<br/>',
                              ),
                            }}
                          />
                        )}
                      </div>
                    </a>
                    {item.link.includes(
                      new URL(globalDomains.PLAY_AFREECATV).hostname,
                    ) ? (
                      <button type='button' className='live'>
                        LIVE
                      </button>
                    ) : (
                      <button
                        ref={buttonRef}
                        type='button'
                        className='alarm'
                        onClick={(e) => handleOnClickAlarm(e, item.idx)}>
                        방송 알람
                      </button>
                    )}
                  </li>
                ),
            )}
          </SoopUiSwiper>

          {
            <div
              ref={alarmRef}
              className='alarm_box'
              style={{
                position: 'absolute',
                top: alarmPosition.top,
                left: alarmPosition.left,
              }}>
              {renderComponent}
            </div>
          }
          {isShowPortalLayer && (
            <FloatingPortal className={'_Modal_UI_Wrap'} target={'soop_wrap'}>
              <div className='flex_box'>
                <div className='basic_alert'>
                  <div className='conts'>
                    <p>로그인이 필요합니다.</p>
                  </div>
                  <div className='btn_area'>
                    <button
                      type='button'
                      onClick={() => {
                        goLogin();
                      }}>
                      확인
                    </button>
                    <button
                      type='button'
                      className='tertiary'
                      onClick={() => setIsShowPortalLayer(false)}>
                      취소
                    </button>
                  </div>
                </div>
              </div>
            </FloatingPortal>
          )}
        </div>
      )}
    </>
  );
};

const basicAlertComponent = (handleOnClickClose, handleOnClickAlarmOn) => (
  <div className='basic_alert'>
    <div className='title'>
      <strong>알림 예약 설정</strong>
    </div>
    <div className='conts'>
      <p className='txt'>
        방송이 시작되면 알려드릴까요? <br />
        알림은 모바일 앱을 통해 발송됩니다.
      </p>
    </div>
    <div className='btn_area'>
      <button type='button' className='active' onClick={handleOnClickAlarmOn}>
        알림 예약
      </button>
    </div>
    <button type='button' className='btn_close' onClick={handleOnClickClose}>
      레이어 닫기
    </button>
  </div>
);

const basicAlertCompleteComponent = (
  handleOnClickClose,
  handleOnClickAlarmCancel,
) => (
  <div className='basic_alert complete'>
    <div className='conts'>
      <p>알림 설정이 완료되었습니다</p>
    </div>
    <div className='btn_area'>
      <button
        type='button'
        className='cancel'
        onClick={handleOnClickAlarmCancel}>
        알림 취소
      </button>
    </div>
    <button type='button' className='btn_close' onClick={handleOnClickClose}>
      레이어 닫기
    </button>
  </div>
);

const basicAlertCancelComponent = (handleOnClickClose) => (
  <div className='basic_alert cancel'>
    <div className='conts'>
      <p>알림 설정이 취소되었습니다.</p>
    </div>
    <button type='button' className='btn_close' onClick={handleOnClickClose}>
      레이어 닫기
    </button>
  </div>
);
