import MyFavoriteApi from '@/api/my/favorite';
import {SectionHeader} from '@/components/main/common';
import PostGroup from '@/components/my/common/group/PostGroup';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const Feed = ({groupId}) => {
  const [feeds, setFeeds] = useState([]);

  const hasFeed = feeds?.length > 0;

  const getFeed = async () => {
    try {
      const feeds = await MyFavoriteApi.getFeed({group_idx: groupId});
      setFeeds(feeds);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFeed();
  }, [groupId]);

  return (
    <>
      {hasFeed && (
        <>
          <SectionHeader
            title='채널 새 글'
            rightElement={
              <Link to='/my/feed' className='more'>
                전체보기
              </Link>
            }
          />
          <div className='new_post_area'>
            <PostGroup feeds={feeds} />
          </div>
        </>
      )}
    </>
  );
};

export default Feed;
