import BjApi from '@/api/bj';
import MyApi from '@/api/my';
import {DELETE} from '@/constants/common/method';
import throwToast from '@/utils/ui/toast';

const MyHistoryStoryApi = {
  /**
   * 내 글/댓글 목록 조회
   * @param {Object} params - The query parameters.
   * @param {number} params.page - The page number.
   * @param {string} params.feed_type - The feed type.[POST_PHOTO, PARRENT_COMMENT, CHILD_COMMENT]
   * @returns {Promise<Array<Object>>} The list of my stories.
   */
  async getMyHistoryStoryList({page = 1, feed_type = ''}) {
    try {
      const params = new URLSearchParams({page, feed_type});
      const response = await MyApi.get(`/api/story?${params.toString()}`);
      return response;
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(error); // 에러 로깅은 개발 환경에서만 수행
      }
      throw error;
    }
  },
  /**
   * 내 글/댓글 삭제
   * @param {Object} params - The query parameters.
   * @param {string} params.feedType - The feed type.
   * @param {string} params.stationUserId - The station user id.
   * @param {number} params.titleNo - The title number.
   * @param {number} params.pCommentNo - 댓글 number.
   * @param {number} params.cCommentNo - 답글 number.
   */
  async deleteMyHistoryStory({
    feedType = '',
    stationUserId = '',
    titleNo = 0,
    pCommentNo = 0,
    cCommentNo = 0,
  }) {
    let url;
    switch (feedType) {
      case 'POST':
      case 'PHOTO':
        url = `/api/story/${stationUserId}/title/${titleNo}`;
        break;
      case 'PARENT_COMMENT':
        url = `/api/story/${stationUserId}/title/${titleNo}/comment/${pCommentNo}`;
        break;
      case 'CHILD_COMMENT':
        url = `/api/story/${stationUserId}/title/${titleNo}/comment/${pCommentNo}/reply/${cCommentNo}`;
        break;
      default:
        url = '';
        break;
    }
    try {
      await BjApi.post(
        url,
        new URLSearchParams({_method: DELETE}).toString(),
        null,
        {
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          credentials: 'include',
        },
      );

      switch (feedType) {
        case 'POST':
        case 'PHOTO':
          throwToast('게시글(댓글, 답글 포함) 삭제가 완료되었습니다.');
          break;
        case 'PARENT_COMMENT':
          throwToast('댓글(답글 포함) 삭제가 완료되었습니다.');
          break;
        case 'CHILD_COMMENT':
          throwToast('답글 삭제가 완료되었습니다.');
          break;
        default:
          break;
      }
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.log(error); // 에러 로깅은 개발 환경에서만 수행
      }
      throw error;
    }
  },
};

export default MyHistoryStoryApi;
