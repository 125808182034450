import Broadcast from '@/components/my/common/item/MyBroadcast';
import {SectionHeader} from '@/components/main/common';
import {VOD_CONTEXT_MENU} from '@/constants/main';
import globalDomains from '@/utils/global-domains';
import {useEffect, useState} from 'react';
import '/public/static/css/module/cBox.scss';
import LogApi from '@/api/common/log';
import useMainStore from '@/stores/main';

export const OriginalContentGroup = ({mainListInfo}) => {
  const [originalContent, setOriginalContent] = useState([]);
  const [displayedContent, setDisplayedContent] = useState([]);
  const {resizeCount} = useMainStore();

  useEffect(() => {
    if (mainListInfo && mainListInfo?.cinetyResult === 1) {
      const formattedContent = mainListInfo?.cinetyData?.map((broadcast) => {
        const {
          readCnt, // 총 조회수
          episodeDate, // 날짜
          contentTitle, // 유저이름
          episodeNo, // 방송 번호
          hashtags,
          thumbUrl, //썸네일 주소
          title, // 방송제목
          category,
          duration,
          categoryName,
          profileImg,
          ...rest // 나머지 속성
        } = broadcast;

        // 새로운 객체를 반환
        return {
          ...rest,
          thumbnailSrc: thumbUrl, // 썸네일 이미지
          viewCount: readCnt, // 조회수
          registerDate: episodeDate, // 등록 일
          userNickname: contentTitle,
          duration: duration,
          title: title, // 방송 제목
          broadcastId: episodeNo, // 방송 ID
          categoryTags: [categoryName || ''],
          hashTags: hashtags,
          adminProfileImg: profileImg,
          userId: 'aforiginal',
        };
      });

      setOriginalContent(formattedContent);
    }
  }, [mainListInfo]);

  useEffect(() => {
    const itemsToShow = resizeCount * 2;
    setDisplayedContent(originalContent.slice(0, itemsToShow));
  }, [resizeCount, originalContent]);

  useEffect(() => {
    sendVodListViewLog();
  }, [originalContent]);

  const sendVodListViewLog = () => {
    try {
      if (originalContent.length > 0) {
        const params = {
          path1: 'popular',
          path2: 'cinety',
          list_send_type: 'client',
          list_view_cnt: originalContent.length,
          list_view_data: originalContent.map((item) => ({
            vno: item.broadcastId,
          })),
        };
        LogApi.sendVodListViewLog(params);
      }
    } catch (error) {}
  };

  return (
    <>
      {displayedContent && displayedContent.length > 0 ? (
        <>
          <SectionHeader
            customElement={
              <>
                <h2>SOOP에서만 볼 수 있는 오리지널 콘텐츠</h2>
                <a
                  href={globalDomains.CINETY_AFREECATV}
                  className='more'
                  target='_blank'>
                  전체보기
                </a>
              </>
            }
          />
          <div className='cBox-list cinety_list'>
            <ul>
              {displayedContent.map((item, idx) => (
                <li data-type='cBox' key={item.broadcastId}>
                  <Broadcast
                    isVod={true}
                    {...item}
                    menuList={VOD_CONTEXT_MENU}
                    isLogSendData={{
                      path1: 'popular',
                      path2: 'list',
                      path3: 'cinety',
                      ...(item.categoryNo && {exCategoryNo: item.categoryNo}),
                    }}
                    isOriginalContent={true}
                  />
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : null}
    </>
  );
};
