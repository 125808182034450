import {useEffect, useMemo, useState} from 'react';
import {Link, useLocation, useSearchParams} from 'react-router-dom';

import SoopUiImgRework from '@/components/ui/SoopUiImgRework';
import {SOOP_UI_IMG_TYPES} from '@/components/ui/SoopUiImg';
import {useBroadcastContext} from '@/components/main/common/Broadcast/BroadcastProvider';

import {isEmpty} from 'lodash';
import {ROUTE} from '@/constants/main/route';
import {VOD_TYPES} from '@/constants';
import {convertToArray} from '@/utils';
import {
  convertToDuration,
  convertViewCnt,
  formatDate,
  goLogin,
  isLogin,
} from '@/utils/main';
import {
  sendLegacyInflowPathLog,
  sendLiveThumbnailClickLog,
  sendVodThumbnailClickLog,
} from '@/utils/main/my/log';
import throwToast from '@/utils/ui/toast';
import MyHistoryApi from '@/api/my/history';
import MyPlusApi from '@/api/main/my-plus';

const THUMBNAIL_TYPES = {
  ADULT: 'ADULT',
  ADULT_LOCK: 'ADULT_LOCK',
  LOCK: 'LOCK',
  NORMAL: 'NORMAL',
  DELETE: 'DELETE',
};

const getDefaultThumbnail = ({
  hasPassword,
  allowsAdult,
  isPrivate,
  isError,
}) => {
  if (hasPassword && allowsAdult) return 'thumb-lock_adult';
  if (allowsAdult) return 'thumb-adult';
  if (hasPassword) return 'thumb-lock';
  if (isPrivate) return 'thumb-private';
  if (isError) return 'thumb-default';
  return null;
};

const BroadcastThumbnailMain = ({children}) => {
  const {broadcastType, vodType} = useBroadcastContext();

  if (broadcastType !== 'vod' && broadcastType !== 'live') {
    throw new Error('broadcastType must be "live" or "vod".');
  }

  if (broadcastType === 'vod' && !vodType) {
    throw new Error('vodType is required.');
  }
  return <div className='thumbs-box'>{children}</div>;
};

const VodThumbnail = () => {
  const SUBSCRIBE_AUTH_NO = 107;
  const location = useLocation();
  const {
    broadcastId,
    broadcastLink,
    vodType,
    hasShowLaterButton,
    thumbnailUrl,
    previewThumbnailUrl,
    originalUserId,
    originalUserNickname,
    duration,
    authNumber,
    categoryNo,
    accessibleAge,
    hasBadge,
    allowsAdult,
    hasPassword,
    isPpv,
    userId,
    hashTags,
    thumbnailType,
    categoryTags,
    isUserClip,
    log,
    isTitleHover,
    handleAfterLogSend,
  } = useBroadcastContext();

  const [searchParams] = useSearchParams();
  const searchKeyword = searchParams.get('szKeyword');
  const [isMouseHovering, setMouseHovering] = useState(false);
  const [imageError, setImageError] = useState(false);

  const isAdult = useMemo(
    () =>
      allowsAdult ||
      (Number(accessibleAge) === 19 && Number(categoryNo) === 30000),
    [allowsAdult, accessibleAge, categoryNo],
  );

  const shouldShowPreview = useMemo(() => {
    // 19금, 다시보기, 구독 전용, webp(미리 보기)파일이 없을 때
    return !(
      vodType === VOD_TYPES.REVIEW ||
      !previewThumbnailUrl ||
      allowsAdult ||
      isPpv ||
      // parseInt(uccType) === 22 || 이건 기존 코드에도 사용되지 않는데
      authNumber === 107
    );
  }, [previewThumbnailUrl, allowsAdult]);
  const isPrivate = hasPassword || thumbnailType === THUMBNAIL_TYPES.LOCK;
  const handleThumbnailClick = () => {
    const currentPathname = location.pathname;

    if (currentPathname === ROUTE.MY.HISTORY.LATER) {
      MyHistoryApi.viewLaterVod(broadcastId).then(() => {});
    }
    // VOD 썸네일 클릭 로그 전송
    sendVodThumbnailClickLog({
      streamerId: originalUserId ? originalUserId : userId, // 원저작자가 있는 영상일 경우에는 bj 가 원저작자 아이디로, 없는 경우에는 업로드한 유저 아이디로
      vodNumber: broadcastId,
      fileType: vodType,
    });

    // 기존 유입 로그 전송
    sendLegacyInflowPathLog({
      broadcastId: broadcastId,
      streamerId: originalUserId ? originalUserId : userId, // 원저작자가 있는 영상일 경우에는 bj 가 원저작자 아이디로, 없는 경우에는 업로드한 유저 아이디로
      type: 'vod',
      moreData: {
        contentsType: 'vod',
        hashTags: convertToArray(hashTags),
        categoryTags: convertToArray(categoryTags),
        exCategoryNo: broadcastId,
        isUserClip,
        ...(searchKeyword && {skey: searchKeyword}),
        ...(log?.groupName && {groupName: log.groupName}),
        ...(log?.groupType && {groupType: log.groupType}),
        ...(log?.listDataType && {listDataType: log.listDataType}),
      },
      isLogSendData: {
        uploader: userId,
        ...log?.data,
      },
    });

    handleAfterLogSend?.();
  };

  const handleMouseEnter = () => {
    if (shouldShowPreview) {
      setMouseHovering(true);
    }
  };

  useEffect(() => {
    if (isTitleHover) {
      handleMouseEnter();
    } else {
      setMouseHovering(false);
    }
  }, [isTitleHover]);

  // 썸네일 이미지, 비공개, 19금 일반
  return (
    <div
      className='thumbs-box'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setMouseHovering(false)}>
      <Link
        className={getDefaultThumbnail({
          hasPassword,
          allowsAdult: isAdult,
          isPrivate,
          isError: imageError,
        })}
        to={broadcastLink}
        target='_blank'
        onClick={handleThumbnailClick}>
        <SoopUiImgRework
          src={isMouseHovering ? previewThumbnailUrl : thumbnailUrl}
          hasPassword={hasPassword}
          isAdult={isAdult}
          isPrivate={isPrivate}
          onError={() => {
            setImageError(true);
          }}
        />
      </Link>
      {hasBadge && (
        <>
          {!shouldShowPreview && (
            <span className='nonView'>미리보기를 제공하지 않는 VOD</span>
          )}
          {authNumber === SUBSCRIBE_AUTH_NO && (
            <span className='_subscribe'>구독전용</span>
          )}
          {vodType === VOD_TYPES.HIGHLIGHT && (
            <span className='_highlight'>하이라이트</span>
          )}
          {vodType === VOD_TYPES.REVIEW && (
            <span className='_replay'>다시보기</span>
          )}
          {originalUserNickname &&
            originalUserId &&
            vodType !== VOD_TYPES.CATCH &&
            vodType !== VOD_TYPES.CATCH_STORY && (
              <span className='clip_nick'>{originalUserNickname}</span>
            )}
          {vodType === VOD_TYPES.CATCH && (
            <span data-testid='catch-badge' className='catch'></span>
          )}
          {vodType === VOD_TYPES.CATCH_STORY && (
            <span data-testid='story' className='story'>
              스토리
            </span>
          )}
        </>
      )}
      {/* api 응답값마다 duration 형식이 다다르다. */}
      {!isEmpty(duration) && vodType !== VOD_TYPES.CATCH && (
        <span data-testid='duration' className='time active'>
          {convertToDuration(duration)}
        </span>
      )}
      {hasShowLaterButton && <ShowLaterButton />}
    </div>
  );
};

const LiveThumbnail = () => {
  const {
    liveType,
    broadcastLink,
    hasShowLaterButton,
    thumbnailUrl,
    viewCount,
    startDateTime,
    hasPassword,
    canResend,
    allowsAdult,
    userId,
    hashTags,
    categoryTags,
    categoryNo,
    categoryName,
    log,
    broadcastId,
    broadBps,
    broadResolution,
    handleAfterLogSend,
  } = useBroadcastContext();
  const [imageError, setImageError] = useState(false);
  const formattedStartDate = useMemo(
    () => formatDate(startDateTime, 'MM-dd HH:mm'),
    [startDateTime],
  );

  const handleThumbnailClick = () => {
    //방송 썸네일 클릭 로그
    sendLiveThumbnailClickLog({
      streamerId: userId,
      broadcastId,
      broadCateNo: categoryNo,
      broadBps,
      broadResolution,
    });

    // 기존에 사용하던 라이브 방송 유입 로그
    sendLegacyInflowPathLog({
      broadcastId,
      streamerId: userId,
      moreData: {
        hashTags: convertToArray(hashTags),
        categoryTags: convertToArray(categoryTags),
        contentsType: 'live',
        broadCateNo: categoryNo,
        directoryCateId: categoryName,
        ...(log?.listDataType && {listDataType: log.listDataType}),
      },
      isLogSendData: log?.data,
    });

    handleAfterLogSend?.();
  };

  return (
    <div className='thumbs-box'>
      <Link
        className={getDefaultThumbnail({
          hasPassword,
          allowsAdult,
          isPrivate: false,
          isError: imageError,
        })}
        to={broadcastLink}
        target='_blank'
        onClick={handleThumbnailClick}>
        <SoopUiImgRework
          type={SOOP_UI_IMG_TYPES.thumbnail}
          src={thumbnailUrl}
          hasPassword={hasPassword}
          isAdult={allowsAdult}
          onError={() => setImageError(true)}
        />
      </Link>
      {Number(liveType) === 40 && <span className='ppv'>유료</span>}
      {Boolean(Number(canResend)) === true && (
        <span className='allow'>탐방허용</span>
      )}
      <span className='views'>
        <em data-testid='view-count'>{convertViewCnt(viewCount)}</em>
      </span>
      {Number(liveType) === 22 && <span className='vr360'>VR</span>}
      <span data-testid='live-start-time' className='time'>
        {formattedStartDate} 방송시작
      </span>
      {hasShowLaterButton && <ShowLaterButton />}
    </div>
  );
};

const PlayListThumbnail = () => {
  const [imageClassName, setImageClassName] = useState('');

  const {
    broadcastLink,
    thumbnailType,
    thumbnailUrl,
    accessibleAge,
    categoryNo,
    allowsAdult,
    hasPassword,
    playlistCount,
    playlistId,
    vodOriginalId,
    vodUploaderId,
    broadcastId,
    log,
    handleAfterLogSend,
  } = useBroadcastContext();

  const isPrivate = hasPassword || thumbnailType === THUMBNAIL_TYPES.LOCK;
  const isAdultContent =
    allowsAdult ||
    (Number(accessibleAge) === 19 && Number(categoryNo) === 30000) ||
    thumbnailType === THUMBNAIL_TYPES.ADULT;
  const isAdultAndPrivate =
    (allowsAdult && hasPassword) ||
    thumbnailType === THUMBNAIL_TYPES.ADULT_LOCK;

  const shouldShowImage = !isPrivate && !isAdultContent && !isAdultAndPrivate;

  useEffect(() => {
    if (
      !thumbnailUrl ||
      playlistCount === 0 ||
      thumbnailType === THUMBNAIL_TYPES.DELETE
    ) {
      setImageClassName('thumb-default');
    }
  }, [thumbnailUrl, playlistCount, thumbnailType]);

  const handleImageError = () => {
    if (imageClassName !== 'thumb-default') {
      setImageClassName('thumb-default');
    }
  };

  const handleThumbnailClick = () => {
    if (playlistCount === 0) {
      throwToast('재생 가능한 VOD가 없습니다.');
      return;
    }

    if (playlistId && vodOriginalId && vodUploaderId) {
      sendLegacyInflowPathLog({
        broadcastId: broadcastId,
        streamerId: vodOriginalId,
        type: 'vod',
        isLogSendData: {...log.data, uploader: vodUploaderId},
      });
    }

    if (handleAfterLogSend) {
      handleAfterLogSend();
    }
  };

  const ThumbnailComponent = ({label, playlistCount}) => (
    <>
      <Link
        to={broadcastLink}
        target='_blank'
        onClick={handleThumbnailClick}
        className={getDefaultThumbnail({
          hasPassword,
          allowsAdult: isAdultContent,
          isPrivate: false,
        })}>
        <SoopUiImgRework
          src={thumbnailUrl}
          hasPassword={hasPassword}
          isAdult={isAdultContent}
          isPrivate={isPrivate}
          onError={handleImageError}
        />
        {label && <span>{label}</span>}
      </Link>
      <div className='play_list'>
        <em>{playlistCount}</em>
      </div>
    </>
  );

  return (
    <div className='thumbs-box'>
      {isPrivate && (
        <ThumbnailComponent label='비공개' playlistCount={playlistCount} />
      )}
      {isAdultContent && (
        <ThumbnailComponent label='19금' playlistCount={playlistCount} />
      )}
      {isAdultAndPrivate && (
        <ThumbnailComponent
          label='연령제한 + 비공개'
          playlistCount={playlistCount}
        />
      )}
      {shouldShowImage && (
        <Link
          className={imageClassName}
          to={broadcastLink}
          target='_blank'
          onClick={handleThumbnailClick}
          rel='noopener noreferrer'>
          {imageClassName !== 'thumb-default' && (
            <SoopUiImgRework src={thumbnailUrl} onError={handleImageError} />
          )}
          <div className='play_list'>
            <em>{playlistCount}</em>
          </div>
        </Link>
      )}
    </div>
  );
};

const ShowLaterButton = () => {
  const {broadcastType, broadcastId} = useBroadcastContext();

  const handleViewingLaterButtonClicked = async () => {
    if (isLogin()) {
      try {
        const response = await MyPlusApi.setLaterView(
          (broadcastType || '').toUpperCase() === 'VOD' ? 'VOD' : 'LIVE',
          broadcastId,
        );
        if (response) {
          throwToast(response.message);
        }
      } catch (error) {
        const {message} = error.response.data;
        throwToast(message);
      }
    } else {
      //비로그인시에는 로그인페이지로 이동
      goLogin();
    }
  };

  return (
    <button
      type='button'
      className='later'
      tip='나중에 보기'
      onClick={handleViewingLaterButtonClicked}>
      <span>나중에 보기</span>
    </button>
  );
};

const BroadcastThumbnail = Object.assign(BroadcastThumbnailMain, {
  VodThumbnail,
  LiveThumbnail,
  PlayListThumbnail,
  ShowLaterButton,
});

export default BroadcastThumbnail;
