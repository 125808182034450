export const SEARCH_LIVE_FILTER = [
  {
    label: '정확도',
    type: 'score',
    value: 'score',
    default: true,
  },
  {
    label: '참여자수(높은순)',
    type: 'view_cnt_up',
    value: 'view_cnt',
    orderType: 'desc',
    default: false,
  },
  {
    label: '참여자수(낮은순)',
    type: 'view_cnt_down',
    value: 'view_cnt',
    orderType: 'asc',
    default: false,
  },
  {
    label: '최근 시작',
    type: 'broad_start',
    value: 'broad_start',
    default: false,
  },
];
export const SEARCH_TAG_SEARCH_LIVE_FILTER = [
  {
    label: '참여자수(높은순)',
    type: 'view_cnt_up',
    value: 'view_cnt',
    orderType: 'desc',
    default: true,
  },
  {
    label: '참여자수(낮은순)',
    type: 'view_cnt_down',
    value: 'view_cnt',
    orderType: 'asc',
    default: false,
  },
  {
    label: '최근 시작',
    type: 'broad_start',
    value: 'broad_start',
    default: false,
  },
];

export const SEARCH_VOD_FILTER = [
  {key: 'sort', label: '정확도', type: 'score', value: 'score', default: true},
  {
    key: 'sort',
    label: '조회수',
    type: 'view_cnt_up',
    value: 'view_cnt',
    orderType: 'desc',
    default: false,
  },
  {
    key: 'sort',
    label: '최근 등록',
    type: 'reg_date',
    value: 'reg_date',
    default: false,
  },
  {
    key: 'sort',
    label: 'UP수',
    type: 'recomm_cnt',
    value: 'recomm_cnt',
    default: false,
  },
];

export const SEARCH_VOD_TAB_FILTER = [
  {
    key: 'sort',
    title: '정렬',
    options: [
      {
        tab: 'vod',
        label: '정확도',
        type: 'score',
        value: 'score',
        default: true,
      },
      {
        tab: 'vod',
        label: '조회수',
        type: 'view_cnt_up',
        value: 'view_cnt',
        orderType: 'desc',
        default: false,
      },
      {
        tab: 'vod',
        label: '최근 등록',
        type: 'reg_date',
        value: 'reg_date',
        default: false,
      },
      {
        tab: 'vod',
        label: 'UP수',
        type: 'recomm_cnt',
        value: 'recomm_cnt',
        default: false,
      },
    ],
  },
  {
    key: 'fileType',
    title: '구분',
    options: [
      {label: '전체', type: 'ALL', value: 'ALL', default: true},
      {label: '다시보기', type: 'REVIEW', value: 'REVIEW', default: false},
      {label: '업로드 VOD', type: 'NORMAL', value: 'NORMAL', default: false},
      {label: '유저클립', type: 'CLIP', value: 'CLIP', default: false},
      {
        label: 'Catch',
        type: 'CATCH',
        value: 'CATCH',
        default: false,
      },
      {label: '재생목록', type: 'PLAYLIST', value: 'PLAYLIST', default: false},
    ],
  },
  {
    key: 'period',
    title: '기간',
    options: [
      {label: '전체', type: 'all', value: 'all', default: false},
      {label: '1일', type: '1day', value: '1day', default: false},
      {label: '1주', type: '1week', value: '1week', default: false},
      {label: '1개월', type: '1month', value: '1month', default: false},
      {label: '1년', type: '1year', value: '1year', default: true},
    ],
  },
];
export const SEARCH_TAG_VOD_FILTER = [
  {
    key: 'sort',
    title: '정렬',
    options: [
      {
        tab: 'vod',
        label: '조회수',
        type: 'view_cnt_up',
        value: 'view_cnt',
        orderType: 'desc',
        default: true,
      },
      {
        tab: 'vod',
        label: '최근 등록',
        type: 'reg_date',
        value: 'reg_date',
        default: false,
      },
      {
        tab: 'vod',
        label: 'UP수',
        type: 'recomm_cnt',
        value: 'recomm_cnt',
        default: false,
      },
    ],
  },
  {
    key: 'fileType',
    title: '구분',
    options: [
      {label: '전체', type: 'ALL', value: 'ALL', default: true},
      {label: '다시보기', type: 'REVIEW', value: 'REVIEW', default: false},
      {label: '업로드 VOD', type: 'NORMAL', value: 'NORMAL', default: false},
      {label: '유저클립', type: 'CLIP', value: 'CLIP', default: false},
      {
        label: 'Catch',
        type: 'CATCH',
        value: 'CATCH',
        default: false,
      },
    ],
  },
  {
    key: 'period',
    title: '기간',
    options: [
      {label: '전체', type: 'all', value: 'all', default: false},
      {label: '1일', type: '1day', value: '1day', default: false},
      {label: '1주', type: '1week', value: '1week', default: true},
      {label: '1개월', type: '1month', value: '1month', default: false},
      {label: '1년', type: '1year', value: '1year', default: false},
    ],
  },
];

export const SEARCH_STREAMER_FILTER = [
  {key: 'sort', label: '정확도', type: 'score', value: 'score', default: true},
  {
    key: 'sort',
    label: '누적 참여자수',
    type: 'total_view_cnt',
    value: 'total_view_cnt',
    default: false,
  },
  {
    key: 'sort',
    label: '누적 방송시간',
    type: 'total_broad_time',
    value: 'total_broad_time',
    default: false,
  },
  {
    key: 'sort',
    label: '애청자수',
    type: 'favorite_cnt',
    value: 'favorite_cnt',
    default: false,
  },
  {
    key: 'sort',
    label: '팬클럽수',
    type: 'fanclub_cnt',
    value: 'fanclub_cnt',
    default: false,
  },
];

export const SEARCH_POST_FILTER = [
  {
    label: '조회수',
    type: 'view_cnt',
    value: 'view_cnt',
    orderType: 'desc',
    default: true,
  },
  {label: '최근 등록', type: 'reg_date', value: 'reg_date', default: false},
];

export const SEARCH_POST_TAB_FILTER = [
  {
    key: 'sort',
    title: '정렬',
    options: [
      {
        tab: 'post',
        label: '조회수',
        type: 'view_cnt',
        value: 'view_cnt',
        default: true,
      },
      {
        tab: 'post',
        label: '최근 등록',
        type: 'reg_date',
        value: 'reg_date',
        default: false,
      },
    ],
  },
  {
    key: 'period',
    title: '기간',
    options: [
      {label: '전체', type: 'all', value: 'all', default: false},
      {label: '1일', type: '1day', value: '1day', default: false},
      {label: '1주', type: '1week', value: '1week', default: false},
      {label: '1개월', type: '1month', value: '1month', default: true},
      {label: '1년', type: '1year', value: '1year', default: false},
    ],
  },
];

export const SEARCH_GAME_PROFILE_STREAMER_FILTER = [
  {
    label: '참여자수(높은순)',
    type: 'desc',
    value: 'desc',
  },
  {
    label: '참여자수(낮은순)',
    type: 'asc',
    value: 'asc',
  },
  {label: '최근 시작', type: 'broad_start', value: 'broad_start'},
];
