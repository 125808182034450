import {forwardRef, useState} from 'react';
import MyFavoriteApi from '@/api/my/favorite';
import throwToast from '@/utils/ui/toast';
import useMyStore from '@/stores/my';

/**
 * 즐겨찾기 그룹 추가 팝업
 * @param {Object} data - 팝업 데이터 (throwDialog 함수에서 전달)
 * @param {Function} handleClose - 팝업 닫기 함수 (팝업에서 전달)
 */
const MyFavoriteGroup = forwardRef(function MyLayer({data, handleClose}, ref) {
  const [groupName, setGroupName] = useState('');

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  const {getFavoriteGroups} = useMyStore();

  const handleGroupAdd = async () => {
    try {
      await MyFavoriteApi.addFavoriteGroup({
        favoriteIds: [data.favoriteId],
        title: groupName,
      });
      throwToast('즐겨찾기 그룹에 추가됐습니다.');
      getFavoriteGroups();
      data.fetchFavoriteGroups?.();
      handleClose();
    } catch (e) {
      // TODO: error handling
    }
  };

  return (
    <>
      <div className='list_form'>
        <div className='title'>
          <input
            type='text'
            placeholder='제목을 입력해주세요.'
            autoComplete='off'
            maxLength={8}
            name='title'
            value={groupName}
            onChange={handleGroupNameChange}
          />
          <span className='bytes'>
            <em>{groupName.length}/</em>10
          </span>
          <button
            type='button'
            className='delete'
            onClick={() => setGroupName('')}>
            삭제
          </button>
        </div>
      </div>
      <button type='button' className='submit' onClick={handleGroupAdd}>
        추가
      </button>
    </>
  );
});

export default MyFavoriteGroup;
