import {useRef} from 'react';

const useCreateInstance = (initialValueOrFunction = {}) => {
  const instanceRef = useRef();
  if (!instanceRef.current) {
    instanceRef.current =
      typeof initialValueOrFunction === 'function'
        ? initialValueOrFunction()
        : initialValueOrFunction;
  }

  return instanceRef.current;
};

export default useCreateInstance;
