'use client';

import {useState, useEffect} from 'react';
import {isLogin} from '@/utils/main';

export const SetList = () => {
  const [isShow, setIsShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      const hideLayer = localStorage.getItem('hideSetList');
      if (hideLayer) {
        const hideLayerDate = new Date(hideLayer);
        const nowDate = new Date();
        if (nowDate < hideLayerDate) {
          setIsShow(false);
        } else {
          setIsShow(true);
        }
      } else {
        setIsShow(true);
      }
    } catch (error) {
      setIsShow(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleOnClickClose = () => {
    setIsShow(false);
  };

  const handleOnClickCloseDays = () => {
    const nowDate = new Date();
    const hideLayer = new Date(nowDate.setDate(nowDate.getDate() + 30));
    localStorage.setItem('hideSetList', hideLayer.toISOString());
    setIsShow(false);
  };

  if (isLoading) {
    return null; // 로딩 중에는 아무것도 렌더링하지 않음
  }

  return (
    isLogin() &&
    isShow && (
      <div className='flex_box'>
        <div className='basic_alert'>
          <div className='title'>
            <em>내 취향</em>에 맞는
            <br /> 리스트를 선택해보세요!
          </div>
          <div className='conts'>
            <div className='explain_type'>
              <div className='box prefer'>
                <span className='icon'></span>
                <div className='text'>
                  <strong>선호</strong>
                  <p>
                    내 시청 이력과 즐겨찾기 내역을 <br />
                    바탕으로 맞춤 콘텐츠가 보여집니다.
                  </p>
                </div>
              </div>
              <div className='box popular'>
                <span className='icon'></span>
                <div className='text'>
                  <strong>인기</strong>
                  <p>
                    참여자수와 조회수를 기준으로<br/> 콘텐츠가 보여지며 SOOP의 <br/> 추천 콘텐츠도 확인할 수 있습니다.
                  </p>
                </div>
              </div>
            </div>
            <div className='close_days'>
              <input
                type='checkbox'
                name=''
                id='day30'
                onClick={handleOnClickCloseDays}
              />
              <label htmlFor='day30'>30일간 다시 보지 않기</label>
            </div>
          </div>
          <button
            type='button'
            className='btn_close'
            onClick={handleOnClickClose}>
            레이어 닫기
          </button>
        </div>
      </div>
    )
  );
};
