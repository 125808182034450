export const PLAYER_TYPE = {
  PREVIEW_PLAYER: 'PREVIEW_PLAYER',
  BANNER_PLAYER: 'BANNER_PLAYER',
  PREVIEW_AD_PLAYER: 'PREVIEW_AD_PLAYER',
};

export const PLAYER_SCRIPT_NAME = {
  PREVIEW_PLAYER: 'PreviewPlayer',
  PREVIEW_AD_PLAYER: 'BannerPlayer',
  BANNER_PLAYER: 'BannerPlayer',
};

export const STREAM_EVENT = {
  LOAD_START: 'loadStart',
  LOADED_METADATA: 'loadedMetaData',
  CAN_PLAY: 'canPlay',
  CAN_PLAY_LOWLATENCY: 'canPlayLowlatency',
  PLAY: 'play',
  PAUSE: 'pause',
  BUFFER: 'buffer',
  DURATION_CHANGE: 'durationChange',
  TIME_UPDATE: 'timeUpdate',
  VOLUME_CHANGE: 'volumeChange',
  END: 'end',
  ERROR: 'error',

  //adaptive streaming event
  LEVEL_SWITCHED: 'levelSwitched',

  /*
   * LOADED_DATA : 'loadedData',
   * STALLED : 'stalled',
   * */
};

export const PLAYER_EVENT = {
  AUTOPLAY_BLOCK: 'AUTOPLAY_BLOCK',
};

export const PREVIEW_PLAYER_ERROR = {
  EXCEED_PLAY_LIMIT: 'EXCEED_PLAY_LIMIT',
  STATUS_ERROR: 'STATUS_ERROR',
};
