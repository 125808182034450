import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

import {decodeHtmlEntities, preProcessValue} from '@/utils';

const useUrlParameter = () => {
  const location = useLocation();
  const [params, setSearchParams] = useSearchParams();
  const pathName = location.pathname;
  const navigate = useNavigate();

  // 멘티슈이슈 0050954로 로직 수정했는데, 혹시 몰라 기존로직 남겨둠
  // get { key1, key2 }
  // const getParams = () => {
  //   let param = {};
  //   if (params.toString() !== '') {
  //     // application/x-www-form-urlencoded 로 인해서 %20 (한칸 띄기) 가 + 로 변하는 이슈가 있어 decode 전 + 를 ' '로 치환
  //     let paramList = decodeURIComponent(
  //       params.toString().replaceAll(/\+/g, ' '),
  //     ).split('&');
  //     paramList.forEach((element) => {
  //       let paramInfo = element.split('=');
  //       param[`${paramInfo[0]}`] = paramInfo[1];
  //     });
  //   }
  //   return param;
  // };

  // get { key1, key2 }
  const getParams = () => {
    let param = {};
    if (params.toString() !== '') {
      // URLSearchParams를 사용하여 파라미터를 파싱
      const searchParams = new URLSearchParams(params.toString());
      for (const [key, value] of searchParams.entries()) {
        // '+' 문자를 먼저 공백으로 변환
        let decodedValue = value.replace(/\+/g, ' ');

        // 문제가 될 수 있는 문자 미리 처리
        decodedValue = preProcessValue(decodedValue);

        // 안전한 디코딩 함수 사용
        decodedValue = decodeURIComponent(decodedValue);

        // HTML 엔티티 디코딩
        decodedValue = decodeHtmlEntities(decodedValue);

        param[key] = decodedValue;
      }
    }
    return param;
  };

  /**
   * multi param push
   * @param newParams new path param ex) {ex, 'type': 'search_channel', 'type2': 'search_channel'}
   * @param path move router ex) /search , /live etc...
   * @returns
   */
  const setParams = (newParams, path = '', isRouter = false) => {
    if (!newParams) {
      // 없을 경우 작동 x
      return;
    }

    /**
     * 다른 페이지로 이동할 경우 매개변수로 받은 path 로
     */
    let movePath = path !== '' ? path : pathName;

    let currentParam = new URLSearchParams(params.toString()).entries();

    newParams = Object.assign(currentParam, newParams);
    newParams = Object.keys(newParams)
      .filter((key) => newParams[key])
      .reduce((res, key) => Object.assign(res, {[key]: newParams[key]}), {});
    // url 이동
    let newParam =
      Object.keys(newParams).length === 0 || isRouter
        ? '' // route 만 이동
        : '?' + new URLSearchParams(newParams).toString();
    // window.location.href= movePath + newParam;
    navigate(movePath + newParam);
  };

  return {
    router: navigate,
    pathName: pathName, // /{router}
    currentPath: `${pathName}${params ? '?' + params : ''}`, // router + param
    setParams,
    getParams, // param get
  };
};

export default useUrlParameter;
