import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

const store = (set) => {
  return {
    selectedCategoryListOrder: 'view_cnt',
    selectedCategoryMenu: 0,
    selectedCategoryType: {
      type: 'category',
      adminId: '',
    },
    selectedCategoryTab: 'live',
    selectedCategoryName: '',
    selectedContentFilter: {
      tab: 'vod',
      selected: [
        {key: 'sort', label: '조회수', type: 'view_cnt_desc'},
        {key: 'fileType', label: '전체', type: 'ALL'},
        {key: 'period', label: '1주', type: '1week'},
      ],
    },
    setSelectedCategoryListOrder: (selectedItem) => {
      set((state) => {
        return {selectedCategoryListOrder: selectedItem};
      });
    },
    setSelectedContentFilter: (selectedItem) => {
      set((state) => ({
        selectedContentFilter: {
          ...state.selectedContentFilter,
          tab: selectedItem.tab,
          selected: [...selectedItem.selected],
        },
      }));
    },
    setSelectedCategoryMenu: (selectedItem) => {
      set((state) => {
        return {selectedCategoryMenu: selectedItem};
      });
    },
    setSelectedCategoryType: (selectedItem) => {
      set((state) => ({
        selectedCategoryType: {
          ...state.selectedCategoryType,
          type: selectedItem.type,
          adminId: selectedItem.adminId,
        },
      }));
    },
    setSelectedCategoryTab: (selectedItem) => {
      set((state) => {
        return {selectedCategoryTab: selectedItem};
      });
    },
    setSelectedCategoryName: (selectedItem) => {
      set((state) => {
        return {selectedCategoryName: selectedItem};
      });
    },
  };
};

const useDirectoryStore = create(devtools(store));
export default useDirectoryStore;
