'use client';

import {useEffect} from 'react';
import globalNavigation from '@/utils/global-navigation';

import useMyStore from '@/stores/my';
import {useNavigate} from 'react-router-dom';
import useMainStore from '@/stores/main';
import useGlobalUIStore from '@/stores';

/**
 * 숲 내비게이션 로더 컴포넌트
 *
 * 숲 내비게이션 초기화 및
 * 스토어에 들어있는 유저 정보 / 광고 / 테마 셋팅을 한다
 */
export default function NavigationLoader() {
  const {setMyInfo} = useMyStore();
  const {setCheckAd} = useMainStore();
  const {setIsDarkMode} = useGlobalUIStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const gnb = document.getElementById('soop-gnb');
      const snb = document.getElementById('soop-snb');
      const fnb = document.getElementById('soop-fnb');

      // 숲 내비게이션 등록
      globalNavigation.init(gnb, snb, fnb);

      // 숲 내비게이션으로부터 유저 정보 받아오기
      globalNavigation.handleAuthorized((user) => {
        setMyInfo(user);
      });

      // SoopNavigation으로 부터 콜백을 받아서 이곳에서 실행해야 하는 불상사
      // Next Router <-> Other Js lib 간 연동할 방법이 없음
      globalNavigation.handleInternalRouteChange((path) => {
        console.log('handleInternalRouteChange::', path);
        navigate(path);
      });

      // 광고 정보 셋팅하기
      globalNavigation.handleNewAdChecked(
        ({hasNewAd, banners, AdParameter}) => {
          setCheckAd({
            hasNewAd: hasNewAd,
            banners: banners,
            AdParameter: AdParameter,
          });
        },
      );

      // 테마 정보 셋팅하기
      globalNavigation.handleThemeChanged((themeInfo) => {
        setIsDarkMode(themeInfo.value === 'dark');
      });
    }
  }, []);

  return <></>;
}
