import {SoopUiImg, SoopUiProfileIconMenu} from '@/components/ui';
import globalDomains from '@/utils/global-domains';
import {
  sendLegacyInflowPathLog,
  sendLiveThumbnailClickLog,
} from '@/utils/main/my/log';
import {useMemo, useRef, useState} from 'react';

/**
 * LIVE Component
 * @param {String} userId - 방송중인 유저 아이디
 * @param {String} userNick - 방송중인 유저 닉네임
 * @param {String} profileImage - 방송중인 유저 프로필 이미지
 * @param {Boolean} isPassword - 방송 비밀번호 여부
 * @param {Number} broadGrade - 방송 시청 등급
 * @param {Number} broadNo - 방송 번호
 * @param {String} broadTitle - 방송 제목
 * @param {String} thumb - 방송 썸네일
 * @param {Number} currentViewCnt - 현재 시청자 수
 * @returns
 */

// 방송국 새 글 라이브 방송 컴포넌트
const FeedLive = ({
  userId = '',
  userNick = '',
  profileImage = '',
  isPassword = false,
  broadGrade = 0,
  broadNo = 0,
  broadTitle = '',
  thumb = '',
  currentViewCnt = 0,
}) => {
  const [imageError, setImageError] = useState(false);
  const soopUiProfileIconMenu = useRef(null);

  const link = `${globalDomains.PLAY_AFREECATV}/${userId}/${broadNo}`;

  const handleThumbnailClick = () => {
    // 방송 썸네일 클릭 로그
    sendLiveThumbnailClickLog({
      streamerId: userId,
      broadcastId: broadNo,
      // 아래 정보들은 백엔드에서 안내려줘서 주석처리(기존 실서버에서도 undefined로 넘어가는 값들이라서 주석처리함)
      // broadCateNo: undefined,
      // broadBps: undefined,
      // broadResolution: undefined,
    });
    // 기존에 사용하던 라이브 방송 유입 로그
    sendLegacyInflowPathLog({
      streamerId: userId,
      broadcastId: broadNo,
    });
  };

  const handleImageError = () => {
    setImageError(true);
    return;
  };

  // 썸네일 이미지, 비공개, 19금 일반, 이미지 에러시 default 이미지
  const thumbnailImage = useMemo(() => {
    let className = '';

    if (isPassword && broadGrade >= 19) {
      className = 'thumb-lock_adult';
    } else if (isPassword) {
      className = 'thumb-lock';
    } else if (broadGrade >= 19) {
      className = 'thumb-adult';
    }

    return (
      <a
        href={link}
        className={className}
        target='_blank'
        onClick={handleThumbnailClick}>
        <span className='thum'>
          {!isPassword && broadGrade < 19 && !imageError ? (
            <SoopUiImg src={thumb} onError={handleImageError} type={'thumbs'} />
          ) : null}
        </span>
        <p className='sbj' title=''>
          {broadTitle}
        </p>
        <span className='feed_live'>LIVE</span>
      </a>
    );
  }, [broadGrade, isPassword, thumb, broadTitle, userId, broadNo]);

  return (
    <>
      <div className='list-item'>
        <div className='item-wrap'>
          <div className='autor_wrap'>
            <span className='thum'>
              <img src={profileImage} alt='프로필 이미지' />
            </span>
            <div className='info_box'>
              <button
                type='button'
                className='nick'
                onClick={() => {
                  soopUiProfileIconMenu.current?.open();
                }}>
                <p>{userNick}</p>
              </button>
              <span>{currentViewCnt}명 시청중</span>
              <SoopUiProfileIconMenu
                ref={soopUiProfileIconMenu}
                streamerId={userId}
                streamerNick={userNick}
              />
            </div>
          </div>

          <div className='onAir_box'>{thumbnailImage}</div>
        </div>
      </div>
    </>
  );
};

export default FeedLive;
