import {BroadcastLayout, BroadcastType} from '../common/Broadcast';
import ErrorBoundary from '../directory/category/ErrorBoundary';
import withHiddenStreamer from './withHiddenStreamer';
const LiveBroadcastItem = ({
  thumbnailUrl,
  userId,
  userNickname,
  viewCount,
  liveType,
  title,
  categoryTags,
  autoHashTags,
  hashTags,
  allowsAdult,
  listDataType,
  score,
  hasPassword,
  hasDrops,
  startDateTime,
  broadcastId,
  handleMenuClicked,
  location,
  broadcastType,
  contextMenu,
  log,
  canResend,
}) => {
  return (
    <ErrorBoundary fallback={<div>로딩중</div>}>
      <BroadcastLayout
        broadcastType={broadcastType}
        viewType='grid'
        thumbnailUrl={thumbnailUrl}
        userId={userId}
        userNickname={userNickname}
        viewCount={viewCount}
        liveType={liveType}
        broadcastId={broadcastId}
        title={title}
        categoryName={
          categoryTags && categoryTags.length > 0 ? categoryTags[0] : ''
        }
        autoHashTags={autoHashTags}
        categoryTags={categoryTags}
        hashTags={hashTags}
        allowsAdult={Number(allowsAdult)}
        hasBadge={autoHashTags || categoryTags || hashTags}
        listDataType={listDataType}
        score={score}
        hasPassword={hasPassword}
        hasDrops={hasDrops}
        startDateTime={startDateTime}
        handleMenuClicked={handleMenuClicked}
        location={location}
        contextMenu={contextMenu}
        canResend={canResend}
        log={log}>
        <BroadcastType.Live />
      </BroadcastLayout>
    </ErrorBoundary>
  );
};

export default withHiddenStreamer(LiveBroadcastItem);
