import MyFeedApi from '@/api/my/feed';

const HiddenFeed = ({userId, fetchFeedData}) => {
  const handleUnBlindBtn = async () => {
    await MyFeedApi.setFeedUnBlind(userId);
    fetchFeedData();
  };

  return (
    <li className='hide_post'>
      <div>
        <strong>게시물 안보기 설정한 게시물 입니다.</strong>
        <p>실시간 인기글에서 해당 스트리머의 게시물이 노출되지 않습니다.</p>
        <button type='button' className='btn-basic' onClick={handleUnBlindBtn}>
          <span>게시물 안보기 해제</span>
        </button>
      </div>
    </li>
  );
};

export default HiddenFeed;
