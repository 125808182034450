import MyHistoryStoryApi from '@/api/my/history/story';
import MyProfileImg from '@/components/my/common/item/MyProfileImg';
import {SoopUiContextMenu} from '@/components/ui';
import {SOOP_UI_IMG_TYPES} from '@/components/ui/SoopUiImg';
import {SoopUiProfileIconMenu, SoopUiImg} from '@/components/ui';
import {MY_STORY_MENU} from '@/constants/my';
import {decodeEmoticon} from '@/libs/emoticon';
import globalDomains from '@/utils/global-domains';
import {convertViewCnt} from '@/utils/main';
import {throwConfirm} from '@/utils/ui/dialog';
import Link from 'next/link';
import {useMemo, useRef, useState} from 'react';

/**
 * @typedef {Object} Count
 * @description 조회수, 댓글수
 * @property {Number} readCnt - 조회수
 * @property {Number} commentCnt - 댓글수
 */

/**
 * @typedef {Array} Photos
 * @description 사진 목록
 * @property {String} url - 사진 URL
 */

/**
 * @description - MyPost 컴포넌트
 * @param {Object} props - MyPost props
 * @param {String} props.feedType - 게시글 타입
 * @param {String} props.url - 게시글 URL
 * @param {String} props.stationUserId - 채널 아이디
 * @param {String} props.stationUserNick - 채널 닉네임
 * @param {String} props.userId - 작성자 아이디
 * @param {Boolean} props.isNotice - 공지 여부
 * @param {Number} props.titleNo - 게시글 번호
 * @param {String} props.titleName - 제목
 * @param {String} props.content - 내용
 * @param {String} props.regDate - 등록일
 * @param {Object} props.count - 조회수, 댓글수
 * @param {Array} props.photos - 사진 목록
 * @param {Object} props.ucc - vod 데이터
 * @returns {JSX.Element} MyPost 컴포넌트
 */
const MyPost = ({
  url = '',
  feedType = '',
  stationUserId = '',
  stationUserNick = '',
  userId = '',
  isNotice = false,
  titleNo = 0,
  titleName = '',
  content = '',
  regDate = '',
  refetching = () => {},
  boardType = 104, // 103 사진 게시글, 104 일반 게시글, 105 vod 게시글
  count: {readCnt = 0, commentCnt = 0} = {readCnt: 0, commentCnt: 0},
  photos = [],
  ucc = {},
}) => {
  const soopUiProfileIconMenu = useRef(null);
  const [isLoadImageError, setIsLoadImageError] = useState(false);
  const [imageClassName, setImageClassName] = useState('');
  const deleteMyPost = async ({feedType, stationUserId, titleNo}) => {
    try {
      await MyHistoryStoryApi.deleteMyHistoryStory({
        feedType,
        stationUserId,
        titleNo,
      });
      refetching(feedType, titleNo);
      return;
    } catch (error) {
      console.log(error);
      return;
    }
  };
  const handleMenuClicked = async (e, type) => {
    const {data: isOk} = await throwConfirm({
      subTitle: '선택하신 게시글(댓글,답글 포함)을 삭제하시겠습니까?',
      message: '삭제 시 스토리와 채널에서 완전 삭제되며, 복구가 불가능합니다.',
    });

    if (!isOk) {
      return;
    }
    if (type === 'delete') {
      deleteMyPost({feedType, stationUserId, titleNo});
    }
  };

  const handleThumbnailPath = useMemo(() => {
    if (boardType === 105) {
      if (!!ucc) {
        const {grade, category, thumb} = ucc || {};
        if (Number(grade) === 19 && category === '30000') {
          setImageClassName('thumb-adult');
          setIsLoadImageError(true);
          return '';
        }
        return 'https:' + thumb;
      }
      setIsLoadImageError(true);
      setImageClassName('thumb-default');
      return '';
    }
    if (photos.length > 0) {
      return photos?.[0]?.url || '';
    }

    return '';
  }, [isLoadImageError]);

  const handleImageError = () => {
    setImageClassName('thumb-default');
    setIsLoadImageError(true);
  };

  return (
    <li className='post'>
      <span className='list_icon' />
      <div className='list_wrap'>
        {/* 내 채널에서 작성한 게시글 */}
        {stationUserId === userId ? (
          <>
            {/* 게시글 정보 */}
            <div className='post_cont'>
              {/* 이미지 */}
              {photos.length > 0 && (
                <div className='post_img'>
                  <a href={url} target='_blank' className={imageClassName}>
                    {!isLoadImageError && (
                      <SoopUiImg
                        src={handleThumbnailPath}
                        type={SOOP_UI_IMG_TYPES.thumbnail}
                        onError={handleImageError}
                      />
                    )}
                  </a>
                </div>
              )}
              {/* 본문 */}
              <div className='cont_area'>
                <a href={url} target='_blank'>
                  <span className='post_badge'>게시글</span>
                  <h3 className='title'>
                    {isNotice && <span className='notice'>공지</span>}
                    {titleName}
                  </h3>
                  <p
                    className='desc'
                    dangerouslySetInnerHTML={{
                      __html: decodeEmoticon(content),
                    }}></p>
                </a>
                <div className='info'>
                  <span className='date'>{regDate}</span>
                  <span className='views'>{convertViewCnt(readCnt)}</span>
                  <span className='cmt'>{commentCnt}</span>
                </div>
              </div>
            </div>
            <SoopUiContextMenu
              menuList={MY_STORY_MENU}
              handleMenuClicked={handleMenuClicked}
              data={(feedType, stationUserId, titleNo)}
            />
          </>
        ) : (
          <>
            {/* 타 채널에서 작성한 게시글 */}
            {/* 타 채널 정보 */}
            <div className='bs_wrap'>
              <div className='cont_area'>
                <Link
                  href={`${globalDomains.BJ_AFREECATV}/${stationUserId}`}
                  target='_blank'>
                  <MyProfileImg userId={stationUserId} className='thumb' />
                </Link>
                <div className='bs_detail'>
                  <div className='info_wrap'>
                    <div className='nick_wrap'>
                      <button
                        type='button'
                        className='nick'
                        onClick={() => {
                          soopUiProfileIconMenu.current?.open();
                        }}>
                        <strong>{stationUserNick}</strong>
                        님의 채널
                        <SoopUiProfileIconMenu
                          ref={soopUiProfileIconMenu}
                          streamerId={stationUserId}
                          streamerNick={stationUserNick}
                        />
                      </button>
                      <div className='nick_wrap'></div>
                      <span className='bs_title'>게시글</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 게시글 정보 */}
            <div className='post_box'>
              {/* 이미지 */}
              {photos.length > 0 && (
                <div className='post_img'>
                  <a href={url} target='_blank'>
                    <SoopUiImg
                      src={photos?.[0]?.url}
                      type={SOOP_UI_IMG_TYPES.thumbnail}
                    />
                  </a>
                </div>
              )}
              {/* 본문 */}
              <div className='cont_area'>
                <Link href={url} target='_blank'>
                  <span className='post_badge'>게시글</span>
                  <h3 className='title'>{titleName}</h3>
                  <p
                    className='desc'
                    dangerouslySetInnerHTML={{
                      __html: decodeEmoticon(content),
                    }}></p>
                </Link>
                <div className='info'>
                  <span className='date'>{regDate}</span>
                  <span className='views'>{convertViewCnt(readCnt)}</span>
                  <span className='cmt'>{commentCnt}</span>
                </div>
              </div>
              <SoopUiContextMenu
                menuList={MY_STORY_MENU}
                handleMenuClicked={handleMenuClicked}
              />
            </div>
          </>
        )}
      </div>
    </li>
  );
};

export default MyPost;
