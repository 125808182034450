'use client';

import MyFavoriteApi from '@/api/my/favorite';
import AvatarGroup from '@/components/my/common/group/AvatarGroup';
import useMyStore from '@/stores/my';
import throwToast from '@/utils/ui/toast';
import {sendPinClickLog} from '@/utils/main/my/log';

const MAX_SIZE = 100;

const Streamers = () => {
  const {streamers, getStreamers} = useMyStore();

  const slicedStreamers = streamers.slice(0, MAX_SIZE);

  const handleUserPin = async (userId, isPin) => {
    if (isPin) {
      await MyFavoriteApi.deletePin(userId);
      throwToast('목록 상단 고정 해제 되었습니다.');
    } else {
      await MyFavoriteApi.setPin(userId);
      throwToast('목록 상단 고정 완료 되었습니다.');
    }

    sendPinClickLog({
      isAddPin: !isPin,
      streamerId: userId,
    });
    getStreamers();
  };

  return (
    <div className='strm_area'>
      <AvatarGroup
        favorites={slicedStreamers}
        handleUserPin={handleUserPin}
        isFavorite
      />
    </div>
  );
};

export default Streamers;
