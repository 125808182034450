import {useEffect} from 'react';
import {Category, CategoryError} from '@/components/main/directory/category';
import {useParams, useNavigate} from 'react-router-dom';

export default function DiscoverCategoryDetail() {
  const {categoryName, categoryType} = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !categoryName ||
      !categoryType ||
      !['live', 'vod', 'catch'].includes(categoryType)
    ) {
      navigate(`/directory/category`);
    }
  }, [categoryName, categoryType, navigate]);

  if (
    categoryName &&
    categoryType &&
    ['live', 'vod', 'catch'].includes(categoryType)
  ) {
    try {
      return (
        <Category categoryName={categoryName} categoryType={categoryType} />
      );
    } catch (error) {
      // 추후 에러페이지로 리턴할지, 메인으로 리다이렉트시킬지..기획적으로 정해봐야할듯
    }
  } else {
    return <CategoryError />;
  }
}
