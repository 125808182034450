import LogApi from '@/api/common/log';
import {sendLegacyInflowPathLog} from '../my/log';

export const sendClickLog = (codeType = '', sendData) => {
  const params = {
    codeType,
    sendData,
  };

  LogApi.sendClickLog(params);
};

/**
 * 상단 비주얼 영역 클릭했을 때 라이브일 경우 보내는 유입로그
 * @param {Object} params
 * @param {number} params.broadCateNo - live 방송의 카테고리 번호
 */
export const sendTopListLiveInflowLog = ({streamerId, broadcastId}) => {
  sendLegacyInflowPathLog({
    isLogSendData: {
      path1: 'home',
      path2: 'top_list',
      path3: 'live',
      contentsType: 'live',
    },
    streamerId,
    broadcastId,
    categoryTags: [],
    hashTags: [],
  });
};
