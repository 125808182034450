'use client';
import FavoriteList from '@/components/my/feed/group/FavoriteList';
import PostList from '@/components/my/feed/group/PostList';
import NoFeedList from '@/components/my/feed/group/NoFeedList';
import Side from '@/components/my/feed/group/Side';
import {useState} from 'react';
import '/public/static/css/module/feed.scss';

const MyFeed = () => {
  const [isFavoriteList, setIsFavoriteList] = useState(null);
  return (
    <div id='container' className='content_area feed'>
      {/* 내 즐겨찾기 리스트 */}
      <FavoriteList setIsFavoriteList={setIsFavoriteList} />

      {isFavoriteList && (
        <div id='bs-contents'>
          {/* 내 채널 새 글 리스트 */}
          <PostList />

          {/* feed side */}
          <Side />
        </div>
      )}

      {/* 내 즐겨찾기 리스트가 없는 경우 */}
      {isFavoriteList !== null && isFavoriteList === false && <NoFeedList />}
    </div>
  );
};

export default MyFeed;
