import MyTabGroup from '@/components/my/common/group/TabGroup';
import {ROUTE} from '@/constants/main/route';
import {Outlet, useLocation} from 'react-router-dom';
import '/public/static/css/module/depth2Menu.scss';

const TABS = [
  {
    name: '즐겨찾기',
    route: ROUTE.MY.FAVORITE,
  },
  {
    name: '구독',
    route: ROUTE.MY.SUBSCRIBE,
  },
  {
    name: '팬클럽',
    route: ROUTE.MY.FAN,
  },
  {
    name: '기록',
    route: ROUTE.MY.HISTORY.RECENT.INDEX,
    layout: [
      ROUTE.MY.HISTORY.RECENT.INDEX,
      ROUTE.MY.HISTORY.RECENT.VOD,
      ROUTE.MY.HISTORY.LATER,
      ROUTE.MY.HISTORY.UP,
      ROUTE.MY.HISTORY.PLAYLIST,
    ],
  },
  {
    name: '내 글/댓글',
    route: ROUTE.MY.HISTORY.STORY,
  },
];

/**
 * /my 경로 한정 레이아웃
 * @param {React.ReactNode} 페이지
 * @returns {JSX.Element} 페이지 레이아웃
 */
const MyLayout = ({children}) => {
  const location = useLocation();
  // 내 글/댓글 페이지에서는 다른 스타일을 적용한다
  const isMyHistoryStory = location.pathname === ROUTE.MY.HISTORY.STORY;
  return (
    <>
      <div className='dept2_menu_wrap'>
        <MyTabGroup tabs={TABS} />
      </div>
      <div
        id='container'
        className={`_my _my_${isMyHistoryStory ? 'story' : 'fav'}`}
        style={{marginBottom: '60px'}}>
        <Outlet />
      </div>
    </>
  );
};

export default MyLayout;
