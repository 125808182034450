import {useState, useEffect, useRef} from 'react';

import {SoopUiSwiper} from '@/components/ui';
import {RenderMatchInfo} from '@/components/search/group/game-profile-group';

import dayjs from 'dayjs';

/**
 * GameSchedule 컴포넌트 ( 경기 일정 탭 )
 * @param {Object} props - 컴포넌트의 props
 * @param {Function} fetchGameScheduleList 경기일정 데이터 받아오는 함수
 * @param {Function} logCallback 로그 관련 콜백 함수
 * @returns GameSchedule 컴포넌트
 */
const GameSchedule = ({
  fetchGameScheduleList = () => {},
  logCallback = () => {},
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [allGameSchedules, setAllGameSchedules] = useState([]); // 해당 일자의 모든 경기 일정 데이터 ( = 원본 )
  const [filteredGameSchedules, setFilteredGameSchedules] = useState([]); // 경기 일정 관련 탭 클릭시 필터되는 경기 일정 데이터
  const [gameScheduleDate, setGameScheduleDate] = useState(() =>
    dayjs().format('YYYY-MM-DD'),
  );

  const inputRef = useRef(null);

  const gameTabList = [
    {name: '전체'},
    ...allGameSchedules
      .flatMap((data) => {
        return data.menu || [];
      })
      .map((menuItem) => {
        const name = navigator.language?.startsWith('ko')
          ? menuItem.codeName
          : menuItem.codeNameEng;
        return {name};
      }),
  ];

  // 이전 경기 일정 버튼 클릭
  const handlePrevClick = () => {
    logCallback({type: 'date'});
    setGameScheduleDate(
      dayjs(gameScheduleDate).subtract(1, 'day').format('YYYY-MM-DD'),
    );
  };

  // 다음 경기 일정 버튼 클릭
  const handleNextClick = () => {
    logCallback({type: 'date'});
    setGameScheduleDate(
      dayjs(gameScheduleDate).add(1, 'day').format('YYYY-MM-DD'),
    );
  };

  // 탭 클릭 시 해당 탭에 포함된 경기 목록만 필터링
  const handleTabClick = (index) => {
    if (!Number.isInteger(index) || index < 0 || index >= gameTabList.length) {
      console.error('Invalid tab index');
      return;
    }

    logCallback({type: 'lg_name'});
    setActiveTab(index);

    if (index === 0) {
      setFilteredGameSchedules(allGameSchedules || []);
      return;
    }

    const selectedTab = gameTabList[index]?.name;
    if (!selectedTab) {
      console.error('Selected tab name not found');
      return;
    }

    const filteredList = (allGameSchedules || [])
      .map((game) => ({
        ...game,
        list: (game.list || []).filter(
          (gameItem) => gameItem?.codeName === selectedTab,
        ),
      }))
      .filter((game) => game.list.length > 0);

    setFilteredGameSchedules(filteredList);
  };

  // 경기 일정 데이터 가져오기
  const handleFetchGameScheduleList = async () => {
    const esportData = await fetchGameScheduleList('esport', gameScheduleDate);

    const isScheduleEmpty = (schedule) => {
      return (
        schedule?.menu.length === 0 &&
        schedule?.list.length === 0 &&
        !schedule?.nextDate &&
        !schedule?.prevDate
      );
    };

    const schedules =
      esportData?.length && !isScheduleEmpty(esportData[0]) ? esportData : [];

    setAllGameSchedules(schedules);
    setFilteredGameSchedules(schedules);
  };

  // 달력 UI > 날짜 변경 관련 로직
  // input 요소에 `change` 이벤트 리스너를 추가
  useEffect(() => {
    const inputElement = inputRef.current;

    // `change` 이벤트 발생 시 호출될 함수
    const handleDateChange = (event) => {
      logCallback({type: 'date'});
      setGameScheduleDate(event.target.value);
    };

    inputElement.addEventListener('change', handleDateChange);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거하여 메모리 누수 방지
      inputElement.removeEventListener('change', handleDateChange);
    };
  }, []);

  //`gameScheduleDate` 상태가 변경될 때 input 요소의 value값 업데이트
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = gameScheduleDate;
    }
    setActiveTab(0); // 경기일정 탭 리셋
    handleFetchGameScheduleList();
  }, [gameScheduleDate]);

  const renderDateSection = () => (
    <div className='date_wrap'>
      <button type='button' className='prev' onClick={handlePrevClick}>
        이전
      </button>
      <div className='date'>
        <div className='inp_date'>
          <span>{dayjs(gameScheduleDate).format('YYYY. MM. DD')}</span>
          <input
            ref={inputRef}
            id='schedule_date'
            type='date'
            onClick={() => logCallback({type: 'date'})}
          />
        </div>
      </div>
      <button type='button' className='next' onClick={handleNextClick}>
        다음
      </button>
    </div>
  );

  const renderNoGameSchedule = (date, direction, handleClick) => (
    <div className='schedule_area'>
      <div className='noList'>해당일에는 경기가 없습니다.</div>
      <div className='game_schedule_btn'>
        <button
          type='button'
          className={`${direction}_schedule`}
          onClick={() => {
            setGameScheduleDate(date);
            handleClick();
          }}>
          {direction === 'next' ? '다음 경기 일정' : '이전 경기 일정'}
        </button>
      </div>
    </div>
  );

  const renderGameTabs = () => (
    <div className='game_tab_area'>
      <SoopUiSwiper
        swiperTag='ul'
        swiperClass='game_tab_list'
        isDisableButton={true}
        options={{
          slidesPerView: 'auto',
          loop: false,
        }}>
        {gameTabList.map((leagueTab, tabIndex) => (
          <li
            key={`leagueTab-${leagueTab.name}-${tabIndex}`}
            className={tabIndex === activeTab ? 'on' : ''}>
            <button type='button' onClick={() => handleTabClick(tabIndex)}>
              <span>{leagueTab.name}</span>
            </button>
          </li>
        ))}
      </SoopUiSwiper>
    </div>
  );

  const renderGameSchedules = (gameList) => (
    <div className='schedule_area'>
      <SoopUiSwiper
        key={gameList?.date}
        swiperTag='ul'
        swiperClass='schedule_list'
        maxSlidesPerView={3}
        options={{
          slidesPerView: 'auto',
          loop: false,
        }}>
        {gameList.list?.map((game, matchIndex) => (
          <li key={`${game.titleNo}-${matchIndex}`}>
            <RenderMatchInfo
              key={matchIndex}
              game={game}
              matchIndex={matchIndex}
              logCallback={logCallback}
            />
          </li>
        ))}
      </SoopUiSwiper>
    </div>
  );

  const renderGameContent = (gameList) => {
    if (gameList.nextDate) {
      return renderNoGameSchedule(gameList.nextDate, 'next', () =>
        logCallback({type: 'date'}),
      );
    } else if (gameList.prevDate) {
      return renderNoGameSchedule(gameList.prevDate, 'prev', () =>
        logCallback({type: 'date'}),
      );
    } else {
      return (
        <>
          {renderGameTabs()}
          {renderGameSchedules(gameList)}
        </>
      );
    }
  };

  return (
    <>
      <div className='game_schedule'>
        {renderDateSection()}
        {filteredGameSchedules.length === 0 ? (
          <div className='schedule_area'>
            <div className='noList'>해당일에는 경기가 없습니다.</div>
          </div>
        ) : (
          filteredGameSchedules.map((gameList, gameIndex) => (
            <div key={`gameList-${gameIndex}`}>
              {renderGameContent(gameList)}
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default GameSchedule;
