'use client';
import CategoryApi from '@/api/directory/category';
import {
  CategoryContent,
  CategoryError,
  CategoryDetail,
} from '@/components/main/directory/category';

import {useEffect, useState} from 'react';
import {DirectoryMenu} from '../DirectoryMenu';
import '/public/static/css/module/category.scss';

const Category = ({categoryName, categoryType}) => {
  const [categoryInfo, setCategoryInfo] = useState({});

  useEffect(() => {
    getCategoryInfo(categoryName);
  }, [location.pathname]);

  // 카테고리 상세정보 api
  const getCategoryInfo = async (categoryName) => {
    try {
      const queryParams = {
        m: 'categoryInfo',
        szPlatform: 'pc',
        szCateName: categoryName,
      };

      const response = await CategoryApi.getCategoryInfo(queryParams);
      if (response && response.result === 1) {
        setCategoryInfo(response.data);
      } else {
        setCategoryInfo({});
      }
    } catch (error) {
      setCategoryInfo({});
    }
  };
  return (
    <>
      {categoryInfo && Object.keys(categoryInfo).length > 0 ? (
        <>
          <DirectoryMenu />
          <div id='container' className='_category'>
            <CategoryDetail categoryInfo={categoryInfo} />
            <CategoryContent
              categoryName={categoryName}
              categoryInfo={categoryInfo}
              categoryType={categoryType}
            />
          </div>
        </>
      ) : (
        <CategoryError />
      )}
    </>
  );
};
export default Category;
