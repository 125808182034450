'use client';
import {SoopUiProfileIconMenu} from '@/components/ui';
import {decodeEmoticon} from '@/libs/emoticon';
import globalDomains from '@/utils/global-domains';
import {useRef, useState} from 'react';
import ActionGroup from '@/components/my/feed/hotissue/item/ActionGroup';
import {convertViewCnt} from '@/utils/main';

/**
 * @typedef {Object} count
 * @property {number} likeCnt - 좋아요 수
 * @property {number} readCnt - 조회 수
 * @property {number} commentCnt - 댓글 수
 */

/**
 * @typedef {Object} photos
 * @property {Number} photoNo - 이미지 번호
 * @property {String} url - 이미지 URL
 */

/**
 * 채널 새 글
 * @param {String} stationUserId - 채널 아이디
 * @param {String} stationUserNick - 채널 닉네임
 * @param {String} stationProfileImage - 채널 프로필 이미지
 * @param {String} regDate - 등록일
 * @param {Object} count - 좋아요, 조회, 댓글 수
 * @param {Boolean} isLike - 좋아요 여부
 * @param {String} url - 글 URL
 * @param {String} titleName - 제목
 * @param {String} content - 내용
 * @param {Object[]} photos - 이미지
 * @returns
 */

const NewPost = (props) => {
  const {
    userId = '',
    userNick = '',
    profileImage = '',
    stationUserId = '',
    stationUserNick = '',
    stationProfileImage = '',
    regDate = '',
    count: {likeCnt = 0, readCnt = 0, commentCnt = 0} = {
      likeCnt: 0,
      readCnt: 0,
      commentCnt: 0,
    },
    isLike = false,
    isNotice = false,
    url = '',
    titleName = '',
    titleNo = 0,
    content = '',
    hashtags = [],
    photos = [],
  } = props;
  const [isStationProfileMenuShow, setIsStationProfileMenuShow] =
    useState(false);
  const [isProfileMenuShow, setIsProfileMenuShow] = useState(false);
  const soopUiProfileIconMenuStation = useRef(null);
  const soopUiProfileIconMenuUser = useRef(null);
  return (
    <>
      <div className='list-item'>
        <div className='item-wrap'>
          {stationUserId !== userId && (
            <div className='list-item-header'>
              <div className='autor_wrap'>
                <span className='thum'>
                  <a
                    href={`${globalDomains.BJ_AFREECATV}/${stationUserId}`}
                    target='_blank'>
                    <img src={stationProfileImage} alt='프로필 이미지' />
                  </a>
                </span>
                <div className='info_box'>
                  <button
                    type='button'
                    className='nick'
                    onClick={() => {
                      soopUiProfileIconMenuStation.current?.open();
                    }}>
                    <p>{stationUserNick}</p>
                    <em>의 방송국</em>
                  </button>
                  <SoopUiProfileIconMenu
                    ref={soopUiProfileIconMenuStation}
                    streamerId={stationUserId}
                    streamerNick={stationUserNick}
                  />
                </div>
              </div>
            </div>
          )}

          {/* 작성자 프로필, 작성일, 조회수 */}
          <div className='autor_wrap'>
            <span className='thum'>
              <a
                href={`${globalDomains.BJ_AFREECATV}/${userId}`}
                target='_blank'>
                <img src={profileImage} alt='프로필 이미지' />
              </a>
            </span>
            <div className='info_box'>
              <button
                type='button'
                className='nick'
                onClick={() => {
                  soopUiProfileIconMenuUser.current?.open();
                }}>
                <p>{userNick}</p>
              </button>
              <span>{regDate}</span>
              <span>{`조회 ${convertViewCnt(readCnt)}`}</span>
              <SoopUiProfileIconMenu
                ref={soopUiProfileIconMenuUser}
                streamerId={userId}
                streamerNick={userNick}
              />
            </div>
          </div>
          {/* 제목, 내용 */}
          <div className='item_contents'>
            <a href={url} target='_blank'>
              <h3>
                {isNotice && <i className='ic_noti'>공지</i>}
                {titleName}
              </h3>
              <p
                className='text'
                dangerouslySetInnerHTML={{__html: decodeEmoticon(content)}}></p>
            </a>
          </div>

          {/* 해쉬태그(디자인 안나와서 임시 미노출 처리. MY 리브랜딩 때 노출 예정) */}
          {/* {hashtags && hashtags.length > 0 && (
            <ul className='hasTag'>
              {hashtags.map((hashtag, idx) => (
                <li key={idx}>
                  <a
                    href={`/search?szSearchType=post&szKeyword=${hashtag}&szStype=di&szActype=input_field&rs=1&szVideoFileType=ALL`}
                    target='_blank'>{`#${hashtag}`}</a>
                </li>
              ))}
            </ul>
          )} */}

          {/* 이미지가 있는 경우 노출 */}
          {photos.length > 0 && (
            <div className='file'>
              <a href={url} target='_blank'>
                <img
                  className='image'
                  src={photos?.[0]?.url}
                  alt='프로필 이미지'
                />
              </a>
            </div>
          )}
          <ActionGroup {...props} />
        </div>
      </div>
    </>
  );
};

export default NewPost;
