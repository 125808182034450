import {useEffect, useState, useMemo, useCallback} from 'react';

import {
  NoSearchResults,
  ShowMore,
  FilterBox,
  Loading,
} from '@/components/search/common';
import {SoopUiImg, SoopUiSelectBox} from '@/components/ui';
import {SectionHeader} from '@/components/main/common';
import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';
import {PostGroupThumbnailInfo} from '@/components/search/group';
import useConvertRegDate from '@/components/main/common/hooks/useConvertRegDate';
import {useInfiniteScroll} from '@/components/main/common/hooks/useInfiniteScroll';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';

import {
  SEARCH_POST_FILTER,
  SEARCH_POST_TAB_FILTER,
} from '@/constants/search/filter';
import {decodeEmoticon} from '@/libs/emoticon';
import {convertViewCnt, newLineToBreak} from '@/utils/main';

/**
 * PostGroup 컴포넌트 ( 게시글 )
 * @param {Object} props 컴포넌트의 props
 * @param {Object[]} post 게시글관련 데이터
 * @param {String} stopWord 금칙어
 * @returns {JSX.Element} PostGroup 컴포넌트
 */
const PostGroup = ({post, stopWord}) => {
  const {getParams} = useUrlParameter();
  const {szKeyword: keyword = '', szSearchType: searchType = ''} = getParams();
  const {
    sessionKey,
    hasMore,
    changeStatus,
    perPage,
    currentPageNumber,
    nextPage,
    postLoading,
    noResult,
    activeTab,
    resetPageNumber,
    isTotalPage,
    isPostPage,
  } = useSearchGlobalStatus();

  const {convertRegDate} = useConvertRegDate();
  const {sendPostClickLog} = useSearchClickLog();

  const [postList, setPostList] = useState([]);
  const [postListSlice, setPostListSlice] = useState(0);
  const [selectBoxItem, setSelectBoxItem] = useState('view_cnt');

  const postDisplayedList = useMemo(() => {
    if (isTotalPage) {
      return postList.slice(0, Math.max(postListSlice, 6)); // 최소 6개 항목 보장
    }
    return postList;
  }, [searchType, postList, postListSlice]);

  // 사용자가 검색한 키워드가 제목이나 내용에 있는 경우 볼드처리
  const highlightKeyword = (text, keyword) => {
    if (!keyword) return text;

    // HTML 이스케이프 함수
    const escapeHtml = (str) => {
      return str
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
    };

    // 텍스트와 키워드를 이스케이프
    let safeText = escapeHtml(text);
    let safeKeyword = escapeHtml(keyword);

    // 정규식 이스케이프
    let escapeRegExp = safeKeyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    let pattern = new RegExp(`${escapeRegExp.trim()}`, 'gi');
    const highlightText = safeText.replace(pattern, '<strong>$&</strong>');

    return highlightText;
  };

  const {ref: lastPositionRef, inView} = useInfiniteScroll({
    delay: 100,
    onChange: (inView) => {
      // 주석된코드 없어도 잘 동작되는데, 혹시 모르니 코드 남겨둠
      // if (inView) {
      //   onHasMore((prevState) => ({...prevState, post: true}));
      // }
      if (inView && isPostPage && hasMore.post) {
        nextPage(activeTab);
      }
    },
  });

  // 더보기 클릭
  const handleShowMore = () => {
    setPostListSlice((prevCount) => prevCount * 2);

    handlePostActionLog({type: 'more'});
  };

  // 통합검색칩 > 필터 옵션 변경
  const handleChangeFilterOption = (item, options) => {
    setSelectBoxItem(item);
    changeStatus('post', 12, [options]);
  };

  // 게시글칩 > 필터 옵션 변경
  const handleChangeDetailFilterOption = (filters) => {
    setPostList([]);
    changeStatus('post', perPage, filters, true);
  };

  // SCKACT 로그
  const handlePostActionLog = useCallback(
    (params, index, postNumber, stationUserId, userId) => {
      const {type} = params || {};
      let contentsType = '';
      let contentsValue = '';

      if (['tt', 'sn'].includes(type)) {
        contentsType = 'post';
        contentsValue = postNumber;
      }

      if (['sti', 'stn'].includes(type)) {
        contentsType = 'bj';
        contentsValue = type === 'sti' ? userId : stationUserId;
      }

      const isRemoveField = ['nick', 'more'].includes(type);

      const param = {
        actCode: type,
        bjid: stationUserId,
        page: currentPageNumber['post'],
        listIdx: index,
        contentsType: contentsType,
        contentsValue: contentsValue,
        tno: postNumber,
        writer: userId,
        sessionKey: sessionKey,
      };

      sendPostClickLog(param, '');
    },
    [searchType, sessionKey, currentPageNumber['post']],
  );

  useEffect(() => {
    setPostListSlice(isTotalPage ? 6 : 40);
  }, [searchType, postList.length]);

  useEffect(() => {
    if (currentPageNumber[activeTab] > 1) {
      setPostList((prevPost) => [...prevPost, ...post]);
    } else {
      setPostList(post);
    }
  }, [post]);

  useEffect(() => {
    // 게시글 필터옵션 '조회수'로 초기화(통합검색에서만)
    if (isTotalPage) {
      setSelectBoxItem('view_cnt');
    }
  }, [searchType]);

  useEffect(() => {
    return () => {
      resetPageNumber('post');
    };
  }, []);

  const renderPostSectionHeader = () => {
    if (isTotalPage && postList.length > 0) {
      return (
        <SectionHeader
          title='게시글'
          rightElement={
            <SoopUiSelectBox
              options={SEARCH_POST_FILTER}
              type={selectBoxItem || ''}
              onChange={handleChangeFilterOption}
            />
          }
        />
      );
    } else if (isPostPage) {
      return (
        <FilterBox
          title='게시글'
          sortItems={SEARCH_POST_TAB_FILTER}
          onCallback={handleChangeDetailFilterOption}
        />
      );
    }
    return null;
  };

  const renderPostSectionNoResult = () => {
    const shouldRenderNoResult =
      isPostPage && !postLoading && (stopWord || noResult.post || !keyword);

    if (shouldRenderNoResult) {
      return (
        <NoSearchResults
          searchTab='post'
          isKeywordEntered={Boolean(keyword)}
          stopWord={stopWord}
        />
      );
    }

    return null;
  };

  const renderPostListItems = () => {
    return postDisplayedList.map((post, index) => (
      <li key={`search_post-${post.titleNo}-${index}`} className='post_box'>
        <div className='post_conts'>
          <PostGroupThumbnailInfo
            userId={post.userId}
            stationUserId={post.stationUserId}
            userNick={post.userNick}
            stationName={post.stationName}
            logCallback={(params) => {
              handlePostActionLog(
                params,
                index,
                post.titleNo,
                post.stationUserId,
                post.userId,
              );
            }}
          />
          <div className='conts_box'>
            <a
              className='title'
              href={`${post.url}`}
              dangerouslySetInnerHTML={{
                __html: highlightKeyword(
                  decodeEmoticon(newLineToBreak(post.title)),
                  keyword,
                ),
              }}
              title={post.title}
              target='_blank'
              onClick={() => {
                handlePostActionLog(
                  {type: 'tt'},
                  index,
                  post.titleNo,
                  post.stationUserId,
                  post.userId,
                );
              }}></a>
            <p
              className='desc'
              dangerouslySetInnerHTML={{
                __html: highlightKeyword(
                  decodeEmoticon(newLineToBreak(post.content)),
                  keyword,
                ),
              }}></p>
          </div>
          <div className='detail_box'>
            <span className='view'>{convertViewCnt(post.viewCnt)}</span>
            <span className='date'>{convertRegDate(post.regDate)}</span>
          </div>
        </div>
        {post.thumbnail !== '' && post.photoCnt > 0 && (
          <a
            className='post_thumb'
            href={`${post.url}`}
            target='_blank'
            onClick={() => {
              handlePostActionLog(
                {type: 'sn'},
                index,
                post.titleNo,
                post.stationUserId,
                post.userId,
              );
            }}>
            <SoopUiImg src={post.thumbnail} type='thumbs' />
            <span className='num'>{post.photoCnt}</span>
          </a>
        )}
      </li>
    ));
  };

  return (
    <>
      {renderPostSectionHeader()}

      {renderPostSectionNoResult()}

      {!stopWord && postList.length > 0 && (
        <>
          <ul className='post_area'>
            {renderPostListItems()}
            {isPostPage && <div ref={lastPositionRef} />}
          </ul>

          {isTotalPage && (
            <ShowMore
              active={postList.length > 6}
              name={'더보기'}
              onHanlder={handleShowMore}
            />
          )}
        </>
      )}

      {/* 로딩 */}
      {isPostPage && postLoading && <Loading />}
    </>
  );
};

export default PostGroup;
