import classNames from 'classnames';
import {SoopUiSwiper} from '@/components/ui';
import {StreamerListItem} from '@/components/search/common';

/**
 * ContentListRenderer 컴포넌트 ( 관련 스트리머, 공식 채널 리스트 렌더컴포넌트 )
 * @param {Object} props - 컴포넌트의 props
 * @param {Object} list - 렌더링될 데이터 리스트
 * @param {Function} resetSlideOnUpdate 스와이퍼 초기화 여부
 * @param {Function} handleCustomMovingButtonClicked SWIPER관련 > 버튼이 눌렸을때 슬라이드 이동 '직전' 실행할 동작 정의 ( 관련 스트리머에서만 동작 )
 * @returns ContentListRenderer 컴포넌트
 */
const ContentListRenderer = ({
  list = [],
  logCallback = () => {},
  resetSlideOnUpdate = false,
  handleCustomMovingButtonClicked = () => {},
}) => {
  return (
    <div className='strm_area'>
      <SoopUiSwiper
        swiperTag='ul'
        maxSlidesPerView={8}
        options={{
          loop: false,
        }}
        resetSlideOnUpdate={resetSlideOnUpdate}
        handleCustomMovingButtonClicked={handleCustomMovingButtonClicked}>
        {list.length > 0 &&
          list.map((item, index) => (
            <li
              className={classNames('strm_box', {live: item.liveBroadNo})}
              key={`${item.userId}`}>
              <StreamerListItem
                {...item}
                logCallback={(params) => {
                  const logParams = {
                    ...params,
                    contentsType: item.liveBroadNo ? 'live' : 'bj',
                    contentsValue: item.liveBroadNo
                      ? item.liveBroadNo
                      : item.userId,
                    bno: item.liveBroadNo ? item.liveBroadNo : '',
                  };
                  logCallback(logParams, index, item.userId);
                }}
                searchArea='ctsp'
              />
            </li>
          ))}
      </SoopUiSwiper>
    </div>
  );
};

export default ContentListRenderer;
