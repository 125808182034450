import MyHistoryStoryList from '@/components/my/history/story/MyHistoryStoryList';
import '/public/static/css/module/my_story.scss';

const MyHistoryStory = () => {
  return (
    <>
      {/* 내 글/댓글 리스트 */}
      <MyHistoryStoryList />
    </>
  );
};

export default MyHistoryStory;
