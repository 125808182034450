'use client';

import {useImperativeHandle, forwardRef} from 'react';

const Message = forwardRef(function Message(
  {
    data,
    bodyRef,
    hasCloseButtonOnly,
    isConfirm,
    handleClose,
    handlePositiveClose,
  },
  ref,
) {
  useImperativeHandle(bodyRef, () => {
    return {
      getReturnValue: (isPositive) => {
        return isPositive;
      },
    };
  }, []);
  const renderButtonArea = () => {
    if (isConfirm) {
      return (
        <div className='btn_area'>
          {!hasCloseButtonOnly && (
            <button type='button' onClick={handleClose}>
              취소
            </button>
          )}
          <button
            type='button'
            className='tertiary'
            onClick={handlePositiveClose}>
            확인
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {data.title && (
        <div className='title'>
          <strong>{data.title}</strong>
        </div>
      )}
      <div className='conts'>
        {data.subTitle && <strong className='subtitle'>{data.subTitle}</strong>}
        <p style={{whiteSpace: 'pre-line'}}>{data.message}</p>
      </div>
      {renderButtonArea()}
      {data.title && (
        <button type='button' className='btn_close' onClick={handleClose}>
          닫기
        </button>
      )}
    </>
  );
});
export default Message;
