import globalDomains from '@/utils/global-domains';

/**
 * id 작성하실때 body 내부랑 겹치지 않게 하기 위해 head 시작 해주시길 바랍니다.
 * @type {[{tag: string, id: string, attribute: [{value: string, key: string}]}]}
 */
export const DYNAMIC_TAG_LIST = [
  // check-new-ad, ad-parameter SoopNavigation에서 로드해서 호출하기까지 시간이 너무 오래 걸리는 관계로 본체에서도 중복하여 로드
  {
    tag: 'script',
    id: 'check-new-ad',
    isLoad: false,
    attribute: [
      {key: 'type', value: 'text/javascript'},
      {
        key: 'src',
        value: `${globalDomains.STATIC_AFREECATV}/asset/service/ad/check_new_ad.js`,
      },
    ],
  },
  {
    tag: 'script',
    id: 'ad-parameter',
    isLoad: false,
    attribute: [
      {key: 'type', value: 'text/javascript'},
      {
        key: 'src',
        value: `${globalDomains.STATIC_AFREECATV}/asset/service/da/AdParameter.js`,
      },
    ],
  },
];
