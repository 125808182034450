import {useState, useRef, useMemo, useCallback, useEffect} from 'react';
import Hls from 'hls.js';

import useCreateInstance from '@/components/my/common/hooks/useCreateInstance';
import useConvertTime from '@/components/main/common/hooks/useConvertTime';

import {Motion, spring} from 'react-motion';
import throwToast from '@/utils/ui/toast';
import MyPlusApi from '@/api/main/my-plus';
import {goLogin, isLogin} from '@/utils/main';
import {sendVodPlayLog} from '@/utils/main/my/log';

/**
 * @typedef {Object} hlsConfig
 * BUFFER_NUDGE_ON_STALL - HLS.js library에서 사용하는 이벤트로 비디오 재생이 버퍼링으로 인해 정지(stall)했을 때 발생
 * manifest file - streaming media의 metadata를 포함하는 파일(미디어 품질 버전, 비디오 및 오디오 코덱, 비트레이트, 해상도 등)
 * @property {Boolean} debug - 디버그 로그 출력 여부(내부적으로 발생하는 이벤트와 오류, 경고 등에 대한 상세 로그를 콘솔에 출력)
 * @property {Number} nudgeOffset - BUFFER_NUDGE_ON_STALL이벤트가 발생했을 때, 현재 재생 위치를 약간 이동하여 재생을 계속할 수 있도록 함(단위: 초)
 * @property {Number} highBufferWatchdogPeriod - BUFFER_NUDGE_ON_STALL이벤트가 발생했을 때, 얼마나 오랫동안 기다릴지 결정(단위: 초)
 * @property {Number} liveSyncDurationCount - 라이브 동기화 지연 시간(단위: 초)
 * @property {Number} manifestLoadingTimeOut - manifest file loading timeout 시간(단위: ms)
 * @property {Number} fragLoadingMaxRetry - 최대 재시도 횟수
 * @property {Number} manifestLoadingMaxRetry - 최대 재시도 횟수
 * @property {Number} levelLoadingMaxRetry - 최대 재시도 횟수
 * @property {Number} maxBufferLength - 버퍼 최대 길이(default 600s)
 * @property {Number} maxMaxBufferLength - maxBufferLength의 상한선(모종의 이유로 maxBufferLength값이 큰 값으로 설정되는 것에 대한 방어로직)(default 600s)
 * @property {Boolean} stretchShortVideoTrack - 짧은 비디오 트랙을 늘릴지 여부
 * @property {Boolean} autoStartLoad - Hls.Events.MANIFEST_PARSED 이벤트가 트리거 된 후 시작
 */

// HLS.js 라이브러리 설정 옵션
const hlsConfig = {
  debug: false,
  nudgeOffset: 0.3, //BUFFER_NUDGE_ON_STALL 발생했을 때 currentTime을 더 많이 움직여 더빨리 찾도록 0.1(default) -> 0.3으로 늘림
  highBufferWatchdogPeriod: 1, //BUFFER_NUDGE_ON_STALL 발생했을 때 너무 오래걸려서 3s(default) -> 1s로 줄임
  liveSyncDurationCount: 1, // default: 3
  manifestLoadingTimeOut: 10000,
  fragLoadingMaxRetry: 2, //재시도 횟수 여부
  manifestLoadingMaxRetry: 2, //재시도 횟수 여부
  levelLoadingMaxRetry: 2, //재시도 횟수 여부
  maxBufferLength: 3, //(default 600s)
  maxMaxBufferLength: 3, //(default 600s)
  stretchShortVideoTrack: true,
  autoStartLoad: true, //Hls.Events.MANIFEST_PARSED 이벤트가 트리거 된 후 시작
};

const MAX_PLAY_TIME = 60; //최대 재생시간(초)
const STATUS = {
  PLAY: 'play',
  PAUSE: 'pause',
  ENDED: 'ended',
};

/**
 * TODO: playable에 대한 처리 해야 함
 * @description 비디오 정보와 렌더링 여부에 따라 비디오 플레이어를 재생.
 * @param {Object} videoInfo - 비디오 정보
 * @param {Boolean} isRendered - 렌더링 여부
 * @param {Boolean} playable - 재생 가능 여부
 * @param {Function} onError - 에러 발생시 호출
 */
const Player = ({
  videoInfo = {},
  isRendered = false,
  playable = false,
  onError = () => {},
}) => {
  /**
   *  state
   */
  const [error, setError] = useState(false);
  const [progressWith, setProgressWith] = useState(0);
  const [status, setStatus] = useState(STATUS.PAUSE);
  const [startTime, setStartTime] = useState(0);
  const [exceptThumbStatus, setExceptThumbStatus] = useState(true);

  const videoRef = useRef(null);
  const progressRef = useRef(null);

  const hls = useCreateInstance(() => {
    const _hls = new Hls(hlsConfig);
    _hls.on(Hls.Events.MANIFEST_PARSED, () => {
      videoRef.current.play();
    });
    _hls.on(Hls.Events.ERROR, (type, error) => {
      if (error.fatal) {
        setError(true);
        onError(true);
      }
    });

    return _hls;
  });

  const {convertSecondToTime} = useConvertTime();
  const {
    authNo = 0,
    isPpv = false,
    uccIsAbroadBlocking = false,
    stationNo = 0,
    titleNo = 0,
    ucc,
    userId = '',
  } = videoInfo || {};

  const {
    isAdult = false,
    category = '',
    fileType = '',
    hls: uccHls = '',
    flag = 'SUCCEED',
    isPassword = false,
    totalFileDuration = 0,
    uccType = '',
  } = ucc || {};

  const isBlocking = useMemo(() => {
    if (isPpv || isAdult || isPassword === true || fileType === 'SMR') {
      return true;
    } else {
      return false;
    }
  }, [videoInfo]);

  useEffect(() => {
    return () => {
      hls.destroy();
    };
  }, [hls]);

  /**
   * 플레이시작 playable && isRendered
   */
  useEffect(() => {
    if (exceptThumbStatus === false) {
      if (playable && isRendered && !isBlocking && videoInfo.ucc?.hls) {
        if (videoRef.current.duration) {
          videoRef.current.play();
        } else {
          hls.loadSource(uccHls);
          hls.attachMedia(videoRef.current);
        }
      } else {
        if (videoRef.current.played.length) {
          videoRef.current.pause();
        }
      }
    }
  }, [exceptThumbStatus, hls, isBlocking, isRendered, uccHls, playable]);

  /**
   * 랜덤 시작시간
   */
  const handleLoadedMetadata = useCallback(() => {
    let startTime = 0;

    if (!videoRef.current) {
      return;
    }

    if (videoRef.current.duration > MAX_PLAY_TIME * 2) {
      startTime = Math.floor(
        Math.random() * (videoRef.current.duration - MAX_PLAY_TIME),
      );
      setStartTime(startTime);
    }
    videoRef.current.currentTime = startTime;

    //로그 호출
    // sendVodLog(item, startTime);
    // 조회수 올리기 api
    // dispatch(hitFeed(item));
    sendVodPlayLog({
      stationNo,
      titleNo,
      bjId: userId,
      category,
      part: fileType,
      isAutoplay: true,
      path1: 'my',
      path2: 'feed',
      videoStart: startTime,
      isPreview: true,
    });
  }, [videoInfo]);
  /**
   * 현재 재생중인 시간 표시
   */
  const displayTime = useCallback(() => {
    return `${convertSecondToTime(videoRef.current?.currentTime || 0)}`;
  }, []);

  /**
   * 시간 업데이트
   */
  const handleTimeUpdate = useCallback(
    (event) => {
      if (videoRef.current) {
        const playTime = Math.min(
          videoRef.current.currentTime,
          startTime + MAX_PLAY_TIME,
        );
        const duration =
          Math.min(videoRef.current.duration, MAX_PLAY_TIME) || 1;
        setProgressWith(
          ((playTime - startTime) / duration) *
            progressRef.current?.clientWidth || 0,
        );

        //MAX_PLAY_TIME초 이상이면 자동중지
        if (videoRef.current.currentTime >= startTime + MAX_PLAY_TIME) {
          videoRef.current.pause();
        }
      } else {
        setProgressWith(0);
      }
    },
    [startTime],
  );

  /**
   * 플레이 상태변경
   */
  const handleStatusChange = useCallback((event) => {
    setStatus(event.type);
  }, []);

  /**
   * 플레이버튼 클릭
   */
  const handlePlayButtonClick = useCallback(
    (event) => {
      if (!isBlocking && !error) {
        if (!videoRef.current) {
          return;
        }

        if (status === STATUS.PLAY) {
          videoRef.current.pause();
        } else {
          //재생시간이 지났을경우 처음으로 이동
          if (videoRef.current.currentTime >= startTime + MAX_PLAY_TIME) {
            videoRef.current.currentTime = startTime;
          }
          videoRef.current.play();
          // 로그
          sendVodPlayLog({
            stationNo,
            titleNo,
            bjId: userId,
            category,
            part: fileType,
            isAutoplay: true,
            path1: 'my',
            path2: 'feed',
            isPreview: true,
          });
        }
        event.preventDefault();
      }
    },
    [error, isBlocking, videoInfo, startTime, status],
  );

  /**
   * 영상클릭
   * (영상 재생 일시정지후  a 링크이동)
   */
  const handleVideoClick = useCallback(() => {
    if (status === STATUS.PLAY) {
      videoRef.current?.pause();
    }
  }, [status]);

  /**
   * 썸네일
   */
  const getThumbClassName = useCallback(
    ({authNo = 101, flag = 'SUCCESS', isAdult = false}) => {
      let thumbClass = '';
      const isPassword = authNo === 102 || flag === 'BJ_HIDDEN';

      if (isAdult && isPassword) {
        thumbClass = 'thumb-lock_adult';
      } else if (isPassword) {
        thumbClass = 'thumb-lock';
      } else if (isAdult) {
        thumbClass = 'thumb-adult';
      } else {
        setExceptThumbStatus(false);
      }

      return thumbClass;
    },
    [],
  );

  const handleError = (e) => {
    console.log(videoInfo.titleNo, e, e.nativeEvent);
    onError(true);
  };

  // 나중에 보기
  const setLaterView = async (type, broadcastId) => {
    if (isLogin()) {
      try {
        const response = await MyPlusApi.setLaterView(type, broadcastId);
        if (response) {
          throwToast(response.message);
        }
      } catch (error) {
        const {message} = error.response?.data;
        throwToast(message);
      }
    } else {
      goLogin();
    }
  };

  /**
   * 비디오가 뷰 포트 내에 들어왔다면 맨 첨으로 이동하고 재시작 시킴
   */
  const videoObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 비디오가 뷰포트에 들어올 때 재생이 끝까지 다 되었다면 처음으로 이동해서 재시작
          if (
            videoRef.current &&
            videoRef.current?.currentTime >= startTime + MAX_PLAY_TIME
          ) {
            handleLoadedMetadata();
            handleTimeUpdate();
            videoRef.current.play(); // 재생 시작
          }
        }
      });
    },
    {
      threshold: 1, // 비디오의 50% 이상이 뷰포트에 들어왔을 때 트리거
    },
  );

  useEffect(() => {
    if (isPpv || uccIsAbroadBlocking || error || fileType === 'SMR') {
      onError(true);
    }

    if (videoRef.current) {
      videoObserver.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        videoObserver.unobserve(videoRef.current);
      }
    };
  }, []);

  return (
    <>
      {!exceptThumbStatus ? (
        <video
          key={videoInfo.indexRegDate}
          ref={videoRef}
          className='video'
          id='video_p'
          autoPlay={true}
          muted={true}
          playsInline={true}
          onPlay={handleStatusChange}
          onPause={handleStatusChange}
          onEnded={handleStatusChange}
          onLoadedMetadata={handleLoadedMetadata}
          onTimeUpdate={handleTimeUpdate}
          onError={handleError}
          onWaiting={() => console.log('onWaiting')}
          onPlaying={() => console.log('onPlaying')}
          controls={false}
          preload='metadata'
          onClick={handleVideoClick}
          poster={videoInfo.ucc ? videoInfo.ucc.thumb : ''}
        />
      ) : (
        <div className='thumb_wrap'>
          <span className={getThumbClassName({authNo, flag, isAdult})}></span>
        </div>
      )}
      {['REVIEW', 'HIGHLIGHT'].includes(fileType) && (
        <em
          className={`ic-${fileType.toLowerCase()}`}>{`${fileType === 'REVIEW' ? '다시보기' : '하이라이트'} `}</em>
      )}
      <span className='time'>
        {!isBlocking && `${displayTime()} / `}
        {`${convertSecondToTime(totalFileDuration / 1000)}`}
      </span>

      {isBlocking === false && (
        <div className='ctrl'>
          <Motion style={{width: spring(progressWith)}}>
            {(style) => (
              <div className='progress' ref={progressRef}>
                <div className='watched' style={style}></div>
              </div>
            )}
          </Motion>
        </div>
      )}
      {status === STATUS.PAUSE && (
        <button
          type='button'
          className='btn-play'
          onClick={handlePlayButtonClick}>
          재생
        </button>
      )}
      {isPpv && (
        <p className='text'>상세페이지를 통해 재생이 가능한 VOD입니다.</p>
      )}
      {uccIsAbroadBlocking && (
        <p className='text'>해외에서 재생이 불가한 VOD입니다.</p>
      )}
      {error && (
        <p className='text'>
          미리보기가 원활하지 않습니다.
          <br />
          상세페이지로 이동 후 재생해주시기 바랍니다.
        </p>
      )}
      {fileType === 'SMR' && (
        <p className='text'>
          TV클립은 미리보기가 지원되지 않습니다.
          <br />
          상세페이지로 이동 후 재생해주시기 바랍니다.
        </p>
      )}
      <button
        type='button'
        className='btn-later'
        tip='나중에 보기'
        onClick={(event) => {
          event.preventDefault();
          setLaterView('VOD', titleNo);
          return;
        }}>
        <span>나중에 보기</span>
      </button>
      {uccType === '22' && (
        <button type='button' className='btn-360deg'>
          <span>360도</span>
        </button>
      )}
    </>
  );
};

export default Player;
