// import SoopNavigation from '@front-end/soop-navigation/dist/ui-navigation-esm';
/*
 * SoopNavigation을 글로벌로 연동하기 위한 래퍼 객체
 * */
const GlobalNavigation = () => {
  let navigationCore = null;

  const initializeNavigation = ($gnb, $snb, $fnb) => {
    if (typeof window !== 'undefined') {
      // 빌드 시점에 soop-navigation이 참조하는 window 객체에 접근해서 진상 부리며 빌드를 안해주기 때문에
      // 아래와 같이 처리
      const SoopNavigation =
        require('@front-end/soop-navigation/dist/ui-navigation-esm').default;
      //require('/Users/hslee/WebstormProjects/soop-navigation/dist/ui-navigation-esm.js').default;
      navigationCore = SoopNavigation($gnb, $snb, $fnb, {
        shouldShowHamburgerMenu: true,
        shouldShowAdvertise: true,
        ad: {
          loadsCustomizable: false,
          visibility: {
            hasCollaboAd: true,
            hasSearchBarAd: true,
            hasSnbMenuAd: true,
            hasKeywordAd: true,
          },
        },
      });
    }
  };
  return {
    init($gnb, $snb, $fnb) {
      /*if (typeof window !== undefined) {
        navigationCore = SoopNavigation($gnb, $snb, $fnb, {
          shouldShowHamburgerMenu: true,
        });
      }*/
      initializeNavigation($gnb, $snb, $fnb);
    },
    refresh() {
      if (navigationCore) {
        navigationCore.reload();
      }
    },
    handleAuthorized(callback) {
      if (navigationCore) {
        navigationCore.handleAuthorized((userInfo) => {
          callback(userInfo);
        });
      }
    },
    handleInternalRouteChange(callback) {
      if (navigationCore) {
        navigationCore.handleInternalRouteChange((path) => {
          callback(path);
        });
      }
    },
    handleNewAdChecked(callback) {
      if (navigationCore) {
        navigationCore.handleNewAdChecked((adInfo) => {
          callback(adInfo);
        });
      }
    },
    handleThemeChanged(callback) {
      if (navigationCore) {
        navigationCore.handleThemeChanged((themeInfo) => {
          callback(themeInfo);
        });
      }
    },
  };
};
const globalNavigation = GlobalNavigation();

export default globalNavigation;
