import MyFavoriteApi from '@/api/my/favorite';
import {SectionHeader} from '@/components/main/common';
import MyFavoriteCatch from './Catch';
import {useEffect, useState} from 'react';

const MAX_SIZE = 30;

/**
 * 즐겨찾기 캐치 컴포넌트
 * @desc API 로부터 캐치를 가져오고 필드명을 맞춰서 MyCatchGroup 컴포넌트에 전달한다.
 * @returns {JSX.Element} 캐치
 */
const CatchWrapper = ({groupId}) => {
  const [catches, setCatches] = useState([]);

  const getCatches = async () => {
    try {
      const catches = await MyFavoriteApi.getCatch(groupId);
      setCatches(catches.slice(0, MAX_SIZE));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCatches();
  }, [groupId]);

  const formattedCatches = catches?.map((catchItem) => {
    const {
      copyrightUserId,
      thumb,
      resolutionType,
      copyrightUserNick,
      broadDate,
      readCnt,
      titleNo,
      titleName,
      ...rest
    } = catchItem;

    return {
      ...rest,
      catchId: titleNo,
      thumbnailSrc: thumb,
      isHorizontal: resolutionType === 'horizontal',
      userNickname: copyrightUserNick,
      broadcastDate: broadDate,
      userId: copyrightUserId,
      viewCount: readCnt,
      title: titleName,
    };
  });

  return (
    formattedCatches?.length > 0 && (
      <>
        <SectionHeader title='Catch' />
        <div className='catch_area'>
          <MyFavoriteCatch catches={formattedCatches} />
        </div>
      </>
    )
  );
};

export default CatchWrapper;
