'use client';
import SearchApi from '@/api/search/search';
import {convertViewCnt} from '@/utils/main';
import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSearchClickLog} from '@/components/search/common/hooks/useSearchClickLog';
import {useSearchGlobalStatus} from '@/components/search/SearchProvider';
import useUrlParameter from '@/components/search/common/hooks/useUrlParameter';

/**
 * TagSearchTagTrend 컴포넌트 ( 태그트렌드 )
 * @param {Object} props 컴포넌트의 props
 * @param {Object[]} vod vod 데이터
 * @param {String} stopWord 금칙어
 * @returns VodGroup 컴포넌트
 */
const TagSearchTagTrend = () => {
  const {sendRelatedSearchTermsSectionClickLog} = useSearchClickLog();
  const {sessionKey} = useSearchGlobalStatus();
  const {getParams} = useUrlParameter();
  const {szSearchType: searchType = '', hashtype, tagname} = getParams();

  const [tagTrend, setTagTrend] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTagTrend = async () => {
      try {
        const params = {
          m: 'hashSearch',
          service: 'rank',
        };
        const response = await SearchApi.getTagTrend(params);
        if (response && response.rank && Array.isArray(response.rank)) {
          setTagTrend(response.rank);
        } else {
          console.warn('Invalid response format or missing rank data');
          setTagTrend([]);
        }
      } catch (error) {
        setTagTrend([]);
      }
    };
    fetchTagTrend();
  }, []);

  // SCKACT 로그
  const handleLogCallback = useCallback(
    (index) => {
      sendRelatedSearchTermsSectionClickLog({
        tab:
          hashtype === 'live' || (hashtype === undefined && tagname)
            ? 'live'
            : 'vod',
        acttype: 'trend',
        actCode: 'trend',
        location: 'hash',
        listIdx: index,
        sessionKey: sessionKey,
      });
    },
    [sessionKey, searchType, hashtype, tagname],
  );

  const handleClickTagTrend = (option, index) => {
    if (option) {
      const query = {
        hashtype: 'live',
        hash: 'hashtag',
        tagname: option.key,
        stype: 'hash',
        acttype: 'trend',
        location: 'hash',
      };

      // sck2 로그 관련 태그 검색 페이지 뒤로가기 처리
      // 목적: 태그 검색 페이지에서 뒤로가기 시 sck2로그에 올바른 히스토리 상태 유지
      // 1. 현재 페이지가 '/search'로 시작하는 경우에만 실행
      if (location.pathname.startsWith('/search')) {
        // 2. 현재 URL의 파라미터 추출
        const currentParams = new URLSearchParams(location.search);

        // 3. URL 파라미터 수정
        // - 'location' 파라미터가 있으면 값을 'hash'로 설정
        // - 없으면 'szLocation' 파라미터를 'hash'로 설정
        if (currentParams.has('location')) {
          currentParams.set('location', 'hash');
        } else {
          currentParams.set('szLocation', 'hash');
        }

        // 4. 수정된 URL을 history에 push
        // replace: true 옵션으로 현재 history 항목을 대체
        const desiredPath = `${location.pathname}?${currentParams.toString()}`;
        navigate(desiredPath, {replace: true});
      }
      const queryString = new URLSearchParams(query).toString();
      const newURL = `/search?${queryString}`;
      navigate(newURL);
    }
  };

  return (
    <>
      {tagTrend.length > 0 && (
        <div className='search_aside_floating'>
          <div className='floating_box'>
            <h2 className='title'>🔥 태그 트랜드</h2>
            <ul>
              {tagTrend.map((option, index) => (
                <li key={`aside_floating_${option.key}_${index}`}>
                  <button
                    type='button'
                    onClick={() => {
                      handleLogCallback(index);
                      handleClickTagTrend(option, index);
                    }}>
                    <i>{index + 1}</i>
                    <div className='info'>
                      <span>{option.key}</span>
                      <span className='view'>
                        {convertViewCnt(option.count)}번 조회
                      </span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default TagSearchTagTrend;
