'use client';
import '/public/static/css/module/feed.scss';
import MyFavoriteApi from '@/api/my/favorite';
import {useOutsideClick} from '@/components/main/common/hooks/useOutsideClick';
import globalDomains from '@/utils/global-domains';
import {getLocalStorage, getUserCookie, goLogin, isLogin} from '@/utils/main';
import {getSearchInflowTab, getUrlLocation} from '@/utils/main/search';
import throwToast from '@/utils/ui/toast';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {FloatingPortal} from '../main/common';
import {sendClickLog} from '@/utils/main/my-plus/log';
import {useLocation, useNavigate} from 'react-router-dom';
import {ROUTE} from '@/constants/main/route';

/**
 * props.handleMenuButtonClicked
 * @typedef {Object} EventData
 * @property {'goBJ' | 'clickNote' | 'deleteFavorite' } eventName - 발생한 이벤트 이름
 * @property {Object} [data] - 이벤트와 관련된 데이터
 * @property {number} [data.id] - 데이터의 ID
 */

/**
 *
 * @param {Object} props - SoopUiProfileIconMenu props
 * @param {string} props.streamerId -  스트리머 아이디
 * @param {string} props.streamerNick - 스트리머 닉네임
 * @param {string} props.handleMenuButtonClicked - 메뉴가 클릭 되었을때 콜백
 * @param {function} props.searchArea - 페이지 내 어떤 영역에서 검색했는지 ( SCK2 로그 관련 )
 * @public
 * open() - SoopUiProfileIconMenu 노출
 * close() - SoopUiProfileIconMenu 비노출
 */

const SoopUiProfileIconMenu = forwardRef(function SoopUiProfileIconMenu(
  {streamerId, streamerNick, handleMenuButtonClicked, searchArea = 'total'},
  ref,
) {
  const navigate = useNavigate();

  const [isOpen, setOpen] = useState(false);
  const [isHiddenOnResize, setHiddenOnResize] = useState(false); // 리사이즈 시 안보였다가 완료되면 나타남 (과한 디테일인데)

  const soopUiProfileIconMenuRef = useRef(null); // input select를 연상시키는 이름이라 변경함 selectBoxWrapperRef -> soopUiProfileIconMenuRef

  //TODO : 처음열었을때 즐겨찾기 상태인지 체크하는 api 값이 들어가야함
  const [isAlreadyFavorited, setAlreadyFavorited] = useState(false); // isFavoriteOn -> isAlreadyFavorited 이미 즐찾 추가됨
  const [position, setPosition] = useState({top: 0, left: 0});
  // const [isHidden, setIsHidden] = useState(false);
  const userId = getUserCookie('uid');
  const location = useLocation();
  const selectedMode = getLocalStorage('selectedMode', undefined);
  const clickLocation =
    selectedMode === 'prefer' && isLogin() ? 'myplus_list' : 'popular_list';
  const isMine = streamerId === userId;

  // check보다는 calc가 명확한거 같아 이름 변경
  const calcMenuPosition = () => {
    if (soopUiProfileIconMenuRef.current) {
      const MENU_WIDTH = 180;
      const MENU_HEIGHT = 70;
      const {top, left, bottom} =
        soopUiProfileIconMenuRef.current.getBoundingClientRect();
      let newTop = top + window.scrollY;
      let newLeft = left + window.scrollX;

      // 메뉴가 우측에서 열릴 때 짤릴 케이스가 발생하면 좌측으로 조정
      if (newLeft + MENU_WIDTH > window.innerWidth) {
        newLeft = window.innerWidth - MENU_WIDTH - 10;
      }

      // 메뉴가 아래로 열릴 때 짤릴 케이스가 발생하면 위로 조정
      if (bottom + MENU_HEIGHT > window.innerHeight) {
        newTop = top + window.scrollY - MENU_HEIGHT;
      } else {
        newTop = bottom + window.scrollY;
      }

      setPosition({top: newTop, left: newLeft});
    }
  };

  /**
   * 채널 가기 버튼 클릭 시
   */
  const handleStreamerClicked = () => {
    handleMenuButtonClicked &&
      handleMenuButtonClicked({
        eventName: 'goBJ',
        data: {
          id: streamerId,
        },
      });
    if (ROUTE.INDEX === location.pathname) {
      sendClickLog('ict', {
        button_type: 'station',
        location: clickLocation,
      });
    }
    setOpen(false);
    window.open(`${globalDomains.BJ_AFREECATV}/${streamerId}`, '_blank');
  };

  /**
   * 쪽지 보내기 버튼 클릭 시
   */
  const handleNoteClicked = () => {
    handleMenuButtonClicked &&
      handleMenuButtonClicked({
        eventName: 'clickNote',
        data: {
          id: streamerId,
        },
      });
    if (ROUTE.INDEX === location.pathname) {
      sendClickLog('ict', {
        button_type: 'note',
        location: clickLocation,
      });
    }
    setOpen(false);
    if (!isLogin()) {
      goLogin();
      return;
    } else {
      window.open(
        `${globalDomains.NOTE_AFREECATV}/app/index.php?page=write&targetUserId=${streamerId}`,
        '_blank',
        'top=10, left=10, width=500, height=600',
      );
    }
  };


  /**
   * 검색 버튼 클릭 시
   */

  //TODO: szLocation 로그 기획 확정 후 추후 수정 예정
  const handleTotalSearchClicked = () => {
    const urlLocation = getUrlLocation(location.pathname);
    const inflowTab = getSearchInflowTab(location.pathname);

    if (ROUTE.INDEX === location.pathname) {
      sendClickLog('ict', {
        button_type: 'nick_search',
        location: clickLocation,
      });
    }
    setOpen(false);

    const searchParams = new URLSearchParams({
      szLocation: urlLocation,
      szSearchType: 'total',
      szStype: 'ni',
      szKeyword: streamerNick,
      szVideoFileType: 'ALL',
      rs: '1',
      acttype: searchArea,
    });

    if (inflowTab && inflowTab.trim() !== '') {
      searchParams.append('inflow_tab', inflowTab);
    }

    navigate(`/search?${searchParams.toString()}`);
  };

  const handleToggleFavorite = async (isFavoriteOn) => {
    // option -> toggle 조금 더 구체적인 용어로 변경

    if (ROUTE.INDEX === location.pathname) {
      sendClickLog('ict', {
        button_type: 'favorite',
        location: clickLocation,
      });
    }
    if (!isLogin()) {
      goLogin();
    } else {
      if (isFavoriteOn) {
        await MyFavoriteApi.deleteFavorite(streamerId);
        setAlreadyFavorited(!isAlreadyFavorited);
        throwToast('즐겨찾기에서 삭제됐습니다.');
      } else {
        await MyFavoriteApi.addFavorite(streamerId);
        setAlreadyFavorited(!isAlreadyFavorited);
        throwToast('즐겨찾기에 추가됐습니다.');
      }

      handleMenuButtonClicked &&
        handleMenuButtonClicked({
          eventName: 'deleteFavorite',
          data: {
            id: streamerId,
          },
        });

      // 240719 GT : 기획서에 없던 내용인데 임의로 navi를 새로 고침 시켜서 즐찾 목록이 즉시 갱신 되도록 하였다. 이게 멋있으니까
      // 하지만 스테이지 환경에서부터 즐겨찾기 목록이 캐시에 의해 관리되고 있어 갱신이 안되는 관계로 괜히 맨티스 이슈만 생겼다.
      // 그래서 해당 동작을 중지한다. 추후 개선될 여지는 있음
      // globalNavigation.refresh();
    }
  };

  async function fetchIsFavorite() {
    // 본인 일때, 비로그인일때 api 호출 안되게 조건 추가
    if (isMine || !isLogin()) {
      return;
    }
    try {
      const isFavorite = await MyFavoriteApi.getIsFavorite(streamerId);
      setAlreadyFavorited(isFavorite);
    } catch (error) {
      console.error('Error occurred:', error);
    }
  }

  useEffect(() => {
    if (isOpen) {
      calcMenuPosition();
      fetchIsFavorite();
    }
  }, [isOpen]);

  useEffect(() => {
    let resizeTimeout;
    const handleResize = () => {
      setOpen(false);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      // clearTimeout(resizeTimeout);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /**
   * 현재 dom 외 외부 클릭 close
   */
  useOutsideClick(soopUiProfileIconMenuRef, () => {
    setOpen(false);
  });

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
  }));

  return (
    <div ref={soopUiProfileIconMenuRef}>
      {isOpen && (
        <FloatingPortal target='soop_wrap'>
          <div
            className='icon-menu'
            style={{
              top: position.top,
              left: position.left,
            }}>
            <button
              type='button'
              className='community'
              tip='채널'
              onClick={handleStreamerClicked}>
              <span>채널 가기</span>
            </button>
            {!isMine && (
              <>
                <button
                  type='button'
                  className='send_memo'
                  tip='쪽지 보내기'
                  onClick={handleNoteClicked}>
                  <span>쪽지 보내기</span>
                </button>
                <button
                  type='button'
                  className={`favor ${isAlreadyFavorited ? 'on' : ''}`}
                  tip={`${isAlreadyFavorited ? '즐겨찾기 삭제' : '즐겨찾기 추가'}`}
                  onClick={() => handleToggleFavorite(isAlreadyFavorited)}>
                  <span>즐겨찾기</span>
                </button>
              </>
            )}
            <button
              type='button'
              className='search'
              tip='검색'
              onClick={handleTotalSearchClicked}>
              <span>닉네임 검색</span>
            </button>
          </div>
        </FloatingPortal>
      )}
    </div>
  );
});

export default SoopUiProfileIconMenu;
