'use client';

export default function useConvertTime() {
  const convertSecondToTime = (sec) => {
    let hours = Math.floor(sec / 3600);
    let minutes = Math.floor((sec - hours * 3600) / 60);
    let seconds = Math.floor(sec) - hours * 3600 - minutes * 60;
    let time = '';

    if (hours !== 0) {
      time = hours + ':';
    }

    time += hours > 0 ? String(minutes).padStart(2, '0') : String(minutes);
    time += ':';
    time += String(seconds).padStart(2, '0');
    return time;
  };

  return {convertSecondToTime};
}
