'use client';

import MyFavoriteApi from '@/api/my/favorite';
import {forwardRef, useState, useEffect} from 'react';
import {throwDialog} from '@/utils/ui/dialog';
import throwToast from '@/utils/ui/toast';
import {isEmpty} from 'lodash';
import {sendCreateFavoriteGroupClickLog} from '@/utils/main/my/log';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {ROUTE} from '@/constants/main/route';
import useMyStore from '@/stores/my';

const MyFavoriteGroup = forwardRef(function MyLayer({data, handleClose}, ref) {
  const [favoriteGroups, setFavoriteGroups] = useState([]);
  const [checkedGroups, setCheckedGroups] = useState([]);
  const {getFavoriteGroups} = useMyStore();

  const fetchFavoriteGroups = async () => {
    try {
      const favoriteGroups = await MyFavoriteApi.getFavoriteGroups(
        data.streamerId,
      );
      setFavoriteGroups(favoriteGroups);
      const joinedGroups = favoriteGroups.filter((group) => group.already);
      setCheckedGroups(joinedGroups.map((group) => group.idx));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchFavoriteGroups();
  }, []);

  const [searchParams] = useSearchParams();
  const groupId = searchParams.get('groupId');
  const navigate = useNavigate();

  const handleGroupAdd = async () => {
    if (checkedGroups.length === 0) {
      throwToast('추가할 그룹이 없습니다.');
      return;
    }

    try {
      await MyFavoriteApi.putFavoriteGroup({
        favoriteId: data.streamerId,
        groupIds: checkedGroups,
      });
      getFavoriteGroups();

      if (groupId) {
        const {favorites} = await MyFavoriteApi.getFavorites(groupId);

        if (favorites.length === 0) {
          handleClose();
          navigate(ROUTE.MY.FAVORITE);
        }
      }

      throwToast('즐겨찾기 그룹에 추가됐습니다.');
      handleClose();
    } catch (e) {
      throwToast('에러가 발생하였습니다.');
    }
  };

  const handleCheckboxChange = (idx, isChecked) => {
    setCheckedGroups((prevState) => {
      if (isChecked) {
        return [...prevState, idx];
      } else {
        return prevState.filter((item) => item !== idx);
      }
    });
  };

  const handleNewGroupAdd = async () => {
    const addedStreamers = [];

    if (!isEmpty(data.broadcastInfo)) {
      addedStreamers.push({
        userId: data.broadcastInfo.userId,
        userNick: data.broadcastInfo.userNickname,
      });
    }

    if (!isEmpty(data.streamerInfo)) {
      addedStreamers.push(data.streamerInfo);
    }

    sendCreateFavoriteGroupClickLog({
      buttonType: 'create_method3',
    });

    const {action} = await throwDialog(
      'my/ExtendedNewGroup',
      {
        addedStreamers,
        favoriteUserId: data.streamerId,
        checkedGroups,
      },
      {
        isCustomDialog: true,
        customClassName: 'myFav_group_layer',
      },
    );

    if (action === 'positive') {
      fetchFavoriteGroups();
    }
  };

  return (
    <>
      <ul className='list_box'>
        {favoriteGroups.map((group) => (
          <li key={group.idx}>
            <input
              type='checkbox'
              id={`plst${group.idx}`}
              checked={checkedGroups.includes(group.idx)}
              onChange={(e) =>
                handleCheckboxChange(group.idx, e.target.checked)
              }
            />
            <label htmlFor={`plst${group.idx}`}>{group.title}</label>
          </li>
        ))}
      </ul>
      <div className='btn_area'>
        <button
          type='button'
          className='new_list_add'
          onClick={handleNewGroupAdd}>
          새 그룹 만들기
        </button>
        <button type='button' className='submit' onClick={handleGroupAdd}>
          확인
        </button>
      </div>
    </>
  );
});

export default MyFavoriteGroup;
