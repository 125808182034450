import MyApi from '@/api/my';
import StbbsApi from '@/api/stbbs';
import {convertKeysToSnakeCase} from '@/utils';
import {snakeCase} from 'lodash';

/**
 * @typedef {Object} Broadcast - 방송 정보
 * @property {string[]} autoHashtags - 자동 해시태그
 * @property {number} broadBps - 방송 BPS
 * @property {string} broadCateNo - 방송 카테고리 번호
 * @property {string} broadImg - 방송 이미지 URL
 * @property {number} broadNo - 방송 번호
 * @property {string} broadResolution - 방송 해상도
 * @property {string} broadStart - 방송 시작 시간
 * @property {string} broadTitle - 방송 제목
 * @property {string} broadType - 방송 타입
 * @property {string[]} categoryTags - 카테고리 태그
 * @property {boolean} is1080p - 1080p 여부
 * @property {boolean} isAdult - 성인 여부
 * @property {boolean} isDrops - 드롭스 여부
 * @property {boolean} isFanclub - 팬클럽 여부
 * @property {boolean} isFavorite - 즐겨찾기 여부
 * @property {boolean} isLive - 라이브 여부
 * @property {boolean} isPassword - 비밀 방송 여부
 * @property {boolean} isPin - 핀 여부
 * @property {boolean} isSubscribe - 구독 여부
 * @property {boolean} isVisitBroad - 방문 방송 여부
 * @property {string} lastBroadStart - 마지막 방송 시작일
 * @property {number} mobileViewCnt - 모바일 시청자수
 * @property {number} orderNo - 정렬 번호
 * @property {number} parentBroadNo - 부모 방송 번호
 * @property {number} pcViewCnt - PC 시청자수
 * @property {string} stationName - 방송사
 * @property {number} totalViewCnt - 총 시청자수
 * @property {string} url - 방송 URL
 * @property {string} userId - 사용자 아이디
 * @property {string} userNick - 사용자 닉네임
 * @property {string[]} hashTags - 해시태그
 */

const MyHistoryApi = {
  /**
   * 최근 본 LIVE 목록 조회
   * @returns {Promise<Broadcast[]>}
   */
  async getRecentLiveBroadcasts() {
    try {
      const {data: liveBroadcasts} = await MyApi.get('/api/recent');
      return liveBroadcasts || [];
    } catch (error) {
      return [];
    }
  },
  /**
   * 최근 본 VOD 목록 조회
   * @param {Object} params
   * @param {number} params.page
   * @param {'reg_date' | 'read_cnt' | 'like_cnt'} params.orderByColumn - 정렬 기준
   * @param {'desc' | 'asc'} params.orderByType - 정렬 타입
   * @returns {Promise<Broadcast[]>}
   */
  async getRecentVod({
    page = 1,
    orderByColumn = 'reg_date',
    orderByType = 'desc',
  }) {
    try {
      const {data, hasMore} = await MyApi.get('/api/history/recent/vod', {
        page,
        order_by_column: orderByColumn,
        order_by_type: orderByType,
      });
      return {vod: data || [], hasMore: hasMore || false};
    } catch (error) {
      return {vod: [], hasMore: false};
    }
  },
  /**
   * 최근 본 VOD 전체 삭제
   */
  async deleteAllRecentVod() {
    try {
      const formData = new FormData();
      formData.append('_method', 'delete');
      await MyApi.post('/api/history/recent/vod/all', formData);
    } catch (error) {
      throw error;
    }
  },
  /**
   * 최근 본 스트리머의 LIVE 전체 삭제
   */
  async deleteAllRecentLive() {
    try {
      const formData = new FormData();
      formData.append('_method', 'delete');
      await MyApi.post('/api/recent/delete/all', formData);
    } catch (error) {
      throw error;
    }
  },
  /**
   * 최근 본 스트리머의 특정  LIVE 삭제
   * @param {string} userId
   */
  async deleteRecentLive(userId) {
    try {
      const formData = new FormData();
      formData.append('_method', 'delete');
      await MyApi.post(`/api/recent/${userId}`, formData);
    } catch (error) {
      throw error;
    }
  },
  /**
   * 최근 본 스트리머의 특정 VOD 삭제
   * @param {number} titleNo - VOD 방송 인덱스
   */
  async deleteRecentVod(titleNo) {
    try {
      const formData = new FormData();
      formData.append('_method', 'delete');
      await MyApi.post(`/api/history/recent/vod/${titleNo}`, formData);
    } catch (error) {
      throw error;
    }
  },
  /**
   * 나중에 보기 스트리머의 특정 VOD 삭제
   * @param {number} titleNo - VOD 방송 인덱스
   * @param {VOD | LIVE} type - 방송 타입
   */
  async deleteLaterBroadcast(titleNo, type) {
    try {
      const formData = new FormData();
      formData.append('_method', 'delete');
      await MyApi.post(
        // 킹받게 하나는 대문자고 하나는 소문자임 ㄷㄷ
        `/api/history/later/${type === 'VOD' ? type : 'live'}/${titleNo}`,
        formData,
      );
    } catch (error) {
      throw error;
    }
  },
  /**
   * 나중에 보기 VOD 목록 조회
   * @param {Object} params
   * @param {number} params.page
   * @param {'reg_date' | 'read_cnt' | 'like_cnt'} params.orderByColumn - 정렬 기준
   * @param {'desc' | 'asc'} params.orderByType - 정렬 타입
   * @returns {Promise<Broadcast[]>}
   */
  async getLaterVod({
    page = 1,
    orderByColumn = 'reg_date',
    orderByType = 'desc',
  }) {
    try {
      const {data, hasMore} = await MyApi.get('/api/history/later/vod', {
        page,
        order_by_column: orderByColumn,
        order_by_type: orderByType,
      });
      return {vod: data || [], hasMore: hasMore || false};
    } catch (error) {
      return {vod: [], hasMore: false};
    }
  },
  /**
   * 나중에 보기 VOD 삭제
   * @param {'all'| 'view'} type
   */
  async deleteLaterVod(type) {
    try {
      const formData = new FormData();
      formData.append('_method', 'delete');
      const response = await MyApi.post(
        `/api/history/later/VOD/${type}`,
        formData,
      );
      return response;
    } catch (error) {
      throw error;
    }
  },
  /**
   * up 한 vod 목록 조회
   * @param {Object} params
   * @param {number} params.page
   * @param {'reg_date' | 'read_cnt' | 'like_cnt'} params.orderByColumn - 정렬 기준
   * @param {'desc' | 'asc'} params.orderByType - 정렬 타입
   * @returns {Promise<Broadcast[]>}
   */
  async getUpVod({page = 1, orderByColumn = 'reg_date', orderByType = 'desc'}) {
    try {
      const {data, hasMore} = await MyApi.get('/api/history/up/vod', {
        page,
        order_by_column: orderByColumn,
        order_by_type: orderByType,
      });
      return {vod: data || [], hasMore: hasMore || false};
    } catch (error) {
      return {vod: [], hasMore: false};
    }
  },
  /**
   * playlist 조회
   * @returns {Promise<Broadcast[]>}
   */
  async getPlaylist() {
    try {
      const {data: playlist} = await MyApi.get('/api/history/playlist');
      return playlist || [];
    } catch (error) {
      return [];
    }
  },
  /**
   * 라이브 방송 나중에 보기
   * @param {'VOD' | 'LIVE'}  broadcastType
   * @param {number} broadcastId
   */
  async viewLive(broadcastType, broadcastId) {
    try {
      await MyApi.post(`/api/history/later/${broadcastType}/${broadcastId}`);
    } catch (e) {
      throw e;
    }
  },
  /**
   *
   * @param {number} listIdx - 플레이리스트 인덱스
   * @param {boolean} isMine - 생성한 사람이 추가한 사람이랑 같은지 여부
   */
  async deletePlaylist(listIdx, isMine) {
    try {
      const data = await StbbsApi.post(
        '/vodplaylist/api/api.php',
        {
          szWork: isMine ? 'del_playlist_list' : 'share_playlist_bj_del_list',
          nPlaylistIdx: listIdx,
        },
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      return data;
    } catch (e) {
      throw error;
    }
  },
  /**
   * UP 한 VOD 를 삭제하는 함수
   * @param {Object} params
   * @param {string} params.streamerId
   * @param {number} params.titleNo
   * @returns {Promise<{message}>}
   */
  async deleteUpVod(params) {
    const {streamerId, titleNo} = params;

    try {
      const formData = new FormData();
      formData.append('_method', 'delete');
      const data = await MyApi.post(
        `/api/history/up/${streamerId}/${titleNo}`,
        formData,
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  async viewLaterVod(broadcastId) {
    try {
      const data = await MyApi.post(
        `/api/history/later/vod/${broadcastId}`,
        {
          _method: 'PUT',
        },
        {},
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      );
      return data;
    } catch (e) {
      throw error;
    }
  },
};

export default MyHistoryApi;
