import '/public/static/css/module/cBox.scss';
import {CategoryList} from '@/components/main/MyPlus/common/CategoryList';
import {LiveGroup} from '@/components/main/MyPlus/common/LiveGroup';
import {PREFER_INIT_LIVE_LIST, PREFER_INIT_VOD_LIST} from '@/constants/main';
import {useEffect, useState} from 'react';
import {TopBanner} from '@/components/main/MyPlus/common/TopBanner';
import {VodGroup} from '@/components/main/MyPlus/common/VodGroup';
import {CatchGroup} from '@/components/main/MyPlus/common/CatchGroup';
import {CatchStroyGroup} from '@/components/main/MyPlus/common/CatchStoryGroup';
import {PlayerArea} from '@/components/main/MyPlus/common/PlayerArea';
import {Banner} from '@/components/common';
import useMainStore from '@/stores/main';

export const Prefer = ({response, selectedMode, mainListInfo}) => {
  //선호 Live 리스트
  const [preferLiveList, setPreferLiveList] = useState(PREFER_INIT_LIVE_LIST);
  //선호 Vod 리스트
  const [preferVodList, setPreferVodList] = useState(PREFER_INIT_VOD_LIST);
  //스트리밍 Live 리스트
  const {
    banner,
    isShowMainBanner,
    isLoadingPlayerArea,
    isCloseMainBanner,
    setTopLiveList,
  } = useMainStore();

  useEffect(() => {
    if (response) {
      setPreferLiveList(
        {
          liveCnt: response.liveCnt || 0,
          liveList:
            response.liveList && response.liveList?.length > 0
              ? response.liveList
              : [],
        } || PREFER_INIT_LIVE_LIST,
      );

      setPreferVodList(
        {
          vodCnt: response.vodCnt || 0,
          vodList:
            response.vodList && response.vodList?.length > 0
              ? response.vodList
              : [],
        } || PREFER_INIT_VOD_LIST,
      );
    }
  }, [response]);

  useEffect(() => {
    if (
      (response.topLiveList && response.topLiveList.length > 0) ||
      (banner.main.exist && mainListInfo)
    ) {
      const formattedList = (response.topLiveList || []).map((broadcast) => {
        const {
          totalViewCnt,
          userNick,
          broadTitle,
          broadNo,
          broadThumb,
          ...rest
        } = broadcast;
        return {
          ...rest,
          viewCount: totalViewCnt,
          userNickname: userNick,
          title: broadTitle,
          broadcastId: broadNo,
          thumbnailSrc: broadThumb,
          isBanner: false,
        };
      });
      setTopLiveList({
        simplifyMode:
          mainListInfo && mainListInfo.simplifymodeResult === -1 ? false : true,
        count: formattedList.length + banner.main.creatives.length || 0,
        list: [...formattedList, ...banner.main.creatives] || [],
      });
    }
  }, [mainListInfo, response.topLiveList, banner.main]);

  return (
    <>
      {/* 해상도 축소에 따라 플로팅배너가 비노출되었을때, 기존 플로팅배너 DOM을 그대로 유지하고있어야해서(해상도 확대 시. 시청했던 시점부터 다시 광고 재생을 위해서) 실제로 플로팅 배너 닫기버튼을 클릭하지 않을 시에는 계속헤서 dom을 유지시킴 */}
      {!isCloseMainBanner && <Banner type={'main'} category={'myplus'} />}
      {/* 플로팅 배너 미노출 && 비쥬얼 영역이 로딩중(플로팅 배너가 노출되기 전에도 비쥬얼 영역이 노출되고 그 위를 플로팅배너가 덮이는 현상이 있어서)일때 노출  */}
      {!isShowMainBanner && !isLoadingPlayerArea && (
        <PlayerArea banner={banner.main} />
      )}
      <LiveGroup response={preferLiveList} selectedMode={selectedMode} />
      <CategoryList selectedMode={selectedMode} />
      <TopBanner mainListInfo={mainListInfo} />
      <CatchGroup selectedMode={selectedMode} />
      <VodGroup response={preferVodList} selectedMode={selectedMode} />
      <CatchStroyGroup />
    </>
  );
};
