import MyFeedApi from '@/api/my/feed';
import throwToast from '@/utils/ui/toast';
import classNames from 'classnames';
import {useRef, useState} from 'react';
import throwSharePopup, {generateUrlForShare} from '@/utils/ui/share-popup';

const ActionGroup = (props) => {
  const {
    userId = '',
    stationUserId = '',
    isLike = false,
    titleNo = 0,
    titleName = '',
    url = '',
    count: {likeCnt = 0, commentCnt = 0, readCnt = 0} = {
      likeCnt: 0,
      commentCnt: 0,
      readCnt: 0,
    },
    photos,
    scheme,
    stationNo,
    content,
    feedType,
  } = props;

  const [isMyLike, setIsMyLike] = useState(isLike);
  const [totalLikeCnt, setTotalLikeCnt] = useState(likeCnt + (isLike ? 1 : 0));
  const buttonRef = useRef();
  const firstPhotoUrl = photos?.[0]?.url;

  const handleOnClickLike = async (isMyLike, stationUserId, titleNo) => {
    try {
      const response = isMyLike
        ? await MyFeedApi.setFeedUnLike(stationUserId, titleNo)
        : await MyFeedApi.setFeedLike(stationUserId, titleNo);

      throwToast(response);
      setIsMyLike(!isMyLike);
      setTotalLikeCnt((prevCount) => prevCount + (isMyLike ? -1 : 1));
    } catch (error) {
      const {message} = error.response.data;
      throwToast(message);
    }
  };

  const handleClickComment = () => {
    window.open(`${url}#comment`);
  };

  const handleShareClick = (buttonRef) => {
    const shareUrlInfo = generateUrlForShare(
      {
        type: feedType.toLowerCase(),
        title: titleName,
        content: content,
        count: readCnt,
        thumbnailUrl: firstPhotoUrl,
        titleNo: titleNo,
        broadcastId: titleNo,
        appScheme: scheme,
        stationNo: stationNo,
        userId: userId,
      },
      url,
    );
    throwSharePopup({
      title: titleName,
      pageUrl: shareUrlInfo.pageUrl,
      kakaoParams: shareUrlInfo.kakaoParams,
      parentPosition: buttonRef.current.getBoundingClientRect(),
    });
  };

  return (
    <div className='item_util'>
      <button
        type='button'
        className={classNames('btn-basic up', {on: isMyLike})}
        onClick={() => handleOnClickLike(isMyLike, stationUserId, titleNo)}>
        <span>up</span>
        <em>{totalLikeCnt}</em>
      </button>
      <button
        type='button'
        className='btn-basic cmmt_list'
        onClick={handleClickComment}>
        <span>댓글</span>
        <em>{commentCnt}</em>
      </button>
      <div className='share_box'>
        <button
          type='button'
          className='btn-basic share'
          ref={buttonRef}
          onClick={() => handleShareClick(buttonRef)}>
          <span>공유하기</span>
        </button>
      </div>
    </div>
  );
};

export default ActionGroup;
